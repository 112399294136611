import { useContext } from 'react';

import { Button } from '@chakra-ui/react';
import { MdNotifications, MdNotificationsOff } from 'react-icons/md';
import { toast } from 'react-toastify';

import { useUpdateSubscriptionToNotificationsStatusMutation } from '../../../gql/generated/graphql';

import { useUser } from '../../../hooks/useUser';
import { DealActionsEnum, DealContext } from '../../../libs/DealContext';

// Not a progression button, but appears in the same place
const NotificationSubscribeButton = () => {
  const user = useUser();
  const { deal, dispatch } = useContext(DealContext);

  const isUserSubscribed = deal?.notification_subscribers
    ?.map((ns) => ns.subscriber_id)
    .includes(user.id);

  const [updateSubscriptionStatus, { loading }] =
    useUpdateSubscriptionToNotificationsStatusMutation();

  const handleClicked = async () => {
    if (!deal.id) {
      return;
    }

    try {
      // Update Subscription Status
      await updateSubscriptionStatus({
        variables: { dealId: deal.id, subscribeToNotifications: !isUserSubscribed },
      });

      // Update deal context
      const updatedSubscribers = isUserSubscribed
        ? deal?.notification_subscribers?.filter((s) => s.subscriber_id !== user.id) ?? []
        : [...(deal?.notification_subscribers ?? []), { deal_id: deal.id, subscriber_id: user.id }];

      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          ...deal,
          notification_subscribers: updatedSubscribers,
        },
      });
      toast.success('Notification subscription status updated');
    } catch (error) {
      toast.error('Failed to update notification subscription status');
    }
  };

  return (
    <Button variant="secondary" size="lg" p={0} onClick={handleClicked} isLoading={loading}>
      {isUserSubscribed ? <MdNotifications size="25px" /> : <MdNotificationsOff size="25px" />}
    </Button>
  );
};

export default NotificationSubscribeButton;
