import { useContext, useEffect, useMemo } from 'react';

import { Box, Button, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { MdArticle, MdKeyboardArrowDown } from 'react-icons/md';

import { Customer } from '../../../gql/customerGql';
import {
  useBankInfoQuery,
  useTemplateInfoQuery,
  useTemplateTextsQuery,
} from '../../../gql/generated/graphql';

import { BankEnum } from '../../../constants/bank';
import { useUser } from '../../../hooks/useUser';
import { TextWidgetContext } from '../../../libs/contextLib';
import { fillText } from '../../../utils/messages';

type TemplateSelectProps = {
  client: Customer;
  bank?: BankEnum;
};

const TemplateSelect = ({ client, bank }: TemplateSelectProps) => {
  const { textDealId } = useContext(TextWidgetContext);
  const { setFieldValue } = useFormikContext();
  const { data: templateData } = useTemplateTextsQuery();
  const templates = templateData?.templateTexts || [];
  const { data: bankData, refetch: bankRefetch } = useBankInfoQuery({
    variables: { deal_id: textDealId ?? -1 },
    fetchPolicy: 'cache-first',
  });
  const { data: tempData } = useTemplateInfoQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: textDealId!, sources: [], types: [] },
  });
  const bankInfo = bankData?.bankInfo;
  const tempInfo = tempData?.deal;
  const user = useUser();

  useEffect(() => {
    bankRefetch();
  }, [bank]);

  const templateOptions = useMemo(
    () =>
      templates.map((template) => (
        <Box
          p={2}
          _hover={{ bgColor: 'gray.200', cursor: 'pointer' }}
          key={template?.id}
          onClick={() => {
            setFieldValue(
              'text_message',
              fillText(template?.text ?? '', {
                user,
                customer: client,
                bank: bankInfo,
                deal: tempInfo,
              }),
            );
          }}
        >
          {template?.description}
        </Box>
      )),
    [
      client.first_name,
      client.email,
      templates,
      bankInfo?.name,
      bankInfo?.phone,
      bankInfo?.address,
      bankInfo?.city,
      bankInfo?.state,
      bankInfo?.zip,
      user.name,
      user.phone_number,
      tempInfo?.car?.payoff?.lienholder_name,
      tempInfo?.financial_info?.money_down,
    ],
  );

  return (
    <Popover placement="left-start">
      <PopoverTrigger>
        <Button variant="primary" size="sm">
          <MdArticle size={20} color="white" />
          <MdKeyboardArrowDown size={15} color="white" />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Box bgColor="white" boxShadow="md" pt={2} pb={2} overflowY="scroll" h="200px">
          {templateOptions}
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default TemplateSelect;
