import { FollowUpTypeEnum, Maybe } from '../gql/generated/graphql';

export const getFollowUpTypeLabel = (type: Maybe<FollowUpTypeEnum>): string => {
  switch (type) {
    case FollowUpTypeEnum.Appointment:
      return 'Appointment';
    default:
      return 'Reminder';
  }
};

export const followUpTypeOptions = Object.values(FollowUpTypeEnum).map((type) => ({
  value: type,
  label: getFollowUpTypeLabel(type),
}));
