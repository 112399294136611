import { useContext, useState } from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { FaEdit } from 'react-icons/fa';

import { Deal, DealStateEnum } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

import ConfirmEditModal from '../ConfirmEditModal/ConfirmEditModal';

import { PermissionEnum } from '../../constants/permissions';
import { Can } from '../../libs/Can';
import { DealContext } from '../../libs/DealContext';

interface EditDealInfoButtonProps extends ButtonProps {
  isEditingWithPermission: boolean;
  setIsEditingWithPermission: (isEditing: boolean) => void;
}

const EditDealInfoButton = ({
  isEditingWithPermission,
  setIsEditingWithPermission,
  ...rest
}: EditDealInfoButtonProps) => {
  const { deal, isRecalculatingPayoff } = useContext(DealContext);

  const { values } = useFormikContext<Deal>();
  const [isSaving, setSaving] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const onEdit = () => {
    setIsEditingWithPermission(true);
    setSaving(false);
  };

  return (
    <Can I={PermissionEnum.EditDeal}>
      <Button
        variant="secondary"
        isLoading={isSaving}
        isDisabled={isRecalculatingPayoff}
        loadingText="EDIT"
        hidden={values.state !== DealStateEnum.Closed || isEditingWithPermission}
        onClick={() => {
          setShowEditModal(true);
        }}
        size="sm"
        leftIcon={<FaEdit fontSize="md" />}
        w={deal.type !== DealType.Acquisition ? '70px' : '100px'}
        {...rest}
      >
        EDIT
      </Button>

      <ConfirmEditModal
        isOpen={showEditModal}
        onConfirm={() => {
          onEdit();
          setShowEditModal(false);
        }}
        onClose={() => setShowEditModal(false)}
      />
    </Can>
  );
};

export default EditDealInfoButton;
