import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  GUID: any;
  JSON: any;
  JSONObject: any;
  Void: any;
};

export type Auth0Role = {
  __typename?: 'Auth0Role';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Auth0RoleInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum Auth0RoleName {
  AccountingTeam = 'AccountingTeam',
  Admin = 'Admin',
  CSuite = 'CSuite',
  CustomerService = 'CustomerService',
  D2dAdmin = 'D2dAdmin',
  D2dCloser = 'D2dCloser',
  D2dDev = 'D2dDev',
  D2dSetter = 'D2dSetter',
  D2dSetterLead = 'D2dSetterLead',
  FinanceTeam = 'FinanceTeam',
  FundingClerk = 'FundingClerk',
  FundingClerkManager = 'FundingClerkManager',
  InboundFinancialSpecialist = 'InboundFinancialSpecialist',
  InboundSalesAdvisor = 'InboundSalesAdvisor',
  InboundSalesPodLead = 'InboundSalesPodLead',
  OutboundAdvisorManager = 'OutboundAdvisorManager',
  OutboundSetter = 'OutboundSetter',
  OutboundWebAdvisor = 'OutboundWebAdvisor',
  PayoffClerk = 'PayoffClerk',
  PayoffManager = 'PayoffManager',
  StructuringAssistant = 'StructuringAssistant',
  StructuringManager = 'StructuringManager',
  TitleClerk = 'TitleClerk',
  TitleManager = 'TitleManager',
  WebAdmin = 'WebAdmin',
}

export type AutoStructureBankLogo = {
  __typename?: 'AutoStructureBankLogo';
  bank_name?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
};

export type CallsPagination = {
  __typename?: 'CallsPagination';
  calls?: Maybe<Array<Maybe<Call>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  height?: Maybe<Scalars['Float']>;
  pageNumber?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Float']>;
  xPosition?: Maybe<Scalars['Float']>;
  yPosition?: Maybe<Scalars['Float']>;
};

export type CreateTransactionInput = {
  callbackUrl?: InputMaybe<Scalars['String']>;
  conditions?: InputMaybe<TtConditionFilterInput>;
  parameters?: InputMaybe<TtFeesInput>;
  processor?: InputMaybe<Processor>;
  state?: InputMaybe<StateAbbreviation>;
  types?: InputMaybe<Array<InputMaybe<TtProductType>>>;
};

export type CreateUserInput = {
  auth0Roles: Array<InputMaybe<Auth0RoleInput>>;
  email: Scalars['String'];
  huntGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  podIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  recruiterId?: InputMaybe<Scalars['String']>;
};

export type CreditAppInput = {
  cobuyer?: InputMaybe<CustomerInput>;
  contact?: InputMaybe<DealContactInput>;
  customer: CustomerInput;
  financial_info: FinancialInfoInput;
  id: Scalars['ID'];
  odometer_status?: InputMaybe<OdometerStatus>;
  second_contact?: InputMaybe<DealContactInput>;
  state: Scalars['String'];
};

export type CreditApplicationSubmission = {
  __typename?: 'CreditApplicationSubmission';
  created_at?: Maybe<Scalars['DateTime']>;
  credit_applications?: Maybe<Array<Maybe<CreditApplication>>>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type CreditApplicationSubmissionFilter = {
  dealIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type D2dDocument = {
  __typename?: 'D2dDocument';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type DdSoftPull = {
  __typename?: 'DDSoftPull';
  cobuyer?: Maybe<Prequalification>;
  customer: Prequalification;
};

export type DashboardDocumentUpload = {
  __typename?: 'DashboardDocumentUpload';
  deal: Deal;
  fileType: Scalars['String'];
};

export type DateFilter = {
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
};

export type DealDocument = {
  __typename?: 'DealDocument';
  data?: Maybe<Scalars['String']>;
  dealJacketId?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  docStatCode?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['String']>;
  documentName?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  signAttributes?: Maybe<Array<Maybe<DealDocumentSignAttribute>>>;
  signatureStatus?: Maybe<DocumentSignatureStatusEnum>;
  wetSignature?: Maybe<Scalars['Boolean']>;
};

export type DealDocumentSignAttribute = {
  __typename?: 'DealDocumentSignAttribute';
  actorName?: Maybe<Scalars['String']>;
  actorType?: Maybe<ActorType>;
  dateCoordinates?: Maybe<Coordinate>;
  dateFieldName?: Maybe<Scalars['String']>;
  initialsType?: Maybe<Scalars['Boolean']>;
  signCaptureTms?: Maybe<Scalars['String']>;
  signFieldName?: Maybe<Scalars['String']>;
  signPadText?: Maybe<Scalars['String']>;
  signRequired?: Maybe<Scalars['Boolean']>;
  signatureCoordinates?: Maybe<Coordinate>;
};

export type DealImported = {
  __typename?: 'DealImported';
  deal_id: Scalars['ID'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};

export enum DealMediaSourceEnum {
  System = 'system',
  User = 'user',
}

export enum DealMediaStateEnum {
  Active = 'active',
  Pending = 'pending',
}

export enum DealSortableColumnsEnum {
  CreatedAt = 'created_at',
  CustomerName = 'customer_name',
  DealStage = 'deal_stage',
}

export enum DealSource {
  All = 'all',
  CallIn = 'call_in',
  D2d = 'd2d',
  Outbound = 'outbound',
  Web = 'web',
}

export enum DealStateEnumV2 {
  AtAuction = 'at_auction',
  Booted = 'booted',
  Closed = 'closed',
  Closing = 'closing',
  Estimate = 'estimate',
  Finalized = 'finalized',
  Floor = 'floor',
  Funded = 'funded',
  PaidOff = 'paid_off',
  ReadyForPickup = 'ready_for_pickup',
  SendPayoff = 'send_payoff',
  SentToProcessor = 'sent_to_processor',
  Signatures = 'signatures',
  Signed = 'signed',
  SoftClose = 'soft_close',
  Sold = 'sold',
  Structuring = 'structuring',
  StructuringInProgress = 'structuring_in_progress',
  TitleReceived = 'title_received',
  TitleSent = 'title_sent',
  WaitingForTitle = 'waiting_for_title',
}

export enum DealType {
  Acquisition = 'acquisition',
  Buyout = 'buyout',
  Refi = 'refi',
}

export enum DealTypeV2 {
  Acquisition = 'acquisition',
  Buyout = 'buyout',
  Refi = 'refi',
}

export type DealWithErrors = {
  __typename?: 'DealWithErrors';
  deal?: Maybe<Deal>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DealsConnection = {
  __typename?: 'DealsConnection';
  items: Array<Maybe<Deal>>;
  pagination: PaginationType;
  total?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type DealsFilter = {
  closerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['GUID']>>>;
  created_at?: InputMaybe<DateFilter>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  isFinalized?: InputMaybe<Scalars['Boolean']>;
  leaseIds?: InputMaybe<Array<InputMaybe<Scalars['GUID']>>>;
  searchValue?: InputMaybe<Scalars['String']>;
  setterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<DealSource>;
  state?: InputMaybe<Array<InputMaybe<DealStateEnumV2>>>;
  type?: InputMaybe<DealTypeV2>;
  updated_at?: InputMaybe<DateFilter>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum DocumentProgressStatus {
  Failed = 'failed',
  GenerateDocuments = 'generate_documents',
  PendingLender = 'pending_lender',
  PendingR1 = 'pending_r1',
  ReadyForSignatures = 'ready_for_signatures',
  Required = 'required',
  SentForSignatures = 'sent_for_signatures',
  Signed = 'signed',
  UploadDocuments = 'upload_documents',
}

export enum DocumentSignatureStatusEnum {
  Completed = 'completed',
  InProcess = 'in_process',
  Unstarted = 'unstarted',
}

export type DownPaymentReceived = {
  __typename?: 'DownPaymentReceived';
  amount: Scalars['Float'];
  dealId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  type: Scalars['String'];
};

export type DriversLicenseGql = {
  __typename?: 'DriversLicenseGQL';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ExperianOutput = {
  __typename?: 'ExperianOutput';
  errors?: Maybe<Array<Scalars['String']>>;
  riskModel?: Maybe<ExperianRiskModel>;
};

export type ExperianRiskModel = {
  __typename?: 'ExperianRiskModel';
  score?: Maybe<Scalars['String']>;
};

export type ExternalCustomerInput = {
  dashboard_visited?: InputMaybe<Scalars['Boolean']>;
  dl_expiration_date?: InputMaybe<Scalars['DateTime']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_authenticated_route?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
};

export type ExternalDealInputType = {
  auto_import_variation?: InputMaybe<AutoImportVariation>;
};

export type ExtractedDocumentGql = {
  __typename?: 'ExtractedDocumentGQL';
  documentName?: Maybe<Scalars['ID']>;
  fields?: Maybe<Array<Maybe<ExtractedField>>>;
  key?: Maybe<Scalars['String']>;
};

export type ExtractedField = {
  __typename?: 'ExtractedField';
  confidence?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FeeGql = {
  __typename?: 'FeeGQL';
  baseTaxAmount?: Maybe<Scalars['Float']>;
  docFeeTaxAmount?: Maybe<Scalars['Float']>;
  feeProcessor?: Maybe<Scalars['String']>;
  registrationTransferFee?: Maybe<Scalars['Float']>;
  salesTaxRate?: Maybe<Scalars['Float']>;
  state?: Maybe<StateAbbreviation>;
  taxProcessor?: Maybe<Scalars['String']>;
  totalFeeAmount?: Maybe<Scalars['Float']>;
  warrantyTaxAmount?: Maybe<Scalars['Float']>;
};

export type FilterType = {
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  banks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  closedDateFrom?: InputMaybe<Scalars['DateTime']>;
  closedDateTo?: InputMaybe<Scalars['DateTime']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  dealStates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hasTitleReceived?: InputMaybe<Scalars['Boolean']>;
  lienholders?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notifications?: InputMaybe<Scalars['Boolean']>;
  problemDeals?: InputMaybe<Scalars['Boolean']>;
  processors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  titleDateFrom?: InputMaybe<Scalars['DateTime']>;
  titleDateTo?: InputMaybe<Scalars['DateTime']>;
  titlingPods?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  usStates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  vins?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HuntGroup = {
  __typename?: 'HuntGroup';
  created_at?: Maybe<Scalars['DateTime']>;
  default_queue?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  ring_all?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type KbbSelectedOptionsInput = {
  id?: InputMaybe<Scalars['ID']>;
  lendingOptionPrice?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  retailOptionPrice?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type KbbSelectedOptionsObject = {
  __typename?: 'KbbSelectedOptionsObject';
  selected_options?: Maybe<Array<Maybe<KbbSelectedOptionsType>>>;
};

export type KbbSelectedOptionsObjectInput = {
  selected_options?: InputMaybe<Array<InputMaybe<KbbSelectedOptionsInput>>>;
};

export enum LeExperianErrorCodeEnum {
  Frozen = 'FROZEN',
  Locked = 'LOCKED',
  NoRecordFound = 'NO_RECORD_FOUND',
}

export type Lender = {
  __typename?: 'Lender';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type NotificationSubscriber = {
  __typename?: 'NotificationSubscriber';
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  subscriber_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type NotificationSubscriberInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  subscriber_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export enum OdometerStatus {
  Changed = 'Changed',
  Mismatch = 'Mismatch',
  Verified = 'Verified',
  Warning = 'Warning',
}

export type PaginatedDeals = {
  __typename?: 'PaginatedDeals';
  deals?: Maybe<Array<Maybe<Deal>>>;
  dealsMaxAge?: Maybe<Scalars['DateTime']>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  results?: Maybe<Array<Maybe<User>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type PaginationInputType = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type PaginationType = {
  __typename?: 'PaginationType';
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum PaperworkType {
  Adobe = 'adobe',
  Esign = 'esign',
  Paper = 'paper',
}

export type PaymentOptionsInput = {
  bank?: InputMaybe<Scalars['String']>;
  bankFees?: InputMaybe<Scalars['Float']>;
  banks?: InputMaybe<Array<InputMaybe<BankInput>>>;
  baseTaxAmount?: InputMaybe<Scalars['Float']>;
  buyRate?: InputMaybe<Scalars['Float']>;
  carMileage?: InputMaybe<Scalars['Int']>;
  carYear?: InputMaybe<Scalars['Int']>;
  clientName?: InputMaybe<Scalars['String']>;
  closer2Id?: InputMaybe<Scalars['String']>;
  closerId?: InputMaybe<Scalars['String']>;
  daysToPayment?: InputMaybe<Scalars['Int']>;
  docFee?: InputMaybe<Scalars['Float']>;
  gapCost?: InputMaybe<Scalars['Float']>;
  gapPrice?: InputMaybe<Scalars['Float']>;
  lienholder?: InputMaybe<Scalars['String']>;
  moneyDown?: InputMaybe<Scalars['Float']>;
  newRegistrationFee?: InputMaybe<Scalars['Float']>;
  prequalificationCreditScore?: InputMaybe<Scalars['Int']>;
  registrationTransferFee?: InputMaybe<Scalars['Float']>;
  sellRate?: InputMaybe<Scalars['Float']>;
  setterId?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['Int']>;
  titleFee?: InputMaybe<Scalars['Float']>;
  titleRegistrationOption?: InputMaybe<TitleRegistrationOption>;
  userEnteredReserve?: InputMaybe<Scalars['Float']>;
  vehiclePayoff?: InputMaybe<Scalars['Float']>;
  vscCost?: InputMaybe<Scalars['Float']>;
  vscPrice?: InputMaybe<Scalars['Float']>;
  warrantyTaxAmount?: InputMaybe<Scalars['Float']>;
};

export type PrequalificationCreditReport = {
  __typename?: 'PrequalificationCreditReport';
  addresses?: Maybe<Array<PrequalificationCreditReportAddress>>;
  employments?: Maybe<Array<PrequalificationCreditReportEmployment>>;
  informationalMessages?: Maybe<Array<Scalars['String']>>;
  names?: Maybe<PrequalificationCreditReportNames>;
  scoreFactors?: Maybe<Array<PrequalificationCreditScoreScoreFactor>>;
  tradelines?: Maybe<PrequalificationCreditReportTradelines>;
};

export type PrequalificationCreditReportAddress = {
  __typename?: 'PrequalificationCreditReportAddress';
  city?: Maybe<Scalars['String']>;
  firstReportedDate?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetPrefix?: Maybe<Scalars['String']>;
  streetSuffix?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type PrequalificationCreditReportEmployment = {
  __typename?: 'PrequalificationCreditReportEmployment';
  addressExtraLine?: Maybe<Scalars['String']>;
  addressFirstLine?: Maybe<Scalars['String']>;
  addressSecondLine?: Maybe<Scalars['String']>;
  firstReportedDate?: Maybe<Scalars['String']>;
  lastUpdatedDate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type PrequalificationCreditReportName = {
  __typename?: 'PrequalificationCreditReportName';
  firstName: Scalars['String'];
  generationCode?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  secondSurname?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type PrequalificationCreditReportNames = {
  __typename?: 'PrequalificationCreditReportNames';
  aliases: Array<Maybe<PrequalificationCreditReportName>>;
  customerName?: Maybe<PrequalificationCreditReportName>;
};

export type PrequalificationCreditReportTradeline = {
  __typename?: 'PrequalificationCreditReportTradeline';
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  amount1?: Maybe<Scalars['String']>;
  amount1Qualifier?: Maybe<Scalars['String']>;
  amount2?: Maybe<Scalars['String']>;
  amount2Qualifier?: Maybe<Scalars['String']>;
  amountPastDue?: Maybe<Scalars['String']>;
  balanceAmount?: Maybe<Scalars['String']>;
  balanceDate?: Maybe<Scalars['String']>;
  delinquency30Days?: Maybe<Scalars['String']>;
  delinquency60Days?: Maybe<Scalars['String']>;
  delinquency90to180Days?: Maybe<Scalars['String']>;
  ecoa?: Maybe<Scalars['String']>;
  kob?: Maybe<Scalars['String']>;
  monthlyPaymentAmount?: Maybe<Scalars['String']>;
  monthsHistory?: Maybe<Scalars['String']>;
  openDate?: Maybe<Scalars['String']>;
  openOrClosed?: Maybe<Scalars['String']>;
  paymentHistory?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscriberName?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
};

export type PrequalificationCreditReportTradelines = {
  __typename?: 'PrequalificationCreditReportTradelines';
  installment: Array<PrequalificationCreditReportTradeline>;
  revolving: Array<PrequalificationCreditReportTradeline>;
  unknown: Array<PrequalificationCreditReportTradeline>;
};

export type PrequalificationCreditReports = {
  __typename?: 'PrequalificationCreditReports';
  cobuyer?: Maybe<PrequalificationCreditReport>;
  customer: PrequalificationCreditReport;
};

export type PrequalificationCreditScoreScoreFactor = {
  __typename?: 'PrequalificationCreditScoreScoreFactor';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PrequalificationRequest = {
  __typename?: 'PrequalificationRequest';
  first_name?: Maybe<Scalars['String']>;
  gross_income?: Maybe<Scalars['Float']>;
  income_sources?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  lending_book_value?: Maybe<Scalars['Float']>;
  money_down?: Maybe<Scalars['Float']>;
  pay_frequency?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  vehicle_payoff?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['String']>;
};

export enum Processor {
  Atc = 'Atc',
  DlrDmv = 'DlrDmv',
  TaterTitle = 'TaterTitle',
  Vitu = 'Vitu',
}

export type R1ContractValidationErrors = {
  __typename?: 'R1ContractValidationErrors';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type R1ContractValidationErrorsInput = {
  errors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type R1ContractValidationWarnings = {
  __typename?: 'R1ContractValidationWarnings';
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type R1ContractValidationWarningsInput = {
  warnings?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum ReferralSourceEnum {
  DidNotAsk = 'did_not_ask',
  InternetSearch = 'internet_search',
  Mailer = 'mailer',
  Other = 'other',
  RecommendationFromFriend = 'recommendation_from_friend',
  SocialMediaOrBlogPost = 'social_media_or_blog_post',
  Text = 'text',
  TrustedReview = 'trusted_review',
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  acquisitionDealInfoUpsert?: Maybe<Deal>;
  addDocumentsToMediaCenter?: Maybe<Scalars['Boolean']>;
  addLendersToCreditApplicationV2: CreditApplication;
  addParticipant?: Maybe<ResultAddParticipant>;
  applyDocumentSignature?: Maybe<Scalars['Void']>;
  archivePod?: Maybe<Scalars['Void']>;
  auctionUpsert?: Maybe<Auction>;
  automateCreditApplication?: Maybe<Deal>;
  buyerCobuyerSwap?: Maybe<Deal>;
  callOut?: Maybe<Call>;
  cancelPayoffRequest?: Maybe<PayoffRequest>;
  completedPayoffRequestsJob?: Maybe<Scalars['Void']>;
  conditionReportUpsert?: Maybe<ConditionReportUpsert>;
  createAffiliateTemporaryInfo?: Maybe<TemporaryInfo>;
  createArticle?: Maybe<Article>;
  createCreditApplicationV2: CreditApplication;
  createDealV2: Deal;
  createDownPaymentLink?: Maybe<Scalars['String']>;
  createExternalDealUploadUrl?: Maybe<ExternalDealUploadUrl>;
  createExternalTempInfoUploadUrl?: Maybe<ExternalTempInfoUploadUrl>;
  createFeedback?: Maybe<Scalars['Void']>;
  createJDPBookSheet?: Maybe<Scalars['Boolean']>;
  createKBBBookSheet?: Maybe<Scalars['Boolean']>;
  createManualEntryTemporaryInfo?: Maybe<TemporaryInfo>;
  createNewEstimateAndSetFollowUp?: Maybe<Deal>;
  createNewEstimateFromFiles?: Maybe<CreateNewEstimateFromFiles>;
  createOptEvent?: Maybe<OptEvent>;
  createPayoffRequest?: Maybe<PayoffRequest>;
  createPotentialUser?: Maybe<PotentialUser>;
  createReview?: Maybe<Review>;
  createStructuringFollowUp?: Maybe<Scalars['Boolean']>;
  createTemporaryInfo?: Maybe<TemporaryInfo>;
  createTransaction?: Maybe<Scalars['Float']>;
  createUser?: Maybe<User>;
  creditAppUpsert?: Maybe<Deal>;
  customDatesUpdate?: Maybe<DealDates>;
  customerInfoUpsert?: Maybe<Deal>;
  dealAssign?: Maybe<Deal>;
  dealCompleted?: Maybe<Deal>;
  dealDetailNetsuite?: Maybe<Deal>;
  dealFinalizeAcquisition?: Maybe<Scalars['Boolean']>;
  dealHasProblemSwitch?: Maybe<Deal>;
  dealInfoUpsert?: Maybe<Deal>;
  dealSeen?: Maybe<Scalars['Boolean']>;
  dealTagsUpsert: Array<Tag>;
  dealUnwound?: Maybe<Deal>;
  dealUpdateAssignments?: Maybe<Deal>;
  dealUpdateMissingRequiredDocuments?: Maybe<Scalars['Boolean']>;
  dealUpdateRequestBoot?: Maybe<Deal>;
  dealUpdateState?: Maybe<DealWithErrors>;
  dealUpdateStateAndSendEmails?: Maybe<Deal>;
  dealUpdateType?: Maybe<Deal>;
  deleteAndReplaceFiles?: Maybe<Scalars['Boolean']>;
  deleteArticle?: Maybe<Article>;
  deleteComFlowFile?: Maybe<Scalars['Void']>;
  deleteContracts?: Maybe<Scalars['Boolean']>;
  deleteCreditApplicationV2: Scalars['ID'];
  deleteD2dDocument?: Maybe<Scalars['Boolean']>;
  deleteDealV2: Scalars['Int'];
  deleteReview?: Maybe<Review>;
  deleteStructuringFollowUp?: Maybe<Scalars['Boolean']>;
  determineApprovalMutation?: Maybe<DetermineApproval>;
  documentsInfoUpdate?: Maybe<Scalars['Void']>;
  editD2dDocumentName?: Maybe<Scalars['Boolean']>;
  endSalesFlow?: Maybe<Scalars['Boolean']>;
  estimateUpsert?: Maybe<Deal>;
  executeCardDownPayment?: Maybe<FinixTransfer>;
  executeDownPayment?: Maybe<FinixTransfer>;
  executeExternalSoftPull?: Maybe<Scalars['Boolean']>;
  externalCustomerUpdate?: Maybe<Customer>;
  externalUpdateDeal?: Maybe<Deal>;
  fileSend?: Maybe<Message>;
  finalizeR1Signing?: Maybe<Scalars['Void']>;
  financialInfoAcquisitionUpsert?: Maybe<FinancialInfoAcquisition>;
  followUpDelete?: Maybe<FollowUp>;
  followUpInsert?: Maybe<FollowUp>;
  followUpUpdate?: Maybe<FollowUp>;
  generateAndUploadRateExceptionForm?: Maybe<Scalars['Boolean']>;
  getMarketPrequalification: MarketPrequalification;
  hangUpCall?: Maybe<Scalars['Boolean']>;
  importDeal?: Maybe<Deal>;
  linkAccount?: Maybe<Scalars['Void']>;
  linkUnlinkedCustomers?: Maybe<Scalars['Boolean']>;
  logSigningError?: Maybe<SigningError>;
  logViewedDocumentEvent?: Maybe<Scalars['Void']>;
  manuallySelectBank: FinancialInfo;
  mediaDelete?: Maybe<Scalars['Boolean']>;
  mediaDeleteFromCom?: Maybe<Scalars['Boolean']>;
  mediaInsert?: Maybe<DealMedia>;
  mediaInsertCom?: Maybe<DealMedia>;
  mediaRename?: Maybe<Scalars['Boolean']>;
  mediaUpdate?: Maybe<DealMedia>;
  mediaUpdateCom?: Maybe<DealMedia>;
  mergeCreditApplicationV2?: Maybe<Scalars['Boolean']>;
  mergeFiles?: Maybe<MergedDocuments>;
  messageSend?: Maybe<Message>;
  mockDealProcessing?: Maybe<Scalars['Void']>;
  newEstimateUpsert?: Maybe<Deal>;
  noteCreate?: Maybe<Note>;
  noteDelete?: Maybe<Note>;
  noteUpdate?: Maybe<Note>;
  notificationSeen?: Maybe<Scalars['Boolean']>;
  notificationsSeen?: Maybe<Scalars['Boolean']>;
  optOut?: Maybe<Scalars['Void']>;
  overrideCalledByFinancialSpecialist?: Maybe<Deal>;
  payoffRequestCancel?: Maybe<PayoffRequest>;
  payoffRequestConfirm?: Maybe<PayoffRequest>;
  payoffRequestFail?: Maybe<PayoffRequest>;
  payoffRequestFailureRequest?: Maybe<PayoffRequest>;
  payoffRequestUpdate?: Maybe<PayoffRequest>;
  payoffUpdate?: Maybe<Payoff>;
  podUpsert?: Maybe<Pod>;
  podUpsertAndPodUsersUpdate?: Maybe<BasePod>;
  processPaperwork?: Maybe<Deal>;
  registerDealer?: Maybe<Scalars['String']>;
  registerProduct?: Maybe<Scalars['String']>;
  removeDuplicateFinancialInfos?: Maybe<Scalars['Boolean']>;
  resendEmails?: Maybe<Scalars['Boolean']>;
  resetPasswordAndSendEmail?: Maybe<Scalars['Void']>;
  revivePod?: Maybe<Pod>;
  saveConsent: SaveConsent;
  saveNewCustomerCallOutcome?: Maybe<Call>;
  selectCreditDecision?: Maybe<SelectCreditDecision>;
  selectCreditDecisionV2?: Maybe<CreditDecision>;
  sendContracts?: Maybe<Scalars['Boolean']>;
  sendDocumentsWithAdobeAndUpdateState?: Maybe<Deal>;
  sendPaymentEstimateEmailAndText?: Maybe<Scalars['Boolean']>;
  setReadyForSignaturesStatus?: Maybe<Scalars['Boolean']>;
  skipTimelineStep?: Maybe<Deal>;
  softDeleteUser?: Maybe<Scalars['Boolean']>;
  ssnLogCreate?: Maybe<SsnLog>;
  storeSignatureData?: Maybe<Scalars['Boolean']>;
  submitCreditApplicationV2: CreditApplication;
  submitToRouteOne?: Maybe<Deal>;
  temporaryInfoImport?: Maybe<Deal>;
  temporaryInfoUpdate?: Maybe<TemporaryInfo>;
  temporaryInfoUpdateAndImport?: Maybe<TemporaryInfo>;
  unlinkTemporaryInfo?: Maybe<TemporaryInfo>;
  updateArticle?: Maybe<Article>;
  updateCar?: Maybe<Car>;
  updateCarRegistrationExpiration?: Maybe<Car>;
  updateCreditAppOnDeal?: Maybe<UpdateCreditAppOnDealResponse>;
  updateCreditApplicationV2: CreditApplication;
  updateCustomer?: Maybe<Customer>;
  updateCustomerAndAddress?: Maybe<Customer>;
  updateDealNeedsElectronicSignatureVerification?: Maybe<Deal>;
  updateDealV2: Deal;
  updateFinancialInfo?: Maybe<FinancialInfo>;
  updateInsuranceInfo?: Maybe<ProofOfInsurance>;
  updatePayoffClerk?: Maybe<Scalars['Boolean']>;
  updateProcessor?: Maybe<FinancialInfo>;
  updateProofOfInsurance?: Maybe<ProofOfInsurance>;
  updateReview?: Maybe<Review>;
  updateStructuringFollowUpNotes?: Maybe<Scalars['Boolean']>;
  updateSubscriptionToNotificationsStatus?: Maybe<NotificationSubscriber>;
  updateUser?: Maybe<User>;
  updateUserCallStatus?: Maybe<User>;
  updateUserSettings?: Maybe<User>;
  uploadDocument?: Maybe<ExtractedDocumentGql>;
  uploadDocumentToRouteOne?: Maybe<Scalars['Boolean']>;
  uploadDriversLicense?: Maybe<DriversLicenseGql>;
  vehicleInfoUpsert?: Maybe<Deal>;
  verifyAccount?: Maybe<Scalars['Void']>;
  voidContract?: Maybe<Scalars['Boolean']>;
};

export type RootMutationTypeAcquisitionDealInfoUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeAddDocumentsToMediaCenterArgs = {
  deal_id: Scalars['ID'];
  files?: InputMaybe<Array<InputMaybe<AddDocumentsToMediaCenterInput>>>;
};

export type RootMutationTypeAddLendersToCreditApplicationV2Args = {
  id: Scalars['ID'];
  lenderIds: Array<InputMaybe<Scalars['String']>>;
};

export type RootMutationTypeAddParticipantArgs = {
  agent_phone?: InputMaybe<Scalars['String']>;
  conference_command?: InputMaybe<Scalars['String']>;
  conference_id?: InputMaybe<Scalars['String']>;
  hunt_group_slug?: InputMaybe<Scalars['String']>;
  new_agent_phone?: InputMaybe<Scalars['String']>;
  new_agent_user_id?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeApplyDocumentSignatureArgs = {
  dealId: Scalars['ID'];
  dealJacketID: Scalars['String'];
  documentID: Scalars['String'];
  documentName: Scalars['String'];
  isCobuyer?: InputMaybe<Scalars['Boolean']>;
  isLastSignature?: InputMaybe<Scalars['Boolean']>;
  signatureData: Scalars['String'];
  signatureFieldName: Scalars['String'];
};

export type RootMutationTypeArchivePodArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RootMutationTypeAuctionUpsertArgs = {
  auction?: InputMaybe<AuctionInput>;
};

export type RootMutationTypeAutomateCreditApplicationArgs = {
  deal_id: Scalars['ID'];
};

export type RootMutationTypeBuyerCobuyerSwapArgs = {
  cobuyer_id: Scalars['ID'];
  customer_id: Scalars['ID'];
  deal_id: Scalars['ID'];
};

export type RootMutationTypeCallOutArgs = {
  calledNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeConditionReportUpsertArgs = {
  conditionReport?: InputMaybe<ConditionReportUpsertInput>;
};

export type RootMutationTypeCreateAffiliateTemporaryInfoArgs = {
  device_type?: InputMaybe<Scalars['String']>;
  query_params?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeCreateArticleArgs = {
  article?: InputMaybe<ArticleInput>;
};

export type RootMutationTypeCreateCreditApplicationV2Args = {
  input: CreditApplicationCreateInput;
};

export type RootMutationTypeCreateDealV2Args = {
  input: DealInput;
};

export type RootMutationTypeCreateExternalDealUploadUrlArgs = {
  dealId: Scalars['ID'];
  fileDescription: Scalars['String'];
  fileName: Scalars['String'];
};

export type RootMutationTypeCreateExternalTempInfoUploadUrlArgs = {
  fileDescription: Scalars['String'];
  fileName: Scalars['String'];
  guid: Scalars['ID'];
};

export type RootMutationTypeCreateFeedbackArgs = {
  deal_id: Scalars['ID'];
  email: Scalars['String'];
  improvements: Scalars['String'];
  name: Scalars['String'];
  rating: Scalars['Int'];
};

export type RootMutationTypeCreateJdpBookSheetArgs = {
  customerState: Scalars['String'];
  dealId: Scalars['ID'];
};

export type RootMutationTypeCreateKbbBookSheetArgs = {
  dealId: Scalars['ID'];
};

export type RootMutationTypeCreateManualEntryTemporaryInfoArgs = {
  device_type?: InputMaybe<Scalars['String']>;
  extra_info?: InputMaybe<ExtraTempInfoInputType>;
  fuel_type?: InputMaybe<Scalars['String']>;
  kbb_trim_name?: InputMaybe<Scalars['String']>;
  license_plate?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  query_params?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  vehicle_type?: InputMaybe<Scalars['String']>;
  vin: Scalars['String'];
  year?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeCreateNewEstimateAndSetFollowUpArgs = {
  address_line?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  date_utc: Scalars['DateTime'];
  email?: InputMaybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  no_email?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  phone_number: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeCreateNewEstimateFromFilesArgs = {
  data?: InputMaybe<TemporaryDataInput>;
  files?: InputMaybe<Array<InputMaybe<NewEstimateFileInput>>>;
};

export type RootMutationTypeCreateOptEventArgs = {
  event: OptEventInput;
  token?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeCreatePayoffRequestArgs = {
  deal_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeCreatePotentialUserArgs = {
  potential_user?: InputMaybe<PotentialUserInput>;
};

export type RootMutationTypeCreateReviewArgs = {
  review?: InputMaybe<ReviewInput>;
};

export type RootMutationTypeCreateStructuringFollowUpArgs = {
  dealId: Scalars['ID'];
  notes: Scalars['String'];
};

export type RootMutationTypeCreateTemporaryInfoArgs = {
  device_type?: InputMaybe<Scalars['String']>;
  extra_info?: InputMaybe<ExtraTempInfoInputType>;
  ignore_existing_deal?: InputMaybe<Scalars['Boolean']>;
  license_plate_number?: InputMaybe<Scalars['String']>;
  license_plate_state?: InputMaybe<Scalars['String']>;
  query_params?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeCreateTransactionArgs = {
  input?: InputMaybe<CreateTransactionInput>;
};

export type RootMutationTypeCreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type RootMutationTypeCreditAppUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeCustomDatesUpdateArgs = {
  date: Scalars['DateTime'];
  date_key: Scalars['String'];
  deal_id: Scalars['ID'];
};

export type RootMutationTypeCustomerInfoUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeDealAssignArgs = {
  allow_reassign?: InputMaybe<Scalars['Boolean']>;
  assignee_id?: InputMaybe<Scalars['String']>;
  deal_id: Scalars['ID'];
  deal_state?: InputMaybe<Scalars['String']>;
  role: Scalars['String'];
};

export type RootMutationTypeDealCompletedArgs = {
  id: Scalars['ID'];
  state: Scalars['String'];
};

export type RootMutationTypeDealDetailNetsuiteArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDealFinalizeAcquisitionArgs = {
  address_line: Scalars['String'];
  address_line_2?: InputMaybe<Scalars['String']>;
  cash_to_customer: Scalars['Float'];
  city: Scalars['String'];
  county: Scalars['String'];
  dob: Scalars['Date'];
  id: Scalars['ID'];
  mileage?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  vehicle_payoff?: InputMaybe<Scalars['Float']>;
  zip: Scalars['String'];
};

export type RootMutationTypeDealHasProblemSwitchArgs = {
  has_problem: Scalars['Boolean'];
  id: Scalars['ID'];
  note?: InputMaybe<NoteInput>;
};

export type RootMutationTypeDealInfoUpsertArgs = {
  car?: InputMaybe<CarInput>;
  customer?: InputMaybe<CustomerInput>;
  financialInfo?: InputMaybe<FinancialInfoInput>;
};

export type RootMutationTypeDealSeenArgs = {
  dealId: Scalars['ID'];
};

export type RootMutationTypeDealTagsUpsertArgs = {
  dealId: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
};

export type RootMutationTypeDealUnwoundArgs = {
  boot_reason: BootReasonInput;
  id: Scalars['ID'];
};

export type RootMutationTypeDealUpdateAssignmentsArgs = {
  closer2_id?: InputMaybe<Scalars['String']>;
  closer_id?: InputMaybe<Scalars['String']>;
  deal_source?: InputMaybe<Scalars['String']>;
  funding_clerk_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  pod_id?: InputMaybe<Scalars['ID']>;
  setter_id?: InputMaybe<Scalars['String']>;
  structuring_manager_id?: InputMaybe<Scalars['String']>;
  title_clerk2_id?: InputMaybe<Scalars['String']>;
  title_clerk_id?: InputMaybe<Scalars['String']>;
  titling_pod_id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<DealType>;
};

export type RootMutationTypeDealUpdateMissingRequiredDocumentsArgs = {
  id: Scalars['ID'];
  missing_required_external_documents?: InputMaybe<Scalars['Boolean']>;
  new_missing_required_external_documents: Scalars['Boolean'];
};

export type RootMutationTypeDealUpdateRequestBootArgs = {
  boot_deal?: InputMaybe<Scalars['Boolean']>;
  boot_reason: BootReasonInput;
  id: Scalars['ID'];
  partial_note?: InputMaybe<NoteInput>;
  request_boot: Scalars['Boolean'];
};

export type RootMutationTypeDealUpdateStateArgs = {
  clearSetDate?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  state?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeDealUpdateStateAndSendEmailsArgs = {
  contactType?: InputMaybe<ContactType>;
  date?: InputMaybe<Scalars['DateTime']>;
  dealIsSigningOnCom?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isR1ContractUploaded?: InputMaybe<Scalars['Boolean']>;
  isR1ContractValidated?: InputMaybe<Scalars['Boolean']>;
  state: Scalars['String'];
};

export type RootMutationTypeDealUpdateTypeArgs = {
  id: Scalars['ID'];
  type: DealType;
};

export type RootMutationTypeDeleteAndReplaceFilesArgs = {
  deal_id: Scalars['ID'];
  files_to_delete?: InputMaybe<Array<InputMaybe<AddDocumentsToMediaCenterInput>>>;
  files_to_upload?: InputMaybe<Array<InputMaybe<AddDocumentsToMediaCenterInput>>>;
};

export type RootMutationTypeDeleteArticleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeDeleteComFlowFileArgs = {
  key: Scalars['String'];
};

export type RootMutationTypeDeleteContractsArgs = {
  filePaths: Array<InputMaybe<Scalars['String']>>;
};

export type RootMutationTypeDeleteCreditApplicationV2Args = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteD2dDocumentArgs = {
  key: Scalars['String'];
};

export type RootMutationTypeDeleteDealV2Args = {
  id: Scalars['Int'];
};

export type RootMutationTypeDeleteReviewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeDeleteStructuringFollowUpArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDetermineApprovalMutationArgs = {
  data: DetermineApprovalInput;
};

export type RootMutationTypeDocumentsInfoUpdateArgs = {
  data: DocumentsInfoUpdateInput;
};

export type RootMutationTypeEditD2dDocumentNameArgs = {
  key: Scalars['String'];
  newKey: Scalars['String'];
};

export type RootMutationTypeEndSalesFlowArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
  importType: ImportType;
  reason: EndSalesFlowReasonEnum;
  tempInfoId?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeEstimateUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeExecuteCardDownPaymentArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  dealId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fraudSessionId?: InputMaybe<Scalars['String']>;
  idempotencyId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeExecuteDownPaymentArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  bank?: InputMaybe<Scalars['String']>;
  dealId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fraudSessionId?: InputMaybe<Scalars['String']>;
  idempotencyId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  plaidAccountId?: InputMaybe<Scalars['String']>;
  plaidToken?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeExecuteExternalSoftPullArgs = {
  devCreditScore?: InputMaybe<Scalars['Int']>;
  devOverride?: InputMaybe<Scalars['Boolean']>;
  isHardPull?: InputMaybe<Scalars['Boolean']>;
  paymentEstimateInput: PaymentEstimateInput;
};

export type RootMutationTypeExternalCustomerUpdateArgs = {
  customer?: InputMaybe<ExternalCustomerInput>;
  customerId: Scalars['ID'];
};

export type RootMutationTypeExternalUpdateDealArgs = {
  data?: InputMaybe<ExternalDealInputType>;
  dealId?: InputMaybe<Scalars['ID']>;
  tempInfoId?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeFileSendArgs = {
  dealPhoneNumber?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeFinalizeR1SigningArgs = {
  dealId: Scalars['ID'];
  dealJacketID: Scalars['String'];
};

export type RootMutationTypeFinancialInfoAcquisitionUpsertArgs = {
  car_id?: InputMaybe<Scalars['ID']>;
  clear_override?: InputMaybe<Scalars['Boolean']>;
  financialInfoAcquisition?: InputMaybe<FinancialInfoAcquisitionInput>;
  id: Scalars['ID'];
  mmr?: InputMaybe<Scalars['Float']>;
  override?: InputMaybe<Scalars['Boolean']>;
  state: Scalars['String'];
  vehicle_payoff?: InputMaybe<Scalars['Float']>;
};

export type RootMutationTypeFollowUpDeleteArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeFollowUpInsertArgs = {
  date_utc: Scalars['DateTime'];
  deal_id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  type: FollowUpTypeEnum;
  tz: Scalars['String'];
};

export type RootMutationTypeFollowUpUpdateArgs = {
  date_utc: Scalars['DateTime'];
  deal_id: Scalars['ID'];
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  type: FollowUpTypeEnum;
  tz: Scalars['String'];
};

export type RootMutationTypeGetMarketPrequalificationArgs = {
  addressLine?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  prequalId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeHangUpCallArgs = {
  conference_id: Scalars['String'];
};

export type RootMutationTypeImportDealArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeLinkAccountArgs = {
  deal_id?: InputMaybe<Scalars['ID']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']>;
  ssn_last_4?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeLogSigningErrorArgs = {
  error: SigningErrorInput;
};

export type RootMutationTypeLogViewedDocumentEventArgs = {
  documentViewedEvent: SigningAuditDocumentViewedEvent;
};

export type RootMutationTypeManuallySelectBankArgs = {
  bank: Scalars['String'];
  dealId: Scalars['ID'];
};

export type RootMutationTypeMediaDeleteArgs = {
  filePath: Scalars['String'];
};

export type RootMutationTypeMediaDeleteFromComArgs = {
  dealId: Scalars['ID'];
  filePath: Scalars['String'];
};

export type RootMutationTypeMediaInsertArgs = {
  dealId: Scalars['ID'];
  key: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSONObject']>;
  type?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeMediaInsertComArgs = {
  dealId: Scalars['ID'];
  key: Scalars['String'];
  type: Scalars['String'];
};

export type RootMutationTypeMediaRenameArgs = {
  dealId: Scalars['ID'];
  fileName: Scalars['String'];
  filePath: Scalars['String'];
};

export type RootMutationTypeMediaUpdateArgs = {
  dealId: Scalars['ID'];
  has_verified_digital_signature?: InputMaybe<Scalars['Boolean']>;
  has_wet_signature?: InputMaybe<Scalars['Boolean']>;
  is_notarized?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  newFileName?: InputMaybe<Scalars['String']>;
  oldKey: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type RootMutationTypeMediaUpdateComArgs = {
  dealId: Scalars['ID'];
  newFileName?: InputMaybe<Scalars['String']>;
  oldKey: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeMergeCreditApplicationV2Args = {
  id: Scalars['ID'];
  imported_date_utc?: InputMaybe<Scalars['DateTime']>;
  set_date?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<DealStateEnumV2>;
};

export type RootMutationTypeMergeFilesArgs = {
  deal_id: Scalars['ID'];
  docs?: InputMaybe<Array<InputMaybe<DealMediaInput>>>;
  missing_required_media_list?: InputMaybe<Array<InputMaybe<DealMediaTypeEnum>>>;
};

export type RootMutationTypeMessageSendArgs = {
  dealPhoneNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeMockDealProcessingArgs = {
  deal_id: Scalars['ID'];
  has_gap: Scalars['Boolean'];
  has_vsc: Scalars['Boolean'];
  is_conditioned: Scalars['Boolean'];
};

export type RootMutationTypeNewEstimateUpsertArgs = {
  newEstimateUpsertInput?: InputMaybe<NewEstimateUpsertInput>;
};

export type RootMutationTypeNoteCreateArgs = {
  note?: InputMaybe<NoteInput>;
};

export type RootMutationTypeNoteDeleteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeNoteUpdateArgs = {
  note?: InputMaybe<NoteInput>;
};

export type RootMutationTypeNotificationSeenArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
  notificationId: Scalars['ID'];
  seen: Scalars['Boolean'];
};

export type RootMutationTypeNotificationsSeenArgs = {
  dealId: Scalars['ID'];
  notificationIds: Array<InputMaybe<Scalars['ID']>>;
};

export type RootMutationTypeOptOutArgs = {
  deal_id?: InputMaybe<Scalars['ID']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']>;
};

export type RootMutationTypeOverrideCalledByFinancialSpecialistArgs = {
  dealId: Scalars['ID'];
};

export type RootMutationTypePayoffRequestCancelArgs = {
  payoffRequestId?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypePayoffRequestConfirmArgs = {
  payoffRequest: PayoffRequestConfirmInput;
};

export type RootMutationTypePayoffRequestFailArgs = {
  payoffRequest: PayoffRequestInput;
};

export type RootMutationTypePayoffRequestFailureRequestArgs = {
  payoffRequest: PayoffRequestInput;
};

export type RootMutationTypePayoffRequestUpdateArgs = {
  payoffRequest: PayoffRequestInput;
};

export type RootMutationTypePayoffUpdateArgs = {
  payoffInfo?: InputMaybe<PayoffInput>;
};

export type RootMutationTypePodUpsertArgs = {
  pod?: InputMaybe<BasePodInput>;
};

export type RootMutationTypePodUpsertAndPodUsersUpdateArgs = {
  newPod: PodInput;
  oldPod?: InputMaybe<PodInput>;
};

export type RootMutationTypeProcessPaperworkArgs = {
  id: Scalars['ID'];
  paperwork_type: Scalars['String'];
  state: Scalars['String'];
};

export type RootMutationTypeRegisterDealerArgs = {
  dealer?: InputMaybe<DealerInput>;
};

export type RootMutationTypeRegisterProductArgs = {
  dealer_id: Scalars['ID'];
  product_id: Scalars['ID'];
  provider_dealer_code: Scalars['String'];
};

export type RootMutationTypeResendEmailsArgs = {
  dealId: Scalars['ID'];
};

export type RootMutationTypeResetPasswordAndSendEmailArgs = {
  access_token?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeRevivePodArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RootMutationTypeSaveConsentArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<ConsentNameEnumType>;
  version?: InputMaybe<Scalars['Int']>;
};

export type RootMutationTypeSaveNewCustomerCallOutcomeArgs = {
  call_id: Scalars['String'];
  outcome?: InputMaybe<Scalars['String']>;
  outcome_description?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeSelectCreditDecisionArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeSelectCreditDecisionV2Args = {
  amountFinanced: Scalars['Float'];
  id: Scalars['ID'];
  moneyDown: Scalars['Float'];
  sellRate: Scalars['Float'];
};

export type RootMutationTypeSendContractsArgs = {
  deal_id: Scalars['ID'];
  keys: Array<InputMaybe<Scalars['String']>>;
};

export type RootMutationTypeSendDocumentsWithAdobeAndUpdateStateArgs = {
  deal_id: Scalars['ID'];
  keys: Array<InputMaybe<Scalars['String']>>;
  state: DealStateEnum;
};

export type RootMutationTypeSendPaymentEstimateEmailAndTextArgs = {
  data?: InputMaybe<PaymentEstimateInput>;
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeSetReadyForSignaturesStatusArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
  documentProgressStatus?: InputMaybe<DocumentProgressStatus>;
  jacketId?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeSkipTimelineStepArgs = {
  documentProgressStatus?: InputMaybe<DocumentProgressStatus>;
  jacketId?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeSoftDeleteUserArgs = {
  id: Scalars['String'];
};

export type RootMutationTypeSsnLogCreateArgs = {
  log?: InputMaybe<SsnLogInput>;
};

export type RootMutationTypeStoreSignatureDataArgs = {
  customer_id: Scalars['ID'];
  initials_data_url?: InputMaybe<Scalars['String']>;
  initials_strokes?: InputMaybe<Scalars['String']>;
  signature_data_url?: InputMaybe<Scalars['String']>;
  signature_strokes?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeSubmitCreditApplicationV2Args = {
  id: Scalars['ID'];
  lenderIds: Array<InputMaybe<Scalars['String']>>;
};

export type RootMutationTypeSubmitToRouteOneArgs = {
  deal?: InputMaybe<DealInput>;
  lenderIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RootMutationTypeTemporaryInfoImportArgs = {
  id: Scalars['ID'];
  importType?: InputMaybe<ImportType>;
  transformType: TransformType;
};

export type RootMutationTypeTemporaryInfoUpdateArgs = {
  info?: InputMaybe<TemporaryInfoInput>;
};

export type RootMutationTypeTemporaryInfoUpdateAndImportArgs = {
  info?: InputMaybe<TemporaryInfoInput>;
  transformType?: InputMaybe<TransformType>;
};

export type RootMutationTypeUnlinkTemporaryInfoArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeUpdateArticleArgs = {
  article?: InputMaybe<ArticleInput>;
};

export type RootMutationTypeUpdateCarArgs = {
  car?: InputMaybe<CarInput>;
};

export type RootMutationTypeUpdateCarRegistrationExpirationArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
  registration_expiration?: InputMaybe<Scalars['DateTime']>;
};

export type RootMutationTypeUpdateCreditAppOnDealArgs = {
  input: CreditAppInput;
};

export type RootMutationTypeUpdateCreditApplicationV2Args = {
  input: CreditApplicationUpdateInput;
};

export type RootMutationTypeUpdateCustomerArgs = {
  customer?: InputMaybe<CustomerInput>;
};

export type RootMutationTypeUpdateCustomerAndAddressArgs = {
  customer?: InputMaybe<CustomerInput>;
};

export type RootMutationTypeUpdateDealNeedsElectronicSignatureVerificationArgs = {
  deal_id: Scalars['ID'];
  needs_electronic_signature_verification: Scalars['Boolean'];
};

export type RootMutationTypeUpdateDealV2Args = {
  input: DealInput;
};

export type RootMutationTypeUpdateFinancialInfoArgs = {
  input: UpdateFinancialInfoInput;
};

export type RootMutationTypeUpdateInsuranceInfoArgs = {
  expiration_date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  insurance_company?: InputMaybe<Scalars['String']>;
  policy_no?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdatePayoffClerkArgs = {
  payoff_clerk_id?: InputMaybe<Scalars['String']>;
  payoff_request_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUpdateProcessorArgs = {
  deal_id: Scalars['ID'];
  fi_id: Scalars['ID'];
  processor?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateProofOfInsuranceArgs = {
  customer_id: Scalars['ID'];
  proof_of_insurance?: InputMaybe<ProofOfInsuranceInput>;
};

export type RootMutationTypeUpdateReviewArgs = {
  review?: InputMaybe<ReviewInput>;
};

export type RootMutationTypeUpdateStructuringFollowUpNotesArgs = {
  id: Scalars['ID'];
  notes: Scalars['String'];
};

export type RootMutationTypeUpdateSubscriptionToNotificationsStatusArgs = {
  dealId: Scalars['ID'];
  subscribeToNotifications: Scalars['Boolean'];
};

export type RootMutationTypeUpdateUserArgs = {
  allAuth0RoleIds: Array<Scalars['String']>;
  updateUserInput: UpdateUserInput;
};

export type RootMutationTypeUpdateUserCallStatusArgs = {
  status: Scalars['String'];
};

export type RootMutationTypeUpdateUserSettingsArgs = {
  auto_assign_deals: Scalars['Boolean'];
  hours: Scalars['String'];
  id: Scalars['String'];
  max_auto_assign_deals: Scalars['Int'];
  on_vacation: Scalars['Boolean'];
  overnight_deals: Scalars['Boolean'];
};

export type RootMutationTypeUploadDocumentArgs = {
  bytes?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<TtDocumentName>;
};

export type RootMutationTypeUploadDocumentToRouteOneArgs = {
  doc?: InputMaybe<UploadDocumentsInput>;
  jacketId?: InputMaybe<Scalars['String']>;
  withCobuyer?: InputMaybe<Scalars['Boolean']>;
};

export type RootMutationTypeUploadDriversLicenseArgs = {
  bytes?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeVehicleInfoUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeVerifyAccountArgs = {
  deal_id?: InputMaybe<Scalars['ID']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']>;
  ssn_last_4?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeVoidContractArgs = {
  deal_id: Scalars['ID'];
  void_gap?: InputMaybe<Scalars['Boolean']>;
  void_vsc?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  acquisitionOffer?: Maybe<AcquisitionOffer>;
  addressDetail?: Maybe<Address>;
  affiliateDetails?: Maybe<Affiliate>;
  articleImageUploadUrl?: Maybe<ArticleImageUploadUrl>;
  articles?: Maybe<Array<Maybe<Article>>>;
  auctionAverageTransportCost?: Maybe<Scalars['Int']>;
  auctionDistances?: Maybe<AuctionDistances>;
  auctions?: Maybe<Array<Maybe<Auction>>>;
  bankInfo?: Maybe<Bank>;
  bankLogo?: Maybe<Scalars['String']>;
  banks?: Maybe<Array<Maybe<Bank>>>;
  bootedDeals?: Maybe<Array<Maybe<Deal>>>;
  calculateOptions?: Maybe<Array<Maybe<KbbOption>>>;
  callInProgress?: Maybe<Conference>;
  carDetailLicensePlate?: Maybe<Car>;
  carDetailVin?: Maybe<Car>;
  checkExistingAccountByEmail?: Maybe<Scalars['Boolean']>;
  checkFlowStatus?: Maybe<FlowStatus>;
  completedDeals?: Maybe<Array<Maybe<Deal>>>;
  createFetchUrlsFromCom: Array<Scalars['String']>;
  creditApplicationSubmissions: Array<Maybe<CreditApplicationSubmission>>;
  creditApplicationV2?: Maybe<CreditApplication>;
  creditDecisionV2?: Maybe<CreditDecision>;
  currentConsentAgreementText?: Maybe<ConsentAgreement>;
  customer?: Maybe<Customer>;
  dashboardDeals?: Maybe<Array<Maybe<Deal>>>;
  deal?: Maybe<Deal>;
  dealAdvisors?: Maybe<Deal>;
  dealStates?: Maybe<Array<Maybe<DealState>>>;
  dealV2?: Maybe<Deal>;
  dealerProducts?: Maybe<Array<Maybe<Product>>>;
  dealsV2: DealsConnection;
  dealsWithNotification?: Maybe<DealsWithNotification>;
  decodeAffiliateCarDetails?: Maybe<TemporaryData>;
  decryptSSN?: Maybe<Scalars['String']>;
  determineApprovalQuery?: Maybe<DetermineApproval>;
  duplicateDeals?: Maybe<Array<Maybe<Deal>>>;
  experianCreditReport?: Maybe<PrequalificationCreditReports>;
  fetchProofOfInsurance?: Maybe<ProofOfInsurance>;
  followUp?: Maybe<FollowUp>;
  gapRates?: Maybe<Array<Maybe<Rate>>>;
  generateContracts?: Maybe<Contracts>;
  generateDocuments?: Maybe<Documents>;
  generatePurchaseOrder?: Maybe<PurchaseOrder>;
  generateR1EContract: Scalars['Boolean'];
  generateTestDocuments?: Maybe<Scalars['Boolean']>;
  getAllHuntGroups?: Maybe<Array<Maybe<HuntGroup>>>;
  getApprovedCreditDecisionsByDealId?: Maybe<Array<Maybe<CreditDecision>>>;
  getAuth0Roles?: Maybe<Array<Auth0Role>>;
  getAvailableUsers?: Maybe<Array<Maybe<GetAvailableUsers>>>;
  getBase64StringFromS3Object?: Maybe<Scalars['String']>;
  getByAuth0Id?: Maybe<Customer>;
  getComPackageOptions?: Maybe<GetPackageOptions>;
  getCreditDecisionByR1FSID?: Maybe<CreditDecision>;
  getD2dDocuments?: Maybe<Array<Maybe<D2dDocument>>>;
  getDDSoftPull: DdSoftPull;
  getDownPaymentAmountByMethod?: Maybe<Scalars['Float']>;
  getExternalAutoStructureBankLogos?: Maybe<Array<Maybe<AutoStructureBankLogo>>>;
  getFees?: Maybe<FeeGql>;
  getFollowUpDealsPaginated?: Maybe<PaginatedDeals>;
  getOperatingHoursAndTimeSlots?: Maybe<OperatingHoursTimeSlots>;
  getR1CustomerCopy?: Maybe<Array<Maybe<DealDocument>>>;
  getR1DealDocument?: Maybe<DealDocument>;
  getTwilioNumbers?: Maybe<Array<Maybe<TwilioNumber>>>;
  getUnlinkedDeals?: Maybe<Array<Maybe<Deal>>>;
  getUploadUrl?: Maybe<MediaUploadUrl>;
  getUserCallHistory?: Maybe<CallsPagination>;
  getUserCreationData?: Maybe<UserCreationData>;
  getUserTwilioNumber?: Maybe<Scalars['String']>;
  getUsers?: Maybe<PaginatedUser>;
  getWaitingCalls?: Maybe<GetWaitingCalls>;
  getWarrantyInfo?: Maybe<CarWarranty>;
  inbox?: Maybe<InboxMessagesType>;
  jdpValues?: Maybe<Car>;
  jurisdiction?: Maybe<TtJurisdiction>;
  kbbValues?: Maybe<KbbValuesResult>;
  kbbVin?: Maybe<KbbVinObjectResult>;
  keyMetrics?: Maybe<Array<Maybe<KeyMetric>>>;
  laneKeyMetrics?: Maybe<Array<Maybe<LaneKeyMetric>>>;
  leaderboardSetter: Array<LeaderboardSetterStats>;
  lenders?: Maybe<Array<Maybe<Lender>>>;
  lienholderUtility?: Maybe<Array<Maybe<Scalars['String']>>>;
  loggedUser?: Maybe<User>;
  media?: Maybe<Array<Maybe<DealMedia>>>;
  mediaFromCom?: Maybe<Array<DealMedia>>;
  messages?: Maybe<MessagesWithErrors>;
  messagesDbPaginated?: Maybe<MessagesDbPaginated>;
  metricsPages?: Maybe<Array<Maybe<MetricsPages>>>;
  mmr?: Maybe<MmrResult>;
  notes?: Maybe<Array<Maybe<Note>>>;
  paymentEstimate?: Maybe<PaymentEstimate>;
  paymentOptions?: Maybe<PaymentOptions>;
  payoffDashboardDeals?: Maybe<PayoffDashboard>;
  payoffRequest?: Maybe<PayoffRequest>;
  pod?: Maybe<Pod>;
  podHours?: Maybe<Array<Maybe<PodHours>>>;
  pods?: Maybe<Array<Maybe<Pod>>>;
  podsAll?: Maybe<Array<Maybe<Pod>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  providers?: Maybe<Array<Maybe<Provider>>>;
  retrieveDealDocsUploadedToR1?: Maybe<Array<Maybe<DealDocument>>>;
  reviews?: Maybe<Array<Maybe<Review>>>;
  searchDeals?: Maybe<Array<Deal>>;
  searchWebLeads?: Maybe<Array<Maybe<TemporaryInfo>>>;
  sendPurchaseOrder?: Maybe<Scalars['Boolean']>;
  startR1DocumentsFlow?: Maybe<Scalars['Boolean']>;
  structuringFollowUp?: Maybe<StructuringFollowUp>;
  structuringFollowUpDeals?: Maybe<PaginatedDeals>;
  surcharges?: Maybe<Array<Maybe<Surcharge>>>;
  tags: Array<Tag>;
  templateTexts?: Maybe<Array<Maybe<TemplateText>>>;
  temporaryInfo?: Maybe<TemporaryInfo>;
  temporaryInfoAuthenticated?: Maybe<TemporaryInfo>;
  temporaryRegistrationTagsDeals?: Maybe<PaginatedDeals>;
  titlingDashboardDeals?: Maybe<PaginatedDeals>;
  titlingPodsAll?: Maybe<Array<Maybe<Pod>>>;
  titlingUsers?: Maybe<Array<Maybe<PodUser>>>;
  totalCompletedDeals?: Maybe<Scalars['Int']>;
  userDeal?: Maybe<Deal>;
  userDealReady?: Maybe<DealReadyType>;
  userDeals?: Maybe<Array<Maybe<Deals>>>;
  userPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  userTitlingPods?: Maybe<Array<Maybe<Pod>>>;
  validZip?: Maybe<Scalars['Boolean']>;
  validateBankContract?: Maybe<Scalars['Boolean']>;
  validateCreditApplicationV2?: Maybe<Array<Maybe<Scalars['String']>>>;
  vehicleMileage?: Maybe<VehicleMileageGql>;
  visibleArticles?: Maybe<VisibleArticles>;
  visibleReviews?: Maybe<Array<Maybe<Review>>>;
  vscRates?: Maybe<Array<Maybe<Rate>>>;
};

export type RootQueryTypeAcquisitionOfferArgs = {
  accidents: AccidentsEnumType;
  book_value?: InputMaybe<Scalars['Int']>;
  lienholder?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  mmr_value?: InputMaybe<Scalars['Float']>;
  vehicle_type?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAddressDetailArgs = {
  address?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAffiliateDetailsArgs = {
  affiliateExternalId?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAuctionAverageTransportCostArgs = {
  customerAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAuctionDistancesArgs = {
  data?: InputMaybe<AuctionDistancesInput>;
};

export type RootQueryTypeBankInfoArgs = {
  deal_id: Scalars['ID'];
};

export type RootQueryTypeBankLogoArgs = {
  bank_name: Scalars['String'];
};

export type RootQueryTypeBanksArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  county?: InputMaybe<Scalars['String']>;
  selectedBank?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeBootedDealsArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']>;
  end_date: Scalars['DateTime'];
  pod_id?: InputMaybe<Scalars['ID']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']>;
  sources: Array<InputMaybe<Scalars['String']>>;
  start_date: Scalars['DateTime'];
  types: Array<InputMaybe<DealType>>;
  unread_messages_filter?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryTypeCalculateOptionsArgs = {
  allKbbVehicleOptions?: InputMaybe<Array<InputMaybe<KbbOptionInput>>>;
  color?: InputMaybe<Scalars['String']>;
  configToModify?: InputMaybe<Array<InputMaybe<KbbConfigType>>>;
  startingVehicleOptions?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  vehicleId?: InputMaybe<Scalars['Int']>;
  vin: Scalars['String'];
};

export type RootQueryTypeCarDetailLicensePlateArgs = {
  license_plate_number: Scalars['String'];
  license_plate_state: Scalars['String'];
};

export type RootQueryTypeCarDetailVinArgs = {
  vin?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeCheckExistingAccountByEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeCheckFlowStatusArgs = {
  guid?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeCompletedDealsArgs = {
  address_state?: InputMaybe<Scalars['String']>;
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']>;
  boot_requested_filter?: InputMaybe<Scalars['Boolean']>;
  deal_state?: InputMaybe<Scalars['String']>;
  end_date: Scalars['DateTime'];
  notifications?: InputMaybe<Scalars['Boolean']>;
  pod_id?: InputMaybe<Scalars['ID']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']>;
  sources: Array<InputMaybe<Scalars['String']>>;
  start_date: Scalars['DateTime'];
  types: Array<InputMaybe<DealType>>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeCreateFetchUrlsFromComArgs = {
  keys: Array<InputMaybe<Scalars['String']>>;
};

export type RootQueryTypeCreditApplicationSubmissionsArgs = {
  filter?: InputMaybe<CreditApplicationSubmissionFilter>;
};

export type RootQueryTypeCreditApplicationV2Args = {
  id: Scalars['ID'];
};

export type RootQueryTypeCreditDecisionV2Args = {
  id: Scalars['Int'];
};

export type RootQueryTypeCurrentConsentAgreementTextArgs = {
  agreementType?: InputMaybe<ConsentAgreementTypeEnum>;
  version?: InputMaybe<Scalars['Int']>;
};

export type RootQueryTypeCustomerArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeDashboardDealsArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']>;
  boot_requested_filter?: InputMaybe<Scalars['Boolean']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pod_id?: InputMaybe<Scalars['ID']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']>;
  showFollowUps?: InputMaybe<Scalars['Boolean']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']>;
  sources: Array<InputMaybe<Scalars['String']>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  types: Array<InputMaybe<DealType>>;
  unread_messages_filter?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeDealArgs = {
  id: Scalars['ID'];
  sources: Array<InputMaybe<Scalars['String']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeDealAdvisorsArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeDealStatesArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeDealV2Args = {
  id?: InputMaybe<Scalars['Int']>;
  leaseId?: InputMaybe<Scalars['GUID']>;
};

export type RootQueryTypeDealerProductsArgs = {
  dealer_id: Scalars['ID'];
};

export type RootQueryTypeDealsV2Args = {
  filter?: InputMaybe<DealsFilter>;
  pagination?: InputMaybe<PaginationInputType>;
  sorting?: InputMaybe<SortingTypeV2>;
};

export type RootQueryTypeDealsWithNotificationArgs = {
  pod_id?: InputMaybe<Scalars['ID']>;
  sources: Array<InputMaybe<Scalars['String']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeDecodeAffiliateCarDetailsArgs = {
  license_plate_number?: InputMaybe<Scalars['String']>;
  license_plate_state?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeDecryptSsnArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeDetermineApprovalQueryArgs = {
  data?: InputMaybe<DetermineApprovalQueryInput>;
};

export type RootQueryTypeDuplicateDealsArgs = {
  first_name?: InputMaybe<Scalars['String']>;
  home_phone_number?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeExperianCreditReportArgs = {
  dealId: Scalars['ID'];
};

export type RootQueryTypeFetchProofOfInsuranceArgs = {
  customer_id: Scalars['ID'];
};

export type RootQueryTypeFollowUpArgs = {
  deal_id: Scalars['ID'];
};

export type RootQueryTypeGapRatesArgs = {
  bank?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<RateInput>;
  deal_id: Scalars['ID'];
};

export type RootQueryTypeGenerateContractsArgs = {
  data?: InputMaybe<ContractsInput>;
};

export type RootQueryTypeGenerateDocumentsArgs = {
  data?: InputMaybe<DocumentsInput>;
};

export type RootQueryTypeGeneratePurchaseOrderArgs = {
  deal_id: Scalars['ID'];
};

export type RootQueryTypeGenerateR1EContractArgs = {
  dealJacketId: Scalars['String'];
};

export type RootQueryTypeGenerateTestDocumentsArgs = {
  update_deal_id: Scalars['ID'];
};

export type RootQueryTypeGetAllHuntGroupsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeGetApprovedCreditDecisionsByDealIdArgs = {
  dealId: Scalars['ID'];
};

export type RootQueryTypeGetBase64StringFromS3ObjectArgs = {
  key: Scalars['String'];
};

export type RootQueryTypeGetComPackageOptionsArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeGetCreditDecisionByR1FsidArgs = {
  deal_id: Scalars['ID'];
  r1_fsid: Scalars['String'];
};

export type RootQueryTypeGetDdSoftPullArgs = {
  applicationId?: InputMaybe<Scalars['ID']>;
  dealId?: InputMaybe<Scalars['ID']>;
  paymentEstimateInput: PaymentEstimateInput;
  shouldForceRetry?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryTypeGetDownPaymentAmountByMethodArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
  paymentType?: InputMaybe<PaymentInstrumentType>;
};

export type RootQueryTypeGetFeesArgs = {
  address?: InputMaybe<Scalars['String']>;
  bookValue?: InputMaybe<Scalars['Float']>;
  city?: InputMaybe<Scalars['String']>;
  cobuyerAddress?: InputMaybe<Scalars['String']>;
  cobuyerCity?: InputMaybe<Scalars['String']>;
  cobuyerFirstName?: InputMaybe<Scalars['String']>;
  cobuyerLastName?: InputMaybe<Scalars['String']>;
  cobuyerMiddleName?: InputMaybe<Scalars['String']>;
  cobuyerState?: InputMaybe<StateAbbreviation>;
  cobuyerZip?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  dealId?: InputMaybe<Scalars['String']>;
  docFee?: InputMaybe<Scalars['Float']>;
  estimatedPayoff?: InputMaybe<Scalars['Float']>;
  financialInfoId?: InputMaybe<Scalars['ID']>;
  firstName?: InputMaybe<Scalars['String']>;
  fuelType?: InputMaybe<TtFuelType>;
  lastName?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  newBank?: InputMaybe<Scalars['String']>;
  newBankAddress?: InputMaybe<Scalars['String']>;
  newBankAddress2?: InputMaybe<Scalars['String']>;
  newBankCity?: InputMaybe<Scalars['String']>;
  newBankState?: InputMaybe<StateAbbreviation>;
  newBankZip?: InputMaybe<Scalars['String']>;
  newBankZipPlus4?: InputMaybe<Scalars['String']>;
  odometerReading?: InputMaybe<Scalars['Int']>;
  payoff?: InputMaybe<Scalars['Float']>;
  previouslyTitledState?: InputMaybe<StateAbbreviation>;
  retailBookValue?: InputMaybe<Scalars['Float']>;
  signedDate?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateAbbreviation>;
  transferringPlates?: InputMaybe<Scalars['Boolean']>;
  uniqueId?: InputMaybe<Scalars['String']>;
  vehicleType?: InputMaybe<TtVehicleType>;
  vin?: InputMaybe<Scalars['String']>;
  warranty?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeGetFollowUpDealsPaginatedArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']>;
  boot_requested?: InputMaybe<Scalars['Boolean']>;
  notifications?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pod_id?: InputMaybe<Scalars['ID']>;
  results_per_page?: InputMaybe<Scalars['Int']>;
  scheduledByMeFilter?: InputMaybe<Scalars['Boolean']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']>;
  sort_column: Scalars['String'];
  sort_direction: Scalars['String'];
  sources: Array<InputMaybe<Scalars['String']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeGetR1CustomerCopyArgs = {
  dealJacketID: Scalars['String'];
  includeExtraPDFBinaries?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryTypeGetR1DealDocumentArgs = {
  dealJacketID: Scalars['String'];
  documentID: Scalars['String'];
  includePdfBinary?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryTypeGetUploadUrlArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
  fileName: Scalars['String'];
  useD2dDocumentCenterBucket?: InputMaybe<Scalars['Boolean']>;
  useD2dVoiceRecordingsBucket?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryTypeGetUserCallHistoryArgs = {
  items_per_page?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type RootQueryTypeGetUsersArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  podId?: InputMaybe<Scalars['ID']>;
  roleNames?: InputMaybe<Array<InputMaybe<Auth0RoleName>>>;
  searchText?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  teamType?: InputMaybe<TeamType>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RootQueryTypeGetWarrantyInfoArgs = {
  make: Scalars['String'];
  mileage: Scalars['Int'];
  year: Scalars['String'];
};

export type RootQueryTypeInboxArgs = {
  filterBy?: InputMaybe<InboxFilterByEnum>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InboxOrderByEnum>;
  page?: InputMaybe<Scalars['Int']>;
  podIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  unreadOnly?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryTypeJdpValuesArgs = {
  mileage: Scalars['Float'];
  stateCode?: InputMaybe<Scalars['String']>;
  vin: Scalars['String'];
};

export type RootQueryTypeJurisdictionArgs = {
  id?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateAbbreviation>;
};

export type RootQueryTypeKbbValuesArgs = {
  data: KbbValuesDataType;
};

export type RootQueryTypeKbbVinArgs = {
  vin: Scalars['String'];
};

export type RootQueryTypeKeyMetricsArgs = {
  end_date: Scalars['DateTime'];
  pod_ids: Array<InputMaybe<Scalars['String']>>;
  start_date: Scalars['DateTime'];
};

export type RootQueryTypeLaneKeyMetricsArgs = {
  podId?: InputMaybe<Scalars['ID']>;
  sources: Array<InputMaybe<Scalars['String']>>;
  today?: InputMaybe<Scalars['DateTime']>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeLeaderboardSetterArgs = {
  after: Scalars['DateTime'];
  before: Scalars['DateTime'];
  teamType?: InputMaybe<TeamType>;
};

export type RootQueryTypeLendersArgs = {
  query: Scalars['String'];
};

export type RootQueryTypeLienholderUtilityArgs = {
  make?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeMediaArgs = {
  dealId: Scalars['ID'];
};

export type RootQueryTypeMediaFromComArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeMessagesArgs = {
  dealPhoneNumber?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeMessagesDbPaginatedArgs = {
  dealId: Scalars['String'];
  dealPhoneNumber: Scalars['String'];
  page: Scalars['Int'];
};

export type RootQueryTypeMmrArgs = {
  color: Scalars['String'];
  grade: Scalars['Float'];
  mileage: Scalars['Int'];
  state: Scalars['String'];
  trim: Scalars['String'];
  vin: Scalars['String'];
};

export type RootQueryTypeNotesArgs = {
  deal_id: Scalars['ID'];
};

export type RootQueryTypePaymentEstimateArgs = {
  data?: InputMaybe<PaymentEstimateInput>;
};

export type RootQueryTypePaymentOptionsArgs = {
  data?: InputMaybe<PaymentOptionsInput>;
};

export type RootQueryTypePayoffRequestArgs = {
  deal_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypePodArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypePodsArgs = {
  hideArchived?: InputMaybe<Scalars['Boolean']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pod_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  team_type?: InputMaybe<Array<InputMaybe<TeamType>>>;
  userId?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeProductsArgs = {
  provider_id: Scalars['ID'];
};

export type RootQueryTypeRetrieveDealDocsUploadedToR1Args = {
  dealJacketID: Scalars['String'];
  includePdfBinary?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryTypeSearchDealsArgs = {
  query: Scalars['String'];
};

export type RootQueryTypeSearchWebLeadsArgs = {
  query: Scalars['String'];
};

export type RootQueryTypeSendPurchaseOrderArgs = {
  deal_id: Scalars['ID'];
  key?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeStartR1DocumentsFlowArgs = {
  dealId: Scalars['ID'];
};

export type RootQueryTypeStructuringFollowUpArgs = {
  dealId: Scalars['ID'];
};

export type RootQueryTypeStructuringFollowUpDealsArgs = {
  assignedToMe?: InputMaybe<Scalars['Boolean']>;
  bootRequested?: InputMaybe<Scalars['Boolean']>;
  dealTypes: Array<InputMaybe<DealType>>;
  notifications?: InputMaybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  podId?: InputMaybe<Scalars['ID']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']>;
  sortColumn: StructuringFollowUpSortColumn;
  sortDirection: StructuringFollowUpSortDirection;
  sources: Array<InputMaybe<Scalars['String']>>;
};

export type RootQueryTypeSurchargesArgs = {
  product_id: Scalars['Int'];
};

export type RootQueryTypeTagsArgs = {
  input?: InputMaybe<TagsInput>;
};

export type RootQueryTypeTemporaryInfoArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeTemporaryInfoAuthenticatedArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeTemporaryRegistrationTagsDealsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type RootQueryTypeTitlingDashboardDealsArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<FilterType>;
  includeTitleNotes?: InputMaybe<Scalars['Boolean']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pod_id?: InputMaybe<Scalars['ID']>;
  sorting?: InputMaybe<SortingType>;
  sources: Array<InputMaybe<Scalars['String']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeTitlingUsersArgs = {
  titling_pod_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeUserDealArgs = {
  id?: InputMaybe<Scalars['ID']>;
  isCobuyer?: InputMaybe<Scalars['Boolean']>;
  tempInfoId?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeUserDealReadyArgs = {
  tempInfoId: Scalars['ID'];
};

export type RootQueryTypeUserPermissionsArgs = {
  user_id?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeValidZipArgs = {
  zipCode: Scalars['String'];
};

export type RootQueryTypeValidateBankContractArgs = {
  deal_id: Scalars['ID'];
};

export type RootQueryTypeValidateCreditApplicationV2Args = {
  id: Scalars['ID'];
};

export type RootQueryTypeVehicleMileageArgs = {
  vin?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeVisibleArticlesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type RootQueryTypeVscRatesArgs = {
  data?: InputMaybe<RateInput>;
  deal_id: Scalars['ID'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  inProgressCalls?: Maybe<Array<Maybe<Conference>>>;
  onContractValidation?: Maybe<Array<Maybe<Deal>>>;
  onDashboardDocumentUpload?: Maybe<DashboardDocumentUpload>;
  onDealComplete?: Maybe<Scalars['Boolean']>;
  onDealImported?: Maybe<DealImported>;
  onDealUpdate?: Maybe<Array<Maybe<Deal>>>;
  onDownPaymentReceived?: Maybe<DownPaymentReceived>;
  onFollowUpUpdate?: Maybe<Array<Maybe<Deal>>>;
  onNewMessage?: Maybe<Message>;
  onNoDealNotification?: Maybe<Notification>;
  onPayoffRequestStatusUpdate?: Maybe<PayoffRequestStatusUpdate>;
  onPayoffRequestUpdate?: Maybe<PayoffRequestPopulated>;
  onReceivedMessage?: Maybe<MessageData>;
  onStructuringFollowUpUpdate?: Maybe<Array<Maybe<Deal>>>;
  onTemporaryRegistrationTagsDealsUpdated?: Maybe<Array<Maybe<Deal>>>;
  onTransactionCreated?: Maybe<FinancialInfo>;
  onWaitingQueue?: Maybe<OnWaitingQueue>;
  switchCallStatus?: Maybe<Scalars['String']>;
  switchCallStatusIndividual?: Maybe<Scalars['String']>;
};

export type RootSubscriptionTypeOnContractValidationArgs = {
  deal_id?: InputMaybe<Scalars['ID']>;
  fundingClerkOnly?: InputMaybe<Scalars['Boolean']>;
};

export type RootSubscriptionTypeOnDealCompleteArgs = {
  sources: Array<InputMaybe<Scalars['String']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootSubscriptionTypeOnDealUpdateArgs = {
  dealIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sources: Array<InputMaybe<Scalars['String']>>;
  types?: InputMaybe<Array<InputMaybe<DealType>>>;
};

export type RootSubscriptionTypeOnFollowUpUpdateArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
  followUpStatus?: InputMaybe<FollowUpStatusEnum>;
  followUpType?: InputMaybe<FollowUpTypeEnum>;
  scheduledByMeFilter?: InputMaybe<Scalars['Boolean']>;
  sources?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  types?: InputMaybe<Array<InputMaybe<DealType>>>;
};

export type RootSubscriptionTypeOnNewMessageArgs = {
  dealPhoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RootSubscriptionTypeOnPayoffRequestStatusUpdateArgs = {
  dealId?: InputMaybe<Scalars['ID']>;
};

export type RootSubscriptionTypeOnReceivedMessageArgs = {
  activePhoneNumber?: InputMaybe<Scalars['String']>;
  dealPhoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isNotification?: InputMaybe<Scalars['Boolean']>;
  isWidgetOpen?: InputMaybe<Scalars['Boolean']>;
};

export type RootSubscriptionTypeOnStructuringFollowUpUpdateArgs = {
  assignedToMe?: InputMaybe<Scalars['Boolean']>;
  dealId?: InputMaybe<Scalars['ID']>;
  podId?: InputMaybe<Scalars['ID']>;
  sources?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  types?: InputMaybe<Array<InputMaybe<DealType>>>;
};

export type RootSubscriptionTypeOnTransactionCreatedArgs = {
  dealId: Scalars['ID'];
};

export type SaveConsent = {
  __typename?: 'SaveConsent';
  consent?: Maybe<Consent>;
  isNew?: Maybe<Scalars['Boolean']>;
};

export type SelectCreditDecision = {
  __typename?: 'SelectCreditDecision';
  creditDecision: CreditDecision;
  financialInfo: FinancialInfo;
};

export type ShippingLabel = {
  __typename?: 'ShippingLabel';
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  purpose?: Maybe<Scalars['String']>;
  tracker_url?: Maybe<Scalars['String']>;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type SignatureData = {
  __typename?: 'SignatureData';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  initials_data_url?: Maybe<Scalars['String']>;
  initials_strokes?: Maybe<Scalars['String']>;
  signature_data_url?: Maybe<Scalars['String']>;
  signature_strokes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum SigningErrorEnum {
  ApplyDealerSignature = 'APPLY_DEALER_SIGNATURE',
  ApplySignature = 'APPLY_SIGNATURE',
  FinalizeSigning = 'FINALIZE_SIGNING',
  GenerationDate = 'GENERATION_DATE',
  RetrieveDocuments = 'RETRIEVE_DOCUMENTS',
}

export enum SigningSolutionEnum {
  AdobeSign = 'adobe_sign',
  RouteOne = 'route_one',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum SortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortingType = {
  direction?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
};

export type SortingTypeV2 = {
  direction?: InputMaybe<SortDirectionEnum>;
  field?: InputMaybe<DealSortableColumnsEnum>;
};

export enum StateAbbreviation {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Pw = 'PW',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export type StructuringFollowUp = {
  __typename?: 'StructuringFollowUp';
  created_at?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  creator_id?: Maybe<Scalars['ID']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<StructuringFollowUpStatus>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type StructuringFollowUpInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  creator_id?: InputMaybe<Scalars['ID']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  notes?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StructuringFollowUpStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export enum StructuringFollowUpSortColumn {
  Advisor = 'advisor',
  Customer = 'customer',
  Date = 'date',
  DealState = 'dealState',
  StructuringManager = 'structuringManager',
}

export enum StructuringFollowUpSortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum StructuringFollowUpStatus {
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS',
}

export type TtConditionFilterInput = {
  canVerifyDigitalSignatures?: InputMaybe<Scalars['Boolean']>;
  currentAddress?: InputMaybe<Scalars['String']>;
  driversLicenseAddress?: InputMaybe<Scalars['String']>;
  driversLicenseState?: InputMaybe<Scalars['String']>;
  hasCobuyer?: InputMaybe<Scalars['Boolean']>;
  jurisdictionState?: InputMaybe<Scalars['String']>;
  lienholder?: InputMaybe<Scalars['String']>;
  movedStateDuringLease?: InputMaybe<Scalars['Boolean']>;
  newBank?: InputMaybe<TtNewBankEnum>;
  transferringPlates?: InputMaybe<Scalars['Boolean']>;
  transferringRegistration?: InputMaybe<Scalars['Boolean']>;
  vehicleAge?: InputMaybe<Scalars['Int']>;
};

export type TtDocumentConditionsFields = {
  __typename?: 'TTDocumentConditionsFields';
  description?: Maybe<Scalars['String']>;
  variables?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TtDocumentFields = {
  __typename?: 'TTDocumentFields';
  conditions?: Maybe<Array<Maybe<TtDocumentConditionsFields>>>;
  documentTypeId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  supportsImageExtraction?: Maybe<Scalars['Boolean']>;
  type?: Maybe<TtDocumentTypeFields>;
  validations?: Maybe<Array<Maybe<TtValidation>>>;
};

export enum TtDocumentName {
  AcuraSpecificOdometerStatement = 'AcuraSpecificOdometerStatement',
  BackOfDriversLicense = 'BackOfDriversLicense',
  BillOfSale = 'BillOfSale',
  CobuyerBackOfDriversLicense = 'CobuyerBackOfDriversLicense',
  CobuyerDriversLicense = 'CobuyerDriversLicense',
  CobuyerJointTenancyWithRightsOfSurvivorship = 'CobuyerJointTenancyWithRightsOfSurvivorship',
  CobuyerOdometerStatement = 'CobuyerOdometerStatement',
  CobuyerPowerOfAttorney = 'CobuyerPowerOfAttorney',
  CobuyerRegistrationSpecificPowerOfAttorney = 'CobuyerRegistrationSpecificPowerOfAttorney',
  CobuyerSecurePowerOfAttorney = 'CobuyerSecurePowerOfAttorney',
  CobuyerStateSpecificOdometerStatement = 'CobuyerStateSpecificOdometerStatement',
  CobuyerStateSpecificPowerOfAttorney = 'CobuyerStateSpecificPowerOfAttorney',
  CobuyerSupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause = 'CobuyerSupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause',
  DriversLicense = 'DriversLicense',
  ElectronicRegistrationAndTitleAgreement = 'ElectronicRegistrationAndTitleAgreement',
  ElectronicSignatureAttestation = 'ElectronicSignatureAttestation',
  ElectronicSignatureCertification = 'ElectronicSignatureCertification',
  Emissions = 'Emissions',
  GmLeasePacket = 'GMLeasePacket',
  HondaSpecificOdometerStatement = 'HondaSpecificOdometerStatement',
  IdahoSalesTaxExemption = 'IdahoSalesTaxExemption',
  JointOdometerStatement = 'JointOdometerStatement',
  LicensePlate = 'LicensePlate',
  Odometer = 'Odometer',
  OdometerStatement = 'OdometerStatement',
  OhioSpecificHondaSpecificPowerOfAttorney = 'OhioSpecificHondaSpecificPowerOfAttorney',
  PlateTransferLetter = 'PlateTransferLetter',
  PorscheSpecificOdometerStatement = 'PorscheSpecificOdometerStatement',
  PorscheSpecificPayoffInstructions = 'PorscheSpecificPayoffInstructions',
  PowerOfAttorney = 'PowerOfAttorney',
  ProofOfInsurance = 'ProofOfInsurance',
  ProofOfResidence = 'ProofOfResidence',
  ProofOfResidence2 = 'ProofOfResidence2',
  PropertyTaxReceipt = 'PropertyTaxReceipt',
  PurchaseOrder = 'PurchaseOrder',
  Registration = 'Registration',
  RegistrationSpecificPowerOfAttorney = 'RegistrationSpecificPowerOfAttorney',
  SafetyInspection = 'SafetyInspection',
  SalesTaxExemption = 'SalesTaxExemption',
  SecurePowerOfAttorney = 'SecurePowerOfAttorney',
  StateSpecificBillOfSale = 'StateSpecificBillOfSale',
  StateSpecificDamageDisclosure = 'StateSpecificDamageDisclosure',
  StateSpecificOdometerStatement = 'StateSpecificOdometerStatement',
  StateSpecificPowerOfAttorney = 'StateSpecificPowerOfAttorney',
  SupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause = 'SupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause',
  TitleApp = 'TitleApp',
  VinInspection = 'VINInspection',
}

export type TtDocumentTypeFields = {
  __typename?: 'TTDocumentTypeFields';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  parameters?: Maybe<Array<Maybe<TtParameterFields>>>;
};

export type TtFeesInput = {
  address?: InputMaybe<Scalars['String']>;
  bookValue?: InputMaybe<Scalars['Float']>;
  city?: InputMaybe<Scalars['String']>;
  cobuyerAddress?: InputMaybe<Scalars['String']>;
  cobuyerCity?: InputMaybe<Scalars['String']>;
  cobuyerFirstName?: InputMaybe<Scalars['String']>;
  cobuyerLastName?: InputMaybe<Scalars['String']>;
  cobuyerMiddleName?: InputMaybe<Scalars['String']>;
  cobuyerState?: InputMaybe<StateAbbreviation>;
  cobuyerZip?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  dealId?: InputMaybe<Scalars['String']>;
  docFee?: InputMaybe<Scalars['Float']>;
  estimatedPayoff?: InputMaybe<Scalars['Float']>;
  firstName?: InputMaybe<Scalars['String']>;
  fuelType?: InputMaybe<TtFuelType>;
  lastName?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  newBank?: InputMaybe<Scalars['String']>;
  newBankAddress?: InputMaybe<Scalars['String']>;
  newBankAddress2?: InputMaybe<Scalars['String']>;
  newBankCity?: InputMaybe<Scalars['String']>;
  newBankState?: InputMaybe<StateAbbreviation>;
  newBankZip?: InputMaybe<Scalars['String']>;
  newBankZipPlus4?: InputMaybe<Scalars['String']>;
  odometerReading?: InputMaybe<Scalars['Int']>;
  payoff?: InputMaybe<Scalars['Float']>;
  previouslyTitledState?: InputMaybe<StateAbbreviation>;
  retailBookValue?: InputMaybe<Scalars['Float']>;
  signedDate?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateAbbreviation>;
  transferringPlates?: InputMaybe<Scalars['Boolean']>;
  uniqueId?: InputMaybe<Scalars['String']>;
  vehicleType?: InputMaybe<TtVehicleType>;
  vin?: InputMaybe<Scalars['String']>;
  warranty?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

export enum TtFuelType {
  Diesel = 'Diesel',
  Electric = 'Electric',
  Gasoline = 'Gasoline',
  Hybrid = 'Hybrid',
  PlugInHybrid = 'PlugInHybrid',
}

export enum TtGetFeesSourceType {
  ComDevTool = 'COM_DEV_TOOL',
  ComEnterPayoff = 'COM_ENTER_PAYOFF',
  ComImportInfo = 'COM_IMPORT_INFO',
  ComPaymentCalculator = 'COM_PAYMENT_CALCULATOR',
  ComPrequalification = 'COM_PREQUALIFICATION',
  ComSoftPull = 'COM_SOFT_PULL',
  D2DGetTaxesAndFees = 'D2D_GET_TAXES_AND_FEES',
  D2DPrequalification = 'D2D_PREQUALIFICATION',
  DdAutoStructure = 'DD_AUTO_STRUCTURE',
  DdCalcFees = 'DD_CALC_FEES',
  DdLendingTree = 'DD_LENDING_TREE',
  DdPaymentEstimate = 'DD_PAYMENT_ESTIMATE',
  DdPayoffTax = 'DD_PAYOFF_TAX',
  DdPrequalification = 'DD_PREQUALIFICATION',
  MktPaymentCalculator = 'MKT_PAYMENT_CALCULATOR',
  NoneGetTaxesAndFeesInput = 'NONE_GET_TAXES_AND_FEES_INPUT',
  NonePaymentEstimateLogic = 'NONE_PAYMENT_ESTIMATE_LOGIC',
}

export type TtJurisdiction = {
  __typename?: 'TTJurisdiction';
  allowPlateTransfer?: Maybe<Scalars['Boolean']>;
  allowRegistrationTransfer?: Maybe<Scalars['Boolean']>;
  hasRegistrationOnly?: Maybe<Scalars['Boolean']>;
  hasTitleAndRegistration?: Maybe<Scalars['Boolean']>;
  hasTitleOnly?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  products?: Maybe<TtttProductFieldsConnection>;
  registrationProduct?: Maybe<TtProductFields>;
  requireWalkIn?: Maybe<Scalars['Boolean']>;
  supportedProcessors?: Maybe<Array<Maybe<Processor>>>;
  titleProduct?: Maybe<TtProductFields>;
};

export type TtJurisdictionProductsArgs = {
  filter?: InputMaybe<TtProductFilter>;
};

export enum TtNewBankEnum {
  Afcu = 'AFCU',
  AllyFinancial = 'AllyFinancial',
  BankOfAmerica = 'BankOfAmerica',
  CapitalOne = 'CapitalOne',
  Chase = 'Chase',
  FifthThird = 'FifthThird',
  FirstInterstate = 'FirstInterstate',
  Iccu = 'ICCU',
  LentegrityAutoFinance = 'LentegrityAutoFinance',
  Lookout = 'Lookout',
  Mechanics = 'Mechanics',
  MountainAmerica = 'MountainAmerica',
  Pnc = 'PNC',
  PrestigeFinancial = 'PrestigeFinancial',
  Santander = 'Santander',
  Td = 'TD',
  Upgrade = 'Upgrade',
  WellsFargo = 'WellsFargo',
  Westlake = 'Westlake',
  Wings = 'Wings',
}

export type TtPageInfo = {
  __typename?: 'TTPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type TtParameterFields = {
  __typename?: 'TTParameterFields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TtProductFields = {
  __typename?: 'TTProductFields';
  documents?: Maybe<TtttDocumentFieldsConnection>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<TtProductType>;
  validations?: Maybe<Array<Maybe<TtValidation>>>;
};

export type TtProductFieldsDocumentsArgs = {
  conditions?: InputMaybe<TtConditionFilterInput>;
};

export type TtProductFilter = {
  types?: InputMaybe<Array<InputMaybe<TtProductType>>>;
};

export enum TtProductType {
  Registration = 'Registration',
  Title = 'Title',
}

export type TtttDocumentFieldsConnection = {
  __typename?: 'TTTTDocumentFieldsConnection';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<TtDocumentFields>>>;
  pageInfo?: Maybe<TtPageInfo>;
};

export type TtttProductFieldsConnection = {
  __typename?: 'TTTTProductFieldsConnection';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<TtProductFields>>>;
  pageInfo?: Maybe<TtPageInfo>;
};

export type TtValidation = {
  __typename?: 'TTValidation';
  description?: Maybe<Scalars['String']>;
  internalDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum TtVehicleType {
  ExtendedCabPickup = 'ExtendedCabPickup',
  Motorhome = 'Motorhome',
  MultipurposePassengerVehicle = 'MultipurposePassengerVehicle',
  PassengerCar = 'PassengerCar',
  Pickup = 'Pickup',
  Suv = 'SUV',
  Sedan = 'Sedan',
  Truck = 'Truck',
}

export type Tag = {
  __typename?: 'Tag';
  color: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  display_name: Scalars['String'];
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  is_dashboard_visible: Scalars['Boolean'];
  slug: TagSlugEnum;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum TagSlugEnum {
  ContractExpired = 'contract_expired',
  CreditFrozen = 'credit_frozen',
  DirectPay = 'direct_pay',
  DoubleTax = 'double_tax',
  DownPaymentCompleted = 'down_payment_completed',
  EmailPreferred = 'email_preferred',
  HeldAtBank = 'held_at_bank',
  LendingTree = 'lending_tree',
  NeedsCobuyer = 'needs_cobuyer',
  NeedsConsent = 'needs_consent',
  NeedsIncome = 'needs_income',
  NeedsMoneyDown = 'needs_money_down',
  NeedsRecontracting = 'needs_recontracting',
  PayoffFailed = 'payoff_failed',
  PayoffVerified = 'payoff_verified',
  PromoJuly2024 = 'promo_july2024',
  ProofOfIncome = 'proof_of_income',
  ProofOfResidency = 'proof_of_residency',
  ReleaseOfInterest = 'release_of_interest',
  Repo = 'repo',
  SentToBank = 'sent_to_bank',
  SpanishSpeaker = 'spanish_speaker',
  TextPreferred = 'text_preferred',
  TwoCarDriver = 'two_car_driver',
  VerifyingInfo = 'verifying_info',
  Vip = 'vip',
  VscGapContracts = 'vsc_gap_contracts',
  WaitingForDownPayment = 'waiting_for_down_payment',
  WaitingForInterview = 'waiting_for_interview',
  WaitingForPayoff = 'waiting_for_payoff',
  WaitingForPics = 'waiting_for_pics',
  WalkIn = 'walk_in',
}

export type TagsInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type TemplateText = {
  __typename?: 'TemplateText';
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum TitleRegistrationOption {
  TitleAndNewRegistration = 'title_and_new_registration',
  TitleAndRegistrationTransfer = 'title_and_registration_transfer',
  TitleOnly = 'title_only',
}

export type UpdateCreditAppOnDealResponse = {
  __typename?: 'UpdateCreditAppOnDealResponse';
  cobuyer?: Maybe<Customer>;
  contact?: Maybe<DealContact>;
  customer: Customer;
  financial_info: FinancialInfo;
  id: Scalars['Int'];
  odometer_status?: Maybe<OdometerStatus>;
  second_contact?: Maybe<DealContact>;
  state: Scalars['String'];
};

export type UpdateUserInput = {
  auth0Roles: Array<InputMaybe<Auth0RoleInput>>;
  email: Scalars['String'];
  huntGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  id: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  podIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  recruiterId?: InputMaybe<Scalars['String']>;
};

export type UserCreationData = {
  __typename?: 'UserCreationData';
  huntGroups?: Maybe<Array<HuntGroup>>;
  pods?: Maybe<Array<Pod>>;
};

export type UserRoles = {
  __typename?: 'UserRoles';
  roles?: Maybe<Array<Maybe<Auth0Role>>>;
};

export type VehicleMileageGql = {
  __typename?: 'VehicleMileageGQL';
  estimatedAverageMiles?: Maybe<Scalars['Float']>;
  eventCount?: Maybe<Scalars['Int']>;
  lastOdometer?: Maybe<Scalars['Int']>;
  lastOdometerDate?: Maybe<Scalars['String']>;
  resultCode?: Maybe<Scalars['Int']>;
  resultMessage?: Maybe<Scalars['String']>;
  rollback?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export enum AccidentsEnumType {
  NoAccidents = 'no_accidents',
  OneAccident = 'one_accident',
  TwoOrMoreAccidents = 'two_or_more_accidents',
}

export type AcquisitionOffer = {
  __typename?: 'acquisitionOffer';
  appraisedValue?: Maybe<Scalars['Float']>;
  estimatedFees?: Maybe<Scalars['Float']>;
  offer?: Maybe<Scalars['Float']>;
};

export enum ActorType {
  Applicant = 'Applicant',
  Buyer = 'Buyer',
  CoApplicant = 'CoApplicant',
  CoBuyer = 'CoBuyer',
  Customer = 'Customer',
  Dealer = 'Dealer',
  Guarantor = 'Guarantor',
  Sales = 'Sales',
}

export type AddDocumentsToMediaCenterInput = {
  key?: InputMaybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'address';
  address_line?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_type?: Maybe<AddressTypeEnum>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  monthly_payment?: Maybe<Scalars['Float']>;
  months_at_home?: Maybe<Scalars['Int']>;
  moved_states?: Maybe<Scalars['Boolean']>;
  residence_type?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  years_at_home?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address_line?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  address_type?: InputMaybe<AddressTypeEnum>;
  city?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  monthly_payment?: InputMaybe<Scalars['Float']>;
  months_at_home?: InputMaybe<Scalars['Int']>;
  moved_states?: InputMaybe<Scalars['Boolean']>;
  residence_type?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  time_zone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  years_at_home?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

export enum AddressTypeEnum {
  Buyer = 'Buyer',
  CoBuyer = 'CoBuyer',
  Current = 'Current',
  DriversLicense = 'DriversLicense',
  Previous = 'Previous',
}

export type Affiliate = {
  __typename?: 'affiliate';
  affiliate_provider?: Maybe<Affiliate_Provider>;
  affiliate_provider_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['DateTime']>;
  decision_accepted?: Maybe<Scalars['Boolean']>;
  decision_response?: Maybe<Lending_Tree_Decision_Response>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum AffiliateProviderSlug {
  LendingTree = 'lending_tree',
}

export type Affiliate_Provider = {
  __typename?: 'affiliate_provider';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<AffiliateProviderSlug>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Article = {
  __typename?: 'article';
  com_visible?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail_key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type ArticleImageUploadUrl = {
  __typename?: 'articleImageUploadUrl';
  key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ArticleInput = {
  com_visible?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  thumbnail_key?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type AssignmentLog = {
  __typename?: 'assignmentLog';
  assigned_at?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type AssignmentLogInput = {
  assigned_at?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type AssignmentLogs = {
  __typename?: 'assignmentLogs';
  logs?: Maybe<Array<Maybe<AssignmentLog>>>;
};

export type AssignmentLogsInput = {
  logs?: InputMaybe<Array<InputMaybe<AssignmentLogInput>>>;
};

export type Auction = {
  __typename?: 'auction';
  address_line?: Maybe<Scalars['String']>;
  average_transport_cost?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  zip?: Maybe<Scalars['String']>;
};

export type AuctionDistances = {
  __typename?: 'auctionDistances';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  transport_cost?: Maybe<Scalars['Int']>;
};

export type AuctionDistancesInput = {
  auctions?: InputMaybe<Array<InputMaybe<Destinations>>>;
  customerAddress?: InputMaybe<Scalars['String']>;
  customerState?: InputMaybe<Scalars['String']>;
};

export type AuctionInput = {
  address_line?: InputMaybe<Scalars['String']>;
  average_transport_cost?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  zip?: InputMaybe<Scalars['String']>;
};

export enum AutoImportVariation {
  DisplayCustomizePackage = 'displayCustomizePackage',
  ImmediateImport = 'immediateImport',
}

export type Bank = {
  __typename?: 'bank';
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  auto_structure_buyout?: Maybe<Scalars['Boolean']>;
  auto_structure_refi?: Maybe<Scalars['Boolean']>;
  bank_markups?: Maybe<Array<Maybe<BankMarkup>>>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  r1_fsid?: Maybe<Scalars['String']>;
  reserve_structure?: Maybe<Array<Maybe<ReserveStructure>>>;
  signing_solution?: Maybe<SigningSolutionEnum>;
  sort_order?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  use_in_states?: Maybe<Array<Maybe<Scalars['String']>>>;
  zip?: Maybe<Scalars['String']>;
};

export type BankInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  auto_structure_buyout?: InputMaybe<Scalars['Boolean']>;
  auto_structure_refi?: InputMaybe<Scalars['Boolean']>;
  bank_markups?: InputMaybe<Array<InputMaybe<BankMarkupInput>>>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  logo_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  r1_fsid?: InputMaybe<Scalars['String']>;
  reserve_structure?: InputMaybe<Array<InputMaybe<ReserveStructureInput>>>;
  signing_solution?: InputMaybe<SigningSolutionEnum>;
  sort_order?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  use_in_states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zip?: InputMaybe<Scalars['String']>;
};

export type BankMarkup = {
  __typename?: 'bankMarkup';
  bank_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  max_markup?: Maybe<Scalars['Float']>;
  term_max?: Maybe<Scalars['Int']>;
  term_min?: Maybe<Scalars['Int']>;
};

export type BankMarkupInput = {
  bank_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  max_markup?: InputMaybe<Scalars['Float']>;
  term_max?: InputMaybe<Scalars['Int']>;
  term_min?: InputMaybe<Scalars['Int']>;
};

export type BasePod = {
  __typename?: 'basePod';
  archived?: Maybe<Scalars['Boolean']>;
  closer_commission_rate?: Maybe<Scalars['Float']>;
  closer_commission_type?: Maybe<CommissionType>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  manager_commission_rate?: Maybe<Scalars['Float']>;
  manager_commission_type?: Maybe<CommissionType>;
  name?: Maybe<Scalars['String']>;
  parent_pod_id?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  problem_solver?: Maybe<Scalars['Boolean']>;
  setter_commission_rate?: Maybe<Scalars['Float']>;
  setter_commission_type?: Maybe<CommissionType>;
  special_commission_rate?: Maybe<Scalars['Float']>;
  special_commission_type?: Maybe<CommissionType>;
  team_type?: Maybe<TeamType>;
  updated_at?: Maybe<Scalars['DateTime']>;
  us_states_object?: Maybe<UsStatesObject>;
  vsc_markup?: Maybe<Scalars['Int']>;
  vsc_multiplier?: Maybe<Scalars['Float']>;
};

export type BasePodInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  closer_commission_rate?: InputMaybe<Scalars['Float']>;
  closer_commission_type?: InputMaybe<CommissionType>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  hours?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  manager_commission_rate?: InputMaybe<Scalars['Float']>;
  manager_commission_type?: InputMaybe<CommissionType>;
  name?: InputMaybe<Scalars['String']>;
  parent_pod_id?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  problem_solver?: InputMaybe<Scalars['Boolean']>;
  setter_commission_rate?: InputMaybe<Scalars['Float']>;
  setter_commission_type?: InputMaybe<CommissionType>;
  special_commission_rate?: InputMaybe<Scalars['Float']>;
  special_commission_type?: InputMaybe<CommissionType>;
  team_type?: InputMaybe<TeamType>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  us_states_object?: InputMaybe<UsStatesInputObject>;
  vsc_markup?: InputMaybe<Scalars['Int']>;
  vsc_multiplier?: InputMaybe<Scalars['Float']>;
};

export type BaseUser = {
  __typename?: 'baseUser';
  auto_assign_deals?: Maybe<Scalars['Boolean']>;
  call_status?: Maybe<Scalars['String']>;
  can_claim_as_closer?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  max_auto_assign_deals?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  on_vacation?: Maybe<Scalars['Boolean']>;
  overnight_deals?: Maybe<Scalars['Boolean']>;
  phone_number?: Maybe<Scalars['String']>;
  recruiter_id?: Maybe<Scalars['String']>;
  twilio_number?: Maybe<Scalars['String']>;
};

export type BaseUserInput = {
  auto_assign_deals?: InputMaybe<Scalars['Boolean']>;
  call_status?: InputMaybe<Scalars['String']>;
  can_claim_as_closer?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  max_auto_assign_deals?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  on_vacation?: InputMaybe<Scalars['Boolean']>;
  overnight_deals?: InputMaybe<Scalars['Boolean']>;
  phone_number?: InputMaybe<Scalars['String']>;
  recruiter_id?: InputMaybe<Scalars['String']>;
  twilio_number?: InputMaybe<Scalars['String']>;
};

export type BootReasonInput = {
  author_id?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type Call = {
  __typename?: 'call';
  callDuration?: Maybe<Scalars['String']>;
  callStatus?: Maybe<Scalars['String']>;
  calledCity?: Maybe<Scalars['String']>;
  calledNumber?: Maybe<Scalars['String']>;
  calledState?: Maybe<Scalars['String']>;
  callerName?: Maybe<Scalars['String']>;
  caller_city?: Maybe<Scalars['String']>;
  caller_state?: Maybe<Scalars['String']>;
  conferenceId?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['ID']>;
  direction?: Maybe<Scalars['String']>;
  flow?: Maybe<Scalars['String']>;
  fromNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isNewCustomer?: Maybe<Scalars['Boolean']>;
  new_customer_call_outcome?: Maybe<Scalars['String']>;
  new_customer_call_outcome_description?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  pod_id?: Maybe<Scalars['ID']>;
  recordingSid?: Maybe<Scalars['String']>;
  recordingUrl?: Maybe<Scalars['String']>;
  selection?: Maybe<Scalars['String']>;
  selection_message?: Maybe<Scalars['String']>;
  twilioNumber?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['ID']>;
};

export type Car = {
  __typename?: 'car';
  book_value?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  fuel_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  jdp_adjusted_clean_retail?: Maybe<Scalars['Float']>;
  jdp_adjusted_clean_trade?: Maybe<Scalars['Float']>;
  jdp_mileage_adjustment?: Maybe<Scalars['Float']>;
  jdp_trim_body?: Maybe<Scalars['String']>;
  jdp_valuation_date?: Maybe<Scalars['Date']>;
  jdp_vehicle_accessories_object?: Maybe<JdpVehicleAccessoriesObject>;
  kbb_lending_mileage_adjustment?: Maybe<Scalars['Float']>;
  kbb_lending_option_adjustment?: Maybe<Scalars['Float']>;
  kbb_retail_mileage_adjustment?: Maybe<Scalars['Float']>;
  kbb_retail_option_adjustment?: Maybe<Scalars['Float']>;
  kbb_selected_options_object?: Maybe<KbbSelectedOptionsObject>;
  kbb_trim_name?: Maybe<Scalars['String']>;
  kbb_valuation_date?: Maybe<Scalars['String']>;
  kbb_vehicle_name?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  license_plate_state?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mmr?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  odometer_status?: Maybe<OdometerStatus>;
  payoff?: Maybe<Payoff>;
  registration_expiration?: Maybe<Scalars['DateTime']>;
  retail_book_value?: Maybe<Scalars['Float']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  vehicle_type?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type CarInput = {
  book_value?: InputMaybe<Scalars['Float']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  fuel_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  jdp_adjusted_clean_retail?: InputMaybe<Scalars['Float']>;
  jdp_adjusted_clean_trade?: InputMaybe<Scalars['Float']>;
  jdp_mileage_adjustment?: InputMaybe<Scalars['Float']>;
  jdp_trim_body?: InputMaybe<Scalars['String']>;
  jdp_valuation_date?: InputMaybe<Scalars['Date']>;
  jdp_vehicle_accessories_object?: InputMaybe<JdpVehicleAccessoriesObjectInput>;
  kbb_lending_mileage_adjustment?: InputMaybe<Scalars['Float']>;
  kbb_lending_option_adjustment?: InputMaybe<Scalars['Float']>;
  kbb_retail_mileage_adjustment?: InputMaybe<Scalars['Float']>;
  kbb_retail_option_adjustment?: InputMaybe<Scalars['Float']>;
  kbb_selected_options_object?: InputMaybe<KbbSelectedOptionsObjectInput>;
  kbb_trim_name?: InputMaybe<Scalars['String']>;
  kbb_valuation_date?: InputMaybe<Scalars['String']>;
  kbb_vehicle_name?: InputMaybe<Scalars['String']>;
  license_plate_number?: InputMaybe<Scalars['String']>;
  license_plate_state?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  mmr?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  odometer_status?: InputMaybe<OdometerStatus>;
  payoff?: InputMaybe<PayoffInput>;
  registration_expiration?: InputMaybe<Scalars['DateTime']>;
  retail_book_value?: InputMaybe<Scalars['Float']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  vehicle_type?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type CarWarranty = {
  __typename?: 'carWarranty';
  full_miles?: Maybe<Scalars['Int']>;
  full_status?: Maybe<Scalars['String']>;
  full_years?: Maybe<Scalars['Int']>;
  power_train_miles?: Maybe<Scalars['Int']>;
  power_train_status?: Maybe<Scalars['String']>;
  power_train_years?: Maybe<Scalars['Int']>;
};

export enum CardType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  HsaFsa = 'HSA_FSA',
  NonReloadablePrepaid = 'NON_RELOADABLE_PREPAID',
  ReloadablePrepaid = 'RELOADABLE_PREPAID',
  Unknown = 'UNKNOWN',
}

export enum CommissionType {
  Flat = 'flat',
  Percentage = 'percentage',
}

export type ConditionRejection = {
  __typename?: 'conditionRejection';
  conditionRejectionText?: Maybe<Scalars['String']>;
};

export type ConditionReport = {
  __typename?: 'conditionReport';
  accidents?: Maybe<AccidentsEnumType>;
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  exterior?: Maybe<Array<Maybe<ExteriorEnumType>>>;
  id?: Maybe<Scalars['ID']>;
  interior?: Maybe<Array<Maybe<InteriorEnumType>>>;
  lights_on_dash?: Maybe<Scalars['Boolean']>;
  overall_condition?: Maybe<OverallConditionEnumType>;
  score?: Maybe<Scalars['Float']>;
  smoked_in?: Maybe<Scalars['Boolean']>;
  tires?: Maybe<TiresEnumType>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type ConditionReportInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  id?: InputMaybe<Scalars['ID']>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  score?: InputMaybe<Scalars['Float']>;
  smoked_in?: InputMaybe<Scalars['Boolean']>;
  tires?: InputMaybe<TiresEnumType>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type ConditionReportUpsert = {
  __typename?: 'conditionReportUpsert';
  accidents?: Maybe<AccidentsEnumType>;
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  exterior?: Maybe<Array<Maybe<ExteriorEnumType>>>;
  id?: Maybe<Scalars['ID']>;
  interior?: Maybe<Array<Maybe<InteriorEnumType>>>;
  lights_on_dash?: Maybe<Scalars['Boolean']>;
  overall_condition?: Maybe<OverallConditionEnumType>;
  smoked_in?: Maybe<Scalars['Boolean']>;
  tires?: Maybe<TiresEnumType>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type ConditionReportUpsertInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  id?: InputMaybe<Scalars['ID']>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  smoked_in?: InputMaybe<Scalars['Boolean']>;
  tires?: InputMaybe<TiresEnumType>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  year?: InputMaybe<Scalars['String']>;
};

export type Conference = {
  __typename?: 'conference';
  agent_number?: Maybe<Scalars['String']>;
  agent_user_id?: Maybe<Scalars['String']>;
  conference_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  customer_name?: Maybe<Scalars['String']>;
  deal_id?: Maybe<Scalars['Int']>;
  deal_state?: Maybe<Scalars['String']>;
  deal_type?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  hunt_group_slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  is_outbound?: Maybe<Scalars['Boolean']>;
  is_thunder?: Maybe<Scalars['Boolean']>;
  next_participant?: Maybe<Scalars['String']>;
  participants_count?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  transferred_from?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Consent = {
  __typename?: 'consent';
  affiliate_id?: Maybe<Scalars['Int']>;
  consent_agreement_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<ConsentNameEnumType>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
};

export type ConsentAgreement = {
  __typename?: 'consentAgreement';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  is_active?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<ConsentAgreementTypeEnum>;
  updated_at?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export enum ConsentAgreementTypeEnum {
  AchPayment = 'ach_payment',
  HardPull = 'hard_pull',
  SoftPull = 'soft_pull',
  TermsAndConditions = 'terms_and_conditions',
}

export enum ConsentNameEnumType {
  AchPayment = 'ach_payment',
  CreditScoreHardPull = 'credit_score_hard_pull',
  CreditScoreSoftPull = 'credit_score_soft_pull',
  HistoricalCreditScoreHardPull = 'historical_credit_score_hard_pull',
  TermsAndConditions = 'terms_and_conditions',
}

export enum ContactType {
  Email = 'email',
  Text = 'text',
}

export type ContractTerm = {
  __typename?: 'contractTerm';
  financedTermQuantity?: Maybe<Scalars['Int']>;
  totalDownPaymentAmount?: Maybe<Scalars['Float']>;
};

export type Contracts = {
  __typename?: 'contracts';
  files?: Maybe<Array<Maybe<Files>>>;
};

export type ContractsInput = {
  deal_id?: InputMaybe<Scalars['ID']>;
};

export type CreateNewEstimateFromFiles = {
  __typename?: 'createNewEstimateFromFiles';
  deal?: Maybe<Deal>;
  uploadUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreditApplication = {
  __typename?: 'creditApplication';
  created_at?: Maybe<Scalars['DateTime']>;
  credit_application_data?: Maybe<CreditApplicationData>;
  credit_decisions?: Maybe<Array<Maybe<CreditDecision>>>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  r1_conversation_id?: Maybe<Scalars['String']>;
  r1_credit_app_guid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type CreditApplicationAddress = {
  __typename?: 'creditApplicationAddress';
  address_line?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  monthly_payment?: Maybe<Scalars['Float']>;
  months_at_home?: Maybe<Scalars['Int']>;
  residence_type?: Maybe<ResidenceEnumType>;
  state?: Maybe<Scalars['String']>;
  years_at_home?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

export type CreditApplicationAddressInput = {
  address_line?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  monthly_payment?: InputMaybe<Scalars['Float']>;
  months_at_home?: InputMaybe<Scalars['Int']>;
  residence_type?: InputMaybe<ResidenceEnumType>;
  state?: InputMaybe<Scalars['String']>;
  years_at_home?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type CreditApplicationBuyer = {
  __typename?: 'creditApplicationBuyer';
  address?: Maybe<CreditApplicationAddress>;
  dob?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  employment?: Maybe<CreditApplicationEmployment>;
  first_name?: Maybe<Scalars['String']>;
  home_phone_number?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<MaritalStatusEnum>;
  middle_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  prev_address?: Maybe<CreditApplicationAddress>;
  prev_employment?: Maybe<CreditApplicationEmployment>;
  ssn?: Maybe<Scalars['String']>;
};

export type CreditApplicationBuyerInput = {
  address?: InputMaybe<CreditApplicationAddressInput>;
  dob?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  employment?: InputMaybe<CreditApplicationEmploymentInput>;
  first_name?: InputMaybe<Scalars['String']>;
  home_phone_number?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  marital_status?: InputMaybe<MaritalStatusEnum>;
  middle_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  prev_address?: InputMaybe<CreditApplicationAddressInput>;
  prev_employment?: InputMaybe<CreditApplicationEmploymentInput>;
  ssn?: InputMaybe<Scalars['String']>;
};

export type CreditApplicationCar = {
  __typename?: 'creditApplicationCar';
  book_value?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  kbb_trim_name?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  retail_book_value?: Maybe<Scalars['Float']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type CreditApplicationCarInput = {
  book_value?: InputMaybe<Scalars['Float']>;
  color?: InputMaybe<Scalars['String']>;
  kbb_trim_name?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
  retail_book_value?: InputMaybe<Scalars['Float']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type CreditApplicationCreateInput = {
  credit_application_data: CreditApplicationDataInput;
  deal_id: Scalars['ID'];
};

export type CreditApplicationData = {
  __typename?: 'creditApplicationData';
  car?: Maybe<CreditApplicationCar>;
  cobuyer?: Maybe<CreditApplicationBuyer>;
  customer?: Maybe<CreditApplicationBuyer>;
  finance?: Maybe<CreditApplicationFinance>;
  relationshipToBuyer?: Maybe<RelationToBuyerEnum>;
};

export type CreditApplicationDataInput = {
  car?: InputMaybe<CreditApplicationCarInput>;
  cobuyer?: InputMaybe<CreditApplicationBuyerInput>;
  customer?: InputMaybe<CreditApplicationBuyerInput>;
  finance?: InputMaybe<CreditApplicationFinanceInput>;
  relationshipToBuyer?: InputMaybe<RelationToBuyerEnum>;
};

export type CreditApplicationEmployment = {
  __typename?: 'creditApplicationEmployment';
  gross_income?: Maybe<Scalars['Float']>;
  job_title?: Maybe<Scalars['String']>;
  months_at_job?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pay_frequency?: Maybe<IncomeIntervalEnumType>;
  phone_number?: Maybe<Scalars['String']>;
  status?: Maybe<EmploymentStatusType>;
  years_at_job?: Maybe<Scalars['Int']>;
};

export type CreditApplicationEmploymentInput = {
  gross_income?: InputMaybe<Scalars['Float']>;
  job_title?: InputMaybe<Scalars['String']>;
  months_at_job?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  pay_frequency?: InputMaybe<IncomeIntervalEnumType>;
  phone_number?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EmploymentStatusType>;
  years_at_job?: InputMaybe<Scalars['Int']>;
};

export type CreditApplicationFinance = {
  __typename?: 'creditApplicationFinance';
  amount_financed?: Maybe<Scalars['Float']>;
  base_tax_amount?: Maybe<Scalars['Float']>;
  buyer_not_lessee?: Maybe<Scalars['Boolean']>;
  days_to_payment?: Maybe<Scalars['Int']>;
  doc_fee?: Maybe<Scalars['Float']>;
  gap_price?: Maybe<Scalars['Float']>;
  money_down?: Maybe<Scalars['Float']>;
  new_registration_fee?: Maybe<Scalars['Float']>;
  payment?: Maybe<Scalars['Float']>;
  payoff?: Maybe<Scalars['Float']>;
  registration_transfer_fee?: Maybe<Scalars['Float']>;
  sell_rate?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
  title_fee?: Maybe<Scalars['Float']>;
  vsc_price?: Maybe<Scalars['Float']>;
  vsc_term?: Maybe<Scalars['String']>;
  warranty_tax_amount?: Maybe<Scalars['Float']>;
};

export type CreditApplicationFinanceInput = {
  amount_financed?: InputMaybe<Scalars['Float']>;
  base_tax_amount?: InputMaybe<Scalars['Float']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']>;
  days_to_payment?: InputMaybe<Scalars['Int']>;
  doc_fee?: InputMaybe<Scalars['Float']>;
  gap_price?: InputMaybe<Scalars['Float']>;
  money_down?: InputMaybe<Scalars['Float']>;
  new_registration_fee?: InputMaybe<Scalars['Float']>;
  payment?: InputMaybe<Scalars['Float']>;
  payoff?: InputMaybe<Scalars['Float']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']>;
  sell_rate?: InputMaybe<Scalars['Float']>;
  term?: InputMaybe<Scalars['Int']>;
  title_fee?: InputMaybe<Scalars['Float']>;
  vsc_price?: InputMaybe<Scalars['Float']>;
  vsc_term?: InputMaybe<Scalars['String']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']>;
};

export type CreditApplicationUpdateInput = {
  credit_application_data: CreditApplicationDataInput;
  id: Scalars['ID'];
};

export type CreditDecision = {
  __typename?: 'creditDecision';
  annual_percentage_rate?: Maybe<Scalars['Float']>;
  application_status?: Maybe<CreditDecisionStatusEnum>;
  balance_amount?: Maybe<Scalars['Float']>;
  bank?: Maybe<Bank>;
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  lender_fees?: Maybe<Scalars['Float']>;
  max_markup?: Maybe<Scalars['Float']>;
  r1_application_number?: Maybe<Scalars['String']>;
  r1_conversation_id?: Maybe<Scalars['String']>;
  r1_credit_decision_data?: Maybe<R1CreditDecisionData>;
  r1_fsid?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  tier?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum CreditDecisionStatusEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  I = 'I',
  N = 'N',
  O = 'O',
  Ot = 'OT',
  P = 'P',
  R = 'R',
  S = 'S',
}

export enum CreditScore {
  BelowAverage = 'BelowAverage',
  Fair = 'Fair',
  Good = 'Good',
  Great = 'Great',
}

export enum CreditScoreTierEnumType {
  Fair = 'fair',
  Good = 'good',
  NoResponse = 'no_response',
  Poor = 'poor',
  VeryGood = 'very_good',
}

export type Customer = {
  __typename?: 'customer';
  address?: Maybe<Address>;
  auth0_id?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  dashboard_visited?: Maybe<Scalars['Boolean']>;
  dl_expiration_date?: Maybe<Scalars['DateTime']>;
  dob?: Maybe<Scalars['Date']>;
  drivers_license_address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  employment?: Maybe<Employment>;
  finished_signing?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  has_same_address_as_cobuyer?: Maybe<Scalars['Boolean']>;
  home_phone_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  last_authenticated_route?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  name_suffix?: Maybe<Scalars['String']>;
  no_email?: Maybe<Scalars['Boolean']>;
  payoff_email_sent_at?: Maybe<Scalars['DateTime']>;
  phone_number?: Maybe<Scalars['String']>;
  prequalification?: Maybe<Prequalification>;
  prev_address?: Maybe<Address>;
  prev_employment?: Maybe<Employment>;
  proof_of_insurance?: Maybe<ProofOfInsurance>;
  relation_to_buyer?: Maybe<RelationToBuyerEnum>;
  sent_account_email?: Maybe<Scalars['Boolean']>;
  sent_finalized_email?: Maybe<Scalars['DateTime']>;
  sent_review_email?: Maybe<Scalars['DateTime']>;
  sent_to_processor_email_sent_at?: Maybe<Scalars['DateTime']>;
  signature_data?: Maybe<SignatureData>;
  ssn?: Maybe<Scalars['String']>;
  title_received_email_sent_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type CustomerInput = {
  address?: InputMaybe<AddressInput>;
  auth0_id?: InputMaybe<Scalars['String']>;
  contact_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  dashboard_visited?: InputMaybe<Scalars['Boolean']>;
  dl_expiration_date?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['Date']>;
  drivers_license_address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']>;
  employment?: InputMaybe<EmploymentInput>;
  first_name?: InputMaybe<Scalars['String']>;
  has_same_address_as_cobuyer?: InputMaybe<Scalars['Boolean']>;
  home_phone_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  last_authenticated_route?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  marital_status?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  name_suffix?: InputMaybe<Scalars['String']>;
  no_email?: InputMaybe<Scalars['Boolean']>;
  payoff_email_sent_at?: InputMaybe<Scalars['DateTime']>;
  phone_number?: InputMaybe<Scalars['String']>;
  prev_address?: InputMaybe<AddressInput>;
  prev_employment?: InputMaybe<EmploymentInput>;
  proof_of_insurance?: InputMaybe<ProofOfInsuranceInput>;
  relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  sent_account_email?: InputMaybe<Scalars['Boolean']>;
  sent_finalized_email?: InputMaybe<Scalars['DateTime']>;
  sent_review_email?: InputMaybe<Scalars['DateTime']>;
  sent_to_processor_email_sent_at?: InputMaybe<Scalars['DateTime']>;
  ssn?: InputMaybe<Scalars['String']>;
  title_received_email_sent_at?: InputMaybe<Scalars['DateTime']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type Deal = {
  __typename?: 'deal';
  auto_import_variation?: Maybe<AutoImportVariation>;
  boot_reason?: Maybe<Scalars['JSONObject']>;
  called_by_financial_specialist?: Maybe<Scalars['DateTime']>;
  car?: Maybe<Car>;
  closer?: Maybe<User>;
  closer2?: Maybe<User>;
  closer2_id?: Maybe<Scalars['ID']>;
  closer_id?: Maybe<Scalars['ID']>;
  cobuyer?: Maybe<Customer>;
  completion_date_tz?: Maybe<Scalars['String']>;
  completion_date_utc?: Maybe<Scalars['DateTime']>;
  condition_report?: Maybe<ConditionReport>;
  consents?: Maybe<Array<Maybe<Consent>>>;
  contact?: Maybe<DealContact>;
  created_at?: Maybe<Scalars['DateTime']>;
  creation_date_tz?: Maybe<Scalars['String']>;
  creation_date_utc?: Maybe<Scalars['DateTime']>;
  credit_applications?: Maybe<Array<Maybe<CreditApplication>>>;
  customer?: Maybe<Customer>;
  deal_assignment_log?: Maybe<DealAssignmentLog>;
  deal_dates?: Maybe<DealDates>;
  deal_states?: Maybe<Array<Maybe<DealState>>>;
  default_sort_date?: Maybe<Scalars['DateTime']>;
  direct_pay_state?: Maybe<Scalars['Boolean']>;
  document_info?: Maybe<DocumentInfo>;
  document_progress_status?: Maybe<Scalars['String']>;
  extracted_document_data?: Maybe<ExtractedDocumentData>;
  finalized_date?: Maybe<Scalars['DateTime']>;
  financial_info?: Maybe<FinancialInfo>;
  financial_info_acquisition?: Maybe<FinancialInfoAcquisition>;
  follow_up?: Maybe<FollowUp>;
  force_title_only_helper_link?: Maybe<ForceTitleOnlyHelperLink>;
  funding_clerk?: Maybe<User>;
  funding_clerk_id?: Maybe<Scalars['ID']>;
  hasRecentCommunication?: Maybe<Scalars['Boolean']>;
  has_problem?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  import_type?: Maybe<ImportType>;
  imported_date_utc?: Maybe<Scalars['DateTime']>;
  isCobuyer?: Maybe<Scalars['Boolean']>;
  lane_arrival_utc?: Maybe<Scalars['DateTime']>;
  last_outgoing_communication_date_utc?: Maybe<Scalars['DateTime']>;
  lease_id?: Maybe<Scalars['GUID']>;
  marketing_source?: Maybe<Scalars['String']>;
  missing_required_external_documents?: Maybe<Scalars['Boolean']>;
  needs_electronic_signature_verification?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  notification_subscribers?: Maybe<Array<Maybe<NotificationSubscriber>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  paperwork_type?: Maybe<PaperworkType>;
  pod?: Maybe<Pod>;
  pod_id?: Maybe<Scalars['ID']>;
  product?: Maybe<Product>;
  r1_contract_generation_date_utc?: Maybe<Scalars['DateTime']>;
  r1_contract_manually_validated?: Maybe<Scalars['Boolean']>;
  r1_contract_validation_errors_object?: Maybe<R1ContractValidationErrors>;
  r1_contract_validation_started_at?: Maybe<Scalars['DateTime']>;
  r1_contract_validation_warnings_object?: Maybe<R1ContractValidationWarnings>;
  r1_contract_worksheet_created_date_utc?: Maybe<Scalars['DateTime']>;
  r1_jacket_id?: Maybe<Scalars['String']>;
  r1_jacket_id_created_date_utc?: Maybe<Scalars['DateTime']>;
  referral_source?: Maybe<ReferralSource>;
  request_boot?: Maybe<Scalars['Boolean']>;
  sales_visibility?: Maybe<Scalars['Boolean']>;
  second_contact?: Maybe<DealContact>;
  set_date?: Maybe<Scalars['DateTime']>;
  setter?: Maybe<User>;
  setter_id?: Maybe<Scalars['ID']>;
  shipping_labels?: Maybe<Array<Maybe<ShippingLabel>>>;
  signing_on_com?: Maybe<Scalars['Boolean']>;
  sold_date?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  structuring_follow_up?: Maybe<StructuringFollowUp>;
  structuring_manager?: Maybe<User>;
  structuring_manager_id?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Tag>>;
  temporary_registration_tags_user?: Maybe<User>;
  temporary_registration_tags_user_id?: Maybe<Scalars['ID']>;
  title_clerk?: Maybe<User>;
  title_clerk2?: Maybe<User>;
  title_clerk2_id?: Maybe<Scalars['ID']>;
  title_clerk_id?: Maybe<Scalars['ID']>;
  titling_pod?: Maybe<Pod>;
  titling_pod_id?: Maybe<Scalars['ID']>;
  type?: Maybe<DealType>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type DealAssignmentLog = {
  __typename?: 'dealAssignmentLog';
  assignment_logs_object?: Maybe<AssignmentLogs>;
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type DealAssignmentLogInput = {
  assignment_logs_object?: InputMaybe<AssignmentLogsInput>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type DealContact = {
  __typename?: 'dealContact';
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  relation_to_buyer?: Maybe<RelationToBuyerEnum>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type DealContactInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type DealDates = {
  __typename?: 'dealDates';
  created_at?: Maybe<Scalars['DateTime']>;
  custom_dates?: Maybe<Scalars['JSON']>;
  dates?: Maybe<Scalars['JSON']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type DealDatesInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  custom_dates?: InputMaybe<Scalars['JSON']>;
  dates?: InputMaybe<Scalars['JSON']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type DealInput = {
  auto_import_variation?: InputMaybe<AutoImportVariation>;
  boot_reason?: InputMaybe<Scalars['JSONObject']>;
  called_by_financial_specialist?: InputMaybe<Scalars['DateTime']>;
  car?: InputMaybe<CarInput>;
  closer2_id?: InputMaybe<Scalars['ID']>;
  closer_id?: InputMaybe<Scalars['ID']>;
  cobuyer?: InputMaybe<CustomerInput>;
  completion_date_tz?: InputMaybe<Scalars['String']>;
  completion_date_utc?: InputMaybe<Scalars['DateTime']>;
  condition_report?: InputMaybe<ConditionReportInput>;
  contact?: InputMaybe<DealContactInput>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  creation_date_tz?: InputMaybe<Scalars['String']>;
  creation_date_utc?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerInput>;
  deal_assignment_log?: InputMaybe<DealAssignmentLogInput>;
  deal_dates?: InputMaybe<DealDatesInput>;
  deal_states?: InputMaybe<Array<InputMaybe<DealStateInput>>>;
  direct_pay_state?: InputMaybe<Scalars['Boolean']>;
  document_info?: InputMaybe<DocumentInfoInput>;
  document_progress_status?: InputMaybe<Scalars['String']>;
  financial_info?: InputMaybe<FinancialInfoInput>;
  financial_info_acquisition?: InputMaybe<FinancialInfoAcquisitionInput>;
  follow_up?: InputMaybe<FollowUpInput>;
  funding_clerk_id?: InputMaybe<Scalars['ID']>;
  has_problem?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  import_type?: InputMaybe<ImportType>;
  imported_date_utc?: InputMaybe<Scalars['DateTime']>;
  lease_id?: InputMaybe<Scalars['GUID']>;
  marketing_source?: InputMaybe<Scalars['String']>;
  missing_required_external_documents?: InputMaybe<Scalars['Boolean']>;
  needs_electronic_signature_verification?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  notification_subscribers?: InputMaybe<Array<InputMaybe<NotificationSubscriberInput>>>;
  notifications?: InputMaybe<Array<InputMaybe<NotificationInput>>>;
  paperwork_type?: InputMaybe<PaperworkType>;
  pod?: InputMaybe<PodInput>;
  pod_id?: InputMaybe<Scalars['ID']>;
  r1_contract_generation_date_utc?: InputMaybe<Scalars['DateTime']>;
  r1_contract_manually_validated?: InputMaybe<Scalars['Boolean']>;
  r1_contract_validation_errors_object?: InputMaybe<R1ContractValidationErrorsInput>;
  r1_contract_validation_started_at?: InputMaybe<Scalars['DateTime']>;
  r1_contract_validation_warnings_object?: InputMaybe<R1ContractValidationWarningsInput>;
  r1_contract_worksheet_created_date_utc?: InputMaybe<Scalars['DateTime']>;
  r1_jacket_id?: InputMaybe<Scalars['String']>;
  r1_jacket_id_created_date_utc?: InputMaybe<Scalars['DateTime']>;
  referral_source?: InputMaybe<ReferralSourceInput>;
  request_boot?: InputMaybe<Scalars['Boolean']>;
  sales_visibility?: InputMaybe<Scalars['Boolean']>;
  second_contact?: InputMaybe<DealContactInput>;
  set_date?: InputMaybe<Scalars['DateTime']>;
  setter_id?: InputMaybe<Scalars['ID']>;
  signing_on_com?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  structuring_follow_up?: InputMaybe<StructuringFollowUpInput>;
  structuring_manager_id?: InputMaybe<Scalars['ID']>;
  temporary_registration_tags_user_id?: InputMaybe<Scalars['ID']>;
  title_clerk2_id?: InputMaybe<Scalars['ID']>;
  title_clerk_id?: InputMaybe<Scalars['ID']>;
  titling_pod?: InputMaybe<PodInput>;
  titling_pod_id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<DealType>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type DealMedia = {
  __typename?: 'dealMedia';
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  has_verified_digital_signature?: Maybe<Scalars['Boolean']>;
  has_wet_signature?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  is_notarized?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  signed_url?: Maybe<Scalars['String']>;
  source?: Maybe<DealMediaSourceEnum>;
  state?: Maybe<DealMediaStateEnum>;
  type?: Maybe<DealMediaTypeEnum>;
  updated_at?: Maybe<Scalars['DateTime']>;
  uploaded_by_customer?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  wet_signature_required?: Maybe<Scalars['Boolean']>;
};

export type DealMediaInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  has_verified_digital_signature?: InputMaybe<Scalars['Boolean']>;
  has_wet_signature?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  is_notarized?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  signed_url?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<DealMediaSourceEnum>;
  state?: InputMaybe<DealMediaStateEnum>;
  type?: InputMaybe<DealMediaTypeEnum>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  uploaded_by_customer?: InputMaybe<Scalars['Boolean']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  wet_signature_required?: InputMaybe<Scalars['Boolean']>;
};

export enum DealMediaTypeEnum {
  AcuraSpecificOdometerDoc = 'acura_specific_odometer_doc',
  AgreementToFurnishInsurance = 'agreement_to_furnish_insurance',
  BackOfCoBuyersInsurance = 'back_of_co_buyers_insurance',
  BackOfCoBuyersLicense = 'back_of_co_buyers_license',
  BackOfDriversLicense = 'back_of_drivers_license',
  BackOfInsuranceCard = 'back_of_insurance_card',
  BookSheet = 'book_sheet',
  BuyersGuide = 'buyers_guide',
  CaliforniaStatementOfFacts = 'california_statement_of_facts',
  CaliforniaStatementOfFactsCobuyer = 'california_statement_of_facts_cobuyer',
  CobuyerRegistrationSpecificPowerOfAttorney = 'cobuyer_registration_specific_power_of_attorney',
  CobuyerSecurePowerOfAttorney = 'cobuyer_secure_power_of_attorney',
  CobuyerStateSpecificOdoDoc = 'cobuyer_state_specific_odo_doc',
  ColoradoCobuyerSupplementalIdentificationClause = 'colorado_cobuyer_supplemental_identification_clause',
  ColoradoJointTenancy = 'colorado_joint_tenancy',
  ColoradoSupplementalIdentificationClause = 'colorado_supplemental_identification_clause',
  ConnecticutElectronicRegistrationAndTitleAgreement = 'connecticut_electronic_registration_and_title_agreement',
  Contract = 'contract',
  CreditApplication = 'credit_application',
  CreditApproval = 'credit_approval',
  DamageDisclosure = 'damage_disclosure',
  DemandLetter = 'demand_letter',
  DownPayment = 'down_payment',
  ESignConsent = 'e_sign_consent',
  ElectronicSignatureAttestation = 'electronic_signature_attestation',
  ElectronicSignatureCertification = 'electronic_signature_certification',
  ElectronicSignatureVerification = 'electronic_signature_verification',
  Emissions = 'emissions',
  FrontOfCoBuyersInsurance = 'front_of_co_buyers_insurance',
  FrontOfCoBuyersLicense = 'front_of_co_buyers_license',
  FrontOfDriversLicense = 'front_of_drivers_license',
  FrontOfInsuranceCard = 'front_of_insurance_card',
  GmLeasePacket = 'gm_lease_packet',
  HondaSpecificOdometerDoc = 'honda_specific_odometer_doc',
  IdahoSalesTaxExemption = 'idaho_sales_tax_exemption',
  LeaseAgreement = 'lease_agreement',
  LeaseEndAcqContract = 'lease_end_acq_contract',
  LeaseEndCashback = 'lease_end_cashback',
  LeaseendOdoDoc = 'leaseend_odo_doc',
  LeaseendOdoDocCobuyer = 'leaseend_odo_doc_cobuyer',
  LeaseendOdoDocJoint = 'leaseend_odo_doc_joint',
  LicensePlate = 'license_plate',
  MembershipForm = 'membership_form',
  OdoDoc = 'odo_doc',
  OdometerPicture = 'odometer_picture',
  Other = 'other',
  PayoffAuthorization = 'payoff_authorization',
  PayoffDocs = 'payoff_docs',
  PhysicalSignatureCoverLetter = 'physical_signature_cover_letter',
  PicturesOfVehicle = 'pictures_of_vehicle',
  PlateTransferLetter = 'plate_transfer_letter',
  PorscheSpecificOdometerDoc = 'porsche_specific_odometer_doc',
  PorscheSpecificPayoffInstructions = 'porsche_specific_payoff_instructions',
  PowerOfAttorney = 'power_of_attorney',
  PowerOfAttorneyCobuyer = 'power_of_attorney_cobuyer',
  PrivacyPolicy = 'privacy_policy',
  ProofOfIncome = 'proof_of_income',
  ProofOfResidence = 'proof_of_residence',
  ProofOfResidence2 = 'proof_of_residence2',
  PropertyTaxReceipt = 'property_tax_receipt',
  PurchaseOrder = 'purchase_order',
  R1EContract = 'r1_e_contract',
  RateExceptionForm = 'rate_exception_form',
  ReassignmentDisclosure = 'reassignment_disclosure',
  Registration = 'registration',
  RegistrationComplete = 'registration_complete',
  RegistrationSpecificPowerOfAttorney = 'registration_specific_power_of_attorney',
  SafetyInspection = 'safety_inspection',
  SalesTaxExemption = 'sales_tax_exemption',
  SecurePowerOfAttorney = 'secure_power_of_attorney',
  SignedDocs = 'signed_docs',
  StateAndLienholderSpecificPowerOfAttorney = 'state_and_lienholder_specific_power_of_attorney',
  StateSpecificDamageDisclosure = 'state_specific_damage_disclosure',
  StateSpecificDocs = 'state_specific_docs',
  StateSpecificOdoDoc = 'state_specific_odo_doc',
  StateSpecificPowerOfAttorney = 'state_specific_power_of_attorney',
  StateSpecificPowerOfAttorneyCobuyer = 'state_specific_power_of_attorney_cobuyer',
  StateSpecificPurchaseOrder = 'state_specific_purchase_order',
  TaterDocs = 'tater_docs',
  TaxExemption = 'tax_exemption',
  TempTag = 'temp_tag',
  TexasHidalgoDoc = 'texas_hidalgo_doc',
  Title = 'title',
  TitleApplication = 'title_application',
  TitlePacket = 'title_packet',
  TitleRelease = 'title_release',
  VinInspection = 'vin_inspection',
  VscAndGap = 'vsc_and_gap',
  WetSignature = 'wet_signature',
}

export enum DealReadyType {
  Conditioned = 'CONDITIONED',
  Failed = 'FAILED',
  ImmediateImport = 'IMMEDIATE_IMPORT',
  Loading = 'LOADING',
  Ready = 'READY',
}

export type DealState = {
  __typename?: 'dealState';
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_date_utc?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['ID']>;
};

export enum DealStateEnum {
  Auction = 'AUCTION',
  Booted = 'BOOTED',
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Estimate = 'ESTIMATE',
  Finalized = 'FINALIZED',
  Floor = 'FLOOR',
  Funded = 'FUNDED',
  PaidOff = 'PAID_OFF',
  ReadyForPickup = 'READY_FOR_PICKUP',
  SendPayoff = 'SEND_PAYOFF',
  SentForSign = 'SENT_FOR_SIGN',
  SentToProcessor = 'SENT_TO_PROCESSOR',
  Signed = 'SIGNED',
  SoftClose = 'SOFT_CLOSE',
  Sold = 'SOLD',
  Structuring = 'STRUCTURING',
  StructuringProgress = 'STRUCTURING_PROGRESS',
  TitleReceived = 'TITLE_RECEIVED',
  TitleSent = 'TITLE_SENT',
  WaitingForTitle = 'WAITING_FOR_TITLE',
}

export type DealStateInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  updated_date_utc?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  user_id?: InputMaybe<Scalars['ID']>;
};

export type DealerInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contact_name?: InputMaybe<Scalars['String']>;
  dealership_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  observes_daylight_saving?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type Deals = {
  __typename?: 'deals';
  auto_import_variation?: Maybe<AutoImportVariation>;
  boot_reason?: Maybe<Scalars['JSONObject']>;
  called_by_financial_specialist?: Maybe<Scalars['DateTime']>;
  car?: Maybe<Car>;
  closer2_id?: Maybe<Scalars['ID']>;
  closer_id?: Maybe<Scalars['ID']>;
  cobuyer?: Maybe<Customer>;
  completion_date_tz?: Maybe<Scalars['String']>;
  completion_date_utc?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  creation_date_tz?: Maybe<Scalars['String']>;
  creation_date_utc?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<Customer>;
  document_progress_status?: Maybe<Scalars['String']>;
  finalized_date?: Maybe<Scalars['DateTime']>;
  funding_clerk_id?: Maybe<Scalars['ID']>;
  hasRecentCommunication?: Maybe<Scalars['Boolean']>;
  has_problem?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  import_type?: Maybe<ImportType>;
  imported_date_utc?: Maybe<Scalars['DateTime']>;
  isCobuyer?: Maybe<Scalars['Boolean']>;
  lease_id?: Maybe<Scalars['GUID']>;
  marketing_source?: Maybe<Scalars['String']>;
  missing_required_external_documents?: Maybe<Scalars['Boolean']>;
  needs_electronic_signature_verification?: Maybe<Scalars['Boolean']>;
  paperwork_type?: Maybe<PaperworkType>;
  pod_id?: Maybe<Scalars['ID']>;
  r1_contract_generation_date_utc?: Maybe<Scalars['DateTime']>;
  r1_contract_manually_validated?: Maybe<Scalars['Boolean']>;
  r1_contract_validation_errors_object?: Maybe<R1ContractValidationErrors>;
  r1_contract_validation_started_at?: Maybe<Scalars['DateTime']>;
  r1_contract_validation_warnings_object?: Maybe<R1ContractValidationWarnings>;
  r1_contract_worksheet_created_date_utc?: Maybe<Scalars['DateTime']>;
  r1_jacket_id?: Maybe<Scalars['String']>;
  r1_jacket_id_created_date_utc?: Maybe<Scalars['DateTime']>;
  request_boot?: Maybe<Scalars['Boolean']>;
  sales_visibility?: Maybe<Scalars['Boolean']>;
  set_date?: Maybe<Scalars['DateTime']>;
  setter_id?: Maybe<Scalars['ID']>;
  signing_on_com?: Maybe<Scalars['Boolean']>;
  sold_date?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  structuring_manager_id?: Maybe<Scalars['ID']>;
  temporary_registration_tags_user_id?: Maybe<Scalars['ID']>;
  title_clerk2_id?: Maybe<Scalars['ID']>;
  title_clerk_id?: Maybe<Scalars['ID']>;
  titling_pod_id?: Maybe<Scalars['ID']>;
  type?: Maybe<DealType>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type DealsWithNotification = {
  __typename?: 'dealsWithNotification';
  bootedDealsNotificationsMessages?: Maybe<Scalars['Int']>;
  bootedDealsNotificationsNotMessages?: Maybe<Scalars['Int']>;
  completedDealsNotificationsMessages?: Maybe<Scalars['Int']>;
  completedDealsNotificationsNotMessages?: Maybe<Scalars['Int']>;
  followUpDealsNotificationsMessages?: Maybe<Scalars['Int']>;
  followUpDealsNotificationsNotMessages?: Maybe<Scalars['Int']>;
};

export type Decision = {
  __typename?: 'decision';
  conditionRejection?: Maybe<Array<Maybe<ConditionRejection>>>;
  decisionComments?: Maybe<Array<Maybe<Scalars['String']>>>;
  financing?: Maybe<Financing>;
  stipulations?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Deductible = {
  __typename?: 'deductible';
  amount?: Maybe<Scalars['String']>;
  dealer_cost?: Maybe<Scalars['String']>;
  deductible_type?: Maybe<Scalars['String']>;
  f_i_markup?: Maybe<Scalars['String']>;
  max_retail_price?: Maybe<Scalars['String']>;
  min_retail_price?: Maybe<Scalars['String']>;
  rate_id?: Maybe<Scalars['Int']>;
  reduced_amount?: Maybe<Scalars['String']>;
  retail_price?: Maybe<Scalars['String']>;
};

export type Destinations = {
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type DetermineApproval = {
  __typename?: 'determineApproval';
  approved?: Maybe<Scalars['Boolean']>;
  failedCriteria?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DetermineApprovalInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  book_value?: InputMaybe<Scalars['Float']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  kbb_trim?: InputMaybe<Scalars['String']>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  smoked_in?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  temporary_info_id?: InputMaybe<Scalars['String']>;
  tires?: InputMaybe<TiresEnumType>;
  vehicle_type?: InputMaybe<Scalars['String']>;
};

export type DetermineApprovalQueryInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  book_value?: InputMaybe<Scalars['Float']>;
  color?: InputMaybe<Scalars['String']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  financial_info_acquisition_id?: InputMaybe<Scalars['ID']>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  kbb_trim?: InputMaybe<Scalars['String']>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']>;
  mileage?: InputMaybe<Scalars['Float']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  payoff?: InputMaybe<Scalars['Float']>;
  smoked_in?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  tires?: InputMaybe<TiresEnumType>;
  vehicle_type?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type DocumentInfo = {
  __typename?: 'documentInfo';
  adobe_agreement_id?: Maybe<Scalars['String']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
};

export type DocumentInfoInput = {
  adobe_agreement_id?: InputMaybe<Scalars['String']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Documents = {
  __typename?: 'documents';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  files?: Maybe<Array<Maybe<Files>>>;
};

export type DocumentsInfoUpdateInput = {
  hasCobuyer?: InputMaybe<Scalars['Boolean']>;
  movedStates?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  vehicleYear?: InputMaybe<Scalars['Int']>;
};

export type DocumentsInput = {
  deal_id?: InputMaybe<Scalars['ID']>;
  internal_media?: InputMaybe<Array<InputMaybe<DealMediaTypeEnum>>>;
};

export enum DownPaymentStatus {
  Completed = 'COMPLETED',
  ManuallyCompleted = 'MANUALLY_COMPLETED',
  Na = 'NA',
  Requested = 'REQUESTED',
  Sent = 'SENT',
}

export type Employment = {
  __typename?: 'employment';
  customer_id?: Maybe<Scalars['ID']>;
  employment_type?: Maybe<EmploymentTypeType>;
  gross_income?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  job_title?: Maybe<Scalars['String']>;
  months_at_job?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pay_frequency?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  status?: Maybe<EmploymentStatusType>;
  years_at_job?: Maybe<Scalars['Int']>;
};

export enum EmploymentEnumType {
  FullTime = 'full_time',
  PartTime = 'part_time',
}

export type EmploymentInput = {
  customer_id?: InputMaybe<Scalars['ID']>;
  employment_type?: InputMaybe<EmploymentTypeType>;
  gross_income?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  job_title?: InputMaybe<Scalars['String']>;
  months_at_job?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  pay_frequency?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EmploymentStatusType>;
  years_at_job?: InputMaybe<Scalars['Int']>;
};

export enum EmploymentStatusType {
  Employed = 'employed',
  NotEmployed = 'not_employed',
  Retired = 'retired',
}

export enum EmploymentTypeType {
  Current = 'current',
  Previous = 'previous',
}

export enum EndSalesFlowReasonEnum {
  ConditionedFailedDealProcessing = 'CONDITIONED_FAILED_DEAL_PROCESSING',
  DocumentsFinishedFlow = 'DOCUMENTS_FINISHED_FLOW',
  FailedDealProcessing = 'FAILED_DEAL_PROCESSING',
  FinishedFlow = 'FINISHED_FLOW',
  ImmediateImport = 'IMMEDIATE_IMPORT',
  NoKbbValuesFailure = 'NO_KBB_VALUES_FAILURE',
  OneDayInactivity = 'ONE_DAY_INACTIVITY',
  TimedOut = 'TIMED_OUT',
}

export enum ErrorCodeEnum {
  CardPaymentLimitExceeded = 'CARD_PAYMENT_LIMIT_EXCEEDED',
  CardTypeMismatch = 'CARD_TYPE_MISMATCH',
  Forbidden = 'FORBIDDEN',
  ResetGuidError = 'RESET_GUID_ERROR',
  TempInfoNotFound = 'TEMP_INFO_NOT_FOUND',
  Unauthenticated = 'UNAUTHENTICATED',
}

export enum ExteriorEnumType {
  DentsOrDings = 'dents_or_dings',
  GlassWindshieldDamage = 'glass_windshield_damage',
  HailDamage = 'hail_damage',
  MinorScuffs = 'minor_scuffs',
  PaintDamage = 'paint_damage',
  Rusting = 'rusting',
}

export type ExternalDealUploadUrl = {
  __typename?: 'externalDealUploadUrl';
  key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ExternalTempInfoUploadUrl = {
  __typename?: 'externalTempInfoUploadUrl';
  url?: Maybe<Scalars['String']>;
};

export type ExtraTempInfoInputType = {
  address_line?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  prequal_id?: InputMaybe<Scalars['ID']>;
  shopping_cart?: InputMaybe<TemporaryShoppingCartDataInput>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type ExtractedDocumentData = {
  __typename?: 'extractedDocumentData';
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  dl_address?: Maybe<Scalars['String']>;
  dl_city?: Maybe<Scalars['String']>;
  dl_expiration_date?: Maybe<Scalars['String']>;
  dl_first_name?: Maybe<Scalars['String']>;
  dl_last_name?: Maybe<Scalars['String']>;
  dl_middle_name?: Maybe<Scalars['String']>;
  dl_state?: Maybe<Scalars['String']>;
  dl_zip?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  insurance_company?: Maybe<Scalars['String']>;
  insurance_company_confidence?: Maybe<Scalars['Int']>;
  insurance_expiration?: Maybe<Scalars['String']>;
  insurance_expiration_confidence?: Maybe<Scalars['Int']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_name_confidence?: Maybe<Scalars['Int']>;
  insurance_policy_number?: Maybe<Scalars['String']>;
  insurance_policy_number_confidence?: Maybe<Scalars['Int']>;
  insurance_state?: Maybe<Scalars['String']>;
  insurance_state_confidence?: Maybe<Scalars['Int']>;
  insurance_vin?: Maybe<Scalars['String']>;
  insurance_vin_confidence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Files = {
  __typename?: 'files';
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  mediaType?: Maybe<DealMediaTypeEnum>;
  url?: Maybe<Scalars['String']>;
};

export type FinancialInfo = {
  __typename?: 'financialInfo';
  amount_financed?: Maybe<Scalars['Float']>;
  bank?: Maybe<Scalars['String']>;
  bank_fees?: Maybe<Scalars['Float']>;
  base_tax_amount?: Maybe<Scalars['Float']>;
  buy_rate?: Maybe<Scalars['Float']>;
  buyer_not_lessee?: Maybe<Scalars['Boolean']>;
  closer_commission?: Maybe<Scalars['Float']>;
  com_rate_markup?: Maybe<Scalars['Float']>;
  commissionable_profit?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['DateTime']>;
  credit_card_payment_amount_limit?: Maybe<Scalars['Float']>;
  credit_debit_payment_amount_allowed?: Maybe<Scalars['Float']>;
  credit_payment_amount_allowed?: Maybe<Scalars['Float']>;
  credit_payment_amount_limit?: Maybe<Scalars['Float']>;
  days_to_payment?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['ID']>;
  debit_payment_amount_allowed?: Maybe<Scalars['Float']>;
  debit_payment_amount_limit?: Maybe<Scalars['Float']>;
  doc_fee?: Maybe<Scalars['Float']>;
  down_payment_status?: Maybe<DownPaymentStatus>;
  fee_processor?: Maybe<Scalars['String']>;
  first_payment_date?: Maybe<Scalars['Date']>;
  gap_cost?: Maybe<Scalars['Float']>;
  gap_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  money_down?: Maybe<Scalars['Float']>;
  needs_temporary_registration_tags?: Maybe<Scalars['Boolean']>;
  new_lienholder?: Maybe<Bank>;
  new_registration_fee?: Maybe<Scalars['Float']>;
  option_type?: Maybe<Scalars['String']>;
  payment?: Maybe<Scalars['Float']>;
  pen_gap_form_id?: Maybe<Scalars['Int']>;
  pen_gap_rate_id?: Maybe<Scalars['Int']>;
  pen_gap_session_id?: Maybe<Scalars['String']>;
  pen_vsc_form_id?: Maybe<Scalars['Int']>;
  pen_vsc_rate_id?: Maybe<Scalars['Int']>;
  pen_vsc_session_id?: Maybe<Scalars['String']>;
  plate_transfer?: Maybe<Scalars['Boolean']>;
  processor?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['Float']>;
  quick_notes?: Maybe<Scalars['String']>;
  registration_transfer_fee?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  sales_tax_rate?: Maybe<Scalars['Float']>;
  selected_credit_decision?: Maybe<CreditDecision>;
  selected_credit_decision_id?: Maybe<Scalars['ID']>;
  sell_rate?: Maybe<Scalars['Float']>;
  setter_commission?: Maybe<Scalars['Float']>;
  tax_processor?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  title_fee?: Maybe<Scalars['Float']>;
  title_registration_option?: Maybe<TitleRegistrationOption>;
  tt_transaction_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_entered_reserve?: Maybe<Scalars['Float']>;
  vsc_cost?: Maybe<Scalars['Float']>;
  vsc_price?: Maybe<Scalars['Float']>;
  vsc_term?: Maybe<Scalars['String']>;
  vsc_type?: Maybe<Scalars['String']>;
  warranty_tax_amount?: Maybe<Scalars['Float']>;
};

export type FinancialInfoAcquisition = {
  __typename?: 'financialInfoAcquisition';
  appraised_value?: Maybe<Scalars['Float']>;
  auction_fees?: Maybe<Scalars['Float']>;
  auction_id?: Maybe<Scalars['ID']>;
  cash_to_customer?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['ID']>;
  estimated_dealer_fees?: Maybe<Scalars['Float']>;
  gross_profit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  is_approved?: Maybe<Scalars['Boolean']>;
  kbb_lending?: Maybe<Scalars['Float']>;
  max_cash_to_customer?: Maybe<Scalars['Float']>;
  max_total_cost?: Maybe<Scalars['Float']>;
  offer?: Maybe<Scalars['Float']>;
  overriding_user_id?: Maybe<Scalars['String']>;
  sell_price?: Maybe<Scalars['Float']>;
  total_cost?: Maybe<Scalars['Float']>;
  transport_cost?: Maybe<Scalars['Float']>;
};

export type FinancialInfoAcquisitionInput = {
  appraised_value?: InputMaybe<Scalars['Float']>;
  auction_fees?: InputMaybe<Scalars['Float']>;
  auction_id?: InputMaybe<Scalars['ID']>;
  cash_to_customer?: InputMaybe<Scalars['Float']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  estimated_dealer_fees?: InputMaybe<Scalars['Float']>;
  gross_profit?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
  kbb_lending?: InputMaybe<Scalars['Float']>;
  max_cash_to_customer?: InputMaybe<Scalars['Float']>;
  max_total_cost?: InputMaybe<Scalars['Float']>;
  offer?: InputMaybe<Scalars['Float']>;
  overriding_user_id?: InputMaybe<Scalars['String']>;
  sell_price?: InputMaybe<Scalars['Float']>;
  total_cost?: InputMaybe<Scalars['Float']>;
  transport_cost?: InputMaybe<Scalars['Float']>;
};

export type FinancialInfoInput = {
  amount_financed?: InputMaybe<Scalars['Float']>;
  bank?: InputMaybe<Scalars['String']>;
  bank_fees?: InputMaybe<Scalars['Float']>;
  base_tax_amount?: InputMaybe<Scalars['Float']>;
  buy_rate?: InputMaybe<Scalars['Float']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']>;
  closer_commission?: InputMaybe<Scalars['Float']>;
  com_rate_markup?: InputMaybe<Scalars['Float']>;
  commissionable_profit?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  credit_card_payment_amount_limit?: InputMaybe<Scalars['Float']>;
  days_to_payment?: InputMaybe<Scalars['Int']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  doc_fee?: InputMaybe<Scalars['Float']>;
  down_payment_status?: InputMaybe<DownPaymentStatus>;
  fee_processor?: InputMaybe<Scalars['String']>;
  first_payment_date?: InputMaybe<Scalars['Date']>;
  gap_cost?: InputMaybe<Scalars['Float']>;
  gap_price?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  money_down?: InputMaybe<Scalars['Float']>;
  needs_temporary_registration_tags?: InputMaybe<Scalars['Boolean']>;
  new_lienholder?: InputMaybe<BankInput>;
  new_registration_fee?: InputMaybe<Scalars['Float']>;
  option_type?: InputMaybe<Scalars['String']>;
  payment?: InputMaybe<Scalars['Float']>;
  pen_gap_form_id?: InputMaybe<Scalars['Int']>;
  pen_gap_rate_id?: InputMaybe<Scalars['Int']>;
  pen_gap_session_id?: InputMaybe<Scalars['String']>;
  pen_vsc_form_id?: InputMaybe<Scalars['Int']>;
  pen_vsc_rate_id?: InputMaybe<Scalars['Int']>;
  pen_vsc_session_id?: InputMaybe<Scalars['String']>;
  plate_transfer?: InputMaybe<Scalars['Boolean']>;
  processor?: InputMaybe<Scalars['String']>;
  profit?: InputMaybe<Scalars['Float']>;
  quick_notes?: InputMaybe<Scalars['String']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']>;
  reserve?: InputMaybe<Scalars['Float']>;
  sales_tax_rate?: InputMaybe<Scalars['Float']>;
  selected_credit_decision_id?: InputMaybe<Scalars['ID']>;
  sell_rate?: InputMaybe<Scalars['Float']>;
  setter_commission?: InputMaybe<Scalars['Float']>;
  tax_processor?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['Int']>;
  title_fee?: InputMaybe<Scalars['Float']>;
  title_registration_option?: InputMaybe<TitleRegistrationOption>;
  tt_transaction_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  user_entered_reserve?: InputMaybe<Scalars['Float']>;
  vsc_cost?: InputMaybe<Scalars['Float']>;
  vsc_price?: InputMaybe<Scalars['Float']>;
  vsc_term?: InputMaybe<Scalars['String']>;
  vsc_type?: InputMaybe<Scalars['String']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']>;
};

export type Financing = {
  __typename?: 'financing';
  annualPercentageRate?: Maybe<Scalars['Float']>;
  downPaymentAmount?: Maybe<Scalars['Float']>;
  finalAmount?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
};

export type FinixTransfer = {
  __typename?: 'finixTransfer';
  amount?: Maybe<Scalars['Float']>;
  cardBrand?: Maybe<Scalars['String']>;
  cardType?: Maybe<CardType>;
  failureCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maskedNumber?: Maybe<Scalars['String']>;
  state?: Maybe<PaymentState>;
};

export type FlowStatus = {
  __typename?: 'flowStatus';
  car?: Maybe<Car>;
  customerId?: Maybe<Scalars['String']>;
  dealId?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  inFlow?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FollowUp = {
  __typename?: 'followUp';
  created_at?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  creator_id?: Maybe<Scalars['ID']>;
  date_utc?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  status?: Maybe<FollowUpStatusEnum>;
  type?: Maybe<FollowUpTypeEnum>;
  tz?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type FollowUpInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  creator_id?: InputMaybe<Scalars['ID']>;
  date_utc?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  note?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FollowUpStatusEnum>;
  type?: InputMaybe<FollowUpTypeEnum>;
  tz?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export enum FollowUpStatusEnum {
  Completed = 'completed',
  Deleted = 'deleted',
  Due = 'due',
  Scheduled = 'scheduled',
}

export enum FollowUpTypeEnum {
  Appointment = 'appointment',
  Reminder = 'reminder',
}

export type ForceTitleOnlyHelperLink = {
  __typename?: 'forceTitleOnlyHelperLink';
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GetAvailableUsers = {
  __typename?: 'getAvailableUsers';
  auto_assign_deals?: Maybe<Scalars['Boolean']>;
  call_status?: Maybe<Scalars['String']>;
  can_claim_as_closer?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  in_call?: Maybe<Scalars['String']>;
  max_auto_assign_deals?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  on_vacation?: Maybe<Scalars['Boolean']>;
  overnight_deals?: Maybe<Scalars['Boolean']>;
  phone_number?: Maybe<Scalars['String']>;
  recruiter_id?: Maybe<Scalars['String']>;
  twilio_number?: Maybe<Scalars['String']>;
};

export type GetPackageOptions = {
  __typename?: 'getPackageOptions';
  gapMonthlyCost?: Maybe<Scalars['Float']>;
  isGapOffered?: Maybe<Scalars['Boolean']>;
  isVscOffered?: Maybe<Scalars['Boolean']>;
  vscPackageOptions?: Maybe<Vsc>;
};

export type GetWaitingCalls = {
  __typename?: 'getWaitingCalls';
  data?: Maybe<Scalars['JSONObject']>;
};

export enum ImportType {
  AcquisitionAutoImport = 'ACQUISITION_AUTO_IMPORT',
  AcquisitionFlowFinished = 'ACQUISITION_FLOW_FINISHED',
  Affiliate = 'AFFILIATE',
  AffiliateInactivityImport = 'AFFILIATE_INACTIVITY_IMPORT',
  AutoStructureConditioned = 'AUTO_STRUCTURE_CONDITIONED',
  AutoStructureFailure = 'AUTO_STRUCTURE_FAILURE',
  AutoStructureSuccessImmediateImport = 'AUTO_STRUCTURE_SUCCESS_IMMEDIATE_IMPORT',
  AutoStructureTimeout = 'AUTO_STRUCTURE_TIMEOUT',
  DocumentsPageFinished = 'DOCUMENTS_PAGE_FINISHED',
  FrozenOrLockedCredit = 'FROZEN_OR_LOCKED_CREDIT',
  HighLtvRatioAutoImport = 'HIGH_LTV_RATIO_AUTO_IMPORT',
  InactivityImport = 'INACTIVITY_IMPORT',
  ManualImportPostHardPull = 'MANUAL_IMPORT_POST_HARD_PULL',
  ManualImportPostSoftPull = 'MANUAL_IMPORT_POST_SOFT_PULL',
  ManualImportPreSoftPull = 'MANUAL_IMPORT_PRE_SOFT_PULL',
  MarketingNurture = 'MARKETING_NURTURE',
  NoKbbValuesFailure = 'NO_KBB_VALUES_FAILURE',
  OtherLienholder = 'OTHER_LIENHOLDER',
  PayoffRequestFailure = 'PAYOFF_REQUEST_FAILURE',
  PayoffRequestSuccess = 'PAYOFF_REQUEST_SUCCESS',
  PostHardPullAutoImport = 'POST_HARD_PULL_AUTO_IMPORT',
  SoftPullFailure = 'SOFT_PULL_FAILURE',
  SoftPullFailureAutoImport = 'SOFT_PULL_FAILURE_AUTO_IMPORT',
  SoftPullSuccessAutoImport = 'SOFT_PULL_SUCCESS_AUTO_IMPORT',
}

export enum InboxFilterByEnum {
  All = 'all',
  AllSalesPods = 'all_sales_pods',
  AllTitlePods = 'all_title_pods',
  MyLanes = 'my_lanes',
  MyTeams = 'my_teams',
  SalesPod = 'sales_pod',
  TitlePod = 'title_pod',
  YourMessages = 'your_messages',
}

export type InboxMessagesType = {
  __typename?: 'inboxMessagesType';
  messages?: Maybe<Array<Maybe<Message>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export enum InboxOrderByEnum {
  DateNewest = 'date_newest',
  DateOldest = 'date_oldest',
  Deal = 'deal',
}

export enum IncomeIntervalEnumType {
  Biweekly = 'biweekly',
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly',
}

export enum InteriorEnumType {
  EntertainmentConsoleDamage = 'entertainment_console_damage',
  RipsOrTears = 'rips_or_tears',
  SmellsOrOdors = 'smells_or_odors',
  Stains = 'stains',
}

export type JdpVehicleAccessories = {
  __typename?: 'jdpVehicleAccessories';
  acccode?: Maybe<Scalars['String']>;
  accdesc?: Maybe<Scalars['String']>;
  accessorycategory?: Maybe<Scalars['String']>;
  excludes?: Maybe<Scalars['String']>;
  includes?: Maybe<Scalars['String']>;
  isadded?: Maybe<Scalars['Int']>;
  isincluded?: Maybe<Scalars['Int']>;
  loan?: Maybe<Scalars['Int']>;
  retail?: Maybe<Scalars['Int']>;
  tradein?: Maybe<Scalars['Int']>;
};

export type JdpVehicleAccessoriesInput = {
  acccode?: InputMaybe<Scalars['String']>;
  accdesc?: InputMaybe<Scalars['String']>;
  accessorycategory?: InputMaybe<Scalars['String']>;
  excludes?: InputMaybe<Scalars['String']>;
  includes?: InputMaybe<Scalars['String']>;
  isadded?: InputMaybe<Scalars['Int']>;
  isincluded?: InputMaybe<Scalars['Int']>;
  loan?: InputMaybe<Scalars['Int']>;
  retail?: InputMaybe<Scalars['Int']>;
  tradein?: InputMaybe<Scalars['Int']>;
};

export type JdpVehicleAccessoriesObject = {
  __typename?: 'jdpVehicleAccessoriesObject';
  vehicle_accessories?: Maybe<Array<Maybe<JdpVehicleAccessories>>>;
};

export type JdpVehicleAccessoriesObjectInput = {
  vehicle_accessories?: InputMaybe<Array<InputMaybe<JdpVehicleAccessoriesInput>>>;
};

export type KbbConfigType = {
  action?: InputMaybe<Scalars['String']>;
  optionId?: InputMaybe<Scalars['Int']>;
};

export type KbbOption = {
  __typename?: 'kbbOption';
  categoryGroup?: Maybe<Scalars['String']>;
  hasRelationships?: Maybe<Scalars['Boolean']>;
  isConfigurable?: Maybe<Scalars['Boolean']>;
  isConsumer?: Maybe<Scalars['Boolean']>;
  isTypical?: Maybe<Scalars['Boolean']>;
  isVinDecoded?: Maybe<Scalars['Boolean']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  vehicleId?: Maybe<Scalars['Int']>;
  vehicleOptionId?: Maybe<Scalars['Int']>;
};

export type KbbOptionInput = {
  categoryGroup?: InputMaybe<Scalars['String']>;
  hasRelationships?: InputMaybe<Scalars['Boolean']>;
  isConfigurable?: InputMaybe<Scalars['Boolean']>;
  isConsumer?: InputMaybe<Scalars['Boolean']>;
  isTypical?: InputMaybe<Scalars['Boolean']>;
  isVinDecoded?: InputMaybe<Scalars['Boolean']>;
  optionName?: InputMaybe<Scalars['String']>;
  optionType?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['Int']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
  vehicleOptionId?: InputMaybe<Scalars['Int']>;
};

export type KbbSelectedOptionsType = {
  __typename?: 'kbbSelectedOptionsType';
  id?: Maybe<Scalars['ID']>;
  lendingOptionPrice?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  retailOptionPrice?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type KbbValuesDataType = {
  allKbbVehicleOptions?: InputMaybe<Array<InputMaybe<KbbOptionInput>>>;
  mileage?: InputMaybe<Scalars['Int']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
  vehicleOptionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type KbbValuesResult = {
  __typename?: 'kbbValuesResult';
  kbbSelectedOptions?: Maybe<Array<Maybe<KbbSelectedOptionsType>>>;
  lending?: Maybe<Scalars['Float']>;
  lendingMileageAdjustment?: Maybe<Scalars['Float']>;
  lendingOptionAdjustment?: Maybe<Scalars['Float']>;
  lendingOptionPrices?: Maybe<Scalars['JSON']>;
  retail?: Maybe<Scalars['Float']>;
  retailMileageAdjustment?: Maybe<Scalars['Float']>;
  retailOptionAdjustment?: Maybe<Scalars['Float']>;
  retailOptionPrices?: Maybe<Scalars['JSON']>;
  valuationDate?: Maybe<Scalars['String']>;
};

export type KbbVinObjectResult = {
  __typename?: 'kbbVinObjectResult';
  vinResults?: Maybe<Array<Maybe<KbbVinResult>>>;
  warning?: Maybe<Scalars['Boolean']>;
};

export type KbbVinResult = {
  __typename?: 'kbbVinResult';
  makeName?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  trimName?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['Int']>;
  vehicleName?: Maybe<Scalars['String']>;
  vehicleOptions?: Maybe<Array<Maybe<KbbOption>>>;
  yearId?: Maybe<Scalars['String']>;
};

export type KeyMetric = {
  __typename?: 'keyMetric';
  keyMetric?: Maybe<Scalars['String']>;
  value?: Maybe<Value>;
};

export type LaneKeyMetric = {
  __typename?: 'laneKeyMetric';
  day_count?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

export type LeaderboardSetterStats = {
  __typename?: 'leaderboardSetterStats';
  numApps?: Maybe<Scalars['Int']>;
  repId?: Maybe<Scalars['String']>;
  repName?: Maybe<Scalars['String']>;
  teamType?: Maybe<TeamType>;
};

export type Lending_Tree_Decision_Response = {
  __typename?: 'lending_tree_decision_response';
  offers?: Maybe<Array<Maybe<Lending_Tree_Offer>>>;
  partnerDecision?: Maybe<Scalars['String']>;
};

export type Lending_Tree_Offer = {
  __typename?: 'lending_tree_offer';
  amount?: Maybe<Scalars['Float']>;
  apr?: Maybe<Scalars['Float']>;
  monthlyPayment?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
};

export enum MaritalStatusEnum {
  Married = 'married',
  NotMarried = 'not_married',
  Separated = 'separated',
  Widowed = 'widowed',
}

export type MarketPrequalification = {
  __typename?: 'marketPrequalification';
  creditScoreTier: Scalars['String'];
  prequalId: Scalars['ID'];
};

export type MediaListData = {
  __typename?: 'mediaListData';
  contentType?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MediaListDataInput = {
  contentType?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type MediaListInputObj = {
  data?: InputMaybe<Array<InputMaybe<MediaListDataInput>>>;
};

export type MediaListObj = {
  __typename?: 'mediaListObj';
  data?: Maybe<Array<Maybe<MediaListData>>>;
};

export type MediaUploadUrl = {
  __typename?: 'mediaUploadUrl';
  key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MergedDocuments = {
  __typename?: 'mergedDocuments';
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'message';
  body?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  dateCreatedTz?: Maybe<Scalars['String']>;
  deals?: Maybe<Array<Maybe<Deal>>>;
  from?: Maybe<Scalars['String']>;
  fromCustomer?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  mediaListObj?: Maybe<MediaListObj>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  senderName?: Maybe<Scalars['String']>;
  sid?: Maybe<Scalars['String']>;
};

export type MessageData = {
  __typename?: 'messageData';
  deals?: Maybe<Array<Maybe<Deal>>>;
  message?: Maybe<Message>;
  messageDetail?: Maybe<MessageDetail>;
};

export type MessageDetail = {
  __typename?: 'messageDetail';
  dealIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dealStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MessagesDbPaginated = {
  __typename?: 'messagesDbPaginated';
  hasMoreMessages: Scalars['Boolean'];
  messages: Array<Maybe<Message>>;
};

export type MessagesWithErrors = {
  __typename?: 'messagesWithErrors';
  hasErrors?: Maybe<Scalars['Boolean']>;
  messages?: Maybe<Array<Maybe<Message>>>;
};

export type MetricsPages = {
  __typename?: 'metricsPages';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  iframe_url?: Maybe<Scalars['String']>;
  link_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permission_name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type MmrResult = {
  __typename?: 'mmrResult';
  average?: Maybe<Scalars['Int']>;
};

export type NewEstimateCustomerInput = {
  address?: InputMaybe<AddressInput>;
  auth0_id?: InputMaybe<Scalars['String']>;
  contact_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  dashboard_visited?: InputMaybe<Scalars['Boolean']>;
  dl_expiration_date?: InputMaybe<Scalars['DateTime']>;
  dob?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  employment?: InputMaybe<EmploymentInput>;
  first_name?: InputMaybe<Scalars['String']>;
  has_same_address_as_cobuyer?: InputMaybe<Scalars['Boolean']>;
  home_phone_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  last_authenticated_route?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  marital_status?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  name_suffix?: InputMaybe<Scalars['String']>;
  no_email?: InputMaybe<Scalars['Boolean']>;
  payoff_email_sent_at?: InputMaybe<Scalars['DateTime']>;
  phone_number?: InputMaybe<Scalars['String']>;
  relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  sent_account_email?: InputMaybe<Scalars['Boolean']>;
  sent_finalized_email?: InputMaybe<Scalars['DateTime']>;
  sent_review_email?: InputMaybe<Scalars['DateTime']>;
  sent_to_processor_email_sent_at?: InputMaybe<Scalars['DateTime']>;
  ssn?: InputMaybe<Scalars['String']>;
  title_received_email_sent_at?: InputMaybe<Scalars['DateTime']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type NewEstimateFileInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type NewEstimateFinancialInfoInput = {
  amount_financed?: InputMaybe<Scalars['Float']>;
  bank?: InputMaybe<Scalars['String']>;
  bank_fees?: InputMaybe<Scalars['Float']>;
  base_tax_amount?: InputMaybe<Scalars['Float']>;
  buy_rate?: InputMaybe<Scalars['Float']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']>;
  closer_commission?: InputMaybe<Scalars['Float']>;
  com_rate_markup?: InputMaybe<Scalars['Float']>;
  commissionable_profit?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  credit_card_payment_amount_limit?: InputMaybe<Scalars['Float']>;
  days_to_payment?: InputMaybe<Scalars['Int']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  doc_fee?: InputMaybe<Scalars['Float']>;
  down_payment_status?: InputMaybe<DownPaymentStatus>;
  fee_processor?: InputMaybe<Scalars['String']>;
  first_payment_date?: InputMaybe<Scalars['Date']>;
  gap_cost?: InputMaybe<Scalars['Float']>;
  gap_price?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  money_down?: InputMaybe<Scalars['Float']>;
  needs_temporary_registration_tags?: InputMaybe<Scalars['Boolean']>;
  new_registration_fee?: InputMaybe<Scalars['Float']>;
  option_type?: InputMaybe<Scalars['String']>;
  payment?: InputMaybe<Scalars['Float']>;
  pen_gap_form_id?: InputMaybe<Scalars['Int']>;
  pen_gap_rate_id?: InputMaybe<Scalars['Int']>;
  pen_gap_session_id?: InputMaybe<Scalars['String']>;
  pen_vsc_form_id?: InputMaybe<Scalars['Int']>;
  pen_vsc_rate_id?: InputMaybe<Scalars['Int']>;
  pen_vsc_session_id?: InputMaybe<Scalars['String']>;
  plate_transfer?: InputMaybe<Scalars['Boolean']>;
  processor?: InputMaybe<Scalars['String']>;
  profit?: InputMaybe<Scalars['Float']>;
  quick_notes?: InputMaybe<Scalars['String']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']>;
  reserve?: InputMaybe<Scalars['Float']>;
  sales_tax_rate?: InputMaybe<Scalars['Float']>;
  selected_credit_decision_id?: InputMaybe<Scalars['ID']>;
  sell_rate?: InputMaybe<Scalars['Float']>;
  setter_commission?: InputMaybe<Scalars['Float']>;
  tax_processor?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['Int']>;
  title_fee?: InputMaybe<Scalars['Float']>;
  title_registration_option?: InputMaybe<TitleRegistrationOption>;
  tt_transaction_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  user_entered_reserve?: InputMaybe<Scalars['Float']>;
  vsc_cost?: InputMaybe<Scalars['Float']>;
  vsc_price?: InputMaybe<Scalars['Float']>;
  vsc_term?: InputMaybe<Scalars['String']>;
  vsc_type?: InputMaybe<Scalars['String']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']>;
};

export type NewEstimateUpsertInput = {
  called_by_financial_specialist?: InputMaybe<Scalars['DateTime']>;
  car?: InputMaybe<CarInput>;
  closer_id?: InputMaybe<Scalars['String']>;
  creation_date_tz?: InputMaybe<Scalars['String']>;
  creation_date_utc?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<NewEstimateCustomerInput>;
  financial_info?: InputMaybe<NewEstimateFinancialInfoInput>;
  id?: InputMaybe<Scalars['Int']>;
  missing_required_external_documents?: InputMaybe<Scalars['Boolean']>;
  needs_electronic_signature_verification?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  referral_source?: InputMaybe<ReferralSourceInput>;
  request_boot?: InputMaybe<Scalars['Boolean']>;
  setter_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DealType>;
};

export type Note = {
  __typename?: 'note';
  author?: Maybe<User>;
  author_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  creation_date_tz?: Maybe<Scalars['String']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  note_type?: Maybe<Scalars['String']>;
  notification_id?: Maybe<Scalars['ID']>;
  phone_number?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  recordingid?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type NoteInput = {
  author_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  creation_date_tz?: InputMaybe<Scalars['String']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  note_type?: InputMaybe<Scalars['String']>;
  notification_id?: InputMaybe<Scalars['ID']>;
  phone_number?: InputMaybe<Scalars['String']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  recordingid?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type Notification = {
  __typename?: 'notification';
  created_at?: Maybe<Scalars['DateTime']>;
  customer_id?: Maybe<Scalars['ID']>;
  deal?: Maybe<Deal>;
  deal_contact_id?: Maybe<Scalars['ID']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  seen?: Maybe<Scalars['Boolean']>;
  text_message?: Maybe<TextMessage>;
  text_message_id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type NotificationInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  customer_id?: InputMaybe<Scalars['ID']>;
  deal?: InputMaybe<DealInput>;
  deal_contact_id?: InputMaybe<Scalars['ID']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  seen?: InputMaybe<Scalars['Boolean']>;
  text_message?: InputMaybe<TextMessageInput>;
  text_message_id?: InputMaybe<Scalars['ID']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type OnWaitingQueue = {
  __typename?: 'onWaitingQueue';
  data?: Maybe<Array<Maybe<WaitingCall>>>;
  hunt_group_slug?: Maybe<Scalars['String']>;
};

export type OperatingHoursTimeSlots = {
  __typename?: 'operatingHoursTimeSlots';
  isOpen?: Maybe<Scalars['Boolean']>;
  utcTimeSlots?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type OptEvent = {
  __typename?: 'optEvent';
  action?: Maybe<OptEventTypeEnum>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ip?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  opt_in_at?: Maybe<Scalars['DateTime']>;
  phone_number?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  source?: Maybe<OptEventSourceEnum>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  zip?: Maybe<Scalars['String']>;
};

export type OptEventInput = {
  action?: InputMaybe<OptEventTypeEnum>;
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  ip?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  opt_in_at?: InputMaybe<Scalars['DateTime']>;
  phone_number?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<OptEventSourceEnum>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  zip?: InputMaybe<Scalars['String']>;
};

export enum OptEventSourceEnum {
  Com = 'com',
  HubspotMigration = 'hubspot_migration',
  PreFlowPaymentCalculator = 'pre_flow_payment_calculator',
  ReOptDateSelected = 're_opt_date_selected',
  Verse = 'verse',
}

export enum OptEventTypeEnum {
  OptIn = 'opt_in',
  OptOut = 'opt_out',
}

export enum OverallConditionEnumType {
  LikeNew = 'like_new',
  NeedsWork = 'needs_work',
  SlightlyUsed = 'slightly_used',
}

export type PaymentEstimate = {
  __typename?: 'paymentEstimate';
  estimatedAnnualPercentageRate: Scalars['Float'];
  estimatedTaxesAndFees: Scalars['Float'];
  feeDetails: PaymentEstimateFeeDetails;
  paymentEstimateHigh: Scalars['Float'];
  paymentEstimateLow: Scalars['Float'];
  totalEstimatedLoan: Scalars['Float'];
};

export type PaymentEstimateFeeDetails = {
  __typename?: 'paymentEstimateFeeDetails';
  baseTaxAmount: Scalars['Float'];
  docFee: Scalars['Float'];
  docFeeTaxAmount: Scalars['Float'];
  registrationFee: Scalars['Float'];
  titleFee: Scalars['Float'];
  warrantyTaxAmount: Scalars['Float'];
};

export type PaymentEstimateInput = {
  creditScore: CreditScore;
  feesInputFromDD?: InputMaybe<TtFeesInput>;
  financialInfoId?: InputMaybe<Scalars['ID']>;
  fuelType?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  moneyDown: Scalars['Float'];
  payoff: Scalars['Float'];
  term: Scalars['Int'];
  ttGetFeesSource?: InputMaybe<TtGetFeesSourceType>;
  vehicleType?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export enum PaymentInstrumentType {
  All = 'ALL',
  BankAccount = 'BANK_ACCOUNT',
  PaymentCard = 'PAYMENT_CARD',
}

export enum PaymentMethod {
  BankAccount = 'BANK_ACCOUNT',
  Credit = 'CREDIT',
  CreditDebit = 'CREDIT_DEBIT',
  Debit = 'DEBIT',
}

export type PaymentOptions = {
  __typename?: 'paymentOptions';
  gap?: Maybe<PaymentOptionsGqlTypes>;
  noProducts?: Maybe<PaymentOptionsGqlTypes>;
  vsc?: Maybe<PaymentOptionsGqlTypes>;
  vscPlusGap?: Maybe<PaymentOptionsGqlTypes>;
};

export type PaymentOptionsGqlTypes = {
  __typename?: 'paymentOptionsGQLTypes';
  amountFinanced?: Maybe<Scalars['Float']>;
  buyPayment?: Maybe<Scalars['Float']>;
  closerCommission?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  sellPayment?: Maybe<Scalars['Float']>;
  setterCommission?: Maybe<Scalars['Float']>;
};

export enum PaymentState {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Returned = 'RETURNED',
  Succeeded = 'SUCCEEDED',
  Unknown = 'UNKNOWN',
}

export type Payoff = {
  __typename?: 'payoff';
  account_number?: Maybe<Scalars['String']>;
  cap_cost?: Maybe<Scalars['Float']>;
  cap_reduction?: Maybe<Scalars['Float']>;
  car_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['DateTime']>;
  estimated_payoff?: Maybe<Scalars['Float']>;
  good_through_date?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  lease_term?: Maybe<Scalars['Float']>;
  lender_name?: Maybe<Scalars['String']>;
  lienholder_name?: Maybe<Scalars['String']>;
  lienholder_slug?: Maybe<Scalars['String']>;
  maturity_date?: Maybe<Scalars['Date']>;
  money_down?: Maybe<Scalars['Float']>;
  money_factor?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  next_payment_date?: Maybe<Scalars['Date']>;
  old_lease_payment?: Maybe<Scalars['Float']>;
  payoff_includes_sales_tax?: Maybe<Scalars['Boolean']>;
  remaining_payments?: Maybe<Scalars['Float']>;
  residual_amount?: Maybe<Scalars['Float']>;
  residual_percentage?: Maybe<Scalars['Float']>;
  sales_price?: Maybe<Scalars['Float']>;
  sales_tax_from_payoff?: Maybe<Scalars['Float']>;
  sales_tax_from_payoff_entered_manually?: Maybe<Scalars['Boolean']>;
  termination_fees?: Maybe<Scalars['Float']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_entered_total_payoff?: Maybe<Scalars['Float']>;
  vehicle_payoff?: Maybe<Scalars['Float']>;
  verification_status?: Maybe<PayoffVerificationStatus>;
};

export type PayoffDashboard = {
  __typename?: 'payoffDashboard';
  payoffRequests?: Maybe<Array<Maybe<PayoffRequestPopulated>>>;
};

export type PayoffInput = {
  account_number?: InputMaybe<Scalars['String']>;
  cap_cost?: InputMaybe<Scalars['Float']>;
  cap_reduction?: InputMaybe<Scalars['Float']>;
  car_id?: InputMaybe<Scalars['ID']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  estimated_payoff?: InputMaybe<Scalars['Float']>;
  good_through_date?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['ID']>;
  lease_term?: InputMaybe<Scalars['Float']>;
  lender_name?: InputMaybe<Scalars['String']>;
  lienholder_name?: InputMaybe<Scalars['String']>;
  lienholder_slug?: InputMaybe<Scalars['String']>;
  maturity_date?: InputMaybe<Scalars['Date']>;
  money_down?: InputMaybe<Scalars['Float']>;
  money_factor?: InputMaybe<Scalars['Float']>;
  msrp?: InputMaybe<Scalars['Float']>;
  next_payment_date?: InputMaybe<Scalars['Date']>;
  old_lease_payment?: InputMaybe<Scalars['Float']>;
  payoff_includes_sales_tax?: InputMaybe<Scalars['Boolean']>;
  remaining_payments?: InputMaybe<Scalars['Float']>;
  residual_amount?: InputMaybe<Scalars['Float']>;
  residual_percentage?: InputMaybe<Scalars['Float']>;
  sales_price?: InputMaybe<Scalars['Float']>;
  sales_tax_from_payoff?: InputMaybe<Scalars['Float']>;
  sales_tax_from_payoff_entered_manually?: InputMaybe<Scalars['Boolean']>;
  termination_fees?: InputMaybe<Scalars['Float']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  user_entered_total_payoff?: InputMaybe<Scalars['Float']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']>;
  verification_status?: InputMaybe<PayoffVerificationStatus>;
};

export type PayoffRequest = {
  __typename?: 'payoffRequest';
  completed_date?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  fail_reason?: Maybe<Scalars['JSONObject']>;
  good_through_date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  needs_payoff_documents?: Maybe<Scalars['Boolean']>;
  payoff_clerk_id?: Maybe<Scalars['String']>;
  payoff_documents_uploaded?: Maybe<Scalars['Boolean']>;
  sales_tax?: Maybe<Scalars['Float']>;
  status?: Maybe<PayoffRequestStatus>;
  temporary_info_id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  vehicle_payoff?: Maybe<Scalars['Float']>;
};

export type PayoffRequestConfirmInput = {
  completed_date?: InputMaybe<Scalars['DateTime']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  double_tax_applied?: InputMaybe<Scalars['Boolean']>;
  fail_reason?: InputMaybe<Scalars['JSONObject']>;
  good_through_date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  needs_payoff_documents?: InputMaybe<Scalars['Boolean']>;
  payoff_clerk_id?: InputMaybe<Scalars['String']>;
  payoff_documents_uploaded?: InputMaybe<Scalars['Boolean']>;
  payoff_includes_sales_tax?: InputMaybe<Scalars['Boolean']>;
  sales_tax?: InputMaybe<Scalars['Float']>;
  sales_tax_from_payoff_entered_manually?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PayoffRequestStatus>;
  temporary_info_id?: InputMaybe<Scalars['ID']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  user_entered_total_payoff?: InputMaybe<Scalars['Float']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']>;
};

export type PayoffRequestInput = {
  completed_date?: InputMaybe<Scalars['DateTime']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  fail_reason?: InputMaybe<Scalars['JSONObject']>;
  good_through_date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  needs_payoff_documents?: InputMaybe<Scalars['Boolean']>;
  payoff_clerk_id?: InputMaybe<Scalars['String']>;
  payoff_documents_uploaded?: InputMaybe<Scalars['Boolean']>;
  sales_tax?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PayoffRequestStatus>;
  temporary_info_id?: InputMaybe<Scalars['ID']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']>;
};

export type PayoffRequestPopulated = {
  __typename?: 'payoffRequestPopulated';
  completed_date?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['ID']>;
  fail_reason?: Maybe<Scalars['JSONObject']>;
  good_through_date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  needs_payoff_documents?: Maybe<Scalars['Boolean']>;
  payoff_clerk?: Maybe<User>;
  payoff_clerk_id?: Maybe<Scalars['String']>;
  payoff_documents_uploaded?: Maybe<Scalars['Boolean']>;
  sales_tax?: Maybe<Scalars['Float']>;
  status?: Maybe<PayoffRequestStatus>;
  temporary_info?: Maybe<TemporaryInfo>;
  temporary_info_id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  vehicle_payoff?: Maybe<Scalars['Float']>;
};

export enum PayoffRequestStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  FailureRequested = 'FAILURE_REQUESTED',
  Fetching = 'FETCHING',
  Pending = 'PENDING',
}

export type PayoffRequestStatusUpdate = {
  __typename?: 'payoffRequestStatusUpdate';
  completed_date?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['ID']>;
  fail_reason?: Maybe<Scalars['JSONObject']>;
  good_through_date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  needs_payoff_documents?: Maybe<Scalars['Boolean']>;
  payoff_clerk_id?: Maybe<Scalars['String']>;
  payoff_documents_uploaded?: Maybe<Scalars['Boolean']>;
  sales_tax?: Maybe<Scalars['Float']>;
  status?: Maybe<PayoffRequestStatus>;
  temporary_info_id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  vehicle_payoff?: Maybe<Scalars['Float']>;
};

export enum PayoffVerificationStatus {
  CustomerProvided = 'customer_provided',
  Edited = 'edited',
  Verified = 'verified',
  VerifiedBySalesRep = 'verified_by_sales_rep',
}

export type Pod = {
  __typename?: 'pod';
  archived?: Maybe<Scalars['Boolean']>;
  child_pods?: Maybe<Array<Maybe<BasePod>>>;
  closer_commission_rate?: Maybe<Scalars['Float']>;
  closer_commission_type?: Maybe<CommissionType>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  leads?: Maybe<Array<Maybe<BaseUser>>>;
  manager_commission_rate?: Maybe<Scalars['Float']>;
  manager_commission_type?: Maybe<CommissionType>;
  managers?: Maybe<Array<Maybe<BaseUser>>>;
  name?: Maybe<Scalars['String']>;
  parent_pod?: Maybe<BasePod>;
  parent_pod_id?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  pod_users?: Maybe<Array<Maybe<PodUser>>>;
  problem_solver?: Maybe<Scalars['Boolean']>;
  setter_commission_rate?: Maybe<Scalars['Float']>;
  setter_commission_type?: Maybe<CommissionType>;
  special_commission_rate?: Maybe<Scalars['Float']>;
  special_commission_type?: Maybe<CommissionType>;
  team_type?: Maybe<TeamType>;
  updated_at?: Maybe<Scalars['DateTime']>;
  us_states_object?: Maybe<UsStatesObject>;
  usersWithoutTeamRole?: Maybe<Array<Maybe<BaseUser>>>;
  vsc_markup?: Maybe<Scalars['Int']>;
  vsc_multiplier?: Maybe<Scalars['Float']>;
};

export type PodHours = {
  __typename?: 'podHours';
  beginTime?: Maybe<Scalars['String']>;
  day?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
};

export type PodInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  child_pods?: InputMaybe<Array<InputMaybe<BasePodInput>>>;
  closer_commission_rate?: InputMaybe<Scalars['Float']>;
  closer_commission_type?: InputMaybe<CommissionType>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  hours?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  leads?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  manager_commission_rate?: InputMaybe<Scalars['Float']>;
  manager_commission_type?: InputMaybe<CommissionType>;
  managers?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  name?: InputMaybe<Scalars['String']>;
  parent_pod?: InputMaybe<BasePodInput>;
  parent_pod_id?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  pod_users?: InputMaybe<Array<InputMaybe<PodUserInput>>>;
  problem_solver?: InputMaybe<Scalars['Boolean']>;
  setter_commission_rate?: InputMaybe<Scalars['Float']>;
  setter_commission_type?: InputMaybe<CommissionType>;
  special_commission_rate?: InputMaybe<Scalars['Float']>;
  special_commission_type?: InputMaybe<CommissionType>;
  team_type?: InputMaybe<TeamType>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  us_states_object?: InputMaybe<UsStatesInputObject>;
  users?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  usersWithoutTeamRole?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  vsc_markup?: InputMaybe<Scalars['Int']>;
  vsc_multiplier?: InputMaybe<Scalars['Float']>;
};

export type PodUser = {
  __typename?: 'podUser';
  id?: Maybe<Scalars['ID']>;
  pod_id?: Maybe<Scalars['Int']>;
  team_role?: Maybe<TeamRole>;
  user?: Maybe<BaseUser>;
  user_id?: Maybe<Scalars['String']>;
};

export type PodUserInput = {
  id?: InputMaybe<Scalars['ID']>;
  pod_id?: InputMaybe<Scalars['Int']>;
  team_role?: InputMaybe<TeamRole>;
  user?: InputMaybe<BaseUserInput>;
  user_id?: InputMaybe<Scalars['String']>;
};

export enum PotentialRoleEnumType {
  Closer = 'closer',
  NotAssigned = 'not_assigned',
  Setter = 'setter',
  SetterLead = 'setter_lead',
}

export type PotentialUser = {
  __typename?: 'potentialUser';
  added_to_testflight: Scalars['Boolean'];
  created_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  email_created: Scalars['Boolean'];
  employment_status: EmploymentEnumType;
  hired: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  interview_scheduled: Scalars['Boolean'];
  login_created: Scalars['Boolean'];
  name: Scalars['String'];
  phone: Scalars['String'];
  potential_role: PotentialRoleEnumType;
  reached_out: Scalars['Boolean'];
  referral_code?: Maybe<Scalars['String']>;
  signup_date: Scalars['DateTime'];
  slack_created: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['DateTime']>;
  zip: Scalars['Int'];
};

export type PotentialUserInput = {
  email: Scalars['String'];
  employment_status: EmploymentEnumType;
  name: Scalars['String'];
  phone: Scalars['String'];
  referral_code?: InputMaybe<Scalars['String']>;
  zip: Scalars['Int'];
};

export type Prequalification = {
  __typename?: 'prequalification';
  created_at?: Maybe<Scalars['DateTime']>;
  credit_score?: Maybe<Scalars['Int']>;
  credit_score_tier?: Maybe<CreditScoreTierEnumType>;
  customer_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<PrequalificationRequest>;
  ltv?: Maybe<Scalars['Float']>;
  output?: Maybe<ExperianOutput>;
  payment_to_income?: Maybe<Scalars['Float']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Product = {
  __typename?: 'product';
  gap_selected?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  product_type?: Maybe<Scalars['String']>;
  provider_dealer_code_name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  selected_package?: Maybe<Scalars['String']>;
};

export type ProofOfInsurance = {
  __typename?: 'proofOfInsurance';
  company_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  expires?: Maybe<Scalars['DateTime']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  policy_number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type ProofOfInsuranceInput = {
  company_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  expires?: InputMaybe<Scalars['DateTime']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  policy_number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type Provider = {
  __typename?: 'provider';
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
};

export type PurchaseOrder = {
  __typename?: 'purchaseOrder';
  files?: Maybe<Array<Maybe<Files>>>;
};

export type R1CreditDecisionData = {
  __typename?: 'r1CreditDecisionData';
  applicationNumber?: Maybe<Scalars['String']>;
  applicationStatus?: Maybe<CreditDecisionStatusEnum>;
  applicationStatusDetail?: Maybe<Scalars['String']>;
  contractTerm?: Maybe<ContractTerm>;
  decision?: Maybe<Decision>;
};

export type Rate = {
  __typename?: 'rate';
  coverage_name?: Maybe<Scalars['String']>;
  deductible?: Maybe<Deductible>;
  form_id?: Maybe<Scalars['Int']>;
  form_name?: Maybe<Scalars['String']>;
  form_number?: Maybe<Scalars['String']>;
  product_type?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  term_miles?: Maybe<Scalars['String']>;
  term_months?: Maybe<Scalars['String']>;
};

export type RateInput = {
  apr?: InputMaybe<Scalars['Float']>;
  financeTerms?: InputMaybe<Scalars['Int']>;
  financeTermsMileage?: InputMaybe<Scalars['Int']>;
  financedAmount?: InputMaybe<Scalars['Float']>;
  mileage?: InputMaybe<Scalars['Int']>;
  msrp?: InputMaybe<Scalars['Float']>;
  purchasePrice?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type ReferralSource = {
  __typename?: 'referralSource';
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  other_source_description?: Maybe<Scalars['String']>;
  source_name?: Maybe<ReferralSourceEnum>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type ReferralSourceInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  other_source_description?: InputMaybe<Scalars['String']>;
  source_name?: InputMaybe<ReferralSourceEnum>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export enum RelationToBuyerEnum {
  Child = 'child',
  Other = 'other',
  Parent = 'parent',
  ResidesWith = 'resides_with',
  Spouse = 'spouse',
}

export type ReserveStructure = {
  __typename?: 'reserveStructure';
  bank_id?: Maybe<Scalars['ID']>;
  flat_percentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  rate_adj_max?: Maybe<Scalars['Float']>;
  rate_adj_min?: Maybe<Scalars['Float']>;
  term_max?: Maybe<Scalars['Int']>;
  term_min?: Maybe<Scalars['Int']>;
};

export type ReserveStructureInput = {
  bank_id?: InputMaybe<Scalars['ID']>;
  flat_percentage?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  rate_adj_max?: InputMaybe<Scalars['Float']>;
  rate_adj_min?: InputMaybe<Scalars['Float']>;
  term_max?: InputMaybe<Scalars['Int']>;
  term_min?: InputMaybe<Scalars['Int']>;
};

export enum ResidenceEnumType {
  Buying = 'buying',
  Family = 'family',
  Own = 'own',
  Rent = 'rent',
}

export type ResultAddParticipant = {
  __typename?: 'resultAddParticipant';
  call_already_answered?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type Review = {
  __typename?: 'review';
  com_visible?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  review?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type ReviewInput = {
  com_visible?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  customer_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['String']>;
  review?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type SigningAuditDocumentViewedEvent = {
  deal_id?: InputMaybe<Scalars['ID']>;
  document_id?: InputMaybe<Scalars['String']>;
  document_name?: InputMaybe<Scalars['String']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']>;
};

export type SigningError = {
  __typename?: 'signingError';
  created_at?: Maybe<Scalars['DateTime']>;
  deal_id?: Maybe<Scalars['ID']>;
  error_detail?: Maybe<Scalars['String']>;
  error_message?: Maybe<Scalars['String']>;
  error_type?: Maybe<SigningErrorEnum>;
  id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type SigningErrorInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  deal_id?: InputMaybe<Scalars['ID']>;
  error_detail?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['String']>;
  error_type?: InputMaybe<SigningErrorEnum>;
  id?: InputMaybe<Scalars['ID']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type SsnLog = {
  __typename?: 'ssnLog';
  created_at?: Maybe<Scalars['DateTime']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  temp_info_id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
};

export type SsnLogInput = {
  created_at?: InputMaybe<Scalars['DateTime']>;
  customer_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  temp_info_id?: InputMaybe<Scalars['ID']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Surcharge = {
  __typename?: 'surcharge';
  car_status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum TeamRole {
  Lead = 'lead',
  Manager = 'manager',
}

export enum TeamType {
  D2d = 'd2d',
  Inbound = 'inbound',
  Outbound = 'outbound',
  Region = 'region',
  Titling = 'titling',
}

export type TemporaryData = {
  __typename?: 'temporaryData';
  address_line?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  affiliate_external_id?: Maybe<Scalars['String']>;
  book_value?: Maybe<Scalars['Float']>;
  cash_to_customer?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  cobuyer_address_line?: Maybe<Scalars['String']>;
  cobuyer_address_line_2?: Maybe<Scalars['String']>;
  cobuyer_city?: Maybe<Scalars['String']>;
  cobuyer_county?: Maybe<Scalars['String']>;
  cobuyer_dob?: Maybe<Scalars['DateTime']>;
  cobuyer_email?: Maybe<Scalars['String']>;
  cobuyer_employer_name?: Maybe<Scalars['String']>;
  cobuyer_employer_phone_number?: Maybe<Scalars['String']>;
  cobuyer_employment_status?: Maybe<Scalars['String']>;
  cobuyer_first_name?: Maybe<Scalars['String']>;
  cobuyer_job_title?: Maybe<Scalars['String']>;
  cobuyer_last_name?: Maybe<Scalars['String']>;
  cobuyer_marital_status?: Maybe<MaritalStatusEnum>;
  cobuyer_months_at_job?: Maybe<Scalars['Int']>;
  cobuyer_pay_frequency?: Maybe<Scalars['String']>;
  cobuyer_phone_number?: Maybe<Scalars['String']>;
  cobuyer_prequalification_errors?: Maybe<Array<Maybe<LeExperianErrorCodeEnum>>>;
  cobuyer_previous_address_line?: Maybe<Scalars['String']>;
  cobuyer_previous_address_line_2?: Maybe<Scalars['String']>;
  cobuyer_previous_city?: Maybe<Scalars['String']>;
  cobuyer_previous_county?: Maybe<Scalars['String']>;
  cobuyer_previous_employer_name?: Maybe<Scalars['String']>;
  cobuyer_previous_employer_phone_number?: Maybe<Scalars['String']>;
  cobuyer_previous_job_title?: Maybe<Scalars['String']>;
  cobuyer_previous_months_at_job?: Maybe<Scalars['Int']>;
  cobuyer_previous_state?: Maybe<Scalars['String']>;
  cobuyer_previous_years_at_job?: Maybe<Scalars['Int']>;
  cobuyer_previous_zip?: Maybe<Scalars['String']>;
  cobuyer_primary_residence_monthly_payment?: Maybe<Scalars['Float']>;
  cobuyer_primary_residence_months?: Maybe<Scalars['Int']>;
  cobuyer_primary_residence_type?: Maybe<Scalars['String']>;
  cobuyer_primary_residence_years?: Maybe<Scalars['Int']>;
  cobuyer_relation_to_buyer?: Maybe<RelationToBuyerEnum>;
  cobuyer_salary?: Maybe<Scalars['Float']>;
  cobuyer_secondary_residence_months?: Maybe<Scalars['Int']>;
  cobuyer_secondary_residence_years?: Maybe<Scalars['Int']>;
  cobuyer_state?: Maybe<Scalars['String']>;
  cobuyer_years_at_job?: Maybe<Scalars['Int']>;
  cobuyer_zip?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  condition_report?: Maybe<Scalars['JSON']>;
  county?: Maybe<Scalars['String']>;
  credit_score?: Maybe<Scalars['Int']>;
  current_accordion_panel?: Maybe<Scalars['String']>;
  device_type?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  doc_fee?: Maybe<Scalars['Float']>;
  down_payment?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  employer_name?: Maybe<Scalars['String']>;
  employer_phone_number?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  estimated_dealer_fees?: Maybe<Scalars['Float']>;
  first_name?: Maybe<Scalars['String']>;
  fuel_type?: Maybe<Scalars['String']>;
  good_through_date?: Maybe<Scalars['DateTime']>;
  has_full_ssn?: Maybe<Scalars['Boolean']>;
  has_same_address_as_cobuyer?: Maybe<Scalars['Boolean']>;
  high_ltv_ratio?: Maybe<Scalars['Boolean']>;
  include_cobuyer?: Maybe<Scalars['Boolean']>;
  is_hard_pull?: Maybe<Scalars['Boolean']>;
  job_title?: Maybe<Scalars['String']>;
  kbb_all_options?: Maybe<Scalars['JSON']>;
  kbb_lending_mileage_adjustment?: Maybe<Scalars['Float']>;
  kbb_lending_option_adjustment?: Maybe<Scalars['Float']>;
  kbb_retail_mileage_adjustment?: Maybe<Scalars['Float']>;
  kbb_retail_option_adjustment?: Maybe<Scalars['Float']>;
  kbb_selected_options?: Maybe<Scalars['JSON']>;
  kbb_trim_name?: Maybe<Scalars['String']>;
  kbb_valuation_date?: Maybe<Scalars['String']>;
  kbb_vehicle_id?: Maybe<Scalars['Int']>;
  kbb_vehicle_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_url?: Maybe<Scalars['String']>;
  lh_account_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  license_plate_state?: Maybe<Scalars['String']>;
  lienholder?: Maybe<Scalars['String']>;
  lienholderLogo?: Maybe<Scalars['String']>;
  lienholder_slug?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  manual_entry?: Maybe<Scalars['Boolean']>;
  marital_status?: Maybe<MaritalStatusEnum>;
  middle_name?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mmr_value?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  months_at_job?: Maybe<Scalars['Int']>;
  moved_states?: Maybe<Scalars['Boolean']>;
  odometer_status?: Maybe<OdometerStatus>;
  old_lease_payment?: Maybe<Scalars['Float']>;
  other_lienholder?: Maybe<Scalars['String']>;
  pay_frequency?: Maybe<Scalars['String']>;
  payment_estimate_high?: Maybe<Scalars['Float']>;
  payment_estimate_low?: Maybe<Scalars['Float']>;
  payoff_includes_sales_tax?: Maybe<Scalars['Boolean']>;
  phone_number?: Maybe<Scalars['String']>;
  prequal_id?: Maybe<Scalars['ID']>;
  prequalification_errors?: Maybe<Array<Maybe<LeExperianErrorCodeEnum>>>;
  prequalification_success?: Maybe<Scalars['Boolean']>;
  previous_address_line?: Maybe<Scalars['String']>;
  previous_address_line_2?: Maybe<Scalars['String']>;
  previous_city?: Maybe<Scalars['String']>;
  previous_county?: Maybe<Scalars['String']>;
  previous_employer_name?: Maybe<Scalars['String']>;
  previous_employer_phone_number?: Maybe<Scalars['String']>;
  previous_job_title?: Maybe<Scalars['String']>;
  previous_months_at_job?: Maybe<Scalars['Int']>;
  previous_state?: Maybe<Scalars['String']>;
  previous_years_at_job?: Maybe<Scalars['Int']>;
  previous_zip?: Maybe<Scalars['String']>;
  primary_residence_monthly_payment?: Maybe<Scalars['Float']>;
  primary_residence_months?: Maybe<Scalars['Int']>;
  primary_residence_type?: Maybe<Scalars['String']>;
  primary_residence_years?: Maybe<Scalars['Int']>;
  query_params?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  retail_book_value?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
  sales_tax_from_payoff?: Maybe<Scalars['Float']>;
  sales_tax_from_payoff_entered_manually?: Maybe<Scalars['Boolean']>;
  scheduled_call_datetime?: Maybe<Scalars['DateTime']>;
  scheduled_call_datetime_tz?: Maybe<Scalars['String']>;
  secondary_residence_months?: Maybe<Scalars['Int']>;
  secondary_residence_years?: Maybe<Scalars['Int']>;
  shopping_cart?: Maybe<TemporaryShoppingCartData>;
  ssn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  taxes?: Maybe<Scalars['Decimal']>;
  taxes_fees?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
  title_only?: Maybe<Scalars['Boolean']>;
  uploaded_files?: Maybe<Scalars['JSON']>;
  user_entered_total_payoff?: Maybe<Scalars['Float']>;
  vehicle_offer?: Maybe<Scalars['Float']>;
  vehicle_payoff?: Maybe<Scalars['Float']>;
  vehicle_registration_fee?: Maybe<Scalars['Float']>;
  vehicle_type?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  years_at_job?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

export type TemporaryDataInput = {
  address_line?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  affiliate_external_id?: InputMaybe<Scalars['String']>;
  book_value?: InputMaybe<Scalars['Float']>;
  cash_to_customer?: InputMaybe<Scalars['Float']>;
  city?: InputMaybe<Scalars['String']>;
  cobuyer_address_line?: InputMaybe<Scalars['String']>;
  cobuyer_address_line_2?: InputMaybe<Scalars['String']>;
  cobuyer_city?: InputMaybe<Scalars['String']>;
  cobuyer_county?: InputMaybe<Scalars['String']>;
  cobuyer_dob?: InputMaybe<Scalars['DateTime']>;
  cobuyer_email?: InputMaybe<Scalars['String']>;
  cobuyer_employer_name?: InputMaybe<Scalars['String']>;
  cobuyer_employer_phone_number?: InputMaybe<Scalars['String']>;
  cobuyer_employment_status?: InputMaybe<Scalars['String']>;
  cobuyer_first_name?: InputMaybe<Scalars['String']>;
  cobuyer_job_title?: InputMaybe<Scalars['String']>;
  cobuyer_last_name?: InputMaybe<Scalars['String']>;
  cobuyer_marital_status?: InputMaybe<MaritalStatusEnum>;
  cobuyer_months_at_job?: InputMaybe<Scalars['Int']>;
  cobuyer_pay_frequency?: InputMaybe<Scalars['String']>;
  cobuyer_phone_number?: InputMaybe<Scalars['String']>;
  cobuyer_prequalification_errors?: InputMaybe<Array<InputMaybe<LeExperianErrorCodeEnum>>>;
  cobuyer_previous_address_line?: InputMaybe<Scalars['String']>;
  cobuyer_previous_address_line_2?: InputMaybe<Scalars['String']>;
  cobuyer_previous_city?: InputMaybe<Scalars['String']>;
  cobuyer_previous_county?: InputMaybe<Scalars['String']>;
  cobuyer_previous_employer_name?: InputMaybe<Scalars['String']>;
  cobuyer_previous_employer_phone_number?: InputMaybe<Scalars['String']>;
  cobuyer_previous_job_title?: InputMaybe<Scalars['String']>;
  cobuyer_previous_months_at_job?: InputMaybe<Scalars['Int']>;
  cobuyer_previous_state?: InputMaybe<Scalars['String']>;
  cobuyer_previous_years_at_job?: InputMaybe<Scalars['Int']>;
  cobuyer_previous_zip?: InputMaybe<Scalars['String']>;
  cobuyer_primary_residence_monthly_payment?: InputMaybe<Scalars['Float']>;
  cobuyer_primary_residence_months?: InputMaybe<Scalars['Int']>;
  cobuyer_primary_residence_type?: InputMaybe<Scalars['String']>;
  cobuyer_primary_residence_years?: InputMaybe<Scalars['Int']>;
  cobuyer_relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  cobuyer_salary?: InputMaybe<Scalars['Float']>;
  cobuyer_secondary_residence_months?: InputMaybe<Scalars['Int']>;
  cobuyer_secondary_residence_years?: InputMaybe<Scalars['Int']>;
  cobuyer_ssn?: InputMaybe<Scalars['String']>;
  cobuyer_state?: InputMaybe<Scalars['String']>;
  cobuyer_years_at_job?: InputMaybe<Scalars['Int']>;
  cobuyer_zip?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  condition_report?: InputMaybe<Scalars['JSON']>;
  county?: InputMaybe<Scalars['String']>;
  credit_score?: InputMaybe<Scalars['Int']>;
  current_accordion_panel?: InputMaybe<Scalars['String']>;
  device_type?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  doc_fee?: InputMaybe<Scalars['Float']>;
  down_payment?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['String']>;
  employer_name?: InputMaybe<Scalars['String']>;
  employer_phone_number?: InputMaybe<Scalars['String']>;
  employment_status?: InputMaybe<Scalars['String']>;
  estimated_dealer_fees?: InputMaybe<Scalars['Float']>;
  first_name?: InputMaybe<Scalars['String']>;
  fuel_type?: InputMaybe<Scalars['String']>;
  good_through_date?: InputMaybe<Scalars['DateTime']>;
  has_full_ssn?: InputMaybe<Scalars['Boolean']>;
  has_same_address_as_cobuyer?: InputMaybe<Scalars['Boolean']>;
  high_ltv_ratio?: InputMaybe<Scalars['Boolean']>;
  include_cobuyer?: InputMaybe<Scalars['Boolean']>;
  is_hard_pull?: InputMaybe<Scalars['Boolean']>;
  job_title?: InputMaybe<Scalars['String']>;
  kbb_all_options?: InputMaybe<Scalars['JSON']>;
  kbb_lending_mileage_adjustment?: InputMaybe<Scalars['Float']>;
  kbb_lending_option_adjustment?: InputMaybe<Scalars['Float']>;
  kbb_retail_mileage_adjustment?: InputMaybe<Scalars['Float']>;
  kbb_retail_option_adjustment?: InputMaybe<Scalars['Float']>;
  kbb_selected_options?: InputMaybe<Scalars['JSON']>;
  kbb_trim_name?: InputMaybe<Scalars['String']>;
  kbb_valuation_date?: InputMaybe<Scalars['String']>;
  kbb_vehicle_id?: InputMaybe<Scalars['Int']>;
  kbb_vehicle_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_url?: InputMaybe<Scalars['String']>;
  lh_account_number?: InputMaybe<Scalars['String']>;
  license_plate_number?: InputMaybe<Scalars['String']>;
  license_plate_state?: InputMaybe<Scalars['String']>;
  lienholder?: InputMaybe<Scalars['String']>;
  lienholderLogo?: InputMaybe<Scalars['String']>;
  lienholder_slug?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  manual_entry?: InputMaybe<Scalars['Boolean']>;
  marital_status?: InputMaybe<MaritalStatusEnum>;
  middle_name?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  mmr_value?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  months_at_job?: InputMaybe<Scalars['Int']>;
  moved_states?: InputMaybe<Scalars['Boolean']>;
  odometer_status?: InputMaybe<OdometerStatus>;
  old_lease_payment?: InputMaybe<Scalars['Float']>;
  other_lienholder?: InputMaybe<Scalars['String']>;
  pay_frequency?: InputMaybe<Scalars['String']>;
  payment_estimate_high?: InputMaybe<Scalars['Float']>;
  payment_estimate_low?: InputMaybe<Scalars['Float']>;
  payoff_includes_sales_tax?: InputMaybe<Scalars['Boolean']>;
  phone_number?: InputMaybe<Scalars['String']>;
  prequal_id?: InputMaybe<Scalars['ID']>;
  prequalification_errors?: InputMaybe<Array<InputMaybe<LeExperianErrorCodeEnum>>>;
  prequalification_success?: InputMaybe<Scalars['Boolean']>;
  previous_address_line?: InputMaybe<Scalars['String']>;
  previous_address_line_2?: InputMaybe<Scalars['String']>;
  previous_city?: InputMaybe<Scalars['String']>;
  previous_county?: InputMaybe<Scalars['String']>;
  previous_employer_name?: InputMaybe<Scalars['String']>;
  previous_employer_phone_number?: InputMaybe<Scalars['String']>;
  previous_job_title?: InputMaybe<Scalars['String']>;
  previous_months_at_job?: InputMaybe<Scalars['Int']>;
  previous_state?: InputMaybe<Scalars['String']>;
  previous_years_at_job?: InputMaybe<Scalars['Int']>;
  previous_zip?: InputMaybe<Scalars['String']>;
  primary_residence_monthly_payment?: InputMaybe<Scalars['Float']>;
  primary_residence_months?: InputMaybe<Scalars['Int']>;
  primary_residence_type?: InputMaybe<Scalars['String']>;
  primary_residence_years?: InputMaybe<Scalars['Int']>;
  query_params?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
  retail_book_value?: InputMaybe<Scalars['Float']>;
  salary?: InputMaybe<Scalars['Float']>;
  sales_tax_from_payoff?: InputMaybe<Scalars['Float']>;
  sales_tax_from_payoff_entered_manually?: InputMaybe<Scalars['Boolean']>;
  scheduled_call_datetime?: InputMaybe<Scalars['DateTime']>;
  scheduled_call_datetime_tz?: InputMaybe<Scalars['String']>;
  secondary_residence_months?: InputMaybe<Scalars['Int']>;
  secondary_residence_years?: InputMaybe<Scalars['Int']>;
  shopping_cart?: InputMaybe<TemporaryShoppingCartDataInput>;
  ssn?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  taxes?: InputMaybe<Scalars['Decimal']>;
  taxes_fees?: InputMaybe<Scalars['Float']>;
  term?: InputMaybe<Scalars['Int']>;
  title_only?: InputMaybe<Scalars['Boolean']>;
  uploaded_files?: InputMaybe<Scalars['JSON']>;
  user_entered_total_payoff?: InputMaybe<Scalars['Float']>;
  vehicle_offer?: InputMaybe<Scalars['Float']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']>;
  vehicle_registration_fee?: InputMaybe<Scalars['Float']>;
  vehicle_type?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
  years_at_job?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type TemporaryInfo = {
  __typename?: 'temporaryInfo';
  consents?: Maybe<Array<Maybe<Consent>>>;
  data: TemporaryData;
  deal_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<TemporaryInfoStatusEnumType>;
};

export type TemporaryInfoInput = {
  data: TemporaryDataInput;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<TemporaryInfoStatusEnumType>;
};

export enum TemporaryInfoStatusEnumType {
  Imported = 'imported',
  InProgress = 'in_progress',
}

export type TemporaryShoppingCartData = {
  __typename?: 'temporaryShoppingCartData';
  buyout?: Maybe<Scalars['Float']>;
  credit_score?: Maybe<Scalars['Int']>;
  down_payment?: Maybe<Scalars['Float']>;
  estimated_fees?: Maybe<Scalars['Float']>;
  loan_term?: Maybe<Scalars['Int']>;
  payment_estimate_high?: Maybe<Scalars['Float']>;
  payment_estimate_low?: Maybe<Scalars['Float']>;
};

export type TemporaryShoppingCartDataInput = {
  buyout?: InputMaybe<Scalars['Float']>;
  credit_score?: InputMaybe<Scalars['Int']>;
  down_payment?: InputMaybe<Scalars['Float']>;
  estimated_fees?: InputMaybe<Scalars['Float']>;
  loan_term?: InputMaybe<Scalars['Int']>;
  payment_estimate_high?: InputMaybe<Scalars['Float']>;
  payment_estimate_low?: InputMaybe<Scalars['Float']>;
};

export type TextMessage = {
  __typename?: 'textMessage';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  date_created?: Maybe<Scalars['String']>;
  date_sent?: Maybe<Scalars['String']>;
  date_updated?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  media?: Maybe<Scalars['String']>;
  media_list_object?: Maybe<MediaListObj>;
  message?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  recipient_phone_number?: Maybe<Scalars['String']>;
  resolved_media?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
};

export type TextMessageInput = {
  account_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  date_created?: InputMaybe<Scalars['String']>;
  date_sent?: InputMaybe<Scalars['String']>;
  date_updated?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  media?: InputMaybe<Scalars['String']>;
  media_list_object?: InputMaybe<MediaListInputObj>;
  message?: InputMaybe<Scalars['String']>;
  message_id?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Array<InputMaybe<NotificationInput>>>;
  recipient_phone_number?: InputMaybe<Scalars['String']>;
  resolved_media?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  user_id?: InputMaybe<Scalars['String']>;
};

export enum TiresEnumType {
  Fine = 'fine',
  NeedReplacing = 'need_replacing',
  New = 'new',
}

export enum TransformType {
  AcquisitionApproved = 'ACQUISITION_APPROVED',
  AcquisitionNotApproved = 'ACQUISITION_NOT_APPROVED',
  Affiliate = 'AFFILIATE',
  AffiliateInactivityImport = 'AFFILIATE_INACTIVITY_IMPORT',
  AutoStructureBegin = 'AUTO_STRUCTURE_BEGIN',
  CallBackPayoff = 'CALL_BACK_PAYOFF',
  FrozenOrLockedCredit = 'FROZEN_OR_LOCKED_CREDIT',
  HighLtvRatioAutoImport = 'HIGH_LTV_RATIO_AUTO_IMPORT',
  InactivityImport = 'INACTIVITY_IMPORT',
  ManualImportPostSoftPull = 'MANUAL_IMPORT_POST_SOFT_PULL',
  ManualImportPreSoftPull = 'MANUAL_IMPORT_PRE_SOFT_PULL',
  MarketingNurture = 'MARKETING_NURTURE',
  NoKbbFailure = 'NO_KBB_FAILURE',
  OtherLienholder = 'OTHER_LIENHOLDER',
  PayoffRequestFailure = 'PAYOFF_REQUEST_FAILURE',
  PayoffRequestSuccess = 'PAYOFF_REQUEST_SUCCESS',
  SoftPullFailure = 'SOFT_PULL_FAILURE',
  SoftPullFailureAutoImport = 'SOFT_PULL_FAILURE_AUTO_IMPORT',
  SoftPullSuccessAutoImport = 'SOFT_PULL_SUCCESS_AUTO_IMPORT',
}

export type TwilioNumber = {
  __typename?: 'twilioNumber';
  friendlyName?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type UpdateFinancialInfoInput = {
  amount_financed?: InputMaybe<Scalars['Float']>;
  bank?: InputMaybe<Scalars['String']>;
  bank_fees?: InputMaybe<Scalars['Float']>;
  base_tax_amount?: InputMaybe<Scalars['Float']>;
  buy_rate?: InputMaybe<Scalars['Float']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']>;
  closer_commission?: InputMaybe<Scalars['Float']>;
  com_rate_markup?: InputMaybe<Scalars['Float']>;
  commissionable_profit?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  credit_card_payment_amount_limit?: InputMaybe<Scalars['Float']>;
  days_to_payment?: InputMaybe<Scalars['Int']>;
  deal_id: Scalars['ID'];
  doc_fee?: InputMaybe<Scalars['Float']>;
  down_payment_status?: InputMaybe<DownPaymentStatus>;
  fee_processor?: InputMaybe<Scalars['String']>;
  first_payment_date?: InputMaybe<Scalars['Date']>;
  gap_cost?: InputMaybe<Scalars['Float']>;
  gap_price?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  money_down?: InputMaybe<Scalars['Float']>;
  needs_temporary_registration_tags?: InputMaybe<Scalars['Boolean']>;
  new_lienholder?: InputMaybe<BankInput>;
  new_registration_fee?: InputMaybe<Scalars['Float']>;
  option_type?: InputMaybe<Scalars['String']>;
  payment?: InputMaybe<Scalars['Float']>;
  pen_gap_form_id?: InputMaybe<Scalars['Int']>;
  pen_gap_rate_id?: InputMaybe<Scalars['Int']>;
  pen_gap_session_id?: InputMaybe<Scalars['String']>;
  pen_vsc_form_id?: InputMaybe<Scalars['Int']>;
  pen_vsc_rate_id?: InputMaybe<Scalars['Int']>;
  pen_vsc_session_id?: InputMaybe<Scalars['String']>;
  plate_transfer?: InputMaybe<Scalars['Boolean']>;
  processor?: InputMaybe<Scalars['String']>;
  profit?: InputMaybe<Scalars['Float']>;
  quick_notes?: InputMaybe<Scalars['String']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']>;
  reserve?: InputMaybe<Scalars['Float']>;
  sales_tax_rate?: InputMaybe<Scalars['Float']>;
  selected_credit_decision_id?: InputMaybe<Scalars['ID']>;
  sell_rate?: InputMaybe<Scalars['Float']>;
  setter_commission?: InputMaybe<Scalars['Float']>;
  tax_processor?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['Int']>;
  title_fee?: InputMaybe<Scalars['Float']>;
  title_registration_option?: InputMaybe<TitleRegistrationOption>;
  tt_transaction_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  user_entered_reserve?: InputMaybe<Scalars['Float']>;
  vsc_cost?: InputMaybe<Scalars['Float']>;
  vsc_price?: InputMaybe<Scalars['Float']>;
  vsc_term?: InputMaybe<Scalars['String']>;
  vsc_type?: InputMaybe<Scalars['String']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']>;
};

export type UploadDocumentsInput = {
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UsStatesInputObject = {
  states?: InputMaybe<Array<InputMaybe<StateAbbreviation>>>;
};

export type UsStatesObject = {
  __typename?: 'usStatesObject';
  states?: Maybe<Array<Maybe<StateAbbreviation>>>;
};

export type User = {
  __typename?: 'user';
  auth0_roles?: Maybe<UserRoles>;
  auto_assign_deals?: Maybe<Scalars['Boolean']>;
  call_status?: Maybe<Scalars['String']>;
  can_claim_as_closer?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  huntGroups?: Maybe<Array<Maybe<HuntGroup>>>;
  id?: Maybe<Scalars['String']>;
  max_auto_assign_deals?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  on_vacation?: Maybe<Scalars['Boolean']>;
  overnight_deals?: Maybe<Scalars['Boolean']>;
  phone_number?: Maybe<Scalars['String']>;
  pods?: Maybe<Array<Maybe<Pod>>>;
  recruiter_id?: Maybe<Scalars['String']>;
  titlingPods?: Maybe<Array<Maybe<Pod>>>;
  twilio_number?: Maybe<Scalars['String']>;
};

export type UserInput = {
  auto_assign_deals?: InputMaybe<Scalars['Boolean']>;
  call_status?: InputMaybe<Scalars['String']>;
  can_claim_as_closer?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  max_auto_assign_deals?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  on_vacation?: InputMaybe<Scalars['Boolean']>;
  overnight_deals?: InputMaybe<Scalars['Boolean']>;
  phone_number?: InputMaybe<Scalars['String']>;
  pods?: InputMaybe<Array<InputMaybe<PodInput>>>;
  recruiter_id?: InputMaybe<Scalars['String']>;
  titlingPods?: InputMaybe<Array<InputMaybe<PodInput>>>;
  twilio_number?: InputMaybe<Scalars['String']>;
};

export type Value = {
  __typename?: 'value';
  acquisition?: Maybe<Scalars['Float']>;
  buyout?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type VisibleArticles = {
  __typename?: 'visibleArticles';
  results?: Maybe<Array<Maybe<Article>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Vsc = {
  __typename?: 'vsc';
  noProductsPmt?: Maybe<Scalars['Float']>;
  startingIndex?: Maybe<Scalars['Int']>;
  vscOffers?: Maybe<Array<Maybe<VscOffers>>>;
};

export type VscCoverages = {
  __typename?: 'vscCoverages';
  category?: Maybe<Scalars['String']>;
  components?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Scalars['String']>;
};

export type VscDetails = {
  __typename?: 'vscDetails';
  cost?: Maybe<Scalars['Float']>;
  deductible?: Maybe<Scalars['Float']>;
  miles?: Maybe<Scalars['Int']>;
  payment?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

export type VscOffers = {
  __typename?: 'vscOffers';
  coverage?: Maybe<Array<Maybe<VscCoverages>>>;
  details?: Maybe<VscDetails>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type WaitingCall = {
  __typename?: 'waitingCall';
  conference_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  customer_name?: Maybe<Scalars['String']>;
  deal_id?: Maybe<Scalars['Int']>;
  deal_state?: Maybe<Scalars['String']>;
  deal_type?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  hunt_group_slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  is_thunder?: Maybe<Scalars['Boolean']>;
  phone_number?: Maybe<Scalars['String']>;
  processing_started_at?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  transferred_from?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type AcquisitionOfferQueryVariables = Exact<{
  accidents: AccidentsEnumType;
  mileage: Scalars['Int'];
  mmr_value: Scalars['Float'];
  book_value: Scalars['Int'];
  vehicle_type?: InputMaybe<Scalars['String']>;
  lienholder?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
}>;

export type AcquisitionOfferQuery = {
  __typename?: 'RootQueryType';
  acquisitionOffer?:
    | {
        __typename?: 'acquisitionOffer';
        offer?: number | null | undefined;
        appraisedValue?: number | null | undefined;
      }
    | null
    | undefined;
};

export type AddressDetailQueryVariables = Exact<{
  address?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
}>;

export type AddressDetailQuery = {
  __typename?: 'RootQueryType';
  addressDetail?:
    | {
        __typename?: 'address';
        city?: string | null | undefined;
        county?: string | null | undefined;
        state?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CreateArticleMutationVariables = Exact<{
  article?: InputMaybe<ArticleInput>;
}>;

export type CreateArticleMutation = {
  __typename?: 'RootMutationType';
  createArticle?: { __typename?: 'article'; id?: number | null | undefined } | null | undefined;
};

export type ArticlesQueryVariables = Exact<{ [key: string]: never }>;

export type ArticlesQuery = {
  __typename?: 'RootQueryType';
  articles?:
    | Array<
        | {
            __typename?: 'article';
            id?: number | null | undefined;
            title?: string | null | undefined;
            headline?: string | null | undefined;
            url?: string | null | undefined;
            com_visible?: boolean | null | undefined;
            created_at?: any | null | undefined;
            thumbnail_key?: string | null | undefined;
            thumbnail?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type DeleteArticleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteArticleMutation = {
  __typename?: 'RootMutationType';
  deleteArticle?: { __typename?: 'article'; id?: number | null | undefined } | null | undefined;
};

export type UpdateArticleMutationVariables = Exact<{
  article?: InputMaybe<ArticleInput>;
}>;

export type UpdateArticleMutation = {
  __typename?: 'RootMutationType';
  updateArticle?:
    | {
        __typename?: 'article';
        id?: number | null | undefined;
        title?: string | null | undefined;
        headline?: string | null | undefined;
        url?: string | null | undefined;
        com_visible?: boolean | null | undefined;
        created_at?: any | null | undefined;
        thumbnail_key?: string | null | undefined;
        thumbnail?: string | null | undefined;
      }
    | null
    | undefined;
};

export type AuctionsQueryVariables = Exact<{ [key: string]: never }>;

export type AuctionsQuery = {
  __typename?: 'RootQueryType';
  auctions?:
    | Array<
        | {
            __typename?: 'auction';
            id?: number | null | undefined;
            name?: string | null | undefined;
            address_line?: string | null | undefined;
            zip?: string | null | undefined;
            state?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type AuctionUpsertMutationVariables = Exact<{
  auction?: InputMaybe<AuctionInput>;
}>;

export type AuctionUpsertMutation = {
  __typename?: 'RootMutationType';
  auctionUpsert?: { __typename?: 'auction'; id?: number | null | undefined } | null | undefined;
};

export type AuctionDistancesQueryVariables = Exact<{
  data?: InputMaybe<AuctionDistancesInput>;
}>;

export type AuctionDistancesQuery = {
  __typename?: 'RootQueryType';
  auctionDistances?:
    | {
        __typename?: 'auctionDistances';
        name?: string | null | undefined;
        transport_cost?: number | null | undefined;
        id?: number | null | undefined;
      }
    | null
    | undefined;
};

export type AuctionAverageTransportCostQueryVariables = Exact<{
  id: Scalars['ID'];
  customerAddress: Scalars['String'];
}>;

export type AuctionAverageTransportCostQuery = {
  __typename?: 'RootQueryType';
  auctionAverageTransportCost?: number | null | undefined;
};

export type GetAuth0RolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAuth0RolesQuery = {
  __typename?: 'RootQueryType';
  getAuth0Roles?:
    | Array<{
        __typename?: 'Auth0Role';
        id?: string | null | undefined;
        name?: string | null | undefined;
      }>
    | null
    | undefined;
};

export type BanksQueryVariables = Exact<{
  state?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  selectedBank?: InputMaybe<Scalars['String']>;
}>;

export type BanksQuery = {
  __typename?: 'RootQueryType';
  banks?:
    | Array<
        | {
            __typename?: 'bank';
            id?: number | null | undefined;
            name?: string | null | undefined;
            r1_fsid?: string | null | undefined;
            active?: boolean | null | undefined;
            display_name?: string | null | undefined;
            reserve_structure?:
              | Array<
                  | {
                      __typename?: 'reserveStructure';
                      id?: number | null | undefined;
                      bank_id?: number | null | undefined;
                      rate_adj_min?: number | null | undefined;
                      rate_adj_max?: number | null | undefined;
                      flat_percentage?: number | null | undefined;
                      term_min?: number | null | undefined;
                      term_max?: number | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            bank_markups?:
              | Array<
                  | {
                      __typename?: 'bankMarkup';
                      term_min?: number | null | undefined;
                      term_max?: number | null | undefined;
                      max_markup?: number | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type BankInfoQueryVariables = Exact<{
  deal_id: Scalars['ID'];
}>;

export type BankInfoQuery = {
  __typename?: 'RootQueryType';
  bankInfo?:
    | {
        __typename?: 'bank';
        name?: string | null | undefined;
        address?: string | null | undefined;
        city?: string | null | undefined;
        state?: string | null | undefined;
        zip?: string | null | undefined;
        phone?: string | null | undefined;
        r1_fsid?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CallOutMutationVariables = Exact<{
  calledNumber: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
}>;

export type CallOutMutation = {
  __typename?: 'RootMutationType';
  callOut?:
    | {
        __typename?: 'call';
        calledNumber?: string | null | undefined;
        id?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetUserCallHistoryQueryVariables = Exact<{
  items_per_page: Scalars['Int'];
  page: Scalars['Int'];
}>;

export type GetUserCallHistoryQuery = {
  __typename?: 'RootQueryType';
  getUserCallHistory?:
    | {
        __typename?: 'CallsPagination';
        totalRecords?: number | null | undefined;
        calls?:
          | Array<
              | {
                  __typename?: 'call';
                  id?: string | null | undefined;
                  callStatus?: string | null | undefined;
                  direction?: string | null | undefined;
                  twilioNumber?: string | null | undefined;
                  fromNumber?: string | null | undefined;
                  calledNumber?: string | null | undefined;
                  user_id?: number | null | undefined;
                  recordingSid?: string | null | undefined;
                  recordingUrl?: string | null | undefined;
                  outcome?: string | null | undefined;
                  calledCity?: string | null | undefined;
                  calledState?: string | null | undefined;
                  created_at?: any | null | undefined;
                  deal?:
                    | {
                        __typename?: 'deal';
                        id?: number | null | undefined;
                        state?: string | null | undefined;
                        customer?:
                          | {
                              __typename?: 'customer';
                              id?: number | null | undefined;
                              first_name?: string | null | undefined;
                              last_name?: string | null | undefined;
                              phone_number?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        cobuyer?:
                          | {
                              __typename?: 'customer';
                              id?: number | null | undefined;
                              first_name?: string | null | undefined;
                              last_name?: string | null | undefined;
                              phone_number?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        contact?:
                          | {
                              __typename?: 'dealContact';
                              id?: number | null | undefined;
                              first_name?: string | null | undefined;
                              last_name?: string | null | undefined;
                              phone_number?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        second_contact?:
                          | {
                              __typename?: 'dealContact';
                              id?: number | null | undefined;
                              first_name?: string | null | undefined;
                              last_name?: string | null | undefined;
                              phone_number?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type HangUpCallMutationVariables = Exact<{
  conference_id: Scalars['String'];
}>;

export type HangUpCallMutation = {
  __typename?: 'RootMutationType';
  hangUpCall?: boolean | null | undefined;
};

export type SaveNewCustomerCallOutcomeMutationVariables = Exact<{
  call_id: Scalars['String'];
  outcome?: InputMaybe<Scalars['String']>;
  outcome_description?: InputMaybe<Scalars['String']>;
}>;

export type SaveNewCustomerCallOutcomeMutation = {
  __typename?: 'RootMutationType';
  saveNewCustomerCallOutcome?:
    | { __typename?: 'call'; id?: string | null | undefined }
    | null
    | undefined;
};

export type CarDetailVinQueryVariables = Exact<{
  vin?: InputMaybe<Scalars['String']>;
}>;

export type CarDetailVinQuery = {
  __typename?: 'RootQueryType';
  carDetailVin?:
    | {
        __typename?: 'car';
        year?: string | null | undefined;
        make?: string | null | undefined;
        model?: string | null | undefined;
        vehicle_type?: string | null | undefined;
        fuel_type?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CarDetailLicensePlateQueryVariables = Exact<{
  license_plate_state: Scalars['String'];
  license_plate_number: Scalars['String'];
}>;

export type CarDetailLicensePlateQuery = {
  __typename?: 'RootQueryType';
  carDetailLicensePlate?:
    | {
        __typename?: 'car';
        vin?: string | null | undefined;
        year?: string | null | undefined;
        make?: string | null | undefined;
        model?: string | null | undefined;
        vehicle_type?: string | null | undefined;
        fuel_type?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetWarrantyInfoQueryVariables = Exact<{
  make: Scalars['String'];
  mileage: Scalars['Int'];
  year: Scalars['String'];
}>;

export type GetWarrantyInfoQuery = {
  __typename?: 'RootQueryType';
  getWarrantyInfo?:
    | {
        __typename?: 'carWarranty';
        full_years?: number | null | undefined;
        full_miles?: number | null | undefined;
        full_status?: string | null | undefined;
        power_train_years?: number | null | undefined;
        power_train_miles?: number | null | undefined;
        power_train_status?: string | null | undefined;
      }
    | null
    | undefined;
};

export type VehicleInfoUpsertMutationVariables = Exact<{
  deal?: InputMaybe<DealInput>;
}>;

export type VehicleInfoUpsertMutation = {
  __typename?: 'RootMutationType';
  vehicleInfoUpsert?:
    | {
        __typename?: 'deal';
        id?: number | null | undefined;
        car?:
          | {
              __typename?: 'car';
              id?: number | null | undefined;
              license_plate_number?: string | null | undefined;
              license_plate_state?: string | null | undefined;
              vin?: string | null | undefined;
              year?: string | null | undefined;
              make?: string | null | undefined;
              model?: string | null | undefined;
              mileage?: number | null | undefined;
              color?: string | null | undefined;
              book_value?: number | null | undefined;
              retail_book_value?: number | null | undefined;
              jdp_adjusted_clean_trade?: number | null | undefined;
              jdp_adjusted_clean_retail?: number | null | undefined;
              vehicle_type?: string | null | undefined;
              fuel_type?: string | null | undefined;
              kbb_trim_name?: string | null | undefined;
              mmr?: number | null | undefined;
              registration_expiration?: any | null | undefined;
              odometer_status?: OdometerStatus | null | undefined;
              payoff?:
                | {
                    __typename?: 'payoff';
                    id?: number | null | undefined;
                    lienholder_name?: string | null | undefined;
                    lienholder_slug?: string | null | undefined;
                    lender_name?: string | null | undefined;
                    account_number?: string | null | undefined;
                    vehicle_payoff?: number | null | undefined;
                    sales_tax_from_payoff?: number | null | undefined;
                    good_through_date?: any | null | undefined;
                    lease_term?: number | null | undefined;
                    remaining_payments?: number | null | undefined;
                    msrp?: number | null | undefined;
                    residual_percentage?: number | null | undefined;
                    sales_price?: number | null | undefined;
                    cap_reduction?: number | null | undefined;
                    money_down?: number | null | undefined;
                    money_factor?: number | null | undefined;
                    termination_fees?: number | null | undefined;
                    cap_cost?: number | null | undefined;
                    residual_amount?: number | null | undefined;
                    estimated_payoff?: number | null | undefined;
                    old_lease_payment?: number | null | undefined;
                    payoff_includes_sales_tax?: boolean | null | undefined;
                    sales_tax_from_payoff_entered_manually?: boolean | null | undefined;
                    verification_status?: PayoffVerificationStatus | null | undefined;
                    next_payment_date?: any | null | undefined;
                    user_entered_total_payoff?: number | null | undefined;
                    maturity_date?: any | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        financial_info_acquisition?:
          | {
              __typename?: 'financialInfoAcquisition';
              id?: number | null | undefined;
              deal_id?: number | null | undefined;
              kbb_lending?: number | null | undefined;
              appraised_value?: number | null | undefined;
              max_cash_to_customer?: number | null | undefined;
              max_total_cost?: number | null | undefined;
              cash_to_customer?: number | null | undefined;
              is_approved?: boolean | null | undefined;
              auction_fees?: number | null | undefined;
              transport_cost?: number | null | undefined;
              total_cost?: number | null | undefined;
              auction_id?: number | null | undefined;
              gross_profit?: number | null | undefined;
              sell_price?: number | null | undefined;
              overriding_user_id?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type VehicleMileageQueryVariables = Exact<{
  vin: Scalars['String'];
}>;

export type VehicleMileageQuery = {
  __typename?: 'RootQueryType';
  vehicleMileage?:
    | {
        __typename?: 'VehicleMileageGQL';
        estimatedAverageMiles?: number | null | undefined;
        eventCount?: number | null | undefined;
        lastOdometer?: number | null | undefined;
        lastOdometerDate?: string | null | undefined;
        resultCode?: number | null | undefined;
        resultMessage?: string | null | undefined;
        rollback?: string | null | undefined;
        vin?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateCarMutationVariables = Exact<{
  car: CarInput;
}>;

export type UpdateCarMutation = {
  __typename?: 'RootMutationType';
  updateCar?:
    | {
        __typename?: 'car';
        id?: number | null | undefined;
        registration_expiration?: any | null | undefined;
      }
    | null
    | undefined;
};

export type ConditionReportUpsertMutationVariables = Exact<{
  conditionReport?: InputMaybe<ConditionReportUpsertInput>;
}>;

export type ConditionReportUpsertMutation = {
  __typename?: 'RootMutationType';
  conditionReportUpsert?:
    | { __typename?: 'conditionReportUpsert'; id?: number | null | undefined }
    | null
    | undefined;
};

export type DetermineApprovalQueryQueryVariables = Exact<{
  data?: InputMaybe<DetermineApprovalQueryInput>;
}>;

export type DetermineApprovalQueryQuery = {
  __typename?: 'RootQueryType';
  determineApprovalQuery?:
    | {
        __typename?: 'determineApproval';
        approved?: boolean | null | undefined;
        failedCriteria?: Array<string | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export type SaveConsentMutationVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<ConsentNameEnumType>;
}>;

export type SaveConsentMutation = {
  __typename?: 'RootMutationType';
  saveConsent: {
    __typename?: 'SaveConsent';
    isNew?: boolean | null | undefined;
    consent?:
      | {
          __typename?: 'consent';
          id?: number | null | undefined;
          type?: ConsentNameEnumType | null | undefined;
        }
      | null
      | undefined;
  };
};

export type GenerateContractsQueryVariables = Exact<{
  data?: InputMaybe<ContractsInput>;
}>;

export type GenerateContractsQuery = {
  __typename?: 'RootQueryType';
  generateContracts?:
    | {
        __typename?: 'contracts';
        files?:
          | Array<
              | {
                  __typename?: 'files';
                  url?: string | null | undefined;
                  filename?: string | null | undefined;
                  key?: string | null | undefined;
                  mediaType?: DealMediaTypeEnum | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteContractsMutationVariables = Exact<{
  filePaths: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type DeleteContractsMutation = {
  __typename?: 'RootMutationType';
  deleteContracts?: boolean | null | undefined;
};

export type SendContractsMutationVariables = Exact<{
  deal_id: Scalars['ID'];
  keys: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type SendContractsMutation = {
  __typename?: 'RootMutationType';
  sendContracts?: boolean | null | undefined;
};

export type CreditApplicationSubmissionsQueryVariables = Exact<{
  filter?: InputMaybe<CreditApplicationSubmissionFilter>;
}>;

export type CreditApplicationSubmissionsQuery = {
  __typename?: 'RootQueryType';
  creditApplicationSubmissions: Array<
    | {
        __typename?: 'CreditApplicationSubmission';
        id?: number | null | undefined;
        created_at?: any | null | undefined;
        credit_applications?:
          | Array<
              | {
                  __typename?: 'creditApplication';
                  id?: number | null | undefined;
                  credit_decisions?:
                    | Array<
                        | {
                            __typename?: 'creditDecision';
                            id?: number | null | undefined;
                            r1_fsid?: string | null | undefined;
                            r1_application_number?: string | null | undefined;
                            application_status?: CreditDecisionStatusEnum | null | undefined;
                            term?: number | null | undefined;
                            annual_percentage_rate?: number | null | undefined;
                            max_markup?: number | null | undefined;
                            created_at?: any | null | undefined;
                            balance_amount?: number | null | undefined;
                            lender_fees?: number | null | undefined;
                            r1_credit_decision_data?:
                              | {
                                  __typename?: 'r1CreditDecisionData';
                                  applicationNumber?: string | null | undefined;
                                  applicationStatus?: CreditDecisionStatusEnum | null | undefined;
                                  applicationStatusDetail?: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type CreditDecisionDataQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CreditDecisionDataQuery = {
  __typename?: 'RootQueryType';
  creditDecisionV2?:
    | {
        __typename?: 'creditDecision';
        id?: number | null | undefined;
        r1_credit_decision_data?:
          | {
              __typename?: 'r1CreditDecisionData';
              applicationNumber?: string | null | undefined;
              decision?:
                | {
                    __typename?: 'decision';
                    stipulations?: Array<string | null | undefined> | null | undefined;
                    decisionComments?: Array<string | null | undefined> | null | undefined;
                    conditionRejection?:
                      | Array<
                          | {
                              __typename?: 'conditionRejection';
                              conditionRejectionText?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreditDecisionApplicationNumberQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CreditDecisionApplicationNumberQuery = {
  __typename?: 'RootQueryType';
  creditDecisionV2?:
    | {
        __typename?: 'creditDecision';
        id?: number | null | undefined;
        r1_application_number?: string | null | undefined;
      }
    | null
    | undefined;
};

export type SelectCreditDecisionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SelectCreditDecisionMutation = {
  __typename?: 'RootMutationType';
  selectCreditDecision?:
    | {
        __typename?: 'SelectCreditDecision';
        creditDecision: { __typename?: 'creditDecision'; id?: number | null | undefined };
        financialInfo: {
          __typename?: 'financialInfo';
          id?: number | null | undefined;
          bank?: string | null | undefined;
          bank_fees?: number | null | undefined;
          selected_credit_decision_id?: number | null | undefined;
          term?: number | null | undefined;
          sell_rate?: number | null | undefined;
          buy_rate?: number | null | undefined;
          first_payment_date?: any | null | undefined;
        };
      }
    | null
    | undefined;
};

export type GetCreditDecisionByR1FsidQueryVariables = Exact<{
  deal_id: Scalars['ID'];
  r1_fsid: Scalars['String'];
}>;

export type GetCreditDecisionByR1FsidQuery = {
  __typename?: 'RootQueryType';
  getCreditDecisionByR1FSID?:
    | {
        __typename?: 'creditDecision';
        id?: number | null | undefined;
        r1_application_number?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateCustomerMutationVariables = Exact<{
  customer?: InputMaybe<CustomerInput>;
}>;

export type UpdateCustomerMutation = {
  __typename?: 'RootMutationType';
  updateCustomer?: { __typename?: 'customer'; id?: number | null | undefined } | null | undefined;
};

export type UpdateCustomerAndAddressMutationVariables = Exact<{
  customer?: InputMaybe<CustomerInput>;
}>;

export type UpdateCustomerAndAddressMutation = {
  __typename?: 'RootMutationType';
  updateCustomerAndAddress?:
    | { __typename?: 'customer'; id?: number | null | undefined }
    | null
    | undefined;
};

export type UpdateInsuranceInfoMutationVariables = Exact<{
  id: Scalars['ID'];
  insurance_company?: InputMaybe<Scalars['String']>;
  policy_no?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['Date']>;
}>;

export type UpdateInsuranceInfoMutation = {
  __typename?: 'RootMutationType';
  updateInsuranceInfo?:
    | {
        __typename?: 'proofOfInsurance';
        id?: number | null | undefined;
        company_name?: string | null | undefined;
        expires?: any | null | undefined;
        policy_number?: string | null | undefined;
      }
    | null
    | undefined;
};

export type AssignmentUsersFragmentFragment = {
  __typename?: 'deal';
  pod_id?: number | null | undefined;
  setter_id?: number | null | undefined;
  closer_id?: number | null | undefined;
  closer2_id?: number | null | undefined;
  structuring_manager_id?: number | null | undefined;
  funding_clerk_id?: number | null | undefined;
  pod?:
    | {
        __typename?: 'pod';
        id?: number | null | undefined;
        name?: string | null | undefined;
        color?: string | null | undefined;
      }
    | null
    | undefined;
  setter?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        pods?:
          | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
  closer?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        pods?:
          | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
  closer2?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        pods?:
          | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
  structuring_manager?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        pods?:
          | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
  funding_clerk?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        pods?:
          | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TitleUsersFragmentFragment = {
  __typename?: 'deal';
  titling_pod_id?: number | null | undefined;
  title_clerk_id?: number | null | undefined;
  title_clerk2_id?: number | null | undefined;
  titling_pod?:
    | {
        __typename?: 'pod';
        id?: number | null | undefined;
        color?: string | null | undefined;
        name?: string | null | undefined;
        leads?:
          | Array<
              | {
                  __typename?: 'baseUser';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  title_clerk?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        deleted_at?: any | null | undefined;
      }
    | null
    | undefined;
  title_clerk2?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        deleted_at?: any | null | undefined;
      }
    | null
    | undefined;
};

export type PersonCreditAppOnDealFragment = {
  __typename?: 'customer';
  id?: number | null | undefined;
  dob?: any | null | undefined;
  ssn?: string | null | undefined;
  email?: string | null | undefined;
  no_email?: boolean | null | undefined;
  first_name?: string | null | undefined;
  middle_name?: string | null | undefined;
  last_name?: string | null | undefined;
  name_suffix?: string | null | undefined;
  home_phone_number?: string | null | undefined;
  phone_number?: string | null | undefined;
  marital_status?: string | null | undefined;
  has_same_address_as_cobuyer?: boolean | null | undefined;
  address?:
    | {
        __typename?: 'address';
        id?: number | null | undefined;
        address_type?: AddressTypeEnum | null | undefined;
        address_line?: string | null | undefined;
        address_line_2?: string | null | undefined;
        city?: string | null | undefined;
        county?: string | null | undefined;
        state?: string | null | undefined;
        zip?: string | null | undefined;
        monthly_payment?: number | null | undefined;
        years_at_home?: number | null | undefined;
        months_at_home?: number | null | undefined;
        residence_type?: string | null | undefined;
      }
    | null
    | undefined;
  prev_address?:
    | {
        __typename?: 'address';
        id?: number | null | undefined;
        address_type?: AddressTypeEnum | null | undefined;
        address_line?: string | null | undefined;
        address_line_2?: string | null | undefined;
        city?: string | null | undefined;
        county?: string | null | undefined;
        state?: string | null | undefined;
        zip?: string | null | undefined;
        years_at_home?: number | null | undefined;
        months_at_home?: number | null | undefined;
      }
    | null
    | undefined;
  employment?:
    | {
        __typename?: 'employment';
        id?: number | null | undefined;
        status?: EmploymentStatusType | null | undefined;
        name?: string | null | undefined;
        job_title?: string | null | undefined;
        phone_number?: string | null | undefined;
        years_at_job?: number | null | undefined;
        months_at_job?: number | null | undefined;
        gross_income?: number | null | undefined;
        pay_frequency?: string | null | undefined;
      }
    | null
    | undefined;
  prev_employment?:
    | {
        __typename?: 'employment';
        id?: number | null | undefined;
        name?: string | null | undefined;
        job_title?: string | null | undefined;
        years_at_job?: number | null | undefined;
        months_at_job?: number | null | undefined;
      }
    | null
    | undefined;
  proof_of_insurance?:
    | {
        __typename?: 'proofOfInsurance';
        id?: number | null | undefined;
        company_name?: string | null | undefined;
        policy_number?: string | null | undefined;
        expires?: any | null | undefined;
      }
    | null
    | undefined;
};

export type ContactPersonFragment = {
  __typename?: 'dealContact';
  id?: number | null | undefined;
  first_name?: string | null | undefined;
  middle_name?: string | null | undefined;
  last_name?: string | null | undefined;
  phone_number?: string | null | undefined;
  email?: string | null | undefined;
};

export type SearchDealsQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type SearchDealsQuery = {
  __typename?: 'RootQueryType';
  searchDeals?:
    | Array<{
        __typename?: 'deal';
        id?: number | null | undefined;
        state?: string | null | undefined;
        source?: string | null | undefined;
        closer_id?: number | null | undefined;
        closer2_id?: number | null | undefined;
        customer?:
          | {
              __typename?: 'customer';
              first_name?: string | null | undefined;
              middle_name?: string | null | undefined;
              last_name?: string | null | undefined;
              phone_number?: string | null | undefined;
              home_phone_number?: string | null | undefined;
              email?: string | null | undefined;
            }
          | null
          | undefined;
        car?: { __typename?: 'car'; vin?: string | null | undefined } | null | undefined;
      }>
    | null
    | undefined;
};

export type TemplateInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  sources: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  types: Array<InputMaybe<DealType>> | InputMaybe<DealType>;
}>;

export type TemplateInfoQuery = {
  __typename?: 'RootQueryType';
  deal?:
    | {
        __typename?: 'deal';
        car?:
          | {
              __typename?: 'car';
              year?: string | null | undefined;
              make?: string | null | undefined;
              model?: string | null | undefined;
              payoff?:
                | { __typename?: 'payoff'; lienholder_name?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
        financial_info?:
          | { __typename?: 'financialInfo'; money_down?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DashboardDealsQueryVariables = Exact<{
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']>;
  boot_requested_filter?: InputMaybe<Scalars['Boolean']>;
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']>;
  unread_messages_filter?: InputMaybe<Scalars['Boolean']>;
  sources: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  types: Array<InputMaybe<DealType>> | InputMaybe<DealType>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  showFollowUps?: InputMaybe<Scalars['Boolean']>;
  pod_id?: InputMaybe<Scalars['ID']>;
  user_id?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  showActiveTags?: InputMaybe<Scalars['Boolean']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']>;
}>;

export type DashboardDealsQuery = {
  __typename?: 'RootQueryType';
  dashboardDeals?:
    | Array<
        | {
            __typename?: 'deal';
            id?: number | null | undefined;
            type?: DealType | null | undefined;
            source?: string | null | undefined;
            state?: string | null | undefined;
            lane_arrival_utc?: any | null | undefined;
            default_sort_date?: any | null | undefined;
            missing_required_external_documents?: boolean | null | undefined;
            paperwork_type?: PaperworkType | null | undefined;
            request_boot?: boolean | null | undefined;
            boot_reason?: any | null | undefined;
            created_at?: any | null | undefined;
            structuring_manager_id?: number | null | undefined;
            hasRecentCommunication?: boolean | null | undefined;
            called_by_financial_specialist?: any | null | undefined;
            tags?:
              | Array<{
                  __typename?: 'Tag';
                  id: number;
                  display_name: string;
                  color: string;
                  is_dashboard_visible: boolean;
                }>
              | null
              | undefined;
            customer?:
              | {
                  __typename?: 'customer';
                  id?: number | null | undefined;
                  first_name?: string | null | undefined;
                  last_name?: string | null | undefined;
                  middle_name?: string | null | undefined;
                  phone_number?: string | null | undefined;
                  address?:
                    | {
                        __typename?: 'address';
                        state?: string | null | undefined;
                        county?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            pod?:
              | {
                  __typename?: 'pod';
                  id?: number | null | undefined;
                  name?: string | null | undefined;
                  color?: string | null | undefined;
                }
              | null
              | undefined;
            setter?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            closer?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            closer2?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            structuring_manager?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            funding_clerk?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            titling_pod?:
              | {
                  __typename?: 'pod';
                  id?: number | null | undefined;
                  color?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            title_clerk?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            title_clerk2?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            notifications?:
              | Array<
                  | {
                      __typename?: 'notification';
                      id?: number | null | undefined;
                      message?: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            follow_up?:
              | {
                  __typename?: 'followUp';
                  id?: number | null | undefined;
                  creator_id?: number | null | undefined;
                  deal_id?: number | null | undefined;
                  date_utc?: any | null | undefined;
                  note?: string | null | undefined;
                  status?: FollowUpStatusEnum | null | undefined;
                  tz?: string | null | undefined;
                  type?: FollowUpTypeEnum | null | undefined;
                }
              | null
              | undefined;
            deal_dates?:
              | { __typename?: 'dealDates'; dates?: any | null | undefined }
              | null
              | undefined;
            car?:
              | {
                  __typename?: 'car';
                  payoff?:
                    | {
                        __typename?: 'payoff';
                        lienholder_slug?: string | null | undefined;
                        lienholder_name?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            financial_info?:
              | {
                  __typename?: 'financialInfo';
                  bank?: string | null | undefined;
                  processor?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CompletedDealsQueryVariables = Exact<{
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']>;
  boot_requested_filter?: InputMaybe<Scalars['Boolean']>;
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']>;
  start_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  sources: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  types: Array<InputMaybe<DealType>> | InputMaybe<DealType>;
  user_id?: InputMaybe<Scalars['String']>;
  deal_state?: InputMaybe<Scalars['String']>;
  address_state?: InputMaybe<Scalars['String']>;
  pod_id?: InputMaybe<Scalars['ID']>;
  notifications?: InputMaybe<Scalars['Boolean']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']>;
}>;

export type CompletedDealsQuery = {
  __typename?: 'RootQueryType';
  completedDeals?:
    | Array<
        | {
            __typename?: 'deal';
            id?: number | null | undefined;
            type?: DealType | null | undefined;
            state?: string | null | undefined;
            completion_date_utc?: any | null | undefined;
            completion_date_tz?: string | null | undefined;
            customer?:
              | {
                  __typename?: 'customer';
                  first_name?: string | null | undefined;
                  last_name?: string | null | undefined;
                  name_suffix?: string | null | undefined;
                  address?:
                    | { __typename?: 'address'; state?: string | null | undefined }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            setter?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
            closer?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
            closer2?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
            car?:
              | {
                  __typename?: 'car';
                  year?: string | null | undefined;
                  make?: string | null | undefined;
                  model?: string | null | undefined;
                  vin?: string | null | undefined;
                  payoff?:
                    | {
                        __typename?: 'payoff';
                        lienholder_name?: string | null | undefined;
                        lender_name?: string | null | undefined;
                        good_through_date?: any | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            financial_info?:
              | {
                  __typename?: 'financialInfo';
                  bank?: string | null | undefined;
                  amount_financed?: number | null | undefined;
                }
              | null
              | undefined;
            notifications?:
              | Array<
                  | {
                      __typename?: 'notification';
                      id?: number | null | undefined;
                      message?: string | null | undefined;
                      seen?: boolean | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            deal_dates?:
              | { __typename?: 'dealDates'; dates?: any | null | undefined }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type DealDetailNetsuiteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DealDetailNetsuiteMutation = {
  __typename?: 'RootMutationType';
  dealDetailNetsuite?: { __typename?: 'deal'; id?: number | null | undefined } | null | undefined;
};

export type DealUpdateAssignmentsMutationVariables = Exact<{
  id: Scalars['ID'];
  type?: InputMaybe<DealType>;
  pod_id?: InputMaybe<Scalars['ID']>;
  setter_id?: InputMaybe<Scalars['String']>;
  closer_id?: InputMaybe<Scalars['String']>;
  closer2_id?: InputMaybe<Scalars['String']>;
  structuring_manager_id?: InputMaybe<Scalars['String']>;
  funding_clerk_id?: InputMaybe<Scalars['String']>;
  titling_pod_id?: InputMaybe<Scalars['ID']>;
  title_clerk_id?: InputMaybe<Scalars['String']>;
  title_clerk2_id?: InputMaybe<Scalars['String']>;
  deal_source?: InputMaybe<Scalars['String']>;
}>;

export type DealUpdateAssignmentsMutation = {
  __typename?: 'RootMutationType';
  dealUpdateAssignments?:
    | {
        __typename?: 'deal';
        id?: number | null | undefined;
        type?: DealType | null | undefined;
        source?: string | null | undefined;
        pod_id?: number | null | undefined;
        setter_id?: number | null | undefined;
        closer_id?: number | null | undefined;
        closer2_id?: number | null | undefined;
        structuring_manager_id?: number | null | undefined;
        funding_clerk_id?: number | null | undefined;
        titling_pod_id?: number | null | undefined;
        title_clerk_id?: number | null | undefined;
        title_clerk2_id?: number | null | undefined;
        car?:
          | {
              __typename?: 'car';
              payoff?:
                | {
                    __typename?: 'payoff';
                    lender_name?: string | null | undefined;
                    lienholder_name?: string | null | undefined;
                    lienholder_slug?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        customer?:
          | {
              __typename?: 'customer';
              address?:
                | { __typename?: 'address'; moved_states?: boolean | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
        financial_info?:
          | {
              __typename?: 'financialInfo';
              bank?: string | null | undefined;
              title_registration_option?: TitleRegistrationOption | null | undefined;
              plate_transfer?: boolean | null | undefined;
              buyer_not_lessee?: boolean | null | undefined;
              sales_tax_rate?: number | null | undefined;
              base_tax_amount?: number | null | undefined;
              warranty_tax_amount?: number | null | undefined;
              new_registration_fee?: number | null | undefined;
              title_fee?: number | null | undefined;
            }
          | null
          | undefined;
        pod?:
          | {
              __typename?: 'pod';
              id?: number | null | undefined;
              name?: string | null | undefined;
              color?: string | null | undefined;
            }
          | null
          | undefined;
        setter?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              pods?:
                | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
        closer?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              pods?:
                | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
        closer2?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              pods?:
                | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
        structuring_manager?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              pods?:
                | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
        funding_clerk?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              pods?:
                | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
        titling_pod?:
          | {
              __typename?: 'pod';
              id?: number | null | undefined;
              color?: string | null | undefined;
              name?: string | null | undefined;
              leads?:
                | Array<
                    | {
                        __typename?: 'baseUser';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        title_clerk?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              deleted_at?: any | null | undefined;
            }
          | null
          | undefined;
        title_clerk2?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              deleted_at?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DealUpdateStateMutationVariables = Exact<{
  id: Scalars['ID'];
  state?: InputMaybe<Scalars['String']>;
  clearSetDate?: InputMaybe<Scalars['Boolean']>;
}>;

export type DealUpdateStateMutation = {
  __typename?: 'RootMutationType';
  dealUpdateState?:
    | {
        __typename?: 'DealWithErrors';
        errors?: Array<string | null | undefined> | null | undefined;
        deal?:
          | {
              __typename?: 'deal';
              id?: number | null | undefined;
              state?: string | null | undefined;
              financial_info?:
                | {
                    __typename?: 'financialInfo';
                    id?: number | null | undefined;
                    first_payment_date?: any | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ImportDealMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ImportDealMutation = {
  __typename?: 'RootMutationType';
  importDeal?: { __typename?: 'deal'; id?: number | null | undefined } | null | undefined;
};

export type DealTagsUpsertMutationVariables = Exact<{
  dealId: Scalars['ID'];
  tagIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DealTagsUpsertMutation = {
  __typename?: 'RootMutationType';
  dealTagsUpsert: Array<{
    __typename?: 'Tag';
    id: number;
    slug: TagSlugEnum;
    display_name: string;
    color: string;
    is_active: boolean;
    is_dashboard_visible: boolean;
  }>;
};

export type DealAssignMutationVariables = Exact<{
  deal_id: Scalars['ID'];
  assignee_id?: InputMaybe<Scalars['String']>;
  deal_state?: InputMaybe<Scalars['String']>;
  role: Scalars['String'];
  allow_reassign?: InputMaybe<Scalars['Boolean']>;
}>;

export type DealAssignMutation = {
  __typename?: 'RootMutationType';
  dealAssign?: { __typename?: 'deal'; id?: number | null | undefined } | null | undefined;
};

export type DealUpdateRequestBootMutationVariables = Exact<{
  id: Scalars['ID'];
  request_boot: Scalars['Boolean'];
  boot_reason: BootReasonInput;
  partial_note?: InputMaybe<NoteInput>;
  boot_deal?: InputMaybe<Scalars['Boolean']>;
}>;

export type DealUpdateRequestBootMutation = {
  __typename?: 'RootMutationType';
  dealUpdateRequestBoot?:
    | {
        __typename?: 'deal';
        request_boot?: boolean | null | undefined;
        boot_reason?: any | null | undefined;
        state?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ProcessPaperworkMutationVariables = Exact<{
  id: Scalars['ID'];
  state: Scalars['String'];
  paperwork_type: Scalars['String'];
}>;

export type ProcessPaperworkMutation = {
  __typename?: 'RootMutationType';
  processPaperwork?: { __typename?: 'deal'; id?: number | null | undefined } | null | undefined;
};

export type UpdateCreditAppOnDealMutationVariables = Exact<{
  input: CreditAppInput;
}>;

export type UpdateCreditAppOnDealMutation = {
  __typename?: 'RootMutationType';
  updateCreditAppOnDeal?:
    | {
        __typename?: 'UpdateCreditAppOnDealResponse';
        id: number;
        state: string;
        odometer_status?: OdometerStatus | null | undefined;
        customer: {
          __typename?: 'customer';
          id?: number | null | undefined;
          dob?: any | null | undefined;
          ssn?: string | null | undefined;
          email?: string | null | undefined;
          no_email?: boolean | null | undefined;
          first_name?: string | null | undefined;
          middle_name?: string | null | undefined;
          last_name?: string | null | undefined;
          name_suffix?: string | null | undefined;
          home_phone_number?: string | null | undefined;
          phone_number?: string | null | undefined;
          marital_status?: string | null | undefined;
          has_same_address_as_cobuyer?: boolean | null | undefined;
          address?:
            | {
                __typename?: 'address';
                id?: number | null | undefined;
                address_type?: AddressTypeEnum | null | undefined;
                address_line?: string | null | undefined;
                address_line_2?: string | null | undefined;
                city?: string | null | undefined;
                county?: string | null | undefined;
                state?: string | null | undefined;
                zip?: string | null | undefined;
                monthly_payment?: number | null | undefined;
                years_at_home?: number | null | undefined;
                months_at_home?: number | null | undefined;
                residence_type?: string | null | undefined;
              }
            | null
            | undefined;
          prev_address?:
            | {
                __typename?: 'address';
                id?: number | null | undefined;
                address_type?: AddressTypeEnum | null | undefined;
                address_line?: string | null | undefined;
                address_line_2?: string | null | undefined;
                city?: string | null | undefined;
                county?: string | null | undefined;
                state?: string | null | undefined;
                zip?: string | null | undefined;
                years_at_home?: number | null | undefined;
                months_at_home?: number | null | undefined;
              }
            | null
            | undefined;
          employment?:
            | {
                __typename?: 'employment';
                id?: number | null | undefined;
                status?: EmploymentStatusType | null | undefined;
                name?: string | null | undefined;
                job_title?: string | null | undefined;
                phone_number?: string | null | undefined;
                years_at_job?: number | null | undefined;
                months_at_job?: number | null | undefined;
                gross_income?: number | null | undefined;
                pay_frequency?: string | null | undefined;
              }
            | null
            | undefined;
          prev_employment?:
            | {
                __typename?: 'employment';
                id?: number | null | undefined;
                name?: string | null | undefined;
                job_title?: string | null | undefined;
                years_at_job?: number | null | undefined;
                months_at_job?: number | null | undefined;
              }
            | null
            | undefined;
          proof_of_insurance?:
            | {
                __typename?: 'proofOfInsurance';
                id?: number | null | undefined;
                company_name?: string | null | undefined;
                policy_number?: string | null | undefined;
                expires?: any | null | undefined;
              }
            | null
            | undefined;
        };
        cobuyer?:
          | {
              __typename?: 'customer';
              id?: number | null | undefined;
              dob?: any | null | undefined;
              ssn?: string | null | undefined;
              email?: string | null | undefined;
              no_email?: boolean | null | undefined;
              first_name?: string | null | undefined;
              middle_name?: string | null | undefined;
              last_name?: string | null | undefined;
              name_suffix?: string | null | undefined;
              home_phone_number?: string | null | undefined;
              phone_number?: string | null | undefined;
              marital_status?: string | null | undefined;
              has_same_address_as_cobuyer?: boolean | null | undefined;
              address?:
                | {
                    __typename?: 'address';
                    id?: number | null | undefined;
                    address_type?: AddressTypeEnum | null | undefined;
                    address_line?: string | null | undefined;
                    address_line_2?: string | null | undefined;
                    city?: string | null | undefined;
                    county?: string | null | undefined;
                    state?: string | null | undefined;
                    zip?: string | null | undefined;
                    monthly_payment?: number | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                    residence_type?: string | null | undefined;
                  }
                | null
                | undefined;
              prev_address?:
                | {
                    __typename?: 'address';
                    id?: number | null | undefined;
                    address_type?: AddressTypeEnum | null | undefined;
                    address_line?: string | null | undefined;
                    address_line_2?: string | null | undefined;
                    city?: string | null | undefined;
                    county?: string | null | undefined;
                    state?: string | null | undefined;
                    zip?: string | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                  }
                | null
                | undefined;
              employment?:
                | {
                    __typename?: 'employment';
                    id?: number | null | undefined;
                    status?: EmploymentStatusType | null | undefined;
                    name?: string | null | undefined;
                    job_title?: string | null | undefined;
                    phone_number?: string | null | undefined;
                    years_at_job?: number | null | undefined;
                    months_at_job?: number | null | undefined;
                    gross_income?: number | null | undefined;
                    pay_frequency?: string | null | undefined;
                  }
                | null
                | undefined;
              prev_employment?:
                | {
                    __typename?: 'employment';
                    id?: number | null | undefined;
                    name?: string | null | undefined;
                    job_title?: string | null | undefined;
                    years_at_job?: number | null | undefined;
                    months_at_job?: number | null | undefined;
                  }
                | null
                | undefined;
              proof_of_insurance?:
                | {
                    __typename?: 'proofOfInsurance';
                    id?: number | null | undefined;
                    company_name?: string | null | undefined;
                    policy_number?: string | null | undefined;
                    expires?: any | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        financial_info: {
          __typename?: 'financialInfo';
          id?: number | null | undefined;
          term?: number | null | undefined;
          money_down?: number | null | undefined;
          buyer_not_lessee?: boolean | null | undefined;
        };
        contact?:
          | {
              __typename?: 'dealContact';
              id?: number | null | undefined;
              first_name?: string | null | undefined;
              middle_name?: string | null | undefined;
              last_name?: string | null | undefined;
              phone_number?: string | null | undefined;
              email?: string | null | undefined;
            }
          | null
          | undefined;
        second_contact?:
          | {
              __typename?: 'dealContact';
              id?: number | null | undefined;
              first_name?: string | null | undefined;
              middle_name?: string | null | undefined;
              last_name?: string | null | undefined;
              phone_number?: string | null | undefined;
              email?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type OverrideCalledByFinancialSpecialistMutationVariables = Exact<{
  dealId: Scalars['ID'];
}>;

export type OverrideCalledByFinancialSpecialistMutation = {
  __typename?: 'RootMutationType';
  overrideCalledByFinancialSpecialist?:
    | {
        __typename?: 'deal';
        id?: number | null | undefined;
        called_by_financial_specialist?: any | null | undefined;
      }
    | null
    | undefined;
};

export type OnDealCompleteSubscriptionVariables = Exact<{
  sources: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  types: Array<InputMaybe<DealType>> | InputMaybe<DealType>;
}>;

export type OnDealCompleteSubscription = {
  __typename?: 'RootSubscriptionType';
  onDealComplete?: boolean | null | undefined;
};

export type CustomDatesUpdateMutationVariables = Exact<{
  deal_id: Scalars['ID'];
  date_key: Scalars['String'];
  date: Scalars['DateTime'];
}>;

export type CustomDatesUpdateMutation = {
  __typename?: 'RootMutationType';
  customDatesUpdate?:
    | {
        __typename?: 'dealDates';
        id?: number | null | undefined;
        custom_dates?: any | null | undefined;
      }
    | null
    | undefined;
};

export type ReferralSourceFragmentFragment = {
  __typename?: 'referralSource';
  id?: number | null | undefined;
  source_name?: ReferralSourceEnum | null | undefined;
  other_source_description?: string | null | undefined;
};

export type DealStatesQueryVariables = Exact<{
  dealId: Scalars['ID'];
}>;

export type DealStatesQuery = {
  __typename?: 'RootQueryType';
  dealStates?:
    | Array<
        | {
            __typename?: 'dealState';
            id?: number | null | undefined;
            state?: string | null | undefined;
            updated_date_utc?: any | null | undefined;
            updated_at?: any | null | undefined;
            user?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GenerateDocumentsQueryVariables = Exact<{
  data?: InputMaybe<DocumentsInput>;
}>;

export type GenerateDocumentsQuery = {
  __typename?: 'RootQueryType';
  generateDocuments?:
    | {
        __typename?: 'documents';
        errors?: Array<string | null | undefined> | null | undefined;
        files?:
          | Array<
              | {
                  __typename?: 'files';
                  url?: string | null | undefined;
                  filename?: string | null | undefined;
                  key?: string | null | undefined;
                  mediaType?: DealMediaTypeEnum | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RetrieveDealDocsUploadedToR1QueryVariables = Exact<{
  dealJacketID: Scalars['String'];
  includePdfBinary?: InputMaybe<Scalars['Boolean']>;
}>;

export type RetrieveDealDocsUploadedToR1Query = {
  __typename?: 'RootQueryType';
  retrieveDealDocsUploadedToR1?:
    | Array<
        | {
            __typename?: 'DealDocument';
            documentName?: string | null | undefined;
            documentType?: string | null | undefined;
            signatureStatus?: DocumentSignatureStatusEnum | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ValidateBankContractQueryVariables = Exact<{
  deal_id: Scalars['ID'];
}>;

export type ValidateBankContractQuery = {
  __typename?: 'RootQueryType';
  validateBankContract?: boolean | null | undefined;
};

export type GeneratePurchaseOrderQueryVariables = Exact<{
  deal_id: Scalars['ID'];
}>;

export type GeneratePurchaseOrderQuery = {
  __typename?: 'RootQueryType';
  generatePurchaseOrder?:
    | {
        __typename?: 'purchaseOrder';
        files?:
          | Array<
              | {
                  __typename?: 'files';
                  url?: string | null | undefined;
                  filename?: string | null | undefined;
                  key?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SendPurchaseOrderQueryVariables = Exact<{
  deal_id: Scalars['ID'];
  key: Scalars['String'];
}>;

export type SendPurchaseOrderQuery = {
  __typename?: 'RootQueryType';
  sendPurchaseOrder?: boolean | null | undefined;
};

export type UploadDocumentToRouteOneMutationVariables = Exact<{
  jacketId?: InputMaybe<Scalars['String']>;
  doc?: InputMaybe<UploadDocumentsInput>;
  withCobuyer?: InputMaybe<Scalars['Boolean']>;
}>;

export type UploadDocumentToRouteOneMutation = {
  __typename?: 'RootMutationType';
  uploadDocumentToRouteOne?: boolean | null | undefined;
};

export type SetReadyForSignaturesStatusMutationVariables = Exact<{
  jacketId?: InputMaybe<Scalars['String']>;
  documentProgressStatus?: InputMaybe<DocumentProgressStatus>;
}>;

export type SetReadyForSignaturesStatusMutation = {
  __typename?: 'RootMutationType';
  setReadyForSignaturesStatus?: boolean | null | undefined;
};

export type MergeFilesMutationVariables = Exact<{
  deal_id: Scalars['ID'];
  docs?: InputMaybe<Array<InputMaybe<DealMediaInput>> | InputMaybe<DealMediaInput>>;
  missing_required_media_list?: InputMaybe<
    Array<InputMaybe<DealMediaTypeEnum>> | InputMaybe<DealMediaTypeEnum>
  >;
}>;

export type MergeFilesMutation = {
  __typename?: 'RootMutationType';
  mergeFiles?:
    | {
        __typename?: 'mergedDocuments';
        url?: string | null | undefined;
        filename?: string | null | undefined;
        key?: string | null | undefined;
      }
    | null
    | undefined;
};

export type AddDocumentsToMediaCenterMutationVariables = Exact<{
  deal_id: Scalars['ID'];
  files:
    | Array<InputMaybe<AddDocumentsToMediaCenterInput>>
    | InputMaybe<AddDocumentsToMediaCenterInput>;
}>;

export type AddDocumentsToMediaCenterMutation = {
  __typename?: 'RootMutationType';
  addDocumentsToMediaCenter?: boolean | null | undefined;
};

export type SendDocumentsWithAdobeAndUpdateStateMutationVariables = Exact<{
  deal_id: Scalars['ID'];
  keys: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  state: DealStateEnum;
}>;

export type SendDocumentsWithAdobeAndUpdateStateMutation = {
  __typename?: 'RootMutationType';
  sendDocumentsWithAdobeAndUpdateState?:
    | { __typename?: 'deal'; id?: number | null | undefined; state?: string | null | undefined }
    | null
    | undefined;
};

export type OnContractValidationSubscriptionVariables = Exact<{
  fundingClerkOnly?: InputMaybe<Scalars['Boolean']>;
  deal_id?: InputMaybe<Scalars['ID']>;
}>;

export type OnContractValidationSubscription = {
  __typename?: 'RootSubscriptionType';
  onContractValidation?:
    | Array<
        | {
            __typename?: 'deal';
            id?: number | null | undefined;
            document_progress_status?: string | null | undefined;
            r1_contract_manually_validated?: boolean | null | undefined;
            r1_contract_validation_errors_object?:
              | {
                  __typename?: 'R1ContractValidationErrors';
                  errors?: Array<string | null | undefined> | null | undefined;
                }
              | null
              | undefined;
            r1_contract_validation_warnings_object?:
              | {
                  __typename?: 'R1ContractValidationWarnings';
                  warnings?: Array<string | null | undefined> | null | undefined;
                }
              | null
              | undefined;
            customer?:
              | {
                  __typename?: 'customer';
                  first_name?: string | null | undefined;
                  last_name?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type OnDashboardDocumentUploadSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnDashboardDocumentUploadSubscription = {
  __typename?: 'RootSubscriptionType';
  onDashboardDocumentUpload?:
    | {
        __typename?: 'DashboardDocumentUpload';
        fileType: string;
        deal: {
          __typename?: 'deal';
          id?: number | null | undefined;
          customer?:
            | {
                __typename?: 'customer';
                first_name?: string | null | undefined;
                last_name?: string | null | undefined;
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type OnDownPaymentReceivedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnDownPaymentReceivedSubscription = {
  __typename?: 'RootSubscriptionType';
  onDownPaymentReceived?:
    | {
        __typename?: 'DownPaymentReceived';
        amount: number;
        dealId: number;
        firstName: string;
        lastName: string;
        type: string;
      }
    | null
    | undefined;
};

export type DealFragmentFragment = {
  __typename: 'deal';
  id?: number | null | undefined;
  type?: DealType | null | undefined;
  source?: string | null | undefined;
  state?: string | null | undefined;
  has_problem?: boolean | null | undefined;
  completion_date_utc?: any | null | undefined;
  completion_date_tz?: string | null | undefined;
  missing_required_external_documents?: boolean | null | undefined;
  paperwork_type?: PaperworkType | null | undefined;
  direct_pay_state?: boolean | null | undefined;
  request_boot?: boolean | null | undefined;
  boot_reason?: any | null | undefined;
  r1_jacket_id?: string | null | undefined;
  r1_jacket_id_created_date_utc?: any | null | undefined;
  document_progress_status?: string | null | undefined;
  r1_contract_manually_validated?: boolean | null | undefined;
  signing_on_com?: boolean | null | undefined;
  pod_id?: number | null | undefined;
  setter_id?: number | null | undefined;
  closer_id?: number | null | undefined;
  closer2_id?: number | null | undefined;
  funding_clerk_id?: number | null | undefined;
  titling_pod_id?: number | null | undefined;
  title_clerk_id?: number | null | undefined;
  title_clerk2_id?: number | null | undefined;
  r1_contract_validation_errors_object?:
    | {
        __typename?: 'R1ContractValidationErrors';
        errors?: Array<string | null | undefined> | null | undefined;
      }
    | null
    | undefined;
  r1_contract_validation_warnings_object?:
    | {
        __typename?: 'R1ContractValidationWarnings';
        warnings?: Array<string | null | undefined> | null | undefined;
      }
    | null
    | undefined;
  pod?:
    | {
        __typename?: 'pod';
        id?: number | null | undefined;
        name?: string | null | undefined;
        color?: string | null | undefined;
      }
    | null
    | undefined;
  setter?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        pods?:
          | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
  closer?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        pods?:
          | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
  closer2?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        pods?:
          | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
  funding_clerk?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        pods?:
          | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
  titling_pod?:
    | {
        __typename?: 'pod';
        id?: number | null | undefined;
        color?: string | null | undefined;
        name?: string | null | undefined;
        leads?:
          | Array<
              | {
                  __typename?: 'baseUser';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  title_clerk?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        deleted_at?: any | null | undefined;
      }
    | null
    | undefined;
  title_clerk2?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        name?: string | null | undefined;
        deleted_at?: any | null | undefined;
      }
    | null
    | undefined;
  car?:
    | {
        __typename?: 'car';
        id?: number | null | undefined;
        license_plate_number?: string | null | undefined;
        license_plate_state?: string | null | undefined;
        vin?: string | null | undefined;
        year?: string | null | undefined;
        make?: string | null | undefined;
        model?: string | null | undefined;
        mileage?: number | null | undefined;
        color?: string | null | undefined;
        book_value?: number | null | undefined;
        retail_book_value?: number | null | undefined;
        jdp_adjusted_clean_trade?: number | null | undefined;
        jdp_adjusted_clean_retail?: number | null | undefined;
        vehicle_type?: string | null | undefined;
        fuel_type?: string | null | undefined;
        kbb_trim_name?: string | null | undefined;
        mmr?: number | null | undefined;
        registration_expiration?: any | null | undefined;
        odometer_status?: OdometerStatus | null | undefined;
        kbb_selected_options_object?:
          | {
              __typename?: 'KbbSelectedOptionsObject';
              selected_options?:
                | Array<
                    | {
                        __typename?: 'kbbSelectedOptionsType';
                        id?: number | null | undefined;
                        name?: string | null | undefined;
                        type?: string | null | undefined;
                        lendingOptionPrice?: number | null | undefined;
                        retailOptionPrice?: number | null | undefined;
                        removed?: boolean | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        payoff?:
          | {
              __typename?: 'payoff';
              id?: number | null | undefined;
              lienholder_name?: string | null | undefined;
              lienholder_slug?: string | null | undefined;
              lender_name?: string | null | undefined;
              account_number?: string | null | undefined;
              vehicle_payoff?: number | null | undefined;
              good_through_date?: any | null | undefined;
              lease_term?: number | null | undefined;
              remaining_payments?: number | null | undefined;
              msrp?: number | null | undefined;
              residual_percentage?: number | null | undefined;
              sales_price?: number | null | undefined;
              cap_reduction?: number | null | undefined;
              money_down?: number | null | undefined;
              money_factor?: number | null | undefined;
              termination_fees?: number | null | undefined;
              cap_cost?: number | null | undefined;
              residual_amount?: number | null | undefined;
              estimated_payoff?: number | null | undefined;
              old_lease_payment?: number | null | undefined;
              sales_tax_from_payoff?: number | null | undefined;
              payoff_includes_sales_tax?: boolean | null | undefined;
              sales_tax_from_payoff_entered_manually?: boolean | null | undefined;
              verification_status?: PayoffVerificationStatus | null | undefined;
              next_payment_date?: any | null | undefined;
              user_entered_total_payoff?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  customer?:
    | {
        __typename?: 'customer';
        id?: number | null | undefined;
        first_name?: string | null | undefined;
        middle_name?: string | null | undefined;
        last_name?: string | null | undefined;
        name_suffix?: string | null | undefined;
        phone_number?: string | null | undefined;
        home_phone_number?: string | null | undefined;
        email?: string | null | undefined;
        no_email?: boolean | null | undefined;
        ssn?: string | null | undefined;
        dob?: any | null | undefined;
        has_same_address_as_cobuyer?: boolean | null | undefined;
        marital_status?: string | null | undefined;
        auth0_id?: string | null | undefined;
        address?:
          | {
              __typename?: 'address';
              id?: number | null | undefined;
              address_line?: string | null | undefined;
              address_line_2?: string | null | undefined;
              zip?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              county?: string | null | undefined;
              address_type?: AddressTypeEnum | null | undefined;
              residence_type?: string | null | undefined;
              years_at_home?: number | null | undefined;
              months_at_home?: number | null | undefined;
              monthly_payment?: number | null | undefined;
              moved_states?: boolean | null | undefined;
            }
          | null
          | undefined;
        prev_address?:
          | {
              __typename?: 'address';
              id?: number | null | undefined;
              address_line?: string | null | undefined;
              address_line_2?: string | null | undefined;
              zip?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              county?: string | null | undefined;
              address_type?: AddressTypeEnum | null | undefined;
              residence_type?: string | null | undefined;
              years_at_home?: number | null | undefined;
              months_at_home?: number | null | undefined;
              monthly_payment?: number | null | undefined;
              moved_states?: boolean | null | undefined;
            }
          | null
          | undefined;
        employment?:
          | {
              __typename?: 'employment';
              id?: number | null | undefined;
              status?: EmploymentStatusType | null | undefined;
              name?: string | null | undefined;
              job_title?: string | null | undefined;
              phone_number?: string | null | undefined;
              years_at_job?: number | null | undefined;
              months_at_job?: number | null | undefined;
              gross_income?: number | null | undefined;
              pay_frequency?: string | null | undefined;
            }
          | null
          | undefined;
        prev_employment?:
          | {
              __typename?: 'employment';
              id?: number | null | undefined;
              status?: EmploymentStatusType | null | undefined;
              name?: string | null | undefined;
              job_title?: string | null | undefined;
              phone_number?: string | null | undefined;
              years_at_job?: number | null | undefined;
              months_at_job?: number | null | undefined;
              gross_income?: number | null | undefined;
              pay_frequency?: string | null | undefined;
            }
          | null
          | undefined;
        proof_of_insurance?:
          | {
              __typename?: 'proofOfInsurance';
              id?: number | null | undefined;
              company_name?: string | null | undefined;
              policy_number?: string | null | undefined;
              expires?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  cobuyer?:
    | {
        __typename?: 'customer';
        id?: number | null | undefined;
        first_name?: string | null | undefined;
        middle_name?: string | null | undefined;
        last_name?: string | null | undefined;
        name_suffix?: string | null | undefined;
        phone_number?: string | null | undefined;
        home_phone_number?: string | null | undefined;
        email?: string | null | undefined;
        no_email?: boolean | null | undefined;
        ssn?: string | null | undefined;
        dob?: any | null | undefined;
        has_same_address_as_cobuyer?: boolean | null | undefined;
        marital_status?: string | null | undefined;
        auth0_id?: string | null | undefined;
        relation_to_buyer?: RelationToBuyerEnum | null | undefined;
        address?:
          | {
              __typename?: 'address';
              id?: number | null | undefined;
              address_line?: string | null | undefined;
              address_line_2?: string | null | undefined;
              zip?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              county?: string | null | undefined;
              address_type?: AddressTypeEnum | null | undefined;
              residence_type?: string | null | undefined;
              years_at_home?: number | null | undefined;
              months_at_home?: number | null | undefined;
              monthly_payment?: number | null | undefined;
              moved_states?: boolean | null | undefined;
            }
          | null
          | undefined;
        prev_address?:
          | {
              __typename?: 'address';
              id?: number | null | undefined;
              address_line?: string | null | undefined;
              address_line_2?: string | null | undefined;
              zip?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              county?: string | null | undefined;
              address_type?: AddressTypeEnum | null | undefined;
              residence_type?: string | null | undefined;
              years_at_home?: number | null | undefined;
              months_at_home?: number | null | undefined;
              monthly_payment?: number | null | undefined;
              moved_states?: boolean | null | undefined;
            }
          | null
          | undefined;
        employment?:
          | {
              __typename?: 'employment';
              id?: number | null | undefined;
              status?: EmploymentStatusType | null | undefined;
              name?: string | null | undefined;
              job_title?: string | null | undefined;
              phone_number?: string | null | undefined;
              years_at_job?: number | null | undefined;
              months_at_job?: number | null | undefined;
              gross_income?: number | null | undefined;
              pay_frequency?: string | null | undefined;
            }
          | null
          | undefined;
        prev_employment?:
          | {
              __typename?: 'employment';
              id?: number | null | undefined;
              status?: EmploymentStatusType | null | undefined;
              name?: string | null | undefined;
              job_title?: string | null | undefined;
              phone_number?: string | null | undefined;
              years_at_job?: number | null | undefined;
              months_at_job?: number | null | undefined;
              gross_income?: number | null | undefined;
              pay_frequency?: string | null | undefined;
            }
          | null
          | undefined;
        proof_of_insurance?:
          | {
              __typename?: 'proofOfInsurance';
              id?: number | null | undefined;
              company_name?: string | null | undefined;
              policy_number?: string | null | undefined;
              expires?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  contact?:
    | {
        __typename?: 'dealContact';
        id?: number | null | undefined;
        deal_id?: number | null | undefined;
        relation_to_buyer?: RelationToBuyerEnum | null | undefined;
        first_name?: string | null | undefined;
        middle_name?: string | null | undefined;
        last_name?: string | null | undefined;
        phone_number?: string | null | undefined;
      }
    | null
    | undefined;
  second_contact?:
    | {
        __typename?: 'dealContact';
        id?: number | null | undefined;
        deal_id?: number | null | undefined;
        relation_to_buyer?: RelationToBuyerEnum | null | undefined;
        first_name?: string | null | undefined;
        middle_name?: string | null | undefined;
        last_name?: string | null | undefined;
        phone_number?: string | null | undefined;
      }
    | null
    | undefined;
  financial_info?:
    | {
        __typename?: 'financialInfo';
        id?: number | null | undefined;
        deal_id?: number | null | undefined;
        money_down?: number | null | undefined;
        base_tax_amount?: number | null | undefined;
        warranty_tax_amount?: number | null | undefined;
        title_fee?: number | null | undefined;
        new_registration_fee?: number | null | undefined;
        registration_transfer_fee?: number | null | undefined;
        doc_fee?: number | null | undefined;
        sales_tax_rate?: number | null | undefined;
        vsc_price?: number | null | undefined;
        vsc_cost?: number | null | undefined;
        gap_price?: number | null | undefined;
        gap_cost?: number | null | undefined;
        days_to_payment?: number | null | undefined;
        first_payment_date?: any | null | undefined;
        sell_rate?: number | null | undefined;
        buy_rate?: number | null | undefined;
        term?: number | null | undefined;
        bank?: string | null | undefined;
        vsc_type?: string | null | undefined;
        vsc_term?: string | null | undefined;
        payment?: number | null | undefined;
        amount_financed?: number | null | undefined;
        profit?: number | null | undefined;
        setter_commission?: number | null | undefined;
        closer_commission?: number | null | undefined;
        option_type?: string | null | undefined;
        plate_transfer?: boolean | null | undefined;
        pen_vsc_session_id?: string | null | undefined;
        pen_vsc_rate_id?: number | null | undefined;
        pen_vsc_form_id?: number | null | undefined;
        pen_gap_session_id?: string | null | undefined;
        pen_gap_rate_id?: number | null | undefined;
        pen_gap_form_id?: number | null | undefined;
        title_registration_option?: TitleRegistrationOption | null | undefined;
        processor?: string | null | undefined;
        tt_transaction_id?: string | null | undefined;
        buyer_not_lessee?: boolean | null | undefined;
        new_lienholder?:
          | {
              __typename?: 'bank';
              name?: string | null | undefined;
              r1_fsid?: string | null | undefined;
              address?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              zip?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  financial_info_acquisition?:
    | {
        __typename?: 'financialInfoAcquisition';
        id?: number | null | undefined;
        deal_id?: number | null | undefined;
        kbb_lending?: number | null | undefined;
        appraised_value?: number | null | undefined;
        max_cash_to_customer?: number | null | undefined;
        max_total_cost?: number | null | undefined;
        cash_to_customer?: number | null | undefined;
        is_approved?: boolean | null | undefined;
        auction_fees?: number | null | undefined;
        transport_cost?: number | null | undefined;
        total_cost?: number | null | undefined;
        auction_id?: number | null | undefined;
        gross_profit?: number | null | undefined;
        sell_price?: number | null | undefined;
        overriding_user_id?: string | null | undefined;
      }
    | null
    | undefined;
  condition_report?:
    | {
        __typename?: 'conditionReport';
        id?: number | null | undefined;
        deal_id?: number | null | undefined;
        accidents?: AccidentsEnumType | null | undefined;
        tires?: TiresEnumType | null | undefined;
        exterior?: Array<ExteriorEnumType | null | undefined> | null | undefined;
        interior?: Array<InteriorEnumType | null | undefined> | null | undefined;
        smoked_in?: boolean | null | undefined;
        lights_on_dash?: boolean | null | undefined;
        overall_condition?: OverallConditionEnumType | null | undefined;
        score?: number | null | undefined;
      }
    | null
    | undefined;
  document_info?:
    | {
        __typename?: 'documentInfo';
        id?: number | null | undefined;
        adobe_agreement_id?: string | null | undefined;
      }
    | null
    | undefined;
  deal_states?:
    | Array<
        | {
            __typename?: 'dealState';
            id?: number | null | undefined;
            deal_id?: number | null | undefined;
            state?: string | null | undefined;
            updated_date_utc?: any | null | undefined;
            user_id?: number | null | undefined;
            user?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  deal_dates?:
    | {
        __typename?: 'dealDates';
        id?: number | null | undefined;
        deal_id?: number | null | undefined;
        dates?: any | null | undefined;
        custom_dates?: any | null | undefined;
      }
    | null
    | undefined;
  notifications?:
    | Array<
        | {
            __typename?: 'notification';
            id?: number | null | undefined;
            message?: string | null | undefined;
            seen?: boolean | null | undefined;
            customer_id?: number | null | undefined;
            deal_contact_id?: number | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  referral_source?:
    | {
        __typename?: 'referralSource';
        id?: number | null | undefined;
        source_name?: ReferralSourceEnum | null | undefined;
        other_source_description?: string | null | undefined;
      }
    | null
    | undefined;
};

export type NewEstimateUpsertMutationVariables = Exact<{
  newEstimateUpsertInput?: InputMaybe<NewEstimateUpsertInput>;
}>;

export type NewEstimateUpsertMutation = {
  __typename?: 'RootMutationType';
  newEstimateUpsert?:
    | {
        __typename: 'deal';
        id?: number | null | undefined;
        type?: DealType | null | undefined;
        source?: string | null | undefined;
        state?: string | null | undefined;
        has_problem?: boolean | null | undefined;
        completion_date_utc?: any | null | undefined;
        completion_date_tz?: string | null | undefined;
        missing_required_external_documents?: boolean | null | undefined;
        paperwork_type?: PaperworkType | null | undefined;
        direct_pay_state?: boolean | null | undefined;
        request_boot?: boolean | null | undefined;
        boot_reason?: any | null | undefined;
        r1_jacket_id?: string | null | undefined;
        r1_jacket_id_created_date_utc?: any | null | undefined;
        document_progress_status?: string | null | undefined;
        r1_contract_manually_validated?: boolean | null | undefined;
        signing_on_com?: boolean | null | undefined;
        pod_id?: number | null | undefined;
        setter_id?: number | null | undefined;
        closer_id?: number | null | undefined;
        closer2_id?: number | null | undefined;
        funding_clerk_id?: number | null | undefined;
        titling_pod_id?: number | null | undefined;
        title_clerk_id?: number | null | undefined;
        title_clerk2_id?: number | null | undefined;
        r1_contract_validation_errors_object?:
          | {
              __typename?: 'R1ContractValidationErrors';
              errors?: Array<string | null | undefined> | null | undefined;
            }
          | null
          | undefined;
        r1_contract_validation_warnings_object?:
          | {
              __typename?: 'R1ContractValidationWarnings';
              warnings?: Array<string | null | undefined> | null | undefined;
            }
          | null
          | undefined;
        pod?:
          | {
              __typename?: 'pod';
              id?: number | null | undefined;
              name?: string | null | undefined;
              color?: string | null | undefined;
            }
          | null
          | undefined;
        setter?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              pods?:
                | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
        closer?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              pods?:
                | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
        closer2?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              pods?:
                | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
        funding_clerk?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              pods?:
                | Array<{ __typename?: 'pod'; id?: number | null | undefined } | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
        titling_pod?:
          | {
              __typename?: 'pod';
              id?: number | null | undefined;
              color?: string | null | undefined;
              name?: string | null | undefined;
              leads?:
                | Array<
                    | {
                        __typename?: 'baseUser';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        title_clerk?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              deleted_at?: any | null | undefined;
            }
          | null
          | undefined;
        title_clerk2?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
              deleted_at?: any | null | undefined;
            }
          | null
          | undefined;
        car?:
          | {
              __typename?: 'car';
              id?: number | null | undefined;
              license_plate_number?: string | null | undefined;
              license_plate_state?: string | null | undefined;
              vin?: string | null | undefined;
              year?: string | null | undefined;
              make?: string | null | undefined;
              model?: string | null | undefined;
              mileage?: number | null | undefined;
              color?: string | null | undefined;
              book_value?: number | null | undefined;
              retail_book_value?: number | null | undefined;
              jdp_adjusted_clean_trade?: number | null | undefined;
              jdp_adjusted_clean_retail?: number | null | undefined;
              vehicle_type?: string | null | undefined;
              fuel_type?: string | null | undefined;
              kbb_trim_name?: string | null | undefined;
              mmr?: number | null | undefined;
              registration_expiration?: any | null | undefined;
              odometer_status?: OdometerStatus | null | undefined;
              kbb_selected_options_object?:
                | {
                    __typename?: 'KbbSelectedOptionsObject';
                    selected_options?:
                      | Array<
                          | {
                              __typename?: 'kbbSelectedOptionsType';
                              id?: number | null | undefined;
                              name?: string | null | undefined;
                              type?: string | null | undefined;
                              lendingOptionPrice?: number | null | undefined;
                              retailOptionPrice?: number | null | undefined;
                              removed?: boolean | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              payoff?:
                | {
                    __typename?: 'payoff';
                    id?: number | null | undefined;
                    lienholder_name?: string | null | undefined;
                    lienholder_slug?: string | null | undefined;
                    lender_name?: string | null | undefined;
                    account_number?: string | null | undefined;
                    vehicle_payoff?: number | null | undefined;
                    good_through_date?: any | null | undefined;
                    lease_term?: number | null | undefined;
                    remaining_payments?: number | null | undefined;
                    msrp?: number | null | undefined;
                    residual_percentage?: number | null | undefined;
                    sales_price?: number | null | undefined;
                    cap_reduction?: number | null | undefined;
                    money_down?: number | null | undefined;
                    money_factor?: number | null | undefined;
                    termination_fees?: number | null | undefined;
                    cap_cost?: number | null | undefined;
                    residual_amount?: number | null | undefined;
                    estimated_payoff?: number | null | undefined;
                    old_lease_payment?: number | null | undefined;
                    sales_tax_from_payoff?: number | null | undefined;
                    payoff_includes_sales_tax?: boolean | null | undefined;
                    sales_tax_from_payoff_entered_manually?: boolean | null | undefined;
                    verification_status?: PayoffVerificationStatus | null | undefined;
                    next_payment_date?: any | null | undefined;
                    user_entered_total_payoff?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        customer?:
          | {
              __typename?: 'customer';
              id?: number | null | undefined;
              first_name?: string | null | undefined;
              middle_name?: string | null | undefined;
              last_name?: string | null | undefined;
              name_suffix?: string | null | undefined;
              phone_number?: string | null | undefined;
              home_phone_number?: string | null | undefined;
              email?: string | null | undefined;
              no_email?: boolean | null | undefined;
              ssn?: string | null | undefined;
              dob?: any | null | undefined;
              has_same_address_as_cobuyer?: boolean | null | undefined;
              marital_status?: string | null | undefined;
              auth0_id?: string | null | undefined;
              address?:
                | {
                    __typename?: 'address';
                    id?: number | null | undefined;
                    address_line?: string | null | undefined;
                    address_line_2?: string | null | undefined;
                    zip?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    county?: string | null | undefined;
                    address_type?: AddressTypeEnum | null | undefined;
                    residence_type?: string | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                    monthly_payment?: number | null | undefined;
                    moved_states?: boolean | null | undefined;
                  }
                | null
                | undefined;
              prev_address?:
                | {
                    __typename?: 'address';
                    id?: number | null | undefined;
                    address_line?: string | null | undefined;
                    address_line_2?: string | null | undefined;
                    zip?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    county?: string | null | undefined;
                    address_type?: AddressTypeEnum | null | undefined;
                    residence_type?: string | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                    monthly_payment?: number | null | undefined;
                    moved_states?: boolean | null | undefined;
                  }
                | null
                | undefined;
              employment?:
                | {
                    __typename?: 'employment';
                    id?: number | null | undefined;
                    status?: EmploymentStatusType | null | undefined;
                    name?: string | null | undefined;
                    job_title?: string | null | undefined;
                    phone_number?: string | null | undefined;
                    years_at_job?: number | null | undefined;
                    months_at_job?: number | null | undefined;
                    gross_income?: number | null | undefined;
                    pay_frequency?: string | null | undefined;
                  }
                | null
                | undefined;
              prev_employment?:
                | {
                    __typename?: 'employment';
                    id?: number | null | undefined;
                    status?: EmploymentStatusType | null | undefined;
                    name?: string | null | undefined;
                    job_title?: string | null | undefined;
                    phone_number?: string | null | undefined;
                    years_at_job?: number | null | undefined;
                    months_at_job?: number | null | undefined;
                    gross_income?: number | null | undefined;
                    pay_frequency?: string | null | undefined;
                  }
                | null
                | undefined;
              proof_of_insurance?:
                | {
                    __typename?: 'proofOfInsurance';
                    id?: number | null | undefined;
                    company_name?: string | null | undefined;
                    policy_number?: string | null | undefined;
                    expires?: any | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        cobuyer?:
          | {
              __typename?: 'customer';
              id?: number | null | undefined;
              first_name?: string | null | undefined;
              middle_name?: string | null | undefined;
              last_name?: string | null | undefined;
              name_suffix?: string | null | undefined;
              phone_number?: string | null | undefined;
              home_phone_number?: string | null | undefined;
              email?: string | null | undefined;
              no_email?: boolean | null | undefined;
              ssn?: string | null | undefined;
              dob?: any | null | undefined;
              has_same_address_as_cobuyer?: boolean | null | undefined;
              marital_status?: string | null | undefined;
              auth0_id?: string | null | undefined;
              relation_to_buyer?: RelationToBuyerEnum | null | undefined;
              address?:
                | {
                    __typename?: 'address';
                    id?: number | null | undefined;
                    address_line?: string | null | undefined;
                    address_line_2?: string | null | undefined;
                    zip?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    county?: string | null | undefined;
                    address_type?: AddressTypeEnum | null | undefined;
                    residence_type?: string | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                    monthly_payment?: number | null | undefined;
                    moved_states?: boolean | null | undefined;
                  }
                | null
                | undefined;
              prev_address?:
                | {
                    __typename?: 'address';
                    id?: number | null | undefined;
                    address_line?: string | null | undefined;
                    address_line_2?: string | null | undefined;
                    zip?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    county?: string | null | undefined;
                    address_type?: AddressTypeEnum | null | undefined;
                    residence_type?: string | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                    monthly_payment?: number | null | undefined;
                    moved_states?: boolean | null | undefined;
                  }
                | null
                | undefined;
              employment?:
                | {
                    __typename?: 'employment';
                    id?: number | null | undefined;
                    status?: EmploymentStatusType | null | undefined;
                    name?: string | null | undefined;
                    job_title?: string | null | undefined;
                    phone_number?: string | null | undefined;
                    years_at_job?: number | null | undefined;
                    months_at_job?: number | null | undefined;
                    gross_income?: number | null | undefined;
                    pay_frequency?: string | null | undefined;
                  }
                | null
                | undefined;
              prev_employment?:
                | {
                    __typename?: 'employment';
                    id?: number | null | undefined;
                    status?: EmploymentStatusType | null | undefined;
                    name?: string | null | undefined;
                    job_title?: string | null | undefined;
                    phone_number?: string | null | undefined;
                    years_at_job?: number | null | undefined;
                    months_at_job?: number | null | undefined;
                    gross_income?: number | null | undefined;
                    pay_frequency?: string | null | undefined;
                  }
                | null
                | undefined;
              proof_of_insurance?:
                | {
                    __typename?: 'proofOfInsurance';
                    id?: number | null | undefined;
                    company_name?: string | null | undefined;
                    policy_number?: string | null | undefined;
                    expires?: any | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        contact?:
          | {
              __typename?: 'dealContact';
              id?: number | null | undefined;
              deal_id?: number | null | undefined;
              relation_to_buyer?: RelationToBuyerEnum | null | undefined;
              first_name?: string | null | undefined;
              middle_name?: string | null | undefined;
              last_name?: string | null | undefined;
              phone_number?: string | null | undefined;
            }
          | null
          | undefined;
        second_contact?:
          | {
              __typename?: 'dealContact';
              id?: number | null | undefined;
              deal_id?: number | null | undefined;
              relation_to_buyer?: RelationToBuyerEnum | null | undefined;
              first_name?: string | null | undefined;
              middle_name?: string | null | undefined;
              last_name?: string | null | undefined;
              phone_number?: string | null | undefined;
            }
          | null
          | undefined;
        financial_info?:
          | {
              __typename?: 'financialInfo';
              id?: number | null | undefined;
              deal_id?: number | null | undefined;
              money_down?: number | null | undefined;
              base_tax_amount?: number | null | undefined;
              warranty_tax_amount?: number | null | undefined;
              title_fee?: number | null | undefined;
              new_registration_fee?: number | null | undefined;
              registration_transfer_fee?: number | null | undefined;
              doc_fee?: number | null | undefined;
              sales_tax_rate?: number | null | undefined;
              vsc_price?: number | null | undefined;
              vsc_cost?: number | null | undefined;
              gap_price?: number | null | undefined;
              gap_cost?: number | null | undefined;
              days_to_payment?: number | null | undefined;
              first_payment_date?: any | null | undefined;
              sell_rate?: number | null | undefined;
              buy_rate?: number | null | undefined;
              term?: number | null | undefined;
              bank?: string | null | undefined;
              vsc_type?: string | null | undefined;
              vsc_term?: string | null | undefined;
              payment?: number | null | undefined;
              amount_financed?: number | null | undefined;
              profit?: number | null | undefined;
              setter_commission?: number | null | undefined;
              closer_commission?: number | null | undefined;
              option_type?: string | null | undefined;
              plate_transfer?: boolean | null | undefined;
              pen_vsc_session_id?: string | null | undefined;
              pen_vsc_rate_id?: number | null | undefined;
              pen_vsc_form_id?: number | null | undefined;
              pen_gap_session_id?: string | null | undefined;
              pen_gap_rate_id?: number | null | undefined;
              pen_gap_form_id?: number | null | undefined;
              title_registration_option?: TitleRegistrationOption | null | undefined;
              processor?: string | null | undefined;
              tt_transaction_id?: string | null | undefined;
              buyer_not_lessee?: boolean | null | undefined;
              new_lienholder?:
                | {
                    __typename?: 'bank';
                    name?: string | null | undefined;
                    r1_fsid?: string | null | undefined;
                    address?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    zip?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        financial_info_acquisition?:
          | {
              __typename?: 'financialInfoAcquisition';
              id?: number | null | undefined;
              deal_id?: number | null | undefined;
              kbb_lending?: number | null | undefined;
              appraised_value?: number | null | undefined;
              max_cash_to_customer?: number | null | undefined;
              max_total_cost?: number | null | undefined;
              cash_to_customer?: number | null | undefined;
              is_approved?: boolean | null | undefined;
              auction_fees?: number | null | undefined;
              transport_cost?: number | null | undefined;
              total_cost?: number | null | undefined;
              auction_id?: number | null | undefined;
              gross_profit?: number | null | undefined;
              sell_price?: number | null | undefined;
              overriding_user_id?: string | null | undefined;
            }
          | null
          | undefined;
        condition_report?:
          | {
              __typename?: 'conditionReport';
              id?: number | null | undefined;
              deal_id?: number | null | undefined;
              accidents?: AccidentsEnumType | null | undefined;
              tires?: TiresEnumType | null | undefined;
              exterior?: Array<ExteriorEnumType | null | undefined> | null | undefined;
              interior?: Array<InteriorEnumType | null | undefined> | null | undefined;
              smoked_in?: boolean | null | undefined;
              lights_on_dash?: boolean | null | undefined;
              overall_condition?: OverallConditionEnumType | null | undefined;
              score?: number | null | undefined;
            }
          | null
          | undefined;
        document_info?:
          | {
              __typename?: 'documentInfo';
              id?: number | null | undefined;
              adobe_agreement_id?: string | null | undefined;
            }
          | null
          | undefined;
        deal_states?:
          | Array<
              | {
                  __typename?: 'dealState';
                  id?: number | null | undefined;
                  deal_id?: number | null | undefined;
                  state?: string | null | undefined;
                  updated_date_utc?: any | null | undefined;
                  user_id?: number | null | undefined;
                  user?:
                    | {
                        __typename?: 'user';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        deal_dates?:
          | {
              __typename?: 'dealDates';
              id?: number | null | undefined;
              deal_id?: number | null | undefined;
              dates?: any | null | undefined;
              custom_dates?: any | null | undefined;
            }
          | null
          | undefined;
        notifications?:
          | Array<
              | {
                  __typename?: 'notification';
                  id?: number | null | undefined;
                  message?: string | null | undefined;
                  seen?: boolean | null | undefined;
                  customer_id?: number | null | undefined;
                  deal_contact_id?: number | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        referral_source?:
          | {
              __typename?: 'referralSource';
              id?: number | null | undefined;
              source_name?: ReferralSourceEnum | null | undefined;
              other_source_description?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ManuallySelectBankMutationVariables = Exact<{
  dealId: Scalars['ID'];
  bank: Scalars['String'];
}>;

export type ManuallySelectBankMutation = {
  __typename?: 'RootMutationType';
  manuallySelectBank: {
    __typename?: 'financialInfo';
    id?: number | null | undefined;
    bank?: string | null | undefined;
    selected_credit_decision_id?: number | null | undefined;
    first_payment_date?: any | null | undefined;
  };
};

export type UpdateProcessorMutationVariables = Exact<{
  deal_id: Scalars['ID'];
  fi_id: Scalars['ID'];
  processor?: InputMaybe<Scalars['String']>;
}>;

export type UpdateProcessorMutation = {
  __typename?: 'RootMutationType';
  updateProcessor?:
    | {
        __typename?: 'financialInfo';
        id?: number | null | undefined;
        processor?: string | null | undefined;
      }
    | null
    | undefined;
};

export type OnTransactionCreatedSubscriptionVariables = Exact<{
  dealId: Scalars['ID'];
}>;

export type OnTransactionCreatedSubscription = {
  __typename?: 'RootSubscriptionType';
  onTransactionCreated?:
    | {
        __typename?: 'financialInfo';
        deal_id?: number | null | undefined;
        tt_transaction_id?: string | null | undefined;
      }
    | null
    | undefined;
};

export type FinancialInfoAcquisitionUpsertMutationVariables = Exact<{
  financialInfoAcquisition?: InputMaybe<FinancialInfoAcquisitionInput>;
  id: Scalars['ID'];
  state: Scalars['String'];
  car_id?: InputMaybe<Scalars['ID']>;
  mmr?: InputMaybe<Scalars['Float']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']>;
  override?: InputMaybe<Scalars['Boolean']>;
  clear_override?: InputMaybe<Scalars['Boolean']>;
}>;

export type FinancialInfoAcquisitionUpsertMutation = {
  __typename?: 'RootMutationType';
  financialInfoAcquisitionUpsert?:
    | { __typename?: 'financialInfoAcquisition'; id?: number | null | undefined }
    | null
    | undefined;
};

export type GetFollowUpDealsPaginatedQueryVariables = Exact<{
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']>;
  scheduledByMeFilter?: InputMaybe<Scalars['Boolean']>;
  boot_requested?: InputMaybe<Scalars['Boolean']>;
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']>;
  notifications?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pod_id?: InputMaybe<Scalars['ID']>;
  sort_column: Scalars['String'];
  sort_direction: Scalars['String'];
  sources: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  types: Array<InputMaybe<DealType>> | InputMaybe<DealType>;
  results_per_page?: InputMaybe<Scalars['Int']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetFollowUpDealsPaginatedQuery = {
  __typename?: 'RootQueryType';
  getFollowUpDealsPaginated?:
    | {
        __typename?: 'PaginatedDeals';
        totalRecords?: number | null | undefined;
        deals?:
          | Array<
              | {
                  __typename?: 'deal';
                  id?: number | null | undefined;
                  state?: string | null | undefined;
                  customer?:
                    | {
                        __typename?: 'customer';
                        id?: number | null | undefined;
                        first_name?: string | null | undefined;
                        last_name?: string | null | undefined;
                        phone_number?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  follow_up?:
                    | {
                        __typename?: 'followUp';
                        id?: number | null | undefined;
                        creator_id?: number | null | undefined;
                        deal_id?: number | null | undefined;
                        date_utc?: any | null | undefined;
                        note?: string | null | undefined;
                        status?: FollowUpStatusEnum | null | undefined;
                        tz?: string | null | undefined;
                        type?: FollowUpTypeEnum | null | undefined;
                        creator?:
                          | {
                              __typename?: 'user';
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  notifications?:
                    | Array<
                        | {
                            __typename?: 'notification';
                            id?: number | null | undefined;
                            message?: string | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type FollowUpQueryVariables = Exact<{
  deal_id: Scalars['ID'];
}>;

export type FollowUpQuery = {
  __typename?: 'RootQueryType';
  followUp?:
    | {
        __typename?: 'followUp';
        id?: number | null | undefined;
        creator_id?: number | null | undefined;
        deal_id?: number | null | undefined;
        date_utc?: any | null | undefined;
        note?: string | null | undefined;
        status?: FollowUpStatusEnum | null | undefined;
        tz?: string | null | undefined;
        type?: FollowUpTypeEnum | null | undefined;
      }
    | null
    | undefined;
};

export type FollowUpInsertMutationVariables = Exact<{
  deal_id: Scalars['ID'];
  type: FollowUpTypeEnum;
  date_utc: Scalars['DateTime'];
  tz: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
}>;

export type FollowUpInsertMutation = {
  __typename?: 'RootMutationType';
  followUpInsert?:
    | {
        __typename?: 'followUp';
        id?: number | null | undefined;
        creator_id?: number | null | undefined;
        deal_id?: number | null | undefined;
        date_utc?: any | null | undefined;
        note?: string | null | undefined;
        status?: FollowUpStatusEnum | null | undefined;
        tz?: string | null | undefined;
        type?: FollowUpTypeEnum | null | undefined;
      }
    | null
    | undefined;
};

export type FollowUpUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  type: FollowUpTypeEnum;
  date_utc: Scalars['DateTime'];
  tz: Scalars['String'];
  deal_id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
}>;

export type FollowUpUpdateMutation = {
  __typename?: 'RootMutationType';
  followUpUpdate?:
    | {
        __typename?: 'followUp';
        id?: number | null | undefined;
        creator_id?: number | null | undefined;
        deal_id?: number | null | undefined;
        date_utc?: any | null | undefined;
        note?: string | null | undefined;
        status?: FollowUpStatusEnum | null | undefined;
        tz?: string | null | undefined;
        type?: FollowUpTypeEnum | null | undefined;
      }
    | null
    | undefined;
};

export type FollowUpDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FollowUpDeleteMutation = {
  __typename?: 'RootMutationType';
  followUpDelete?: { __typename?: 'followUp'; id?: number | null | undefined } | null | undefined;
};

export type OnFollowUpUpdateSubscriptionVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']>;
  sources?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  types?: InputMaybe<Array<InputMaybe<DealType>> | InputMaybe<DealType>>;
  scheduledByMeFilter?: InputMaybe<Scalars['Boolean']>;
  followUpType?: InputMaybe<FollowUpTypeEnum>;
  followUpStatus?: InputMaybe<FollowUpStatusEnum>;
}>;

export type OnFollowUpUpdateSubscription = {
  __typename?: 'RootSubscriptionType';
  onFollowUpUpdate?:
    | Array<
        | {
            __typename?: 'deal';
            id?: number | null | undefined;
            state?: string | null | undefined;
            customer?:
              | {
                  __typename?: 'customer';
                  id?: number | null | undefined;
                  first_name?: string | null | undefined;
                  last_name?: string | null | undefined;
                }
              | null
              | undefined;
            follow_up?:
              | {
                  __typename?: 'followUp';
                  id?: number | null | undefined;
                  creator_id?: number | null | undefined;
                  deal_id?: number | null | undefined;
                  date_utc?: any | null | undefined;
                  note?: string | null | undefined;
                  status?: FollowUpStatusEnum | null | undefined;
                  tz?: string | null | undefined;
                  type?: FollowUpTypeEnum | null | undefined;
                  creator?:
                    | {
                        __typename?: 'user';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type InProgressCallsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type InProgressCallsSubscription = {
  __typename?: 'RootSubscriptionType';
  inProgressCalls?:
    | Array<
        | {
            __typename?: 'conference';
            from?: string | null | undefined;
            to?: string | null | undefined;
            conference_id?: string | null | undefined;
            phone_number?: string | null | undefined;
            agent_number?: string | null | undefined;
            deal_id?: number | null | undefined;
            customer_name?: string | null | undefined;
            agent_user_id?: string | null | undefined;
            deal_state?: string | null | undefined;
            deal_type?: string | null | undefined;
            is_thunder?: boolean | null | undefined;
            hunt_group_slug?: string | null | undefined;
            is_outbound?: boolean | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type JdpValuesQueryVariables = Exact<{
  vin: Scalars['String'];
  mileage: Scalars['Float'];
  stateCode?: InputMaybe<Scalars['String']>;
}>;

export type JdpValuesQuery = {
  __typename?: 'RootQueryType';
  jdpValues?:
    | {
        __typename?: 'car';
        jdp_mileage_adjustment?: number | null | undefined;
        jdp_adjusted_clean_retail?: number | null | undefined;
        jdp_adjusted_clean_trade?: number | null | undefined;
        jdp_trim_body?: string | null | undefined;
        jdp_valuation_date?: any | null | undefined;
        jdp_vehicle_accessories_object?:
          | {
              __typename?: 'jdpVehicleAccessoriesObject';
              vehicle_accessories?:
                | Array<
                    | {
                        __typename?: 'jdpVehicleAccessories';
                        acccode?: string | null | undefined;
                        accdesc?: string | null | undefined;
                        accessorycategory?: string | null | undefined;
                        excludes?: string | null | undefined;
                        includes?: string | null | undefined;
                        isadded?: number | null | undefined;
                        isincluded?: number | null | undefined;
                        loan?: number | null | undefined;
                        retail?: number | null | undefined;
                        tradein?: number | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateJdpBookSheetMutationVariables = Exact<{
  dealId: Scalars['ID'];
  customerState: Scalars['String'];
}>;

export type CreateJdpBookSheetMutation = {
  __typename?: 'RootMutationType';
  createJDPBookSheet?: boolean | null | undefined;
};

export type KbbVinQueryVariables = Exact<{
  vin: Scalars['String'];
}>;

export type KbbVinQuery = {
  __typename?: 'RootQueryType';
  kbbVin?:
    | {
        __typename?: 'kbbVinObjectResult';
        warning?: boolean | null | undefined;
        vinResults?:
          | Array<
              | {
                  __typename?: 'kbbVinResult';
                  vehicleId?: number | null | undefined;
                  modelName?: string | null | undefined;
                  trimName?: string | null | undefined;
                  makeName?: string | null | undefined;
                  yearId?: string | null | undefined;
                  vehicleName?: string | null | undefined;
                  vehicleOptions?:
                    | Array<
                        | {
                            __typename?: 'kbbOption';
                            categoryGroup?: string | null | undefined;
                            hasRelationships?: boolean | null | undefined;
                            isConfigurable?: boolean | null | undefined;
                            isConsumer?: boolean | null | undefined;
                            isTypical?: boolean | null | undefined;
                            isVinDecoded?: boolean | null | undefined;
                            optionName?: string | null | undefined;
                            optionType?: string | null | undefined;
                            sortOrder?: number | null | undefined;
                            vehicleId?: number | null | undefined;
                            vehicleOptionId?: number | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type KbbValuesQueryVariables = Exact<{
  data: KbbValuesDataType;
}>;

export type KbbValuesQuery = {
  __typename?: 'RootQueryType';
  kbbValues?:
    | {
        __typename?: 'kbbValuesResult';
        retail?: number | null | undefined;
        retailMileageAdjustment?: number | null | undefined;
        retailOptionAdjustment?: number | null | undefined;
        retailOptionPrices?: any | null | undefined;
        lending?: number | null | undefined;
        lendingMileageAdjustment?: number | null | undefined;
        lendingOptionAdjustment?: number | null | undefined;
        lendingOptionPrices?: any | null | undefined;
        valuationDate?: string | null | undefined;
        kbbSelectedOptions?:
          | Array<
              | {
                  __typename?: 'kbbSelectedOptionsType';
                  id?: number | null | undefined;
                  name?: string | null | undefined;
                  type?: string | null | undefined;
                  removed?: boolean | null | undefined;
                  lendingOptionPrice?: number | null | undefined;
                  retailOptionPrice?: number | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CalculateOptionsQueryVariables = Exact<{
  vehicleId: Scalars['Int'];
  allKbbVehicleOptions?: InputMaybe<Array<InputMaybe<KbbOptionInput>> | InputMaybe<KbbOptionInput>>;
  startingVehicleOptions?: InputMaybe<
    Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>
  >;
  configToModify?: InputMaybe<Array<InputMaybe<KbbConfigType>> | InputMaybe<KbbConfigType>>;
  vin: Scalars['String'];
  color?: InputMaybe<Scalars['String']>;
}>;

export type CalculateOptionsQuery = {
  __typename?: 'RootQueryType';
  calculateOptions?:
    | Array<
        | {
            __typename?: 'kbbOption';
            vehicleOptionId?: number | null | undefined;
            optionName?: string | null | undefined;
            optionType?: string | null | undefined;
            categoryGroup?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CreateKbbBookSheetMutationVariables = Exact<{
  dealId: Scalars['ID'];
}>;

export type CreateKbbBookSheetMutation = {
  __typename?: 'RootMutationType';
  createKBBBookSheet?: boolean | null | undefined;
};

export type KeyMetricsQueryVariables = Exact<{
  start_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  pod_ids: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type KeyMetricsQuery = {
  __typename?: 'RootQueryType';
  keyMetrics?:
    | Array<
        | {
            __typename?: 'keyMetric';
            keyMetric?: string | null | undefined;
            value?:
              | {
                  __typename?: 'value';
                  buyout?: number | null | undefined;
                  acquisition?: number | null | undefined;
                  total?: number | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type LaneKeyMetricsQueryVariables = Exact<{
  today?: InputMaybe<Scalars['DateTime']>;
  sources: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  types: Array<InputMaybe<DealType>> | InputMaybe<DealType>;
}>;

export type LaneKeyMetricsQuery = {
  __typename?: 'RootQueryType';
  laneKeyMetrics?:
    | Array<
        | {
            __typename?: 'laneKeyMetric';
            state?: string | null | undefined;
            day_count?: number | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type LenderFragmentFragment = {
  __typename?: 'Lender';
  id?: string | null | undefined;
  name?: string | null | undefined;
};

export type LendersQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type LendersQuery = {
  __typename?: 'RootQueryType';
  lenders?:
    | Array<
        | {
            __typename?: 'Lender';
            id?: string | null | undefined;
            name?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type MediaQueryVariables = Exact<{
  dealId: Scalars['ID'];
}>;

export type MediaQuery = {
  __typename?: 'RootQueryType';
  media?:
    | Array<
        | {
            __typename?: 'dealMedia';
            id?: number | null | undefined;
            key?: string | null | undefined;
            signed_url?: string | null | undefined;
            type?: DealMediaTypeEnum | null | undefined;
            metadata?: any | null | undefined;
            deal_id?: number | null | undefined;
            verified?: boolean | null | undefined;
            state?: DealMediaStateEnum | null | undefined;
            source?: DealMediaSourceEnum | null | undefined;
            has_verified_digital_signature?: boolean | null | undefined;
            has_wet_signature?: boolean | null | undefined;
            is_notarized?: boolean | null | undefined;
            created_at?: any | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetUploadUrlQueryVariables = Exact<{
  dealId: Scalars['ID'];
  fileName: Scalars['String'];
}>;

export type GetUploadUrlQuery = {
  __typename?: 'RootQueryType';
  getUploadUrl?:
    | {
        __typename?: 'mediaUploadUrl';
        key?: string | null | undefined;
        url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ArticleImageUploadUrlQueryVariables = Exact<{ [key: string]: never }>;

export type ArticleImageUploadUrlQuery = {
  __typename?: 'RootQueryType';
  articleImageUploadUrl?:
    | {
        __typename?: 'articleImageUploadUrl';
        url?: string | null | undefined;
        key?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetBase64StringFromS3ObjectQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type GetBase64StringFromS3ObjectQuery = {
  __typename?: 'RootQueryType';
  getBase64StringFromS3Object?: string | null | undefined;
};

export type MediaInsertMutationVariables = Exact<{
  key: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  dealId: Scalars['ID'];
  metadata?: InputMaybe<Scalars['JSONObject']>;
}>;

export type MediaInsertMutation = {
  __typename?: 'RootMutationType';
  mediaInsert?:
    | {
        __typename?: 'dealMedia';
        id?: number | null | undefined;
        key?: string | null | undefined;
        signed_url?: string | null | undefined;
        type?: DealMediaTypeEnum | null | undefined;
        metadata?: any | null | undefined;
        deal_id?: number | null | undefined;
        has_verified_digital_signature?: boolean | null | undefined;
        has_wet_signature?: boolean | null | undefined;
        is_notarized?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type MediaDeleteMutationVariables = Exact<{
  filePath: Scalars['String'];
}>;

export type MediaDeleteMutation = {
  __typename?: 'RootMutationType';
  mediaDelete?: boolean | null | undefined;
};

export type DeleteAndReplaceFilesMutationVariables = Exact<{
  deal_id: Scalars['ID'];
  files_to_delete:
    | Array<InputMaybe<AddDocumentsToMediaCenterInput>>
    | InputMaybe<AddDocumentsToMediaCenterInput>;
  files_to_upload:
    | Array<InputMaybe<AddDocumentsToMediaCenterInput>>
    | InputMaybe<AddDocumentsToMediaCenterInput>;
}>;

export type DeleteAndReplaceFilesMutation = {
  __typename?: 'RootMutationType';
  deleteAndReplaceFiles?: boolean | null | undefined;
};

export type MediaRenameMutationVariables = Exact<{
  dealId: Scalars['ID'];
  filePath: Scalars['String'];
  fileName: Scalars['String'];
}>;

export type MediaRenameMutation = {
  __typename?: 'RootMutationType';
  mediaRename?: boolean | null | undefined;
};

export type MediaUpdateMutationVariables = Exact<{
  dealId: Scalars['ID'];
  oldKey: Scalars['String'];
  newFileName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  has_verified_digital_signature?: InputMaybe<Scalars['Boolean']>;
  has_wet_signature?: InputMaybe<Scalars['Boolean']>;
  is_notarized?: InputMaybe<Scalars['Boolean']>;
}>;

export type MediaUpdateMutation = {
  __typename?: 'RootMutationType';
  mediaUpdate?:
    | {
        __typename?: 'dealMedia';
        id?: number | null | undefined;
        key?: string | null | undefined;
        signed_url?: string | null | undefined;
        type?: DealMediaTypeEnum | null | undefined;
        metadata?: any | null | undefined;
        deal_id?: number | null | undefined;
        verified?: boolean | null | undefined;
        has_verified_digital_signature?: boolean | null | undefined;
        has_wet_signature?: boolean | null | undefined;
        is_notarized?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type MessagesQueryVariables = Exact<{
  dealPhoneNumber?: InputMaybe<Scalars['String']>;
}>;

export type MessagesQuery = {
  __typename?: 'RootQueryType';
  messages?:
    | {
        __typename?: 'messagesWithErrors';
        hasErrors?: boolean | null | undefined;
        messages?:
          | Array<
              | {
                  __typename?: 'message';
                  id?: number | null | undefined;
                  fromCustomer?: boolean | null | undefined;
                  body?: string | null | undefined;
                  senderName?: string | null | undefined;
                  dateCreated?: any | null | undefined;
                  mediaListObj?:
                    | {
                        __typename?: 'mediaListObj';
                        data?:
                          | Array<
                              | {
                                  __typename?: 'mediaListData';
                                  url?: string | null | undefined;
                                  contentType?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MessagesDbPaginatedQueryVariables = Exact<{
  dealPhoneNumber: Scalars['String'];
  dealId: Scalars['String'];
  page: Scalars['Int'];
}>;

export type MessagesDbPaginatedQuery = {
  __typename?: 'RootQueryType';
  messagesDbPaginated?:
    | {
        __typename?: 'messagesDbPaginated';
        hasMoreMessages: boolean;
        messages: Array<
          | {
              __typename?: 'message';
              id?: number | null | undefined;
              fromCustomer?: boolean | null | undefined;
              body?: string | null | undefined;
              senderName?: string | null | undefined;
              dateCreated?: any | null | undefined;
              mediaListObj?:
                | {
                    __typename?: 'mediaListObj';
                    data?:
                      | Array<
                          | {
                              __typename?: 'mediaListData';
                              url?: string | null | undefined;
                              contentType?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type MessageSendMutationVariables = Exact<{
  message?: InputMaybe<Scalars['String']>;
  dealPhoneNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
}>;

export type MessageSendMutation = {
  __typename?: 'RootMutationType';
  messageSend?:
    | {
        __typename?: 'message';
        fromCustomer?: boolean | null | undefined;
        body?: string | null | undefined;
        senderName?: string | null | undefined;
        dateCreated?: any | null | undefined;
        mediaListObj?:
          | {
              __typename?: 'mediaListObj';
              data?:
                | Array<
                    | {
                        __typename?: 'mediaListData';
                        url?: string | null | undefined;
                        contentType?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type FileSendMutationVariables = Exact<{
  file?: InputMaybe<Scalars['String']>;
  dealPhoneNumber?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}>;

export type FileSendMutation = {
  __typename?: 'RootMutationType';
  fileSend?:
    | {
        __typename?: 'message';
        fromCustomer?: boolean | null | undefined;
        body?: string | null | undefined;
        mediaListObj?:
          | {
              __typename?: 'mediaListObj';
              data?:
                | Array<
                    | {
                        __typename?: 'mediaListData';
                        url?: string | null | undefined;
                        contentType?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type OnNewMessageSubscriptionVariables = Exact<{
  dealPhoneNumbers?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type OnNewMessageSubscription = {
  __typename?: 'RootSubscriptionType';
  onNewMessage?:
    | {
        __typename?: 'message';
        fromCustomer?: boolean | null | undefined;
        body?: string | null | undefined;
        dateCreated?: any | null | undefined;
        from?: string | null | undefined;
        mediaListObj?:
          | {
              __typename?: 'mediaListObj';
              data?:
                | Array<
                    | {
                        __typename?: 'mediaListData';
                        url?: string | null | undefined;
                        contentType?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        deals?:
          | Array<
              | {
                  __typename?: 'deal';
                  id?: number | null | undefined;
                  state?: string | null | undefined;
                  pod_id?: number | null | undefined;
                  titling_pod_id?: number | null | undefined;
                  setter_id?: number | null | undefined;
                  closer_id?: number | null | undefined;
                  closer2_id?: number | null | undefined;
                  funding_clerk_id?: number | null | undefined;
                  title_clerk_id?: number | null | undefined;
                  title_clerk2_id?: number | null | undefined;
                  customer?:
                    | {
                        __typename?: 'customer';
                        first_name?: string | null | undefined;
                        last_name?: string | null | undefined;
                        phone_number?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  cobuyer?:
                    | {
                        __typename?: 'customer';
                        first_name?: string | null | undefined;
                        last_name?: string | null | undefined;
                        phone_number?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type OnReceivedMessageSubscriptionVariables = Exact<{
  dealPhoneNumbers?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  isNotification?: InputMaybe<Scalars['Boolean']>;
  isWidgetOpen?: InputMaybe<Scalars['Boolean']>;
  activePhoneNumber?: InputMaybe<Scalars['String']>;
}>;

export type OnReceivedMessageSubscription = {
  __typename?: 'RootSubscriptionType';
  onReceivedMessage?:
    | {
        __typename?: 'messageData';
        message?:
          | {
              __typename?: 'message';
              id?: number | null | undefined;
              fromCustomer?: boolean | null | undefined;
              body?: string | null | undefined;
              dateCreated?: any | null | undefined;
              from?: string | null | undefined;
              mediaListObj?:
                | {
                    __typename?: 'mediaListObj';
                    data?:
                      | Array<
                          | {
                              __typename?: 'mediaListData';
                              url?: string | null | undefined;
                              contentType?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        deals?:
          | Array<
              | {
                  __typename?: 'deal';
                  id?: number | null | undefined;
                  state?: string | null | undefined;
                  notification_subscribers?:
                    | Array<
                        | {
                            __typename?: 'NotificationSubscriber';
                            id?: number | null | undefined;
                            deal_id?: number | null | undefined;
                            subscriber_id?: string | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                  car?:
                    | {
                        __typename?: 'car';
                        id?: number | null | undefined;
                        make?: string | null | undefined;
                        model?: string | null | undefined;
                        year?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  customer?:
                    | {
                        __typename?: 'customer';
                        first_name?: string | null | undefined;
                        last_name?: string | null | undefined;
                        phone_number?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  cobuyer?:
                    | {
                        __typename?: 'customer';
                        first_name?: string | null | undefined;
                        last_name?: string | null | undefined;
                        phone_number?: string | null | undefined;
                        relation_to_buyer?: RelationToBuyerEnum | null | undefined;
                      }
                    | null
                    | undefined;
                  contact?:
                    | {
                        __typename?: 'dealContact';
                        first_name?: string | null | undefined;
                        last_name?: string | null | undefined;
                        phone_number?: string | null | undefined;
                        relation_to_buyer?: RelationToBuyerEnum | null | undefined;
                      }
                    | null
                    | undefined;
                  second_contact?:
                    | {
                        __typename?: 'dealContact';
                        first_name?: string | null | undefined;
                        last_name?: string | null | undefined;
                        phone_number?: string | null | undefined;
                        relation_to_buyer?: RelationToBuyerEnum | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        messageDetail?:
          | {
              __typename?: 'messageDetail';
              shortNames?: Array<string | null | undefined> | null | undefined;
              dealIds?: Array<number | null | undefined> | null | undefined;
              dealStates?: Array<string | null | undefined> | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TemplateTextsQueryVariables = Exact<{ [key: string]: never }>;

export type TemplateTextsQuery = {
  __typename?: 'RootQueryType';
  templateTexts?:
    | Array<
        | {
            __typename?: 'TemplateText';
            id?: number | null | undefined;
            description?: string | null | undefined;
            text?: string | null | undefined;
            key?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type InboxQueryVariables = Exact<{
  filterBy?: InputMaybe<InboxFilterByEnum>;
  podIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  orderBy?: InputMaybe<InboxOrderByEnum>;
  unreadOnly?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
}>;

export type InboxQuery = {
  __typename?: 'RootQueryType';
  inbox?:
    | {
        __typename?: 'inboxMessagesType';
        totalRecords?: number | null | undefined;
        messages?:
          | Array<
              | {
                  __typename?: 'message';
                  id?: number | null | undefined;
                  from?: string | null | undefined;
                  fromCustomer?: boolean | null | undefined;
                  body?: string | null | undefined;
                  dateCreated?: any | null | undefined;
                  dateCreatedTz?: string | null | undefined;
                  mediaListObj?:
                    | {
                        __typename?: 'mediaListObj';
                        data?:
                          | Array<
                              | {
                                  __typename?: 'mediaListData';
                                  url?: string | null | undefined;
                                  contentType?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  notifications?:
                    | Array<
                        | {
                            __typename?: 'notification';
                            id?: number | null | undefined;
                            seen?: boolean | null | undefined;
                            deal?:
                              | {
                                  __typename?: 'deal';
                                  id?: number | null | undefined;
                                  state?: string | null | undefined;
                                  car?:
                                    | {
                                        __typename?: 'car';
                                        id?: number | null | undefined;
                                        year?: string | null | undefined;
                                        make?: string | null | undefined;
                                        model?: string | null | undefined;
                                      }
                                    | null
                                    | undefined;
                                  customer?:
                                    | {
                                        __typename?: 'customer';
                                        id?: number | null | undefined;
                                        first_name?: string | null | undefined;
                                        last_name?: string | null | undefined;
                                        phone_number?: string | null | undefined;
                                      }
                                    | null
                                    | undefined;
                                  cobuyer?:
                                    | {
                                        __typename?: 'customer';
                                        id?: number | null | undefined;
                                        first_name?: string | null | undefined;
                                        last_name?: string | null | undefined;
                                        phone_number?: string | null | undefined;
                                      }
                                    | null
                                    | undefined;
                                  contact?:
                                    | {
                                        __typename?: 'dealContact';
                                        id?: number | null | undefined;
                                        first_name?: string | null | undefined;
                                        last_name?: string | null | undefined;
                                        phone_number?: string | null | undefined;
                                      }
                                    | null
                                    | undefined;
                                  second_contact?:
                                    | {
                                        __typename?: 'dealContact';
                                        id?: number | null | undefined;
                                        first_name?: string | null | undefined;
                                        last_name?: string | null | undefined;
                                        phone_number?: string | null | undefined;
                                      }
                                    | null
                                    | undefined;
                                  setter?:
                                    | {
                                        __typename?: 'user';
                                        id?: string | null | undefined;
                                        name?: string | null | undefined;
                                        pods?:
                                          | Array<
                                              | {
                                                  __typename?: 'pod';
                                                  name?: string | null | undefined;
                                                  color?: string | null | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined;
                                  closer?:
                                    | {
                                        __typename?: 'user';
                                        id?: string | null | undefined;
                                        name?: string | null | undefined;
                                        pods?:
                                          | Array<
                                              | {
                                                  __typename?: 'pod';
                                                  name?: string | null | undefined;
                                                  color?: string | null | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined;
                                  closer2?:
                                    | {
                                        __typename?: 'user';
                                        id?: string | null | undefined;
                                        name?: string | null | undefined;
                                        pods?:
                                          | Array<
                                              | {
                                                  __typename?: 'pod';
                                                  name?: string | null | undefined;
                                                  color?: string | null | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined;
                                  funding_clerk?:
                                    | {
                                        __typename?: 'user';
                                        id?: string | null | undefined;
                                        name?: string | null | undefined;
                                        pods?:
                                          | Array<
                                              | {
                                                  __typename?: 'pod';
                                                  name?: string | null | undefined;
                                                  color?: string | null | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined;
                                  title_clerk?:
                                    | {
                                        __typename?: 'user';
                                        id?: string | null | undefined;
                                        name?: string | null | undefined;
                                        titlingPods?:
                                          | Array<
                                              | {
                                                  __typename?: 'pod';
                                                  name?: string | null | undefined;
                                                  color?: string | null | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined;
                                  title_clerk2?:
                                    | {
                                        __typename?: 'user';
                                        id?: string | null | undefined;
                                        name?: string | null | undefined;
                                        titlingPods?:
                                          | Array<
                                              | {
                                                  __typename?: 'pod';
                                                  name?: string | null | undefined;
                                                  color?: string | null | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MetricsPagesQueryVariables = Exact<{ [key: string]: never }>;

export type MetricsPagesQuery = {
  __typename?: 'RootQueryType';
  metricsPages?:
    | Array<
        | {
            __typename?: 'metricsPages';
            id?: number | null | undefined;
            name?: string | null | undefined;
            link_name?: string | null | undefined;
            iframe_url?: string | null | undefined;
            permission_name?: string | null | undefined;
            sort_order?: number | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type MmrQueryVariables = Exact<{
  vin: Scalars['String'];
  mileage: Scalars['Int'];
  color: Scalars['String'];
  grade: Scalars['Float'];
  trim: Scalars['String'];
  state: Scalars['String'];
}>;

export type MmrQuery = {
  __typename?: 'RootQueryType';
  mmr?: { __typename?: 'mmrResult'; average?: number | null | undefined } | null | undefined;
};

export type NoteCreateMutationVariables = Exact<{
  note?: InputMaybe<NoteInput>;
}>;

export type NoteCreateMutation = {
  __typename?: 'RootMutationType';
  noteCreate?: { __typename?: 'note'; id?: number | null | undefined } | null | undefined;
};

export type NoteUpdateMutationVariables = Exact<{
  note?: InputMaybe<NoteInput>;
}>;

export type NoteUpdateMutation = {
  __typename?: 'RootMutationType';
  noteUpdate?: { __typename?: 'note'; id?: number | null | undefined } | null | undefined;
};

export type NoteDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type NoteDeleteMutation = {
  __typename?: 'RootMutationType';
  noteDelete?: { __typename?: 'note'; id?: number | null | undefined } | null | undefined;
};

export type NotesQueryVariables = Exact<{
  dealId: Scalars['ID'];
}>;

export type NotesQuery = {
  __typename?: 'RootQueryType';
  notes?:
    | Array<
        | {
            __typename?: 'note';
            id?: number | null | undefined;
            author_id?: string | null | undefined;
            text?: string | null | undefined;
            deal_id?: number | null | undefined;
            created_at?: any | null | undefined;
            creation_date_tz?: string | null | undefined;
            note_type?: string | null | undefined;
            pinned?: boolean | null | undefined;
            author?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type NotificationSeenMutationVariables = Exact<{
  notificationId: Scalars['ID'];
  seen: Scalars['Boolean'];
  dealId?: InputMaybe<Scalars['ID']>;
}>;

export type NotificationSeenMutation = {
  __typename?: 'RootMutationType';
  notificationSeen?: boolean | null | undefined;
};

export type NotificationsSeenMutationVariables = Exact<{
  notificationIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
  dealId: Scalars['ID'];
}>;

export type NotificationsSeenMutation = {
  __typename?: 'RootMutationType';
  notificationsSeen?: boolean | null | undefined;
};

export type OnNoDealNotificationSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnNoDealNotificationSubscription = {
  __typename?: 'RootSubscriptionType';
  onNoDealNotification?:
    | {
        __typename?: 'notification';
        id?: number | null | undefined;
        seen?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateSubscriptionToNotificationsStatusMutationVariables = Exact<{
  dealId: Scalars['ID'];
  subscribeToNotifications: Scalars['Boolean'];
}>;

export type UpdateSubscriptionToNotificationsStatusMutation = {
  __typename?: 'RootMutationType';
  updateSubscriptionToNotificationsStatus?:
    | {
        __typename?: 'NotificationSubscriber';
        id?: number | null | undefined;
        deal_id?: number | null | undefined;
        subscriber_id?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PaymentOptionFragmentFragment = {
  __typename?: 'paymentOptionsGQLTypes';
  name?: string | null | undefined;
  amountFinanced?: number | null | undefined;
  buyPayment?: number | null | undefined;
  sellPayment?: number | null | undefined;
  profit?: number | null | undefined;
  setterCommission?: number | null | undefined;
  closerCommission?: number | null | undefined;
  reserve?: number | null | undefined;
};

export type PaymentOptionsQueryVariables = Exact<{
  data?: InputMaybe<PaymentOptionsInput>;
}>;

export type PaymentOptionsQuery = {
  __typename?: 'RootQueryType';
  paymentOptions?:
    | {
        __typename?: 'paymentOptions';
        vscPlusGap?:
          | {
              __typename?: 'paymentOptionsGQLTypes';
              name?: string | null | undefined;
              amountFinanced?: number | null | undefined;
              buyPayment?: number | null | undefined;
              sellPayment?: number | null | undefined;
              profit?: number | null | undefined;
              setterCommission?: number | null | undefined;
              closerCommission?: number | null | undefined;
              reserve?: number | null | undefined;
            }
          | null
          | undefined;
        vsc?:
          | {
              __typename?: 'paymentOptionsGQLTypes';
              name?: string | null | undefined;
              amountFinanced?: number | null | undefined;
              buyPayment?: number | null | undefined;
              sellPayment?: number | null | undefined;
              profit?: number | null | undefined;
              setterCommission?: number | null | undefined;
              closerCommission?: number | null | undefined;
              reserve?: number | null | undefined;
            }
          | null
          | undefined;
        gap?:
          | {
              __typename?: 'paymentOptionsGQLTypes';
              name?: string | null | undefined;
              amountFinanced?: number | null | undefined;
              buyPayment?: number | null | undefined;
              sellPayment?: number | null | undefined;
              profit?: number | null | undefined;
              setterCommission?: number | null | undefined;
              closerCommission?: number | null | undefined;
              reserve?: number | null | undefined;
            }
          | null
          | undefined;
        noProducts?:
          | {
              __typename?: 'paymentOptionsGQLTypes';
              name?: string | null | undefined;
              amountFinanced?: number | null | undefined;
              buyPayment?: number | null | undefined;
              sellPayment?: number | null | undefined;
              profit?: number | null | undefined;
              setterCommission?: number | null | undefined;
              closerCommission?: number | null | undefined;
              reserve?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PaymentEstimateQueryVariables = Exact<{
  data?: InputMaybe<PaymentEstimateInput>;
}>;

export type PaymentEstimateQuery = {
  __typename?: 'RootQueryType';
  paymentEstimate?:
    | {
        __typename?: 'paymentEstimate';
        paymentEstimateLow: number;
        paymentEstimateHigh: number;
        estimatedTaxesAndFees: number;
        totalEstimatedLoan: number;
        estimatedAnnualPercentageRate: number;
      }
    | null
    | undefined;
};

export type PayoffFragmentFragment = {
  __typename?: 'payoff';
  id?: number | null | undefined;
  lienholder_name?: string | null | undefined;
  lienholder_slug?: string | null | undefined;
  lender_name?: string | null | undefined;
  account_number?: string | null | undefined;
  vehicle_payoff?: number | null | undefined;
  sales_tax_from_payoff?: number | null | undefined;
  good_through_date?: any | null | undefined;
  lease_term?: number | null | undefined;
  remaining_payments?: number | null | undefined;
  msrp?: number | null | undefined;
  residual_percentage?: number | null | undefined;
  sales_price?: number | null | undefined;
  cap_reduction?: number | null | undefined;
  money_down?: number | null | undefined;
  money_factor?: number | null | undefined;
  termination_fees?: number | null | undefined;
  cap_cost?: number | null | undefined;
  residual_amount?: number | null | undefined;
  estimated_payoff?: number | null | undefined;
  old_lease_payment?: number | null | undefined;
  payoff_includes_sales_tax?: boolean | null | undefined;
  sales_tax_from_payoff_entered_manually?: boolean | null | undefined;
  verification_status?: PayoffVerificationStatus | null | undefined;
  next_payment_date?: any | null | undefined;
  user_entered_total_payoff?: number | null | undefined;
  maturity_date?: any | null | undefined;
};

export type PayoffUpdateMutationVariables = Exact<{
  payoff?: InputMaybe<PayoffInput>;
}>;

export type PayoffUpdateMutation = {
  __typename?: 'RootMutationType';
  payoffUpdate?:
    | {
        __typename?: 'payoff';
        id?: number | null | undefined;
        lienholder_name?: string | null | undefined;
        lienholder_slug?: string | null | undefined;
        lender_name?: string | null | undefined;
        account_number?: string | null | undefined;
        vehicle_payoff?: number | null | undefined;
        sales_tax_from_payoff?: number | null | undefined;
        good_through_date?: any | null | undefined;
        lease_term?: number | null | undefined;
        remaining_payments?: number | null | undefined;
        msrp?: number | null | undefined;
        residual_percentage?: number | null | undefined;
        sales_price?: number | null | undefined;
        cap_reduction?: number | null | undefined;
        money_down?: number | null | undefined;
        money_factor?: number | null | undefined;
        termination_fees?: number | null | undefined;
        cap_cost?: number | null | undefined;
        residual_amount?: number | null | undefined;
        estimated_payoff?: number | null | undefined;
        old_lease_payment?: number | null | undefined;
        payoff_includes_sales_tax?: boolean | null | undefined;
        sales_tax_from_payoff_entered_manually?: boolean | null | undefined;
        verification_status?: PayoffVerificationStatus | null | undefined;
        next_payment_date?: any | null | undefined;
        user_entered_total_payoff?: number | null | undefined;
        maturity_date?: any | null | undefined;
      }
    | null
    | undefined;
};

export type PayoffRequestPopulatedFragmentFragment = {
  __typename?: 'payoffRequestPopulated';
  id?: number | null | undefined;
  created_at?: any | null | undefined;
  deal_id?: number | null | undefined;
  temporary_info_id?: number | null | undefined;
  status?: PayoffRequestStatus | null | undefined;
  vehicle_payoff?: number | null | undefined;
  sales_tax?: number | null | undefined;
  completed_date?: any | null | undefined;
  good_through_date?: any | null | undefined;
  fail_reason?: any | null | undefined;
  payoff_clerk_id?: string | null | undefined;
  needs_payoff_documents?: boolean | null | undefined;
  payoff_documents_uploaded?: boolean | null | undefined;
  deal?:
    | {
        __typename?: 'deal';
        id?: number | null | undefined;
        closer_id?: number | null | undefined;
        created_at?: any | null | undefined;
        closer?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
            }
          | null
          | undefined;
        car?:
          | {
              __typename?: 'car';
              vehicle_type?: string | null | undefined;
              fuel_type?: string | null | undefined;
              year?: string | null | undefined;
              make?: string | null | undefined;
              model?: string | null | undefined;
              vin?: string | null | undefined;
              mileage?: number | null | undefined;
              payoff?:
                | {
                    __typename?: 'payoff';
                    account_number?: string | null | undefined;
                    lienholder_name?: string | null | undefined;
                    lienholder_slug?: string | null | undefined;
                    lender_name?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        customer?:
          | {
              __typename?: 'customer';
              id?: number | null | undefined;
              first_name?: string | null | undefined;
              last_name?: string | null | undefined;
              ssn?: string | null | undefined;
              dob?: any | null | undefined;
              phone_number?: string | null | undefined;
              home_phone_number?: string | null | undefined;
              address?:
                | {
                    __typename?: 'address';
                    city?: string | null | undefined;
                    county?: string | null | undefined;
                    zip?: string | null | undefined;
                    state?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        financial_info?:
          | { __typename?: 'financialInfo'; buyer_not_lessee?: boolean | null | undefined }
          | null
          | undefined;
        notifications?:
          | Array<
              | {
                  __typename?: 'notification';
                  id?: number | null | undefined;
                  message?: string | null | undefined;
                  created_at?: any | null | undefined;
                  seen?: boolean | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  temporary_info?:
    | {
        __typename?: 'temporaryInfo';
        id?: number | null | undefined;
        data: {
          __typename?: 'temporaryData';
          state?: string | null | undefined;
          city?: string | null | undefined;
          county?: string | null | undefined;
          year?: string | null | undefined;
          make?: string | null | undefined;
          model?: string | null | undefined;
          vin?: string | null | undefined;
          mileage?: number | null | undefined;
          lh_account_number?: string | null | undefined;
          lienholder?: string | null | undefined;
          first_name?: string | null | undefined;
          last_name?: string | null | undefined;
          dob?: any | null | undefined;
          phone_number?: string | null | undefined;
          zip?: string | null | undefined;
          ssn?: string | null | undefined;
          vehicle_type?: string | null | undefined;
          fuel_type?: string | null | undefined;
        };
      }
    | null
    | undefined;
  payoff_clerk?:
    | { __typename?: 'user'; id?: string | null | undefined; name?: string | null | undefined }
    | null
    | undefined;
};

export type PayoffDashboardDealsQueryVariables = Exact<{ [key: string]: never }>;

export type PayoffDashboardDealsQuery = {
  __typename?: 'RootQueryType';
  payoffDashboardDeals?:
    | {
        __typename?: 'payoffDashboard';
        payoffRequests?:
          | Array<
              | {
                  __typename?: 'payoffRequestPopulated';
                  id?: number | null | undefined;
                  created_at?: any | null | undefined;
                  deal_id?: number | null | undefined;
                  temporary_info_id?: number | null | undefined;
                  status?: PayoffRequestStatus | null | undefined;
                  vehicle_payoff?: number | null | undefined;
                  sales_tax?: number | null | undefined;
                  completed_date?: any | null | undefined;
                  good_through_date?: any | null | undefined;
                  fail_reason?: any | null | undefined;
                  payoff_clerk_id?: string | null | undefined;
                  needs_payoff_documents?: boolean | null | undefined;
                  payoff_documents_uploaded?: boolean | null | undefined;
                  deal?:
                    | {
                        __typename?: 'deal';
                        id?: number | null | undefined;
                        closer_id?: number | null | undefined;
                        created_at?: any | null | undefined;
                        closer?:
                          | {
                              __typename?: 'user';
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        car?:
                          | {
                              __typename?: 'car';
                              vehicle_type?: string | null | undefined;
                              fuel_type?: string | null | undefined;
                              year?: string | null | undefined;
                              make?: string | null | undefined;
                              model?: string | null | undefined;
                              vin?: string | null | undefined;
                              mileage?: number | null | undefined;
                              payoff?:
                                | {
                                    __typename?: 'payoff';
                                    account_number?: string | null | undefined;
                                    lienholder_name?: string | null | undefined;
                                    lienholder_slug?: string | null | undefined;
                                    lender_name?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        customer?:
                          | {
                              __typename?: 'customer';
                              id?: number | null | undefined;
                              first_name?: string | null | undefined;
                              last_name?: string | null | undefined;
                              ssn?: string | null | undefined;
                              dob?: any | null | undefined;
                              phone_number?: string | null | undefined;
                              home_phone_number?: string | null | undefined;
                              address?:
                                | {
                                    __typename?: 'address';
                                    city?: string | null | undefined;
                                    county?: string | null | undefined;
                                    zip?: string | null | undefined;
                                    state?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        financial_info?:
                          | {
                              __typename?: 'financialInfo';
                              buyer_not_lessee?: boolean | null | undefined;
                            }
                          | null
                          | undefined;
                        notifications?:
                          | Array<
                              | {
                                  __typename?: 'notification';
                                  id?: number | null | undefined;
                                  message?: string | null | undefined;
                                  created_at?: any | null | undefined;
                                  seen?: boolean | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  temporary_info?:
                    | {
                        __typename?: 'temporaryInfo';
                        id?: number | null | undefined;
                        data: {
                          __typename?: 'temporaryData';
                          state?: string | null | undefined;
                          city?: string | null | undefined;
                          county?: string | null | undefined;
                          year?: string | null | undefined;
                          make?: string | null | undefined;
                          model?: string | null | undefined;
                          vin?: string | null | undefined;
                          mileage?: number | null | undefined;
                          lh_account_number?: string | null | undefined;
                          lienholder?: string | null | undefined;
                          first_name?: string | null | undefined;
                          last_name?: string | null | undefined;
                          dob?: any | null | undefined;
                          phone_number?: string | null | undefined;
                          zip?: string | null | undefined;
                          ssn?: string | null | undefined;
                          vehicle_type?: string | null | undefined;
                          fuel_type?: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                  payoff_clerk?:
                    | {
                        __typename?: 'user';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PayoffRequestConfirmMutationVariables = Exact<{
  payoffRequest: PayoffRequestConfirmInput;
}>;

export type PayoffRequestConfirmMutation = {
  __typename?: 'RootMutationType';
  payoffRequestConfirm?:
    | {
        __typename?: 'payoffRequest';
        id?: number | null | undefined;
        vehicle_payoff?: number | null | undefined;
        good_through_date?: any | null | undefined;
      }
    | null
    | undefined;
};

export type UpdatePayoffClerkMutationVariables = Exact<{
  payoff_request_id?: InputMaybe<Scalars['ID']>;
  payoff_clerk_id?: InputMaybe<Scalars['String']>;
}>;

export type UpdatePayoffClerkMutation = {
  __typename?: 'RootMutationType';
  updatePayoffClerk?: boolean | null | undefined;
};

export type PayoffRequestFailureRequestMutationVariables = Exact<{
  payoffRequest: PayoffRequestInput;
}>;

export type PayoffRequestFailureRequestMutation = {
  __typename?: 'RootMutationType';
  payoffRequestFailureRequest?:
    | {
        __typename?: 'payoffRequest';
        id?: number | null | undefined;
        fail_reason?: any | null | undefined;
      }
    | null
    | undefined;
};

export type PayoffRequestFailMutationVariables = Exact<{
  payoffRequest: PayoffRequestInput;
}>;

export type PayoffRequestFailMutation = {
  __typename?: 'RootMutationType';
  payoffRequestFail?:
    | {
        __typename?: 'payoffRequest';
        id?: number | null | undefined;
        fail_reason?: any | null | undefined;
      }
    | null
    | undefined;
};

export type CreatePayoffRequestMutationVariables = Exact<{
  deal_id: Scalars['ID'];
}>;

export type CreatePayoffRequestMutation = {
  __typename?: 'RootMutationType';
  createPayoffRequest?:
    | {
        __typename?: 'payoffRequest';
        deal_id?: number | null | undefined;
        status?: PayoffRequestStatus | null | undefined;
        vehicle_payoff?: number | null | undefined;
        sales_tax?: number | null | undefined;
        completed_date?: any | null | undefined;
        good_through_date?: any | null | undefined;
      }
    | null
    | undefined;
};

export type PayoffRequestCancelMutationVariables = Exact<{
  payoffRequestId: Scalars['ID'];
}>;

export type PayoffRequestCancelMutation = {
  __typename?: 'RootMutationType';
  payoffRequestCancel?:
    | { __typename?: 'payoffRequest'; id?: number | null | undefined }
    | null
    | undefined;
};

export type OnPayoffRequestUpdateSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnPayoffRequestUpdateSubscription = {
  __typename?: 'RootSubscriptionType';
  onPayoffRequestUpdate?:
    | {
        __typename?: 'payoffRequestPopulated';
        id?: number | null | undefined;
        created_at?: any | null | undefined;
        deal_id?: number | null | undefined;
        temporary_info_id?: number | null | undefined;
        status?: PayoffRequestStatus | null | undefined;
        vehicle_payoff?: number | null | undefined;
        sales_tax?: number | null | undefined;
        completed_date?: any | null | undefined;
        good_through_date?: any | null | undefined;
        fail_reason?: any | null | undefined;
        payoff_clerk_id?: string | null | undefined;
        needs_payoff_documents?: boolean | null | undefined;
        payoff_documents_uploaded?: boolean | null | undefined;
        deal?:
          | {
              __typename?: 'deal';
              id?: number | null | undefined;
              closer_id?: number | null | undefined;
              created_at?: any | null | undefined;
              closer?:
                | {
                    __typename?: 'user';
                    id?: string | null | undefined;
                    name?: string | null | undefined;
                  }
                | null
                | undefined;
              car?:
                | {
                    __typename?: 'car';
                    vehicle_type?: string | null | undefined;
                    fuel_type?: string | null | undefined;
                    year?: string | null | undefined;
                    make?: string | null | undefined;
                    model?: string | null | undefined;
                    vin?: string | null | undefined;
                    mileage?: number | null | undefined;
                    payoff?:
                      | {
                          __typename?: 'payoff';
                          account_number?: string | null | undefined;
                          lienholder_name?: string | null | undefined;
                          lienholder_slug?: string | null | undefined;
                          lender_name?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              customer?:
                | {
                    __typename?: 'customer';
                    id?: number | null | undefined;
                    first_name?: string | null | undefined;
                    last_name?: string | null | undefined;
                    ssn?: string | null | undefined;
                    dob?: any | null | undefined;
                    phone_number?: string | null | undefined;
                    home_phone_number?: string | null | undefined;
                    address?:
                      | {
                          __typename?: 'address';
                          city?: string | null | undefined;
                          county?: string | null | undefined;
                          zip?: string | null | undefined;
                          state?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              financial_info?:
                | { __typename?: 'financialInfo'; buyer_not_lessee?: boolean | null | undefined }
                | null
                | undefined;
              notifications?:
                | Array<
                    | {
                        __typename?: 'notification';
                        id?: number | null | undefined;
                        message?: string | null | undefined;
                        created_at?: any | null | undefined;
                        seen?: boolean | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        temporary_info?:
          | {
              __typename?: 'temporaryInfo';
              id?: number | null | undefined;
              data: {
                __typename?: 'temporaryData';
                state?: string | null | undefined;
                city?: string | null | undefined;
                county?: string | null | undefined;
                year?: string | null | undefined;
                make?: string | null | undefined;
                model?: string | null | undefined;
                vin?: string | null | undefined;
                mileage?: number | null | undefined;
                lh_account_number?: string | null | undefined;
                lienholder?: string | null | undefined;
                first_name?: string | null | undefined;
                last_name?: string | null | undefined;
                dob?: any | null | undefined;
                phone_number?: string | null | undefined;
                zip?: string | null | undefined;
                ssn?: string | null | undefined;
                vehicle_type?: string | null | undefined;
                fuel_type?: string | null | undefined;
              };
            }
          | null
          | undefined;
        payoff_clerk?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type OnPayoffRequestStatusUpdateSubscriptionVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']>;
}>;

export type OnPayoffRequestStatusUpdateSubscription = {
  __typename?: 'RootSubscriptionType';
  onPayoffRequestStatusUpdate?:
    | {
        __typename?: 'payoffRequestStatusUpdate';
        id?: number | null | undefined;
        deal_id?: number | null | undefined;
        temporary_info_id?: number | null | undefined;
        status?: PayoffRequestStatus | null | undefined;
        vehicle_payoff?: number | null | undefined;
        good_through_date?: any | null | undefined;
        sales_tax?: number | null | undefined;
        completed_date?: any | null | undefined;
        fail_reason?: any | null | undefined;
        created_at?: any | null | undefined;
        updated_at?: any | null | undefined;
        deal?:
          | {
              __typename?: 'deal';
              id?: number | null | undefined;
              state?: string | null | undefined;
              customer?:
                | {
                    __typename?: 'customer';
                    first_name?: string | null | undefined;
                    last_name?: string | null | undefined;
                  }
                | null
                | undefined;
              car?:
                | {
                    __typename?: 'car';
                    payoff?:
                      | {
                          __typename?: 'payoff';
                          user_entered_total_payoff?: number | null | undefined;
                          vehicle_payoff?: number | null | undefined;
                          sales_tax_from_payoff?: number | null | undefined;
                          payoff_includes_sales_tax?: boolean | null | undefined;
                          sales_tax_from_payoff_entered_manually?: boolean | null | undefined;
                          good_through_date?: any | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              tags?:
                | Array<{
                    __typename?: 'Tag';
                    id: number;
                    slug: TagSlugEnum;
                    display_name: string;
                    color: string;
                    is_active: boolean;
                    is_dashboard_visible: boolean;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PayoffRequestQueryVariables = Exact<{
  deal_id?: InputMaybe<Scalars['ID']>;
}>;

export type PayoffRequestQuery = {
  __typename?: 'RootQueryType';
  payoffRequest?:
    | {
        __typename?: 'payoffRequest';
        id?: number | null | undefined;
        status?: PayoffRequestStatus | null | undefined;
      }
    | null
    | undefined;
};

export type PayoffRequestUpdateMutationVariables = Exact<{
  payoffRequest: PayoffRequestInput;
}>;

export type PayoffRequestUpdateMutation = {
  __typename?: 'RootMutationType';
  payoffRequestUpdate?:
    | { __typename?: 'payoffRequest'; id?: number | null | undefined }
    | null
    | undefined;
};

export type PodsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  pod_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  team_type?: InputMaybe<Array<InputMaybe<TeamType>> | InputMaybe<TeamType>>;
  includeDetails?: InputMaybe<Scalars['Boolean']>;
  hideArchived?: InputMaybe<Scalars['Boolean']>;
}>;

export type PodsQuery = {
  __typename?: 'RootQueryType';
  pods?:
    | Array<
        | {
            __typename?: 'pod';
            id?: number | null | undefined;
            name?: string | null | undefined;
            color?: string | null | undefined;
            hours?: string | null | undefined;
            archived?: boolean | null | undefined;
            team_type?: TeamType | null | undefined;
            created_at?: any | null | undefined;
            vsc_multiplier?: number | null | undefined;
            vsc_markup?: number | null | undefined;
            manager_commission_rate?: number | null | undefined;
            manager_commission_type?: CommissionType | null | undefined;
            special_commission_rate?: number | null | undefined;
            special_commission_type?: CommissionType | null | undefined;
            closer_commission_rate?: number | null | undefined;
            closer_commission_type?: CommissionType | null | undefined;
            setter_commission_rate?: number | null | undefined;
            setter_commission_type?: CommissionType | null | undefined;
            problem_solver?: boolean | null | undefined;
            parent_pod_id?: number | null | undefined;
            managers?:
              | Array<
                  | {
                      __typename?: 'baseUser';
                      id?: string | null | undefined;
                      name?: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            leads?:
              | Array<
                  | {
                      __typename?: 'baseUser';
                      id?: string | null | undefined;
                      name?: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            us_states_object?:
              | {
                  __typename?: 'usStatesObject';
                  states?: Array<StateAbbreviation | null | undefined> | null | undefined;
                }
              | null
              | undefined;
            pod_users?:
              | Array<
                  | {
                      __typename?: 'podUser';
                      id?: number | null | undefined;
                      user_id?: string | null | undefined;
                      pod_id?: number | null | undefined;
                      team_role?: TeamRole | null | undefined;
                      user?:
                        | {
                            __typename?: 'baseUser';
                            id?: string | null | undefined;
                            name?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            parent_pod?:
              | {
                  __typename?: 'basePod';
                  id?: number | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            child_pods?:
              | Array<
                  | {
                      __typename?: 'basePod';
                      id?: number | null | undefined;
                      name?: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            usersWithoutTeamRole?:
              | Array<
                  | {
                      __typename?: 'baseUser';
                      id?: string | null | undefined;
                      name?: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type PodUpsertAndPodUsersUpdateMutationVariables = Exact<{
  newPod: PodInput;
  oldPod?: InputMaybe<PodInput>;
}>;

export type PodUpsertAndPodUsersUpdateMutation = {
  __typename?: 'RootMutationType';
  podUpsertAndPodUsersUpdate?:
    | { __typename?: 'basePod'; id?: number | null | undefined }
    | null
    | undefined;
};

export type PodUpsertMutationVariables = Exact<{
  pod?: InputMaybe<BasePodInput>;
}>;

export type PodUpsertMutation = {
  __typename?: 'RootMutationType';
  podUpsert?:
    | {
        __typename?: 'pod';
        id?: number | null | undefined;
        name?: string | null | undefined;
        color?: string | null | undefined;
        hours?: string | null | undefined;
        vsc_multiplier?: number | null | undefined;
        vsc_markup?: number | null | undefined;
        archived?: boolean | null | undefined;
        closer_commission_rate?: number | null | undefined;
        team_type?: TeamType | null | undefined;
        problem_solver?: boolean | null | undefined;
        us_states_object?:
          | {
              __typename?: 'usStatesObject';
              states?: Array<StateAbbreviation | null | undefined> | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ArchivePodMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;

export type ArchivePodMutation = {
  __typename?: 'RootMutationType';
  archivePod?: any | null | undefined;
};

export type RevivePodMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;

export type RevivePodMutation = {
  __typename?: 'RootMutationType';
  revivePod?:
    | {
        __typename?: 'pod';
        id?: number | null | undefined;
        name?: string | null | undefined;
        color?: string | null | undefined;
        hours?: string | null | undefined;
        archived?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type TitlingPodsAllQueryVariables = Exact<{ [key: string]: never }>;

export type TitlingPodsAllQuery = {
  __typename?: 'RootQueryType';
  titlingPodsAll?:
    | Array<
        | {
            __typename?: 'pod';
            id?: number | null | undefined;
            name?: string | null | undefined;
            color?: string | null | undefined;
            problem_solver?: boolean | null | undefined;
            archived?: boolean | null | undefined;
            us_states_object?:
              | {
                  __typename?: 'usStatesObject';
                  states?: Array<StateAbbreviation | null | undefined> | null | undefined;
                }
              | null
              | undefined;
            pod_users?:
              | Array<
                  | {
                      __typename?: 'podUser';
                      id?: number | null | undefined;
                      user_id?: string | null | undefined;
                      pod_id?: number | null | undefined;
                      team_role?: TeamRole | null | undefined;
                      user?:
                        | {
                            __typename?: 'baseUser';
                            id?: string | null | undefined;
                            name?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type PrequalificationRequestFragmentFragment = {
  __typename?: 'PrequalificationRequest';
  first_name?: string | null | undefined;
  last_name?: string | null | undefined;
  ssn?: string | null | undefined;
  zip?: string | null | undefined;
  gross_income?: number | null | undefined;
  pay_frequency?: string | null | undefined;
  vehicle_payoff?: number | null | undefined;
  lending_book_value?: number | null | undefined;
  money_down?: number | null | undefined;
  income_sources?: number | null | undefined;
};

export type PrequalificationFragmentFragment = {
  __typename?: 'prequalification';
  id?: number | null | undefined;
  credit_score?: number | null | undefined;
  ltv?: number | null | undefined;
  payment_to_income?: number | null | undefined;
  customer_id?: number | null | undefined;
  credit_score_tier?: CreditScoreTierEnumType | null | undefined;
  input?:
    | {
        __typename?: 'PrequalificationRequest';
        first_name?: string | null | undefined;
        last_name?: string | null | undefined;
        ssn?: string | null | undefined;
        zip?: string | null | undefined;
        gross_income?: number | null | undefined;
        pay_frequency?: string | null | undefined;
        vehicle_payoff?: number | null | undefined;
        lending_book_value?: number | null | undefined;
        money_down?: number | null | undefined;
        income_sources?: number | null | undefined;
      }
    | null
    | undefined;
  output?:
    | { __typename?: 'ExperianOutput'; errors?: Array<string> | null | undefined }
    | null
    | undefined;
};

export type PrequalificationCreditReportNameFragmentFragment = {
  __typename?: 'PrequalificationCreditReportName';
  firstName: string;
  middleName?: string | null | undefined;
  surname: string;
  secondSurname?: string | null | undefined;
  generationCode?: string | null | undefined;
};

export type PrequalificationCreditReportTradelineFragmentFragment = {
  __typename?: 'PrequalificationCreditReportTradeline';
  subscriberName?: string | null | undefined;
  accountNumber?: string | null | undefined;
  kob?: string | null | undefined;
  status?: string | null | undefined;
  accountType?: string | null | undefined;
  openDate?: string | null | undefined;
  balanceDate?: string | null | undefined;
  amount1?: string | null | undefined;
  amount1Qualifier?: string | null | undefined;
  amount2?: string | null | undefined;
  amount2Qualifier?: string | null | undefined;
  amountPastDue?: string | null | undefined;
  balanceAmount?: string | null | undefined;
  openOrClosed?: string | null | undefined;
  ecoa?: string | null | undefined;
  monthlyPaymentAmount?: string | null | undefined;
  monthsHistory?: string | null | undefined;
  terms?: string | null | undefined;
  delinquency30Days?: string | null | undefined;
  delinquency60Days?: string | null | undefined;
  delinquency90to180Days?: string | null | undefined;
  paymentHistory?: string | null | undefined;
};

export type PrequalificationCreditReportFragmentFragment = {
  __typename?: 'PrequalificationCreditReport';
  informationalMessages?: Array<string> | null | undefined;
  names?:
    | {
        __typename?: 'PrequalificationCreditReportNames';
        customerName?:
          | {
              __typename?: 'PrequalificationCreditReportName';
              firstName: string;
              middleName?: string | null | undefined;
              surname: string;
              secondSurname?: string | null | undefined;
              generationCode?: string | null | undefined;
            }
          | null
          | undefined;
        aliases: Array<
          | {
              __typename?: 'PrequalificationCreditReportName';
              firstName: string;
              middleName?: string | null | undefined;
              surname: string;
              secondSurname?: string | null | undefined;
              generationCode?: string | null | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
  addresses?:
    | Array<{
        __typename?: 'PrequalificationCreditReportAddress';
        city?: string | null | undefined;
        state?: string | null | undefined;
        zipCode?: string | null | undefined;
        streetName?: string | null | undefined;
        streetPrefix?: string | null | undefined;
        streetSuffix?: string | null | undefined;
        firstReportedDate?: string | null | undefined;
      }>
    | null
    | undefined;
  employments?:
    | Array<{
        __typename?: 'PrequalificationCreditReportEmployment';
        addressExtraLine?: string | null | undefined;
        addressFirstLine?: string | null | undefined;
        addressSecondLine?: string | null | undefined;
        firstReportedDate?: string | null | undefined;
        lastUpdatedDate?: string | null | undefined;
        name?: string | null | undefined;
        source?: string | null | undefined;
        zipCode?: string | null | undefined;
      }>
    | null
    | undefined;
  scoreFactors?:
    | Array<{
        __typename?: 'PrequalificationCreditScoreScoreFactor';
        code?: string | null | undefined;
        description?: string | null | undefined;
      }>
    | null
    | undefined;
  tradelines?:
    | {
        __typename?: 'PrequalificationCreditReportTradelines';
        revolving: Array<{
          __typename?: 'PrequalificationCreditReportTradeline';
          subscriberName?: string | null | undefined;
          accountNumber?: string | null | undefined;
          kob?: string | null | undefined;
          status?: string | null | undefined;
          accountType?: string | null | undefined;
          openDate?: string | null | undefined;
          balanceDate?: string | null | undefined;
          amount1?: string | null | undefined;
          amount1Qualifier?: string | null | undefined;
          amount2?: string | null | undefined;
          amount2Qualifier?: string | null | undefined;
          amountPastDue?: string | null | undefined;
          balanceAmount?: string | null | undefined;
          openOrClosed?: string | null | undefined;
          ecoa?: string | null | undefined;
          monthlyPaymentAmount?: string | null | undefined;
          monthsHistory?: string | null | undefined;
          terms?: string | null | undefined;
          delinquency30Days?: string | null | undefined;
          delinquency60Days?: string | null | undefined;
          delinquency90to180Days?: string | null | undefined;
          paymentHistory?: string | null | undefined;
        }>;
        installment: Array<{
          __typename?: 'PrequalificationCreditReportTradeline';
          subscriberName?: string | null | undefined;
          accountNumber?: string | null | undefined;
          kob?: string | null | undefined;
          status?: string | null | undefined;
          accountType?: string | null | undefined;
          openDate?: string | null | undefined;
          balanceDate?: string | null | undefined;
          amount1?: string | null | undefined;
          amount1Qualifier?: string | null | undefined;
          amount2?: string | null | undefined;
          amount2Qualifier?: string | null | undefined;
          amountPastDue?: string | null | undefined;
          balanceAmount?: string | null | undefined;
          openOrClosed?: string | null | undefined;
          ecoa?: string | null | undefined;
          monthlyPaymentAmount?: string | null | undefined;
          monthsHistory?: string | null | undefined;
          terms?: string | null | undefined;
          delinquency30Days?: string | null | undefined;
          delinquency60Days?: string | null | undefined;
          delinquency90to180Days?: string | null | undefined;
          paymentHistory?: string | null | undefined;
        }>;
        unknown: Array<{
          __typename?: 'PrequalificationCreditReportTradeline';
          subscriberName?: string | null | undefined;
          accountNumber?: string | null | undefined;
          kob?: string | null | undefined;
          status?: string | null | undefined;
          accountType?: string | null | undefined;
          openDate?: string | null | undefined;
          balanceDate?: string | null | undefined;
          amount1?: string | null | undefined;
          amount1Qualifier?: string | null | undefined;
          amount2?: string | null | undefined;
          amount2Qualifier?: string | null | undefined;
          amountPastDue?: string | null | undefined;
          balanceAmount?: string | null | undefined;
          openOrClosed?: string | null | undefined;
          ecoa?: string | null | undefined;
          monthlyPaymentAmount?: string | null | undefined;
          monthsHistory?: string | null | undefined;
          terms?: string | null | undefined;
          delinquency30Days?: string | null | undefined;
          delinquency60Days?: string | null | undefined;
          delinquency90to180Days?: string | null | undefined;
          paymentHistory?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetDdSoftPullQueryVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']>;
  paymentEstimateInput: PaymentEstimateInput;
  shouldForceRetry?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetDdSoftPullQuery = {
  __typename?: 'RootQueryType';
  getDDSoftPull: {
    __typename?: 'DDSoftPull';
    customer: {
      __typename?: 'prequalification';
      id?: number | null | undefined;
      credit_score?: number | null | undefined;
      ltv?: number | null | undefined;
      payment_to_income?: number | null | undefined;
      customer_id?: number | null | undefined;
      credit_score_tier?: CreditScoreTierEnumType | null | undefined;
      input?:
        | {
            __typename?: 'PrequalificationRequest';
            first_name?: string | null | undefined;
            last_name?: string | null | undefined;
            ssn?: string | null | undefined;
            zip?: string | null | undefined;
            gross_income?: number | null | undefined;
            pay_frequency?: string | null | undefined;
            vehicle_payoff?: number | null | undefined;
            lending_book_value?: number | null | undefined;
            money_down?: number | null | undefined;
            income_sources?: number | null | undefined;
          }
        | null
        | undefined;
      output?:
        | { __typename?: 'ExperianOutput'; errors?: Array<string> | null | undefined }
        | null
        | undefined;
    };
    cobuyer?:
      | {
          __typename?: 'prequalification';
          id?: number | null | undefined;
          credit_score?: number | null | undefined;
          ltv?: number | null | undefined;
          payment_to_income?: number | null | undefined;
          customer_id?: number | null | undefined;
          credit_score_tier?: CreditScoreTierEnumType | null | undefined;
          input?:
            | {
                __typename?: 'PrequalificationRequest';
                first_name?: string | null | undefined;
                last_name?: string | null | undefined;
                ssn?: string | null | undefined;
                zip?: string | null | undefined;
                gross_income?: number | null | undefined;
                pay_frequency?: string | null | undefined;
                vehicle_payoff?: number | null | undefined;
                lending_book_value?: number | null | undefined;
                money_down?: number | null | undefined;
                income_sources?: number | null | undefined;
              }
            | null
            | undefined;
          output?:
            | { __typename?: 'ExperianOutput'; errors?: Array<string> | null | undefined }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type ExperianCreditReportQueryVariables = Exact<{
  dealId: Scalars['ID'];
}>;

export type ExperianCreditReportQuery = {
  __typename?: 'RootQueryType';
  experianCreditReport?:
    | {
        __typename?: 'PrequalificationCreditReports';
        customer: {
          __typename?: 'PrequalificationCreditReport';
          informationalMessages?: Array<string> | null | undefined;
          names?:
            | {
                __typename?: 'PrequalificationCreditReportNames';
                customerName?:
                  | {
                      __typename?: 'PrequalificationCreditReportName';
                      firstName: string;
                      middleName?: string | null | undefined;
                      surname: string;
                      secondSurname?: string | null | undefined;
                      generationCode?: string | null | undefined;
                    }
                  | null
                  | undefined;
                aliases: Array<
                  | {
                      __typename?: 'PrequalificationCreditReportName';
                      firstName: string;
                      middleName?: string | null | undefined;
                      surname: string;
                      secondSurname?: string | null | undefined;
                      generationCode?: string | null | undefined;
                    }
                  | null
                  | undefined
                >;
              }
            | null
            | undefined;
          addresses?:
            | Array<{
                __typename?: 'PrequalificationCreditReportAddress';
                city?: string | null | undefined;
                state?: string | null | undefined;
                zipCode?: string | null | undefined;
                streetName?: string | null | undefined;
                streetPrefix?: string | null | undefined;
                streetSuffix?: string | null | undefined;
                firstReportedDate?: string | null | undefined;
              }>
            | null
            | undefined;
          employments?:
            | Array<{
                __typename?: 'PrequalificationCreditReportEmployment';
                addressExtraLine?: string | null | undefined;
                addressFirstLine?: string | null | undefined;
                addressSecondLine?: string | null | undefined;
                firstReportedDate?: string | null | undefined;
                lastUpdatedDate?: string | null | undefined;
                name?: string | null | undefined;
                source?: string | null | undefined;
                zipCode?: string | null | undefined;
              }>
            | null
            | undefined;
          scoreFactors?:
            | Array<{
                __typename?: 'PrequalificationCreditScoreScoreFactor';
                code?: string | null | undefined;
                description?: string | null | undefined;
              }>
            | null
            | undefined;
          tradelines?:
            | {
                __typename?: 'PrequalificationCreditReportTradelines';
                revolving: Array<{
                  __typename?: 'PrequalificationCreditReportTradeline';
                  subscriberName?: string | null | undefined;
                  accountNumber?: string | null | undefined;
                  kob?: string | null | undefined;
                  status?: string | null | undefined;
                  accountType?: string | null | undefined;
                  openDate?: string | null | undefined;
                  balanceDate?: string | null | undefined;
                  amount1?: string | null | undefined;
                  amount1Qualifier?: string | null | undefined;
                  amount2?: string | null | undefined;
                  amount2Qualifier?: string | null | undefined;
                  amountPastDue?: string | null | undefined;
                  balanceAmount?: string | null | undefined;
                  openOrClosed?: string | null | undefined;
                  ecoa?: string | null | undefined;
                  monthlyPaymentAmount?: string | null | undefined;
                  monthsHistory?: string | null | undefined;
                  terms?: string | null | undefined;
                  delinquency30Days?: string | null | undefined;
                  delinquency60Days?: string | null | undefined;
                  delinquency90to180Days?: string | null | undefined;
                  paymentHistory?: string | null | undefined;
                }>;
                installment: Array<{
                  __typename?: 'PrequalificationCreditReportTradeline';
                  subscriberName?: string | null | undefined;
                  accountNumber?: string | null | undefined;
                  kob?: string | null | undefined;
                  status?: string | null | undefined;
                  accountType?: string | null | undefined;
                  openDate?: string | null | undefined;
                  balanceDate?: string | null | undefined;
                  amount1?: string | null | undefined;
                  amount1Qualifier?: string | null | undefined;
                  amount2?: string | null | undefined;
                  amount2Qualifier?: string | null | undefined;
                  amountPastDue?: string | null | undefined;
                  balanceAmount?: string | null | undefined;
                  openOrClosed?: string | null | undefined;
                  ecoa?: string | null | undefined;
                  monthlyPaymentAmount?: string | null | undefined;
                  monthsHistory?: string | null | undefined;
                  terms?: string | null | undefined;
                  delinquency30Days?: string | null | undefined;
                  delinquency60Days?: string | null | undefined;
                  delinquency90to180Days?: string | null | undefined;
                  paymentHistory?: string | null | undefined;
                }>;
                unknown: Array<{
                  __typename?: 'PrequalificationCreditReportTradeline';
                  subscriberName?: string | null | undefined;
                  accountNumber?: string | null | undefined;
                  kob?: string | null | undefined;
                  status?: string | null | undefined;
                  accountType?: string | null | undefined;
                  openDate?: string | null | undefined;
                  balanceDate?: string | null | undefined;
                  amount1?: string | null | undefined;
                  amount1Qualifier?: string | null | undefined;
                  amount2?: string | null | undefined;
                  amount2Qualifier?: string | null | undefined;
                  amountPastDue?: string | null | undefined;
                  balanceAmount?: string | null | undefined;
                  openOrClosed?: string | null | undefined;
                  ecoa?: string | null | undefined;
                  monthlyPaymentAmount?: string | null | undefined;
                  monthsHistory?: string | null | undefined;
                  terms?: string | null | undefined;
                  delinquency30Days?: string | null | undefined;
                  delinquency60Days?: string | null | undefined;
                  delinquency90to180Days?: string | null | undefined;
                  paymentHistory?: string | null | undefined;
                }>;
              }
            | null
            | undefined;
        };
        cobuyer?:
          | {
              __typename?: 'PrequalificationCreditReport';
              informationalMessages?: Array<string> | null | undefined;
              names?:
                | {
                    __typename?: 'PrequalificationCreditReportNames';
                    customerName?:
                      | {
                          __typename?: 'PrequalificationCreditReportName';
                          firstName: string;
                          middleName?: string | null | undefined;
                          surname: string;
                          secondSurname?: string | null | undefined;
                          generationCode?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    aliases: Array<
                      | {
                          __typename?: 'PrequalificationCreditReportName';
                          firstName: string;
                          middleName?: string | null | undefined;
                          surname: string;
                          secondSurname?: string | null | undefined;
                          generationCode?: string | null | undefined;
                        }
                      | null
                      | undefined
                    >;
                  }
                | null
                | undefined;
              addresses?:
                | Array<{
                    __typename?: 'PrequalificationCreditReportAddress';
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    zipCode?: string | null | undefined;
                    streetName?: string | null | undefined;
                    streetPrefix?: string | null | undefined;
                    streetSuffix?: string | null | undefined;
                    firstReportedDate?: string | null | undefined;
                  }>
                | null
                | undefined;
              employments?:
                | Array<{
                    __typename?: 'PrequalificationCreditReportEmployment';
                    addressExtraLine?: string | null | undefined;
                    addressFirstLine?: string | null | undefined;
                    addressSecondLine?: string | null | undefined;
                    firstReportedDate?: string | null | undefined;
                    lastUpdatedDate?: string | null | undefined;
                    name?: string | null | undefined;
                    source?: string | null | undefined;
                    zipCode?: string | null | undefined;
                  }>
                | null
                | undefined;
              scoreFactors?:
                | Array<{
                    __typename?: 'PrequalificationCreditScoreScoreFactor';
                    code?: string | null | undefined;
                    description?: string | null | undefined;
                  }>
                | null
                | undefined;
              tradelines?:
                | {
                    __typename?: 'PrequalificationCreditReportTradelines';
                    revolving: Array<{
                      __typename?: 'PrequalificationCreditReportTradeline';
                      subscriberName?: string | null | undefined;
                      accountNumber?: string | null | undefined;
                      kob?: string | null | undefined;
                      status?: string | null | undefined;
                      accountType?: string | null | undefined;
                      openDate?: string | null | undefined;
                      balanceDate?: string | null | undefined;
                      amount1?: string | null | undefined;
                      amount1Qualifier?: string | null | undefined;
                      amount2?: string | null | undefined;
                      amount2Qualifier?: string | null | undefined;
                      amountPastDue?: string | null | undefined;
                      balanceAmount?: string | null | undefined;
                      openOrClosed?: string | null | undefined;
                      ecoa?: string | null | undefined;
                      monthlyPaymentAmount?: string | null | undefined;
                      monthsHistory?: string | null | undefined;
                      terms?: string | null | undefined;
                      delinquency30Days?: string | null | undefined;
                      delinquency60Days?: string | null | undefined;
                      delinquency90to180Days?: string | null | undefined;
                      paymentHistory?: string | null | undefined;
                    }>;
                    installment: Array<{
                      __typename?: 'PrequalificationCreditReportTradeline';
                      subscriberName?: string | null | undefined;
                      accountNumber?: string | null | undefined;
                      kob?: string | null | undefined;
                      status?: string | null | undefined;
                      accountType?: string | null | undefined;
                      openDate?: string | null | undefined;
                      balanceDate?: string | null | undefined;
                      amount1?: string | null | undefined;
                      amount1Qualifier?: string | null | undefined;
                      amount2?: string | null | undefined;
                      amount2Qualifier?: string | null | undefined;
                      amountPastDue?: string | null | undefined;
                      balanceAmount?: string | null | undefined;
                      openOrClosed?: string | null | undefined;
                      ecoa?: string | null | undefined;
                      monthlyPaymentAmount?: string | null | undefined;
                      monthsHistory?: string | null | undefined;
                      terms?: string | null | undefined;
                      delinquency30Days?: string | null | undefined;
                      delinquency60Days?: string | null | undefined;
                      delinquency90to180Days?: string | null | undefined;
                      paymentHistory?: string | null | undefined;
                    }>;
                    unknown: Array<{
                      __typename?: 'PrequalificationCreditReportTradeline';
                      subscriberName?: string | null | undefined;
                      accountNumber?: string | null | undefined;
                      kob?: string | null | undefined;
                      status?: string | null | undefined;
                      accountType?: string | null | undefined;
                      openDate?: string | null | undefined;
                      balanceDate?: string | null | undefined;
                      amount1?: string | null | undefined;
                      amount1Qualifier?: string | null | undefined;
                      amount2?: string | null | undefined;
                      amount2Qualifier?: string | null | undefined;
                      amountPastDue?: string | null | undefined;
                      balanceAmount?: string | null | undefined;
                      openOrClosed?: string | null | undefined;
                      ecoa?: string | null | undefined;
                      monthlyPaymentAmount?: string | null | undefined;
                      monthsHistory?: string | null | undefined;
                      terms?: string | null | undefined;
                      delinquency30Days?: string | null | undefined;
                      delinquency60Days?: string | null | undefined;
                      delinquency90to180Days?: string | null | undefined;
                      paymentHistory?: string | null | undefined;
                    }>;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RateFragmentFragment = {
  __typename?: 'rate';
  form_id?: number | null | undefined;
  session_id?: string | null | undefined;
  coverage_name?: string | null | undefined;
  term_miles?: string | null | undefined;
  term_months?: string | null | undefined;
  deductible?:
    | {
        __typename?: 'deductible';
        rate_id?: number | null | undefined;
        amount?: string | null | undefined;
        dealer_cost?: string | null | undefined;
        deductible_type?: string | null | undefined;
        f_i_markup?: string | null | undefined;
        max_retail_price?: string | null | undefined;
        min_retail_price?: string | null | undefined;
        retail_price?: string | null | undefined;
      }
    | null
    | undefined;
};

export type VscRatesQueryVariables = Exact<{
  deal_id: Scalars['ID'];
  data?: InputMaybe<RateInput>;
}>;

export type VscRatesQuery = {
  __typename?: 'RootQueryType';
  vscRates?:
    | Array<
        | {
            __typename?: 'rate';
            form_id?: number | null | undefined;
            session_id?: string | null | undefined;
            coverage_name?: string | null | undefined;
            term_miles?: string | null | undefined;
            term_months?: string | null | undefined;
            deductible?:
              | {
                  __typename?: 'deductible';
                  rate_id?: number | null | undefined;
                  amount?: string | null | undefined;
                  dealer_cost?: string | null | undefined;
                  deductible_type?: string | null | undefined;
                  f_i_markup?: string | null | undefined;
                  max_retail_price?: string | null | undefined;
                  min_retail_price?: string | null | undefined;
                  retail_price?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GapRatesQueryVariables = Exact<{
  bank: Scalars['String'];
  deal_id: Scalars['ID'];
  data?: InputMaybe<RateInput>;
}>;

export type GapRatesQuery = {
  __typename?: 'RootQueryType';
  gapRates?:
    | Array<
        | {
            __typename?: 'rate';
            form_id?: number | null | undefined;
            session_id?: string | null | undefined;
            coverage_name?: string | null | undefined;
            term_miles?: string | null | undefined;
            term_months?: string | null | undefined;
            deductible?:
              | {
                  __typename?: 'deductible';
                  rate_id?: number | null | undefined;
                  amount?: string | null | undefined;
                  dealer_cost?: string | null | undefined;
                  deductible_type?: string | null | undefined;
                  f_i_markup?: string | null | undefined;
                  max_retail_price?: string | null | undefined;
                  min_retail_price?: string | null | undefined;
                  retail_price?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CreateReviewMutationVariables = Exact<{
  review?: InputMaybe<ReviewInput>;
}>;

export type CreateReviewMutation = {
  __typename?: 'RootMutationType';
  createReview?: { __typename?: 'review'; id?: number | null | undefined } | null | undefined;
};

export type ReviewsQueryVariables = Exact<{ [key: string]: never }>;

export type ReviewsQuery = {
  __typename?: 'RootQueryType';
  reviews?:
    | Array<
        | {
            __typename?: 'review';
            id?: number | null | undefined;
            customer_name?: string | null | undefined;
            review?: string | null | undefined;
            com_visible?: boolean | null | undefined;
            created_at?: any | null | undefined;
            location?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type DeleteReviewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteReviewMutation = {
  __typename?: 'RootMutationType';
  deleteReview?: { __typename?: 'review'; id?: number | null | undefined } | null | undefined;
};

export type UpdateReviewMutationVariables = Exact<{
  review?: InputMaybe<ReviewInput>;
}>;

export type UpdateReviewMutation = {
  __typename?: 'RootMutationType';
  updateReview?:
    | {
        __typename?: 'review';
        id?: number | null | undefined;
        customer_name?: string | null | undefined;
        review?: string | null | undefined;
        com_visible?: boolean | null | undefined;
        created_at?: any | null | undefined;
        location?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CreateSsnLogMutationVariables = Exact<{
  log: SsnLogInput;
}>;

export type CreateSsnLogMutation = {
  __typename?: 'RootMutationType';
  ssnLogCreate?: { __typename?: 'ssnLog'; id?: number | null | undefined } | null | undefined;
};

export type StructuringFollowUpFragmentFragment = {
  __typename?: 'StructuringFollowUp';
  id?: number | null | undefined;
  created_at?: any | null | undefined;
  status?: StructuringFollowUpStatus | null | undefined;
  creator_id?: number | null | undefined;
  notes?: string | null | undefined;
};

export type StructuringFollowUpDealFragmentFragment = {
  __typename?: 'deal';
  id?: number | null | undefined;
  state?: string | null | undefined;
  type?: DealType | null | undefined;
  source?: string | null | undefined;
  closer_id?: number | null | undefined;
  structuring_manager_id?: number | null | undefined;
  customer?:
    | {
        __typename?: 'customer';
        first_name?: string | null | undefined;
        last_name?: string | null | undefined;
        phone_number?: string | null | undefined;
      }
    | null
    | undefined;
  setter?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
  closer?:
    | { __typename?: 'user'; id?: string | null | undefined; name?: string | null | undefined }
    | null
    | undefined;
  structuring_manager?:
    | { __typename?: 'user'; id?: string | null | undefined; name?: string | null | undefined }
    | null
    | undefined;
  structuring_follow_up?:
    | {
        __typename?: 'StructuringFollowUp';
        id?: number | null | undefined;
        created_at?: any | null | undefined;
        status?: StructuringFollowUpStatus | null | undefined;
        creator_id?: number | null | undefined;
        notes?: string | null | undefined;
      }
    | null
    | undefined;
  tags?:
    | Array<{
        __typename?: 'Tag';
        id: number;
        color: string;
        display_name: string;
        is_dashboard_visible: boolean;
      }>
    | null
    | undefined;
  notifications?:
    | Array<
        | {
            __typename?: 'notification';
            message?: string | null | undefined;
            seen?: boolean | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type StructuringFollowUpQueryVariables = Exact<{
  dealId: Scalars['ID'];
}>;

export type StructuringFollowUpQuery = {
  __typename?: 'RootQueryType';
  structuringFollowUp?:
    | {
        __typename?: 'StructuringFollowUp';
        id?: number | null | undefined;
        created_at?: any | null | undefined;
        status?: StructuringFollowUpStatus | null | undefined;
        creator_id?: number | null | undefined;
        notes?: string | null | undefined;
      }
    | null
    | undefined;
};

export type StructuringFollowUpDealsQueryVariables = Exact<{
  sortColumn: StructuringFollowUpSortColumn;
  sortDirection: StructuringFollowUpSortDirection;
  sources: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  dealTypes: Array<InputMaybe<DealType>> | InputMaybe<DealType>;
  assignedToMe?: InputMaybe<Scalars['Boolean']>;
  bootRequested?: InputMaybe<Scalars['Boolean']>;
  notifications?: InputMaybe<Scalars['Boolean']>;
  podId?: InputMaybe<Scalars['ID']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  showActiveTags?: InputMaybe<Scalars['Boolean']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']>;
}>;

export type StructuringFollowUpDealsQuery = {
  __typename?: 'RootQueryType';
  structuringFollowUpDeals?:
    | {
        __typename?: 'PaginatedDeals';
        totalRecords?: number | null | undefined;
        deals?:
          | Array<
              | {
                  __typename?: 'deal';
                  id?: number | null | undefined;
                  state?: string | null | undefined;
                  type?: DealType | null | undefined;
                  source?: string | null | undefined;
                  closer_id?: number | null | undefined;
                  structuring_manager_id?: number | null | undefined;
                  customer?:
                    | {
                        __typename?: 'customer';
                        first_name?: string | null | undefined;
                        last_name?: string | null | undefined;
                        phone_number?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  setter?:
                    | { __typename?: 'user'; name?: string | null | undefined }
                    | null
                    | undefined;
                  closer?:
                    | {
                        __typename?: 'user';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  structuring_manager?:
                    | {
                        __typename?: 'user';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  structuring_follow_up?:
                    | {
                        __typename?: 'StructuringFollowUp';
                        id?: number | null | undefined;
                        created_at?: any | null | undefined;
                        status?: StructuringFollowUpStatus | null | undefined;
                        creator_id?: number | null | undefined;
                        notes?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  tags?:
                    | Array<{
                        __typename?: 'Tag';
                        id: number;
                        color: string;
                        display_name: string;
                        is_dashboard_visible: boolean;
                      }>
                    | null
                    | undefined;
                  notifications?:
                    | Array<
                        | {
                            __typename?: 'notification';
                            message?: string | null | undefined;
                            seen?: boolean | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateStructuringFollowUpMutationVariables = Exact<{
  dealId: Scalars['ID'];
  notes: Scalars['String'];
}>;

export type CreateStructuringFollowUpMutation = {
  __typename?: 'RootMutationType';
  createStructuringFollowUp?: boolean | null | undefined;
};

export type UpdateStructuringFollowUpNotesMutationVariables = Exact<{
  id: Scalars['ID'];
  notes: Scalars['String'];
}>;

export type UpdateStructuringFollowUpNotesMutation = {
  __typename?: 'RootMutationType';
  updateStructuringFollowUpNotes?: boolean | null | undefined;
};

export type DeleteStructuringFollowUpMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteStructuringFollowUpMutation = {
  __typename?: 'RootMutationType';
  deleteStructuringFollowUp?: boolean | null | undefined;
};

export type OnStructuringFollowUpUpdateSubscriptionVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']>;
  sources?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  types?: InputMaybe<Array<InputMaybe<DealType>> | InputMaybe<DealType>>;
  assignedToMe?: InputMaybe<Scalars['Boolean']>;
  podId?: InputMaybe<Scalars['ID']>;
}>;

export type OnStructuringFollowUpUpdateSubscription = {
  __typename?: 'RootSubscriptionType';
  onStructuringFollowUpUpdate?:
    | Array<
        | {
            __typename?: 'deal';
            id?: number | null | undefined;
            state?: string | null | undefined;
            type?: DealType | null | undefined;
            source?: string | null | undefined;
            closer_id?: number | null | undefined;
            structuring_manager_id?: number | null | undefined;
            customer?:
              | {
                  __typename?: 'customer';
                  first_name?: string | null | undefined;
                  last_name?: string | null | undefined;
                  phone_number?: string | null | undefined;
                }
              | null
              | undefined;
            setter?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
            closer?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            structuring_manager?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            structuring_follow_up?:
              | {
                  __typename?: 'StructuringFollowUp';
                  id?: number | null | undefined;
                  created_at?: any | null | undefined;
                  status?: StructuringFollowUpStatus | null | undefined;
                  creator_id?: number | null | undefined;
                  notes?: string | null | undefined;
                }
              | null
              | undefined;
            tags?:
              | Array<{
                  __typename?: 'Tag';
                  id: number;
                  color: string;
                  display_name: string;
                  is_dashboard_visible: boolean;
                }>
              | null
              | undefined;
            notifications?:
              | Array<
                  | {
                      __typename?: 'notification';
                      message?: string | null | undefined;
                      seen?: boolean | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type TagsQueryVariables = Exact<{
  input?: InputMaybe<TagsInput>;
}>;

export type TagsQuery = {
  __typename?: 'RootQueryType';
  tags: Array<{
    __typename?: 'Tag';
    id: number;
    slug: TagSlugEnum;
    display_name: string;
    color: string;
    is_active: boolean;
    is_dashboard_visible: boolean;
  }>;
};

export type JurisdictionQueryVariables = Exact<{
  state: StateAbbreviation;
}>;

export type JurisdictionQuery = {
  __typename?: 'RootQueryType';
  jurisdiction?:
    | {
        __typename?: 'TTJurisdiction';
        hasRegistrationOnly?: boolean | null | undefined;
        hasTitleOnly?: boolean | null | undefined;
        hasTitleAndRegistration?: boolean | null | undefined;
        allowRegistrationTransfer?: boolean | null | undefined;
        allowPlateTransfer?: boolean | null | undefined;
        requireWalkIn?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type JurisdictionProcessorQueryVariables = Exact<{
  state: StateAbbreviation;
}>;

export type JurisdictionProcessorQuery = {
  __typename?: 'RootQueryType';
  jurisdiction?:
    | {
        __typename?: 'TTJurisdiction';
        supportedProcessors?: Array<Processor | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export type RequiredDocumentsAndValidationsQueryVariables = Exact<{
  state: StateAbbreviation;
  types: Array<TtProductType> | TtProductType;
  conditions?: InputMaybe<TtConditionFilterInput>;
}>;

export type RequiredDocumentsAndValidationsQuery = {
  __typename?: 'RootQueryType';
  jurisdiction?:
    | {
        __typename?: 'TTJurisdiction';
        products?:
          | {
              __typename?: 'TTTTProductFieldsConnection';
              items?:
                | Array<
                    | {
                        __typename?: 'TTProductFields';
                        id?: number | null | undefined;
                        type?: TtProductType | null | undefined;
                        documents?:
                          | {
                              __typename?: 'TTTTDocumentFieldsConnection';
                              count?: number | null | undefined;
                              items?:
                                | Array<
                                    | {
                                        __typename?: 'TTDocumentFields';
                                        type?:
                                          | {
                                              __typename?: 'TTDocumentTypeFields';
                                              id?: number | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                        validations?:
                                          | Array<
                                              | {
                                                  __typename?: 'TTValidation';
                                                  internalDescription?: string | null | undefined;
                                                  description?: string | null | undefined;
                                                  name?: string | null | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        validations?:
                          | Array<
                              | {
                                  __typename?: 'TTValidation';
                                  name?: string | null | undefined;
                                  description?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RequiredDocumentsQueryVariables = Exact<{
  state: StateAbbreviation;
  types: Array<TtProductType> | TtProductType;
  conditions?: InputMaybe<TtConditionFilterInput>;
}>;

export type RequiredDocumentsQuery = {
  __typename?: 'RootQueryType';
  jurisdiction?:
    | {
        __typename?: 'TTJurisdiction';
        hasRegistrationOnly?: boolean | null | undefined;
        hasTitleOnly?: boolean | null | undefined;
        hasTitleAndRegistration?: boolean | null | undefined;
        allowRegistrationTransfer?: boolean | null | undefined;
        allowPlateTransfer?: boolean | null | undefined;
        requireWalkIn?: boolean | null | undefined;
        products?:
          | {
              __typename?: 'TTTTProductFieldsConnection';
              items?:
                | Array<
                    | {
                        __typename?: 'TTProductFields';
                        id?: number | null | undefined;
                        type?: TtProductType | null | undefined;
                        documents?:
                          | {
                              __typename?: 'TTTTDocumentFieldsConnection';
                              count?: number | null | undefined;
                              items?:
                                | Array<
                                    | {
                                        __typename?: 'TTDocumentFields';
                                        type?:
                                          | {
                                              __typename?: 'TTDocumentTypeFields';
                                              id?: number | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                        validations?:
                                          | Array<
                                              | {
                                                  __typename?: 'TTValidation';
                                                  name?: string | null | undefined;
                                                  description?: string | null | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetFeesQueryVariables = Exact<{
  uniqueId?: InputMaybe<Scalars['String']>;
  state: StateAbbreviation;
  previouslyTitledState: StateAbbreviation;
  city: Scalars['String'];
  county: Scalars['String'];
  zip: Scalars['String'];
  vin: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  year: Scalars['Int'];
  vehicleType: TtVehicleType;
  fuelType: TtFuelType;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  retailBookValue: Scalars['Float'];
  bookValue: Scalars['Float'];
  docFee: Scalars['Float'];
  payoff: Scalars['Float'];
  estimatedPayoff: Scalars['Float'];
  ssn: Scalars['String'];
  warranty: Scalars['Float'];
  financialInfoId?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Scalars['String']>;
}>;

export type GetFeesQuery = {
  __typename?: 'RootQueryType';
  getFees?:
    | {
        __typename?: 'FeeGQL';
        state?: StateAbbreviation | null | undefined;
        totalFeeAmount?: number | null | undefined;
        salesTaxRate?: number | null | undefined;
        baseTaxAmount?: number | null | undefined;
        warrantyTaxAmount?: number | null | undefined;
        taxProcessor?: string | null | undefined;
        feeProcessor?: string | null | undefined;
        docFeeTaxAmount?: number | null | undefined;
        registrationTransferFee?: number | null | undefined;
      }
    | null
    | undefined;
};

export type UploadDocumentMutationVariables = Exact<{
  bytes: Scalars['String'];
  documentType: TtDocumentName;
}>;

export type UploadDocumentMutation = {
  __typename?: 'RootMutationType';
  uploadDocument?:
    | {
        __typename?: 'ExtractedDocumentGQL';
        documentName?: number | null | undefined;
        key?: string | null | undefined;
        fields?:
          | Array<
              | {
                  __typename?: 'ExtractedField';
                  confidence?: number | null | undefined;
                  name?: string | null | undefined;
                  value?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UploadDriversLicenseMutationVariables = Exact<{
  bytes: Scalars['String'];
}>;

export type UploadDriversLicenseMutation = {
  __typename?: 'RootMutationType';
  uploadDriversLicense?:
    | {
        __typename?: 'DriversLicenseGQL';
        firstName?: string | null | undefined;
        middleName?: string | null | undefined;
        lastName?: string | null | undefined;
        address?: string | null | undefined;
        city?: string | null | undefined;
        state?: string | null | undefined;
        zip?: string | null | undefined;
        expirationDate?: any | null | undefined;
      }
    | null
    | undefined;
};

export type CreateTransactionMutationVariables = Exact<{
  input: CreateTransactionInput;
}>;

export type CreateTransactionMutation = {
  __typename?: 'RootMutationType';
  createTransaction?: number | null | undefined;
};

export type TemporaryInfoFragmentFragment = {
  __typename?: 'temporaryInfo';
  id?: number | null | undefined;
  data: {
    __typename?: 'temporaryData';
    vin?: string | null | undefined;
    year?: string | null | undefined;
    make?: string | null | undefined;
    model?: string | null | undefined;
    fuel_type?: string | null | undefined;
    vehicle_type?: string | null | undefined;
    color?: string | null | undefined;
    mileage?: number | null | undefined;
    phone_number?: string | null | undefined;
    email?: string | null | undefined;
    lienholder?: string | null | undefined;
    lienholderLogo?: string | null | undefined;
    first_name?: string | null | undefined;
    last_name?: string | null | undefined;
    address_line?: string | null | undefined;
    city?: string | null | undefined;
    state?: string | null | undefined;
    zip?: string | null | undefined;
    previous_address_line?: string | null | undefined;
    previous_city?: string | null | undefined;
    previous_state?: string | null | undefined;
    previous_zip?: string | null | undefined;
    scheduled_call_datetime?: any | null | undefined;
    scheduled_call_datetime_tz?: string | null | undefined;
    primary_residence_type?: string | null | undefined;
    primary_residence_years?: number | null | undefined;
    primary_residence_months?: number | null | undefined;
    primary_residence_monthly_payment?: number | null | undefined;
    secondary_residence_years?: number | null | undefined;
    secondary_residence_months?: number | null | undefined;
    dob?: any | null | undefined;
    good_through_date?: any | null | undefined;
    employer_name?: string | null | undefined;
    job_title?: string | null | undefined;
    employer_phone_number?: string | null | undefined;
    years_at_job?: number | null | undefined;
    months_at_job?: number | null | undefined;
    salary?: number | null | undefined;
    previous_employer_name?: string | null | undefined;
    previous_job_title?: string | null | undefined;
    previous_employer_phone_number?: string | null | undefined;
    previous_years_at_job?: number | null | undefined;
    previous_months_at_job?: number | null | undefined;
    retail_book_value?: number | null | undefined;
    kbb_retail_mileage_adjustment?: number | null | undefined;
    kbb_retail_option_adjustment?: number | null | undefined;
    book_value?: number | null | undefined;
    kbb_lending_mileage_adjustment?: number | null | undefined;
    kbb_lending_option_adjustment?: number | null | undefined;
    kbb_valuation_date?: string | null | undefined;
    kbb_vehicle_name?: string | null | undefined;
    kbb_trim_name?: string | null | undefined;
    kbb_selected_options?: any | null | undefined;
    credit_score?: number | null | undefined;
    term?: number | null | undefined;
    rate?: number | null | undefined;
    down_payment?: number | null | undefined;
    taxes_fees?: number | null | undefined;
    payoff_includes_sales_tax?: boolean | null | undefined;
    old_lease_payment?: number | null | undefined;
    vehicle_payoff?: number | null | undefined;
  };
};

export type SearchWebLeadsQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type SearchWebLeadsQuery = {
  __typename?: 'RootQueryType';
  searchWebLeads?:
    | Array<
        | {
            __typename?: 'temporaryInfo';
            id?: number | null | undefined;
            deal_id?: number | null | undefined;
            data: {
              __typename?: 'temporaryData';
              first_name?: string | null | undefined;
              last_name?: string | null | undefined;
              phone_number?: string | null | undefined;
              email?: string | null | undefined;
              vin?: string | null | undefined;
            };
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type TemporaryInfoAuthenticatedQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TemporaryInfoAuthenticatedQuery = {
  __typename?: 'RootQueryType';
  temporaryInfoAuthenticated?:
    | {
        __typename?: 'temporaryInfo';
        id?: number | null | undefined;
        consents?:
          | Array<
              | { __typename?: 'consent'; type?: ConsentNameEnumType | null | undefined }
              | null
              | undefined
            >
          | null
          | undefined;
        data: {
          __typename?: 'temporaryData';
          vin?: string | null | undefined;
          year?: string | null | undefined;
          make?: string | null | undefined;
          model?: string | null | undefined;
          fuel_type?: string | null | undefined;
          vehicle_type?: string | null | undefined;
          color?: string | null | undefined;
          mileage?: number | null | undefined;
          phone_number?: string | null | undefined;
          email?: string | null | undefined;
          lienholder?: string | null | undefined;
          lienholderLogo?: string | null | undefined;
          first_name?: string | null | undefined;
          last_name?: string | null | undefined;
          address_line?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          zip?: string | null | undefined;
          previous_address_line?: string | null | undefined;
          previous_city?: string | null | undefined;
          previous_state?: string | null | undefined;
          previous_zip?: string | null | undefined;
          scheduled_call_datetime?: any | null | undefined;
          scheduled_call_datetime_tz?: string | null | undefined;
          primary_residence_type?: string | null | undefined;
          primary_residence_years?: number | null | undefined;
          primary_residence_months?: number | null | undefined;
          primary_residence_monthly_payment?: number | null | undefined;
          secondary_residence_years?: number | null | undefined;
          secondary_residence_months?: number | null | undefined;
          dob?: any | null | undefined;
          good_through_date?: any | null | undefined;
          employer_name?: string | null | undefined;
          job_title?: string | null | undefined;
          employer_phone_number?: string | null | undefined;
          years_at_job?: number | null | undefined;
          months_at_job?: number | null | undefined;
          salary?: number | null | undefined;
          previous_employer_name?: string | null | undefined;
          previous_job_title?: string | null | undefined;
          previous_employer_phone_number?: string | null | undefined;
          previous_years_at_job?: number | null | undefined;
          previous_months_at_job?: number | null | undefined;
          retail_book_value?: number | null | undefined;
          kbb_retail_mileage_adjustment?: number | null | undefined;
          kbb_retail_option_adjustment?: number | null | undefined;
          book_value?: number | null | undefined;
          kbb_lending_mileage_adjustment?: number | null | undefined;
          kbb_lending_option_adjustment?: number | null | undefined;
          kbb_valuation_date?: string | null | undefined;
          kbb_vehicle_name?: string | null | undefined;
          kbb_trim_name?: string | null | undefined;
          kbb_selected_options?: any | null | undefined;
          credit_score?: number | null | undefined;
          term?: number | null | undefined;
          rate?: number | null | undefined;
          down_payment?: number | null | undefined;
          taxes_fees?: number | null | undefined;
          payoff_includes_sales_tax?: boolean | null | undefined;
          old_lease_payment?: number | null | undefined;
          vehicle_payoff?: number | null | undefined;
        };
      }
    | null
    | undefined;
};

export type TemporaryInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TemporaryInfoQuery = {
  __typename?: 'RootQueryType';
  temporaryInfo?:
    | {
        __typename?: 'temporaryInfo';
        id?: number | null | undefined;
        data: {
          __typename?: 'temporaryData';
          vin?: string | null | undefined;
          year?: string | null | undefined;
          make?: string | null | undefined;
          model?: string | null | undefined;
          fuel_type?: string | null | undefined;
          vehicle_type?: string | null | undefined;
          color?: string | null | undefined;
          mileage?: number | null | undefined;
          phone_number?: string | null | undefined;
          email?: string | null | undefined;
          lienholder?: string | null | undefined;
          lienholderLogo?: string | null | undefined;
          first_name?: string | null | undefined;
          last_name?: string | null | undefined;
          address_line?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          zip?: string | null | undefined;
          previous_address_line?: string | null | undefined;
          previous_city?: string | null | undefined;
          previous_state?: string | null | undefined;
          previous_zip?: string | null | undefined;
          scheduled_call_datetime?: any | null | undefined;
          scheduled_call_datetime_tz?: string | null | undefined;
          primary_residence_type?: string | null | undefined;
          primary_residence_years?: number | null | undefined;
          primary_residence_months?: number | null | undefined;
          primary_residence_monthly_payment?: number | null | undefined;
          secondary_residence_years?: number | null | undefined;
          secondary_residence_months?: number | null | undefined;
          dob?: any | null | undefined;
          good_through_date?: any | null | undefined;
          employer_name?: string | null | undefined;
          job_title?: string | null | undefined;
          employer_phone_number?: string | null | undefined;
          years_at_job?: number | null | undefined;
          months_at_job?: number | null | undefined;
          salary?: number | null | undefined;
          previous_employer_name?: string | null | undefined;
          previous_job_title?: string | null | undefined;
          previous_employer_phone_number?: string | null | undefined;
          previous_years_at_job?: number | null | undefined;
          previous_months_at_job?: number | null | undefined;
          retail_book_value?: number | null | undefined;
          kbb_retail_mileage_adjustment?: number | null | undefined;
          kbb_retail_option_adjustment?: number | null | undefined;
          book_value?: number | null | undefined;
          kbb_lending_mileage_adjustment?: number | null | undefined;
          kbb_lending_option_adjustment?: number | null | undefined;
          kbb_valuation_date?: string | null | undefined;
          kbb_vehicle_name?: string | null | undefined;
          kbb_trim_name?: string | null | undefined;
          kbb_selected_options?: any | null | undefined;
          credit_score?: number | null | undefined;
          term?: number | null | undefined;
          rate?: number | null | undefined;
          down_payment?: number | null | undefined;
          taxes_fees?: number | null | undefined;
          payoff_includes_sales_tax?: boolean | null | undefined;
          old_lease_payment?: number | null | undefined;
          vehicle_payoff?: number | null | undefined;
        };
      }
    | null
    | undefined;
};

export type TemporaryInfoImportMutationVariables = Exact<{
  id: Scalars['ID'];
  transformType: TransformType;
  importType: ImportType;
}>;

export type TemporaryInfoImportMutation = {
  __typename?: 'RootMutationType';
  temporaryInfoImport?: { __typename?: 'deal'; id?: number | null | undefined } | null | undefined;
};

export type OnDealImportedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnDealImportedSubscription = {
  __typename?: 'RootSubscriptionType';
  onDealImported?:
    | { __typename?: 'DealImported'; deal_id: number; first_name: string; last_name: string }
    | null
    | undefined;
};

export type TemporaryRegistrationTagsDealFragment = {
  __typename?: 'deal';
  id?: number | null | undefined;
  state?: string | null | undefined;
  temporary_registration_tags_user?:
    | { __typename?: 'user'; id?: string | null | undefined; name?: string | null | undefined }
    | null
    | undefined;
  customer?:
    | {
        __typename?: 'customer';
        first_name?: string | null | undefined;
        last_name?: string | null | undefined;
        phone_number?: string | null | undefined;
        address?: { __typename?: 'address'; state?: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
  car?:
    | {
        __typename?: 'car';
        id?: number | null | undefined;
        registration_expiration?: any | null | undefined;
      }
    | null
    | undefined;
  closer?:
    | { __typename?: 'user'; id?: string | null | undefined; name?: string | null | undefined }
    | null
    | undefined;
  title_clerk?:
    | { __typename?: 'user'; id?: string | null | undefined; name?: string | null | undefined }
    | null
    | undefined;
  deal_dates?:
    | { __typename?: 'dealDates'; custom_dates?: any | null | undefined }
    | null
    | undefined;
  shipping_labels?:
    | Array<
        | {
            __typename?: 'ShippingLabel';
            tracking_number?: string | null | undefined;
            tracker_url?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  financial_info?:
    | {
        __typename?: 'financialInfo';
        needs_temporary_registration_tags?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type TemporaryRegistrationTagsDealsQueryVariables = Exact<{
  currentPage?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
}>;

export type TemporaryRegistrationTagsDealsQuery = {
  __typename?: 'RootQueryType';
  temporaryRegistrationTagsDeals?:
    | {
        __typename?: 'PaginatedDeals';
        totalRecords?: number | null | undefined;
        deals?:
          | Array<
              | {
                  __typename?: 'deal';
                  id?: number | null | undefined;
                  state?: string | null | undefined;
                  temporary_registration_tags_user?:
                    | {
                        __typename?: 'user';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  customer?:
                    | {
                        __typename?: 'customer';
                        first_name?: string | null | undefined;
                        last_name?: string | null | undefined;
                        phone_number?: string | null | undefined;
                        address?:
                          | { __typename?: 'address'; state?: string | null | undefined }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  car?:
                    | {
                        __typename?: 'car';
                        id?: number | null | undefined;
                        registration_expiration?: any | null | undefined;
                      }
                    | null
                    | undefined;
                  closer?:
                    | {
                        __typename?: 'user';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  title_clerk?:
                    | {
                        __typename?: 'user';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  deal_dates?:
                    | { __typename?: 'dealDates'; custom_dates?: any | null | undefined }
                    | null
                    | undefined;
                  shipping_labels?:
                    | Array<
                        | {
                            __typename?: 'ShippingLabel';
                            tracking_number?: string | null | undefined;
                            tracker_url?: string | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                  financial_info?:
                    | {
                        __typename?: 'financialInfo';
                        needs_temporary_registration_tags?: boolean | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type OnTemporaryRegistrationTagsDealsUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnTemporaryRegistrationTagsDealsUpdatedSubscription = {
  __typename?: 'RootSubscriptionType';
  onTemporaryRegistrationTagsDealsUpdated?:
    | Array<
        | {
            __typename?: 'deal';
            id?: number | null | undefined;
            state?: string | null | undefined;
            temporary_registration_tags_user?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            customer?:
              | {
                  __typename?: 'customer';
                  first_name?: string | null | undefined;
                  last_name?: string | null | undefined;
                  phone_number?: string | null | undefined;
                  address?:
                    | { __typename?: 'address'; state?: string | null | undefined }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            car?:
              | {
                  __typename?: 'car';
                  id?: number | null | undefined;
                  registration_expiration?: any | null | undefined;
                }
              | null
              | undefined;
            closer?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            title_clerk?:
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
            deal_dates?:
              | { __typename?: 'dealDates'; custom_dates?: any | null | undefined }
              | null
              | undefined;
            shipping_labels?:
              | Array<
                  | {
                      __typename?: 'ShippingLabel';
                      tracking_number?: string | null | undefined;
                      tracker_url?: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            financial_info?:
              | {
                  __typename?: 'financialInfo';
                  needs_temporary_registration_tags?: boolean | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetTwilioNumbersQueryVariables = Exact<{ [key: string]: never }>;

export type GetTwilioNumbersQuery = {
  __typename?: 'RootQueryType';
  getTwilioNumbers?:
    | Array<
        | {
            __typename?: 'twilioNumber';
            number?: string | null | undefined;
            friendlyName?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type UserFragmentFragment = {
  __typename?: 'user';
  id?: string | null | undefined;
  email?: string | null | undefined;
  name?: string | null | undefined;
  phone_number?: string | null | undefined;
  twilio_number?: string | null | undefined;
  created_at?: any | null | undefined;
  recruiter_id?: string | null | undefined;
  hours?: string | null | undefined;
  on_vacation?: boolean | null | undefined;
  auto_assign_deals?: boolean | null | undefined;
  overnight_deals?: boolean | null | undefined;
  max_auto_assign_deals?: number | null | undefined;
  auth0_roles?:
    | {
        __typename?: 'UserRoles';
        roles?:
          | Array<
              | {
                  __typename?: 'Auth0Role';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  pods?:
    | Array<
        | {
            __typename?: 'pod';
            id?: number | null | undefined;
            color?: string | null | undefined;
            name?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetUsersQueryVariables = Exact<{
  currentPage?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  podId?: InputMaybe<Scalars['ID']>;
  roleNames?: InputMaybe<Array<InputMaybe<Auth0RoleName>> | InputMaybe<Auth0RoleName>>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  teamType?: InputMaybe<TeamType>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
}>;

export type GetUsersQuery = {
  __typename?: 'RootQueryType';
  getUsers?:
    | {
        __typename?: 'PaginatedUser';
        totalRecords?: number | null | undefined;
        results?:
          | Array<
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  email?: string | null | undefined;
                  name?: string | null | undefined;
                  phone_number?: string | null | undefined;
                  twilio_number?: string | null | undefined;
                  created_at?: any | null | undefined;
                  recruiter_id?: string | null | undefined;
                  hours?: string | null | undefined;
                  on_vacation?: boolean | null | undefined;
                  auto_assign_deals?: boolean | null | undefined;
                  overnight_deals?: boolean | null | undefined;
                  max_auto_assign_deals?: number | null | undefined;
                  auth0_roles?:
                    | {
                        __typename?: 'UserRoles';
                        roles?:
                          | Array<
                              | {
                                  __typename?: 'Auth0Role';
                                  id?: string | null | undefined;
                                  name?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  pods?:
                    | Array<
                        | {
                            __typename?: 'pod';
                            id?: number | null | undefined;
                            color?: string | null | undefined;
                            name?: string | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetUsersForManagementQueryVariables = Exact<{
  currentPage?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  podId?: InputMaybe<Scalars['ID']>;
  roleNames?: InputMaybe<Array<InputMaybe<Auth0RoleName>> | InputMaybe<Auth0RoleName>>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  teamType?: InputMaybe<TeamType>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
}>;

export type GetUsersForManagementQuery = {
  __typename?: 'RootQueryType';
  getUsers?:
    | {
        __typename?: 'PaginatedUser';
        totalRecords?: number | null | undefined;
        results?:
          | Array<
              | {
                  __typename?: 'user';
                  id?: string | null | undefined;
                  email?: string | null | undefined;
                  name?: string | null | undefined;
                  phone_number?: string | null | undefined;
                  twilio_number?: string | null | undefined;
                  created_at?: any | null | undefined;
                  recruiter_id?: string | null | undefined;
                  hours?: string | null | undefined;
                  on_vacation?: boolean | null | undefined;
                  auto_assign_deals?: boolean | null | undefined;
                  overnight_deals?: boolean | null | undefined;
                  max_auto_assign_deals?: number | null | undefined;
                  huntGroups?:
                    | Array<
                        | {
                            __typename?: 'HuntGroup';
                            id?: number | null | undefined;
                            name?: string | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                  auth0_roles?:
                    | {
                        __typename?: 'UserRoles';
                        roles?:
                          | Array<
                              | {
                                  __typename?: 'Auth0Role';
                                  id?: string | null | undefined;
                                  name?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  pods?:
                    | Array<
                        | {
                            __typename?: 'pod';
                            id?: number | null | undefined;
                            color?: string | null | undefined;
                            name?: string | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TitlingUsersQueryVariables = Exact<{
  titling_pod_id?: InputMaybe<Scalars['ID']>;
}>;

export type TitlingUsersQuery = {
  __typename?: 'RootQueryType';
  titlingUsers?:
    | Array<
        | {
            __typename?: 'podUser';
            user_id?: string | null | undefined;
            pod_id?: number | null | undefined;
            team_role?: TeamRole | null | undefined;
            user?:
              | {
                  __typename?: 'baseUser';
                  id?: string | null | undefined;
                  email?: string | null | undefined;
                  name?: string | null | undefined;
                  nickname?: string | null | undefined;
                  deleted_at?: any | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetAvailableUsersAndAllHuntGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvailableUsersAndAllHuntGroupsQuery = {
  __typename?: 'RootQueryType';
  getAvailableUsers?:
    | Array<
        | {
            __typename?: 'getAvailableUsers';
            id?: string | null | undefined;
            email?: string | null | undefined;
            name?: string | null | undefined;
            nickname?: string | null | undefined;
            phone_number?: string | null | undefined;
            twilio_number?: string | null | undefined;
            call_status?: string | null | undefined;
            in_call?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  getAllHuntGroups?:
    | Array<
        | {
            __typename?: 'HuntGroup';
            id?: number | null | undefined;
            name?: string | null | undefined;
            phone_number?: string | null | undefined;
            slug?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type LoggedUserQueryVariables = Exact<{ [key: string]: never }>;

export type LoggedUserQuery = {
  __typename?: 'RootQueryType';
  loggedUser?:
    | {
        __typename?: 'user';
        name?: string | null | undefined;
        call_status?: string | null | undefined;
        can_claim_as_closer?: boolean | null | undefined;
        hours?: string | null | undefined;
        on_vacation?: boolean | null | undefined;
        pods?:
          | Array<
              { __typename?: 'pod'; team_type?: TeamType | null | undefined } | null | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserTitlingPodsQueryVariables = Exact<{ [key: string]: never }>;

export type UserTitlingPodsQuery = {
  __typename?: 'RootQueryType';
  userTitlingPods?:
    | Array<
        | {
            __typename?: 'pod';
            id?: number | null | undefined;
            name?: string | null | undefined;
            color?: string | null | undefined;
            problem_solver?: boolean | null | undefined;
            archived?: boolean | null | undefined;
            us_states_object?:
              | {
                  __typename?: 'usStatesObject';
                  states?: Array<StateAbbreviation | null | undefined> | null | undefined;
                }
              | null
              | undefined;
            pod_users?:
              | Array<
                  | {
                      __typename?: 'podUser';
                      id?: number | null | undefined;
                      user_id?: string | null | undefined;
                      pod_id?: number | null | undefined;
                      team_role?: TeamRole | null | undefined;
                      user?:
                        | {
                            __typename?: 'baseUser';
                            id?: string | null | undefined;
                            name?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type UserPermissionsQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type UserPermissionsQuery = {
  __typename?: 'RootQueryType';
  userPermissions?: Array<string | null | undefined> | null | undefined;
};

export type GetUserTwilioNumberQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserTwilioNumberQuery = {
  __typename?: 'RootQueryType';
  getUserTwilioNumber?: string | null | undefined;
};

export type GetUserCreationDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserCreationDataQuery = {
  __typename?: 'RootQueryType';
  getUserCreationData?:
    | {
        __typename?: 'UserCreationData';
        huntGroups?:
          | Array<{
              __typename?: 'HuntGroup';
              id?: number | null | undefined;
              name?: string | null | undefined;
              slug?: string | null | undefined;
            }>
          | null
          | undefined;
        pods?:
          | Array<{
              __typename?: 'pod';
              id?: number | null | undefined;
              name?: string | null | undefined;
              color?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateUserMutationVariables = Exact<{
  createUserInput: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'RootMutationType';
  createUser?: { __typename?: 'user'; id?: string | null | undefined } | null | undefined;
};

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
  allAuth0RoleIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type UpdateUserMutation = {
  __typename?: 'RootMutationType';
  updateUser?: { __typename?: 'user'; id?: string | null | undefined } | null | undefined;
};

export type UpdateUserSettingsMutationVariables = Exact<{
  id: Scalars['String'];
  hours: Scalars['String'];
  on_vacation: Scalars['Boolean'];
  auto_assign_deals: Scalars['Boolean'];
  overnight_deals: Scalars['Boolean'];
  max_auto_assign_deals: Scalars['Int'];
}>;

export type UpdateUserSettingsMutation = {
  __typename?: 'RootMutationType';
  updateUserSettings?:
    | {
        __typename?: 'user';
        id?: string | null | undefined;
        hours?: string | null | undefined;
        on_vacation?: boolean | null | undefined;
        auto_assign_deals?: boolean | null | undefined;
        overnight_deals?: boolean | null | undefined;
        max_auto_assign_deals?: number | null | undefined;
      }
    | null
    | undefined;
};

export type SoftDeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type SoftDeleteUserMutation = {
  __typename?: 'RootMutationType';
  softDeleteUser?: boolean | null | undefined;
};

export type UpdateUserCallStatusMutationVariables = Exact<{
  status: Scalars['String'];
}>;

export type UpdateUserCallStatusMutation = {
  __typename?: 'RootMutationType';
  updateUserCallStatus?:
    | { __typename?: 'user'; call_status?: string | null | undefined }
    | null
    | undefined;
};

export type SwitchCallStatusSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SwitchCallStatusSubscription = {
  __typename?: 'RootSubscriptionType';
  switchCallStatus?: string | null | undefined;
};

export type SwitchCallStatusIndividualSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SwitchCallStatusIndividualSubscription = {
  __typename?: 'RootSubscriptionType';
  switchCallStatusIndividual?: string | null | undefined;
};

export type GetWaitingCallsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWaitingCallsQuery = {
  __typename?: 'RootQueryType';
  getWaitingCalls?:
    | { __typename?: 'getWaitingCalls'; data?: any | null | undefined }
    | null
    | undefined;
};

export type CallInProgressQueryVariables = Exact<{ [key: string]: never }>;

export type CallInProgressQuery = {
  __typename?: 'RootQueryType';
  callInProgress?:
    | {
        __typename?: 'conference';
        from?: string | null | undefined;
        to?: string | null | undefined;
        customer_name?: string | null | undefined;
        deal_id?: number | null | undefined;
        deal_state?: string | null | undefined;
        deal_type?: string | null | undefined;
        is_thunder?: boolean | null | undefined;
        conference_id?: string | null | undefined;
        agent_user_id?: string | null | undefined;
        hunt_group_slug?: string | null | undefined;
        is_outbound?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type AddParticipantMutationVariables = Exact<{
  hunt_group_slug?: InputMaybe<Scalars['String']>;
  conference_id?: InputMaybe<Scalars['String']>;
  agent_phone?: InputMaybe<Scalars['String']>;
  new_agent_phone?: InputMaybe<Scalars['String']>;
  new_agent_user_id?: InputMaybe<Scalars['String']>;
}>;

export type AddParticipantMutation = {
  __typename?: 'RootMutationType';
  addParticipant?:
    | {
        __typename?: 'resultAddParticipant';
        status?: string | null | undefined;
        call_already_answered?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type OnWaitingQueueSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnWaitingQueueSubscription = {
  __typename?: 'RootSubscriptionType';
  onWaitingQueue?:
    | {
        __typename?: 'onWaitingQueue';
        hunt_group_slug?: string | null | undefined;
        data?:
          | Array<
              | {
                  __typename?: 'waitingCall';
                  from?: string | null | undefined;
                  conference_id?: string | null | undefined;
                  phone_number?: string | null | undefined;
                  started_at?: string | null | undefined;
                  deal_id?: number | null | undefined;
                  deal_state?: string | null | undefined;
                  is_thunder?: boolean | null | undefined;
                  customer_name?: string | null | undefined;
                  hunt_group_slug?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AssignmentUsersFragmentFragmentDoc = gql`
  fragment AssignmentUsersFragment on deal {
    pod_id
    pod {
      id
      name
      color
    }
    setter_id
    setter {
      id
      name
      pods {
        id
      }
    }
    closer_id
    closer {
      id
      name
      pods {
        id
      }
    }
    closer2_id
    closer2 {
      id
      name
      pods {
        id
      }
    }
    structuring_manager_id
    structuring_manager {
      id
      name
      pods {
        id
      }
    }
    funding_clerk_id
    funding_clerk {
      id
      name
      pods {
        id
      }
    }
  }
`;
export const TitleUsersFragmentFragmentDoc = gql`
  fragment TitleUsersFragment on deal {
    titling_pod_id
    titling_pod {
      id
      color
      name
      leads {
        id
        name
      }
    }
    title_clerk_id
    title_clerk {
      id
      name
      deleted_at
    }
    title_clerk2_id
    title_clerk2 {
      id
      name
      deleted_at
    }
  }
`;
export const PersonCreditAppOnDealFragmentDoc = gql`
  fragment PersonCreditAppOnDeal on customer {
    id
    dob
    ssn
    email
    no_email
    first_name
    middle_name
    last_name
    name_suffix
    home_phone_number
    phone_number
    marital_status
    has_same_address_as_cobuyer
    address {
      id
      address_type
      address_line
      address_line_2
      city
      county
      state
      zip
      monthly_payment
      years_at_home
      months_at_home
      residence_type
    }
    prev_address {
      id
      address_type
      address_line
      address_line_2
      city
      county
      state
      zip
      years_at_home
      months_at_home
    }
    employment {
      id
      status
      name
      job_title
      phone_number
      years_at_job
      months_at_job
      gross_income
      pay_frequency
    }
    prev_employment {
      id
      name
      job_title
      years_at_job
      months_at_job
    }
    proof_of_insurance {
      id
      company_name
      policy_number
      expires
    }
  }
`;
export const ContactPersonFragmentDoc = gql`
  fragment ContactPerson on dealContact {
    id
    first_name
    middle_name
    last_name
    phone_number
    email
  }
`;
export const ReferralSourceFragmentFragmentDoc = gql`
  fragment ReferralSourceFragment on referralSource {
    id
    source_name
    other_source_description
  }
`;
export const DealFragmentFragmentDoc = gql`
  fragment DealFragment on deal {
    __typename
    id
    type
    source
    state
    has_problem
    completion_date_utc
    completion_date_tz
    missing_required_external_documents
    paperwork_type
    direct_pay_state
    request_boot
    boot_reason
    r1_jacket_id
    r1_jacket_id_created_date_utc
    document_progress_status
    r1_contract_validation_errors_object {
      errors
    }
    r1_contract_validation_warnings_object {
      warnings
    }
    r1_contract_manually_validated
    signing_on_com
    pod_id
    pod {
      id
      name
      color
    }
    setter_id
    setter {
      id
      name
      pods {
        id
      }
    }
    closer_id
    closer {
      id
      name
      pods {
        id
      }
    }
    closer2_id
    closer2 {
      id
      name
      pods {
        id
      }
    }
    funding_clerk_id
    funding_clerk {
      id
      name
      pods {
        id
      }
    }
    titling_pod_id
    titling_pod {
      id
      color
      name
      leads {
        id
        name
      }
    }
    title_clerk_id
    title_clerk {
      id
      name
      deleted_at
    }
    title_clerk2_id
    title_clerk2 {
      id
      name
      deleted_at
    }
    car {
      id
      license_plate_number
      license_plate_state
      vin
      year
      make
      model
      mileage
      color
      book_value
      retail_book_value
      jdp_adjusted_clean_trade
      jdp_adjusted_clean_retail
      vehicle_type
      fuel_type
      kbb_trim_name
      mmr
      registration_expiration
      odometer_status
      kbb_selected_options_object {
        selected_options {
          id
          name
          type
          lendingOptionPrice
          retailOptionPrice
          removed
        }
      }
      payoff {
        id
        lienholder_name
        lienholder_slug
        lender_name
        account_number
        vehicle_payoff
        good_through_date
        lease_term
        remaining_payments
        msrp
        residual_percentage
        sales_price
        cap_reduction
        money_down
        money_factor
        termination_fees
        cap_cost
        residual_amount
        estimated_payoff
        old_lease_payment
        sales_tax_from_payoff
        payoff_includes_sales_tax
        sales_tax_from_payoff_entered_manually
        verification_status
        next_payment_date
        user_entered_total_payoff
      }
    }
    customer {
      id
      first_name
      middle_name
      last_name
      name_suffix
      phone_number
      home_phone_number
      email
      no_email
      ssn
      dob
      address {
        id
        address_line
        address_line_2
        zip
        city
        state
        county
        address_type
        residence_type
        years_at_home
        months_at_home
        monthly_payment
        moved_states
      }
      prev_address {
        id
        address_line
        address_line_2
        zip
        city
        state
        county
        address_type
        residence_type
        years_at_home
        months_at_home
        monthly_payment
        moved_states
      }
      employment {
        id
        status
        name
        job_title
        phone_number
        years_at_job
        months_at_job
        gross_income
        pay_frequency
      }
      prev_employment {
        id
        status
        name
        job_title
        phone_number
        years_at_job
        months_at_job
        gross_income
        pay_frequency
      }
      proof_of_insurance {
        id
        company_name
        policy_number
        expires
      }
      has_same_address_as_cobuyer
      marital_status
      auth0_id
    }
    cobuyer {
      id
      first_name
      middle_name
      last_name
      name_suffix
      phone_number
      home_phone_number
      email
      no_email
      ssn
      dob
      address {
        id
        address_line
        address_line_2
        zip
        city
        state
        county
        address_type
        residence_type
        years_at_home
        months_at_home
        monthly_payment
        moved_states
      }
      prev_address {
        id
        address_line
        address_line_2
        zip
        city
        state
        county
        address_type
        residence_type
        years_at_home
        months_at_home
        monthly_payment
        moved_states
      }
      employment {
        id
        status
        name
        job_title
        phone_number
        years_at_job
        months_at_job
        gross_income
        pay_frequency
      }
      prev_employment {
        id
        status
        name
        job_title
        phone_number
        years_at_job
        months_at_job
        gross_income
        pay_frequency
      }
      proof_of_insurance {
        id
        company_name
        policy_number
        expires
      }
      has_same_address_as_cobuyer
      marital_status
      auth0_id
      relation_to_buyer
    }
    contact {
      id
      deal_id
      relation_to_buyer
      first_name
      middle_name
      last_name
      phone_number
    }
    second_contact {
      id
      deal_id
      relation_to_buyer
      first_name
      middle_name
      last_name
      phone_number
    }
    financial_info {
      id
      deal_id
      money_down
      base_tax_amount
      warranty_tax_amount
      title_fee
      new_registration_fee
      registration_transfer_fee
      doc_fee
      sales_tax_rate
      vsc_price
      vsc_cost
      gap_price
      gap_cost
      days_to_payment
      first_payment_date
      sell_rate
      buy_rate
      term
      bank
      vsc_type
      vsc_term
      payment
      amount_financed
      profit
      setter_commission
      closer_commission
      option_type
      plate_transfer
      pen_vsc_session_id
      pen_vsc_rate_id
      pen_vsc_form_id
      pen_gap_session_id
      pen_gap_rate_id
      pen_gap_form_id
      title_registration_option
      processor
      tt_transaction_id
      buyer_not_lessee
      new_lienholder {
        name
        r1_fsid
        address
        city
        state
        zip
      }
    }
    financial_info_acquisition {
      id
      deal_id
      kbb_lending
      appraised_value
      max_cash_to_customer
      max_total_cost
      cash_to_customer
      is_approved
      auction_fees
      transport_cost
      total_cost
      auction_id
      gross_profit
      sell_price
      overriding_user_id
    }
    condition_report {
      id
      deal_id
      accidents
      tires
      exterior
      interior
      smoked_in
      lights_on_dash
      overall_condition
      score
    }
    document_info {
      id
      adobe_agreement_id
    }
    deal_states {
      id
      deal_id
      state
      updated_date_utc
      user_id
      user {
        id
        name
      }
    }
    deal_dates {
      id
      deal_id
      dates
      custom_dates
    }
    notifications {
      id
      message
      seen
      customer_id
      deal_contact_id
    }
    referral_source {
      id
      source_name
      other_source_description
    }
  }
`;
export const LenderFragmentFragmentDoc = gql`
  fragment LenderFragment on Lender {
    id
    name
  }
`;
export const PaymentOptionFragmentFragmentDoc = gql`
  fragment PaymentOptionFragment on paymentOptionsGQLTypes {
    name
    amountFinanced
    buyPayment
    sellPayment
    profit
    setterCommission
    closerCommission
    reserve
  }
`;
export const PayoffFragmentFragmentDoc = gql`
  fragment PayoffFragment on payoff {
    id
    lienholder_name
    lienholder_slug
    lender_name
    account_number
    vehicle_payoff
    sales_tax_from_payoff
    good_through_date
    lease_term
    remaining_payments
    msrp
    residual_percentage
    sales_price
    cap_reduction
    money_down
    money_factor
    termination_fees
    cap_cost
    residual_amount
    estimated_payoff
    old_lease_payment
    payoff_includes_sales_tax
    sales_tax_from_payoff_entered_manually
    verification_status
    next_payment_date
    user_entered_total_payoff
    maturity_date
  }
`;
export const PayoffRequestPopulatedFragmentFragmentDoc = gql`
  fragment PayoffRequestPopulatedFragment on payoffRequestPopulated {
    id
    created_at
    deal_id
    deal {
      id
      closer {
        id
        name
      }
      car {
        vehicle_type
        fuel_type
        year
        make
        model
        vin
        mileage
        payoff {
          account_number
          lienholder_name
          lienholder_slug
          lender_name
        }
      }
      customer {
        id
        first_name
        last_name
        ssn
        dob
        phone_number
        home_phone_number
        address {
          city
          county
          zip
          state
        }
      }
      financial_info {
        buyer_not_lessee
      }
      closer_id
      created_at
      notifications {
        id
        message
        created_at
        seen
      }
    }
    temporary_info_id
    temporary_info {
      id
      data {
        state
        city
        county
        year
        make
        model
        vin
        mileage
        lh_account_number
        lienholder
        first_name
        last_name
        dob
        phone_number
        zip
        ssn
        vehicle_type
        fuel_type
      }
    }
    status
    vehicle_payoff
    sales_tax
    completed_date
    good_through_date
    created_at
    fail_reason
    payoff_clerk_id
    payoff_clerk {
      id
      name
    }
    needs_payoff_documents
    payoff_documents_uploaded
  }
`;
export const PrequalificationRequestFragmentFragmentDoc = gql`
  fragment PrequalificationRequestFragment on PrequalificationRequest {
    first_name
    last_name
    ssn
    zip
    gross_income
    pay_frequency
    vehicle_payoff
    lending_book_value
    money_down
    income_sources
  }
`;
export const PrequalificationFragmentFragmentDoc = gql`
  fragment PrequalificationFragment on prequalification {
    id
    input {
      ...PrequalificationRequestFragment
    }
    output {
      errors
    }
    credit_score
    ltv
    payment_to_income
    customer_id
    credit_score_tier
  }
  ${PrequalificationRequestFragmentFragmentDoc}
`;
export const PrequalificationCreditReportNameFragmentFragmentDoc = gql`
  fragment PrequalificationCreditReportNameFragment on PrequalificationCreditReportName {
    firstName
    middleName
    surname
    secondSurname
    generationCode
  }
`;
export const PrequalificationCreditReportTradelineFragmentFragmentDoc = gql`
  fragment PrequalificationCreditReportTradelineFragment on PrequalificationCreditReportTradeline {
    subscriberName
    accountNumber
    kob
    status
    accountType
    openDate
    balanceDate
    amount1
    amount1Qualifier
    amount2
    amount2Qualifier
    amountPastDue
    balanceAmount
    openOrClosed
    ecoa
    monthlyPaymentAmount
    monthsHistory
    terms
    delinquency30Days
    delinquency60Days
    delinquency90to180Days
    paymentHistory
  }
`;
export const PrequalificationCreditReportFragmentFragmentDoc = gql`
  fragment PrequalificationCreditReportFragment on PrequalificationCreditReport {
    names {
      customerName {
        ...PrequalificationCreditReportNameFragment
      }
      aliases {
        ...PrequalificationCreditReportNameFragment
      }
    }
    addresses {
      city
      state
      zipCode
      streetName
      streetPrefix
      streetSuffix
      firstReportedDate
    }
    employments {
      addressExtraLine
      addressFirstLine
      addressSecondLine
      firstReportedDate
      lastUpdatedDate
      name
      source
      zipCode
    }
    informationalMessages
    scoreFactors {
      code
      description
    }
    tradelines {
      revolving {
        ...PrequalificationCreditReportTradelineFragment
      }
      installment {
        ...PrequalificationCreditReportTradelineFragment
      }
      unknown {
        ...PrequalificationCreditReportTradelineFragment
      }
    }
  }
  ${PrequalificationCreditReportNameFragmentFragmentDoc}
  ${PrequalificationCreditReportTradelineFragmentFragmentDoc}
`;
export const RateFragmentFragmentDoc = gql`
  fragment RateFragment on rate {
    form_id
    session_id
    coverage_name
    term_miles
    term_months
    deductible {
      rate_id
      amount
      dealer_cost
      deductible_type
      f_i_markup
      max_retail_price
      min_retail_price
      retail_price
    }
  }
`;
export const StructuringFollowUpFragmentFragmentDoc = gql`
  fragment StructuringFollowUpFragment on StructuringFollowUp {
    id
    created_at
    status
    creator_id
    notes
  }
`;
export const StructuringFollowUpDealFragmentFragmentDoc = gql`
  fragment StructuringFollowUpDealFragment on deal {
    id
    state
    type
    source
    customer {
      first_name
      last_name
      phone_number
    }
    setter {
      name
    }
    closer_id
    closer {
      id
      name
    }
    structuring_manager_id
    structuring_manager {
      id
      name
    }
    structuring_follow_up {
      ...StructuringFollowUpFragment
    }
    tags {
      id
      color
      display_name
      is_dashboard_visible
    }
    notifications {
      message
      seen
    }
  }
  ${StructuringFollowUpFragmentFragmentDoc}
`;
export const TemporaryInfoFragmentFragmentDoc = gql`
  fragment TemporaryInfoFragment on temporaryInfo {
    id
    data {
      vin
      year
      make
      model
      fuel_type
      vehicle_type
      color
      mileage
      phone_number
      email
      lienholder
      lienholderLogo
      first_name
      last_name
      address_line
      city
      state
      zip
      previous_address_line
      previous_city
      previous_state
      previous_zip
      scheduled_call_datetime
      scheduled_call_datetime_tz
      primary_residence_type
      primary_residence_years
      primary_residence_months
      primary_residence_monthly_payment
      secondary_residence_years
      secondary_residence_months
      dob
      good_through_date
      employer_name
      job_title
      employer_phone_number
      years_at_job
      months_at_job
      salary
      previous_employer_name
      previous_job_title
      previous_employer_phone_number
      previous_years_at_job
      previous_months_at_job
      retail_book_value
      kbb_retail_mileage_adjustment
      kbb_retail_option_adjustment
      book_value
      kbb_lending_mileage_adjustment
      kbb_lending_option_adjustment
      kbb_valuation_date
      kbb_vehicle_name
      kbb_trim_name
      kbb_selected_options
      credit_score
      term
      rate
      down_payment
      taxes_fees
      payoff_includes_sales_tax
      old_lease_payment
      vehicle_payoff
    }
  }
`;
export const TemporaryRegistrationTagsDealFragmentDoc = gql`
  fragment TemporaryRegistrationTagsDeal on deal {
    id
    state
    temporary_registration_tags_user {
      id
      name
    }
    customer {
      first_name
      last_name
      phone_number
      address {
        state
      }
    }
    car {
      id
      registration_expiration
    }
    closer {
      id
      name
    }
    title_clerk {
      id
      name
    }
    deal_dates {
      custom_dates
    }
    shipping_labels {
      tracking_number
      tracker_url
    }
    financial_info {
      needs_temporary_registration_tags
    }
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on user {
    id
    email
    name
    phone_number
    twilio_number
    created_at
    recruiter_id
    auth0_roles {
      roles {
        id
        name
      }
    }
    pods {
      id
      color
      name
    }
    hours
    on_vacation
    auto_assign_deals
    overnight_deals
    max_auto_assign_deals
  }
`;
export const AcquisitionOfferDocument = gql`
  query acquisitionOffer(
    $accidents: accidentsEnumType!
    $mileage: Int!
    $mmr_value: Float!
    $book_value: Int!
    $vehicle_type: String
    $lienholder: String
    $make: String
  ) {
    acquisitionOffer(
      accidents: $accidents
      mileage: $mileage
      mmr_value: $mmr_value
      book_value: $book_value
      vehicle_type: $vehicle_type
      lienholder: $lienholder
      make: $make
    ) {
      offer
      appraisedValue
    }
  }
`;

/**
 * __useAcquisitionOfferQuery__
 *
 * To run a query within a React component, call `useAcquisitionOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcquisitionOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcquisitionOfferQuery({
 *   variables: {
 *      accidents: // value for 'accidents'
 *      mileage: // value for 'mileage'
 *      mmr_value: // value for 'mmr_value'
 *      book_value: // value for 'book_value'
 *      vehicle_type: // value for 'vehicle_type'
 *      lienholder: // value for 'lienholder'
 *      make: // value for 'make'
 *   },
 * });
 */
export function useAcquisitionOfferQuery(
  baseOptions: Apollo.QueryHookOptions<AcquisitionOfferQuery, AcquisitionOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AcquisitionOfferQuery, AcquisitionOfferQueryVariables>(
    AcquisitionOfferDocument,
    options,
  );
}
export function useAcquisitionOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AcquisitionOfferQuery, AcquisitionOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AcquisitionOfferQuery, AcquisitionOfferQueryVariables>(
    AcquisitionOfferDocument,
    options,
  );
}
export type AcquisitionOfferQueryHookResult = ReturnType<typeof useAcquisitionOfferQuery>;
export type AcquisitionOfferLazyQueryHookResult = ReturnType<typeof useAcquisitionOfferLazyQuery>;
export type AcquisitionOfferQueryResult = Apollo.QueryResult<
  AcquisitionOfferQuery,
  AcquisitionOfferQueryVariables
>;
export const AddressDetailDocument = gql`
  query addressDetail($address: String, $zipCode: String) {
    addressDetail(address: $address, zipCode: $zipCode) {
      city
      county
      state
    }
  }
`;

/**
 * __useAddressDetailQuery__
 *
 * To run a query within a React component, call `useAddressDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressDetailQuery({
 *   variables: {
 *      address: // value for 'address'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useAddressDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<AddressDetailQuery, AddressDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressDetailQuery, AddressDetailQueryVariables>(
    AddressDetailDocument,
    options,
  );
}
export function useAddressDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AddressDetailQuery, AddressDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressDetailQuery, AddressDetailQueryVariables>(
    AddressDetailDocument,
    options,
  );
}
export type AddressDetailQueryHookResult = ReturnType<typeof useAddressDetailQuery>;
export type AddressDetailLazyQueryHookResult = ReturnType<typeof useAddressDetailLazyQuery>;
export type AddressDetailQueryResult = Apollo.QueryResult<
  AddressDetailQuery,
  AddressDetailQueryVariables
>;
export const CreateArticleDocument = gql`
  mutation createArticle($article: articleInput) {
    createArticle(article: $article) {
      id
    }
  }
`;
export type CreateArticleMutationFn = Apollo.MutationFunction<
  CreateArticleMutation,
  CreateArticleMutationVariables
>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      article: // value for 'article'
 *   },
 * });
 */
export function useCreateArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(
    CreateArticleDocument,
    options,
  );
}
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleMutation,
  CreateArticleMutationVariables
>;
export const ArticlesDocument = gql`
  query articles {
    articles {
      id
      title
      headline
      url
      com_visible
      created_at
      thumbnail_key
      thumbnail
    }
  }
`;

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
}
export function useArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
}
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
export const DeleteArticleDocument = gql`
  mutation deleteArticle($id: ID!) {
    deleteArticle(id: $id) {
      id
    }
  }
`;
export type DeleteArticleMutationFn = Apollo.MutationFunction<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>;

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(
    DeleteArticleDocument,
    options,
  );
}
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = Apollo.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>;
export const UpdateArticleDocument = gql`
  mutation updateArticle($article: articleInput) {
    updateArticle(article: $article) {
      id
      title
      headline
      url
      com_visible
      created_at
      thumbnail_key
      thumbnail
    }
  }
`;
export type UpdateArticleMutationFn = Apollo.MutationFunction<
  UpdateArticleMutation,
  UpdateArticleMutationVariables
>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      article: // value for 'article'
 *   },
 * });
 */
export function useUpdateArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(
    UpdateArticleDocument,
    options,
  );
}
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleMutation,
  UpdateArticleMutationVariables
>;
export const AuctionsDocument = gql`
  query auctions {
    auctions {
      id
      name
      address_line
      zip
      state
    }
  }
`;

/**
 * __useAuctionsQuery__
 *
 * To run a query within a React component, call `useAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<AuctionsQuery, AuctionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuctionsQuery, AuctionsQueryVariables>(AuctionsDocument, options);
}
export function useAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuctionsQuery, AuctionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuctionsQuery, AuctionsQueryVariables>(AuctionsDocument, options);
}
export type AuctionsQueryHookResult = ReturnType<typeof useAuctionsQuery>;
export type AuctionsLazyQueryHookResult = ReturnType<typeof useAuctionsLazyQuery>;
export type AuctionsQueryResult = Apollo.QueryResult<AuctionsQuery, AuctionsQueryVariables>;
export const AuctionUpsertDocument = gql`
  mutation auctionUpsert($auction: auctionInput) {
    auctionUpsert(auction: $auction) {
      id
    }
  }
`;
export type AuctionUpsertMutationFn = Apollo.MutationFunction<
  AuctionUpsertMutation,
  AuctionUpsertMutationVariables
>;

/**
 * __useAuctionUpsertMutation__
 *
 * To run a mutation, you first call `useAuctionUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuctionUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [auctionUpsertMutation, { data, loading, error }] = useAuctionUpsertMutation({
 *   variables: {
 *      auction: // value for 'auction'
 *   },
 * });
 */
export function useAuctionUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<AuctionUpsertMutation, AuctionUpsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuctionUpsertMutation, AuctionUpsertMutationVariables>(
    AuctionUpsertDocument,
    options,
  );
}
export type AuctionUpsertMutationHookResult = ReturnType<typeof useAuctionUpsertMutation>;
export type AuctionUpsertMutationResult = Apollo.MutationResult<AuctionUpsertMutation>;
export type AuctionUpsertMutationOptions = Apollo.BaseMutationOptions<
  AuctionUpsertMutation,
  AuctionUpsertMutationVariables
>;
export const AuctionDistancesDocument = gql`
  query auctionDistances($data: auctionDistancesInput) {
    auctionDistances(data: $data) {
      name
      transport_cost
      id
    }
  }
`;

/**
 * __useAuctionDistancesQuery__
 *
 * To run a query within a React component, call `useAuctionDistancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionDistancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionDistancesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAuctionDistancesQuery(
  baseOptions?: Apollo.QueryHookOptions<AuctionDistancesQuery, AuctionDistancesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuctionDistancesQuery, AuctionDistancesQueryVariables>(
    AuctionDistancesDocument,
    options,
  );
}
export function useAuctionDistancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuctionDistancesQuery, AuctionDistancesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuctionDistancesQuery, AuctionDistancesQueryVariables>(
    AuctionDistancesDocument,
    options,
  );
}
export type AuctionDistancesQueryHookResult = ReturnType<typeof useAuctionDistancesQuery>;
export type AuctionDistancesLazyQueryHookResult = ReturnType<typeof useAuctionDistancesLazyQuery>;
export type AuctionDistancesQueryResult = Apollo.QueryResult<
  AuctionDistancesQuery,
  AuctionDistancesQueryVariables
>;
export const AuctionAverageTransportCostDocument = gql`
  query auctionAverageTransportCost($id: ID!, $customerAddress: String!) {
    auctionAverageTransportCost(id: $id, customerAddress: $customerAddress)
  }
`;

/**
 * __useAuctionAverageTransportCostQuery__
 *
 * To run a query within a React component, call `useAuctionAverageTransportCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionAverageTransportCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionAverageTransportCostQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerAddress: // value for 'customerAddress'
 *   },
 * });
 */
export function useAuctionAverageTransportCostQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuctionAverageTransportCostQuery,
    AuctionAverageTransportCostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AuctionAverageTransportCostQuery,
    AuctionAverageTransportCostQueryVariables
  >(AuctionAverageTransportCostDocument, options);
}
export function useAuctionAverageTransportCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuctionAverageTransportCostQuery,
    AuctionAverageTransportCostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AuctionAverageTransportCostQuery,
    AuctionAverageTransportCostQueryVariables
  >(AuctionAverageTransportCostDocument, options);
}
export type AuctionAverageTransportCostQueryHookResult = ReturnType<
  typeof useAuctionAverageTransportCostQuery
>;
export type AuctionAverageTransportCostLazyQueryHookResult = ReturnType<
  typeof useAuctionAverageTransportCostLazyQuery
>;
export type AuctionAverageTransportCostQueryResult = Apollo.QueryResult<
  AuctionAverageTransportCostQuery,
  AuctionAverageTransportCostQueryVariables
>;
export const GetAuth0RolesDocument = gql`
  query getAuth0Roles {
    getAuth0Roles {
      id
      name
    }
  }
`;

/**
 * __useGetAuth0RolesQuery__
 *
 * To run a query within a React component, call `useGetAuth0RolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuth0RolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuth0RolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuth0RolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAuth0RolesQuery, GetAuth0RolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuth0RolesQuery, GetAuth0RolesQueryVariables>(
    GetAuth0RolesDocument,
    options,
  );
}
export function useGetAuth0RolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAuth0RolesQuery, GetAuth0RolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuth0RolesQuery, GetAuth0RolesQueryVariables>(
    GetAuth0RolesDocument,
    options,
  );
}
export type GetAuth0RolesQueryHookResult = ReturnType<typeof useGetAuth0RolesQuery>;
export type GetAuth0RolesLazyQueryHookResult = ReturnType<typeof useGetAuth0RolesLazyQuery>;
export type GetAuth0RolesQueryResult = Apollo.QueryResult<
  GetAuth0RolesQuery,
  GetAuth0RolesQueryVariables
>;
export const BanksDocument = gql`
  query banks($state: String, $county: String, $active: Boolean, $selectedBank: String) {
    banks(state: $state, county: $county, active: $active, selectedBank: $selectedBank) {
      id
      name
      r1_fsid
      active
      display_name
      reserve_structure {
        id
        bank_id
        rate_adj_min
        rate_adj_max
        flat_percentage
        term_min
        term_max
      }
      bank_markups {
        term_min
        term_max
        max_markup
      }
    }
  }
`;

/**
 * __useBanksQuery__
 *
 * To run a query within a React component, call `useBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBanksQuery({
 *   variables: {
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      active: // value for 'active'
 *      selectedBank: // value for 'selectedBank'
 *   },
 * });
 */
export function useBanksQuery(
  baseOptions?: Apollo.QueryHookOptions<BanksQuery, BanksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BanksQuery, BanksQueryVariables>(BanksDocument, options);
}
export function useBanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BanksQuery, BanksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BanksQuery, BanksQueryVariables>(BanksDocument, options);
}
export type BanksQueryHookResult = ReturnType<typeof useBanksQuery>;
export type BanksLazyQueryHookResult = ReturnType<typeof useBanksLazyQuery>;
export type BanksQueryResult = Apollo.QueryResult<BanksQuery, BanksQueryVariables>;
export const BankInfoDocument = gql`
  query bankInfo($deal_id: ID!) {
    bankInfo(deal_id: $deal_id) {
      name
      address
      city
      state
      zip
      phone
      r1_fsid
    }
  }
`;

/**
 * __useBankInfoQuery__
 *
 * To run a query within a React component, call `useBankInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankInfoQuery({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *   },
 * });
 */
export function useBankInfoQuery(
  baseOptions: Apollo.QueryHookOptions<BankInfoQuery, BankInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BankInfoQuery, BankInfoQueryVariables>(BankInfoDocument, options);
}
export function useBankInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankInfoQuery, BankInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BankInfoQuery, BankInfoQueryVariables>(BankInfoDocument, options);
}
export type BankInfoQueryHookResult = ReturnType<typeof useBankInfoQuery>;
export type BankInfoLazyQueryHookResult = ReturnType<typeof useBankInfoLazyQuery>;
export type BankInfoQueryResult = Apollo.QueryResult<BankInfoQuery, BankInfoQueryVariables>;
export const CallOutDocument = gql`
  mutation callOut($calledNumber: String!, $id: ID) {
    callOut(calledNumber: $calledNumber, id: $id) {
      calledNumber
      id
    }
  }
`;
export type CallOutMutationFn = Apollo.MutationFunction<CallOutMutation, CallOutMutationVariables>;

/**
 * __useCallOutMutation__
 *
 * To run a mutation, you first call `useCallOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callOutMutation, { data, loading, error }] = useCallOutMutation({
 *   variables: {
 *      calledNumber: // value for 'calledNumber'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCallOutMutation(
  baseOptions?: Apollo.MutationHookOptions<CallOutMutation, CallOutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CallOutMutation, CallOutMutationVariables>(CallOutDocument, options);
}
export type CallOutMutationHookResult = ReturnType<typeof useCallOutMutation>;
export type CallOutMutationResult = Apollo.MutationResult<CallOutMutation>;
export type CallOutMutationOptions = Apollo.BaseMutationOptions<
  CallOutMutation,
  CallOutMutationVariables
>;
export const GetUserCallHistoryDocument = gql`
  query getUserCallHistory($items_per_page: Int!, $page: Int!) {
    getUserCallHistory(items_per_page: $items_per_page, page: $page) {
      calls {
        id
        deal {
          id
          state
          customer {
            id
            first_name
            last_name
            phone_number
          }
          cobuyer {
            id
            first_name
            last_name
            phone_number
          }
          contact {
            id
            first_name
            last_name
            phone_number
          }
          second_contact {
            id
            first_name
            last_name
            phone_number
          }
        }
        callStatus
        direction
        twilioNumber
        fromNumber
        calledNumber
        user_id
        recordingSid
        recordingUrl
        outcome
        calledCity
        calledState
        created_at
      }
      totalRecords
    }
  }
`;

/**
 * __useGetUserCallHistoryQuery__
 *
 * To run a query within a React component, call `useGetUserCallHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCallHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCallHistoryQuery({
 *   variables: {
 *      items_per_page: // value for 'items_per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetUserCallHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserCallHistoryQuery, GetUserCallHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserCallHistoryQuery, GetUserCallHistoryQueryVariables>(
    GetUserCallHistoryDocument,
    options,
  );
}
export function useGetUserCallHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCallHistoryQuery,
    GetUserCallHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserCallHistoryQuery, GetUserCallHistoryQueryVariables>(
    GetUserCallHistoryDocument,
    options,
  );
}
export type GetUserCallHistoryQueryHookResult = ReturnType<typeof useGetUserCallHistoryQuery>;
export type GetUserCallHistoryLazyQueryHookResult = ReturnType<
  typeof useGetUserCallHistoryLazyQuery
>;
export type GetUserCallHistoryQueryResult = Apollo.QueryResult<
  GetUserCallHistoryQuery,
  GetUserCallHistoryQueryVariables
>;
export const HangUpCallDocument = gql`
  mutation hangUpCall($conference_id: String!) {
    hangUpCall(conference_id: $conference_id)
  }
`;
export type HangUpCallMutationFn = Apollo.MutationFunction<
  HangUpCallMutation,
  HangUpCallMutationVariables
>;

/**
 * __useHangUpCallMutation__
 *
 * To run a mutation, you first call `useHangUpCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHangUpCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hangUpCallMutation, { data, loading, error }] = useHangUpCallMutation({
 *   variables: {
 *      conference_id: // value for 'conference_id'
 *   },
 * });
 */
export function useHangUpCallMutation(
  baseOptions?: Apollo.MutationHookOptions<HangUpCallMutation, HangUpCallMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HangUpCallMutation, HangUpCallMutationVariables>(
    HangUpCallDocument,
    options,
  );
}
export type HangUpCallMutationHookResult = ReturnType<typeof useHangUpCallMutation>;
export type HangUpCallMutationResult = Apollo.MutationResult<HangUpCallMutation>;
export type HangUpCallMutationOptions = Apollo.BaseMutationOptions<
  HangUpCallMutation,
  HangUpCallMutationVariables
>;
export const SaveNewCustomerCallOutcomeDocument = gql`
  mutation saveNewCustomerCallOutcome(
    $call_id: String!
    $outcome: String
    $outcome_description: String
  ) {
    saveNewCustomerCallOutcome(
      call_id: $call_id
      outcome: $outcome
      outcome_description: $outcome_description
    ) {
      id
    }
  }
`;
export type SaveNewCustomerCallOutcomeMutationFn = Apollo.MutationFunction<
  SaveNewCustomerCallOutcomeMutation,
  SaveNewCustomerCallOutcomeMutationVariables
>;

/**
 * __useSaveNewCustomerCallOutcomeMutation__
 *
 * To run a mutation, you first call `useSaveNewCustomerCallOutcomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNewCustomerCallOutcomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNewCustomerCallOutcomeMutation, { data, loading, error }] = useSaveNewCustomerCallOutcomeMutation({
 *   variables: {
 *      call_id: // value for 'call_id'
 *      outcome: // value for 'outcome'
 *      outcome_description: // value for 'outcome_description'
 *   },
 * });
 */
export function useSaveNewCustomerCallOutcomeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveNewCustomerCallOutcomeMutation,
    SaveNewCustomerCallOutcomeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveNewCustomerCallOutcomeMutation,
    SaveNewCustomerCallOutcomeMutationVariables
  >(SaveNewCustomerCallOutcomeDocument, options);
}
export type SaveNewCustomerCallOutcomeMutationHookResult = ReturnType<
  typeof useSaveNewCustomerCallOutcomeMutation
>;
export type SaveNewCustomerCallOutcomeMutationResult =
  Apollo.MutationResult<SaveNewCustomerCallOutcomeMutation>;
export type SaveNewCustomerCallOutcomeMutationOptions = Apollo.BaseMutationOptions<
  SaveNewCustomerCallOutcomeMutation,
  SaveNewCustomerCallOutcomeMutationVariables
>;
export const CarDetailVinDocument = gql`
  query carDetailVin($vin: String) {
    carDetailVin(vin: $vin) {
      year
      make
      model
      vehicle_type
      fuel_type
    }
  }
`;

/**
 * __useCarDetailVinQuery__
 *
 * To run a query within a React component, call `useCarDetailVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarDetailVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarDetailVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useCarDetailVinQuery(
  baseOptions?: Apollo.QueryHookOptions<CarDetailVinQuery, CarDetailVinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarDetailVinQuery, CarDetailVinQueryVariables>(
    CarDetailVinDocument,
    options,
  );
}
export function useCarDetailVinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CarDetailVinQuery, CarDetailVinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CarDetailVinQuery, CarDetailVinQueryVariables>(
    CarDetailVinDocument,
    options,
  );
}
export type CarDetailVinQueryHookResult = ReturnType<typeof useCarDetailVinQuery>;
export type CarDetailVinLazyQueryHookResult = ReturnType<typeof useCarDetailVinLazyQuery>;
export type CarDetailVinQueryResult = Apollo.QueryResult<
  CarDetailVinQuery,
  CarDetailVinQueryVariables
>;
export const CarDetailLicensePlateDocument = gql`
  query carDetailLicensePlate($license_plate_state: String!, $license_plate_number: String!) {
    carDetailLicensePlate(
      license_plate_state: $license_plate_state
      license_plate_number: $license_plate_number
    ) {
      vin
      year
      make
      model
      vehicle_type
      fuel_type
    }
  }
`;

/**
 * __useCarDetailLicensePlateQuery__
 *
 * To run a query within a React component, call `useCarDetailLicensePlateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarDetailLicensePlateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarDetailLicensePlateQuery({
 *   variables: {
 *      license_plate_state: // value for 'license_plate_state'
 *      license_plate_number: // value for 'license_plate_number'
 *   },
 * });
 */
export function useCarDetailLicensePlateQuery(
  baseOptions: Apollo.QueryHookOptions<
    CarDetailLicensePlateQuery,
    CarDetailLicensePlateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarDetailLicensePlateQuery, CarDetailLicensePlateQueryVariables>(
    CarDetailLicensePlateDocument,
    options,
  );
}
export function useCarDetailLicensePlateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarDetailLicensePlateQuery,
    CarDetailLicensePlateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CarDetailLicensePlateQuery, CarDetailLicensePlateQueryVariables>(
    CarDetailLicensePlateDocument,
    options,
  );
}
export type CarDetailLicensePlateQueryHookResult = ReturnType<typeof useCarDetailLicensePlateQuery>;
export type CarDetailLicensePlateLazyQueryHookResult = ReturnType<
  typeof useCarDetailLicensePlateLazyQuery
>;
export type CarDetailLicensePlateQueryResult = Apollo.QueryResult<
  CarDetailLicensePlateQuery,
  CarDetailLicensePlateQueryVariables
>;
export const GetWarrantyInfoDocument = gql`
  query getWarrantyInfo($make: String!, $mileage: Int!, $year: String!) {
    getWarrantyInfo(make: $make, mileage: $mileage, year: $year) {
      full_years
      full_miles
      full_status
      power_train_years
      power_train_miles
      power_train_status
    }
  }
`;

/**
 * __useGetWarrantyInfoQuery__
 *
 * To run a query within a React component, call `useGetWarrantyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarrantyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarrantyInfoQuery({
 *   variables: {
 *      make: // value for 'make'
 *      mileage: // value for 'mileage'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetWarrantyInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetWarrantyInfoQuery, GetWarrantyInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWarrantyInfoQuery, GetWarrantyInfoQueryVariables>(
    GetWarrantyInfoDocument,
    options,
  );
}
export function useGetWarrantyInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWarrantyInfoQuery, GetWarrantyInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWarrantyInfoQuery, GetWarrantyInfoQueryVariables>(
    GetWarrantyInfoDocument,
    options,
  );
}
export type GetWarrantyInfoQueryHookResult = ReturnType<typeof useGetWarrantyInfoQuery>;
export type GetWarrantyInfoLazyQueryHookResult = ReturnType<typeof useGetWarrantyInfoLazyQuery>;
export type GetWarrantyInfoQueryResult = Apollo.QueryResult<
  GetWarrantyInfoQuery,
  GetWarrantyInfoQueryVariables
>;
export const VehicleInfoUpsertDocument = gql`
  mutation vehicleInfoUpsert($deal: dealInput) {
    vehicleInfoUpsert(deal: $deal) {
      id
      car {
        id
        license_plate_number
        license_plate_state
        vin
        year
        make
        model
        mileage
        color
        book_value
        retail_book_value
        jdp_adjusted_clean_trade
        jdp_adjusted_clean_retail
        vehicle_type
        fuel_type
        kbb_trim_name
        mmr
        registration_expiration
        odometer_status
        payoff {
          ...PayoffFragment
        }
      }
      financial_info_acquisition {
        id
        deal_id
        kbb_lending
        appraised_value
        max_cash_to_customer
        max_total_cost
        cash_to_customer
        is_approved
        auction_fees
        transport_cost
        total_cost
        auction_id
        gross_profit
        sell_price
        overriding_user_id
      }
    }
  }
  ${PayoffFragmentFragmentDoc}
`;
export type VehicleInfoUpsertMutationFn = Apollo.MutationFunction<
  VehicleInfoUpsertMutation,
  VehicleInfoUpsertMutationVariables
>;

/**
 * __useVehicleInfoUpsertMutation__
 *
 * To run a mutation, you first call `useVehicleInfoUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVehicleInfoUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vehicleInfoUpsertMutation, { data, loading, error }] = useVehicleInfoUpsertMutation({
 *   variables: {
 *      deal: // value for 'deal'
 *   },
 * });
 */
export function useVehicleInfoUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VehicleInfoUpsertMutation,
    VehicleInfoUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VehicleInfoUpsertMutation, VehicleInfoUpsertMutationVariables>(
    VehicleInfoUpsertDocument,
    options,
  );
}
export type VehicleInfoUpsertMutationHookResult = ReturnType<typeof useVehicleInfoUpsertMutation>;
export type VehicleInfoUpsertMutationResult = Apollo.MutationResult<VehicleInfoUpsertMutation>;
export type VehicleInfoUpsertMutationOptions = Apollo.BaseMutationOptions<
  VehicleInfoUpsertMutation,
  VehicleInfoUpsertMutationVariables
>;
export const VehicleMileageDocument = gql`
  query vehicleMileage($vin: String!) {
    vehicleMileage(vin: $vin) {
      estimatedAverageMiles
      eventCount
      lastOdometer
      lastOdometerDate
      resultCode
      resultMessage
      rollback
      vin
    }
  }
`;

/**
 * __useVehicleMileageQuery__
 *
 * To run a query within a React component, call `useVehicleMileageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleMileageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleMileageQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useVehicleMileageQuery(
  baseOptions: Apollo.QueryHookOptions<VehicleMileageQuery, VehicleMileageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleMileageQuery, VehicleMileageQueryVariables>(
    VehicleMileageDocument,
    options,
  );
}
export function useVehicleMileageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VehicleMileageQuery, VehicleMileageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleMileageQuery, VehicleMileageQueryVariables>(
    VehicleMileageDocument,
    options,
  );
}
export type VehicleMileageQueryHookResult = ReturnType<typeof useVehicleMileageQuery>;
export type VehicleMileageLazyQueryHookResult = ReturnType<typeof useVehicleMileageLazyQuery>;
export type VehicleMileageQueryResult = Apollo.QueryResult<
  VehicleMileageQuery,
  VehicleMileageQueryVariables
>;
export const UpdateCarDocument = gql`
  mutation updateCar($car: carInput!) {
    updateCar(car: $car) {
      id
      registration_expiration
    }
  }
`;
export type UpdateCarMutationFn = Apollo.MutationFunction<
  UpdateCarMutation,
  UpdateCarMutationVariables
>;

/**
 * __useUpdateCarMutation__
 *
 * To run a mutation, you first call `useUpdateCarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarMutation, { data, loading, error }] = useUpdateCarMutation({
 *   variables: {
 *      car: // value for 'car'
 *   },
 * });
 */
export function useUpdateCarMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCarMutation, UpdateCarMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCarMutation, UpdateCarMutationVariables>(
    UpdateCarDocument,
    options,
  );
}
export type UpdateCarMutationHookResult = ReturnType<typeof useUpdateCarMutation>;
export type UpdateCarMutationResult = Apollo.MutationResult<UpdateCarMutation>;
export type UpdateCarMutationOptions = Apollo.BaseMutationOptions<
  UpdateCarMutation,
  UpdateCarMutationVariables
>;
export const ConditionReportUpsertDocument = gql`
  mutation conditionReportUpsert($conditionReport: conditionReportUpsertInput) {
    conditionReportUpsert(conditionReport: $conditionReport) {
      id
    }
  }
`;
export type ConditionReportUpsertMutationFn = Apollo.MutationFunction<
  ConditionReportUpsertMutation,
  ConditionReportUpsertMutationVariables
>;

/**
 * __useConditionReportUpsertMutation__
 *
 * To run a mutation, you first call `useConditionReportUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConditionReportUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [conditionReportUpsertMutation, { data, loading, error }] = useConditionReportUpsertMutation({
 *   variables: {
 *      conditionReport: // value for 'conditionReport'
 *   },
 * });
 */
export function useConditionReportUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConditionReportUpsertMutation,
    ConditionReportUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConditionReportUpsertMutation, ConditionReportUpsertMutationVariables>(
    ConditionReportUpsertDocument,
    options,
  );
}
export type ConditionReportUpsertMutationHookResult = ReturnType<
  typeof useConditionReportUpsertMutation
>;
export type ConditionReportUpsertMutationResult =
  Apollo.MutationResult<ConditionReportUpsertMutation>;
export type ConditionReportUpsertMutationOptions = Apollo.BaseMutationOptions<
  ConditionReportUpsertMutation,
  ConditionReportUpsertMutationVariables
>;
export const DetermineApprovalQueryDocument = gql`
  query determineApprovalQuery($data: determineApprovalQueryInput) {
    determineApprovalQuery(data: $data) {
      approved
      failedCriteria
    }
  }
`;

/**
 * __useDetermineApprovalQueryQuery__
 *
 * To run a query within a React component, call `useDetermineApprovalQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetermineApprovalQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetermineApprovalQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDetermineApprovalQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DetermineApprovalQueryQuery,
    DetermineApprovalQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DetermineApprovalQueryQuery, DetermineApprovalQueryQueryVariables>(
    DetermineApprovalQueryDocument,
    options,
  );
}
export function useDetermineApprovalQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DetermineApprovalQueryQuery,
    DetermineApprovalQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DetermineApprovalQueryQuery, DetermineApprovalQueryQueryVariables>(
    DetermineApprovalQueryDocument,
    options,
  );
}
export type DetermineApprovalQueryQueryHookResult = ReturnType<
  typeof useDetermineApprovalQueryQuery
>;
export type DetermineApprovalQueryLazyQueryHookResult = ReturnType<
  typeof useDetermineApprovalQueryLazyQuery
>;
export type DetermineApprovalQueryQueryResult = Apollo.QueryResult<
  DetermineApprovalQueryQuery,
  DetermineApprovalQueryQueryVariables
>;
export const SaveConsentDocument = gql`
  mutation saveConsent($dealId: ID, $type: consentNameEnumType) {
    saveConsent(dealId: $dealId, type: $type) {
      isNew
      consent {
        id
        type
      }
    }
  }
`;
export type SaveConsentMutationFn = Apollo.MutationFunction<
  SaveConsentMutation,
  SaveConsentMutationVariables
>;

/**
 * __useSaveConsentMutation__
 *
 * To run a mutation, you first call `useSaveConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveConsentMutation, { data, loading, error }] = useSaveConsentMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSaveConsentMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveConsentMutation, SaveConsentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveConsentMutation, SaveConsentMutationVariables>(
    SaveConsentDocument,
    options,
  );
}
export type SaveConsentMutationHookResult = ReturnType<typeof useSaveConsentMutation>;
export type SaveConsentMutationResult = Apollo.MutationResult<SaveConsentMutation>;
export type SaveConsentMutationOptions = Apollo.BaseMutationOptions<
  SaveConsentMutation,
  SaveConsentMutationVariables
>;
export const GenerateContractsDocument = gql`
  query generateContracts($data: contractsInput) {
    generateContracts(data: $data) {
      files {
        url
        filename
        key
        mediaType
      }
    }
  }
`;

/**
 * __useGenerateContractsQuery__
 *
 * To run a query within a React component, call `useGenerateContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateContractsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGenerateContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<GenerateContractsQuery, GenerateContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateContractsQuery, GenerateContractsQueryVariables>(
    GenerateContractsDocument,
    options,
  );
}
export function useGenerateContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateContractsQuery,
    GenerateContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerateContractsQuery, GenerateContractsQueryVariables>(
    GenerateContractsDocument,
    options,
  );
}
export type GenerateContractsQueryHookResult = ReturnType<typeof useGenerateContractsQuery>;
export type GenerateContractsLazyQueryHookResult = ReturnType<typeof useGenerateContractsLazyQuery>;
export type GenerateContractsQueryResult = Apollo.QueryResult<
  GenerateContractsQuery,
  GenerateContractsQueryVariables
>;
export const DeleteContractsDocument = gql`
  mutation deleteContracts($filePaths: [String]!) {
    deleteContracts(filePaths: $filePaths)
  }
`;
export type DeleteContractsMutationFn = Apollo.MutationFunction<
  DeleteContractsMutation,
  DeleteContractsMutationVariables
>;

/**
 * __useDeleteContractsMutation__
 *
 * To run a mutation, you first call `useDeleteContractsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractsMutation, { data, loading, error }] = useDeleteContractsMutation({
 *   variables: {
 *      filePaths: // value for 'filePaths'
 *   },
 * });
 */
export function useDeleteContractsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteContractsMutation,
    DeleteContractsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContractsMutation, DeleteContractsMutationVariables>(
    DeleteContractsDocument,
    options,
  );
}
export type DeleteContractsMutationHookResult = ReturnType<typeof useDeleteContractsMutation>;
export type DeleteContractsMutationResult = Apollo.MutationResult<DeleteContractsMutation>;
export type DeleteContractsMutationOptions = Apollo.BaseMutationOptions<
  DeleteContractsMutation,
  DeleteContractsMutationVariables
>;
export const SendContractsDocument = gql`
  mutation sendContracts($deal_id: ID!, $keys: [String]!) {
    sendContracts(deal_id: $deal_id, keys: $keys)
  }
`;
export type SendContractsMutationFn = Apollo.MutationFunction<
  SendContractsMutation,
  SendContractsMutationVariables
>;

/**
 * __useSendContractsMutation__
 *
 * To run a mutation, you first call `useSendContractsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContractsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContractsMutation, { data, loading, error }] = useSendContractsMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useSendContractsMutation(
  baseOptions?: Apollo.MutationHookOptions<SendContractsMutation, SendContractsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendContractsMutation, SendContractsMutationVariables>(
    SendContractsDocument,
    options,
  );
}
export type SendContractsMutationHookResult = ReturnType<typeof useSendContractsMutation>;
export type SendContractsMutationResult = Apollo.MutationResult<SendContractsMutation>;
export type SendContractsMutationOptions = Apollo.BaseMutationOptions<
  SendContractsMutation,
  SendContractsMutationVariables
>;
export const CreditApplicationSubmissionsDocument = gql`
  query creditApplicationSubmissions($filter: CreditApplicationSubmissionFilter) {
    creditApplicationSubmissions(filter: $filter) {
      id
      created_at
      credit_applications {
        id
        credit_decisions {
          id
          r1_fsid
          r1_application_number
          r1_credit_decision_data {
            applicationNumber
            applicationStatus
            applicationStatusDetail
          }
          application_status
          term
          annual_percentage_rate
          max_markup
          created_at
          balance_amount
          lender_fees
        }
      }
    }
  }
`;

/**
 * __useCreditApplicationSubmissionsQuery__
 *
 * To run a query within a React component, call `useCreditApplicationSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditApplicationSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditApplicationSubmissionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCreditApplicationSubmissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CreditApplicationSubmissionsQuery,
    CreditApplicationSubmissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreditApplicationSubmissionsQuery,
    CreditApplicationSubmissionsQueryVariables
  >(CreditApplicationSubmissionsDocument, options);
}
export function useCreditApplicationSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreditApplicationSubmissionsQuery,
    CreditApplicationSubmissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreditApplicationSubmissionsQuery,
    CreditApplicationSubmissionsQueryVariables
  >(CreditApplicationSubmissionsDocument, options);
}
export type CreditApplicationSubmissionsQueryHookResult = ReturnType<
  typeof useCreditApplicationSubmissionsQuery
>;
export type CreditApplicationSubmissionsLazyQueryHookResult = ReturnType<
  typeof useCreditApplicationSubmissionsLazyQuery
>;
export type CreditApplicationSubmissionsQueryResult = Apollo.QueryResult<
  CreditApplicationSubmissionsQuery,
  CreditApplicationSubmissionsQueryVariables
>;
export const CreditDecisionDataDocument = gql`
  query creditDecisionData($id: Int!) {
    creditDecisionV2(id: $id) {
      id
      r1_credit_decision_data {
        applicationNumber
        decision {
          stipulations
          decisionComments
          conditionRejection {
            conditionRejectionText
          }
        }
      }
    }
  }
`;

/**
 * __useCreditDecisionDataQuery__
 *
 * To run a query within a React component, call `useCreditDecisionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditDecisionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditDecisionDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreditDecisionDataQuery(
  baseOptions: Apollo.QueryHookOptions<CreditDecisionDataQuery, CreditDecisionDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreditDecisionDataQuery, CreditDecisionDataQueryVariables>(
    CreditDecisionDataDocument,
    options,
  );
}
export function useCreditDecisionDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreditDecisionDataQuery,
    CreditDecisionDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreditDecisionDataQuery, CreditDecisionDataQueryVariables>(
    CreditDecisionDataDocument,
    options,
  );
}
export type CreditDecisionDataQueryHookResult = ReturnType<typeof useCreditDecisionDataQuery>;
export type CreditDecisionDataLazyQueryHookResult = ReturnType<
  typeof useCreditDecisionDataLazyQuery
>;
export type CreditDecisionDataQueryResult = Apollo.QueryResult<
  CreditDecisionDataQuery,
  CreditDecisionDataQueryVariables
>;
export const CreditDecisionApplicationNumberDocument = gql`
  query creditDecisionApplicationNumber($id: Int!) {
    creditDecisionV2(id: $id) {
      id
      r1_application_number
    }
  }
`;

/**
 * __useCreditDecisionApplicationNumberQuery__
 *
 * To run a query within a React component, call `useCreditDecisionApplicationNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditDecisionApplicationNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditDecisionApplicationNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreditDecisionApplicationNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreditDecisionApplicationNumberQuery,
    CreditDecisionApplicationNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreditDecisionApplicationNumberQuery,
    CreditDecisionApplicationNumberQueryVariables
  >(CreditDecisionApplicationNumberDocument, options);
}
export function useCreditDecisionApplicationNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreditDecisionApplicationNumberQuery,
    CreditDecisionApplicationNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreditDecisionApplicationNumberQuery,
    CreditDecisionApplicationNumberQueryVariables
  >(CreditDecisionApplicationNumberDocument, options);
}
export type CreditDecisionApplicationNumberQueryHookResult = ReturnType<
  typeof useCreditDecisionApplicationNumberQuery
>;
export type CreditDecisionApplicationNumberLazyQueryHookResult = ReturnType<
  typeof useCreditDecisionApplicationNumberLazyQuery
>;
export type CreditDecisionApplicationNumberQueryResult = Apollo.QueryResult<
  CreditDecisionApplicationNumberQuery,
  CreditDecisionApplicationNumberQueryVariables
>;
export const SelectCreditDecisionDocument = gql`
  mutation selectCreditDecision($id: ID!) {
    selectCreditDecision(id: $id) {
      creditDecision {
        id
      }
      financialInfo {
        id
        bank
        bank_fees
        selected_credit_decision_id
        term
        sell_rate
        buy_rate
        first_payment_date
      }
    }
  }
`;
export type SelectCreditDecisionMutationFn = Apollo.MutationFunction<
  SelectCreditDecisionMutation,
  SelectCreditDecisionMutationVariables
>;

/**
 * __useSelectCreditDecisionMutation__
 *
 * To run a mutation, you first call `useSelectCreditDecisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectCreditDecisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectCreditDecisionMutation, { data, loading, error }] = useSelectCreditDecisionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelectCreditDecisionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectCreditDecisionMutation,
    SelectCreditDecisionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SelectCreditDecisionMutation, SelectCreditDecisionMutationVariables>(
    SelectCreditDecisionDocument,
    options,
  );
}
export type SelectCreditDecisionMutationHookResult = ReturnType<
  typeof useSelectCreditDecisionMutation
>;
export type SelectCreditDecisionMutationResult =
  Apollo.MutationResult<SelectCreditDecisionMutation>;
export type SelectCreditDecisionMutationOptions = Apollo.BaseMutationOptions<
  SelectCreditDecisionMutation,
  SelectCreditDecisionMutationVariables
>;
export const GetCreditDecisionByR1FsidDocument = gql`
  query getCreditDecisionByR1FSID($deal_id: ID!, $r1_fsid: String!) {
    getCreditDecisionByR1FSID(deal_id: $deal_id, r1_fsid: $r1_fsid) {
      id
      r1_application_number
    }
  }
`;

/**
 * __useGetCreditDecisionByR1FsidQuery__
 *
 * To run a query within a React component, call `useGetCreditDecisionByR1FsidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditDecisionByR1FsidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditDecisionByR1FsidQuery({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      r1_fsid: // value for 'r1_fsid'
 *   },
 * });
 */
export function useGetCreditDecisionByR1FsidQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreditDecisionByR1FsidQuery,
    GetCreditDecisionByR1FsidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCreditDecisionByR1FsidQuery, GetCreditDecisionByR1FsidQueryVariables>(
    GetCreditDecisionByR1FsidDocument,
    options,
  );
}
export function useGetCreditDecisionByR1FsidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreditDecisionByR1FsidQuery,
    GetCreditDecisionByR1FsidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCreditDecisionByR1FsidQuery,
    GetCreditDecisionByR1FsidQueryVariables
  >(GetCreditDecisionByR1FsidDocument, options);
}
export type GetCreditDecisionByR1FsidQueryHookResult = ReturnType<
  typeof useGetCreditDecisionByR1FsidQuery
>;
export type GetCreditDecisionByR1FsidLazyQueryHookResult = ReturnType<
  typeof useGetCreditDecisionByR1FsidLazyQuery
>;
export type GetCreditDecisionByR1FsidQueryResult = Apollo.QueryResult<
  GetCreditDecisionByR1FsidQuery,
  GetCreditDecisionByR1FsidQueryVariables
>;
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($customer: customerInput) {
    updateCustomer(customer: $customer) {
      id
    }
  }
`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    UpdateCustomerDocument,
    options,
  );
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const UpdateCustomerAndAddressDocument = gql`
  mutation updateCustomerAndAddress($customer: customerInput) {
    updateCustomerAndAddress(customer: $customer) {
      id
    }
  }
`;
export type UpdateCustomerAndAddressMutationFn = Apollo.MutationFunction<
  UpdateCustomerAndAddressMutation,
  UpdateCustomerAndAddressMutationVariables
>;

/**
 * __useUpdateCustomerAndAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerAndAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerAndAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerAndAddressMutation, { data, loading, error }] = useUpdateCustomerAndAddressMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useUpdateCustomerAndAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerAndAddressMutation,
    UpdateCustomerAndAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCustomerAndAddressMutation,
    UpdateCustomerAndAddressMutationVariables
  >(UpdateCustomerAndAddressDocument, options);
}
export type UpdateCustomerAndAddressMutationHookResult = ReturnType<
  typeof useUpdateCustomerAndAddressMutation
>;
export type UpdateCustomerAndAddressMutationResult =
  Apollo.MutationResult<UpdateCustomerAndAddressMutation>;
export type UpdateCustomerAndAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerAndAddressMutation,
  UpdateCustomerAndAddressMutationVariables
>;
export const UpdateInsuranceInfoDocument = gql`
  mutation updateInsuranceInfo(
    $id: ID!
    $insurance_company: String
    $policy_no: String
    $expiration_date: Date
  ) {
    updateInsuranceInfo(
      id: $id
      insurance_company: $insurance_company
      policy_no: $policy_no
      expiration_date: $expiration_date
    ) {
      id
      company_name
      expires
      policy_number
    }
  }
`;
export type UpdateInsuranceInfoMutationFn = Apollo.MutationFunction<
  UpdateInsuranceInfoMutation,
  UpdateInsuranceInfoMutationVariables
>;

/**
 * __useUpdateInsuranceInfoMutation__
 *
 * To run a mutation, you first call `useUpdateInsuranceInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsuranceInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsuranceInfoMutation, { data, loading, error }] = useUpdateInsuranceInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      insurance_company: // value for 'insurance_company'
 *      policy_no: // value for 'policy_no'
 *      expiration_date: // value for 'expiration_date'
 *   },
 * });
 */
export function useUpdateInsuranceInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInsuranceInfoMutation,
    UpdateInsuranceInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInsuranceInfoMutation, UpdateInsuranceInfoMutationVariables>(
    UpdateInsuranceInfoDocument,
    options,
  );
}
export type UpdateInsuranceInfoMutationHookResult = ReturnType<
  typeof useUpdateInsuranceInfoMutation
>;
export type UpdateInsuranceInfoMutationResult = Apollo.MutationResult<UpdateInsuranceInfoMutation>;
export type UpdateInsuranceInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateInsuranceInfoMutation,
  UpdateInsuranceInfoMutationVariables
>;
export const SearchDealsDocument = gql`
  query searchDeals($query: String!) {
    searchDeals(query: $query) {
      id
      state
      source
      closer_id
      closer2_id
      customer {
        first_name
        middle_name
        last_name
        phone_number
        home_phone_number
        email
      }
      car {
        vin
      }
    }
  }
`;

/**
 * __useSearchDealsQuery__
 *
 * To run a query within a React component, call `useSearchDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDealsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchDealsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchDealsQuery, SearchDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchDealsQuery, SearchDealsQueryVariables>(SearchDealsDocument, options);
}
export function useSearchDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchDealsQuery, SearchDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchDealsQuery, SearchDealsQueryVariables>(
    SearchDealsDocument,
    options,
  );
}
export type SearchDealsQueryHookResult = ReturnType<typeof useSearchDealsQuery>;
export type SearchDealsLazyQueryHookResult = ReturnType<typeof useSearchDealsLazyQuery>;
export type SearchDealsQueryResult = Apollo.QueryResult<
  SearchDealsQuery,
  SearchDealsQueryVariables
>;
export const TemplateInfoDocument = gql`
  query templateInfo($id: ID!, $sources: [String]!, $types: [DealType]!) {
    deal(id: $id, sources: $sources, types: $types) {
      car {
        year
        make
        model
        payoff {
          lienholder_name
        }
      }
      financial_info {
        money_down
      }
    }
  }
`;

/**
 * __useTemplateInfoQuery__
 *
 * To run a query within a React component, call `useTemplateInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sources: // value for 'sources'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useTemplateInfoQuery(
  baseOptions: Apollo.QueryHookOptions<TemplateInfoQuery, TemplateInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TemplateInfoQuery, TemplateInfoQueryVariables>(
    TemplateInfoDocument,
    options,
  );
}
export function useTemplateInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TemplateInfoQuery, TemplateInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TemplateInfoQuery, TemplateInfoQueryVariables>(
    TemplateInfoDocument,
    options,
  );
}
export type TemplateInfoQueryHookResult = ReturnType<typeof useTemplateInfoQuery>;
export type TemplateInfoLazyQueryHookResult = ReturnType<typeof useTemplateInfoLazyQuery>;
export type TemplateInfoQueryResult = Apollo.QueryResult<
  TemplateInfoQuery,
  TemplateInfoQueryVariables
>;
export const DashboardDealsDocument = gql`
  query dashboardDeals(
    $assigned_to_me_filter: Boolean
    $boot_requested_filter: Boolean
    $assigned_to_deleted_users_filter: Boolean
    $unread_messages_filter: Boolean
    $sources: [String]!
    $types: [DealType]!
    $states: [String]
    $showFollowUps: Boolean
    $pod_id: ID
    $user_id: String
    $permissions: [String]
    $showActiveTags: Boolean
    $showMatureFollowUps: Boolean
  ) {
    dashboardDeals(
      assigned_to_me_filter: $assigned_to_me_filter
      boot_requested_filter: $boot_requested_filter
      assigned_to_deleted_users_filter: $assigned_to_deleted_users_filter
      unread_messages_filter: $unread_messages_filter
      sources: $sources
      types: $types
      states: $states
      showFollowUps: $showFollowUps
      pod_id: $pod_id
      user_id: $user_id
      permissions: $permissions
      showActiveTags: $showActiveTags
      showMatureFollowUps: $showMatureFollowUps
    ) {
      id
      type
      source
      state
      lane_arrival_utc
      default_sort_date
      missing_required_external_documents
      paperwork_type
      request_boot
      boot_reason
      created_at
      tags {
        id
        display_name
        color
        is_dashboard_visible
      }
      customer {
        id
        first_name
        last_name
        middle_name
        phone_number
        address {
          state
          county
        }
      }
      pod {
        id
        name
        color
      }
      setter {
        id
        name
      }
      closer {
        id
        name
      }
      closer2 {
        id
        name
      }
      structuring_manager_id
      structuring_manager {
        id
        name
      }
      funding_clerk {
        id
        name
      }
      titling_pod {
        id
        color
        name
      }
      title_clerk {
        id
        name
      }
      title_clerk2 {
        id
        name
      }
      notifications {
        id
        message
      }
      follow_up {
        id
        creator_id
        deal_id
        date_utc
        note
        status
        tz
        type
      }
      deal_dates {
        dates
      }
      car {
        payoff {
          lienholder_slug
          lienholder_name
        }
      }
      financial_info {
        bank
        processor
      }
      hasRecentCommunication
      called_by_financial_specialist
    }
  }
`;

/**
 * __useDashboardDealsQuery__
 *
 * To run a query within a React component, call `useDashboardDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDealsQuery({
 *   variables: {
 *      assigned_to_me_filter: // value for 'assigned_to_me_filter'
 *      boot_requested_filter: // value for 'boot_requested_filter'
 *      assigned_to_deleted_users_filter: // value for 'assigned_to_deleted_users_filter'
 *      unread_messages_filter: // value for 'unread_messages_filter'
 *      sources: // value for 'sources'
 *      types: // value for 'types'
 *      states: // value for 'states'
 *      showFollowUps: // value for 'showFollowUps'
 *      pod_id: // value for 'pod_id'
 *      user_id: // value for 'user_id'
 *      permissions: // value for 'permissions'
 *      showActiveTags: // value for 'showActiveTags'
 *      showMatureFollowUps: // value for 'showMatureFollowUps'
 *   },
 * });
 */
export function useDashboardDealsQuery(
  baseOptions: Apollo.QueryHookOptions<DashboardDealsQuery, DashboardDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardDealsQuery, DashboardDealsQueryVariables>(
    DashboardDealsDocument,
    options,
  );
}
export function useDashboardDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashboardDealsQuery, DashboardDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardDealsQuery, DashboardDealsQueryVariables>(
    DashboardDealsDocument,
    options,
  );
}
export type DashboardDealsQueryHookResult = ReturnType<typeof useDashboardDealsQuery>;
export type DashboardDealsLazyQueryHookResult = ReturnType<typeof useDashboardDealsLazyQuery>;
export type DashboardDealsQueryResult = Apollo.QueryResult<
  DashboardDealsQuery,
  DashboardDealsQueryVariables
>;
export const CompletedDealsDocument = gql`
  query completedDeals(
    $assigned_to_me_filter: Boolean
    $boot_requested_filter: Boolean
    $assigned_to_deleted_users_filter: Boolean
    $start_date: DateTime!
    $end_date: DateTime!
    $sources: [String]!
    $types: [DealType]!
    $user_id: String
    $deal_state: String
    $address_state: String
    $pod_id: ID
    $notifications: Boolean
    $showActiveTags: Boolean
    $showMatureFollowUps: Boolean
  ) {
    completedDeals(
      assigned_to_me_filter: $assigned_to_me_filter
      boot_requested_filter: $boot_requested_filter
      assigned_to_deleted_users_filter: $assigned_to_deleted_users_filter
      start_date: $start_date
      end_date: $end_date
      sources: $sources
      types: $types
      deal_state: $deal_state
      user_id: $user_id
      address_state: $address_state
      pod_id: $pod_id
      notifications: $notifications
      showActiveTags: $showActiveTags
      showMatureFollowUps: $showMatureFollowUps
    ) {
      id
      type
      state
      completion_date_utc
      completion_date_tz
      customer {
        first_name
        last_name
        name_suffix
        address {
          state
        }
      }
      setter {
        name
      }
      closer {
        name
      }
      closer2 {
        name
      }
      car {
        year
        make
        model
        vin
        payoff {
          lienholder_name
          lender_name
          good_through_date
        }
      }
      financial_info {
        bank
        amount_financed
      }
      notifications {
        id
        message
        seen
      }
      deal_dates {
        dates
      }
    }
  }
`;

/**
 * __useCompletedDealsQuery__
 *
 * To run a query within a React component, call `useCompletedDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedDealsQuery({
 *   variables: {
 *      assigned_to_me_filter: // value for 'assigned_to_me_filter'
 *      boot_requested_filter: // value for 'boot_requested_filter'
 *      assigned_to_deleted_users_filter: // value for 'assigned_to_deleted_users_filter'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      sources: // value for 'sources'
 *      types: // value for 'types'
 *      user_id: // value for 'user_id'
 *      deal_state: // value for 'deal_state'
 *      address_state: // value for 'address_state'
 *      pod_id: // value for 'pod_id'
 *      notifications: // value for 'notifications'
 *      showActiveTags: // value for 'showActiveTags'
 *      showMatureFollowUps: // value for 'showMatureFollowUps'
 *   },
 * });
 */
export function useCompletedDealsQuery(
  baseOptions: Apollo.QueryHookOptions<CompletedDealsQuery, CompletedDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompletedDealsQuery, CompletedDealsQueryVariables>(
    CompletedDealsDocument,
    options,
  );
}
export function useCompletedDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompletedDealsQuery, CompletedDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompletedDealsQuery, CompletedDealsQueryVariables>(
    CompletedDealsDocument,
    options,
  );
}
export type CompletedDealsQueryHookResult = ReturnType<typeof useCompletedDealsQuery>;
export type CompletedDealsLazyQueryHookResult = ReturnType<typeof useCompletedDealsLazyQuery>;
export type CompletedDealsQueryResult = Apollo.QueryResult<
  CompletedDealsQuery,
  CompletedDealsQueryVariables
>;
export const DealDetailNetsuiteDocument = gql`
  mutation dealDetailNetsuite($id: ID!) {
    dealDetailNetsuite(id: $id) {
      id
    }
  }
`;
export type DealDetailNetsuiteMutationFn = Apollo.MutationFunction<
  DealDetailNetsuiteMutation,
  DealDetailNetsuiteMutationVariables
>;

/**
 * __useDealDetailNetsuiteMutation__
 *
 * To run a mutation, you first call `useDealDetailNetsuiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealDetailNetsuiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealDetailNetsuiteMutation, { data, loading, error }] = useDealDetailNetsuiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDealDetailNetsuiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DealDetailNetsuiteMutation,
    DealDetailNetsuiteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DealDetailNetsuiteMutation, DealDetailNetsuiteMutationVariables>(
    DealDetailNetsuiteDocument,
    options,
  );
}
export type DealDetailNetsuiteMutationHookResult = ReturnType<typeof useDealDetailNetsuiteMutation>;
export type DealDetailNetsuiteMutationResult = Apollo.MutationResult<DealDetailNetsuiteMutation>;
export type DealDetailNetsuiteMutationOptions = Apollo.BaseMutationOptions<
  DealDetailNetsuiteMutation,
  DealDetailNetsuiteMutationVariables
>;
export const DealUpdateAssignmentsDocument = gql`
  mutation dealUpdateAssignments(
    $id: ID!
    $type: DealType
    $pod_id: ID
    $setter_id: String
    $closer_id: String
    $closer2_id: String
    $structuring_manager_id: String
    $funding_clerk_id: String
    $titling_pod_id: ID
    $title_clerk_id: String
    $title_clerk2_id: String
    $deal_source: String
  ) {
    dealUpdateAssignments(
      id: $id
      type: $type
      pod_id: $pod_id
      setter_id: $setter_id
      closer_id: $closer_id
      closer2_id: $closer2_id
      structuring_manager_id: $structuring_manager_id
      funding_clerk_id: $funding_clerk_id
      titling_pod_id: $titling_pod_id
      title_clerk_id: $title_clerk_id
      title_clerk2_id: $title_clerk2_id
      deal_source: $deal_source
    ) {
      id
      type
      ...AssignmentUsersFragment
      ...TitleUsersFragment
      source
      car {
        payoff {
          lender_name
          lienholder_name
          lienholder_slug
        }
      }
      customer {
        address {
          moved_states
        }
      }
      financial_info {
        bank
        title_registration_option
        plate_transfer
        buyer_not_lessee
        sales_tax_rate
        base_tax_amount
        warranty_tax_amount
        new_registration_fee
        title_fee
      }
    }
  }
  ${AssignmentUsersFragmentFragmentDoc}
  ${TitleUsersFragmentFragmentDoc}
`;
export type DealUpdateAssignmentsMutationFn = Apollo.MutationFunction<
  DealUpdateAssignmentsMutation,
  DealUpdateAssignmentsMutationVariables
>;

/**
 * __useDealUpdateAssignmentsMutation__
 *
 * To run a mutation, you first call `useDealUpdateAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealUpdateAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealUpdateAssignmentsMutation, { data, loading, error }] = useDealUpdateAssignmentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      pod_id: // value for 'pod_id'
 *      setter_id: // value for 'setter_id'
 *      closer_id: // value for 'closer_id'
 *      closer2_id: // value for 'closer2_id'
 *      structuring_manager_id: // value for 'structuring_manager_id'
 *      funding_clerk_id: // value for 'funding_clerk_id'
 *      titling_pod_id: // value for 'titling_pod_id'
 *      title_clerk_id: // value for 'title_clerk_id'
 *      title_clerk2_id: // value for 'title_clerk2_id'
 *      deal_source: // value for 'deal_source'
 *   },
 * });
 */
export function useDealUpdateAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DealUpdateAssignmentsMutation,
    DealUpdateAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DealUpdateAssignmentsMutation, DealUpdateAssignmentsMutationVariables>(
    DealUpdateAssignmentsDocument,
    options,
  );
}
export type DealUpdateAssignmentsMutationHookResult = ReturnType<
  typeof useDealUpdateAssignmentsMutation
>;
export type DealUpdateAssignmentsMutationResult =
  Apollo.MutationResult<DealUpdateAssignmentsMutation>;
export type DealUpdateAssignmentsMutationOptions = Apollo.BaseMutationOptions<
  DealUpdateAssignmentsMutation,
  DealUpdateAssignmentsMutationVariables
>;
export const DealUpdateStateDocument = gql`
  mutation dealUpdateState($id: ID!, $state: String, $clearSetDate: Boolean) {
    dealUpdateState(id: $id, state: $state, clearSetDate: $clearSetDate) {
      deal {
        id
        state
        financial_info {
          id
          first_payment_date
        }
      }
      errors
    }
  }
`;
export type DealUpdateStateMutationFn = Apollo.MutationFunction<
  DealUpdateStateMutation,
  DealUpdateStateMutationVariables
>;

/**
 * __useDealUpdateStateMutation__
 *
 * To run a mutation, you first call `useDealUpdateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealUpdateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealUpdateStateMutation, { data, loading, error }] = useDealUpdateStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *      clearSetDate: // value for 'clearSetDate'
 *   },
 * });
 */
export function useDealUpdateStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DealUpdateStateMutation,
    DealUpdateStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DealUpdateStateMutation, DealUpdateStateMutationVariables>(
    DealUpdateStateDocument,
    options,
  );
}
export type DealUpdateStateMutationHookResult = ReturnType<typeof useDealUpdateStateMutation>;
export type DealUpdateStateMutationResult = Apollo.MutationResult<DealUpdateStateMutation>;
export type DealUpdateStateMutationOptions = Apollo.BaseMutationOptions<
  DealUpdateStateMutation,
  DealUpdateStateMutationVariables
>;
export const ImportDealDocument = gql`
  mutation importDeal($id: ID!) {
    importDeal(id: $id) {
      id
    }
  }
`;
export type ImportDealMutationFn = Apollo.MutationFunction<
  ImportDealMutation,
  ImportDealMutationVariables
>;

/**
 * __useImportDealMutation__
 *
 * To run a mutation, you first call `useImportDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importDealMutation, { data, loading, error }] = useImportDealMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useImportDealMutation(
  baseOptions?: Apollo.MutationHookOptions<ImportDealMutation, ImportDealMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportDealMutation, ImportDealMutationVariables>(
    ImportDealDocument,
    options,
  );
}
export type ImportDealMutationHookResult = ReturnType<typeof useImportDealMutation>;
export type ImportDealMutationResult = Apollo.MutationResult<ImportDealMutation>;
export type ImportDealMutationOptions = Apollo.BaseMutationOptions<
  ImportDealMutation,
  ImportDealMutationVariables
>;
export const DealTagsUpsertDocument = gql`
  mutation dealTagsUpsert($dealId: ID!, $tagIds: [ID!]!) {
    dealTagsUpsert(dealId: $dealId, tagIds: $tagIds) {
      id
      slug
      display_name
      color
      is_active
      is_dashboard_visible
    }
  }
`;
export type DealTagsUpsertMutationFn = Apollo.MutationFunction<
  DealTagsUpsertMutation,
  DealTagsUpsertMutationVariables
>;

/**
 * __useDealTagsUpsertMutation__
 *
 * To run a mutation, you first call `useDealTagsUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealTagsUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealTagsUpsertMutation, { data, loading, error }] = useDealTagsUpsertMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useDealTagsUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<DealTagsUpsertMutation, DealTagsUpsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DealTagsUpsertMutation, DealTagsUpsertMutationVariables>(
    DealTagsUpsertDocument,
    options,
  );
}
export type DealTagsUpsertMutationHookResult = ReturnType<typeof useDealTagsUpsertMutation>;
export type DealTagsUpsertMutationResult = Apollo.MutationResult<DealTagsUpsertMutation>;
export type DealTagsUpsertMutationOptions = Apollo.BaseMutationOptions<
  DealTagsUpsertMutation,
  DealTagsUpsertMutationVariables
>;
export const DealAssignDocument = gql`
  mutation dealAssign(
    $deal_id: ID!
    $assignee_id: String
    $deal_state: String
    $role: String!
    $allow_reassign: Boolean
  ) {
    dealAssign(
      deal_id: $deal_id
      assignee_id: $assignee_id
      deal_state: $deal_state
      role: $role
      allow_reassign: $allow_reassign
    ) {
      id
    }
  }
`;
export type DealAssignMutationFn = Apollo.MutationFunction<
  DealAssignMutation,
  DealAssignMutationVariables
>;

/**
 * __useDealAssignMutation__
 *
 * To run a mutation, you first call `useDealAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealAssignMutation, { data, loading, error }] = useDealAssignMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      assignee_id: // value for 'assignee_id'
 *      deal_state: // value for 'deal_state'
 *      role: // value for 'role'
 *      allow_reassign: // value for 'allow_reassign'
 *   },
 * });
 */
export function useDealAssignMutation(
  baseOptions?: Apollo.MutationHookOptions<DealAssignMutation, DealAssignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DealAssignMutation, DealAssignMutationVariables>(
    DealAssignDocument,
    options,
  );
}
export type DealAssignMutationHookResult = ReturnType<typeof useDealAssignMutation>;
export type DealAssignMutationResult = Apollo.MutationResult<DealAssignMutation>;
export type DealAssignMutationOptions = Apollo.BaseMutationOptions<
  DealAssignMutation,
  DealAssignMutationVariables
>;
export const DealUpdateRequestBootDocument = gql`
  mutation dealUpdateRequestBoot(
    $id: ID!
    $request_boot: Boolean!
    $boot_reason: bootReasonInput!
    $partial_note: noteInput
    $boot_deal: Boolean
  ) {
    dealUpdateRequestBoot(
      id: $id
      request_boot: $request_boot
      boot_reason: $boot_reason
      partial_note: $partial_note
      boot_deal: $boot_deal
    ) {
      request_boot
      boot_reason
      state
    }
  }
`;
export type DealUpdateRequestBootMutationFn = Apollo.MutationFunction<
  DealUpdateRequestBootMutation,
  DealUpdateRequestBootMutationVariables
>;

/**
 * __useDealUpdateRequestBootMutation__
 *
 * To run a mutation, you first call `useDealUpdateRequestBootMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealUpdateRequestBootMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealUpdateRequestBootMutation, { data, loading, error }] = useDealUpdateRequestBootMutation({
 *   variables: {
 *      id: // value for 'id'
 *      request_boot: // value for 'request_boot'
 *      boot_reason: // value for 'boot_reason'
 *      partial_note: // value for 'partial_note'
 *      boot_deal: // value for 'boot_deal'
 *   },
 * });
 */
export function useDealUpdateRequestBootMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DealUpdateRequestBootMutation,
    DealUpdateRequestBootMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DealUpdateRequestBootMutation, DealUpdateRequestBootMutationVariables>(
    DealUpdateRequestBootDocument,
    options,
  );
}
export type DealUpdateRequestBootMutationHookResult = ReturnType<
  typeof useDealUpdateRequestBootMutation
>;
export type DealUpdateRequestBootMutationResult =
  Apollo.MutationResult<DealUpdateRequestBootMutation>;
export type DealUpdateRequestBootMutationOptions = Apollo.BaseMutationOptions<
  DealUpdateRequestBootMutation,
  DealUpdateRequestBootMutationVariables
>;
export const ProcessPaperworkDocument = gql`
  mutation processPaperwork($id: ID!, $state: String!, $paperwork_type: String!) {
    processPaperwork(id: $id, state: $state, paperwork_type: $paperwork_type) {
      id
    }
  }
`;
export type ProcessPaperworkMutationFn = Apollo.MutationFunction<
  ProcessPaperworkMutation,
  ProcessPaperworkMutationVariables
>;

/**
 * __useProcessPaperworkMutation__
 *
 * To run a mutation, you first call `useProcessPaperworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessPaperworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processPaperworkMutation, { data, loading, error }] = useProcessPaperworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *      paperwork_type: // value for 'paperwork_type'
 *   },
 * });
 */
export function useProcessPaperworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessPaperworkMutation,
    ProcessPaperworkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProcessPaperworkMutation, ProcessPaperworkMutationVariables>(
    ProcessPaperworkDocument,
    options,
  );
}
export type ProcessPaperworkMutationHookResult = ReturnType<typeof useProcessPaperworkMutation>;
export type ProcessPaperworkMutationResult = Apollo.MutationResult<ProcessPaperworkMutation>;
export type ProcessPaperworkMutationOptions = Apollo.BaseMutationOptions<
  ProcessPaperworkMutation,
  ProcessPaperworkMutationVariables
>;
export const UpdateCreditAppOnDealDocument = gql`
  mutation updateCreditAppOnDeal($input: CreditAppInput!) {
    updateCreditAppOnDeal(input: $input) {
      id
      state
      odometer_status
      customer {
        ...PersonCreditAppOnDeal
      }
      cobuyer {
        ...PersonCreditAppOnDeal
      }
      financial_info {
        id
        term
        money_down
        buyer_not_lessee
      }
      contact {
        ...ContactPerson
      }
      second_contact {
        ...ContactPerson
      }
    }
  }
  ${PersonCreditAppOnDealFragmentDoc}
  ${ContactPersonFragmentDoc}
`;
export type UpdateCreditAppOnDealMutationFn = Apollo.MutationFunction<
  UpdateCreditAppOnDealMutation,
  UpdateCreditAppOnDealMutationVariables
>;

/**
 * __useUpdateCreditAppOnDealMutation__
 *
 * To run a mutation, you first call `useUpdateCreditAppOnDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditAppOnDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditAppOnDealMutation, { data, loading, error }] = useUpdateCreditAppOnDealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditAppOnDealMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCreditAppOnDealMutation,
    UpdateCreditAppOnDealMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCreditAppOnDealMutation, UpdateCreditAppOnDealMutationVariables>(
    UpdateCreditAppOnDealDocument,
    options,
  );
}
export type UpdateCreditAppOnDealMutationHookResult = ReturnType<
  typeof useUpdateCreditAppOnDealMutation
>;
export type UpdateCreditAppOnDealMutationResult =
  Apollo.MutationResult<UpdateCreditAppOnDealMutation>;
export type UpdateCreditAppOnDealMutationOptions = Apollo.BaseMutationOptions<
  UpdateCreditAppOnDealMutation,
  UpdateCreditAppOnDealMutationVariables
>;
export const OverrideCalledByFinancialSpecialistDocument = gql`
  mutation overrideCalledByFinancialSpecialist($dealId: ID!) {
    overrideCalledByFinancialSpecialist(dealId: $dealId) {
      id
      called_by_financial_specialist
    }
  }
`;
export type OverrideCalledByFinancialSpecialistMutationFn = Apollo.MutationFunction<
  OverrideCalledByFinancialSpecialistMutation,
  OverrideCalledByFinancialSpecialistMutationVariables
>;

/**
 * __useOverrideCalledByFinancialSpecialistMutation__
 *
 * To run a mutation, you first call `useOverrideCalledByFinancialSpecialistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverrideCalledByFinancialSpecialistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overrideCalledByFinancialSpecialistMutation, { data, loading, error }] = useOverrideCalledByFinancialSpecialistMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useOverrideCalledByFinancialSpecialistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OverrideCalledByFinancialSpecialistMutation,
    OverrideCalledByFinancialSpecialistMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OverrideCalledByFinancialSpecialistMutation,
    OverrideCalledByFinancialSpecialistMutationVariables
  >(OverrideCalledByFinancialSpecialistDocument, options);
}
export type OverrideCalledByFinancialSpecialistMutationHookResult = ReturnType<
  typeof useOverrideCalledByFinancialSpecialistMutation
>;
export type OverrideCalledByFinancialSpecialistMutationResult =
  Apollo.MutationResult<OverrideCalledByFinancialSpecialistMutation>;
export type OverrideCalledByFinancialSpecialistMutationOptions = Apollo.BaseMutationOptions<
  OverrideCalledByFinancialSpecialistMutation,
  OverrideCalledByFinancialSpecialistMutationVariables
>;
export const OnDealCompleteDocument = gql`
  subscription onDealComplete($sources: [String]!, $types: [DealType]!) {
    onDealComplete(sources: $sources, types: $types)
  }
`;

/**
 * __useOnDealCompleteSubscription__
 *
 * To run a query within a React component, call `useOnDealCompleteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDealCompleteSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDealCompleteSubscription({
 *   variables: {
 *      sources: // value for 'sources'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useOnDealCompleteSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnDealCompleteSubscription,
    OnDealCompleteSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnDealCompleteSubscription, OnDealCompleteSubscriptionVariables>(
    OnDealCompleteDocument,
    options,
  );
}
export type OnDealCompleteSubscriptionHookResult = ReturnType<typeof useOnDealCompleteSubscription>;
export type OnDealCompleteSubscriptionResult =
  Apollo.SubscriptionResult<OnDealCompleteSubscription>;
export const CustomDatesUpdateDocument = gql`
  mutation customDatesUpdate($deal_id: ID!, $date_key: String!, $date: DateTime!) {
    customDatesUpdate(deal_id: $deal_id, date_key: $date_key, date: $date) {
      id
      custom_dates
    }
  }
`;
export type CustomDatesUpdateMutationFn = Apollo.MutationFunction<
  CustomDatesUpdateMutation,
  CustomDatesUpdateMutationVariables
>;

/**
 * __useCustomDatesUpdateMutation__
 *
 * To run a mutation, you first call `useCustomDatesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomDatesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customDatesUpdateMutation, { data, loading, error }] = useCustomDatesUpdateMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      date_key: // value for 'date_key'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCustomDatesUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomDatesUpdateMutation,
    CustomDatesUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomDatesUpdateMutation, CustomDatesUpdateMutationVariables>(
    CustomDatesUpdateDocument,
    options,
  );
}
export type CustomDatesUpdateMutationHookResult = ReturnType<typeof useCustomDatesUpdateMutation>;
export type CustomDatesUpdateMutationResult = Apollo.MutationResult<CustomDatesUpdateMutation>;
export type CustomDatesUpdateMutationOptions = Apollo.BaseMutationOptions<
  CustomDatesUpdateMutation,
  CustomDatesUpdateMutationVariables
>;
export const DealStatesDocument = gql`
  query dealStates($dealId: ID!) {
    dealStates(dealId: $dealId) {
      id
      state
      updated_date_utc
      updated_at
      user {
        name
      }
    }
  }
`;

/**
 * __useDealStatesQuery__
 *
 * To run a query within a React component, call `useDealStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealStatesQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useDealStatesQuery(
  baseOptions: Apollo.QueryHookOptions<DealStatesQuery, DealStatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealStatesQuery, DealStatesQueryVariables>(DealStatesDocument, options);
}
export function useDealStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealStatesQuery, DealStatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealStatesQuery, DealStatesQueryVariables>(
    DealStatesDocument,
    options,
  );
}
export type DealStatesQueryHookResult = ReturnType<typeof useDealStatesQuery>;
export type DealStatesLazyQueryHookResult = ReturnType<typeof useDealStatesLazyQuery>;
export type DealStatesQueryResult = Apollo.QueryResult<DealStatesQuery, DealStatesQueryVariables>;
export const GenerateDocumentsDocument = gql`
  query generateDocuments($data: documentsInput) {
    generateDocuments(data: $data) {
      files {
        url
        filename
        key
        mediaType
      }
      errors
    }
  }
`;

/**
 * __useGenerateDocumentsQuery__
 *
 * To run a query within a React component, call `useGenerateDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateDocumentsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGenerateDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GenerateDocumentsQuery, GenerateDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateDocumentsQuery, GenerateDocumentsQueryVariables>(
    GenerateDocumentsDocument,
    options,
  );
}
export function useGenerateDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateDocumentsQuery,
    GenerateDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerateDocumentsQuery, GenerateDocumentsQueryVariables>(
    GenerateDocumentsDocument,
    options,
  );
}
export type GenerateDocumentsQueryHookResult = ReturnType<typeof useGenerateDocumentsQuery>;
export type GenerateDocumentsLazyQueryHookResult = ReturnType<typeof useGenerateDocumentsLazyQuery>;
export type GenerateDocumentsQueryResult = Apollo.QueryResult<
  GenerateDocumentsQuery,
  GenerateDocumentsQueryVariables
>;
export const RetrieveDealDocsUploadedToR1Document = gql`
  query retrieveDealDocsUploadedToR1($dealJacketID: String!, $includePdfBinary: Boolean) {
    retrieveDealDocsUploadedToR1(dealJacketID: $dealJacketID, includePdfBinary: $includePdfBinary) {
      documentName
      documentType
      signatureStatus
    }
  }
`;

/**
 * __useRetrieveDealDocsUploadedToR1Query__
 *
 * To run a query within a React component, call `useRetrieveDealDocsUploadedToR1Query` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveDealDocsUploadedToR1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveDealDocsUploadedToR1Query({
 *   variables: {
 *      dealJacketID: // value for 'dealJacketID'
 *      includePdfBinary: // value for 'includePdfBinary'
 *   },
 * });
 */
export function useRetrieveDealDocsUploadedToR1Query(
  baseOptions: Apollo.QueryHookOptions<
    RetrieveDealDocsUploadedToR1Query,
    RetrieveDealDocsUploadedToR1QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RetrieveDealDocsUploadedToR1Query,
    RetrieveDealDocsUploadedToR1QueryVariables
  >(RetrieveDealDocsUploadedToR1Document, options);
}
export function useRetrieveDealDocsUploadedToR1LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrieveDealDocsUploadedToR1Query,
    RetrieveDealDocsUploadedToR1QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RetrieveDealDocsUploadedToR1Query,
    RetrieveDealDocsUploadedToR1QueryVariables
  >(RetrieveDealDocsUploadedToR1Document, options);
}
export type RetrieveDealDocsUploadedToR1QueryHookResult = ReturnType<
  typeof useRetrieveDealDocsUploadedToR1Query
>;
export type RetrieveDealDocsUploadedToR1LazyQueryHookResult = ReturnType<
  typeof useRetrieveDealDocsUploadedToR1LazyQuery
>;
export type RetrieveDealDocsUploadedToR1QueryResult = Apollo.QueryResult<
  RetrieveDealDocsUploadedToR1Query,
  RetrieveDealDocsUploadedToR1QueryVariables
>;
export const ValidateBankContractDocument = gql`
  query validateBankContract($deal_id: ID!) {
    validateBankContract(deal_id: $deal_id)
  }
`;

/**
 * __useValidateBankContractQuery__
 *
 * To run a query within a React component, call `useValidateBankContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateBankContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateBankContractQuery({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *   },
 * });
 */
export function useValidateBankContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateBankContractQuery,
    ValidateBankContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateBankContractQuery, ValidateBankContractQueryVariables>(
    ValidateBankContractDocument,
    options,
  );
}
export function useValidateBankContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateBankContractQuery,
    ValidateBankContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateBankContractQuery, ValidateBankContractQueryVariables>(
    ValidateBankContractDocument,
    options,
  );
}
export type ValidateBankContractQueryHookResult = ReturnType<typeof useValidateBankContractQuery>;
export type ValidateBankContractLazyQueryHookResult = ReturnType<
  typeof useValidateBankContractLazyQuery
>;
export type ValidateBankContractQueryResult = Apollo.QueryResult<
  ValidateBankContractQuery,
  ValidateBankContractQueryVariables
>;
export const GeneratePurchaseOrderDocument = gql`
  query generatePurchaseOrder($deal_id: ID!) {
    generatePurchaseOrder(deal_id: $deal_id) {
      files {
        url
        filename
        key
      }
    }
  }
`;

/**
 * __useGeneratePurchaseOrderQuery__
 *
 * To run a query within a React component, call `useGeneratePurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratePurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratePurchaseOrderQuery({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *   },
 * });
 */
export function useGeneratePurchaseOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratePurchaseOrderQuery,
    GeneratePurchaseOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeneratePurchaseOrderQuery, GeneratePurchaseOrderQueryVariables>(
    GeneratePurchaseOrderDocument,
    options,
  );
}
export function useGeneratePurchaseOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratePurchaseOrderQuery,
    GeneratePurchaseOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GeneratePurchaseOrderQuery, GeneratePurchaseOrderQueryVariables>(
    GeneratePurchaseOrderDocument,
    options,
  );
}
export type GeneratePurchaseOrderQueryHookResult = ReturnType<typeof useGeneratePurchaseOrderQuery>;
export type GeneratePurchaseOrderLazyQueryHookResult = ReturnType<
  typeof useGeneratePurchaseOrderLazyQuery
>;
export type GeneratePurchaseOrderQueryResult = Apollo.QueryResult<
  GeneratePurchaseOrderQuery,
  GeneratePurchaseOrderQueryVariables
>;
export const SendPurchaseOrderDocument = gql`
  query sendPurchaseOrder($deal_id: ID!, $key: String!) {
    sendPurchaseOrder(deal_id: $deal_id, key: $key)
  }
`;

/**
 * __useSendPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useSendPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendPurchaseOrderQuery({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useSendPurchaseOrderQuery(
  baseOptions: Apollo.QueryHookOptions<SendPurchaseOrderQuery, SendPurchaseOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendPurchaseOrderQuery, SendPurchaseOrderQueryVariables>(
    SendPurchaseOrderDocument,
    options,
  );
}
export function useSendPurchaseOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SendPurchaseOrderQuery,
    SendPurchaseOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendPurchaseOrderQuery, SendPurchaseOrderQueryVariables>(
    SendPurchaseOrderDocument,
    options,
  );
}
export type SendPurchaseOrderQueryHookResult = ReturnType<typeof useSendPurchaseOrderQuery>;
export type SendPurchaseOrderLazyQueryHookResult = ReturnType<typeof useSendPurchaseOrderLazyQuery>;
export type SendPurchaseOrderQueryResult = Apollo.QueryResult<
  SendPurchaseOrderQuery,
  SendPurchaseOrderQueryVariables
>;
export const UploadDocumentToRouteOneDocument = gql`
  mutation uploadDocumentToRouteOne(
    $jacketId: String
    $doc: uploadDocumentsInput
    $withCobuyer: Boolean
  ) {
    uploadDocumentToRouteOne(jacketId: $jacketId, doc: $doc, withCobuyer: $withCobuyer)
  }
`;
export type UploadDocumentToRouteOneMutationFn = Apollo.MutationFunction<
  UploadDocumentToRouteOneMutation,
  UploadDocumentToRouteOneMutationVariables
>;

/**
 * __useUploadDocumentToRouteOneMutation__
 *
 * To run a mutation, you first call `useUploadDocumentToRouteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentToRouteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentToRouteOneMutation, { data, loading, error }] = useUploadDocumentToRouteOneMutation({
 *   variables: {
 *      jacketId: // value for 'jacketId'
 *      doc: // value for 'doc'
 *      withCobuyer: // value for 'withCobuyer'
 *   },
 * });
 */
export function useUploadDocumentToRouteOneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadDocumentToRouteOneMutation,
    UploadDocumentToRouteOneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadDocumentToRouteOneMutation,
    UploadDocumentToRouteOneMutationVariables
  >(UploadDocumentToRouteOneDocument, options);
}
export type UploadDocumentToRouteOneMutationHookResult = ReturnType<
  typeof useUploadDocumentToRouteOneMutation
>;
export type UploadDocumentToRouteOneMutationResult =
  Apollo.MutationResult<UploadDocumentToRouteOneMutation>;
export type UploadDocumentToRouteOneMutationOptions = Apollo.BaseMutationOptions<
  UploadDocumentToRouteOneMutation,
  UploadDocumentToRouteOneMutationVariables
>;
export const SetReadyForSignaturesStatusDocument = gql`
  mutation setReadyForSignaturesStatus(
    $jacketId: String
    $documentProgressStatus: DocumentProgressStatus
  ) {
    setReadyForSignaturesStatus(
      jacketId: $jacketId
      documentProgressStatus: $documentProgressStatus
    )
  }
`;
export type SetReadyForSignaturesStatusMutationFn = Apollo.MutationFunction<
  SetReadyForSignaturesStatusMutation,
  SetReadyForSignaturesStatusMutationVariables
>;

/**
 * __useSetReadyForSignaturesStatusMutation__
 *
 * To run a mutation, you first call `useSetReadyForSignaturesStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReadyForSignaturesStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReadyForSignaturesStatusMutation, { data, loading, error }] = useSetReadyForSignaturesStatusMutation({
 *   variables: {
 *      jacketId: // value for 'jacketId'
 *      documentProgressStatus: // value for 'documentProgressStatus'
 *   },
 * });
 */
export function useSetReadyForSignaturesStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetReadyForSignaturesStatusMutation,
    SetReadyForSignaturesStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetReadyForSignaturesStatusMutation,
    SetReadyForSignaturesStatusMutationVariables
  >(SetReadyForSignaturesStatusDocument, options);
}
export type SetReadyForSignaturesStatusMutationHookResult = ReturnType<
  typeof useSetReadyForSignaturesStatusMutation
>;
export type SetReadyForSignaturesStatusMutationResult =
  Apollo.MutationResult<SetReadyForSignaturesStatusMutation>;
export type SetReadyForSignaturesStatusMutationOptions = Apollo.BaseMutationOptions<
  SetReadyForSignaturesStatusMutation,
  SetReadyForSignaturesStatusMutationVariables
>;
export const MergeFilesDocument = gql`
  mutation mergeFiles(
    $deal_id: ID!
    $docs: [dealMediaInput]
    $missing_required_media_list: [dealMediaTypeEnum]
  ) {
    mergeFiles(
      deal_id: $deal_id
      docs: $docs
      missing_required_media_list: $missing_required_media_list
    ) {
      url
      filename
      key
    }
  }
`;
export type MergeFilesMutationFn = Apollo.MutationFunction<
  MergeFilesMutation,
  MergeFilesMutationVariables
>;

/**
 * __useMergeFilesMutation__
 *
 * To run a mutation, you first call `useMergeFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeFilesMutation, { data, loading, error }] = useMergeFilesMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      docs: // value for 'docs'
 *      missing_required_media_list: // value for 'missing_required_media_list'
 *   },
 * });
 */
export function useMergeFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<MergeFilesMutation, MergeFilesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MergeFilesMutation, MergeFilesMutationVariables>(
    MergeFilesDocument,
    options,
  );
}
export type MergeFilesMutationHookResult = ReturnType<typeof useMergeFilesMutation>;
export type MergeFilesMutationResult = Apollo.MutationResult<MergeFilesMutation>;
export type MergeFilesMutationOptions = Apollo.BaseMutationOptions<
  MergeFilesMutation,
  MergeFilesMutationVariables
>;
export const AddDocumentsToMediaCenterDocument = gql`
  mutation addDocumentsToMediaCenter($deal_id: ID!, $files: [addDocumentsToMediaCenterInput]!) {
    addDocumentsToMediaCenter(deal_id: $deal_id, files: $files)
  }
`;
export type AddDocumentsToMediaCenterMutationFn = Apollo.MutationFunction<
  AddDocumentsToMediaCenterMutation,
  AddDocumentsToMediaCenterMutationVariables
>;

/**
 * __useAddDocumentsToMediaCenterMutation__
 *
 * To run a mutation, you first call `useAddDocumentsToMediaCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentsToMediaCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentsToMediaCenterMutation, { data, loading, error }] = useAddDocumentsToMediaCenterMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useAddDocumentsToMediaCenterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDocumentsToMediaCenterMutation,
    AddDocumentsToMediaCenterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddDocumentsToMediaCenterMutation,
    AddDocumentsToMediaCenterMutationVariables
  >(AddDocumentsToMediaCenterDocument, options);
}
export type AddDocumentsToMediaCenterMutationHookResult = ReturnType<
  typeof useAddDocumentsToMediaCenterMutation
>;
export type AddDocumentsToMediaCenterMutationResult =
  Apollo.MutationResult<AddDocumentsToMediaCenterMutation>;
export type AddDocumentsToMediaCenterMutationOptions = Apollo.BaseMutationOptions<
  AddDocumentsToMediaCenterMutation,
  AddDocumentsToMediaCenterMutationVariables
>;
export const SendDocumentsWithAdobeAndUpdateStateDocument = gql`
  mutation sendDocumentsWithAdobeAndUpdateState(
    $deal_id: ID!
    $keys: [String]!
    $state: dealStateEnum!
  ) {
    sendDocumentsWithAdobeAndUpdateState(deal_id: $deal_id, keys: $keys, state: $state) {
      id
      state
    }
  }
`;
export type SendDocumentsWithAdobeAndUpdateStateMutationFn = Apollo.MutationFunction<
  SendDocumentsWithAdobeAndUpdateStateMutation,
  SendDocumentsWithAdobeAndUpdateStateMutationVariables
>;

/**
 * __useSendDocumentsWithAdobeAndUpdateStateMutation__
 *
 * To run a mutation, you first call `useSendDocumentsWithAdobeAndUpdateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDocumentsWithAdobeAndUpdateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDocumentsWithAdobeAndUpdateStateMutation, { data, loading, error }] = useSendDocumentsWithAdobeAndUpdateStateMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      keys: // value for 'keys'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSendDocumentsWithAdobeAndUpdateStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendDocumentsWithAdobeAndUpdateStateMutation,
    SendDocumentsWithAdobeAndUpdateStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendDocumentsWithAdobeAndUpdateStateMutation,
    SendDocumentsWithAdobeAndUpdateStateMutationVariables
  >(SendDocumentsWithAdobeAndUpdateStateDocument, options);
}
export type SendDocumentsWithAdobeAndUpdateStateMutationHookResult = ReturnType<
  typeof useSendDocumentsWithAdobeAndUpdateStateMutation
>;
export type SendDocumentsWithAdobeAndUpdateStateMutationResult =
  Apollo.MutationResult<SendDocumentsWithAdobeAndUpdateStateMutation>;
export type SendDocumentsWithAdobeAndUpdateStateMutationOptions = Apollo.BaseMutationOptions<
  SendDocumentsWithAdobeAndUpdateStateMutation,
  SendDocumentsWithAdobeAndUpdateStateMutationVariables
>;
export const OnContractValidationDocument = gql`
  subscription onContractValidation($fundingClerkOnly: Boolean, $deal_id: ID) {
    onContractValidation(fundingClerkOnly: $fundingClerkOnly, deal_id: $deal_id) {
      id
      document_progress_status
      r1_contract_validation_errors_object {
        errors
      }
      r1_contract_validation_warnings_object {
        warnings
      }
      r1_contract_manually_validated
      customer {
        first_name
        last_name
      }
    }
  }
`;

/**
 * __useOnContractValidationSubscription__
 *
 * To run a query within a React component, call `useOnContractValidationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnContractValidationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnContractValidationSubscription({
 *   variables: {
 *      fundingClerkOnly: // value for 'fundingClerkOnly'
 *      deal_id: // value for 'deal_id'
 *   },
 * });
 */
export function useOnContractValidationSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnContractValidationSubscription,
    OnContractValidationSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnContractValidationSubscription,
    OnContractValidationSubscriptionVariables
  >(OnContractValidationDocument, options);
}
export type OnContractValidationSubscriptionHookResult = ReturnType<
  typeof useOnContractValidationSubscription
>;
export type OnContractValidationSubscriptionResult =
  Apollo.SubscriptionResult<OnContractValidationSubscription>;
export const OnDashboardDocumentUploadDocument = gql`
  subscription onDashboardDocumentUpload {
    onDashboardDocumentUpload {
      deal {
        id
        customer {
          first_name
          last_name
        }
      }
      fileType
    }
  }
`;

/**
 * __useOnDashboardDocumentUploadSubscription__
 *
 * To run a query within a React component, call `useOnDashboardDocumentUploadSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDashboardDocumentUploadSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDashboardDocumentUploadSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDashboardDocumentUploadSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnDashboardDocumentUploadSubscription,
    OnDashboardDocumentUploadSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnDashboardDocumentUploadSubscription,
    OnDashboardDocumentUploadSubscriptionVariables
  >(OnDashboardDocumentUploadDocument, options);
}
export type OnDashboardDocumentUploadSubscriptionHookResult = ReturnType<
  typeof useOnDashboardDocumentUploadSubscription
>;
export type OnDashboardDocumentUploadSubscriptionResult =
  Apollo.SubscriptionResult<OnDashboardDocumentUploadSubscription>;
export const OnDownPaymentReceivedDocument = gql`
  subscription onDownPaymentReceived {
    onDownPaymentReceived {
      amount
      dealId
      firstName
      lastName
      type
    }
  }
`;

/**
 * __useOnDownPaymentReceivedSubscription__
 *
 * To run a query within a React component, call `useOnDownPaymentReceivedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDownPaymentReceivedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDownPaymentReceivedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDownPaymentReceivedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnDownPaymentReceivedSubscription,
    OnDownPaymentReceivedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnDownPaymentReceivedSubscription,
    OnDownPaymentReceivedSubscriptionVariables
  >(OnDownPaymentReceivedDocument, options);
}
export type OnDownPaymentReceivedSubscriptionHookResult = ReturnType<
  typeof useOnDownPaymentReceivedSubscription
>;
export type OnDownPaymentReceivedSubscriptionResult =
  Apollo.SubscriptionResult<OnDownPaymentReceivedSubscription>;
export const NewEstimateUpsertDocument = gql`
  mutation newEstimateUpsert($newEstimateUpsertInput: newEstimateUpsertInput) {
    newEstimateUpsert(newEstimateUpsertInput: $newEstimateUpsertInput) {
      ...DealFragment
    }
  }
  ${DealFragmentFragmentDoc}
`;
export type NewEstimateUpsertMutationFn = Apollo.MutationFunction<
  NewEstimateUpsertMutation,
  NewEstimateUpsertMutationVariables
>;

/**
 * __useNewEstimateUpsertMutation__
 *
 * To run a mutation, you first call `useNewEstimateUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewEstimateUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newEstimateUpsertMutation, { data, loading, error }] = useNewEstimateUpsertMutation({
 *   variables: {
 *      newEstimateUpsertInput: // value for 'newEstimateUpsertInput'
 *   },
 * });
 */
export function useNewEstimateUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NewEstimateUpsertMutation,
    NewEstimateUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NewEstimateUpsertMutation, NewEstimateUpsertMutationVariables>(
    NewEstimateUpsertDocument,
    options,
  );
}
export type NewEstimateUpsertMutationHookResult = ReturnType<typeof useNewEstimateUpsertMutation>;
export type NewEstimateUpsertMutationResult = Apollo.MutationResult<NewEstimateUpsertMutation>;
export type NewEstimateUpsertMutationOptions = Apollo.BaseMutationOptions<
  NewEstimateUpsertMutation,
  NewEstimateUpsertMutationVariables
>;
export const ManuallySelectBankDocument = gql`
  mutation manuallySelectBank($dealId: ID!, $bank: String!) {
    manuallySelectBank(dealId: $dealId, bank: $bank) {
      id
      bank
      selected_credit_decision_id
      first_payment_date
    }
  }
`;
export type ManuallySelectBankMutationFn = Apollo.MutationFunction<
  ManuallySelectBankMutation,
  ManuallySelectBankMutationVariables
>;

/**
 * __useManuallySelectBankMutation__
 *
 * To run a mutation, you first call `useManuallySelectBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManuallySelectBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manuallySelectBankMutation, { data, loading, error }] = useManuallySelectBankMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      bank: // value for 'bank'
 *   },
 * });
 */
export function useManuallySelectBankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManuallySelectBankMutation,
    ManuallySelectBankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManuallySelectBankMutation, ManuallySelectBankMutationVariables>(
    ManuallySelectBankDocument,
    options,
  );
}
export type ManuallySelectBankMutationHookResult = ReturnType<typeof useManuallySelectBankMutation>;
export type ManuallySelectBankMutationResult = Apollo.MutationResult<ManuallySelectBankMutation>;
export type ManuallySelectBankMutationOptions = Apollo.BaseMutationOptions<
  ManuallySelectBankMutation,
  ManuallySelectBankMutationVariables
>;
export const UpdateProcessorDocument = gql`
  mutation updateProcessor($deal_id: ID!, $fi_id: ID!, $processor: String) {
    updateProcessor(deal_id: $deal_id, fi_id: $fi_id, processor: $processor) {
      id
      processor
    }
  }
`;
export type UpdateProcessorMutationFn = Apollo.MutationFunction<
  UpdateProcessorMutation,
  UpdateProcessorMutationVariables
>;

/**
 * __useUpdateProcessorMutation__
 *
 * To run a mutation, you first call `useUpdateProcessorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcessorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProcessorMutation, { data, loading, error }] = useUpdateProcessorMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      fi_id: // value for 'fi_id'
 *      processor: // value for 'processor'
 *   },
 * });
 */
export function useUpdateProcessorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProcessorMutation,
    UpdateProcessorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProcessorMutation, UpdateProcessorMutationVariables>(
    UpdateProcessorDocument,
    options,
  );
}
export type UpdateProcessorMutationHookResult = ReturnType<typeof useUpdateProcessorMutation>;
export type UpdateProcessorMutationResult = Apollo.MutationResult<UpdateProcessorMutation>;
export type UpdateProcessorMutationOptions = Apollo.BaseMutationOptions<
  UpdateProcessorMutation,
  UpdateProcessorMutationVariables
>;
export const OnTransactionCreatedDocument = gql`
  subscription onTransactionCreated($dealId: ID!) {
    onTransactionCreated(dealId: $dealId) {
      deal_id
      tt_transaction_id
    }
  }
`;

/**
 * __useOnTransactionCreatedSubscription__
 *
 * To run a query within a React component, call `useOnTransactionCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTransactionCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTransactionCreatedSubscription({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useOnTransactionCreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnTransactionCreatedSubscription,
    OnTransactionCreatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnTransactionCreatedSubscription,
    OnTransactionCreatedSubscriptionVariables
  >(OnTransactionCreatedDocument, options);
}
export type OnTransactionCreatedSubscriptionHookResult = ReturnType<
  typeof useOnTransactionCreatedSubscription
>;
export type OnTransactionCreatedSubscriptionResult =
  Apollo.SubscriptionResult<OnTransactionCreatedSubscription>;
export const FinancialInfoAcquisitionUpsertDocument = gql`
  mutation financialInfoAcquisitionUpsert(
    $financialInfoAcquisition: financialInfoAcquisitionInput
    $id: ID!
    $state: String!
    $car_id: ID
    $mmr: Float
    $vehicle_payoff: Float
    $override: Boolean
    $clear_override: Boolean
  ) {
    financialInfoAcquisitionUpsert(
      financialInfoAcquisition: $financialInfoAcquisition
      id: $id
      state: $state
      car_id: $car_id
      mmr: $mmr
      vehicle_payoff: $vehicle_payoff
      override: $override
      clear_override: $clear_override
    ) {
      id
    }
  }
`;
export type FinancialInfoAcquisitionUpsertMutationFn = Apollo.MutationFunction<
  FinancialInfoAcquisitionUpsertMutation,
  FinancialInfoAcquisitionUpsertMutationVariables
>;

/**
 * __useFinancialInfoAcquisitionUpsertMutation__
 *
 * To run a mutation, you first call `useFinancialInfoAcquisitionUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinancialInfoAcquisitionUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [financialInfoAcquisitionUpsertMutation, { data, loading, error }] = useFinancialInfoAcquisitionUpsertMutation({
 *   variables: {
 *      financialInfoAcquisition: // value for 'financialInfoAcquisition'
 *      id: // value for 'id'
 *      state: // value for 'state'
 *      car_id: // value for 'car_id'
 *      mmr: // value for 'mmr'
 *      vehicle_payoff: // value for 'vehicle_payoff'
 *      override: // value for 'override'
 *      clear_override: // value for 'clear_override'
 *   },
 * });
 */
export function useFinancialInfoAcquisitionUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinancialInfoAcquisitionUpsertMutation,
    FinancialInfoAcquisitionUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinancialInfoAcquisitionUpsertMutation,
    FinancialInfoAcquisitionUpsertMutationVariables
  >(FinancialInfoAcquisitionUpsertDocument, options);
}
export type FinancialInfoAcquisitionUpsertMutationHookResult = ReturnType<
  typeof useFinancialInfoAcquisitionUpsertMutation
>;
export type FinancialInfoAcquisitionUpsertMutationResult =
  Apollo.MutationResult<FinancialInfoAcquisitionUpsertMutation>;
export type FinancialInfoAcquisitionUpsertMutationOptions = Apollo.BaseMutationOptions<
  FinancialInfoAcquisitionUpsertMutation,
  FinancialInfoAcquisitionUpsertMutationVariables
>;
export const GetFollowUpDealsPaginatedDocument = gql`
  query getFollowUpDealsPaginated(
    $assigned_to_me_filter: Boolean
    $scheduledByMeFilter: Boolean
    $boot_requested: Boolean
    $assigned_to_deleted_users_filter: Boolean
    $notifications: Boolean
    $page: Int
    $pod_id: ID
    $sort_column: String!
    $sort_direction: String!
    $sources: [String]!
    $types: [DealType]!
    $results_per_page: Int
    $showActiveTags: Boolean
    $showMatureFollowUps: Boolean
  ) {
    getFollowUpDealsPaginated(
      assigned_to_me_filter: $assigned_to_me_filter
      scheduledByMeFilter: $scheduledByMeFilter
      boot_requested: $boot_requested
      assigned_to_deleted_users_filter: $assigned_to_deleted_users_filter
      notifications: $notifications
      page: $page
      pod_id: $pod_id
      results_per_page: $results_per_page
      sort_column: $sort_column
      sort_direction: $sort_direction
      sources: $sources
      types: $types
      showActiveTags: $showActiveTags
      showMatureFollowUps: $showMatureFollowUps
    ) {
      deals {
        id
        state
        customer {
          id
          first_name
          last_name
          phone_number
        }
        follow_up {
          id
          creator_id
          deal_id
          date_utc
          note
          status
          tz
          type
          creator {
            id
            name
          }
        }
        notifications {
          id
          message
        }
      }
      totalRecords
    }
  }
`;

/**
 * __useGetFollowUpDealsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetFollowUpDealsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowUpDealsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowUpDealsPaginatedQuery({
 *   variables: {
 *      assigned_to_me_filter: // value for 'assigned_to_me_filter'
 *      scheduledByMeFilter: // value for 'scheduledByMeFilter'
 *      boot_requested: // value for 'boot_requested'
 *      assigned_to_deleted_users_filter: // value for 'assigned_to_deleted_users_filter'
 *      notifications: // value for 'notifications'
 *      page: // value for 'page'
 *      pod_id: // value for 'pod_id'
 *      sort_column: // value for 'sort_column'
 *      sort_direction: // value for 'sort_direction'
 *      sources: // value for 'sources'
 *      types: // value for 'types'
 *      results_per_page: // value for 'results_per_page'
 *      showActiveTags: // value for 'showActiveTags'
 *      showMatureFollowUps: // value for 'showMatureFollowUps'
 *   },
 * });
 */
export function useGetFollowUpDealsPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFollowUpDealsPaginatedQuery,
    GetFollowUpDealsPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFollowUpDealsPaginatedQuery, GetFollowUpDealsPaginatedQueryVariables>(
    GetFollowUpDealsPaginatedDocument,
    options,
  );
}
export function useGetFollowUpDealsPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFollowUpDealsPaginatedQuery,
    GetFollowUpDealsPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFollowUpDealsPaginatedQuery,
    GetFollowUpDealsPaginatedQueryVariables
  >(GetFollowUpDealsPaginatedDocument, options);
}
export type GetFollowUpDealsPaginatedQueryHookResult = ReturnType<
  typeof useGetFollowUpDealsPaginatedQuery
>;
export type GetFollowUpDealsPaginatedLazyQueryHookResult = ReturnType<
  typeof useGetFollowUpDealsPaginatedLazyQuery
>;
export type GetFollowUpDealsPaginatedQueryResult = Apollo.QueryResult<
  GetFollowUpDealsPaginatedQuery,
  GetFollowUpDealsPaginatedQueryVariables
>;
export const FollowUpDocument = gql`
  query followUp($deal_id: ID!) {
    followUp(deal_id: $deal_id) {
      id
      creator_id
      deal_id
      date_utc
      note
      status
      tz
      type
    }
  }
`;

/**
 * __useFollowUpQuery__
 *
 * To run a query within a React component, call `useFollowUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowUpQuery({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *   },
 * });
 */
export function useFollowUpQuery(
  baseOptions: Apollo.QueryHookOptions<FollowUpQuery, FollowUpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FollowUpQuery, FollowUpQueryVariables>(FollowUpDocument, options);
}
export function useFollowUpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FollowUpQuery, FollowUpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FollowUpQuery, FollowUpQueryVariables>(FollowUpDocument, options);
}
export type FollowUpQueryHookResult = ReturnType<typeof useFollowUpQuery>;
export type FollowUpLazyQueryHookResult = ReturnType<typeof useFollowUpLazyQuery>;
export type FollowUpQueryResult = Apollo.QueryResult<FollowUpQuery, FollowUpQueryVariables>;
export const FollowUpInsertDocument = gql`
  mutation followUpInsert(
    $deal_id: ID!
    $type: followUpTypeEnum!
    $date_utc: DateTime!
    $tz: String!
    $note: String
  ) {
    followUpInsert(deal_id: $deal_id, type: $type, date_utc: $date_utc, tz: $tz, note: $note) {
      id
      creator_id
      deal_id
      date_utc
      note
      status
      tz
      type
    }
  }
`;
export type FollowUpInsertMutationFn = Apollo.MutationFunction<
  FollowUpInsertMutation,
  FollowUpInsertMutationVariables
>;

/**
 * __useFollowUpInsertMutation__
 *
 * To run a mutation, you first call `useFollowUpInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUpInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUpInsertMutation, { data, loading, error }] = useFollowUpInsertMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      type: // value for 'type'
 *      date_utc: // value for 'date_utc'
 *      tz: // value for 'tz'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useFollowUpInsertMutation(
  baseOptions?: Apollo.MutationHookOptions<FollowUpInsertMutation, FollowUpInsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FollowUpInsertMutation, FollowUpInsertMutationVariables>(
    FollowUpInsertDocument,
    options,
  );
}
export type FollowUpInsertMutationHookResult = ReturnType<typeof useFollowUpInsertMutation>;
export type FollowUpInsertMutationResult = Apollo.MutationResult<FollowUpInsertMutation>;
export type FollowUpInsertMutationOptions = Apollo.BaseMutationOptions<
  FollowUpInsertMutation,
  FollowUpInsertMutationVariables
>;
export const FollowUpUpdateDocument = gql`
  mutation followUpUpdate(
    $id: ID!
    $type: followUpTypeEnum!
    $date_utc: DateTime!
    $tz: String!
    $deal_id: ID!
    $note: String
  ) {
    followUpUpdate(
      id: $id
      type: $type
      date_utc: $date_utc
      tz: $tz
      deal_id: $deal_id
      note: $note
    ) {
      id
      creator_id
      deal_id
      date_utc
      note
      status
      tz
      type
    }
  }
`;
export type FollowUpUpdateMutationFn = Apollo.MutationFunction<
  FollowUpUpdateMutation,
  FollowUpUpdateMutationVariables
>;

/**
 * __useFollowUpUpdateMutation__
 *
 * To run a mutation, you first call `useFollowUpUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUpUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUpUpdateMutation, { data, loading, error }] = useFollowUpUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      date_utc: // value for 'date_utc'
 *      tz: // value for 'tz'
 *      deal_id: // value for 'deal_id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useFollowUpUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<FollowUpUpdateMutation, FollowUpUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FollowUpUpdateMutation, FollowUpUpdateMutationVariables>(
    FollowUpUpdateDocument,
    options,
  );
}
export type FollowUpUpdateMutationHookResult = ReturnType<typeof useFollowUpUpdateMutation>;
export type FollowUpUpdateMutationResult = Apollo.MutationResult<FollowUpUpdateMutation>;
export type FollowUpUpdateMutationOptions = Apollo.BaseMutationOptions<
  FollowUpUpdateMutation,
  FollowUpUpdateMutationVariables
>;
export const FollowUpDeleteDocument = gql`
  mutation followUpDelete($id: ID!) {
    followUpDelete(id: $id) {
      id
    }
  }
`;
export type FollowUpDeleteMutationFn = Apollo.MutationFunction<
  FollowUpDeleteMutation,
  FollowUpDeleteMutationVariables
>;

/**
 * __useFollowUpDeleteMutation__
 *
 * To run a mutation, you first call `useFollowUpDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUpDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUpDeleteMutation, { data, loading, error }] = useFollowUpDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFollowUpDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<FollowUpDeleteMutation, FollowUpDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FollowUpDeleteMutation, FollowUpDeleteMutationVariables>(
    FollowUpDeleteDocument,
    options,
  );
}
export type FollowUpDeleteMutationHookResult = ReturnType<typeof useFollowUpDeleteMutation>;
export type FollowUpDeleteMutationResult = Apollo.MutationResult<FollowUpDeleteMutation>;
export type FollowUpDeleteMutationOptions = Apollo.BaseMutationOptions<
  FollowUpDeleteMutation,
  FollowUpDeleteMutationVariables
>;
export const OnFollowUpUpdateDocument = gql`
  subscription onFollowUpUpdate(
    $dealId: ID
    $sources: [String]
    $types: [DealType]
    $scheduledByMeFilter: Boolean
    $followUpType: followUpTypeEnum
    $followUpStatus: followUpStatusEnum
  ) {
    onFollowUpUpdate(
      dealId: $dealId
      sources: $sources
      types: $types
      scheduledByMeFilter: $scheduledByMeFilter
      followUpType: $followUpType
      followUpStatus: $followUpStatus
    ) {
      id
      state
      customer {
        id
        first_name
        last_name
      }
      follow_up {
        id
        creator_id
        deal_id
        date_utc
        note
        status
        tz
        type
        creator {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useOnFollowUpUpdateSubscription__
 *
 * To run a query within a React component, call `useOnFollowUpUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnFollowUpUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnFollowUpUpdateSubscription({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      sources: // value for 'sources'
 *      types: // value for 'types'
 *      scheduledByMeFilter: // value for 'scheduledByMeFilter'
 *      followUpType: // value for 'followUpType'
 *      followUpStatus: // value for 'followUpStatus'
 *   },
 * });
 */
export function useOnFollowUpUpdateSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnFollowUpUpdateSubscription,
    OnFollowUpUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnFollowUpUpdateSubscription,
    OnFollowUpUpdateSubscriptionVariables
  >(OnFollowUpUpdateDocument, options);
}
export type OnFollowUpUpdateSubscriptionHookResult = ReturnType<
  typeof useOnFollowUpUpdateSubscription
>;
export type OnFollowUpUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnFollowUpUpdateSubscription>;
export const InProgressCallsDocument = gql`
  subscription inProgressCalls {
    inProgressCalls {
      from
      to
      conference_id
      phone_number
      agent_number
      deal_id
      customer_name
      agent_user_id
      deal_state
      deal_type
      is_thunder
      hunt_group_slug
      is_outbound
    }
  }
`;

/**
 * __useInProgressCallsSubscription__
 *
 * To run a query within a React component, call `useInProgressCallsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInProgressCallsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInProgressCallsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInProgressCallsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    InProgressCallsSubscription,
    InProgressCallsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<InProgressCallsSubscription, InProgressCallsSubscriptionVariables>(
    InProgressCallsDocument,
    options,
  );
}
export type InProgressCallsSubscriptionHookResult = ReturnType<
  typeof useInProgressCallsSubscription
>;
export type InProgressCallsSubscriptionResult =
  Apollo.SubscriptionResult<InProgressCallsSubscription>;
export const JdpValuesDocument = gql`
  query jdpValues($vin: String!, $mileage: Float!, $stateCode: String) {
    jdpValues(vin: $vin, mileage: $mileage, stateCode: $stateCode) {
      jdp_mileage_adjustment
      jdp_adjusted_clean_retail
      jdp_adjusted_clean_trade
      jdp_trim_body
      jdp_valuation_date
      jdp_vehicle_accessories_object {
        vehicle_accessories {
          acccode
          accdesc
          accessorycategory
          excludes
          includes
          isadded
          isincluded
          loan
          retail
          tradein
        }
      }
    }
  }
`;

/**
 * __useJdpValuesQuery__
 *
 * To run a query within a React component, call `useJdpValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJdpValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJdpValuesQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      mileage: // value for 'mileage'
 *      stateCode: // value for 'stateCode'
 *   },
 * });
 */
export function useJdpValuesQuery(
  baseOptions: Apollo.QueryHookOptions<JdpValuesQuery, JdpValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JdpValuesQuery, JdpValuesQueryVariables>(JdpValuesDocument, options);
}
export function useJdpValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JdpValuesQuery, JdpValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JdpValuesQuery, JdpValuesQueryVariables>(JdpValuesDocument, options);
}
export type JdpValuesQueryHookResult = ReturnType<typeof useJdpValuesQuery>;
export type JdpValuesLazyQueryHookResult = ReturnType<typeof useJdpValuesLazyQuery>;
export type JdpValuesQueryResult = Apollo.QueryResult<JdpValuesQuery, JdpValuesQueryVariables>;
export const CreateJdpBookSheetDocument = gql`
  mutation createJDPBookSheet($dealId: ID!, $customerState: String!) {
    createJDPBookSheet(dealId: $dealId, customerState: $customerState)
  }
`;
export type CreateJdpBookSheetMutationFn = Apollo.MutationFunction<
  CreateJdpBookSheetMutation,
  CreateJdpBookSheetMutationVariables
>;

/**
 * __useCreateJdpBookSheetMutation__
 *
 * To run a mutation, you first call `useCreateJdpBookSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJdpBookSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJdpBookSheetMutation, { data, loading, error }] = useCreateJdpBookSheetMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      customerState: // value for 'customerState'
 *   },
 * });
 */
export function useCreateJdpBookSheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateJdpBookSheetMutation,
    CreateJdpBookSheetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateJdpBookSheetMutation, CreateJdpBookSheetMutationVariables>(
    CreateJdpBookSheetDocument,
    options,
  );
}
export type CreateJdpBookSheetMutationHookResult = ReturnType<typeof useCreateJdpBookSheetMutation>;
export type CreateJdpBookSheetMutationResult = Apollo.MutationResult<CreateJdpBookSheetMutation>;
export type CreateJdpBookSheetMutationOptions = Apollo.BaseMutationOptions<
  CreateJdpBookSheetMutation,
  CreateJdpBookSheetMutationVariables
>;
export const KbbVinDocument = gql`
  query kbbVin($vin: String!) {
    kbbVin(vin: $vin) {
      warning
      vinResults {
        vehicleId
        vehicleOptions {
          categoryGroup
          hasRelationships
          isConfigurable
          isConsumer
          isTypical
          isVinDecoded
          optionName
          optionType
          sortOrder
          vehicleId
          vehicleOptionId
        }
        modelName
        trimName
        makeName
        yearId
        vehicleName
      }
    }
  }
`;

/**
 * __useKbbVinQuery__
 *
 * To run a query within a React component, call `useKbbVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useKbbVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKbbVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useKbbVinQuery(
  baseOptions: Apollo.QueryHookOptions<KbbVinQuery, KbbVinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KbbVinQuery, KbbVinQueryVariables>(KbbVinDocument, options);
}
export function useKbbVinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KbbVinQuery, KbbVinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KbbVinQuery, KbbVinQueryVariables>(KbbVinDocument, options);
}
export type KbbVinQueryHookResult = ReturnType<typeof useKbbVinQuery>;
export type KbbVinLazyQueryHookResult = ReturnType<typeof useKbbVinLazyQuery>;
export type KbbVinQueryResult = Apollo.QueryResult<KbbVinQuery, KbbVinQueryVariables>;
export const KbbValuesDocument = gql`
  query kbbValues($data: kbbValuesDataType!) {
    kbbValues(data: $data) {
      retail
      retailMileageAdjustment
      retailOptionAdjustment
      retailOptionPrices
      lending
      lendingMileageAdjustment
      lendingOptionAdjustment
      lendingOptionPrices
      valuationDate
      kbbSelectedOptions {
        id
        name
        type
        removed
        lendingOptionPrice
        retailOptionPrice
      }
    }
  }
`;

/**
 * __useKbbValuesQuery__
 *
 * To run a query within a React component, call `useKbbValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKbbValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKbbValuesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useKbbValuesQuery(
  baseOptions: Apollo.QueryHookOptions<KbbValuesQuery, KbbValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KbbValuesQuery, KbbValuesQueryVariables>(KbbValuesDocument, options);
}
export function useKbbValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KbbValuesQuery, KbbValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KbbValuesQuery, KbbValuesQueryVariables>(KbbValuesDocument, options);
}
export type KbbValuesQueryHookResult = ReturnType<typeof useKbbValuesQuery>;
export type KbbValuesLazyQueryHookResult = ReturnType<typeof useKbbValuesLazyQuery>;
export type KbbValuesQueryResult = Apollo.QueryResult<KbbValuesQuery, KbbValuesQueryVariables>;
export const CalculateOptionsDocument = gql`
  query calculateOptions(
    $vehicleId: Int!
    $allKbbVehicleOptions: [kbbOptionInput]
    $startingVehicleOptions: [Int]
    $configToModify: [kbbConfigType]
    $vin: String!
    $color: String
  ) {
    calculateOptions(
      vehicleId: $vehicleId
      allKbbVehicleOptions: $allKbbVehicleOptions
      startingVehicleOptions: $startingVehicleOptions
      configToModify: $configToModify
      vin: $vin
      color: $color
    ) {
      vehicleOptionId
      optionName
      optionType
      categoryGroup
    }
  }
`;

/**
 * __useCalculateOptionsQuery__
 *
 * To run a query within a React component, call `useCalculateOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateOptionsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      allKbbVehicleOptions: // value for 'allKbbVehicleOptions'
 *      startingVehicleOptions: // value for 'startingVehicleOptions'
 *      configToModify: // value for 'configToModify'
 *      vin: // value for 'vin'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCalculateOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<CalculateOptionsQuery, CalculateOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalculateOptionsQuery, CalculateOptionsQueryVariables>(
    CalculateOptionsDocument,
    options,
  );
}
export function useCalculateOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalculateOptionsQuery, CalculateOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalculateOptionsQuery, CalculateOptionsQueryVariables>(
    CalculateOptionsDocument,
    options,
  );
}
export type CalculateOptionsQueryHookResult = ReturnType<typeof useCalculateOptionsQuery>;
export type CalculateOptionsLazyQueryHookResult = ReturnType<typeof useCalculateOptionsLazyQuery>;
export type CalculateOptionsQueryResult = Apollo.QueryResult<
  CalculateOptionsQuery,
  CalculateOptionsQueryVariables
>;
export const CreateKbbBookSheetDocument = gql`
  mutation createKBBBookSheet($dealId: ID!) {
    createKBBBookSheet(dealId: $dealId)
  }
`;
export type CreateKbbBookSheetMutationFn = Apollo.MutationFunction<
  CreateKbbBookSheetMutation,
  CreateKbbBookSheetMutationVariables
>;

/**
 * __useCreateKbbBookSheetMutation__
 *
 * To run a mutation, you first call `useCreateKbbBookSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKbbBookSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKbbBookSheetMutation, { data, loading, error }] = useCreateKbbBookSheetMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useCreateKbbBookSheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKbbBookSheetMutation,
    CreateKbbBookSheetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKbbBookSheetMutation, CreateKbbBookSheetMutationVariables>(
    CreateKbbBookSheetDocument,
    options,
  );
}
export type CreateKbbBookSheetMutationHookResult = ReturnType<typeof useCreateKbbBookSheetMutation>;
export type CreateKbbBookSheetMutationResult = Apollo.MutationResult<CreateKbbBookSheetMutation>;
export type CreateKbbBookSheetMutationOptions = Apollo.BaseMutationOptions<
  CreateKbbBookSheetMutation,
  CreateKbbBookSheetMutationVariables
>;
export const KeyMetricsDocument = gql`
  query keyMetrics($start_date: DateTime!, $end_date: DateTime!, $pod_ids: [String]!) {
    keyMetrics(start_date: $start_date, end_date: $end_date, pod_ids: $pod_ids) {
      keyMetric
      value {
        buyout
        acquisition
        total
      }
    }
  }
`;

/**
 * __useKeyMetricsQuery__
 *
 * To run a query within a React component, call `useKeyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyMetricsQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      pod_ids: // value for 'pod_ids'
 *   },
 * });
 */
export function useKeyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<KeyMetricsQuery, KeyMetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeyMetricsQuery, KeyMetricsQueryVariables>(KeyMetricsDocument, options);
}
export function useKeyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KeyMetricsQuery, KeyMetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeyMetricsQuery, KeyMetricsQueryVariables>(
    KeyMetricsDocument,
    options,
  );
}
export type KeyMetricsQueryHookResult = ReturnType<typeof useKeyMetricsQuery>;
export type KeyMetricsLazyQueryHookResult = ReturnType<typeof useKeyMetricsLazyQuery>;
export type KeyMetricsQueryResult = Apollo.QueryResult<KeyMetricsQuery, KeyMetricsQueryVariables>;
export const LaneKeyMetricsDocument = gql`
  query laneKeyMetrics($today: DateTime, $sources: [String]!, $types: [DealType]!) {
    laneKeyMetrics(today: $today, sources: $sources, types: $types) {
      state
      day_count
    }
  }
`;

/**
 * __useLaneKeyMetricsQuery__
 *
 * To run a query within a React component, call `useLaneKeyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLaneKeyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLaneKeyMetricsQuery({
 *   variables: {
 *      today: // value for 'today'
 *      sources: // value for 'sources'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useLaneKeyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<LaneKeyMetricsQuery, LaneKeyMetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LaneKeyMetricsQuery, LaneKeyMetricsQueryVariables>(
    LaneKeyMetricsDocument,
    options,
  );
}
export function useLaneKeyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LaneKeyMetricsQuery, LaneKeyMetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LaneKeyMetricsQuery, LaneKeyMetricsQueryVariables>(
    LaneKeyMetricsDocument,
    options,
  );
}
export type LaneKeyMetricsQueryHookResult = ReturnType<typeof useLaneKeyMetricsQuery>;
export type LaneKeyMetricsLazyQueryHookResult = ReturnType<typeof useLaneKeyMetricsLazyQuery>;
export type LaneKeyMetricsQueryResult = Apollo.QueryResult<
  LaneKeyMetricsQuery,
  LaneKeyMetricsQueryVariables
>;
export const LendersDocument = gql`
  query lenders($query: String!) {
    lenders(query: $query) {
      ...LenderFragment
    }
  }
  ${LenderFragmentFragmentDoc}
`;

/**
 * __useLendersQuery__
 *
 * To run a query within a React component, call `useLendersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useLendersQuery(
  baseOptions: Apollo.QueryHookOptions<LendersQuery, LendersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LendersQuery, LendersQueryVariables>(LendersDocument, options);
}
export function useLendersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LendersQuery, LendersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LendersQuery, LendersQueryVariables>(LendersDocument, options);
}
export type LendersQueryHookResult = ReturnType<typeof useLendersQuery>;
export type LendersLazyQueryHookResult = ReturnType<typeof useLendersLazyQuery>;
export type LendersQueryResult = Apollo.QueryResult<LendersQuery, LendersQueryVariables>;
export const MediaDocument = gql`
  query media($dealId: ID!) {
    media(dealId: $dealId) {
      id
      key
      signed_url
      type
      metadata
      deal_id
      verified
      state
      source
      has_verified_digital_signature
      has_wet_signature
      is_notarized
      created_at
    }
  }
`;

/**
 * __useMediaQuery__
 *
 * To run a query within a React component, call `useMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useMediaQuery(
  baseOptions: Apollo.QueryHookOptions<MediaQuery, MediaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options);
}
export function useMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaQuery, MediaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options);
}
export type MediaQueryHookResult = ReturnType<typeof useMediaQuery>;
export type MediaLazyQueryHookResult = ReturnType<typeof useMediaLazyQuery>;
export type MediaQueryResult = Apollo.QueryResult<MediaQuery, MediaQueryVariables>;
export const GetUploadUrlDocument = gql`
  query getUploadUrl($dealId: ID!, $fileName: String!) {
    getUploadUrl(dealId: $dealId, fileName: $fileName) {
      key
      url
    }
  }
`;

/**
 * __useGetUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadUrlQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGetUploadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetUploadUrlQuery, GetUploadUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUploadUrlQuery, GetUploadUrlQueryVariables>(
    GetUploadUrlDocument,
    options,
  );
}
export function useGetUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUploadUrlQuery, GetUploadUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUploadUrlQuery, GetUploadUrlQueryVariables>(
    GetUploadUrlDocument,
    options,
  );
}
export type GetUploadUrlQueryHookResult = ReturnType<typeof useGetUploadUrlQuery>;
export type GetUploadUrlLazyQueryHookResult = ReturnType<typeof useGetUploadUrlLazyQuery>;
export type GetUploadUrlQueryResult = Apollo.QueryResult<
  GetUploadUrlQuery,
  GetUploadUrlQueryVariables
>;
export const ArticleImageUploadUrlDocument = gql`
  query articleImageUploadUrl {
    articleImageUploadUrl {
      url
      key
    }
  }
`;

/**
 * __useArticleImageUploadUrlQuery__
 *
 * To run a query within a React component, call `useArticleImageUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleImageUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleImageUploadUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticleImageUploadUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ArticleImageUploadUrlQuery,
    ArticleImageUploadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticleImageUploadUrlQuery, ArticleImageUploadUrlQueryVariables>(
    ArticleImageUploadUrlDocument,
    options,
  );
}
export function useArticleImageUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleImageUploadUrlQuery,
    ArticleImageUploadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticleImageUploadUrlQuery, ArticleImageUploadUrlQueryVariables>(
    ArticleImageUploadUrlDocument,
    options,
  );
}
export type ArticleImageUploadUrlQueryHookResult = ReturnType<typeof useArticleImageUploadUrlQuery>;
export type ArticleImageUploadUrlLazyQueryHookResult = ReturnType<
  typeof useArticleImageUploadUrlLazyQuery
>;
export type ArticleImageUploadUrlQueryResult = Apollo.QueryResult<
  ArticleImageUploadUrlQuery,
  ArticleImageUploadUrlQueryVariables
>;
export const GetBase64StringFromS3ObjectDocument = gql`
  query getBase64StringFromS3Object($key: String!) {
    getBase64StringFromS3Object(key: $key)
  }
`;

/**
 * __useGetBase64StringFromS3ObjectQuery__
 *
 * To run a query within a React component, call `useGetBase64StringFromS3ObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBase64StringFromS3ObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBase64StringFromS3ObjectQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetBase64StringFromS3ObjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBase64StringFromS3ObjectQuery,
    GetBase64StringFromS3ObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBase64StringFromS3ObjectQuery,
    GetBase64StringFromS3ObjectQueryVariables
  >(GetBase64StringFromS3ObjectDocument, options);
}
export function useGetBase64StringFromS3ObjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBase64StringFromS3ObjectQuery,
    GetBase64StringFromS3ObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBase64StringFromS3ObjectQuery,
    GetBase64StringFromS3ObjectQueryVariables
  >(GetBase64StringFromS3ObjectDocument, options);
}
export type GetBase64StringFromS3ObjectQueryHookResult = ReturnType<
  typeof useGetBase64StringFromS3ObjectQuery
>;
export type GetBase64StringFromS3ObjectLazyQueryHookResult = ReturnType<
  typeof useGetBase64StringFromS3ObjectLazyQuery
>;
export type GetBase64StringFromS3ObjectQueryResult = Apollo.QueryResult<
  GetBase64StringFromS3ObjectQuery,
  GetBase64StringFromS3ObjectQueryVariables
>;
export const MediaInsertDocument = gql`
  mutation mediaInsert($key: String!, $type: String, $dealId: ID!, $metadata: JSONObject) {
    mediaInsert(key: $key, type: $type, dealId: $dealId, metadata: $metadata) {
      id
      key
      signed_url
      type
      metadata
      deal_id
      has_verified_digital_signature
      has_wet_signature
      is_notarized
    }
  }
`;
export type MediaInsertMutationFn = Apollo.MutationFunction<
  MediaInsertMutation,
  MediaInsertMutationVariables
>;

/**
 * __useMediaInsertMutation__
 *
 * To run a mutation, you first call `useMediaInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaInsertMutation, { data, loading, error }] = useMediaInsertMutation({
 *   variables: {
 *      key: // value for 'key'
 *      type: // value for 'type'
 *      dealId: // value for 'dealId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useMediaInsertMutation(
  baseOptions?: Apollo.MutationHookOptions<MediaInsertMutation, MediaInsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MediaInsertMutation, MediaInsertMutationVariables>(
    MediaInsertDocument,
    options,
  );
}
export type MediaInsertMutationHookResult = ReturnType<typeof useMediaInsertMutation>;
export type MediaInsertMutationResult = Apollo.MutationResult<MediaInsertMutation>;
export type MediaInsertMutationOptions = Apollo.BaseMutationOptions<
  MediaInsertMutation,
  MediaInsertMutationVariables
>;
export const MediaDeleteDocument = gql`
  mutation mediaDelete($filePath: String!) {
    mediaDelete(filePath: $filePath)
  }
`;
export type MediaDeleteMutationFn = Apollo.MutationFunction<
  MediaDeleteMutation,
  MediaDeleteMutationVariables
>;

/**
 * __useMediaDeleteMutation__
 *
 * To run a mutation, you first call `useMediaDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaDeleteMutation, { data, loading, error }] = useMediaDeleteMutation({
 *   variables: {
 *      filePath: // value for 'filePath'
 *   },
 * });
 */
export function useMediaDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<MediaDeleteMutation, MediaDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MediaDeleteMutation, MediaDeleteMutationVariables>(
    MediaDeleteDocument,
    options,
  );
}
export type MediaDeleteMutationHookResult = ReturnType<typeof useMediaDeleteMutation>;
export type MediaDeleteMutationResult = Apollo.MutationResult<MediaDeleteMutation>;
export type MediaDeleteMutationOptions = Apollo.BaseMutationOptions<
  MediaDeleteMutation,
  MediaDeleteMutationVariables
>;
export const DeleteAndReplaceFilesDocument = gql`
  mutation deleteAndReplaceFiles(
    $deal_id: ID!
    $files_to_delete: [addDocumentsToMediaCenterInput]!
    $files_to_upload: [addDocumentsToMediaCenterInput]!
  ) {
    deleteAndReplaceFiles(
      deal_id: $deal_id
      files_to_delete: $files_to_delete
      files_to_upload: $files_to_upload
    )
  }
`;
export type DeleteAndReplaceFilesMutationFn = Apollo.MutationFunction<
  DeleteAndReplaceFilesMutation,
  DeleteAndReplaceFilesMutationVariables
>;

/**
 * __useDeleteAndReplaceFilesMutation__
 *
 * To run a mutation, you first call `useDeleteAndReplaceFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAndReplaceFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAndReplaceFilesMutation, { data, loading, error }] = useDeleteAndReplaceFilesMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      files_to_delete: // value for 'files_to_delete'
 *      files_to_upload: // value for 'files_to_upload'
 *   },
 * });
 */
export function useDeleteAndReplaceFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAndReplaceFilesMutation,
    DeleteAndReplaceFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAndReplaceFilesMutation, DeleteAndReplaceFilesMutationVariables>(
    DeleteAndReplaceFilesDocument,
    options,
  );
}
export type DeleteAndReplaceFilesMutationHookResult = ReturnType<
  typeof useDeleteAndReplaceFilesMutation
>;
export type DeleteAndReplaceFilesMutationResult =
  Apollo.MutationResult<DeleteAndReplaceFilesMutation>;
export type DeleteAndReplaceFilesMutationOptions = Apollo.BaseMutationOptions<
  DeleteAndReplaceFilesMutation,
  DeleteAndReplaceFilesMutationVariables
>;
export const MediaRenameDocument = gql`
  mutation mediaRename($dealId: ID!, $filePath: String!, $fileName: String!) {
    mediaRename(dealId: $dealId, filePath: $filePath, fileName: $fileName)
  }
`;
export type MediaRenameMutationFn = Apollo.MutationFunction<
  MediaRenameMutation,
  MediaRenameMutationVariables
>;

/**
 * __useMediaRenameMutation__
 *
 * To run a mutation, you first call `useMediaRenameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaRenameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaRenameMutation, { data, loading, error }] = useMediaRenameMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      filePath: // value for 'filePath'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useMediaRenameMutation(
  baseOptions?: Apollo.MutationHookOptions<MediaRenameMutation, MediaRenameMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MediaRenameMutation, MediaRenameMutationVariables>(
    MediaRenameDocument,
    options,
  );
}
export type MediaRenameMutationHookResult = ReturnType<typeof useMediaRenameMutation>;
export type MediaRenameMutationResult = Apollo.MutationResult<MediaRenameMutation>;
export type MediaRenameMutationOptions = Apollo.BaseMutationOptions<
  MediaRenameMutation,
  MediaRenameMutationVariables
>;
export const MediaUpdateDocument = gql`
  mutation mediaUpdate(
    $dealId: ID!
    $oldKey: String!
    $newFileName: String
    $type: String
    $metadata: JSONObject
    $verified: Boolean
    $has_verified_digital_signature: Boolean
    $has_wet_signature: Boolean
    $is_notarized: Boolean
  ) {
    mediaUpdate(
      dealId: $dealId
      oldKey: $oldKey
      newFileName: $newFileName
      type: $type
      metadata: $metadata
      verified: $verified
      has_verified_digital_signature: $has_verified_digital_signature
      has_wet_signature: $has_wet_signature
      is_notarized: $is_notarized
    ) {
      id
      key
      signed_url
      type
      metadata
      deal_id
      verified
      has_verified_digital_signature
      has_wet_signature
      is_notarized
    }
  }
`;
export type MediaUpdateMutationFn = Apollo.MutationFunction<
  MediaUpdateMutation,
  MediaUpdateMutationVariables
>;

/**
 * __useMediaUpdateMutation__
 *
 * To run a mutation, you first call `useMediaUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaUpdateMutation, { data, loading, error }] = useMediaUpdateMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      oldKey: // value for 'oldKey'
 *      newFileName: // value for 'newFileName'
 *      type: // value for 'type'
 *      metadata: // value for 'metadata'
 *      verified: // value for 'verified'
 *      has_verified_digital_signature: // value for 'has_verified_digital_signature'
 *      has_wet_signature: // value for 'has_wet_signature'
 *      is_notarized: // value for 'is_notarized'
 *   },
 * });
 */
export function useMediaUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<MediaUpdateMutation, MediaUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MediaUpdateMutation, MediaUpdateMutationVariables>(
    MediaUpdateDocument,
    options,
  );
}
export type MediaUpdateMutationHookResult = ReturnType<typeof useMediaUpdateMutation>;
export type MediaUpdateMutationResult = Apollo.MutationResult<MediaUpdateMutation>;
export type MediaUpdateMutationOptions = Apollo.BaseMutationOptions<
  MediaUpdateMutation,
  MediaUpdateMutationVariables
>;
export const MessagesDocument = gql`
  query messages($dealPhoneNumber: String) {
    messages(dealPhoneNumber: $dealPhoneNumber) {
      messages {
        id
        fromCustomer
        body
        senderName
        dateCreated
        mediaListObj {
          data {
            url
            contentType
          }
        }
      }
      hasErrors
    }
  }
`;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      dealPhoneNumber: // value for 'dealPhoneNumber'
 *   },
 * });
 */
export function useMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
}
export function useMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
}
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const MessagesDbPaginatedDocument = gql`
  query messagesDbPaginated($dealPhoneNumber: String!, $dealId: String!, $page: Int!) {
    messagesDbPaginated(dealPhoneNumber: $dealPhoneNumber, dealId: $dealId, page: $page) {
      hasMoreMessages
      messages {
        id
        fromCustomer
        body
        senderName
        dateCreated
        mediaListObj {
          data {
            url
            contentType
          }
        }
      }
    }
  }
`;

/**
 * __useMessagesDbPaginatedQuery__
 *
 * To run a query within a React component, call `useMessagesDbPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesDbPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesDbPaginatedQuery({
 *   variables: {
 *      dealPhoneNumber: // value for 'dealPhoneNumber'
 *      dealId: // value for 'dealId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useMessagesDbPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<MessagesDbPaginatedQuery, MessagesDbPaginatedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessagesDbPaginatedQuery, MessagesDbPaginatedQueryVariables>(
    MessagesDbPaginatedDocument,
    options,
  );
}
export function useMessagesDbPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessagesDbPaginatedQuery,
    MessagesDbPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessagesDbPaginatedQuery, MessagesDbPaginatedQueryVariables>(
    MessagesDbPaginatedDocument,
    options,
  );
}
export type MessagesDbPaginatedQueryHookResult = ReturnType<typeof useMessagesDbPaginatedQuery>;
export type MessagesDbPaginatedLazyQueryHookResult = ReturnType<
  typeof useMessagesDbPaginatedLazyQuery
>;
export type MessagesDbPaginatedQueryResult = Apollo.QueryResult<
  MessagesDbPaginatedQuery,
  MessagesDbPaginatedQueryVariables
>;
export const MessageSendDocument = gql`
  mutation messageSend($message: String, $dealPhoneNumber: String, $id: ID) {
    messageSend(message: $message, dealPhoneNumber: $dealPhoneNumber, id: $id) {
      fromCustomer
      body
      mediaListObj {
        data {
          url
          contentType
        }
      }
      senderName
      dateCreated
    }
  }
`;
export type MessageSendMutationFn = Apollo.MutationFunction<
  MessageSendMutation,
  MessageSendMutationVariables
>;

/**
 * __useMessageSendMutation__
 *
 * To run a mutation, you first call `useMessageSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageSendMutation, { data, loading, error }] = useMessageSendMutation({
 *   variables: {
 *      message: // value for 'message'
 *      dealPhoneNumber: // value for 'dealPhoneNumber'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageSendMutation(
  baseOptions?: Apollo.MutationHookOptions<MessageSendMutation, MessageSendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MessageSendMutation, MessageSendMutationVariables>(
    MessageSendDocument,
    options,
  );
}
export type MessageSendMutationHookResult = ReturnType<typeof useMessageSendMutation>;
export type MessageSendMutationResult = Apollo.MutationResult<MessageSendMutation>;
export type MessageSendMutationOptions = Apollo.BaseMutationOptions<
  MessageSendMutation,
  MessageSendMutationVariables
>;
export const FileSendDocument = gql`
  mutation fileSend($file: String, $dealPhoneNumber: String, $id: ID!) {
    fileSend(file: $file, dealPhoneNumber: $dealPhoneNumber, id: $id) {
      fromCustomer
      body
      mediaListObj {
        data {
          url
          contentType
        }
      }
    }
  }
`;
export type FileSendMutationFn = Apollo.MutationFunction<
  FileSendMutation,
  FileSendMutationVariables
>;

/**
 * __useFileSendMutation__
 *
 * To run a mutation, you first call `useFileSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileSendMutation, { data, loading, error }] = useFileSendMutation({
 *   variables: {
 *      file: // value for 'file'
 *      dealPhoneNumber: // value for 'dealPhoneNumber'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileSendMutation(
  baseOptions?: Apollo.MutationHookOptions<FileSendMutation, FileSendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FileSendMutation, FileSendMutationVariables>(FileSendDocument, options);
}
export type FileSendMutationHookResult = ReturnType<typeof useFileSendMutation>;
export type FileSendMutationResult = Apollo.MutationResult<FileSendMutation>;
export type FileSendMutationOptions = Apollo.BaseMutationOptions<
  FileSendMutation,
  FileSendMutationVariables
>;
export const OnNewMessageDocument = gql`
  subscription onNewMessage($dealPhoneNumbers: [String]) {
    onNewMessage(dealPhoneNumbers: $dealPhoneNumbers) {
      fromCustomer
      body
      dateCreated
      mediaListObj {
        data {
          url
          contentType
        }
      }
      from
      deals {
        id
        state
        customer {
          first_name
          last_name
          phone_number
        }
        cobuyer {
          first_name
          last_name
          phone_number
        }
        pod_id
        titling_pod_id
        setter_id
        closer_id
        closer2_id
        funding_clerk_id
        title_clerk_id
        title_clerk2_id
      }
    }
  }
`;

/**
 * __useOnNewMessageSubscription__
 *
 * To run a query within a React component, call `useOnNewMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnNewMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNewMessageSubscription({
 *   variables: {
 *      dealPhoneNumbers: // value for 'dealPhoneNumbers'
 *   },
 * });
 */
export function useOnNewMessageSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnNewMessageSubscription,
    OnNewMessageSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnNewMessageSubscription, OnNewMessageSubscriptionVariables>(
    OnNewMessageDocument,
    options,
  );
}
export type OnNewMessageSubscriptionHookResult = ReturnType<typeof useOnNewMessageSubscription>;
export type OnNewMessageSubscriptionResult = Apollo.SubscriptionResult<OnNewMessageSubscription>;
export const OnReceivedMessageDocument = gql`
  subscription onReceivedMessage(
    $dealPhoneNumbers: [String]
    $isNotification: Boolean
    $isWidgetOpen: Boolean
    $activePhoneNumber: String
  ) {
    onReceivedMessage(
      dealPhoneNumbers: $dealPhoneNumbers
      isNotification: $isNotification
      isWidgetOpen: $isWidgetOpen
      activePhoneNumber: $activePhoneNumber
    ) {
      message {
        id
        fromCustomer
        body
        dateCreated
        mediaListObj {
          data {
            url
            contentType
          }
        }
        from
      }
      deals {
        id
        state
        notification_subscribers {
          id
          deal_id
          subscriber_id
        }
        car {
          id
          make
          model
          year
        }
        customer {
          first_name
          last_name
          phone_number
        }
        cobuyer {
          first_name
          last_name
          phone_number
          relation_to_buyer
        }
        contact {
          first_name
          last_name
          phone_number
          relation_to_buyer
        }
        second_contact {
          first_name
          last_name
          phone_number
          relation_to_buyer
        }
      }
      messageDetail {
        shortNames
        dealIds
        dealStates
      }
    }
  }
`;

/**
 * __useOnReceivedMessageSubscription__
 *
 * To run a query within a React component, call `useOnReceivedMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnReceivedMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnReceivedMessageSubscription({
 *   variables: {
 *      dealPhoneNumbers: // value for 'dealPhoneNumbers'
 *      isNotification: // value for 'isNotification'
 *      isWidgetOpen: // value for 'isWidgetOpen'
 *      activePhoneNumber: // value for 'activePhoneNumber'
 *   },
 * });
 */
export function useOnReceivedMessageSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnReceivedMessageSubscription,
    OnReceivedMessageSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnReceivedMessageSubscription,
    OnReceivedMessageSubscriptionVariables
  >(OnReceivedMessageDocument, options);
}
export type OnReceivedMessageSubscriptionHookResult = ReturnType<
  typeof useOnReceivedMessageSubscription
>;
export type OnReceivedMessageSubscriptionResult =
  Apollo.SubscriptionResult<OnReceivedMessageSubscription>;
export const TemplateTextsDocument = gql`
  query templateTexts {
    templateTexts {
      id
      description
      text
      key
    }
  }
`;

/**
 * __useTemplateTextsQuery__
 *
 * To run a query within a React component, call `useTemplateTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateTextsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateTextsQuery(
  baseOptions?: Apollo.QueryHookOptions<TemplateTextsQuery, TemplateTextsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TemplateTextsQuery, TemplateTextsQueryVariables>(
    TemplateTextsDocument,
    options,
  );
}
export function useTemplateTextsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TemplateTextsQuery, TemplateTextsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TemplateTextsQuery, TemplateTextsQueryVariables>(
    TemplateTextsDocument,
    options,
  );
}
export type TemplateTextsQueryHookResult = ReturnType<typeof useTemplateTextsQuery>;
export type TemplateTextsLazyQueryHookResult = ReturnType<typeof useTemplateTextsLazyQuery>;
export type TemplateTextsQueryResult = Apollo.QueryResult<
  TemplateTextsQuery,
  TemplateTextsQueryVariables
>;
export const InboxDocument = gql`
  query inbox(
    $filterBy: inboxFilterByEnum
    $podIds: [ID]
    $orderBy: inboxOrderByEnum
    $unreadOnly: Boolean
    $page: Int
    $itemsPerPage: Int
  ) {
    inbox(
      filterBy: $filterBy
      podIds: $podIds
      orderBy: $orderBy
      unreadOnly: $unreadOnly
      page: $page
      itemsPerPage: $itemsPerPage
    ) {
      messages {
        id
        from
        fromCustomer
        body
        dateCreated
        dateCreatedTz
        mediaListObj {
          data {
            url
            contentType
          }
        }
        notifications {
          id
          seen
          deal {
            id
            state
            car {
              id
              year
              make
              model
            }
            customer {
              id
              first_name
              last_name
              phone_number
            }
            cobuyer {
              id
              first_name
              last_name
              phone_number
            }
            contact {
              id
              first_name
              last_name
              phone_number
            }
            second_contact {
              id
              first_name
              last_name
              phone_number
            }
            setter {
              id
              name
              pods {
                name
                color
              }
            }
            closer {
              id
              name
              pods {
                name
                color
              }
            }
            closer2 {
              id
              name
              pods {
                name
                color
              }
            }
            funding_clerk {
              id
              name
              pods {
                name
                color
              }
            }
            title_clerk {
              id
              name
              titlingPods {
                name
                color
              }
            }
            title_clerk2 {
              id
              name
              titlingPods {
                name
                color
              }
            }
          }
        }
      }
      totalRecords
    }
  }
`;

/**
 * __useInboxQuery__
 *
 * To run a query within a React component, call `useInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      podIds: // value for 'podIds'
 *      orderBy: // value for 'orderBy'
 *      unreadOnly: // value for 'unreadOnly'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useInboxQuery(
  baseOptions?: Apollo.QueryHookOptions<InboxQuery, InboxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InboxQuery, InboxQueryVariables>(InboxDocument, options);
}
export function useInboxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InboxQuery, InboxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InboxQuery, InboxQueryVariables>(InboxDocument, options);
}
export type InboxQueryHookResult = ReturnType<typeof useInboxQuery>;
export type InboxLazyQueryHookResult = ReturnType<typeof useInboxLazyQuery>;
export type InboxQueryResult = Apollo.QueryResult<InboxQuery, InboxQueryVariables>;
export const MetricsPagesDocument = gql`
  query metricsPages {
    metricsPages {
      id
      name
      link_name
      iframe_url
      permission_name
      sort_order
    }
  }
`;

/**
 * __useMetricsPagesQuery__
 *
 * To run a query within a React component, call `useMetricsPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMetricsPagesQuery(
  baseOptions?: Apollo.QueryHookOptions<MetricsPagesQuery, MetricsPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MetricsPagesQuery, MetricsPagesQueryVariables>(
    MetricsPagesDocument,
    options,
  );
}
export function useMetricsPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MetricsPagesQuery, MetricsPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MetricsPagesQuery, MetricsPagesQueryVariables>(
    MetricsPagesDocument,
    options,
  );
}
export type MetricsPagesQueryHookResult = ReturnType<typeof useMetricsPagesQuery>;
export type MetricsPagesLazyQueryHookResult = ReturnType<typeof useMetricsPagesLazyQuery>;
export type MetricsPagesQueryResult = Apollo.QueryResult<
  MetricsPagesQuery,
  MetricsPagesQueryVariables
>;
export const MmrDocument = gql`
  query mmr(
    $vin: String!
    $mileage: Int!
    $color: String!
    $grade: Float!
    $trim: String!
    $state: String!
  ) {
    mmr(vin: $vin, mileage: $mileage, color: $color, grade: $grade, trim: $trim, state: $state) {
      average
    }
  }
`;

/**
 * __useMmrQuery__
 *
 * To run a query within a React component, call `useMmrQuery` and pass it any options that fit your needs.
 * When your component renders, `useMmrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMmrQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      mileage: // value for 'mileage'
 *      color: // value for 'color'
 *      grade: // value for 'grade'
 *      trim: // value for 'trim'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useMmrQuery(baseOptions: Apollo.QueryHookOptions<MmrQuery, MmrQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MmrQuery, MmrQueryVariables>(MmrDocument, options);
}
export function useMmrLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MmrQuery, MmrQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MmrQuery, MmrQueryVariables>(MmrDocument, options);
}
export type MmrQueryHookResult = ReturnType<typeof useMmrQuery>;
export type MmrLazyQueryHookResult = ReturnType<typeof useMmrLazyQuery>;
export type MmrQueryResult = Apollo.QueryResult<MmrQuery, MmrQueryVariables>;
export const NoteCreateDocument = gql`
  mutation noteCreate($note: noteInput) {
    noteCreate(note: $note) {
      id
    }
  }
`;
export type NoteCreateMutationFn = Apollo.MutationFunction<
  NoteCreateMutation,
  NoteCreateMutationVariables
>;

/**
 * __useNoteCreateMutation__
 *
 * To run a mutation, you first call `useNoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteCreateMutation, { data, loading, error }] = useNoteCreateMutation({
 *   variables: {
 *      note: // value for 'note'
 *   },
 * });
 */
export function useNoteCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<NoteCreateMutation, NoteCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NoteCreateMutation, NoteCreateMutationVariables>(
    NoteCreateDocument,
    options,
  );
}
export type NoteCreateMutationHookResult = ReturnType<typeof useNoteCreateMutation>;
export type NoteCreateMutationResult = Apollo.MutationResult<NoteCreateMutation>;
export type NoteCreateMutationOptions = Apollo.BaseMutationOptions<
  NoteCreateMutation,
  NoteCreateMutationVariables
>;
export const NoteUpdateDocument = gql`
  mutation noteUpdate($note: noteInput) {
    noteUpdate(note: $note) {
      id
    }
  }
`;
export type NoteUpdateMutationFn = Apollo.MutationFunction<
  NoteUpdateMutation,
  NoteUpdateMutationVariables
>;

/**
 * __useNoteUpdateMutation__
 *
 * To run a mutation, you first call `useNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteUpdateMutation, { data, loading, error }] = useNoteUpdateMutation({
 *   variables: {
 *      note: // value for 'note'
 *   },
 * });
 */
export function useNoteUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<NoteUpdateMutation, NoteUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NoteUpdateMutation, NoteUpdateMutationVariables>(
    NoteUpdateDocument,
    options,
  );
}
export type NoteUpdateMutationHookResult = ReturnType<typeof useNoteUpdateMutation>;
export type NoteUpdateMutationResult = Apollo.MutationResult<NoteUpdateMutation>;
export type NoteUpdateMutationOptions = Apollo.BaseMutationOptions<
  NoteUpdateMutation,
  NoteUpdateMutationVariables
>;
export const NoteDeleteDocument = gql`
  mutation noteDelete($id: ID!) {
    noteDelete(id: $id) {
      id
    }
  }
`;
export type NoteDeleteMutationFn = Apollo.MutationFunction<
  NoteDeleteMutation,
  NoteDeleteMutationVariables
>;

/**
 * __useNoteDeleteMutation__
 *
 * To run a mutation, you first call `useNoteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteDeleteMutation, { data, loading, error }] = useNoteDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<NoteDeleteMutation, NoteDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NoteDeleteMutation, NoteDeleteMutationVariables>(
    NoteDeleteDocument,
    options,
  );
}
export type NoteDeleteMutationHookResult = ReturnType<typeof useNoteDeleteMutation>;
export type NoteDeleteMutationResult = Apollo.MutationResult<NoteDeleteMutation>;
export type NoteDeleteMutationOptions = Apollo.BaseMutationOptions<
  NoteDeleteMutation,
  NoteDeleteMutationVariables
>;
export const NotesDocument = gql`
  query notes($dealId: ID!) {
    notes(deal_id: $dealId) {
      id
      author_id
      text
      deal_id
      created_at
      creation_date_tz
      note_type
      pinned
      author {
        name
      }
    }
  }
`;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useNotesQuery(
  baseOptions: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
}
export function useNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
}
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<NotesQuery, NotesQueryVariables>;
export const NotificationSeenDocument = gql`
  mutation notificationSeen($notificationId: ID!, $seen: Boolean!, $dealId: ID) {
    notificationSeen(notificationId: $notificationId, seen: $seen, dealId: $dealId)
  }
`;
export type NotificationSeenMutationFn = Apollo.MutationFunction<
  NotificationSeenMutation,
  NotificationSeenMutationVariables
>;

/**
 * __useNotificationSeenMutation__
 *
 * To run a mutation, you first call `useNotificationSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSeenMutation, { data, loading, error }] = useNotificationSeenMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *      seen: // value for 'seen'
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useNotificationSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationSeenMutation,
    NotificationSeenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NotificationSeenMutation, NotificationSeenMutationVariables>(
    NotificationSeenDocument,
    options,
  );
}
export type NotificationSeenMutationHookResult = ReturnType<typeof useNotificationSeenMutation>;
export type NotificationSeenMutationResult = Apollo.MutationResult<NotificationSeenMutation>;
export type NotificationSeenMutationOptions = Apollo.BaseMutationOptions<
  NotificationSeenMutation,
  NotificationSeenMutationVariables
>;
export const NotificationsSeenDocument = gql`
  mutation notificationsSeen($notificationIds: [ID]!, $dealId: ID!) {
    notificationsSeen(notificationIds: $notificationIds, dealId: $dealId)
  }
`;
export type NotificationsSeenMutationFn = Apollo.MutationFunction<
  NotificationsSeenMutation,
  NotificationsSeenMutationVariables
>;

/**
 * __useNotificationsSeenMutation__
 *
 * To run a mutation, you first call `useNotificationsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsSeenMutation, { data, loading, error }] = useNotificationsSeenMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useNotificationsSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationsSeenMutation,
    NotificationsSeenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NotificationsSeenMutation, NotificationsSeenMutationVariables>(
    NotificationsSeenDocument,
    options,
  );
}
export type NotificationsSeenMutationHookResult = ReturnType<typeof useNotificationsSeenMutation>;
export type NotificationsSeenMutationResult = Apollo.MutationResult<NotificationsSeenMutation>;
export type NotificationsSeenMutationOptions = Apollo.BaseMutationOptions<
  NotificationsSeenMutation,
  NotificationsSeenMutationVariables
>;
export const OnNoDealNotificationDocument = gql`
  subscription onNoDealNotification {
    onNoDealNotification {
      id
      seen
    }
  }
`;

/**
 * __useOnNoDealNotificationSubscription__
 *
 * To run a query within a React component, call `useOnNoDealNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnNoDealNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNoDealNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnNoDealNotificationSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnNoDealNotificationSubscription,
    OnNoDealNotificationSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnNoDealNotificationSubscription,
    OnNoDealNotificationSubscriptionVariables
  >(OnNoDealNotificationDocument, options);
}
export type OnNoDealNotificationSubscriptionHookResult = ReturnType<
  typeof useOnNoDealNotificationSubscription
>;
export type OnNoDealNotificationSubscriptionResult =
  Apollo.SubscriptionResult<OnNoDealNotificationSubscription>;
export const UpdateSubscriptionToNotificationsStatusDocument = gql`
  mutation updateSubscriptionToNotificationsStatus(
    $dealId: ID!
    $subscribeToNotifications: Boolean!
  ) {
    updateSubscriptionToNotificationsStatus(
      dealId: $dealId
      subscribeToNotifications: $subscribeToNotifications
    ) {
      id
      deal_id
      subscriber_id
    }
  }
`;
export type UpdateSubscriptionToNotificationsStatusMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionToNotificationsStatusMutation,
  UpdateSubscriptionToNotificationsStatusMutationVariables
>;

/**
 * __useUpdateSubscriptionToNotificationsStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionToNotificationsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionToNotificationsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionToNotificationsStatusMutation, { data, loading, error }] = useUpdateSubscriptionToNotificationsStatusMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      subscribeToNotifications: // value for 'subscribeToNotifications'
 *   },
 * });
 */
export function useUpdateSubscriptionToNotificationsStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubscriptionToNotificationsStatusMutation,
    UpdateSubscriptionToNotificationsStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSubscriptionToNotificationsStatusMutation,
    UpdateSubscriptionToNotificationsStatusMutationVariables
  >(UpdateSubscriptionToNotificationsStatusDocument, options);
}
export type UpdateSubscriptionToNotificationsStatusMutationHookResult = ReturnType<
  typeof useUpdateSubscriptionToNotificationsStatusMutation
>;
export type UpdateSubscriptionToNotificationsStatusMutationResult =
  Apollo.MutationResult<UpdateSubscriptionToNotificationsStatusMutation>;
export type UpdateSubscriptionToNotificationsStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubscriptionToNotificationsStatusMutation,
  UpdateSubscriptionToNotificationsStatusMutationVariables
>;
export const PaymentOptionsDocument = gql`
  query paymentOptions($data: PaymentOptionsInput) {
    paymentOptions(data: $data) {
      vscPlusGap {
        ...PaymentOptionFragment
      }
      vsc {
        ...PaymentOptionFragment
      }
      gap {
        ...PaymentOptionFragment
      }
      noProducts {
        ...PaymentOptionFragment
      }
    }
  }
  ${PaymentOptionFragmentFragmentDoc}
`;

/**
 * __usePaymentOptionsQuery__
 *
 * To run a query within a React component, call `usePaymentOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentOptionsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePaymentOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentOptionsQuery, PaymentOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentOptionsQuery, PaymentOptionsQueryVariables>(
    PaymentOptionsDocument,
    options,
  );
}
export function usePaymentOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentOptionsQuery, PaymentOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentOptionsQuery, PaymentOptionsQueryVariables>(
    PaymentOptionsDocument,
    options,
  );
}
export type PaymentOptionsQueryHookResult = ReturnType<typeof usePaymentOptionsQuery>;
export type PaymentOptionsLazyQueryHookResult = ReturnType<typeof usePaymentOptionsLazyQuery>;
export type PaymentOptionsQueryResult = Apollo.QueryResult<
  PaymentOptionsQuery,
  PaymentOptionsQueryVariables
>;
export const PaymentEstimateDocument = gql`
  query paymentEstimate($data: paymentEstimateInput) {
    paymentEstimate(data: $data) {
      paymentEstimateLow
      paymentEstimateHigh
      estimatedTaxesAndFees
      totalEstimatedLoan
      estimatedAnnualPercentageRate
    }
  }
`;

/**
 * __usePaymentEstimateQuery__
 *
 * To run a query within a React component, call `usePaymentEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentEstimateQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePaymentEstimateQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentEstimateQuery, PaymentEstimateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentEstimateQuery, PaymentEstimateQueryVariables>(
    PaymentEstimateDocument,
    options,
  );
}
export function usePaymentEstimateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentEstimateQuery, PaymentEstimateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentEstimateQuery, PaymentEstimateQueryVariables>(
    PaymentEstimateDocument,
    options,
  );
}
export type PaymentEstimateQueryHookResult = ReturnType<typeof usePaymentEstimateQuery>;
export type PaymentEstimateLazyQueryHookResult = ReturnType<typeof usePaymentEstimateLazyQuery>;
export type PaymentEstimateQueryResult = Apollo.QueryResult<
  PaymentEstimateQuery,
  PaymentEstimateQueryVariables
>;
export const PayoffUpdateDocument = gql`
  mutation payoffUpdate($payoff: payoffInput) {
    payoffUpdate(payoffInfo: $payoff) {
      ...PayoffFragment
    }
  }
  ${PayoffFragmentFragmentDoc}
`;
export type PayoffUpdateMutationFn = Apollo.MutationFunction<
  PayoffUpdateMutation,
  PayoffUpdateMutationVariables
>;

/**
 * __usePayoffUpdateMutation__
 *
 * To run a mutation, you first call `usePayoffUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayoffUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payoffUpdateMutation, { data, loading, error }] = usePayoffUpdateMutation({
 *   variables: {
 *      payoff: // value for 'payoff'
 *   },
 * });
 */
export function usePayoffUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<PayoffUpdateMutation, PayoffUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayoffUpdateMutation, PayoffUpdateMutationVariables>(
    PayoffUpdateDocument,
    options,
  );
}
export type PayoffUpdateMutationHookResult = ReturnType<typeof usePayoffUpdateMutation>;
export type PayoffUpdateMutationResult = Apollo.MutationResult<PayoffUpdateMutation>;
export type PayoffUpdateMutationOptions = Apollo.BaseMutationOptions<
  PayoffUpdateMutation,
  PayoffUpdateMutationVariables
>;
export const PayoffDashboardDealsDocument = gql`
  query payoffDashboardDeals {
    payoffDashboardDeals {
      payoffRequests {
        ...PayoffRequestPopulatedFragment
      }
    }
  }
  ${PayoffRequestPopulatedFragmentFragmentDoc}
`;

/**
 * __usePayoffDashboardDealsQuery__
 *
 * To run a query within a React component, call `usePayoffDashboardDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoffDashboardDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoffDashboardDealsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayoffDashboardDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PayoffDashboardDealsQuery,
    PayoffDashboardDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayoffDashboardDealsQuery, PayoffDashboardDealsQueryVariables>(
    PayoffDashboardDealsDocument,
    options,
  );
}
export function usePayoffDashboardDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayoffDashboardDealsQuery,
    PayoffDashboardDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PayoffDashboardDealsQuery, PayoffDashboardDealsQueryVariables>(
    PayoffDashboardDealsDocument,
    options,
  );
}
export type PayoffDashboardDealsQueryHookResult = ReturnType<typeof usePayoffDashboardDealsQuery>;
export type PayoffDashboardDealsLazyQueryHookResult = ReturnType<
  typeof usePayoffDashboardDealsLazyQuery
>;
export type PayoffDashboardDealsQueryResult = Apollo.QueryResult<
  PayoffDashboardDealsQuery,
  PayoffDashboardDealsQueryVariables
>;
export const PayoffRequestConfirmDocument = gql`
  mutation payoffRequestConfirm($payoffRequest: payoffRequestConfirmInput!) {
    payoffRequestConfirm(payoffRequest: $payoffRequest) {
      id
      vehicle_payoff
      good_through_date
    }
  }
`;
export type PayoffRequestConfirmMutationFn = Apollo.MutationFunction<
  PayoffRequestConfirmMutation,
  PayoffRequestConfirmMutationVariables
>;

/**
 * __usePayoffRequestConfirmMutation__
 *
 * To run a mutation, you first call `usePayoffRequestConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayoffRequestConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payoffRequestConfirmMutation, { data, loading, error }] = usePayoffRequestConfirmMutation({
 *   variables: {
 *      payoffRequest: // value for 'payoffRequest'
 *   },
 * });
 */
export function usePayoffRequestConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayoffRequestConfirmMutation,
    PayoffRequestConfirmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayoffRequestConfirmMutation, PayoffRequestConfirmMutationVariables>(
    PayoffRequestConfirmDocument,
    options,
  );
}
export type PayoffRequestConfirmMutationHookResult = ReturnType<
  typeof usePayoffRequestConfirmMutation
>;
export type PayoffRequestConfirmMutationResult =
  Apollo.MutationResult<PayoffRequestConfirmMutation>;
export type PayoffRequestConfirmMutationOptions = Apollo.BaseMutationOptions<
  PayoffRequestConfirmMutation,
  PayoffRequestConfirmMutationVariables
>;
export const UpdatePayoffClerkDocument = gql`
  mutation updatePayoffClerk($payoff_request_id: ID, $payoff_clerk_id: String) {
    updatePayoffClerk(payoff_request_id: $payoff_request_id, payoff_clerk_id: $payoff_clerk_id)
  }
`;
export type UpdatePayoffClerkMutationFn = Apollo.MutationFunction<
  UpdatePayoffClerkMutation,
  UpdatePayoffClerkMutationVariables
>;

/**
 * __useUpdatePayoffClerkMutation__
 *
 * To run a mutation, you first call `useUpdatePayoffClerkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayoffClerkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayoffClerkMutation, { data, loading, error }] = useUpdatePayoffClerkMutation({
 *   variables: {
 *      payoff_request_id: // value for 'payoff_request_id'
 *      payoff_clerk_id: // value for 'payoff_clerk_id'
 *   },
 * });
 */
export function useUpdatePayoffClerkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayoffClerkMutation,
    UpdatePayoffClerkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePayoffClerkMutation, UpdatePayoffClerkMutationVariables>(
    UpdatePayoffClerkDocument,
    options,
  );
}
export type UpdatePayoffClerkMutationHookResult = ReturnType<typeof useUpdatePayoffClerkMutation>;
export type UpdatePayoffClerkMutationResult = Apollo.MutationResult<UpdatePayoffClerkMutation>;
export type UpdatePayoffClerkMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayoffClerkMutation,
  UpdatePayoffClerkMutationVariables
>;
export const PayoffRequestFailureRequestDocument = gql`
  mutation payoffRequestFailureRequest($payoffRequest: payoffRequestInput!) {
    payoffRequestFailureRequest(payoffRequest: $payoffRequest) {
      id
      fail_reason
    }
  }
`;
export type PayoffRequestFailureRequestMutationFn = Apollo.MutationFunction<
  PayoffRequestFailureRequestMutation,
  PayoffRequestFailureRequestMutationVariables
>;

/**
 * __usePayoffRequestFailureRequestMutation__
 *
 * To run a mutation, you first call `usePayoffRequestFailureRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayoffRequestFailureRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payoffRequestFailureRequestMutation, { data, loading, error }] = usePayoffRequestFailureRequestMutation({
 *   variables: {
 *      payoffRequest: // value for 'payoffRequest'
 *   },
 * });
 */
export function usePayoffRequestFailureRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayoffRequestFailureRequestMutation,
    PayoffRequestFailureRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayoffRequestFailureRequestMutation,
    PayoffRequestFailureRequestMutationVariables
  >(PayoffRequestFailureRequestDocument, options);
}
export type PayoffRequestFailureRequestMutationHookResult = ReturnType<
  typeof usePayoffRequestFailureRequestMutation
>;
export type PayoffRequestFailureRequestMutationResult =
  Apollo.MutationResult<PayoffRequestFailureRequestMutation>;
export type PayoffRequestFailureRequestMutationOptions = Apollo.BaseMutationOptions<
  PayoffRequestFailureRequestMutation,
  PayoffRequestFailureRequestMutationVariables
>;
export const PayoffRequestFailDocument = gql`
  mutation payoffRequestFail($payoffRequest: payoffRequestInput!) {
    payoffRequestFail(payoffRequest: $payoffRequest) {
      id
      fail_reason
    }
  }
`;
export type PayoffRequestFailMutationFn = Apollo.MutationFunction<
  PayoffRequestFailMutation,
  PayoffRequestFailMutationVariables
>;

/**
 * __usePayoffRequestFailMutation__
 *
 * To run a mutation, you first call `usePayoffRequestFailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayoffRequestFailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payoffRequestFailMutation, { data, loading, error }] = usePayoffRequestFailMutation({
 *   variables: {
 *      payoffRequest: // value for 'payoffRequest'
 *   },
 * });
 */
export function usePayoffRequestFailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayoffRequestFailMutation,
    PayoffRequestFailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayoffRequestFailMutation, PayoffRequestFailMutationVariables>(
    PayoffRequestFailDocument,
    options,
  );
}
export type PayoffRequestFailMutationHookResult = ReturnType<typeof usePayoffRequestFailMutation>;
export type PayoffRequestFailMutationResult = Apollo.MutationResult<PayoffRequestFailMutation>;
export type PayoffRequestFailMutationOptions = Apollo.BaseMutationOptions<
  PayoffRequestFailMutation,
  PayoffRequestFailMutationVariables
>;
export const CreatePayoffRequestDocument = gql`
  mutation createPayoffRequest($deal_id: ID!) {
    createPayoffRequest(deal_id: $deal_id) {
      deal_id
      status
      vehicle_payoff
      sales_tax
      completed_date
      good_through_date
    }
  }
`;
export type CreatePayoffRequestMutationFn = Apollo.MutationFunction<
  CreatePayoffRequestMutation,
  CreatePayoffRequestMutationVariables
>;

/**
 * __useCreatePayoffRequestMutation__
 *
 * To run a mutation, you first call `useCreatePayoffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayoffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayoffRequestMutation, { data, loading, error }] = useCreatePayoffRequestMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *   },
 * });
 */
export function useCreatePayoffRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePayoffRequestMutation,
    CreatePayoffRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePayoffRequestMutation, CreatePayoffRequestMutationVariables>(
    CreatePayoffRequestDocument,
    options,
  );
}
export type CreatePayoffRequestMutationHookResult = ReturnType<
  typeof useCreatePayoffRequestMutation
>;
export type CreatePayoffRequestMutationResult = Apollo.MutationResult<CreatePayoffRequestMutation>;
export type CreatePayoffRequestMutationOptions = Apollo.BaseMutationOptions<
  CreatePayoffRequestMutation,
  CreatePayoffRequestMutationVariables
>;
export const PayoffRequestCancelDocument = gql`
  mutation payoffRequestCancel($payoffRequestId: ID!) {
    payoffRequestCancel(payoffRequestId: $payoffRequestId) {
      id
    }
  }
`;
export type PayoffRequestCancelMutationFn = Apollo.MutationFunction<
  PayoffRequestCancelMutation,
  PayoffRequestCancelMutationVariables
>;

/**
 * __usePayoffRequestCancelMutation__
 *
 * To run a mutation, you first call `usePayoffRequestCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayoffRequestCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payoffRequestCancelMutation, { data, loading, error }] = usePayoffRequestCancelMutation({
 *   variables: {
 *      payoffRequestId: // value for 'payoffRequestId'
 *   },
 * });
 */
export function usePayoffRequestCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayoffRequestCancelMutation,
    PayoffRequestCancelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayoffRequestCancelMutation, PayoffRequestCancelMutationVariables>(
    PayoffRequestCancelDocument,
    options,
  );
}
export type PayoffRequestCancelMutationHookResult = ReturnType<
  typeof usePayoffRequestCancelMutation
>;
export type PayoffRequestCancelMutationResult = Apollo.MutationResult<PayoffRequestCancelMutation>;
export type PayoffRequestCancelMutationOptions = Apollo.BaseMutationOptions<
  PayoffRequestCancelMutation,
  PayoffRequestCancelMutationVariables
>;
export const OnPayoffRequestUpdateDocument = gql`
  subscription OnPayoffRequestUpdate {
    onPayoffRequestUpdate {
      ...PayoffRequestPopulatedFragment
    }
  }
  ${PayoffRequestPopulatedFragmentFragmentDoc}
`;

/**
 * __useOnPayoffRequestUpdateSubscription__
 *
 * To run a query within a React component, call `useOnPayoffRequestUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPayoffRequestUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPayoffRequestUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnPayoffRequestUpdateSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnPayoffRequestUpdateSubscription,
    OnPayoffRequestUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnPayoffRequestUpdateSubscription,
    OnPayoffRequestUpdateSubscriptionVariables
  >(OnPayoffRequestUpdateDocument, options);
}
export type OnPayoffRequestUpdateSubscriptionHookResult = ReturnType<
  typeof useOnPayoffRequestUpdateSubscription
>;
export type OnPayoffRequestUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnPayoffRequestUpdateSubscription>;
export const OnPayoffRequestStatusUpdateDocument = gql`
  subscription onPayoffRequestStatusUpdate($dealId: ID) {
    onPayoffRequestStatusUpdate(dealId: $dealId) {
      id
      deal_id
      temporary_info_id
      status
      vehicle_payoff
      good_through_date
      sales_tax
      completed_date
      fail_reason
      created_at
      updated_at
      deal {
        id
        state
        customer {
          first_name
          last_name
        }
        car {
          payoff {
            user_entered_total_payoff
            vehicle_payoff
            sales_tax_from_payoff
            payoff_includes_sales_tax
            sales_tax_from_payoff_entered_manually
            good_through_date
          }
        }
        tags {
          id
          slug
          display_name
          color
          is_active
          is_dashboard_visible
        }
      }
    }
  }
`;

/**
 * __useOnPayoffRequestStatusUpdateSubscription__
 *
 * To run a query within a React component, call `useOnPayoffRequestStatusUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPayoffRequestStatusUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPayoffRequestStatusUpdateSubscription({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useOnPayoffRequestStatusUpdateSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnPayoffRequestStatusUpdateSubscription,
    OnPayoffRequestStatusUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnPayoffRequestStatusUpdateSubscription,
    OnPayoffRequestStatusUpdateSubscriptionVariables
  >(OnPayoffRequestStatusUpdateDocument, options);
}
export type OnPayoffRequestStatusUpdateSubscriptionHookResult = ReturnType<
  typeof useOnPayoffRequestStatusUpdateSubscription
>;
export type OnPayoffRequestStatusUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnPayoffRequestStatusUpdateSubscription>;
export const PayoffRequestDocument = gql`
  query payoffRequest($deal_id: ID) {
    payoffRequest(deal_id: $deal_id) {
      id
      status
    }
  }
`;

/**
 * __usePayoffRequestQuery__
 *
 * To run a query within a React component, call `usePayoffRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoffRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoffRequestQuery({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *   },
 * });
 */
export function usePayoffRequestQuery(
  baseOptions?: Apollo.QueryHookOptions<PayoffRequestQuery, PayoffRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayoffRequestQuery, PayoffRequestQueryVariables>(
    PayoffRequestDocument,
    options,
  );
}
export function usePayoffRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PayoffRequestQuery, PayoffRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PayoffRequestQuery, PayoffRequestQueryVariables>(
    PayoffRequestDocument,
    options,
  );
}
export type PayoffRequestQueryHookResult = ReturnType<typeof usePayoffRequestQuery>;
export type PayoffRequestLazyQueryHookResult = ReturnType<typeof usePayoffRequestLazyQuery>;
export type PayoffRequestQueryResult = Apollo.QueryResult<
  PayoffRequestQuery,
  PayoffRequestQueryVariables
>;
export const PayoffRequestUpdateDocument = gql`
  mutation payoffRequestUpdate($payoffRequest: payoffRequestInput!) {
    payoffRequestUpdate(payoffRequest: $payoffRequest) {
      id
    }
  }
`;
export type PayoffRequestUpdateMutationFn = Apollo.MutationFunction<
  PayoffRequestUpdateMutation,
  PayoffRequestUpdateMutationVariables
>;

/**
 * __usePayoffRequestUpdateMutation__
 *
 * To run a mutation, you first call `usePayoffRequestUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayoffRequestUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payoffRequestUpdateMutation, { data, loading, error }] = usePayoffRequestUpdateMutation({
 *   variables: {
 *      payoffRequest: // value for 'payoffRequest'
 *   },
 * });
 */
export function usePayoffRequestUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayoffRequestUpdateMutation,
    PayoffRequestUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayoffRequestUpdateMutation, PayoffRequestUpdateMutationVariables>(
    PayoffRequestUpdateDocument,
    options,
  );
}
export type PayoffRequestUpdateMutationHookResult = ReturnType<
  typeof usePayoffRequestUpdateMutation
>;
export type PayoffRequestUpdateMutationResult = Apollo.MutationResult<PayoffRequestUpdateMutation>;
export type PayoffRequestUpdateMutationOptions = Apollo.BaseMutationOptions<
  PayoffRequestUpdateMutation,
  PayoffRequestUpdateMutationVariables
>;
export const PodsDocument = gql`
  query pods(
    $userId: String
    $permissions: [String]
    $pod_ids: [ID]
    $team_type: [teamType]
    $includeDetails: Boolean = false
    $hideArchived: Boolean = false
  ) {
    pods(
      userId: $userId
      permissions: $permissions
      pod_ids: $pod_ids
      team_type: $team_type
      hideArchived: $hideArchived
    ) {
      id
      name
      color
      hours
      archived
      team_type
      created_at
      vsc_multiplier
      vsc_markup
      manager_commission_rate
      manager_commission_type
      special_commission_rate
      special_commission_type
      closer_commission_rate
      closer_commission_type
      setter_commission_rate
      setter_commission_type
      managers @include(if: $includeDetails) {
        id
        name
      }
      leads @include(if: $includeDetails) {
        id
        name
      }
      problem_solver
      us_states_object {
        states
      }
      parent_pod_id
      pod_users @include(if: $includeDetails) {
        id
        user_id
        pod_id
        team_role
        user {
          id
          name
        }
      }
      parent_pod @include(if: $includeDetails) {
        id
        name
      }
      child_pods @include(if: $includeDetails) {
        id
        name
      }
      usersWithoutTeamRole @include(if: $includeDetails) {
        id
        name
      }
    }
  }
`;

/**
 * __usePodsQuery__
 *
 * To run a query within a React component, call `usePodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePodsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      permissions: // value for 'permissions'
 *      pod_ids: // value for 'pod_ids'
 *      team_type: // value for 'team_type'
 *      includeDetails: // value for 'includeDetails'
 *      hideArchived: // value for 'hideArchived'
 *   },
 * });
 */
export function usePodsQuery(baseOptions?: Apollo.QueryHookOptions<PodsQuery, PodsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PodsQuery, PodsQueryVariables>(PodsDocument, options);
}
export function usePodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PodsQuery, PodsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PodsQuery, PodsQueryVariables>(PodsDocument, options);
}
export type PodsQueryHookResult = ReturnType<typeof usePodsQuery>;
export type PodsLazyQueryHookResult = ReturnType<typeof usePodsLazyQuery>;
export type PodsQueryResult = Apollo.QueryResult<PodsQuery, PodsQueryVariables>;
export const PodUpsertAndPodUsersUpdateDocument = gql`
  mutation podUpsertAndPodUsersUpdate($newPod: podInput!, $oldPod: podInput) {
    podUpsertAndPodUsersUpdate(newPod: $newPod, oldPod: $oldPod) {
      id
    }
  }
`;
export type PodUpsertAndPodUsersUpdateMutationFn = Apollo.MutationFunction<
  PodUpsertAndPodUsersUpdateMutation,
  PodUpsertAndPodUsersUpdateMutationVariables
>;

/**
 * __usePodUpsertAndPodUsersUpdateMutation__
 *
 * To run a mutation, you first call `usePodUpsertAndPodUsersUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePodUpsertAndPodUsersUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [podUpsertAndPodUsersUpdateMutation, { data, loading, error }] = usePodUpsertAndPodUsersUpdateMutation({
 *   variables: {
 *      newPod: // value for 'newPod'
 *      oldPod: // value for 'oldPod'
 *   },
 * });
 */
export function usePodUpsertAndPodUsersUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PodUpsertAndPodUsersUpdateMutation,
    PodUpsertAndPodUsersUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PodUpsertAndPodUsersUpdateMutation,
    PodUpsertAndPodUsersUpdateMutationVariables
  >(PodUpsertAndPodUsersUpdateDocument, options);
}
export type PodUpsertAndPodUsersUpdateMutationHookResult = ReturnType<
  typeof usePodUpsertAndPodUsersUpdateMutation
>;
export type PodUpsertAndPodUsersUpdateMutationResult =
  Apollo.MutationResult<PodUpsertAndPodUsersUpdateMutation>;
export type PodUpsertAndPodUsersUpdateMutationOptions = Apollo.BaseMutationOptions<
  PodUpsertAndPodUsersUpdateMutation,
  PodUpsertAndPodUsersUpdateMutationVariables
>;
export const PodUpsertDocument = gql`
  mutation podUpsert($pod: basePodInput) {
    podUpsert(pod: $pod) {
      id
      name
      color
      hours
      vsc_multiplier
      vsc_markup
      archived
      closer_commission_rate
      team_type
      problem_solver
      us_states_object {
        states
      }
    }
  }
`;
export type PodUpsertMutationFn = Apollo.MutationFunction<
  PodUpsertMutation,
  PodUpsertMutationVariables
>;

/**
 * __usePodUpsertMutation__
 *
 * To run a mutation, you first call `usePodUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePodUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [podUpsertMutation, { data, loading, error }] = usePodUpsertMutation({
 *   variables: {
 *      pod: // value for 'pod'
 *   },
 * });
 */
export function usePodUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<PodUpsertMutation, PodUpsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PodUpsertMutation, PodUpsertMutationVariables>(
    PodUpsertDocument,
    options,
  );
}
export type PodUpsertMutationHookResult = ReturnType<typeof usePodUpsertMutation>;
export type PodUpsertMutationResult = Apollo.MutationResult<PodUpsertMutation>;
export type PodUpsertMutationOptions = Apollo.BaseMutationOptions<
  PodUpsertMutation,
  PodUpsertMutationVariables
>;
export const ArchivePodDocument = gql`
  mutation archivePod($id: ID!, $name: String!) {
    archivePod(id: $id, name: $name)
  }
`;
export type ArchivePodMutationFn = Apollo.MutationFunction<
  ArchivePodMutation,
  ArchivePodMutationVariables
>;

/**
 * __useArchivePodMutation__
 *
 * To run a mutation, you first call `useArchivePodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePodMutation, { data, loading, error }] = useArchivePodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useArchivePodMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchivePodMutation, ArchivePodMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchivePodMutation, ArchivePodMutationVariables>(
    ArchivePodDocument,
    options,
  );
}
export type ArchivePodMutationHookResult = ReturnType<typeof useArchivePodMutation>;
export type ArchivePodMutationResult = Apollo.MutationResult<ArchivePodMutation>;
export type ArchivePodMutationOptions = Apollo.BaseMutationOptions<
  ArchivePodMutation,
  ArchivePodMutationVariables
>;
export const RevivePodDocument = gql`
  mutation revivePod($id: ID!, $name: String!) {
    revivePod(id: $id, name: $name) {
      id
      name
      color
      hours
      archived
    }
  }
`;
export type RevivePodMutationFn = Apollo.MutationFunction<
  RevivePodMutation,
  RevivePodMutationVariables
>;

/**
 * __useRevivePodMutation__
 *
 * To run a mutation, you first call `useRevivePodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevivePodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revivePodMutation, { data, loading, error }] = useRevivePodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRevivePodMutation(
  baseOptions?: Apollo.MutationHookOptions<RevivePodMutation, RevivePodMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RevivePodMutation, RevivePodMutationVariables>(
    RevivePodDocument,
    options,
  );
}
export type RevivePodMutationHookResult = ReturnType<typeof useRevivePodMutation>;
export type RevivePodMutationResult = Apollo.MutationResult<RevivePodMutation>;
export type RevivePodMutationOptions = Apollo.BaseMutationOptions<
  RevivePodMutation,
  RevivePodMutationVariables
>;
export const TitlingPodsAllDocument = gql`
  query titlingPodsAll {
    titlingPodsAll {
      id
      name
      color
      us_states_object {
        states
      }
      problem_solver
      archived
      pod_users {
        id
        user_id
        pod_id
        team_role
        user {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useTitlingPodsAllQuery__
 *
 * To run a query within a React component, call `useTitlingPodsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useTitlingPodsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTitlingPodsAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useTitlingPodsAllQuery(
  baseOptions?: Apollo.QueryHookOptions<TitlingPodsAllQuery, TitlingPodsAllQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TitlingPodsAllQuery, TitlingPodsAllQueryVariables>(
    TitlingPodsAllDocument,
    options,
  );
}
export function useTitlingPodsAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TitlingPodsAllQuery, TitlingPodsAllQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TitlingPodsAllQuery, TitlingPodsAllQueryVariables>(
    TitlingPodsAllDocument,
    options,
  );
}
export type TitlingPodsAllQueryHookResult = ReturnType<typeof useTitlingPodsAllQuery>;
export type TitlingPodsAllLazyQueryHookResult = ReturnType<typeof useTitlingPodsAllLazyQuery>;
export type TitlingPodsAllQueryResult = Apollo.QueryResult<
  TitlingPodsAllQuery,
  TitlingPodsAllQueryVariables
>;
export const GetDdSoftPullDocument = gql`
  query getDDSoftPull(
    $dealId: ID
    $paymentEstimateInput: paymentEstimateInput!
    $shouldForceRetry: Boolean
  ) {
    getDDSoftPull(
      dealId: $dealId
      paymentEstimateInput: $paymentEstimateInput
      shouldForceRetry: $shouldForceRetry
    ) {
      customer {
        ...PrequalificationFragment
      }
      cobuyer {
        ...PrequalificationFragment
      }
    }
  }
  ${PrequalificationFragmentFragmentDoc}
`;

/**
 * __useGetDdSoftPullQuery__
 *
 * To run a query within a React component, call `useGetDdSoftPullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDdSoftPullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDdSoftPullQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      paymentEstimateInput: // value for 'paymentEstimateInput'
 *      shouldForceRetry: // value for 'shouldForceRetry'
 *   },
 * });
 */
export function useGetDdSoftPullQuery(
  baseOptions: Apollo.QueryHookOptions<GetDdSoftPullQuery, GetDdSoftPullQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDdSoftPullQuery, GetDdSoftPullQueryVariables>(
    GetDdSoftPullDocument,
    options,
  );
}
export function useGetDdSoftPullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDdSoftPullQuery, GetDdSoftPullQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDdSoftPullQuery, GetDdSoftPullQueryVariables>(
    GetDdSoftPullDocument,
    options,
  );
}
export type GetDdSoftPullQueryHookResult = ReturnType<typeof useGetDdSoftPullQuery>;
export type GetDdSoftPullLazyQueryHookResult = ReturnType<typeof useGetDdSoftPullLazyQuery>;
export type GetDdSoftPullQueryResult = Apollo.QueryResult<
  GetDdSoftPullQuery,
  GetDdSoftPullQueryVariables
>;
export const ExperianCreditReportDocument = gql`
  query experianCreditReport($dealId: ID!) {
    experianCreditReport(dealId: $dealId) {
      customer {
        ...PrequalificationCreditReportFragment
      }
      cobuyer {
        ...PrequalificationCreditReportFragment
      }
    }
  }
  ${PrequalificationCreditReportFragmentFragmentDoc}
`;

/**
 * __useExperianCreditReportQuery__
 *
 * To run a query within a React component, call `useExperianCreditReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperianCreditReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperianCreditReportQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useExperianCreditReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExperianCreditReportQuery,
    ExperianCreditReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExperianCreditReportQuery, ExperianCreditReportQueryVariables>(
    ExperianCreditReportDocument,
    options,
  );
}
export function useExperianCreditReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExperianCreditReportQuery,
    ExperianCreditReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExperianCreditReportQuery, ExperianCreditReportQueryVariables>(
    ExperianCreditReportDocument,
    options,
  );
}
export type ExperianCreditReportQueryHookResult = ReturnType<typeof useExperianCreditReportQuery>;
export type ExperianCreditReportLazyQueryHookResult = ReturnType<
  typeof useExperianCreditReportLazyQuery
>;
export type ExperianCreditReportQueryResult = Apollo.QueryResult<
  ExperianCreditReportQuery,
  ExperianCreditReportQueryVariables
>;
export const VscRatesDocument = gql`
  query vscRates($deal_id: ID!, $data: rateInput) {
    vscRates(deal_id: $deal_id, data: $data) {
      ...RateFragment
    }
  }
  ${RateFragmentFragmentDoc}
`;

/**
 * __useVscRatesQuery__
 *
 * To run a query within a React component, call `useVscRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVscRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVscRatesQuery({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVscRatesQuery(
  baseOptions: Apollo.QueryHookOptions<VscRatesQuery, VscRatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VscRatesQuery, VscRatesQueryVariables>(VscRatesDocument, options);
}
export function useVscRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VscRatesQuery, VscRatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VscRatesQuery, VscRatesQueryVariables>(VscRatesDocument, options);
}
export type VscRatesQueryHookResult = ReturnType<typeof useVscRatesQuery>;
export type VscRatesLazyQueryHookResult = ReturnType<typeof useVscRatesLazyQuery>;
export type VscRatesQueryResult = Apollo.QueryResult<VscRatesQuery, VscRatesQueryVariables>;
export const GapRatesDocument = gql`
  query gapRates($bank: String!, $deal_id: ID!, $data: rateInput) {
    gapRates(bank: $bank, deal_id: $deal_id, data: $data) {
      ...RateFragment
    }
  }
  ${RateFragmentFragmentDoc}
`;

/**
 * __useGapRatesQuery__
 *
 * To run a query within a React component, call `useGapRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGapRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGapRatesQuery({
 *   variables: {
 *      bank: // value for 'bank'
 *      deal_id: // value for 'deal_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGapRatesQuery(
  baseOptions: Apollo.QueryHookOptions<GapRatesQuery, GapRatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GapRatesQuery, GapRatesQueryVariables>(GapRatesDocument, options);
}
export function useGapRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GapRatesQuery, GapRatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GapRatesQuery, GapRatesQueryVariables>(GapRatesDocument, options);
}
export type GapRatesQueryHookResult = ReturnType<typeof useGapRatesQuery>;
export type GapRatesLazyQueryHookResult = ReturnType<typeof useGapRatesLazyQuery>;
export type GapRatesQueryResult = Apollo.QueryResult<GapRatesQuery, GapRatesQueryVariables>;
export const CreateReviewDocument = gql`
  mutation createReview($review: reviewInput) {
    createReview(review: $review) {
      id
    }
  }
`;
export type CreateReviewMutationFn = Apollo.MutationFunction<
  CreateReviewMutation,
  CreateReviewMutationVariables
>;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      review: // value for 'review'
 *   },
 * });
 */
export function useCreateReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateReviewMutation, CreateReviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateReviewMutation, CreateReviewMutationVariables>(
    CreateReviewDocument,
    options,
  );
}
export type CreateReviewMutationHookResult = ReturnType<typeof useCreateReviewMutation>;
export type CreateReviewMutationResult = Apollo.MutationResult<CreateReviewMutation>;
export type CreateReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateReviewMutation,
  CreateReviewMutationVariables
>;
export const ReviewsDocument = gql`
  query reviews {
    reviews {
      id
      customer_name
      review
      com_visible
      created_at
      location
    }
  }
`;

/**
 * __useReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<ReviewsQuery, ReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export function useReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReviewsQuery, ReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsQueryResult = Apollo.QueryResult<ReviewsQuery, ReviewsQueryVariables>;
export const DeleteReviewDocument = gql`
  mutation deleteReview($id: ID!) {
    deleteReview(id: $id) {
      id
    }
  }
`;
export type DeleteReviewMutationFn = Apollo.MutationFunction<
  DeleteReviewMutation,
  DeleteReviewMutationVariables
>;

/**
 * __useDeleteReviewMutation__
 *
 * To run a mutation, you first call `useDeleteReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReviewMutation, { data, loading, error }] = useDeleteReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteReviewMutation, DeleteReviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteReviewMutation, DeleteReviewMutationVariables>(
    DeleteReviewDocument,
    options,
  );
}
export type DeleteReviewMutationHookResult = ReturnType<typeof useDeleteReviewMutation>;
export type DeleteReviewMutationResult = Apollo.MutationResult<DeleteReviewMutation>;
export type DeleteReviewMutationOptions = Apollo.BaseMutationOptions<
  DeleteReviewMutation,
  DeleteReviewMutationVariables
>;
export const UpdateReviewDocument = gql`
  mutation updateReview($review: reviewInput) {
    updateReview(review: $review) {
      id
      customer_name
      review
      com_visible
      created_at
      location
    }
  }
`;
export type UpdateReviewMutationFn = Apollo.MutationFunction<
  UpdateReviewMutation,
  UpdateReviewMutationVariables
>;

/**
 * __useUpdateReviewMutation__
 *
 * To run a mutation, you first call `useUpdateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewMutation, { data, loading, error }] = useUpdateReviewMutation({
 *   variables: {
 *      review: // value for 'review'
 *   },
 * });
 */
export function useUpdateReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateReviewMutation, UpdateReviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReviewMutation, UpdateReviewMutationVariables>(
    UpdateReviewDocument,
    options,
  );
}
export type UpdateReviewMutationHookResult = ReturnType<typeof useUpdateReviewMutation>;
export type UpdateReviewMutationResult = Apollo.MutationResult<UpdateReviewMutation>;
export type UpdateReviewMutationOptions = Apollo.BaseMutationOptions<
  UpdateReviewMutation,
  UpdateReviewMutationVariables
>;
export const CreateSsnLogDocument = gql`
  mutation createSsnLog($log: ssnLogInput!) {
    ssnLogCreate(log: $log) {
      id
    }
  }
`;
export type CreateSsnLogMutationFn = Apollo.MutationFunction<
  CreateSsnLogMutation,
  CreateSsnLogMutationVariables
>;

/**
 * __useCreateSsnLogMutation__
 *
 * To run a mutation, you first call `useCreateSsnLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSsnLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSsnLogMutation, { data, loading, error }] = useCreateSsnLogMutation({
 *   variables: {
 *      log: // value for 'log'
 *   },
 * });
 */
export function useCreateSsnLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSsnLogMutation, CreateSsnLogMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSsnLogMutation, CreateSsnLogMutationVariables>(
    CreateSsnLogDocument,
    options,
  );
}
export type CreateSsnLogMutationHookResult = ReturnType<typeof useCreateSsnLogMutation>;
export type CreateSsnLogMutationResult = Apollo.MutationResult<CreateSsnLogMutation>;
export type CreateSsnLogMutationOptions = Apollo.BaseMutationOptions<
  CreateSsnLogMutation,
  CreateSsnLogMutationVariables
>;
export const StructuringFollowUpDocument = gql`
  query structuringFollowUp($dealId: ID!) {
    structuringFollowUp(dealId: $dealId) {
      ...StructuringFollowUpFragment
    }
  }
  ${StructuringFollowUpFragmentFragmentDoc}
`;

/**
 * __useStructuringFollowUpQuery__
 *
 * To run a query within a React component, call `useStructuringFollowUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useStructuringFollowUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStructuringFollowUpQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useStructuringFollowUpQuery(
  baseOptions: Apollo.QueryHookOptions<StructuringFollowUpQuery, StructuringFollowUpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StructuringFollowUpQuery, StructuringFollowUpQueryVariables>(
    StructuringFollowUpDocument,
    options,
  );
}
export function useStructuringFollowUpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StructuringFollowUpQuery,
    StructuringFollowUpQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StructuringFollowUpQuery, StructuringFollowUpQueryVariables>(
    StructuringFollowUpDocument,
    options,
  );
}
export type StructuringFollowUpQueryHookResult = ReturnType<typeof useStructuringFollowUpQuery>;
export type StructuringFollowUpLazyQueryHookResult = ReturnType<
  typeof useStructuringFollowUpLazyQuery
>;
export type StructuringFollowUpQueryResult = Apollo.QueryResult<
  StructuringFollowUpQuery,
  StructuringFollowUpQueryVariables
>;
export const StructuringFollowUpDealsDocument = gql`
  query structuringFollowUpDeals(
    $sortColumn: StructuringFollowUpSortColumn!
    $sortDirection: StructuringFollowUpSortDirection!
    $sources: [String]!
    $dealTypes: [DealType]!
    $assignedToMe: Boolean
    $bootRequested: Boolean
    $notifications: Boolean
    $podId: ID
    $page: Int!
    $pageSize: Int!
    $showActiveTags: Boolean
    $showMatureFollowUps: Boolean
  ) {
    structuringFollowUpDeals(
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      sources: $sources
      dealTypes: $dealTypes
      assignedToMe: $assignedToMe
      bootRequested: $bootRequested
      notifications: $notifications
      podId: $podId
      page: $page
      pageSize: $pageSize
      showActiveTags: $showActiveTags
      showMatureFollowUps: $showMatureFollowUps
    ) {
      deals {
        ...StructuringFollowUpDealFragment
      }
      totalRecords
    }
  }
  ${StructuringFollowUpDealFragmentFragmentDoc}
`;

/**
 * __useStructuringFollowUpDealsQuery__
 *
 * To run a query within a React component, call `useStructuringFollowUpDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStructuringFollowUpDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStructuringFollowUpDealsQuery({
 *   variables: {
 *      sortColumn: // value for 'sortColumn'
 *      sortDirection: // value for 'sortDirection'
 *      sources: // value for 'sources'
 *      dealTypes: // value for 'dealTypes'
 *      assignedToMe: // value for 'assignedToMe'
 *      bootRequested: // value for 'bootRequested'
 *      notifications: // value for 'notifications'
 *      podId: // value for 'podId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      showActiveTags: // value for 'showActiveTags'
 *      showMatureFollowUps: // value for 'showMatureFollowUps'
 *   },
 * });
 */
export function useStructuringFollowUpDealsQuery(
  baseOptions: Apollo.QueryHookOptions<
    StructuringFollowUpDealsQuery,
    StructuringFollowUpDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StructuringFollowUpDealsQuery, StructuringFollowUpDealsQueryVariables>(
    StructuringFollowUpDealsDocument,
    options,
  );
}
export function useStructuringFollowUpDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StructuringFollowUpDealsQuery,
    StructuringFollowUpDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StructuringFollowUpDealsQuery, StructuringFollowUpDealsQueryVariables>(
    StructuringFollowUpDealsDocument,
    options,
  );
}
export type StructuringFollowUpDealsQueryHookResult = ReturnType<
  typeof useStructuringFollowUpDealsQuery
>;
export type StructuringFollowUpDealsLazyQueryHookResult = ReturnType<
  typeof useStructuringFollowUpDealsLazyQuery
>;
export type StructuringFollowUpDealsQueryResult = Apollo.QueryResult<
  StructuringFollowUpDealsQuery,
  StructuringFollowUpDealsQueryVariables
>;
export const CreateStructuringFollowUpDocument = gql`
  mutation createStructuringFollowUp($dealId: ID!, $notes: String!) {
    createStructuringFollowUp(dealId: $dealId, notes: $notes)
  }
`;
export type CreateStructuringFollowUpMutationFn = Apollo.MutationFunction<
  CreateStructuringFollowUpMutation,
  CreateStructuringFollowUpMutationVariables
>;

/**
 * __useCreateStructuringFollowUpMutation__
 *
 * To run a mutation, you first call `useCreateStructuringFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStructuringFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStructuringFollowUpMutation, { data, loading, error }] = useCreateStructuringFollowUpMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useCreateStructuringFollowUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStructuringFollowUpMutation,
    CreateStructuringFollowUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStructuringFollowUpMutation,
    CreateStructuringFollowUpMutationVariables
  >(CreateStructuringFollowUpDocument, options);
}
export type CreateStructuringFollowUpMutationHookResult = ReturnType<
  typeof useCreateStructuringFollowUpMutation
>;
export type CreateStructuringFollowUpMutationResult =
  Apollo.MutationResult<CreateStructuringFollowUpMutation>;
export type CreateStructuringFollowUpMutationOptions = Apollo.BaseMutationOptions<
  CreateStructuringFollowUpMutation,
  CreateStructuringFollowUpMutationVariables
>;
export const UpdateStructuringFollowUpNotesDocument = gql`
  mutation updateStructuringFollowUpNotes($id: ID!, $notes: String!) {
    updateStructuringFollowUpNotes(id: $id, notes: $notes)
  }
`;
export type UpdateStructuringFollowUpNotesMutationFn = Apollo.MutationFunction<
  UpdateStructuringFollowUpNotesMutation,
  UpdateStructuringFollowUpNotesMutationVariables
>;

/**
 * __useUpdateStructuringFollowUpNotesMutation__
 *
 * To run a mutation, you first call `useUpdateStructuringFollowUpNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStructuringFollowUpNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStructuringFollowUpNotesMutation, { data, loading, error }] = useUpdateStructuringFollowUpNotesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateStructuringFollowUpNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStructuringFollowUpNotesMutation,
    UpdateStructuringFollowUpNotesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStructuringFollowUpNotesMutation,
    UpdateStructuringFollowUpNotesMutationVariables
  >(UpdateStructuringFollowUpNotesDocument, options);
}
export type UpdateStructuringFollowUpNotesMutationHookResult = ReturnType<
  typeof useUpdateStructuringFollowUpNotesMutation
>;
export type UpdateStructuringFollowUpNotesMutationResult =
  Apollo.MutationResult<UpdateStructuringFollowUpNotesMutation>;
export type UpdateStructuringFollowUpNotesMutationOptions = Apollo.BaseMutationOptions<
  UpdateStructuringFollowUpNotesMutation,
  UpdateStructuringFollowUpNotesMutationVariables
>;
export const DeleteStructuringFollowUpDocument = gql`
  mutation deleteStructuringFollowUp($id: ID!) {
    deleteStructuringFollowUp(id: $id)
  }
`;
export type DeleteStructuringFollowUpMutationFn = Apollo.MutationFunction<
  DeleteStructuringFollowUpMutation,
  DeleteStructuringFollowUpMutationVariables
>;

/**
 * __useDeleteStructuringFollowUpMutation__
 *
 * To run a mutation, you first call `useDeleteStructuringFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStructuringFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStructuringFollowUpMutation, { data, loading, error }] = useDeleteStructuringFollowUpMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStructuringFollowUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStructuringFollowUpMutation,
    DeleteStructuringFollowUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteStructuringFollowUpMutation,
    DeleteStructuringFollowUpMutationVariables
  >(DeleteStructuringFollowUpDocument, options);
}
export type DeleteStructuringFollowUpMutationHookResult = ReturnType<
  typeof useDeleteStructuringFollowUpMutation
>;
export type DeleteStructuringFollowUpMutationResult =
  Apollo.MutationResult<DeleteStructuringFollowUpMutation>;
export type DeleteStructuringFollowUpMutationOptions = Apollo.BaseMutationOptions<
  DeleteStructuringFollowUpMutation,
  DeleteStructuringFollowUpMutationVariables
>;
export const OnStructuringFollowUpUpdateDocument = gql`
  subscription onStructuringFollowUpUpdate(
    $dealId: ID
    $sources: [String]
    $types: [DealType]
    $assignedToMe: Boolean
    $podId: ID
  ) {
    onStructuringFollowUpUpdate(
      dealId: $dealId
      sources: $sources
      types: $types
      assignedToMe: $assignedToMe
      podId: $podId
    ) {
      ...StructuringFollowUpDealFragment
    }
  }
  ${StructuringFollowUpDealFragmentFragmentDoc}
`;

/**
 * __useOnStructuringFollowUpUpdateSubscription__
 *
 * To run a query within a React component, call `useOnStructuringFollowUpUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnStructuringFollowUpUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnStructuringFollowUpUpdateSubscription({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      sources: // value for 'sources'
 *      types: // value for 'types'
 *      assignedToMe: // value for 'assignedToMe'
 *      podId: // value for 'podId'
 *   },
 * });
 */
export function useOnStructuringFollowUpUpdateSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnStructuringFollowUpUpdateSubscription,
    OnStructuringFollowUpUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnStructuringFollowUpUpdateSubscription,
    OnStructuringFollowUpUpdateSubscriptionVariables
  >(OnStructuringFollowUpUpdateDocument, options);
}
export type OnStructuringFollowUpUpdateSubscriptionHookResult = ReturnType<
  typeof useOnStructuringFollowUpUpdateSubscription
>;
export type OnStructuringFollowUpUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnStructuringFollowUpUpdateSubscription>;
export const TagsDocument = gql`
  query tags($input: TagsInput) {
    tags(input: $input) {
      id
      slug
      display_name
      color
      is_active
      is_dashboard_visible
    }
  }
`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
}
export function useTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
}
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const JurisdictionDocument = gql`
  query jurisdiction($state: StateAbbreviation!) {
    jurisdiction(state: $state) {
      hasRegistrationOnly
      hasTitleOnly
      hasTitleAndRegistration
      allowRegistrationTransfer
      allowPlateTransfer
      requireWalkIn
    }
  }
`;

/**
 * __useJurisdictionQuery__
 *
 * To run a query within a React component, call `useJurisdictionQuery` and pass it any options that fit your needs.
 * When your component renders, `useJurisdictionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJurisdictionQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useJurisdictionQuery(
  baseOptions: Apollo.QueryHookOptions<JurisdictionQuery, JurisdictionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JurisdictionQuery, JurisdictionQueryVariables>(
    JurisdictionDocument,
    options,
  );
}
export function useJurisdictionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JurisdictionQuery, JurisdictionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JurisdictionQuery, JurisdictionQueryVariables>(
    JurisdictionDocument,
    options,
  );
}
export type JurisdictionQueryHookResult = ReturnType<typeof useJurisdictionQuery>;
export type JurisdictionLazyQueryHookResult = ReturnType<typeof useJurisdictionLazyQuery>;
export type JurisdictionQueryResult = Apollo.QueryResult<
  JurisdictionQuery,
  JurisdictionQueryVariables
>;
export const JurisdictionProcessorDocument = gql`
  query jurisdictionProcessor($state: StateAbbreviation!) {
    jurisdiction(state: $state) {
      supportedProcessors
    }
  }
`;

/**
 * __useJurisdictionProcessorQuery__
 *
 * To run a query within a React component, call `useJurisdictionProcessorQuery` and pass it any options that fit your needs.
 * When your component renders, `useJurisdictionProcessorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJurisdictionProcessorQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useJurisdictionProcessorQuery(
  baseOptions: Apollo.QueryHookOptions<
    JurisdictionProcessorQuery,
    JurisdictionProcessorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JurisdictionProcessorQuery, JurisdictionProcessorQueryVariables>(
    JurisdictionProcessorDocument,
    options,
  );
}
export function useJurisdictionProcessorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JurisdictionProcessorQuery,
    JurisdictionProcessorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JurisdictionProcessorQuery, JurisdictionProcessorQueryVariables>(
    JurisdictionProcessorDocument,
    options,
  );
}
export type JurisdictionProcessorQueryHookResult = ReturnType<typeof useJurisdictionProcessorQuery>;
export type JurisdictionProcessorLazyQueryHookResult = ReturnType<
  typeof useJurisdictionProcessorLazyQuery
>;
export type JurisdictionProcessorQueryResult = Apollo.QueryResult<
  JurisdictionProcessorQuery,
  JurisdictionProcessorQueryVariables
>;
export const RequiredDocumentsAndValidationsDocument = gql`
  query RequiredDocumentsAndValidations(
    $state: StateAbbreviation!
    $types: [TTProductType!]!
    $conditions: TTConditionFilterInput
  ) {
    jurisdiction(state: $state) {
      products(filter: { types: $types }) {
        items {
          id
          type
          documents(conditions: $conditions) {
            count
            items {
              type {
                id
                name
              }
              validations {
                internalDescription
                description
                name
              }
            }
          }
          validations {
            name
            description
          }
        }
      }
    }
  }
`;

/**
 * __useRequiredDocumentsAndValidationsQuery__
 *
 * To run a query within a React component, call `useRequiredDocumentsAndValidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequiredDocumentsAndValidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequiredDocumentsAndValidationsQuery({
 *   variables: {
 *      state: // value for 'state'
 *      types: // value for 'types'
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useRequiredDocumentsAndValidationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequiredDocumentsAndValidationsQuery,
    RequiredDocumentsAndValidationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequiredDocumentsAndValidationsQuery,
    RequiredDocumentsAndValidationsQueryVariables
  >(RequiredDocumentsAndValidationsDocument, options);
}
export function useRequiredDocumentsAndValidationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequiredDocumentsAndValidationsQuery,
    RequiredDocumentsAndValidationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequiredDocumentsAndValidationsQuery,
    RequiredDocumentsAndValidationsQueryVariables
  >(RequiredDocumentsAndValidationsDocument, options);
}
export type RequiredDocumentsAndValidationsQueryHookResult = ReturnType<
  typeof useRequiredDocumentsAndValidationsQuery
>;
export type RequiredDocumentsAndValidationsLazyQueryHookResult = ReturnType<
  typeof useRequiredDocumentsAndValidationsLazyQuery
>;
export type RequiredDocumentsAndValidationsQueryResult = Apollo.QueryResult<
  RequiredDocumentsAndValidationsQuery,
  RequiredDocumentsAndValidationsQueryVariables
>;
export const RequiredDocumentsDocument = gql`
  query RequiredDocuments(
    $state: StateAbbreviation!
    $types: [TTProductType!]!
    $conditions: TTConditionFilterInput
  ) {
    jurisdiction(state: $state) {
      products(filter: { types: $types }) {
        items {
          id
          type
          documents(conditions: $conditions) {
            count
            items {
              type {
                id
                name
              }
              validations {
                name
                description
              }
            }
          }
        }
      }
      hasRegistrationOnly
      hasTitleOnly
      hasTitleAndRegistration
      allowRegistrationTransfer
      allowPlateTransfer
      requireWalkIn
    }
  }
`;

/**
 * __useRequiredDocumentsQuery__
 *
 * To run a query within a React component, call `useRequiredDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequiredDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequiredDocumentsQuery({
 *   variables: {
 *      state: // value for 'state'
 *      types: // value for 'types'
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useRequiredDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<RequiredDocumentsQuery, RequiredDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequiredDocumentsQuery, RequiredDocumentsQueryVariables>(
    RequiredDocumentsDocument,
    options,
  );
}
export function useRequiredDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequiredDocumentsQuery,
    RequiredDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RequiredDocumentsQuery, RequiredDocumentsQueryVariables>(
    RequiredDocumentsDocument,
    options,
  );
}
export type RequiredDocumentsQueryHookResult = ReturnType<typeof useRequiredDocumentsQuery>;
export type RequiredDocumentsLazyQueryHookResult = ReturnType<typeof useRequiredDocumentsLazyQuery>;
export type RequiredDocumentsQueryResult = Apollo.QueryResult<
  RequiredDocumentsQuery,
  RequiredDocumentsQueryVariables
>;
export const GetFeesDocument = gql`
  query getFees(
    $uniqueId: String
    $state: StateAbbreviation!
    $previouslyTitledState: StateAbbreviation!
    $city: String!
    $county: String!
    $zip: String!
    $vin: String!
    $make: String!
    $model: String!
    $year: Int!
    $vehicleType: TTVehicleType!
    $fuelType: TTFuelType!
    $firstName: String!
    $lastName: String!
    $retailBookValue: Float!
    $bookValue: Float!
    $docFee: Float!
    $payoff: Float!
    $estimatedPayoff: Float!
    $ssn: String!
    $warranty: Float!
    $financialInfoId: ID
    $source: String
  ) {
    getFees(
      uniqueId: $uniqueId
      state: $state
      previouslyTitledState: $previouslyTitledState
      city: $city
      county: $county
      zip: $zip
      vin: $vin
      make: $make
      model: $model
      year: $year
      vehicleType: $vehicleType
      fuelType: $fuelType
      firstName: $firstName
      lastName: $lastName
      retailBookValue: $retailBookValue
      bookValue: $bookValue
      docFee: $docFee
      payoff: $payoff
      estimatedPayoff: $estimatedPayoff
      ssn: $ssn
      warranty: $warranty
      financialInfoId: $financialInfoId
      source: $source
    ) {
      state
      totalFeeAmount
      salesTaxRate
      baseTaxAmount
      warrantyTaxAmount
      taxProcessor
      feeProcessor
      docFeeTaxAmount
      registrationTransferFee
    }
  }
`;

/**
 * __useGetFeesQuery__
 *
 * To run a query within a React component, call `useGetFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeesQuery({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *      state: // value for 'state'
 *      previouslyTitledState: // value for 'previouslyTitledState'
 *      city: // value for 'city'
 *      county: // value for 'county'
 *      zip: // value for 'zip'
 *      vin: // value for 'vin'
 *      make: // value for 'make'
 *      model: // value for 'model'
 *      year: // value for 'year'
 *      vehicleType: // value for 'vehicleType'
 *      fuelType: // value for 'fuelType'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      retailBookValue: // value for 'retailBookValue'
 *      bookValue: // value for 'bookValue'
 *      docFee: // value for 'docFee'
 *      payoff: // value for 'payoff'
 *      estimatedPayoff: // value for 'estimatedPayoff'
 *      ssn: // value for 'ssn'
 *      warranty: // value for 'warranty'
 *      financialInfoId: // value for 'financialInfoId'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useGetFeesQuery(
  baseOptions: Apollo.QueryHookOptions<GetFeesQuery, GetFeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeesQuery, GetFeesQueryVariables>(GetFeesDocument, options);
}
export function useGetFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFeesQuery, GetFeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeesQuery, GetFeesQueryVariables>(GetFeesDocument, options);
}
export type GetFeesQueryHookResult = ReturnType<typeof useGetFeesQuery>;
export type GetFeesLazyQueryHookResult = ReturnType<typeof useGetFeesLazyQuery>;
export type GetFeesQueryResult = Apollo.QueryResult<GetFeesQuery, GetFeesQueryVariables>;
export const UploadDocumentDocument = gql`
  mutation uploadDocument($bytes: String!, $documentType: TTDocumentName!) {
    uploadDocument(bytes: $bytes, documentType: $documentType) {
      documentName
      key
      fields {
        confidence
        name
        value
      }
    }
  }
`;
export type UploadDocumentMutationFn = Apollo.MutationFunction<
  UploadDocumentMutation,
  UploadDocumentMutationVariables
>;

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentMutation, { data, loading, error }] = useUploadDocumentMutation({
 *   variables: {
 *      bytes: // value for 'bytes'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useUploadDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadDocumentMutation, UploadDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadDocumentMutation, UploadDocumentMutationVariables>(
    UploadDocumentDocument,
    options,
  );
}
export type UploadDocumentMutationHookResult = ReturnType<typeof useUploadDocumentMutation>;
export type UploadDocumentMutationResult = Apollo.MutationResult<UploadDocumentMutation>;
export type UploadDocumentMutationOptions = Apollo.BaseMutationOptions<
  UploadDocumentMutation,
  UploadDocumentMutationVariables
>;
export const UploadDriversLicenseDocument = gql`
  mutation uploadDriversLicense($bytes: String!) {
    uploadDriversLicense(bytes: $bytes) {
      firstName
      middleName
      lastName
      address
      city
      state
      zip
      expirationDate
    }
  }
`;
export type UploadDriversLicenseMutationFn = Apollo.MutationFunction<
  UploadDriversLicenseMutation,
  UploadDriversLicenseMutationVariables
>;

/**
 * __useUploadDriversLicenseMutation__
 *
 * To run a mutation, you first call `useUploadDriversLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDriversLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDriversLicenseMutation, { data, loading, error }] = useUploadDriversLicenseMutation({
 *   variables: {
 *      bytes: // value for 'bytes'
 *   },
 * });
 */
export function useUploadDriversLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadDriversLicenseMutation,
    UploadDriversLicenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadDriversLicenseMutation, UploadDriversLicenseMutationVariables>(
    UploadDriversLicenseDocument,
    options,
  );
}
export type UploadDriversLicenseMutationHookResult = ReturnType<
  typeof useUploadDriversLicenseMutation
>;
export type UploadDriversLicenseMutationResult =
  Apollo.MutationResult<UploadDriversLicenseMutation>;
export type UploadDriversLicenseMutationOptions = Apollo.BaseMutationOptions<
  UploadDriversLicenseMutation,
  UploadDriversLicenseMutationVariables
>;
export const CreateTransactionDocument = gql`
  mutation createTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input)
  }
`;
export type CreateTransactionMutationFn = Apollo.MutationFunction<
  CreateTransactionMutation,
  CreateTransactionMutationVariables
>;

/**
 * __useCreateTransactionMutation__
 *
 * To run a mutation, you first call `useCreateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransactionMutation, { data, loading, error }] = useCreateTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTransactionMutation,
    CreateTransactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTransactionMutation, CreateTransactionMutationVariables>(
    CreateTransactionDocument,
    options,
  );
}
export type CreateTransactionMutationHookResult = ReturnType<typeof useCreateTransactionMutation>;
export type CreateTransactionMutationResult = Apollo.MutationResult<CreateTransactionMutation>;
export type CreateTransactionMutationOptions = Apollo.BaseMutationOptions<
  CreateTransactionMutation,
  CreateTransactionMutationVariables
>;
export const SearchWebLeadsDocument = gql`
  query searchWebLeads($query: String!) {
    searchWebLeads(query: $query) {
      id
      deal_id
      data {
        first_name
        last_name
        phone_number
        email
        vin
      }
    }
  }
`;

/**
 * __useSearchWebLeadsQuery__
 *
 * To run a query within a React component, call `useSearchWebLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWebLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWebLeadsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchWebLeadsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchWebLeadsQuery, SearchWebLeadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchWebLeadsQuery, SearchWebLeadsQueryVariables>(
    SearchWebLeadsDocument,
    options,
  );
}
export function useSearchWebLeadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchWebLeadsQuery, SearchWebLeadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchWebLeadsQuery, SearchWebLeadsQueryVariables>(
    SearchWebLeadsDocument,
    options,
  );
}
export type SearchWebLeadsQueryHookResult = ReturnType<typeof useSearchWebLeadsQuery>;
export type SearchWebLeadsLazyQueryHookResult = ReturnType<typeof useSearchWebLeadsLazyQuery>;
export type SearchWebLeadsQueryResult = Apollo.QueryResult<
  SearchWebLeadsQuery,
  SearchWebLeadsQueryVariables
>;
export const TemporaryInfoAuthenticatedDocument = gql`
  query temporaryInfoAuthenticated($id: ID!) {
    temporaryInfoAuthenticated(id: $id) {
      ...TemporaryInfoFragment
      consents {
        type
      }
    }
  }
  ${TemporaryInfoFragmentFragmentDoc}
`;

/**
 * __useTemporaryInfoAuthenticatedQuery__
 *
 * To run a query within a React component, call `useTemporaryInfoAuthenticatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoAuthenticatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryInfoAuthenticatedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemporaryInfoAuthenticatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    TemporaryInfoAuthenticatedQuery,
    TemporaryInfoAuthenticatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TemporaryInfoAuthenticatedQuery, TemporaryInfoAuthenticatedQueryVariables>(
    TemporaryInfoAuthenticatedDocument,
    options,
  );
}
export function useTemporaryInfoAuthenticatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemporaryInfoAuthenticatedQuery,
    TemporaryInfoAuthenticatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TemporaryInfoAuthenticatedQuery,
    TemporaryInfoAuthenticatedQueryVariables
  >(TemporaryInfoAuthenticatedDocument, options);
}
export type TemporaryInfoAuthenticatedQueryHookResult = ReturnType<
  typeof useTemporaryInfoAuthenticatedQuery
>;
export type TemporaryInfoAuthenticatedLazyQueryHookResult = ReturnType<
  typeof useTemporaryInfoAuthenticatedLazyQuery
>;
export type TemporaryInfoAuthenticatedQueryResult = Apollo.QueryResult<
  TemporaryInfoAuthenticatedQuery,
  TemporaryInfoAuthenticatedQueryVariables
>;
export const TemporaryInfoDocument = gql`
  query temporaryInfo($id: ID!) {
    temporaryInfo(id: $id) {
      ...TemporaryInfoFragment
    }
  }
  ${TemporaryInfoFragmentFragmentDoc}
`;

/**
 * __useTemporaryInfoQuery__
 *
 * To run a query within a React component, call `useTemporaryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemporaryInfoQuery(
  baseOptions: Apollo.QueryHookOptions<TemporaryInfoQuery, TemporaryInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TemporaryInfoQuery, TemporaryInfoQueryVariables>(
    TemporaryInfoDocument,
    options,
  );
}
export function useTemporaryInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TemporaryInfoQuery, TemporaryInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TemporaryInfoQuery, TemporaryInfoQueryVariables>(
    TemporaryInfoDocument,
    options,
  );
}
export type TemporaryInfoQueryHookResult = ReturnType<typeof useTemporaryInfoQuery>;
export type TemporaryInfoLazyQueryHookResult = ReturnType<typeof useTemporaryInfoLazyQuery>;
export type TemporaryInfoQueryResult = Apollo.QueryResult<
  TemporaryInfoQuery,
  TemporaryInfoQueryVariables
>;
export const TemporaryInfoImportDocument = gql`
  mutation temporaryInfoImport($id: ID!, $transformType: transformType!, $importType: importType!) {
    temporaryInfoImport(id: $id, transformType: $transformType, importType: $importType) {
      id
    }
  }
`;
export type TemporaryInfoImportMutationFn = Apollo.MutationFunction<
  TemporaryInfoImportMutation,
  TemporaryInfoImportMutationVariables
>;

/**
 * __useTemporaryInfoImportMutation__
 *
 * To run a mutation, you first call `useTemporaryInfoImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [temporaryInfoImportMutation, { data, loading, error }] = useTemporaryInfoImportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      transformType: // value for 'transformType'
 *      importType: // value for 'importType'
 *   },
 * });
 */
export function useTemporaryInfoImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TemporaryInfoImportMutation,
    TemporaryInfoImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TemporaryInfoImportMutation, TemporaryInfoImportMutationVariables>(
    TemporaryInfoImportDocument,
    options,
  );
}
export type TemporaryInfoImportMutationHookResult = ReturnType<
  typeof useTemporaryInfoImportMutation
>;
export type TemporaryInfoImportMutationResult = Apollo.MutationResult<TemporaryInfoImportMutation>;
export type TemporaryInfoImportMutationOptions = Apollo.BaseMutationOptions<
  TemporaryInfoImportMutation,
  TemporaryInfoImportMutationVariables
>;
export const OnDealImportedDocument = gql`
  subscription onDealImported {
    onDealImported {
      deal_id
      first_name
      last_name
    }
  }
`;

/**
 * __useOnDealImportedSubscription__
 *
 * To run a query within a React component, call `useOnDealImportedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDealImportedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDealImportedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDealImportedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnDealImportedSubscription,
    OnDealImportedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnDealImportedSubscription, OnDealImportedSubscriptionVariables>(
    OnDealImportedDocument,
    options,
  );
}
export type OnDealImportedSubscriptionHookResult = ReturnType<typeof useOnDealImportedSubscription>;
export type OnDealImportedSubscriptionResult =
  Apollo.SubscriptionResult<OnDealImportedSubscription>;
export const TemporaryRegistrationTagsDealsDocument = gql`
  query temporaryRegistrationTagsDeals(
    $currentPage: Int
    $itemsPerPage: Int
    $sortColumn: String
    $sortDirection: SortDirection
  ) {
    temporaryRegistrationTagsDeals(
      currentPage: $currentPage
      itemsPerPage: $itemsPerPage
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      deals {
        ...TemporaryRegistrationTagsDeal
      }
      totalRecords
    }
  }
  ${TemporaryRegistrationTagsDealFragmentDoc}
`;

/**
 * __useTemporaryRegistrationTagsDealsQuery__
 *
 * To run a query within a React component, call `useTemporaryRegistrationTagsDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryRegistrationTagsDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryRegistrationTagsDealsQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      sortColumn: // value for 'sortColumn'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useTemporaryRegistrationTagsDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TemporaryRegistrationTagsDealsQuery,
    TemporaryRegistrationTagsDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TemporaryRegistrationTagsDealsQuery,
    TemporaryRegistrationTagsDealsQueryVariables
  >(TemporaryRegistrationTagsDealsDocument, options);
}
export function useTemporaryRegistrationTagsDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemporaryRegistrationTagsDealsQuery,
    TemporaryRegistrationTagsDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TemporaryRegistrationTagsDealsQuery,
    TemporaryRegistrationTagsDealsQueryVariables
  >(TemporaryRegistrationTagsDealsDocument, options);
}
export type TemporaryRegistrationTagsDealsQueryHookResult = ReturnType<
  typeof useTemporaryRegistrationTagsDealsQuery
>;
export type TemporaryRegistrationTagsDealsLazyQueryHookResult = ReturnType<
  typeof useTemporaryRegistrationTagsDealsLazyQuery
>;
export type TemporaryRegistrationTagsDealsQueryResult = Apollo.QueryResult<
  TemporaryRegistrationTagsDealsQuery,
  TemporaryRegistrationTagsDealsQueryVariables
>;
export const OnTemporaryRegistrationTagsDealsUpdatedDocument = gql`
  subscription onTemporaryRegistrationTagsDealsUpdated {
    onTemporaryRegistrationTagsDealsUpdated {
      ...TemporaryRegistrationTagsDeal
    }
  }
  ${TemporaryRegistrationTagsDealFragmentDoc}
`;

/**
 * __useOnTemporaryRegistrationTagsDealsUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnTemporaryRegistrationTagsDealsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTemporaryRegistrationTagsDealsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTemporaryRegistrationTagsDealsUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnTemporaryRegistrationTagsDealsUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnTemporaryRegistrationTagsDealsUpdatedSubscription,
    OnTemporaryRegistrationTagsDealsUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnTemporaryRegistrationTagsDealsUpdatedSubscription,
    OnTemporaryRegistrationTagsDealsUpdatedSubscriptionVariables
  >(OnTemporaryRegistrationTagsDealsUpdatedDocument, options);
}
export type OnTemporaryRegistrationTagsDealsUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnTemporaryRegistrationTagsDealsUpdatedSubscription
>;
export type OnTemporaryRegistrationTagsDealsUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OnTemporaryRegistrationTagsDealsUpdatedSubscription>;
export const GetTwilioNumbersDocument = gql`
  query getTwilioNumbers {
    getTwilioNumbers {
      number
      friendlyName
    }
  }
`;

/**
 * __useGetTwilioNumbersQuery__
 *
 * To run a query within a React component, call `useGetTwilioNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTwilioNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTwilioNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTwilioNumbersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTwilioNumbersQuery, GetTwilioNumbersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTwilioNumbersQuery, GetTwilioNumbersQueryVariables>(
    GetTwilioNumbersDocument,
    options,
  );
}
export function useGetTwilioNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTwilioNumbersQuery, GetTwilioNumbersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTwilioNumbersQuery, GetTwilioNumbersQueryVariables>(
    GetTwilioNumbersDocument,
    options,
  );
}
export type GetTwilioNumbersQueryHookResult = ReturnType<typeof useGetTwilioNumbersQuery>;
export type GetTwilioNumbersLazyQueryHookResult = ReturnType<typeof useGetTwilioNumbersLazyQuery>;
export type GetTwilioNumbersQueryResult = Apollo.QueryResult<
  GetTwilioNumbersQuery,
  GetTwilioNumbersQueryVariables
>;
export const GetUsersDocument = gql`
  query getUsers(
    $currentPage: Int
    $itemsPerPage: Int
    $podId: ID
    $roleNames: [Auth0RoleName]
    $sortColumn: String
    $sortDirection: SortDirection
    $teamType: teamType
    $userIds: [String]
    $searchText: String
  ) {
    getUsers(
      currentPage: $currentPage
      itemsPerPage: $itemsPerPage
      podId: $podId
      roleNames: $roleNames
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      teamType: $teamType
      userIds: $userIds
      searchText: $searchText
    ) {
      results {
        ...UserFragment
      }
      totalRecords
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      podId: // value for 'podId'
 *      roleNames: // value for 'roleNames'
 *      sortColumn: // value for 'sortColumn'
 *      sortDirection: // value for 'sortDirection'
 *      teamType: // value for 'teamType'
 *      userIds: // value for 'userIds'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUsersForManagementDocument = gql`
  query getUsersForManagement(
    $currentPage: Int
    $itemsPerPage: Int
    $podId: ID
    $roleNames: [Auth0RoleName]
    $sortColumn: String
    $sortDirection: SortDirection
    $teamType: teamType
    $userIds: [String]
    $searchText: String
  ) {
    getUsers(
      currentPage: $currentPage
      itemsPerPage: $itemsPerPage
      podId: $podId
      roleNames: $roleNames
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      teamType: $teamType
      userIds: $userIds
      searchText: $searchText
    ) {
      results {
        ...UserFragment
        huntGroups {
          id
          name
        }
      }
      totalRecords
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetUsersForManagementQuery__
 *
 * To run a query within a React component, call `useGetUsersForManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForManagementQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      podId: // value for 'podId'
 *      roleNames: // value for 'roleNames'
 *      sortColumn: // value for 'sortColumn'
 *      sortDirection: // value for 'sortDirection'
 *      teamType: // value for 'teamType'
 *      userIds: // value for 'userIds'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetUsersForManagementQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersForManagementQuery,
    GetUsersForManagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersForManagementQuery, GetUsersForManagementQueryVariables>(
    GetUsersForManagementDocument,
    options,
  );
}
export function useGetUsersForManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForManagementQuery,
    GetUsersForManagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersForManagementQuery, GetUsersForManagementQueryVariables>(
    GetUsersForManagementDocument,
    options,
  );
}
export type GetUsersForManagementQueryHookResult = ReturnType<typeof useGetUsersForManagementQuery>;
export type GetUsersForManagementLazyQueryHookResult = ReturnType<
  typeof useGetUsersForManagementLazyQuery
>;
export type GetUsersForManagementQueryResult = Apollo.QueryResult<
  GetUsersForManagementQuery,
  GetUsersForManagementQueryVariables
>;
export const TitlingUsersDocument = gql`
  query titlingUsers($titling_pod_id: ID) {
    titlingUsers(titling_pod_id: $titling_pod_id) {
      user_id
      pod_id
      team_role
      user {
        id
        email
        name
        nickname
        deleted_at
      }
    }
  }
`;

/**
 * __useTitlingUsersQuery__
 *
 * To run a query within a React component, call `useTitlingUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTitlingUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTitlingUsersQuery({
 *   variables: {
 *      titling_pod_id: // value for 'titling_pod_id'
 *   },
 * });
 */
export function useTitlingUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<TitlingUsersQuery, TitlingUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TitlingUsersQuery, TitlingUsersQueryVariables>(
    TitlingUsersDocument,
    options,
  );
}
export function useTitlingUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TitlingUsersQuery, TitlingUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TitlingUsersQuery, TitlingUsersQueryVariables>(
    TitlingUsersDocument,
    options,
  );
}
export type TitlingUsersQueryHookResult = ReturnType<typeof useTitlingUsersQuery>;
export type TitlingUsersLazyQueryHookResult = ReturnType<typeof useTitlingUsersLazyQuery>;
export type TitlingUsersQueryResult = Apollo.QueryResult<
  TitlingUsersQuery,
  TitlingUsersQueryVariables
>;
export const GetAvailableUsersAndAllHuntGroupsDocument = gql`
  query getAvailableUsersAndAllHuntGroups {
    getAvailableUsers {
      id
      email
      name
      nickname
      phone_number
      twilio_number
      call_status
      in_call
    }
    getAllHuntGroups {
      id
      name
      phone_number
      slug
    }
  }
`;

/**
 * __useGetAvailableUsersAndAllHuntGroupsQuery__
 *
 * To run a query within a React component, call `useGetAvailableUsersAndAllHuntGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableUsersAndAllHuntGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableUsersAndAllHuntGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableUsersAndAllHuntGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAvailableUsersAndAllHuntGroupsQuery,
    GetAvailableUsersAndAllHuntGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableUsersAndAllHuntGroupsQuery,
    GetAvailableUsersAndAllHuntGroupsQueryVariables
  >(GetAvailableUsersAndAllHuntGroupsDocument, options);
}
export function useGetAvailableUsersAndAllHuntGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableUsersAndAllHuntGroupsQuery,
    GetAvailableUsersAndAllHuntGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableUsersAndAllHuntGroupsQuery,
    GetAvailableUsersAndAllHuntGroupsQueryVariables
  >(GetAvailableUsersAndAllHuntGroupsDocument, options);
}
export type GetAvailableUsersAndAllHuntGroupsQueryHookResult = ReturnType<
  typeof useGetAvailableUsersAndAllHuntGroupsQuery
>;
export type GetAvailableUsersAndAllHuntGroupsLazyQueryHookResult = ReturnType<
  typeof useGetAvailableUsersAndAllHuntGroupsLazyQuery
>;
export type GetAvailableUsersAndAllHuntGroupsQueryResult = Apollo.QueryResult<
  GetAvailableUsersAndAllHuntGroupsQuery,
  GetAvailableUsersAndAllHuntGroupsQueryVariables
>;
export const LoggedUserDocument = gql`
  query loggedUser {
    loggedUser {
      name
      call_status
      can_claim_as_closer
      hours
      on_vacation
      pods {
        team_type
      }
    }
  }
`;

/**
 * __useLoggedUserQuery__
 *
 * To run a query within a React component, call `useLoggedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedUserQuery(
  baseOptions?: Apollo.QueryHookOptions<LoggedUserQuery, LoggedUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoggedUserQuery, LoggedUserQueryVariables>(LoggedUserDocument, options);
}
export function useLoggedUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoggedUserQuery, LoggedUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoggedUserQuery, LoggedUserQueryVariables>(
    LoggedUserDocument,
    options,
  );
}
export type LoggedUserQueryHookResult = ReturnType<typeof useLoggedUserQuery>;
export type LoggedUserLazyQueryHookResult = ReturnType<typeof useLoggedUserLazyQuery>;
export type LoggedUserQueryResult = Apollo.QueryResult<LoggedUserQuery, LoggedUserQueryVariables>;
export const UserTitlingPodsDocument = gql`
  query userTitlingPods {
    userTitlingPods {
      id
      name
      color
      us_states_object {
        states
      }
      problem_solver
      archived
      pod_users {
        id
        user_id
        pod_id
        team_role
        user {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useUserTitlingPodsQuery__
 *
 * To run a query within a React component, call `useUserTitlingPodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTitlingPodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTitlingPodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTitlingPodsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserTitlingPodsQuery, UserTitlingPodsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserTitlingPodsQuery, UserTitlingPodsQueryVariables>(
    UserTitlingPodsDocument,
    options,
  );
}
export function useUserTitlingPodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserTitlingPodsQuery, UserTitlingPodsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserTitlingPodsQuery, UserTitlingPodsQueryVariables>(
    UserTitlingPodsDocument,
    options,
  );
}
export type UserTitlingPodsQueryHookResult = ReturnType<typeof useUserTitlingPodsQuery>;
export type UserTitlingPodsLazyQueryHookResult = ReturnType<typeof useUserTitlingPodsLazyQuery>;
export type UserTitlingPodsQueryResult = Apollo.QueryResult<
  UserTitlingPodsQuery,
  UserTitlingPodsQueryVariables
>;
export const UserPermissionsDocument = gql`
  query userPermissions($user_id: String!) {
    userPermissions(user_id: $user_id)
  }
`;

/**
 * __useUserPermissionsQuery__
 *
 * To run a query within a React component, call `useUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUserPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
    UserPermissionsDocument,
    options,
  );
}
export function useUserPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
    UserPermissionsDocument,
    options,
  );
}
export type UserPermissionsQueryHookResult = ReturnType<typeof useUserPermissionsQuery>;
export type UserPermissionsLazyQueryHookResult = ReturnType<typeof useUserPermissionsLazyQuery>;
export type UserPermissionsQueryResult = Apollo.QueryResult<
  UserPermissionsQuery,
  UserPermissionsQueryVariables
>;
export const GetUserTwilioNumberDocument = gql`
  query getUserTwilioNumber {
    getUserTwilioNumber
  }
`;

/**
 * __useGetUserTwilioNumberQuery__
 *
 * To run a query within a React component, call `useGetUserTwilioNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTwilioNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTwilioNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTwilioNumberQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserTwilioNumberQuery,
    GetUserTwilioNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserTwilioNumberQuery, GetUserTwilioNumberQueryVariables>(
    GetUserTwilioNumberDocument,
    options,
  );
}
export function useGetUserTwilioNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserTwilioNumberQuery,
    GetUserTwilioNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserTwilioNumberQuery, GetUserTwilioNumberQueryVariables>(
    GetUserTwilioNumberDocument,
    options,
  );
}
export type GetUserTwilioNumberQueryHookResult = ReturnType<typeof useGetUserTwilioNumberQuery>;
export type GetUserTwilioNumberLazyQueryHookResult = ReturnType<
  typeof useGetUserTwilioNumberLazyQuery
>;
export type GetUserTwilioNumberQueryResult = Apollo.QueryResult<
  GetUserTwilioNumberQuery,
  GetUserTwilioNumberQueryVariables
>;
export const GetUserCreationDataDocument = gql`
  query getUserCreationData {
    getUserCreationData {
      huntGroups {
        id
        name
        slug
      }
      pods {
        id
        name
        color
      }
    }
  }
`;

/**
 * __useGetUserCreationDataQuery__
 *
 * To run a query within a React component, call `useGetUserCreationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCreationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCreationDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserCreationDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserCreationDataQuery,
    GetUserCreationDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserCreationDataQuery, GetUserCreationDataQueryVariables>(
    GetUserCreationDataDocument,
    options,
  );
}
export function useGetUserCreationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCreationDataQuery,
    GetUserCreationDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserCreationDataQuery, GetUserCreationDataQueryVariables>(
    GetUserCreationDataDocument,
    options,
  );
}
export type GetUserCreationDataQueryHookResult = ReturnType<typeof useGetUserCreationDataQuery>;
export type GetUserCreationDataLazyQueryHookResult = ReturnType<
  typeof useGetUserCreationDataLazyQuery
>;
export type GetUserCreationDataQueryResult = Apollo.QueryResult<
  GetUserCreationDataQuery,
  GetUserCreationDataQueryVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      createUserInput: // value for 'createUserInput'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($updateUserInput: UpdateUserInput!, $allAuth0RoleIds: [String!]!) {
    updateUser(updateUserInput: $updateUserInput, allAuth0RoleIds: $allAuth0RoleIds) {
      id
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *      allAuth0RoleIds: // value for 'allAuth0RoleIds'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateUserSettingsDocument = gql`
  mutation updateUserSettings(
    $id: String!
    $hours: String!
    $on_vacation: Boolean!
    $auto_assign_deals: Boolean!
    $overnight_deals: Boolean!
    $max_auto_assign_deals: Int!
  ) {
    updateUserSettings(
      id: $id
      hours: $hours
      on_vacation: $on_vacation
      auto_assign_deals: $auto_assign_deals
      overnight_deals: $overnight_deals
      max_auto_assign_deals: $max_auto_assign_deals
    ) {
      id
      hours
      on_vacation
      auto_assign_deals
      overnight_deals
      max_auto_assign_deals
    }
  }
`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      hours: // value for 'hours'
 *      on_vacation: // value for 'on_vacation'
 *      auto_assign_deals: // value for 'auto_assign_deals'
 *      overnight_deals: // value for 'overnight_deals'
 *      max_auto_assign_deals: // value for 'max_auto_assign_deals'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
    UpdateUserSettingsDocument,
    options,
  );
}
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const SoftDeleteUserDocument = gql`
  mutation softDeleteUser($id: String!) {
    softDeleteUser(id: $id)
  }
`;
export type SoftDeleteUserMutationFn = Apollo.MutationFunction<
  SoftDeleteUserMutation,
  SoftDeleteUserMutationVariables
>;

/**
 * __useSoftDeleteUserMutation__
 *
 * To run a mutation, you first call `useSoftDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteUserMutation, { data, loading, error }] = useSoftDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<SoftDeleteUserMutation, SoftDeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SoftDeleteUserMutation, SoftDeleteUserMutationVariables>(
    SoftDeleteUserDocument,
    options,
  );
}
export type SoftDeleteUserMutationHookResult = ReturnType<typeof useSoftDeleteUserMutation>;
export type SoftDeleteUserMutationResult = Apollo.MutationResult<SoftDeleteUserMutation>;
export type SoftDeleteUserMutationOptions = Apollo.BaseMutationOptions<
  SoftDeleteUserMutation,
  SoftDeleteUserMutationVariables
>;
export const UpdateUserCallStatusDocument = gql`
  mutation updateUserCallStatus($status: String!) {
    updateUserCallStatus(status: $status) {
      call_status
    }
  }
`;
export type UpdateUserCallStatusMutationFn = Apollo.MutationFunction<
  UpdateUserCallStatusMutation,
  UpdateUserCallStatusMutationVariables
>;

/**
 * __useUpdateUserCallStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserCallStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCallStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCallStatusMutation, { data, loading, error }] = useUpdateUserCallStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateUserCallStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserCallStatusMutation,
    UpdateUserCallStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserCallStatusMutation, UpdateUserCallStatusMutationVariables>(
    UpdateUserCallStatusDocument,
    options,
  );
}
export type UpdateUserCallStatusMutationHookResult = ReturnType<
  typeof useUpdateUserCallStatusMutation
>;
export type UpdateUserCallStatusMutationResult =
  Apollo.MutationResult<UpdateUserCallStatusMutation>;
export type UpdateUserCallStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserCallStatusMutation,
  UpdateUserCallStatusMutationVariables
>;
export const SwitchCallStatusDocument = gql`
  subscription switchCallStatus {
    switchCallStatus
  }
`;

/**
 * __useSwitchCallStatusSubscription__
 *
 * To run a query within a React component, call `useSwitchCallStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSwitchCallStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwitchCallStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSwitchCallStatusSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SwitchCallStatusSubscription,
    SwitchCallStatusSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SwitchCallStatusSubscription,
    SwitchCallStatusSubscriptionVariables
  >(SwitchCallStatusDocument, options);
}
export type SwitchCallStatusSubscriptionHookResult = ReturnType<
  typeof useSwitchCallStatusSubscription
>;
export type SwitchCallStatusSubscriptionResult =
  Apollo.SubscriptionResult<SwitchCallStatusSubscription>;
export const SwitchCallStatusIndividualDocument = gql`
  subscription switchCallStatusIndividual {
    switchCallStatusIndividual
  }
`;

/**
 * __useSwitchCallStatusIndividualSubscription__
 *
 * To run a query within a React component, call `useSwitchCallStatusIndividualSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSwitchCallStatusIndividualSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwitchCallStatusIndividualSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSwitchCallStatusIndividualSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SwitchCallStatusIndividualSubscription,
    SwitchCallStatusIndividualSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SwitchCallStatusIndividualSubscription,
    SwitchCallStatusIndividualSubscriptionVariables
  >(SwitchCallStatusIndividualDocument, options);
}
export type SwitchCallStatusIndividualSubscriptionHookResult = ReturnType<
  typeof useSwitchCallStatusIndividualSubscription
>;
export type SwitchCallStatusIndividualSubscriptionResult =
  Apollo.SubscriptionResult<SwitchCallStatusIndividualSubscription>;
export const GetWaitingCallsDocument = gql`
  query getWaitingCalls {
    getWaitingCalls {
      data
    }
  }
`;

/**
 * __useGetWaitingCallsQuery__
 *
 * To run a query within a React component, call `useGetWaitingCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitingCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitingCallsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWaitingCallsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWaitingCallsQuery, GetWaitingCallsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWaitingCallsQuery, GetWaitingCallsQueryVariables>(
    GetWaitingCallsDocument,
    options,
  );
}
export function useGetWaitingCallsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWaitingCallsQuery, GetWaitingCallsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWaitingCallsQuery, GetWaitingCallsQueryVariables>(
    GetWaitingCallsDocument,
    options,
  );
}
export type GetWaitingCallsQueryHookResult = ReturnType<typeof useGetWaitingCallsQuery>;
export type GetWaitingCallsLazyQueryHookResult = ReturnType<typeof useGetWaitingCallsLazyQuery>;
export type GetWaitingCallsQueryResult = Apollo.QueryResult<
  GetWaitingCallsQuery,
  GetWaitingCallsQueryVariables
>;
export const CallInProgressDocument = gql`
  query callInProgress {
    callInProgress {
      from
      to
      customer_name
      deal_id
      deal_state
      deal_type
      is_thunder
      conference_id
      agent_user_id
      hunt_group_slug
      is_outbound
    }
  }
`;

/**
 * __useCallInProgressQuery__
 *
 * To run a query within a React component, call `useCallInProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallInProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallInProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useCallInProgressQuery(
  baseOptions?: Apollo.QueryHookOptions<CallInProgressQuery, CallInProgressQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallInProgressQuery, CallInProgressQueryVariables>(
    CallInProgressDocument,
    options,
  );
}
export function useCallInProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CallInProgressQuery, CallInProgressQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallInProgressQuery, CallInProgressQueryVariables>(
    CallInProgressDocument,
    options,
  );
}
export type CallInProgressQueryHookResult = ReturnType<typeof useCallInProgressQuery>;
export type CallInProgressLazyQueryHookResult = ReturnType<typeof useCallInProgressLazyQuery>;
export type CallInProgressQueryResult = Apollo.QueryResult<
  CallInProgressQuery,
  CallInProgressQueryVariables
>;
export const AddParticipantDocument = gql`
  mutation addParticipant(
    $hunt_group_slug: String
    $conference_id: String
    $agent_phone: String
    $new_agent_phone: String
    $new_agent_user_id: String
  ) {
    addParticipant(
      hunt_group_slug: $hunt_group_slug
      conference_id: $conference_id
      agent_phone: $agent_phone
      new_agent_phone: $new_agent_phone
      new_agent_user_id: $new_agent_user_id
    ) {
      status
      call_already_answered
    }
  }
`;
export type AddParticipantMutationFn = Apollo.MutationFunction<
  AddParticipantMutation,
  AddParticipantMutationVariables
>;

/**
 * __useAddParticipantMutation__
 *
 * To run a mutation, you first call `useAddParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParticipantMutation, { data, loading, error }] = useAddParticipantMutation({
 *   variables: {
 *      hunt_group_slug: // value for 'hunt_group_slug'
 *      conference_id: // value for 'conference_id'
 *      agent_phone: // value for 'agent_phone'
 *      new_agent_phone: // value for 'new_agent_phone'
 *      new_agent_user_id: // value for 'new_agent_user_id'
 *   },
 * });
 */
export function useAddParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<AddParticipantMutation, AddParticipantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddParticipantMutation, AddParticipantMutationVariables>(
    AddParticipantDocument,
    options,
  );
}
export type AddParticipantMutationHookResult = ReturnType<typeof useAddParticipantMutation>;
export type AddParticipantMutationResult = Apollo.MutationResult<AddParticipantMutation>;
export type AddParticipantMutationOptions = Apollo.BaseMutationOptions<
  AddParticipantMutation,
  AddParticipantMutationVariables
>;
export const OnWaitingQueueDocument = gql`
  subscription onWaitingQueue {
    onWaitingQueue {
      data {
        from
        conference_id
        phone_number
        started_at
        deal_id
        deal_state
        is_thunder
        customer_name
        hunt_group_slug
      }
      hunt_group_slug
    }
  }
`;

/**
 * __useOnWaitingQueueSubscription__
 *
 * To run a query within a React component, call `useOnWaitingQueueSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWaitingQueueSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWaitingQueueSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnWaitingQueueSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnWaitingQueueSubscription,
    OnWaitingQueueSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnWaitingQueueSubscription, OnWaitingQueueSubscriptionVariables>(
    OnWaitingQueueDocument,
    options,
  );
}
export type OnWaitingQueueSubscriptionHookResult = ReturnType<typeof useOnWaitingQueueSubscription>;
export type OnWaitingQueueSubscriptionResult =
  Apollo.SubscriptionResult<OnWaitingQueueSubscription>;
