import { useContext } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { Box, Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { RiTruckLine } from 'react-icons/ri';

import { Maybe } from '../../../gql/generated/graphql';

import Modal from '../../shared/Modal';

import { PermissionEnum } from '../../../constants/permissions';
import { useUser } from '../../../hooks/useUser';
import { Can } from '../../../libs/Can';
import { AbilityContext } from '../../../libs/contextLib';

interface ShippingLabelButtonProps {
  dealId?: Maybe<number>;
  componentType?: 'button' | 'icon';
  refetchTempTagsQueue?: () => Promise<ApolloQueryResult<unknown>>;
}

const ShippingLabelButton = ({
  dealId,
  componentType = 'button',
  refetchTempTagsQueue,
}: ShippingLabelButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useUser();
  const ability = useContext(AbilityContext);

  return (
    <>
      <Can I={PermissionEnum.ShippingLabelsView}>
        {componentType === 'button' ? (
          <Button size="sm" aria-label="Shipping Labels" onClick={onOpen}>
            SHIPPING LABELS
          </Button>
        ) : (
          <Box pos="relative">
            <IconButton
              aria-label="Shipping Labels"
              variant="iconHover"
              size="xs"
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
              icon={<RiTruckLine />}
            />
          </Box>
        )}
      </Can>

      <Modal
        title="Shipping Labels"
        variant="fullContent"
        isOpen={isOpen}
        onClose={() => {
          // We need to trigger the refetch to get the new shipping label tracking number, inserted from Retool.
          refetchTempTagsQueue?.();
          onClose();
        }}
        closeOnOverlayClick
        size="6xl"
      >
        <iframe
          id="shippingLabelIframe"
          title="Shipping label Retool Inline Frame"
          style={{
            borderRadius: 10,
            height: 'calc(100vh - 200px)',
            width: '100%',
          }}
          src={`https://leaseendtest.retool.com/p/shipping-labels?user_id=${
            user.id
          }&deal_id=${dealId}&can_create_label=${ability.has(PermissionEnum.ShippingLabelsCreate)}`}
        />
      </Modal>
    </>
  );
};

export default ShippingLabelButton;
