import { Dispatch } from 'react';

import { Customer, CustomerContactTypeEnum } from '../gql/customerGql';
import { Deal } from '../gql/dealGql';
import {
  Bank,
  Customer as GenCustomer,
  Deal as GenDeal,
  Message as GenMessage,
} from '../gql/generated/graphql';
import { Message } from '../gql/messageGql';

import { ActiveConversation } from '../components/TextWidget/Components/Message';
import { formatPhoneWithParentheses } from '../libs/utils';
import { User } from '../types/user';

import { capitalizeFirstLetter } from './text';

export const getActiveConversation = (
  deal: Deal | GenDeal,
  message: Message | GenMessage,
  setActiveConversation: Dispatch<ActiveConversation>,
) => {
  let { from } = message;
  if (from && from.charAt(0) === '+') {
    from = formatPhoneWithParentheses(from);
  }
  switch (from) {
    case deal.customer?.phone_number:
      setActiveConversation({
        role: CustomerContactTypeEnum.Customer,
        phone_number: deal.customer?.phone_number ?? '',
        first_name: deal.customer?.first_name ?? '',
        last_name: deal.customer?.last_name ?? '',
      });
      break;
    case deal.cobuyer?.phone_number:
      setActiveConversation({
        role: CustomerContactTypeEnum.Cobuyer,
        phone_number: deal.cobuyer?.phone_number ?? '',
        first_name: deal.cobuyer?.first_name ?? '',
        last_name: deal.cobuyer?.last_name ?? '',
      });
      break;
    case deal.contact?.phone_number:
      setActiveConversation({
        role: CustomerContactTypeEnum.Contact,
        phone_number: deal.contact?.phone_number ?? '',
        first_name: deal.contact?.first_name ?? '',
        last_name: deal.contact?.last_name ?? '',
      });
      break;
    case deal.second_contact?.phone_number:
      setActiveConversation({
        role: CustomerContactTypeEnum.Second,
        phone_number: deal.second_contact?.phone_number ?? '',
        first_name: deal.second_contact?.first_name ?? '',
        last_name: deal.second_contact?.last_name ?? '',
      });
      break;
    default:
      setActiveConversation({
        role: '',
        phone_number: '',
        first_name: '',
        last_name: '',
      });
      break;
  }
};

export const fillText = (
  text: string,
  {
    user,
    customer,
    bank,
    deal,
  }: {
    user: User;
    customer: Customer | GenCustomer;
    bank?: Bank | null;
    deal?: Deal | GenDeal | null;
  },
) =>
  text
    .replace('{USER}', user.name.split(' ')[0])
    .replace('{CLIENT}', capitalizeFirstLetter(customer.first_name ?? ''))
    .replace('{CUSTOMER_EMAIL}', customer.email ? customer.email : '')
    .replace('{BANK}', bank?.name || 'BANK')
    .replace(
      '{BANK_ADDRESS}',
      bank
        ? `${bank.phone}, ${bank.address}, ${bank.city}, ${bank.state}, ${bank.zip}`
        : 'BANK INFO',
    )
    .replace(
      '{LIENHOLDER}',
      deal?.car?.payoff?.lienholder_name ? deal.car.payoff.lienholder_name : '',
    )
    .replace('{REP_PHONE}', user.phone_number ? user.phone_number : '')
    .replace(
      '{DOWN_PAYMENT}',
      deal?.financial_info?.money_down ? deal.financial_info.money_down.toString() : '0',
    )
    .replace('{YEAR}', deal?.car?.year ?? '')
    .replace('{MAKE}', deal?.car?.make ?? '')
    .replace('{MODEL}', deal?.car?.model ?? '')
    .replace('[TRACKING NUMBER]', deal?.shipping_labels?.[0]?.tracking_number ?? '');
