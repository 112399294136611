// Keep in sync with API in types/lienholder.ts
export enum LienholderNameEnum {
  ACURA = 'Acura Financial Services',
  ALLY = 'Ally Financial',
  AUDI = 'Audi Financial Services',
  BENTLEY_FINANCIAL_SERVICES = 'Bentley Financial Services',
  BETHPAGE_FCU = 'Bethpage Federal Credit Union',
  BMW_FINANCIAL_SERVICES = 'BMW Financial Services',
  CAL_AUTOMOTIVE = 'CAL Automotive',
  CHASE = 'Chase Auto Finance',
  CHRYSLER_CAPITAL = 'Chrysler Capital (CCAP)',
  FORD_CREDIT = 'Ford Motor Credit',
  GENESIS = 'Genesis Finance',
  GM_FINANCIAL = 'GM Financial',
  HONDA_FINANCIAL_SERVICES = 'Honda Financial Services',
  HUDSON_VALLEY_CREDIT_UNION = 'Hudson Valley Credit Union',
  HYUNDAI_MOTOR_FINANCE = 'Hyundai Motor Finance',
  KIA_MOTOR_FINANCE = 'Kia Finance',
  LAND_ROVER_FINANCIAL_GROUP = 'Land Rover Financial Group',
  LEXUS = 'Lexus Financial Services',
  MAZDA_FINANCIAL_SERVICES = 'Mazda Financial Services',
  MB_FINANCIAL_SERVICES = 'Mercedes-Benz Financial Services',
  MINI_FINANCIAL_SERVICES = 'Mini Financial Services',
  NISSAN_FINANCE = 'Nissan Finance',
  PORSCHE_FINANCIAL_SERVICES = 'Porsche Payment Center',
  SOUTHEAST_TOYOTA_FINANCIAL = 'Southeast Toyota Financial',
  SUBARU_MOTOR_FINANCE = 'Subaru Motor Finance',
  TEACHERS = 'Teachers Federal Credit Union',
  TESLA_FINANCE = 'Tesla Finance',
  TOYOTA_FINANCIAL = 'Toyota Financial',
  US_BANK = 'U.S. Bank',
  VOLVO_CAR_FINANCIAL_SERVICES = 'Volvo Car Financial Services',
  VW_CREDIT = 'Volkswagen Credit',
  OTHER = 'Other',
}

export const OtherLienholder = 'Other';
export const OtherLienholderSlug = 'other';

export const ChryslerCapitalSlug = 'chrystler_capital_ccap';
export const HondaFinancialSlug = 'honda_financial_services';
