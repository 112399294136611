import { useMemo } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import { Deal } from '../../../gql/dealGql';

import { getDealNotificationCountByType } from '../../../constants/notifications';
import TextNotificationBadgeV2 from '../../NotificationBadge/TextNotificationBadgeV2';

type TitleTableNameProps = {
  deal: Deal;
};

const TitleTableName = ({ deal }: TitleTableNameProps) => {
  const dealNotifications = useMemo(() => getDealNotificationCountByType(deal), [deal]);
  if (!deal?.customer) {
    return null;
  }
  return (
    <Flex align="center" gap={1}>
      <TextNotificationBadgeV2 count={dealNotifications.unseenMessages} deal={deal} />
      <Text>{`${deal.customer.first_name} ${deal.customer.last_name}`}</Text>
    </Flex>
  );
};

export default TitleTableName;
