import { Dispatch, MouseEvent, ReactNode, SetStateAction, useCallback } from 'react';

import {
  Box,
  Table as ChakraTable,
  Flex,
  Icon,
  StyleProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { MdInfo } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { Deal, Maybe, SortDirection } from '../../../gql/generated/graphql';

import { HorizontalScrollBarProps } from '../CustomScrollbar';
import Tooltip from '../Tooltip';

import { GetComponentParams, TableColumns } from './types';

import { PaginationControls } from '../../../hooks/usePaginationControls';
import { handleClickOrCommandClick } from '../../../libs/eventHandlers';
import Loader from '../../Loader';
import Pagination from '../../Pagination';
import { FiltersAction } from '../../Titling/utils';

type HeaderColumnProps = {
  header: string;
  sortingKey?: string;
  sortProps?: {
    sortColumn: string;
    setSortColumn: Dispatch<SetStateAction<string>>;
    sortDirection: SortDirection;
    setSortDirection: Dispatch<SetStateAction<SortDirection>>;
  };
  styleProps?: StyleProps;
  tooltip?: string;
};
const HeaderColumn = ({
  header,
  sortingKey,
  sortProps,
  styleProps,
  tooltip,
}: HeaderColumnProps) => {
  const { sortColumn, setSortColumn, sortDirection, setSortDirection } = sortProps ?? {};
  const isSortable =
    !!sortingKey && !!sortColumn && !!setSortColumn && !!sortDirection && !!setSortDirection;

  const isSortColumn = sortColumn === sortingKey;
  const isSortAscending = sortDirection === 'asc';

  return (
    <Th
      color="white"
      cursor={isSortable ? 'pointer' : 'default'}
      {...styleProps}
      onClick={() => {
        if (isSortable) {
          // Toggle sort direction.
          if (isSortColumn) {
            setSortDirection(isSortAscending ? SortDirection.Desc : SortDirection.Asc);
          } else {
            setSortColumn(sortingKey);
            setSortDirection(SortDirection.Asc);
          }
        }
      }}
    >
      {header}
      {tooltip && (
        <Tooltip label={tooltip} bgColor="gray.700" placement="top">
          <Box ml={1}>
            <MdInfo />
          </Box>
        </Tooltip>
      )}
      {isSortColumn && isSortable ? (
        <Icon as={isSortAscending ? BiChevronDown : BiChevronUp} ml={1} />
      ) : null}
    </Th>
  );
};

// TODO: Teams and StructuringFollowUpTable should use this component. Some others probably too.
type LETableProps<T> = {
  columns: TableColumns<T>;
  rowKeyPrefix: string;
  rowKeyProp: keyof T;
  rowColorFunction?: (item: T) => string;
  items?: Maybe<T>[];
  isLoading?: boolean;
  sortProps?: {
    sortColumn: string;
    setSortColumn: Dispatch<SetStateAction<string>>;
    sortDirection: SortDirection;
    setSortDirection: Dispatch<SetStateAction<SortDirection>>;
  };
  paginationControls?: PaginationControls;
  dispatchFilters?: Dispatch<FiltersAction>;
  redirectToDeal?: boolean;
  children?: ReactNode;
  childrenContainerProps?: StyleProps;
} & Omit<GetComponentParams<T>, 'item'>;

const LETable = <T,>({
  columns,
  rowKeyPrefix,
  rowKeyProp,
  componentData,
  items,
  isLoading = false,
  sortProps,
  paginationControls,
  dispatchFilters,
  redirectToDeal = false,
  handleEdit,
  handleDelete,
  customOnClick,
  children,
  childrenContainerProps,
  rowColorFunction,
}: LETableProps<T>) => {
  const history = useHistory();

  const handleRowClick = useCallback(
    (e: MouseEvent, item: T) => {
      if (redirectToDeal) {
        const deal = item as Deal;
        handleClickOrCommandClick(e, `/deals/${deal.id}`, history);
      }
    },
    [redirectToDeal, history],
  );

  return (
    <Box>
      <Flex
        minH={16}
        w="100%"
        bgColor="oxfordBlue"
        borderRadius="10px 10px 0 0"
        alignItems="center"
        justifyContent="flex-end"
        px={8}
        {...childrenContainerProps}
      >
        {children}
      </Flex>
      <Box overflowX="auto" sx={HorizontalScrollBarProps}>
        <ChakraTable overflowX="auto">
          <Thead cursor="pointer" color="white" bgColor="queenBlue" w="100%" px={6} height="55px">
            {columns.map(({ header, sortingKey, styleProps, tooltip }) => (
              <HeaderColumn
                key={header}
                header={header}
                sortingKey={sortingKey}
                sortProps={sortProps}
                styleProps={styleProps}
                tooltip={tooltip}
              />
            ))}
          </Thead>
          <Tbody>
            {items?.map((item) => {
              if (!item) {
                return null;
              }

              return (
                <Tr
                  key={`${rowKeyPrefix}-${item[rowKeyProp]}`}
                  height="55px"
                  borderBottom="2px"
                  borderX="1px"
                  borderColor="backgroundGray"
                  _hover={{
                    filter: 'brightness(0.9)',
                    cursor: redirectToDeal ? 'pointer' : 'default',
                  }}
                  bgColor={rowColorFunction ? rowColorFunction(item) : 'white'}
                  onClick={(e) => handleRowClick(e, item)}
                  onAuxClick={(e) => handleRowClick(e, item)}
                >
                  {columns.map(({ header, getComponent, styleProps }) => (
                    <Td key={header} alignContent="center" {...styleProps}>
                      {getComponent({
                        componentData,
                        item,
                        handleEdit,
                        handleDelete,
                        customOnClick,
                      })}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
      </Box>
      {paginationControls ? (
        <Box mb="50px">
          <Pagination
            paginationControls={paginationControls}
            dispatchFilters={dispatchFilters}
            borderTop="1px solid"
            borderColor="lightGray"
          />
        </Box>
      ) : null}
      <Loader isLoading={isLoading} />
    </Box>
  );
};

export default LETable;
