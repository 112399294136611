import { useContext } from 'react';

import { Box, Flex, StackProps } from '@chakra-ui/react';

import { DealStateEnum } from '../../../gql/dealGql';

import { DealContext } from '../../../libs/DealContext';
import { countDays } from '../../../utils/dates';

type DaysCountProps = StackProps & {
  label: string;
  date?: Date | string | null;
};

const DaysCount = ({ label, date, ...rest }: DaysCountProps) => {
  const { deal } = useContext(DealContext);

  // Stop counting days if the deal is finalized.
  const count = date
    ? countDays(
        date,
        deal.state === DealStateEnum.Finalized
          ? deal.deal_dates?.dates?.[DealStateEnum.Finalized]
          : new Date(),
      )
    : null;

  return (
    <Flex direction="column" flexWrap="wrap" p={2} alignItems="center" {...rest}>
      <Box mt={1} mr={2}>
        {label}
      </Box>
      <Box fontWeight="bold" fontSize="xl" color="queenBlue">
        {count === null || count < 0 ? '-' : count}
      </Box>
    </Flex>
  );
};

export default DaysCount;
