import { Checkbox, Flex, Input, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { WorkingHours, daysOfTheWeek } from '../utils';

const WorkingHoursTable = () => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<{ workingHours: WorkingHours }>();

  return (
    <Flex flexDirection="column">
      <Text fontSize="sm" mb={2}>
        Working Hours
      </Text>
      <Flex flexDirection="column" overflowY="auto" w="full">
        <Table size="sm" mt={2}>
          <Thead>
            <Tr>
              <Th>Day</Th>
              <Th>Time</Th>
            </Tr>
          </Thead>
          <Tbody>
            {daysOfTheWeek.map(({ label, day }) => {
              const working = `working_${day}` as keyof WorkingHours;
              const start = `start_${day}` as keyof WorkingHours;
              const finish = `finish_${day}` as keyof WorkingHours;

              return (
                <Tr key={day} h="42px">
                  <Td>
                    <Checkbox
                      name={`workingHours.${working}`}
                      isChecked={values.workingHours[working] as boolean}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="md"
                    >
                      <Text fontSize="sm">{label}</Text>
                    </Checkbox>
                  </Td>
                  <Td>
                    <Flex gap={2} alignItems="center" flexWrap="wrap">
                      {values.workingHours[working] && (
                        <Input
                          type="time"
                          name={`workingHours.${start}`}
                          value={values.workingHours[start] as string}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.workingHours &&
                            errors.workingHours &&
                            touched.workingHours[start] &&
                            !!errors.workingHours[start]
                          }
                          placeholder="Start Time"
                          size="xs"
                          maxW="min-content"
                        />
                      )}

                      {values.workingHours[working] && <Text>to</Text>}

                      {values.workingHours[working] && (
                        <Input
                          type="time"
                          name={`workingHours.${finish}`}
                          value={values.workingHours[finish] as string}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.workingHours &&
                            errors.workingHours &&
                            touched.workingHours[finish] &&
                            !!errors.workingHours[finish]
                          }
                          placeholder="Finish Time"
                          size="xs"
                          maxW="min-content"
                        />
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  );
};

export default WorkingHoursTable;
