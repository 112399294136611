import { useContext, useEffect, useState } from 'react';

import { addDays } from 'date-fns';

import { DealStateEnum } from '../../../gql/dealGql';
import {
  SortDirection,
  TemporaryRegistrationTagsDealsQuery,
  useOnTemporaryRegistrationTagsDealsUpdatedSubscription,
  useTemporaryRegistrationTagsDealsQuery,
} from '../../../gql/generated/graphql';

import LETable from '../../shared/Table/LETable';
import { FiltersActionKind } from '../utils';

import { TEMP_TAGS_QUEUE_DEFAULT_SORT_COLUMN, TEMP_TAG_COLUMNS } from './constants';

import colors from '../../../chakra/foundations/colors';
import usePaginationControls from '../../../hooks/usePaginationControls';
import { useUser } from '../../../hooks/useUser';
import { FiltersContext } from '../../../libs/contextLib';

type TemporaryRegistrationTagsDeals = NonNullable<
  NonNullable<TemporaryRegistrationTagsDealsQuery['temporaryRegistrationTagsDeals']>['deals']
>;

type TempTagsQueueProps = {
  skip?: boolean;
};
const TempTagsQueue = ({ skip = false }: TempTagsQueueProps) => {
  const user = useUser();

  const {
    filters: { titling: titlingFilters },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const paginationControls = usePaginationControls({
    initialCurrentPage: titlingFilters.currentPage,
    initialItemsPerPage: titlingFilters.itemsPerPage,
  });

  const [temporaryRegistrationTagsDeals, setTemporaryRegistrationTagsDeals] =
    useState<TemporaryRegistrationTagsDeals>([]);

  const [sortColumn, setSortColumn] = useState<string>(
    titlingFilters.tempTagsQueueSortColumn ?? TEMP_TAGS_QUEUE_DEFAULT_SORT_COLUMN,
  );
  const [sortDirection, setSortDirection] = useState(
    titlingFilters.tempTagsQueueSortDirection ?? SortDirection.Asc,
  );

  useEffect(() => {
    if (sortColumn !== titlingFilters.tempTagsQueueSortColumn) {
      dispatchFilters({
        type: FiltersActionKind.SET_TEMP_TAGS_QUEUE_SORT_COLUMN,
        payload: sortColumn,
      });
    }
    if (sortDirection !== titlingFilters.tempTagsQueueSortDirection) {
      dispatchFilters({
        type: FiltersActionKind.SET_TEMP_TAGS_QUEUE_SORT_DIRECTION,
        payload: sortDirection as SortDirection,
      });
    }
  }, [sortColumn, sortDirection]);

  const { refetch } = useTemporaryRegistrationTagsDealsQuery({
    skip,
    variables: {
      currentPage: paginationControls.currentPage,
      itemsPerPage: paginationControls.itemsPerPage,
      sortColumn,
      sortDirection,
    },
    notifyOnNetworkStatusChange: true, // Needed to execute the `onCompleted` after the `refetch`.
    onCompleted: (data) => {
      setTemporaryRegistrationTagsDeals(data?.temporaryRegistrationTagsDeals?.deals ?? []);
      paginationControls.setTotalRecords(data?.temporaryRegistrationTagsDeals?.totalRecords ?? 0);
    },
  });

  useOnTemporaryRegistrationTagsDealsUpdatedSubscription({
    skip,
    onData: ({ data: { data: subscriptionData } }) => {
      if (!subscriptionData?.onTemporaryRegistrationTagsDealsUpdated) {
        return;
      }

      setTemporaryRegistrationTagsDeals((prevDeals) =>
        (subscriptionData.onTemporaryRegistrationTagsDealsUpdated ?? []).reduce(
          (acc, subDeal) => {
            if (!subDeal?.id) {
              return acc;
            }
            // Delete
            if (
              subDeal.state === DealStateEnum.Finalized ||
              !subDeal.financial_info?.needs_temporary_registration_tags
            ) {
              return acc.filter((deal) => deal?.id !== subDeal.id);
            }
            // Update
            const prevDealIndex = prevDeals.findIndex((deal) => deal?.id === subDeal.id);
            if (prevDealIndex !== -1) {
              acc[prevDealIndex] = subDeal;
              return acc;
            }
            // Add
            return [subDeal, ...acc];
          },
          [...prevDeals],
        ),
      );
    },
  });

  return (
    <>
      <LETable
        columns={TEMP_TAG_COLUMNS}
        items={temporaryRegistrationTagsDeals}
        redirectToDeal
        rowKeyPrefix="temporary-registration-tags-deal"
        rowKeyProp="id"
        sortProps={{
          sortColumn,
          setSortColumn,
          sortDirection,
          setSortDirection,
        }}
        paginationControls={paginationControls}
        dispatchFilters={dispatchFilters}
        componentData={{
          userId: user.id,
          refetch,
        }}
        rowColorFunction={(deal) => {
          if (
            deal.deal_dates?.custom_dates?.temporary_registration_tags_expiration_date &&
            addDays(new Date(), 5) >=
              new Date(deal.deal_dates.custom_dates.temporary_registration_tags_expiration_date)
          ) {
            return colors.taskRed;
          }
          if (!deal.deal_dates?.custom_dates?.temporary_registration_tags_sent_date) {
            return colors.taskGreen;
          }
          return 'white';
        }}
      />
    </>
  );
};

export default TempTagsQueue;
