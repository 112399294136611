import { useContext, useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, HStack, List, ListItem, Text } from '@chakra-ui/react';
import Big from 'big.js';
import { useFormikContext } from 'formik';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

import { acquisitionOfferQuery } from '../../gql/acquisitionsGql';
import { determineApprovalQuery } from '../../gql/conditionReportGql';
import { Deal, DealStateEnum } from '../../gql/dealGql';
import { financialInfoAcquisitionUpsert } from '../../gql/financialInfoAcquisitionGql';
import { PaperworkType } from '../../gql/generated/graphql';

import ConditionReport from '../ConditionReport';
import SendContractsButton from '../DealInfoBuyout/components/buttons/SendContractsButton';
import EditDealInfoButton from '../EditDealInfoButton';
import GenerateAndSendContracts from '../GenerateDocs/GenerateAndSendContractsButton';
import Modal from '../shared/Modal';
import NumberInput from '../shared/NumberInput';

import SaveButton from './SaveButton';
import SaveEditButton from './SaveEditButton';
import SendToClosing from './SendToClosingButton';

import { DealContext } from '../../libs/DealContext';
import { ColorsEnum } from '../../libs/colorLib';
import { formatMoney } from '../../libs/utils';

const showButtonsStates = [
  DealStateEnum.Structuring,
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
  DealStateEnum.Signed,
];

const StructuringAndClosedView = () => {
  const { deal } = useContext(DealContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showXModal, setShowXModal] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<Deal>();
  const { financial_info_acquisition, car, condition_report, customer } = values;
  const {
    payoff: { vehicle_payoff },
  } = car;
  const {
    appraised_value,
    cash_to_customer,
    auction_fees,
    transport_cost,
    is_approved,
    max_total_cost,
  } = financial_info_acquisition;
  const [isEditable, setIsEditable] = useState(false);
  const isEsign = deal.paperwork_type === PaperworkType.Esign;
  const isReadOnly = isEditable
    ? false
    : ![DealStateEnum.SoftClose, DealStateEnum.Structuring, DealStateEnum.Closing].includes(
        deal.state,
      );

  let isConditionReportCompleted = false;
  if (
    condition_report?.accidents &&
    condition_report?.tires &&
    condition_report?.smoked_in !== null &&
    condition_report?.lights_on_dash !== null &&
    condition_report?.overall_condition
  ) {
    isConditionReportCompleted = true;
  }

  const [determineApproval, { data }] = useLazyQuery(determineApprovalQuery, {
    fetchPolicy: 'network-only',
  });

  const isApproved: boolean = data?.determineApprovalQuery?.approved ?? is_approved;
  const failedCriteria: string[] = data?.determineApprovalQuery?.failedCriteria ?? [];

  const [acquisitionOffer] = useLazyQuery(acquisitionOfferQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ acquisitionOffer: { appraisedValue, offer } }) => {
      if (!appraised_value) {
        setFieldValue('financial_info_acquisition.appraised_value', appraisedValue);
      }
      setFieldValue('financial_info_acquisition.max_total_cost', offer);
    },
  });

  const [upsertFinancialInfoAcquisition] = useMutation(financialInfoAcquisitionUpsert, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const accidents = condition_report?.accidents;
    const mileage = car?.mileage;
    const mmr_value = car?.mmr;
    const book_value = car?.book_value;
    const vehicle_type = car?.vehicle_type;
    const make = car?.make;
    const lienholder = car?.payoff?.lienholder_name;

    if (accidents && mileage && mmr_value && book_value && vehicle_type) {
      acquisitionOffer({
        variables: {
          accidents,
          mileage,
          mmr_value,
          book_value,
          vehicle_type,
          make,
          lienholder,
        },
      });
    }
  }, [car, condition_report]);

  useEffect(() => {
    if (isConditionReportCompleted) {
      determineApproval({
        variables: {
          data: {
            accidents: condition_report?.accidents,
            tires: condition_report?.tires,
            exterior: condition_report?.exterior,
            interior: condition_report?.interior,
            smoked_in: condition_report?.smoked_in,
            lights_on_dash: condition_report?.lights_on_dash,
            overall_condition: condition_report?.overall_condition,
            year: car.year,
            payoff: vehicle_payoff,
            book_value: car.book_value,
            kbb_trim: car.kbb_trim_name,
            vin: car.vin,
            mileage: car.mileage,
            color: car.color,
            vehicle_type: car.vehicle_type,
            state: customer.address?.state,
            financial_info_acquisition_id: values.financial_info_acquisition.id,
          },
        },
      });
    }
  }, [condition_report, isConditionReportCompleted, car]);

  useEffect(() => {
    if (max_total_cost && vehicle_payoff) {
      let maxCashToCustomer = new Big(max_total_cost).minus(vehicle_payoff).toNumber();

      if (maxCashToCustomer < 0) {
        maxCashToCustomer = 0;
      }

      setFieldValue('financial_info_acquisition.max_cash_to_customer', maxCashToCustomer);
    }
  }, [max_total_cost, vehicle_payoff]);

  useEffect(() => {
    const totalCost =
      (vehicle_payoff ?? 0) + (cash_to_customer ?? 0) + (auction_fees ?? 0) + (transport_cost ?? 0);

    setFieldValue('financial_info_acquisition.total_cost', totalCost);
  }, [vehicle_payoff, cash_to_customer, auction_fees, transport_cost]);

  useEffect(() => {
    upsertFinancialInfoAcquisition({
      variables: {
        financialInfoAcquisition: financial_info_acquisition,
        id: deal.id,
        state: deal.state,
        car_id: car?.id,
        mmr: car?.mmr,
        vehicle_payoff: car?.payoff?.vehicle_payoff,
      },
    });
  }, [financial_info_acquisition]);

  return (
    <>
      <HStack>
        <NumberInput
          label="Payoff"
          name="car.payoff.vehicle_payoff"
          isMoney
          isDisabled={isReadOnly}
        />
        <NumberInput label="KBB Lending" name="car.book_value" isMoney isDisabled={isReadOnly} />
      </HStack>
      <HStack>
        {deal.state === DealStateEnum.Structuring ? (
          <>
            <HStack>
              <Text>Max Cash To Customer:</Text>
              <Text>{formatMoney(values.financial_info_acquisition.max_cash_to_customer)}</Text>
            </HStack>
          </>
        ) : (
          <>
            <NumberInput
              label="Cash To Customer"
              name="financial_info_acquisition.cash_to_customer"
              isMoney
              isDisabled={isReadOnly}
            />
          </>
        )}
        <NumberInput label="MMR" name="car.mmr" isMoney isDisabled={isReadOnly} />
      </HStack>
      <HStack>
        {deal.state === DealStateEnum.Structuring ? (
          <>
            <HStack>
              <Text>Max Total Cost:</Text>
              <Text>{formatMoney(values.financial_info_acquisition.max_total_cost)}</Text>
            </HStack>
          </>
        ) : (
          <HStack>
            <Text> Total Cost:</Text>
            <Text>{formatMoney(values.financial_info_acquisition.total_cost)}</Text>
          </HStack>
        )}
        <NumberInput
          label="Appraised Value"
          name="financial_info_acquisition.appraised_value"
          isMoney
          isDisabled={isReadOnly}
        />
      </HStack>
      {showButtonsStates.includes(deal.state) && (
        <HStack>
          <Button
            size="lgWithIconRight"
            rightIcon={
              isConditionReportCompleted ? (
                <BiCheckCircle color="green" />
              ) : (
                <BiXCircle color="errorsRed" />
              )
            }
            isLoading={showModal}
            onClick={() => setShowModal(true)}
          >
            VIEW CONDITION REPORT
          </Button>
          <Modal title="Condition Report" isOpen={showModal} onClose={() => setShowModal(false)}>
            <ConditionReport setShowModal={setShowModal} isEditing={isEditable} />
          </Modal>
          <SaveButton />
          <SendToClosing />
          <EditDealInfoButton
            isEditingWithPermission={isEditable}
            setIsEditingWithPermission={setIsEditable}
          />
          <SaveEditButton isEditing={isEditable} setIsEditing={setIsEditable} />
          <GenerateAndSendContracts />
          {isEsign && <SendContractsButton />}
        </HStack>
      )}
      <HStack>
        {!!deal.financial_info_acquisition?.cash_to_customer &&
          deal.state === DealStateEnum.Structuring && (
            <>
              <HStack>
                <Text>Gross Profit:</Text>
                <Text>{formatMoney(values.financial_info_acquisition.gross_profit)}</Text>
              </HStack>
              <NumberInput
                label="Cash To Customer"
                name="financial_info_acquisition.cash_to_customer"
                isMoney
                isDisabled={isReadOnly}
              />
            </>
          )}
      </HStack>
      {deal.state === DealStateEnum.Structuring && (
        <HStack>
          {isApproved ? (
            <BiCheckCircle size="200" className="ml-2 mb-4" color={ColorsEnum.GREEN} />
          ) : (
            <>
              <BiXCircle
                size="200"
                className="ml-2 mb-4"
                color={ColorsEnum.RED}
                onClick={() => setShowXModal(true)}
              />
              <Modal
                title="Reasons for not being approved..."
                isOpen={showXModal}
                onClose={() => setShowXModal(false)}
              >
                <List>
                  {failedCriteria?.map((fc) => {
                    return (
                      <ListItem key={fc} color="warning">
                        {fc}
                      </ListItem>
                    );
                  })}
                </List>
              </Modal>
            </>
          )}
        </HStack>
      )}
    </>
  );
};

export default StructuringAndClosedView;
