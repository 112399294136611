import { ChangeEventHandler, useContext, useEffect, useState } from 'react';

import { Flex, InputProps, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal, isClosed } from '../../../../gql/dealGql';
import {
  FinancialInfo,
  LTV_THRESHOLD,
  OptionTypeEnum,
  getTotalTaxes,
} from '../../../../gql/financialInfoGql';

import NumberInput from '../../../shared/NumberInput';

import Package from './Package';

import { LienholderNameEnum } from '../../../../constants/lienholders';
import { PermissionEnum } from '../../../../constants/permissions';
import { usePaymentOptions } from '../../../../hooks/usePaymentOptions';
import { AbilityContext } from '../../../../libs/contextLib';
import { isNumber } from '../../../../libs/utils';
import { getLtvRatio } from '../../../../utils/deals';
import { DebouncedSave } from '../../DealInfoBuyoutForm';
import { paymentOptionsTypes } from '../../types';

type PaymentOptionsProps = {
  debounceSave: DebouncedSave;
  isEditingWithPermission: boolean;
  lienholder?: LienholderNameEnum;
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
};

const PaymentOptions = ({
  debounceSave,
  isEditingWithPermission,
  customHandleChange,
  lienholder,
}: PaymentOptionsProps) => {
  const abilities = useContext(AbilityContext);

  const [paymentOptions] = usePaymentOptions();
  const { vscPlusGap, vsc, gap, noProducts } = paymentOptions;

  const { initialValues, values, handleChange: formikHandleChange } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  const [isGapAvailable, setIsGapAvailable] = useState<boolean>(true);
  const [ltv, setLtv] = useState<number>(0);
  const [ltvUpdated, setLtvUpdated] = useState<boolean>(false);
  const [missingInfo, setMissingInfo] = useState<boolean>(true);
  const [missingInfoUpdated, setMissingInfoUpdated] = useState<boolean>(false);

  const {
    values: {
      financial_info: { option_type },
      state,
    },
    setValues,
  } = useFormikContext<Deal>();

  const isDisabled = isClosed(state) && !isEditingWithPermission;

  const { VscPlusGap, VSC, Gap, NoProducts } = OptionTypeEnum;

  const rateAndTermInputProps: InputProps = {
    bgColor: 'white',
    borderColor: 'gray.600',
    _hover: {
      borderColor: 'gray.600',
    },
    fontSize: {
      base: 'lg',
      '2xl': 'xl',
      '3xl': '2xl',
      '4xl': '3xl',
    },
    minH: '1.5em',
  };

  const mapOptionToValues = (option: paymentOptionsTypes, updateOptionType = false) => {
    const newValues: Deal = {
      ...values,
      financial_info: {
        ...values.financial_info,
        option_type: updateOptionType
          ? (option.name as OptionTypeEnum)
          : values.financial_info.option_type,
        profit: option.profit,
        amount_financed: option.amountFinanced,
        setter_commission: option.setterCommission,
        closer_commission: option.closerCommission,
        payment: option.sellPayment,
        reserve: option.reserve,
        // Sets the initial value of `tempUserEnteredReserve` to the current reserve value
        tempUserEnteredReserve: option.reserve,
        totalTaxAmount: getTotalTaxes({
          ...values.financial_info,
          option_type: option.name as OptionTypeEnum,
        }),
      },
    };

    return newValues;
  };

  const updateForm = (
    option: paymentOptionsTypes,
    config: { updateOptionType?: boolean; autosave?: boolean } = {
      updateOptionType: false,
      autosave: false,
    },
  ) => {
    const mappedValues = mapOptionToValues(option, config.updateOptionType);
    setValues(mappedValues);

    // In some cases where nothing was saved before, `amount_financed` is still null on the DB and is removed when updating one of the modals.
    const paymentOptionsNeverSaved =
      !initialValues.financial_info.amount_financed && mappedValues.financial_info.amount_financed;

    // Temporary fix the DB having different values than the calculated ones
    const keysToCheck: (keyof FinancialInfo)[] = [
      'amount_financed',
      // option values
      'option_type',
      'profit',
      'setter_commission',
      'closer_commission',
      'payment',
      'reserve',
      // totalTaxAmount
      'base_tax_amount',
      'warranty_tax_amount',
    ];

    const valuesAreDifferent = keysToCheck.some((key) => {
      // Get the values
      const mappedValue = mappedValues.financial_info[key];
      if (!mappedValue) {
        return false;
      }

      const initialValue = initialValues.financial_info[key];

      // Check if the values are numbers and round them to 2 decimal places
      const mappedFixed =
        typeof mappedValue === 'number' ? Number(mappedValue.toFixed(2)) : mappedValue;
      const initialFixed =
        typeof initialValue === 'number' ? Number(initialValue.toFixed(2)) : initialValue;

      // Check if the values are different
      return mappedFixed !== initialFixed;
    });
    // End of temporary fix

    if (config.autosave || paymentOptionsNeverSaved || valuesAreDifferent) {
      debounceSave({ newValues: mappedValues });
    }
  };

  const handleClick = (options: paymentOptionsTypes) => {
    if (isDisabled) {
      return;
    }

    updateForm(options, { updateOptionType: true, autosave: true });
  };

  const optionTypeActions = new Map<OptionTypeEnum, (gapAvailable: boolean) => void>();
  optionTypeActions.set(VscPlusGap, (gapAvailable: boolean) =>
    gapAvailable ? updateForm(vscPlusGap) : updateForm(vsc, { updateOptionType: true }),
  );
  optionTypeActions.set(Gap, (gapAvailable: boolean) =>
    gapAvailable ? updateForm(gap) : updateForm(noProducts, { updateOptionType: true }),
  );
  optionTypeActions.set(VSC, () => updateForm(vsc));
  optionTypeActions.set(NoProducts, () => updateForm(noProducts));

  useEffect(() => {
    setMissingInfo(
      !values.car.year ||
        !values.car.vin ||
        !values.car.mileage ||
        !isNumber(values.car.retail_book_value) ||
        !isNumber(values.car.payoff.vehicle_payoff) ||
        !isNumber(values.financial_info.sell_rate) ||
        !isNumber(values.financial_info.term) ||
        !values.customer.address?.state,
    );

    setMissingInfoUpdated(true);
  }, [values.customer.address, values.car, values.financial_info]);

  useEffect(() => {
    const af = values.financial_info.amount_financed;
    const rbv = values.car.retail_book_value;
    if (af && rbv) {
      setLtv(getLtvRatio(af, rbv));
    }

    setLtvUpdated(true);
  }, [values.financial_info.amount_financed, values.car.retail_book_value]);

  useEffect(() => {
    if (ltvUpdated && missingInfoUpdated) {
      const gapAvailable = !missingInfo && ltv >= LTV_THRESHOLD;
      setIsGapAvailable(gapAvailable);

      const action = optionTypeActions.get(option_type || NoProducts);

      action?.(gapAvailable);
    }
  }, [missingInfo, missingInfoUpdated, ltv, ltvUpdated, paymentOptions]);

  return (
    <Flex direction="column" bgColor="brightGray">
      <Flex direction="row" alignSelf="center" mb={3} mt={3} fontSize="sm">
        <Flex mr={2} ml={3} mt={2}>
          <Text mr={2} mt={3} whiteSpace="nowrap">
            Interest Rate
          </Text>
          <NumberInput
            name="financial_info.sell_rate"
            isPercentage
            isDisabled={isDisabled || !abilities.has(PermissionEnum.EditSellRate)}
            additionalHandleChange={handleChange}
            _input={{
              ...rateAndTermInputProps,
              maxW: '4.5em',
            }}
          />
        </Flex>
        <Flex mr={2} ml={3} mt={2}>
          <Text mr={2} mt={3} whiteSpace="nowrap">
            Term
          </Text>
          <NumberInput
            name="financial_info.term"
            isDisabled={isDisabled}
            additionalHandleChange={handleChange}
            _input={{
              ...rateAndTermInputProps,
              maxW: '3.5em',
            }}
          />
        </Flex>
      </Flex>
      <Flex gap={2} alignSelf="center" px={3} maxW="full" overflowX="auto">
        <Package
          name="VSC + GAP"
          option={vscPlusGap}
          lienholder={lienholder}
          isDisabled={isDisabled}
          isAvailable={isGapAvailable}
          onClick={handleClick}
        />
        <Package
          name="VSC Only"
          option={vsc}
          lienholder={lienholder}
          isDisabled={isDisabled}
          isAvailable
          onClick={handleClick}
        />
        <Package
          name="GAP Only"
          option={gap}
          lienholder={lienholder}
          isDisabled={isDisabled}
          isAvailable={isGapAvailable}
          onClick={handleClick}
        />
        <Package
          name="No Protection"
          option={noProducts}
          lienholder={lienholder}
          isDisabled={isDisabled}
          isAvailable
          onClick={handleClick}
          showReserveEdit={!isDisabled && abilities.has(PermissionEnum.EditReserve)}
          debounceSave={debounceSave}
        />
      </Flex>
    </Flex>
  );
};

export default PaymentOptions;
