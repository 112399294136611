/* eslint-disable max-classes-per-file */
import {
  Customer as GeneratedCustomer,
  MaritalStatusEnum,
  Maybe,
  Prequalification,
  ProofOfInsurance,
  TemporaryData,
} from './generated/graphql';

/* eslint-disable-next-line */
import gql from 'graphql-tag';

import { Option } from '../components/shared/types';

import { Address } from './addressGql';
import { DealContact } from './dealContactGql';
import { Employment } from './employmentGql';

export enum CustomerContactTypeEnum {
  Customer = 'customer',
  Cobuyer = 'cobuyer',
  Contact = 'contact',
  Second = 'second',
}

export enum RelationToBuyerEnum {
  ResidesWith = 'resides_with',
  Parent = 'parent',
  Spouse = 'spouse',
  Child = 'child',
  Other = 'other',
}

export const RelationshipToBuyerLabelMap = {
  [RelationToBuyerEnum.ResidesWith]: 'Lives with Buyer',
  [RelationToBuyerEnum.Parent]: 'Parent',
  [RelationToBuyerEnum.Spouse]: 'Spouse',
  [RelationToBuyerEnum.Child]: 'Child',
  [RelationToBuyerEnum.Other]: 'Other',
};

export const NameSuffixEnum = {
  Jr: 'JR',
  Sr: 'SR',
  I: 'I',
  II: 'II',
  III: 'III',
  IV: 'IV',
  V: 'V',
};

export const nameSuffixOptions: Option[] = Object.values(NameSuffixEnum).map((value) => ({
  label: value,
  value,
}));

export const relationshipToBuyerOptions: Option[] = Object.values(RelationToBuyerEnum).map(
  (value) => ({
    label: RelationshipToBuyerLabelMap[value],
    value,
  }),
);

export const relationshipToBuyerNoChildOptions: Option[] = relationshipToBuyerOptions.filter(
  (option) => option.value !== RelationToBuyerEnum.Child,
);

export class Customer {
  id?: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  name_suffix: string | undefined;
  phone_number: string;
  home_phone_number: string;
  email: string;
  no_email: boolean;
  ssn: string;
  ssn_last_4?: string;
  ssn_last_6?: string;
  dob?: string;
  address: Address;
  drivers_license_address?: Address;
  dl_expiration_date?: string;
  employment: Employment;
  prev_address: Address;
  prev_employment: Employment;
  relation_to_buyer?: RelationToBuyerEnum;
  has_same_address_as_cobuyer?: boolean | null;
  proof_of_insurance?: ProofOfInsurance;
  marital_status?: MaritalStatusEnum;
  wi_notification_agreement?: boolean;
  prequalification: Maybe<Prequalification>;
  auth0_id?: string;

  constructor() {
    this.first_name = '';
    this.middle_name = '';
    this.last_name = '';
    this.name_suffix = undefined;
    this.phone_number = '';
    this.home_phone_number = '';
    this.email = '';
    this.no_email = false;
    this.ssn = '';
    this.address = new Address();
    this.employment = new Employment();
    this.prev_address = new Address();
    this.prev_employment = new Employment();
    this.has_same_address_as_cobuyer = null;
    this.marital_status = undefined;
  }
}

export class Cobuyer extends Customer {
  constructor() {
    super();
    this.relation_to_buyer = RelationToBuyerEnum.Spouse;
    this.marital_status = undefined;
  }
}

export const isCustomer = (
  obj: Customer | GeneratedCustomer | DealContact | TemporaryData | null,
): obj is Customer => (obj as Customer)?.name_suffix !== undefined;

export const getFullName = (
  c: Customer | GeneratedCustomer | DealContact | TemporaryData | null | undefined,
) =>
  c
    ? `${c?.first_name ?? ''} ${c?.last_name ?? ''}${
        isCustomer(c) && c.name_suffix ? ` ${c.name_suffix}` : ''
      }`
    : '';

export const getFullNameWithMiddle = (c: Customer) =>
  c
    ? `${c.first_name}${c.middle_name ? ` ${c.middle_name} ` : ' '}${c.last_name} ${
        c.name_suffix || ''
      }`
    : '';

export const getFullNameWithMiddleReversed = (c: Customer) =>
  c ? `${c.last_name} ${c.name_suffix || ''}, ${c.first_name} ${c.middle_name || ''}` : '';

export const getFullNameWithInitial = <
  T extends { first_name?: string; last_name?: string; name_suffix?: string },
>(
  c: T,
) =>
  c
    ? `${c?.first_name ? `${c.first_name} ` : ''}${c?.last_name ? `${c.last_name[0]}.` : ''} ${
        c.name_suffix || ''
      }`
    : '';

export const updateCustomer = gql`
  mutation updateCustomer($customer: customerInput) {
    updateCustomer(customer: $customer) {
      id
    }
  }
`;

export const updateCustomerAndAddress = gql`
  mutation updateCustomerAndAddress($customer: customerInput) {
    updateCustomerAndAddress(customer: $customer) {
      id
    }
  }
`;

export const updateInsuranceInfo = gql`
  mutation updateInsuranceInfo(
    $id: ID!
    $insurance_company: String
    $policy_no: String
    $expiration_date: Date
  ) {
    updateInsuranceInfo(
      id: $id
      insurance_company: $insurance_company
      policy_no: $policy_no
      expiration_date: $expiration_date
    ) {
      id
      company_name
      expires
      policy_number
    }
  }
`;
