import { useContext, useMemo, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import {
  Button,
  RadioGroup as ChakraRadioGroup,
  Radio,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum } from '../../gql/dealGql';
import {
  DealType,
  PaperworkType,
  TitleRegistrationOption,
  useProcessPaperworkMutation,
} from '../../gql/generated/graphql';

import Modal from '../shared/Modal';
import Tooltip from '../shared/Tooltip';

import AdditionalRequiredInformationModal from './AdditionalRequiredInformationModal';

import { PermissionEnum } from '../../constants/permissions';
import { useRequiredDocumentsAndValidations } from '../../hooks/useRequiredDocumentsAndValidations';
import { Can } from '../../libs/Can';
import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { validateDateIsAfter } from '../../libs/yup-validators/dates';
import { canBankUseR1 } from '../../utils/financialInfos';

interface PaperworkOptionsProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseDeal: () => void;
  refetch?: () => Promise<ApolloQueryResult<Deal>>;
}

const PaperworkTypeModal = ({ isOpen, onClose, onCloseDeal }: PaperworkOptionsProps) => {
  const { isRecalculatingPayoff } = useContext(DealContext);
  const { values, errors } = useFormikContext<Deal>();

  const additionalRequiredInformationModal = useDisclosure();

  const { minDaysBeforeRegistrationExpires, loading: loadingRequiredDocumentsAndValidations } =
    useRequiredDocumentsAndValidations({ shouldExecute: isOpen });

  const bankCanUseR1 = !!canBankUseR1(values.financial_info.bank);
  const isTitleOnly =
    values.financial_info.title_registration_option === TitleRegistrationOption.TitleOnly;
  const isTitleAndRegistrationTransfer =
    values.financial_info.title_registration_option ===
    TitleRegistrationOption.TitleAndRegistrationTransfer;

  const [paperworkType, setPaperworkType] = useState(
    bankCanUseR1 ? PaperworkType.Esign : PaperworkType.Adobe,
  );

  const needsToUpdateInsuranceInfo =
    !values.customer.proof_of_insurance?.company_name ||
    !values.customer.proof_of_insurance?.policy_number ||
    !values.customer.proof_of_insurance?.expires;

  const needsToUpdateCarRegistrationExpiration =
    (isTitleOnly &&
      (!values.car.registration_expiration ||
        !validateDateIsAfter(values.car.registration_expiration, 90))) ||
    (isTitleAndRegistrationTransfer &&
      minDaysBeforeRegistrationExpires !== null &&
      (!values.car.registration_expiration ||
        !validateDateIsAfter(
          values.car.registration_expiration,
          minDaysBeforeRegistrationExpires,
        ))) ||
    // Only available for `Title & New Reg` option.
    (!!values.financial_info.needs_temporary_registration_tags &&
      !values.car.registration_expiration);

  const [processPaperwork, { loading }] = useProcessPaperworkMutation();

  const tooltipErrors = useMemo(() => {
    const newTooltipErrors = [];
    const needsEmail = paperworkType !== PaperworkType.Paper;

    if (!values.customer.email) {
      newTooltipErrors.push('Buyer email is required to E-Sign contracts');
    }

    if (values.cobuyer && !values.cobuyer.email) {
      newTooltipErrors.push('Co-buyer email is required to E-Sign contracts');
    }

    return needsEmail ? newTooltipErrors : [];
  }, [values.customer, values.cobuyer, errors, paperworkType]);

  const handleSelectPaperwork = async (dealValues: Deal) => {
    try {
      if (!dealValues.id) {
        throw new Error('No deal id');
      }

      await processPaperwork({
        variables: {
          id: dealValues.id,
          state: DealStateEnum.Closed,
          paperwork_type: paperworkType,
        },
      });

      if (
        (!needsToUpdateInsuranceInfo && !needsToUpdateCarRegistrationExpiration) ||
        dealValues.type === DealType.Acquisition
      ) {
        onCloseDeal();
      } else {
        additionalRequiredInformationModal.onOpen();
      }
    } catch (e) {
      logger.error('PaperworkOptionsButtons.tsx', '', null, e);
      toast.error('Error closing deal');
    }
  };

  return (
    <Can I={PermissionEnum.FinalizePayment}>
      <Modal
        title="Paperwork Type"
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        rightButtons={
          <Tooltip errors={tooltipErrors}>
            <Button
              isLoading={loading}
              isDisabled={
                tooltipErrors.length > 0 ||
                isRecalculatingPayoff ||
                loadingRequiredDocumentsAndValidations
              }
              onClick={() => handleSelectPaperwork(values)}
            >
              CLOSE DEAL
            </Button>
          </Tooltip>
        }
      >
        <Text mb={4}>How will this customer sign their contracts?</Text>
        <ChakraRadioGroup
          name="paperwork_type"
          mx={4}
          value={paperworkType}
          onChange={(val: PaperworkType) => setPaperworkType(val)}
        >
          <Stack>
            {bankCanUseR1 && (
              <Radio mb={0} value={PaperworkType.Esign}>
                <Text fontWeight="normal">E-Sign</Text>
              </Radio>
            )}
            <Radio value={PaperworkType.Paper}>
              <Text fontWeight="normal">Paper</Text>
            </Radio>
            <Radio mb={0} value={PaperworkType.Adobe}>
              <Text fontWeight="normal">Adobe</Text>
            </Radio>
          </Stack>
        </ChakraRadioGroup>

        <AdditionalRequiredInformationModal
          {...additionalRequiredInformationModal}
          handleCloseDeal={onCloseDeal}
          loading={loading}
          needsToUpdateInsuranceInfo={needsToUpdateInsuranceInfo}
          needsToUpdateCarRegistrationExpiration={needsToUpdateCarRegistrationExpiration}
          isTitleOnly={isTitleOnly}
          minDaysBeforeRegistrationExpires={isTitleOnly ? 90 : minDaysBeforeRegistrationExpires}
        />
      </Modal>
    </Can>
  );
};

export default PaperworkTypeModal;
