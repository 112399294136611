/* eslint-disable security/detect-object-injection */
import { FC, useMemo } from 'react';

import { Box, Table, TableContainer, Tbody, Text } from '@chakra-ui/react';
import { format, isToday, isYesterday } from 'date-fns';

import { Call } from '../../../gql/callGql';
import { useGetUserCallHistoryQuery } from '../../../gql/generated/graphql';

import PhoneCallRow from './PhoneCallRow';

import usePaginationControls from '../../../hooks/usePaginationControls';
import Pagination from '../../Pagination';

const UserPhoneCallHistory: FC = () => {
  const paginationControls = usePaginationControls({
    initialItemsPerPageOptions: [50, 100, 250, 500],
  });

  const callsResponse =
    useGetUserCallHistoryQuery({
      variables: {
        items_per_page: paginationControls.itemsPerPage,
        page: paginationControls.currentPage,
      },
      onCompleted: (data) => {
        if (!data.getUserCallHistory?.calls) {
          return;
        }

        const totalRecords = data.getUserCallHistory?.totalRecords;
        if (totalRecords) {
          paginationControls.setTotalRecords(totalRecords);
        }
      },
    }).data?.getUserCallHistory?.calls ?? [];

  const groupCallsByDay = useMemo(() => {
    return callsResponse.reduce((acc, call) => {
      const dateKey = format(new Date(call?.created_at), 'MM/dd/yyyy');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(call as Call);
      return acc;
    }, {} as Record<string, Call[]>);
  }, [callsResponse]);

  return (
    <>
      <Box borderWidth="2px" borderBottomRadius="10px" borderColor="lightGray">
        <TableContainer height="max" style={{ height: '400px', overflowY: 'scroll' }}>
          <Table bgColor="white">
            <Tbody>
              {/* add a row for each different day */}
              {Object.keys(groupCallsByDay).map((dateKey) => (
                <>
                  <Text mt="10px" ml="20px" mb={0} color="silverSlateGray">
                    {isToday(new Date(dateKey))
                      ? 'TODAY'
                      : isYesterday(new Date(dateKey))
                      ? 'YESTERDAY'
                      : new Date(dateKey)
                          .toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                          })
                          .toLocaleUpperCase()}
                  </Text>

                  {groupCallsByDay[dateKey].map((call: Call) => (
                    <PhoneCallRow call={call} />
                  ))}
                </>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          paginationControls={paginationControls}
          borderTop="1px solid"
          borderColor="lightGray"
        />
      </Box>
    </>
  );
};

export default UserPhoneCallHistory;
