import { ComponentStyleConfig } from '@chakra-ui/react';

const modal: ComponentStyleConfig = {
  parts: ['subHeader', 'secondaryTitle'],
  baseStyle: {
    overlay: { width: '100%', height: '100%' },
    dialogContainer: { width: '100%' },
    header: {
      minHeight: '2.5em',

      fontSize: 'md',
      color: 'white',

      bgColor: 'mediumElectricBlue',
      borderRadius: '10px 10px 0px 0px',

      paddingLeft: '1em',
      paddingRight: '12px',
      paddingY: '7px',
    },
    title: {
      whiteSpace: 'nowrap',
    },
    secondaryTitle: {
      fontSize: 'sm',
      color: 'white',
    },
    subHeader: {
      fontSize: 'sm',
      color: 'white',

      bgColor: 'queenBlue',

      height: '35px',
      paddingLeft: '16px',
      paddingRight: '12px',
      paddingY: '7px',
    },
    closeButton: {
      color: 'white',
      fontSize: '10px',

      width: '24px',
      height: '24px',
    },
    dialog: {
      borderRadius: '12px',

      maxH: '90%',
    },
    body: {
      marginX: '20px',
      marginY: '12px',
    },
  },
  variants: {
    fullContent: {
      body: {
        margin: 0,
        padding: 0,
      },
    },
    noPadding: {
      body: {
        padding: 0,
      },
    },
    noMargin: {
      body: {
        margin: 0,
      },
    },
  },
  sizes: {
    md: {
      header: {
        fontSize: 'xl',

        paddingY: '14px',
      },
      secondaryTitle: {
        fontSize: 'md',
      },
      subHeader: {
        fontSize: 'md',

        height: '58px',
        paddingY: '14px',
      },
      closeButton: {
        fontSize: '20px',

        width: '40px',
        height: '40px',
      },
    },
    lg: {
      header: {
        fontSize: '2xl',

        paddingY: '14px',
      },
      secondaryTitle: {
        fontSize: 'xl',
      },
      subHeader: {
        fontSize: 'xl',

        height: '64px',
        paddingY: '14px',
      },
      closeButton: {
        fontSize: '20px',

        width: '48px',
        height: '48px',
      },
    },
    '4xl': {
      body: {
        marginX: '40px',
        marginY: '24px',
      },
    },
    '7xl': {
      dialog: {
        width: '1600px',
        height: '100%',
      },
    },
    full: {
      dialog: {
        width: '90%',
        height: '100%',

        borderTopRadius: '12px',
      },
    },
    dynamicFull: {
      dialog: { height: '100dvh', position: 'fixed' },
    },
  },
};

export default modal;
