import { useCallback, useMemo } from 'react';

import { Button, Checkbox, IconButton, VStack, useDisclosure } from '@chakra-ui/react';
import { Formik } from 'formik';
import { RiMailSendLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
  Deal,
  useCustomDatesUpdateMutation,
  useDealAssignMutation,
  useMessageSendMutation,
  useTemplateTextsQuery,
} from '../../../gql/generated/graphql';

import DatePicker from '../../shared/DatePicker';
import Modal from '../../shared/Modal';
import Tooltip from '../../shared/Tooltip';

import { useUser } from '../../../hooks/useUser';
import { logger } from '../../../libs/Logger';
import { formatPhone } from '../../../libs/utils';
import { fillText } from '../../../utils/messages';
import { RemoveIndex } from '../../../utils/types';

export const validationSchema = Yup.object().shape({
  temporary_registration_tags_expiration_date: Yup.date()
    .optional()
    .nullable()
    .required('Expiration Date is required'),
  send_text_to_customer: Yup.boolean().optional().nullable(),
});

export type FormValues = Partial<RemoveIndex<Yup.InferType<typeof validationSchema>>>;

type SendTempTagsProps = {
  deal: Deal;
};

const SendTempTags = ({ deal }: SendTempTagsProps) => {
  const user = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateCustomDatesMutation, { loading: loadingCustomDatesUpdate }] =
    useCustomDatesUpdateMutation();
  const [sendMessage, { loading: loadingSendMessage }] = useMessageSendMutation();
  const [dealAssign, { loading: loadingDealAssign }] = useDealAssignMutation();

  const loading = loadingCustomDatesUpdate || loadingSendMessage || loadingDealAssign;

  const hasTrackingNumber = !!deal.shipping_labels?.[0]?.tracking_number;

  const { data: templateTextsData } = useTemplateTextsQuery();
  const temporaryTagsTemplateText = useMemo(
    () => templateTextsData?.templateTexts?.find((t) => t?.key === 'titling_temporary_tags'),
    [templateTextsData],
  );

  const initialValues: FormValues = useMemo(
    () => ({
      temporary_registration_tags_expiration_date: deal.deal_dates?.custom_dates
        ?.temporary_registration_tags_expiration_date
        ? new Date(deal.deal_dates.custom_dates.temporary_registration_tags_expiration_date)
        : undefined,
      send_text_to_customer: false,
    }),
    [deal.deal_dates?.custom_dates?.temporary_registration_tags_expiration_date],
  );

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!deal.id || !deal.customer) {
        return;
      }

      try {
        await updateCustomDatesMutation({
          variables: {
            deal_id: deal.id,
            date_key: 'temporary_registration_tags_expiration_date',
            date: values.temporary_registration_tags_expiration_date,
          },
        });
        // `temporary_registration_tags_sent_date` should be updated in second place because it triggers the subscription.
        await updateCustomDatesMutation({
          variables: {
            deal_id: deal.id,
            date_key: 'temporary_registration_tags_sent_date',
            // TODO: DateTime! is interpreted as any.
            // The fix is adding `DateTime: string | Date` to the `config.scalars` in `codegen.yml`. Several errors will be thrown.
            date: new Date().toISOString(),
          },
        });

        // Assign to the user that sent the temp tags.
        await dealAssign({
          variables: {
            deal_id: deal.id ?? 0,
            assignee_id: user.id,
            role: 'temporary_registration_tags_user_id',
            allow_reassign: true,
          },
        });

        if (values.send_text_to_customer) {
          if (!hasTrackingNumber) {
            toast.error('Tracking # not found. Please send manually.');
            onClose();
            return;
          }
          if (!temporaryTagsTemplateText?.text) {
            toast.error('Template text not found. Please send manually.');
            onClose();
            return;
          }

          const message = fillText(temporaryTagsTemplateText.text, {
            user,
            customer: deal.customer,
            deal,
          });
          const formattedPhone = formatPhone(deal.customer.phone_number ?? '');
          await sendMessage({
            variables: {
              id: deal.id,
              dealPhoneNumber: formattedPhone,
              message,
            },
          });
        }

        toast.success('Deal assigned and Temp Tags sent successfully');
        onClose();
      } catch (e) {
        const error = e as Error;
        logger.error(
          'SendTempTags.tsx',
          'upsertVehicleInfo or updateCustomDatesMutation or sendMessage',
          values,
          error,
        );
        toast.error(
          `Error sending Temp Tags: ${error.message || error}. Please refresh and try again.`,
        );
      }
    },
    [deal.id, deal.customer, temporaryTagsTemplateText, updateCustomDatesMutation, sendMessage],
  );

  return (
    <>
      <Tooltip
        label="Shipping Label has not been purchased yet"
        bgColor="gray.700"
        placement="top"
        isDisabled={hasTrackingNumber}
      >
        <IconButton
          aria-label="Send Temp Tags"
          variant="iconHover"
          size="xs"
          isDisabled={!hasTrackingNumber}
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
          icon={<RiMailSendLine />}
        />
      </Tooltip>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {({ values, setFieldValue }) => (
          <Modal
            title="Send Temp Tags"
            hasForm
            isOpen={isOpen}
            onClose={onClose}
            leftButtons={
              <Button variant="warning" onClick={onClose} isDisabled={loading}>
                CANCEL
              </Button>
            }
            rightButtons={
              <Button type="submit" isLoading={loading} loadingText="SEND TAGS" variant="primary">
                SEND TAGS
              </Button>
            }
            scrollBehavior="outside"
          >
            <VStack spacing={4} align="flex-start">
              <DatePicker
                name="temporary_registration_tags_expiration_date"
                topLabel="Please enter the Expiration Date of the temporary tags"
              />
              <Checkbox
                name="send_text_to_customer"
                size="sm"
                isChecked={!!values.send_text_to_customer}
                onChange={(e) => setFieldValue('send_text_to_customer', e.target.checked)}
              >
                Send tracking information text to customer
              </Checkbox>
            </VStack>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default SendTempTags;
