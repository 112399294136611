/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useMemo, useState } from 'react';

export const ITEMS_PER_PAGE_OPTIONS = [25, 50, 100];
export const FIRST_PAGE = 0;

export type PaginationControls = {
  currentPage: number;
  itemsPerPage: number;
  totalRecords: number;
  maxPage: number;
  setItemsPerPage: (perPage: number) => void;
  nextPage: () => void;
  prevPage: () => void;
  setTotalRecords: (totalRecords: number) => void;
  setCurrentPage: (page: number) => void;
  itemsPerPageOptions: number[];
};

type PaginationControlsParams = {
  initialCurrentPage?: PaginationControls['currentPage'];
  initialItemsPerPage?: PaginationControls['itemsPerPage'];
  initialItemsPerPageOptions?: PaginationControls['itemsPerPageOptions'];
};

const usePaginationControls = ({
  initialCurrentPage,
  initialItemsPerPage,
  initialItemsPerPageOptions,
}: PaginationControlsParams = {}): PaginationControls => {
  const itemsPerPageOptions = useMemo(
    () => initialItemsPerPageOptions ?? ITEMS_PER_PAGE_OPTIONS,
    [initialItemsPerPageOptions],
  );

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, _setCurrentPage] = useState(initialCurrentPage ?? FIRST_PAGE);
  const [itemsPerPage, setItemsPerPage] = useState(
    initialItemsPerPage && itemsPerPageOptions.includes(initialItemsPerPage)
      ? initialItemsPerPage
      : itemsPerPageOptions[0],
  );

  const maxPage = useMemo(
    () => Math.ceil(totalRecords / itemsPerPage),
    [totalRecords, itemsPerPage],
  );

  const setCurrentPage = useCallback(
    (page: number) => {
      const pageNumber = Math.max(FIRST_PAGE, page);
      _setCurrentPage(() => Math.min(pageNumber, maxPage));
    },
    [maxPage],
  );

  const nextPage = useCallback(() => {
    _setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, maxPage - 1));
  }, [maxPage]);

  const prevPage = useCallback(() => {
    _setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, FIRST_PAGE));
  }, []);

  return {
    nextPage,
    prevPage,
    setCurrentPage,
    currentPage,
    maxPage,
    itemsPerPage,
    setItemsPerPage,
    totalRecords,
    setTotalRecords,
    itemsPerPageOptions,
  };
};

export default usePaginationControls;
