import { Button, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { User, useUpdateUserSettingsMutation } from '../../../gql/generated/graphql';

import Modal, { LEModalProps } from '../../../components/shared/Modal';

import { MIN_MAX_AUTO_ASSIGN_DEALS } from '../../../constants/user';
import { logger } from '../../../libs/Logger';

type UpdateUserVacationModalProps = LEModalProps & {
  user: User | null;
};

const UpdateUserVacationModal = ({
  user,
  isOpen,
  onClose,
  ...rest
}: UpdateUserVacationModalProps) => {
  const [updateUserSettings, { loading }] = useUpdateUserSettingsMutation();

  if (
    !user ||
    !user.id ||
    !user.hours ||
    user.hours == null ||
    user.auto_assign_deals == null ||
    user.overnight_deals == null
  ) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Update Vacation"
      leftButtons={
        <Button variant="warning" onClick={onClose}>
          Cancel
        </Button>
      }
      rightButtons={
        <Button
          isLoading={loading}
          onClick={async () => {
            try {
              await updateUserSettings({
                variables: {
                  id: user.id ?? '',
                  hours: user.hours ?? '',
                  on_vacation: !user.on_vacation,
                  auto_assign_deals: !!user.auto_assign_deals,
                  overnight_deals: !!user.overnight_deals,
                  max_auto_assign_deals: user.max_auto_assign_deals ?? MIN_MAX_AUTO_ASSIGN_DEALS,
                },
              });
              toast.success('User updated successfully.');
              onClose();
            } catch (e) {
              toast.error('Failed to update user.');
              logger.error(
                'UpdateUserVacationModal',
                'Failed to update vacation setting',
                {
                  id: user?.id,
                  vacation: user?.on_vacation,
                },
                e,
              );
            }
          }}
        >
          Confirm
        </Button>
      }
      {...rest}
    >
      <Text>Are you sure you want to update the vacation setting for {user?.name}</Text>
    </Modal>
  );
};

export default UpdateUserVacationModal;
