import { useContext } from 'react';

import { Box, Button, ButtonGroup, Flex, Heading, IconButton } from '@chakra-ui/react';
import { GoTriangleDown } from 'react-icons/go';
import { MdAddCircle } from 'react-icons/md';
import Select from 'react-select';

import ShippingLabelButton from './ShippingLabels';

import { DealMediaStatusEnum } from '../../../constants/media';
import { DealContext } from '../../../libs/DealContext';
import SignatureUploadModalButton from '../../SignatureUploadModalButton/SignatureUploadModalButton';

export interface MediaFilter {
  label: string;
  value: DealMediaStatusEnum;
}

interface MediaCenterHeaderProps {
  filterValue: MediaFilter;
  onAddMedia: () => void;
  onDelete: () => void;
  onFilter: (value: MediaFilter) => void;
  onPrint: () => void;
  canDelete: boolean;
}

const selectOptions = Object.entries(DealMediaStatusEnum).map(([key, value]) => ({
  label: key,
  value,
}));

const MediaCenterHeader = ({
  canDelete,
  filterValue,
  onAddMedia,
  onDelete,
  onFilter,
  onPrint,
}: MediaCenterHeaderProps) => {
  const { deal } = useContext(DealContext);

  return (
    <Box backgroundColor="oxfordBlue" borderRadius="10px 10px 0 0">
      <Box alignItems="center" display="flex" justifyContent="space-between" px={4} py={2}>
        <Heading color="white" fontSize="20px" fontWeight="medium">
          Media Center
        </Heading>
        <Flex align="center" justify="left">
          <Box minW="125px">
            <Select
              components={{
                DropdownIndicator: () => (
                  <Box color="primary" mr={2}>
                    <GoTriangleDown size={20} />
                  </Box>
                ),
              }}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onChange={(val) => onFilter(val!)}
              options={selectOptions}
              styles={{
                indicatorSeparator: (base) => ({
                  ...base,
                  backgroundColor: 'white',
                }),
              }}
              value={filterValue}
            />
          </Box>
          <IconButton
            icon={<MdAddCircle />}
            variant="icon"
            fontSize={32}
            aria-label="Upload Media"
            onClick={onAddMedia}
          />
        </Flex>
      </Box>

      <Box
        bgColor="queenBlue"
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        px={4}
        py={2}
        rowGap={1}
      >
        <ButtonGroup>
          <Button size="sm" onClick={onPrint}>
            PRINT
          </Button>
          <ShippingLabelButton dealId={deal.id} />
        </ButtonGroup>
        <ButtonGroup>
          <SignatureUploadModalButton inMediaCenter />
          <Button size="sm" variant="boot" isDisabled={canDelete} onClick={onDelete}>
            DELETE
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default MediaCenterHeader;
