import { useContext, useState } from 'react';

import { Box, Table, Tbody, Thead } from '@chakra-ui/react';

import {
  StructuringFollowUpDealsQuery,
  StructuringFollowUpSortColumn,
  StructuringFollowUpSortDirection,
  useOnStructuringFollowUpUpdateSubscription,
  useStructuringFollowUpDealsQuery,
} from '../../gql/generated/graphql';

import Pagination from '../Pagination';

import StructuringFollowUpTableHeader from './StructuringFollowUpTableHeader';
import StructuringFollowUpTableRow from './StructuringFollowUpTableRow';
import { TABLE_COLUMNS_ENTRIES } from './columnConfig';

import { PermissionEnum } from '../../constants/permissions';
import usePaginationControls from '../../hooks/usePaginationControls';
import { AbilityContext, FiltersContext } from '../../libs/contextLib';

export type StructuringFollowUpDeals = NonNullable<
  NonNullable<StructuringFollowUpDealsQuery['structuringFollowUpDeals']>['deals']
>;

const StructuringFollowUpsTable = () => {
  const abilities = useContext(AbilityContext);
  const {
    filters: {
      global: {
        selectedPodId,
        sources,
        types,
        showOnlyUnread,
        showAssignedToMe,
        showBootRequested,
        showActiveTags,
        showMatureFollowUps,
      },
    },
  } = useContext(FiltersContext);

  const [sortColumn, setSortColumn] = useState<StructuringFollowUpSortColumn>(
    StructuringFollowUpSortColumn.Date,
  );
  const [sortDirection, setSortDirection] = useState<StructuringFollowUpSortDirection>(
    StructuringFollowUpSortDirection.Asc,
  );
  const [structuringFollowUpDeals, setStructuringFollowUpDeals] =
    useState<StructuringFollowUpDeals>([]);

  const paginationControls = usePaginationControls({ initialItemsPerPageOptions: [50, 100] });

  const assignedToMe = abilities.has(PermissionEnum.FilterDealOwner) ? true : showAssignedToMe;

  useStructuringFollowUpDealsQuery({
    variables: {
      sortColumn,
      sortDirection,
      page: paginationControls.currentPage,
      pageSize: paginationControls.itemsPerPage,
      sources,
      dealTypes: types,
      assignedToMe,
      bootRequested: showBootRequested,
      notifications: showOnlyUnread,
      podId: selectedPodId,
      showActiveTags,
      showMatureFollowUps,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      paginationControls.setTotalRecords(data.structuringFollowUpDeals?.totalRecords ?? 0);
      setStructuringFollowUpDeals(data.structuringFollowUpDeals?.deals ?? []);
    },
  });

  useOnStructuringFollowUpUpdateSubscription({
    skip: !sources.length,
    variables: {
      sources,
      types,
      assignedToMe,
      podId: selectedPodId,
    },
    onData: ({ data: { data } }) => {
      if (!data?.onStructuringFollowUpUpdate) {
        return;
      }

      setStructuringFollowUpDeals((prevDeals) =>
        (data.onStructuringFollowUpUpdate ?? []).reduce(
          (acc, subDeal) => {
            if (!subDeal?.id) {
              return acc;
            }
            // Delete
            if (!subDeal.structuring_follow_up) {
              return acc.filter((deal) => deal?.id !== subDeal.id);
            }
            // Update
            const prevDealIndex = prevDeals.findIndex((deal) => deal?.id === subDeal.id);
            if (prevDealIndex !== -1) {
              acc[prevDealIndex] = subDeal;
              return acc;
            }
            // Add
            return [subDeal, ...acc];
          },
          [...prevDeals],
        ),
      );
    },
  });

  return (
    <Box w="100%">
      <Box p={6} bgColor="oxfordBlue" borderTopRadius={8} w="100%" />
      <Box w="100%" overflowX="auto">
        <Table>
          <Thead cursor="pointer" color="white" bgColor="queenBlue" w="100%" px={6} height="55px">
            {TABLE_COLUMNS_ENTRIES.map(([header, { sortColumn: columnName, hideHeader }]) =>
              columnName ? (
                <StructuringFollowUpTableHeader
                  header={header}
                  hideHeader={hideHeader}
                  canSort
                  columnName={columnName}
                  sortColumn={sortColumn}
                  setSortColumn={setSortColumn}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                />
              ) : (
                <StructuringFollowUpTableHeader header={header} hideHeader={hideHeader} />
              ),
            )}
          </Thead>
          <Tbody>
            {structuringFollowUpDeals.map((deal) =>
              deal ? <StructuringFollowUpTableRow deal={deal} /> : null,
            )}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        paginationControls={paginationControls}
        borderTop="1px solid"
        borderColor="lightGray"
      />
    </Box>
  );
};

export default StructuringFollowUpsTable;
