import { useContext, useMemo } from 'react';

import {
  Box,
  Button,
  DeepPartial,
  FormControl,
  HStack,
  StyleProps,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Deal } from '../../gql/dealGql';
import { useUpdateCarMutation, useUpdateInsuranceInfoMutation } from '../../gql/generated/graphql';

import { InsuranceForm } from '../CreditApplication/components/InsuranceForm';
import DatePicker from '../shared/DatePicker';
import FormErrorContainer from '../shared/FormErrorContainer';
import { GridFormColumn } from '../shared/GridForm';
import Modal from '../shared/Modal';
import Tooltip from '../shared/Tooltip';

import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { validateDateIsAfter } from '../../libs/yup-validators/dates';
import { isNullOrUndefined } from '../../utils';
import { createDateISO, isDateLessThanNumDaysAway } from '../../utils/dates';

type AdditionalRequiredInformationModalProps = UseDisclosureReturn & {
  isTitleOnly: boolean;
  handleCloseDeal: () => void;
  loading?: boolean;
  needsToUpdateInsuranceInfo?: boolean;
  needsToUpdateCarRegistrationExpiration?: boolean;
  minDaysBeforeRegistrationExpires?: number | null;
};

const AdditionalRequiredInformationModal = ({
  isOpen,
  onClose,
  isTitleOnly,
  handleCloseDeal,
  loading = false,
  needsToUpdateInsuranceInfo = false,
  needsToUpdateCarRegistrationExpiration = false,
  minDaysBeforeRegistrationExpires,
}: AdditionalRequiredInformationModalProps) => {
  const { deal, dispatch } = useContext(DealContext);

  const [updateInsurance] = useUpdateInsuranceInfoMutation();
  const [updateCarRegistrationExpiration] = useUpdateCarMutation();

  const width: StyleProps['width'] = '95%';

  const registrationExpirationRequiredErrorMessage = 'Registration Expiration Date is required';
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...(needsToUpdateInsuranceInfo
          ? {
              customer: Yup.object().shape({
                proof_of_insurance: Yup.object().shape({
                  company_name: Yup.string().nullable().required('Insurance Company is required'),
                  policy_number: Yup.string().nullable().required('Policy No. is required'),
                  expires: Yup.date().nullable().required('Expiration Date is required'),
                }),
              }),
            }
          : {}),
        ...(needsToUpdateCarRegistrationExpiration
          ? {
              car: Yup.object().shape({
                registration_expiration: Yup.date()
                  .nullable()
                  .required(registrationExpirationRequiredErrorMessage)
                  .test(
                    'dateIsAfter',
                    isTitleOnly
                      ? `This registration expires in less than ${minDaysBeforeRegistrationExpires} days. 
                  The customer will need to renew their registration to avoid being impounded or experiencing other issues in titling.`
                      : `Reg Transfer is not allowed with less than ${minDaysBeforeRegistrationExpires} valid days in the current registration.`,
                    (value) =>
                      // `minDaysBeforeRegistrationExpires` is null when `Title & New Reg`.
                      !isNullOrUndefined(minDaysBeforeRegistrationExpires)
                        ? validateDateIsAfter(value, minDaysBeforeRegistrationExpires)
                        : true,
                  ),
              }),
            }
          : {}),
      }),
    [
      needsToUpdateInsuranceInfo,
      needsToUpdateCarRegistrationExpiration,
      isTitleOnly,
      minDaysBeforeRegistrationExpires,
    ],
  );

  const handleSubmit = async (values: Deal) => {
    const { customer, car } = values;
    const { proof_of_insurance } = customer;

    const dispatchPayload: DeepPartial<Deal> = {};

    if (
      customer.id &&
      proof_of_insurance?.company_name &&
      proof_of_insurance?.policy_number &&
      proof_of_insurance?.expires
    ) {
      const expiration_date = createDateISO(proof_of_insurance.expires);
      const updateInsuranceResult = await updateInsurance({
        variables: {
          id: customer.id,
          insurance_company: proof_of_insurance.company_name,
          policy_no: proof_of_insurance.policy_number,
          expiration_date,
        },
      });

      dispatchPayload.customer = {
        proof_of_insurance: {
          company_name: updateInsuranceResult.data?.updateInsuranceInfo?.company_name,
          policy_number: updateInsuranceResult.data?.updateInsuranceInfo?.policy_number,
          expires: updateInsuranceResult.data?.updateInsuranceInfo?.expires,
        },
      };
    }

    if (car.registration_expiration) {
      const updateCarResult = await updateCarRegistrationExpiration({
        variables: {
          car: {
            id: deal.car.id,
            registration_expiration: car.registration_expiration,
          },
        },
      });

      dispatchPayload.car = {
        registration_expiration: updateCarResult.data?.updateCar?.registration_expiration,
      };
    }

    if (Object.keys(dispatchPayload).length) {
      dispatch({
        type: DealActionsEnum.DeepUpdateDeal,
        payload: dispatchPayload,
      });
    }

    handleCloseDeal();
  };

  return (
    <Modal title="Additional Information Required" isOpen={isOpen} onClose={onClose} size="2xl">
      <Formik
        enableReinitialize
        validateOnMount
        onSubmit={handleSubmit}
        initialValues={deal}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, isValid }) => {
          return (
            <Form>
              <Text color="black" align="left">
                In order to generate the final documents and ensure no issues in completing the
                title process, the following additional information is required to proceed:
              </Text>

              {needsToUpdateInsuranceInfo ? (
                <InsuranceForm
                  name="customer"
                  variant="column"
                  showHeader={false}
                  mx={4}
                  mt={0}
                  w={width}
                />
              ) : null}

              {needsToUpdateCarRegistrationExpiration && (
                <Box mb={8} mx={4} w={width}>
                  <GridFormColumn mt={needsToUpdateInsuranceInfo ? 0 : 5}>
                    <FormControl>
                      <DatePicker
                        topLabel="Registration Expiration"
                        name="car.registration_expiration"
                        valueFormat="dateTimeUTC"
                        showMonthDropdown
                        showYearDropdown
                        labelStyles={{ color: 'black', fontWeight: 'bold' }}
                        showErrorMessage={
                          !!errors.car?.registration_expiration &&
                          !!touched.car?.registration_expiration &&
                          errors.car.registration_expiration ===
                            registrationExpirationRequiredErrorMessage
                        }
                      />
                      {!!errors.car?.registration_expiration &&
                      !!touched.car?.registration_expiration &&
                      errors.car.registration_expiration !==
                        registrationExpirationRequiredErrorMessage ? (
                        <FormErrorContainer mt={4}>
                          <Text fontSize={14} fontWeight="normal">
                            {errors.car?.registration_expiration}
                          </Text>
                        </FormErrorContainer>
                      ) : null}
                    </FormControl>
                  </GridFormColumn>
                </Box>
              )}

              {isDateLessThanNumDaysAway(10, values.car.payoff.next_payment_date) ? (
                <FormErrorContainer>
                  <Text fontSize={14} fontWeight="normal">
                    The buyer will still be responsible for the next payment to avoid any penalties.
                  </Text>
                </FormErrorContainer>
              ) : null}

              <HStack mt={4} spacing="auto" mx="auto">
                <Button variant="warning" onClick={onClose}>
                  CANCEL
                </Button>
                <Tooltip errors={errors}>
                  <Button
                    type="submit"
                    isLoading={loading}
                    loadingText="SUBMIT"
                    isDisabled={!isValid}
                  >
                    SUBMIT
                  </Button>
                </Tooltip>
              </HStack>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AdditionalRequiredInformationModal;
