const colors = {
  // Blues
  azureishWhite: '#D1E5F5',
  babyBlueEyes: '#A0C8EA',
  skyBlue: '#77E3FD',
  cadetBlue: '#9BACBE',
  electricBlue: '#082549',
  littleBoyBlue: '#69A6DA',
  mediumElectricBlue: '#005292',
  queenBlue: '#406286',
  queenBlueLight: '#406286b3', // 70% opacity
  secondary: '#00224C',
  silverLakeBlue: '#006cbb',
  oxfordBlue: '#00224C',
  ceruleanBlue: '#00ACE9',
  cornFlowerBlue: '#77A4D5',
  selectBlue: '#4796ea',
  refiCyan: '#00ACE9',

  // Purples
  lavender: '#BCA8D7',
  thunderPurple: '#8C62AD',
  royalPurple: '#662D91',

  // Greens
  caribbeanGreen: {
    500: '#00BA98',
  },
  green: '#8CC63F',
  lightGreen: {
    50: '#F0FBE2',
    100: '#DAEEBF',
    200: '#C3E29B',
    300: '#ADD674',
    400: '#95CA4E',
    500: '#7CB135',
    600: '#5F8A28',
    700: '#44621A',
    800: '#273C0D',
    900: '#0A1500',
  },
  tealGreen: '#16B799',
  taskGreen: '#DDEFC9',

  // Grays
  backgroundGray: '#D1D1D1',
  basicGray: '#808080',
  brightGray: '#EFEFEF',
  selectGray: '#566064',
  darkLiver: '#505050',
  hoverGray: '#F5F5F5',
  platinum: '#E3E3E3',
  silverSlateGray: '#707070',
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },
  taskGrey: 'platinum',

  // Oranges
  americanOrange: '#FF8900',

  // Reds
  errorsRed: '#C40606',
  rubyRed: '#910C28',
  spanishPink: '#EFBAB8',
  violetRed: '#B7169E',
  taskRed: '#EFD1CA',

  // Whites
  snowyWhite: '#F7F7F7',
  white: '#FFFFFF',

  // Yellows
  crayolaYellow: '#F6D36E',
  primary: '#F3C43E',
  taskYellow: '#F7EDAB',
};

export default colors;
