import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';
import { MdAddCircleOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum } from '../../../gql/dealGql';
import {
  StructuringFollowUpDealFragmentFragment,
  useDealAssignMutation,
} from '../../../gql/generated/graphql';

import { logger } from '../../../libs/Logger';
import { handleClaimDealError } from '../../../utils/deals';

interface ClaimActionsProps {
  deal: Deal | StructuringFollowUpDealFragmentFragment;
  setRole: keyof Pick<
    Deal,
    'setter_id' | 'closer_id' | 'structuring_manager_id' | 'temporary_registration_tags_user_id'
  >;
  assumedId?: string;
  showOutlineIcon?: boolean;
  size?: IconButtonProps['size'];
}

const ClaimActions = ({
  deal,
  setRole,
  assumedId,
  showOutlineIcon = false,
  size = 'xxs',
}: ClaimActionsProps) => {
  const [assignDeal] = useDealAssignMutation();

  // If the user is claiming the deal from the floor, move off the floor, else keep in same state
  const claimState = deal.state === DealStateEnum.Floor ? DealStateEnum.SoftClose : deal.state;

  return (
    <IconButton
      icon={showOutlineIcon ? <MdAddCircleOutline /> : <BiPlus />}
      variant="iconHover"
      size={size}
      fontSize={20}
      aria-label="Claim Deal"
      id={`assign-deal-${deal.id}`}
      onClick={async (event) => {
        event.stopPropagation();
        try {
          await assignDeal({
            variables: {
              deal_id: deal.id ?? 0,
              assignee_id: assumedId,
              deal_state: claimState,
              role: setRole,
            },
          });
          toast.success('Deal Assigned!');
        } catch (e) {
          logger.error('ClaimActions.tsx', '', null, e);
          handleClaimDealError(e);
        }
      }}
    />
  );
};

export default ClaimActions;
