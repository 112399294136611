import { useContext } from 'react';

import { Button } from '@chakra-ui/react';
import { BsCheck2All } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { DealStateEnum } from '../../../gql/dealGql';
import { useOverrideCalledByFinancialSpecialistMutation } from '../../../gql/generated/graphql';

import { DealActionsEnum, DealContext } from '../../../libs/DealContext';
import { logger } from '../../../libs/Logger';

const OverrideSeenDealButton = () => {
  // Because the button is "Mark Seen" we will name the mutation function name similar
  const [overrideSeen, { loading }] = useOverrideCalledByFinancialSpecialistMutation();
  const { deal, dispatch } = useContext(DealContext);

  // Only show button in Closing
  if (deal.state !== DealStateEnum.Closing || deal.called_by_financial_specialist) {
    return null;
  }

  const handleClicked = async () => {
    if (!deal.id) {
      return;
    }

    try {
      const { data } = await overrideSeen({
        variables: {
          dealId: deal.id,
        },
      });

      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          ...deal,
          called_by_financial_specialist:
            data?.overrideCalledByFinancialSpecialist?.called_by_financial_specialist,
        },
      });
      toast.success('Deal marked as seen.');
    } catch (error) {
      logger.error(
        'OverrideSeenDealButton.tsx',
        'Failed set called_by_financial_specialist',
        null,
        error,
      );
    }
  };

  return (
    <Button
      size="lgWithIconLeft"
      leftIcon={<BsCheck2All size="20px" />}
      isLoading={loading}
      onClick={handleClicked}
    >
      Mark Seen
    </Button>
  );
};

export default OverrideSeenDealButton;
