import { Dispatch, useEffect } from 'react';

import { Box, Flex, IconButton, StackProps, Text } from '@chakra-ui/react';
import { GoTriangleDown } from 'react-icons/go';
import { MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

import { FiltersAction, FiltersActionKind } from '../Titling/utils';
import CustomReactSelect from '../shared/CustomReactSelect';

import { FIRST_PAGE, PaginationControls } from '../../hooks/usePaginationControls';

interface PaginationProps extends StackProps {
  paginationControls: PaginationControls;
  dispatchFilters?: Dispatch<FiltersAction>;
}

const Pagination = ({ dispatchFilters, paginationControls, ...rest }: PaginationProps) => {
  const {
    itemsPerPage,
    setItemsPerPage,
    currentPage,
    maxPage,
    totalRecords,
    nextPage,
    prevPage,
    setCurrentPage,
    itemsPerPageOptions,
  } = paginationControls;

  useEffect(() => {
    if (totalRecords <= 0) {
      return;
    }

    const actualPage = currentPage * itemsPerPage > totalRecords ? FIRST_PAGE : currentPage;

    if (dispatchFilters) {
      dispatchFilters({
        type: FiltersActionKind.SET_CURRENT_PAGE,
        payload: actualPage,
      });
      dispatchFilters({
        type: FiltersActionKind.SET_ITEMS_PER_PAGE,
        payload: itemsPerPage,
      });
    }

    setItemsPerPage(itemsPerPage);
    setCurrentPage(actualPage);
  }, [totalRecords, currentPage, itemsPerPage]);

  return (
    <Flex
      bgColor="gray.100"
      borderBottomRadius="10px"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="center"
      py={2}
      px={4}
      fontSize={14}
      {...rest}
    >
      <Flex alignItems="center" gap={2} w="150px">
        <Text flexShrink="0">Per Page</Text>
        <CustomReactSelect
          value={{ label: itemsPerPage, value: itemsPerPage }}
          defaultValue={{
            label: Math.min(...itemsPerPageOptions),
            value: Math.min(...itemsPerPageOptions),
          }}
          onChange={(val) => setItemsPerPage(val?.value ?? 0)}
          components={{
            DropdownIndicator: () => (
              <Box color="primary" mt={2} mr={2}>
                <GoTriangleDown size={20} />
              </Box>
            ),
          }}
          options={itemsPerPageOptions.map((pageSize) => ({
            label: pageSize,
            value: pageSize,
          }))}
        />
      </Flex>

      <Flex justifyContent="center" gap={3} minW="300px">
        <Flex gap={2}>
          <IconButton
            variant="iconHover"
            color="oxfordBlue"
            aria-label="first-page"
            onClick={() => setCurrentPage(FIRST_PAGE)}
            isDisabled={currentPage === FIRST_PAGE}
            icon={<MdFirstPage />}
            fontSize="36px"
          />
          <IconButton
            variant="iconHover"
            color="oxfordBlue"
            aria-label="previous"
            onClick={prevPage}
            isDisabled={currentPage === FIRST_PAGE}
            icon={<MdNavigateBefore />}
            fontSize="36px"
          />
        </Flex>
        <Flex alignItems="center">
          <Text flexShrink="0" mx={4}>
            {`${currentPage + 1} of ${maxPage}`}
          </Text>
        </Flex>
        <Flex gap={2}>
          <IconButton
            variant="iconHover"
            color="oxfordBlue"
            aria-label="next"
            onClick={nextPage}
            isDisabled={currentPage === maxPage - 1}
            icon={<MdNavigateNext />}
            fontSize="36px"
          />
          <IconButton
            variant="iconHover"
            color="oxfordBlue"
            aria-label="last-page"
            onClick={() => setCurrentPage(maxPage - 1)}
            isDisabled={currentPage === maxPage - 1}
            icon={<MdLastPage />}
            fontSize="36px"
          />
        </Flex>
      </Flex>

      <Flex alignItems="center" w="25%" minW="120px" justifyContent="flex-end">
        <Text>Total Records: {totalRecords}</Text>
      </Flex>
    </Flex>
  );
};

export default Pagination;
