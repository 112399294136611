import { Deal } from '../gql/dealGql';

enum NotificationType {
  NEW_NOTE = 'NEW_NOTE',
  DEAL_STATE_UPDATE = 'DEAL_STATE_UPDATE',
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED', // Used for notification toasts for texts, and everything in the text widget
  DEAL_SEEN = 'DEAL_SEEN',
  CALL_RECEIVED = 'CALL_RECEIVED',
  CALL_MADE = 'CALL_MADE',
  CALL_RINGING = 'CALL_RINGING',
}

export const notificationMessageMap = {
  [NotificationType.NEW_NOTE]: 'Note Added',
  [NotificationType.DEAL_STATE_UPDATE]: 'Deal State Updated',
  [NotificationType.MESSAGE_RECEIVED]: 'Text Message Received',
  [NotificationType.CALL_RECEIVED]: 'Call Recieved',
  [NotificationType.CALL_MADE]: 'Call Made',
  [NotificationType.CALL_RINGING]: 'Call Ringing',
};

export type Notification = {
  id: number;
  user_id: string;
  deal_id: number;
  seen: boolean;
  message: string;
  created_at: string;
  creation_date_tz: string;
  deal?: Deal;
};

type NotificationCounts = {
  unseenMessages: number;
  messages: number;
  notMessages: number;
  total: number;
};

export const getDealNotificationCountByType = <T extends Pick<Deal, 'notifications'>>(
  deal?: T,
): NotificationCounts => {
  return (deal?.notifications ?? []).reduce(
    (notificationCounts, currentNotification) => {
      const newNotificationsCount = { ...notificationCounts };
      switch (currentNotification.message) {
        case notificationMessageMap.MESSAGE_RECEIVED:
          newNotificationsCount.messages += 1;
          if (!currentNotification.seen) {
            newNotificationsCount.unseenMessages += 1;
          }
          break;
        default:
          newNotificationsCount.notMessages += 1;
          break;
      }
      newNotificationsCount.total += 1;

      return newNotificationsCount;
    },
    {
      unseenMessages: 0,
      messages: 0,
      notMessages: 0,
      total: 0,
    } as NotificationCounts,
  );
};

export const getDealsNotificationCountByType = (deals: Deal[]): NotificationCounts =>
  deals.reduce(
    (notificationsCount: NotificationCounts, currentDeal: Deal) => {
      const currentNotificationCount = getDealNotificationCountByType(currentDeal);
      const newNotificationsCount = notificationsCount;
      newNotificationsCount.unseenMessages += currentNotificationCount.unseenMessages;
      newNotificationsCount.messages += currentNotificationCount.messages;
      newNotificationsCount.notMessages += currentNotificationCount.notMessages;
      newNotificationsCount.total += currentNotificationCount.total;

      return newNotificationsCount;
    },
    {
      unseenMessages: 0,
      messages: 0,
      notMessages: 0,
      total: 0,
    },
  );
