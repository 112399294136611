import { Dispatch, useMemo, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { Deal } from '../../../gql/dealGql';
import { PodUser, SortDirection, useTitlingUsersQuery } from '../../../gql/generated/graphql';

import LETable from '../../shared/Table/LETable';
import { FiltersActionKind, SortingAction, SortingActionKind } from '../utils';

import TableFilters from './TableFilters';
import { TITLE_TRACKER_COLUMNS } from './constants';

import { PaginationControls } from '../../../hooks/usePaginationControls';
import { CombinedFilterActions } from '../../../hooks/useUrlQueryParamsWithMultipleReducers';
import { PodUsersByPod } from '../../../types/user';
import { groupTitlingUsersByPods } from '../../../utils/pods';

type TitleTableProps = {
  deals: Deal[];
  maxAge: string | undefined;
  loading: boolean;
  dispatchFilters: Dispatch<CombinedFilterActions>;
  dispatchSorting: Dispatch<SortingAction>;
  paginationControls: PaginationControls;
};

const TitleTable = ({
  deals,
  maxAge,
  dispatchSorting,
  dispatchFilters,
  loading,
  paginationControls,
}: TitleTableProps) => {
  const [sortColumn, setSortColumn] = useState('completion_date_utc');
  const [sortDirection, setSortDirection] = useState(SortDirection.Asc);

  const { data } = useTitlingUsersQuery();

  // Typed because keys can also be the pod id
  const titlingUsersByPod: PodUsersByPod = useMemo(() => {
    if (!data?.titlingUsers) {
      return {} as PodUsersByPod;
    }

    return groupTitlingUsersByPods(data.titlingUsers as PodUser[]);
  }, [data?.titlingUsers]);

  useMemo(() => {
    // Sorting Column
    dispatchSorting({
      type: SortingActionKind.SET_FIELD,
      payload: sortColumn,
    });
    dispatchFilters({
      type: FiltersActionKind.SET_FIELD,
      payload: sortColumn,
    });

    // Sorting Direction
    dispatchSorting({
      type: SortingActionKind.SET_DIRECTION,
      payload: sortDirection,
    });
    dispatchFilters({
      type: FiltersActionKind.SET_DIRECTION,
      payload: sortDirection,
    });

    // Pagination
    dispatchFilters({
      type: FiltersActionKind.SET_CURRENT_PAGE,
      payload: paginationControls.currentPage,
    });
    dispatchFilters({
      type: FiltersActionKind.SET_ITEMS_PER_PAGE,
      payload: paginationControls.itemsPerPage,
    });
  }, [sortColumn, sortDirection, paginationControls.currentPage, paginationControls.itemsPerPage]);

  return (
    <Box overflowX="auto">
      <LETable
        columns={TITLE_TRACKER_COLUMNS}
        items={[...deals]}
        rowKeyPrefix="title"
        rowKeyProp="id"
        componentData={{ titlingUsersByPod }}
        isLoading={loading}
        sortProps={{
          sortColumn,
          setSortColumn,
          sortDirection,
          setSortDirection,
        }}
        paginationControls={paginationControls}
        redirectToDeal
      >
        <Flex
          py={2}
          w="full"
          overflowX="auto"
          sx={{
            '::-webkit-scrollbar': {
              height: '4px',
            },
            '::-webkit-scrollbar-track': {
              background: 'oxfordBlue',
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: 'cadetBlue',
              borderRadius: '4px',
            },
          }}
        >
          <TableFilters maxAge={maxAge} titlingPodUsers={titlingUsersByPod.active ?? []} />
        </Flex>
      </LETable>
    </Box>
  );
};

export default TitleTable;
