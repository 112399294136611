import { Dispatch, SetStateAction, useMemo } from 'react';

import { Button } from '@chakra-ui/react';
import Big from 'big.js';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  CommissionType,
  PodInput,
  TeamType,
  useGetUsersQuery,
  usePodUpsertAndPodUsersUpdateMutation,
} from '../../../gql/generated/graphql';
import { Pod, TeamTypeDisplay } from '../../../gql/podGql';

import Modal from '../../../components/shared/Modal';
import {
  WorkingHours,
  getWorkingHoursFields,
  getWorkingHoursString,
  podValidationSchema,
} from '../utils';

import PodModalForm from './PodModalForm';

import { PodModalFormRoles, getAuth0RoleNameForTeam } from '../../../utils/pods';
import { filterUsersByRole } from '../../../utils/user';

export interface PodModalFormikFields {
  pod: Pod & {
    selectedTitleClerkLeads: { id: string; name: string }[];
    selectedTitleClerks: { id: string; name: string }[];
    selectedClosers: { id: string; name: string }[];
    selectedSetterLeads: { id: string; name: string }[];
    selectedSetters: { id: string; name: string }[];
    managerCommissionIsFlat?: boolean;
    specialCommissionIsFlat?: boolean;
    closerCommissionIsFlat?: boolean;
    setterCommissionIsFlat?: boolean;
  };
  workingHours: WorkingHours;
}

interface PodModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPod?: Pod | null;
  setSelectedPod: Dispatch<SetStateAction<Pod | null>>;
  regionPods: Pod[];
}

const PodModal = ({ isOpen, onClose, selectedPod, setSelectedPod, regionPods }: PodModalProps) => {
  const { data: getUsersData } = useGetUsersQuery();
  const [podUpsertAndPodUsersUpdate, { loading }] = usePodUpsertAndPodUsersUpdateMutation();

  const users = getUsersData?.getUsers?.results ?? [];

  const handleCloseModal = () => {
    onClose();
    setSelectedPod(null);
  };

  const initialValues = useMemo(() => {
    const selectedTitleClerks = selectedPod?.usersWithoutTeamRole || [];
    const selectedTitleClerkLeads = selectedPod?.leads || [];

    let selectedClosers: {
      id: string;
      name: string;
    }[] = [];
    if (selectedPod?.team_type) {
      // Financial Specialist is the same as Closer
      const financialSpecialists = filterUsersByRole(
        users,
        getAuth0RoleNameForTeam(selectedPod.team_type, PodModalFormRoles.Closer),
      );

      selectedClosers = (selectedPod?.usersWithoutTeamRole || []).filter((u) =>
        financialSpecialists.map((closer) => closer?.id).includes(u.id),
      );
    }

    const selectedSetterLeads = selectedPod?.leads || [];
    const selectedSetters = (selectedPod?.usersWithoutTeamRole || []).filter(
      (user) => !selectedClosers.map((closer) => closer.id).includes(user.id),
    );

    const managerCommissionIsFlat = selectedPod?.manager_commission_type === CommissionType.Flat;
    const specialCommissionIsFlat = selectedPod?.special_commission_type === CommissionType.Flat;
    const closerCommissionIsFlat = selectedPod?.closer_commission_type === CommissionType.Flat;
    const setterCommissionIsFlat = selectedPod?.setter_commission_type === CommissionType.Flat;

    const fields: PodModalFormikFields = {
      pod: {
        ...(selectedPod || new Pod()),
        team_type: selectedPod?.team_type || undefined,
        managers: selectedPod?.managers || [],
        selectedTitleClerkLeads,
        selectedTitleClerks,
        selectedClosers,
        selectedSetterLeads,
        selectedSetters,
        manager_commission_rate: managerCommissionIsFlat
          ? selectedPod?.manager_commission_rate || 0
          : new Big(selectedPod?.manager_commission_rate || 0).times(100).toNumber(),
        special_commission_rate: specialCommissionIsFlat
          ? selectedPod?.special_commission_rate || 0
          : new Big(selectedPod?.special_commission_rate || 0).times(100).toNumber(),
        closer_commission_rate: closerCommissionIsFlat
          ? selectedPod?.closer_commission_rate || 0
          : new Big(selectedPod?.closer_commission_rate || 0).times(100).toNumber(),
        setter_commission_rate: setterCommissionIsFlat
          ? selectedPod?.setter_commission_rate || 0
          : new Big(selectedPod?.setter_commission_rate || 0).times(100).toNumber(),
        managerCommissionIsFlat,
        specialCommissionIsFlat,
        closerCommissionIsFlat,
        setterCommissionIsFlat,
        manager_commission_type: selectedPod?.manager_commission_type || CommissionType.Percentage,
        special_commission_type: selectedPod?.special_commission_type || CommissionType.Percentage,
        closer_commission_type: selectedPod?.closer_commission_type || CommissionType.Percentage,
        setter_commission_type: selectedPod?.setter_commission_type || CommissionType.Percentage,
        us_states_object: selectedPod?.us_states_object || { states: [] },
      },
      workingHours: getWorkingHoursFields(selectedPod?.hours),
    };
    return fields;
  }, [selectedPod]);

  const onSave = async (values: PodModalFormikFields, formikHelpers: { resetForm: () => void }) => {
    const oldPod = selectedPod;
    const newPod: PodInput = {
      archived: values.pod.archived,
      color: values.pod.color,
      created_at: values.pod.created_at,
      hours: getWorkingHoursString(values.workingHours),
      id: values.pod.id,
      leads: values.pod.leads,
      managers: values.pod.managers,
      name: values.pod.name,
      parent_pod: values.pod.parent_pod,
      parent_pod_id: values.pod.parent_pod_id,
      manager_commission_rate: values.pod.managerCommissionIsFlat
        ? values.pod.manager_commission_rate || 0
        : new Big(values.pod.manager_commission_rate || 0).div(100).toNumber(),
      special_commission_rate: values.pod.specialCommissionIsFlat
        ? values.pod.special_commission_rate || 0
        : new Big(values.pod.special_commission_rate || 0).div(100).toNumber(),
      closer_commission_rate: values.pod.closerCommissionIsFlat
        ? values.pod.closer_commission_rate || 0
        : new Big(values.pod.closer_commission_rate || 0).div(100).toNumber(),
      setter_commission_rate: values.pod.setterCommissionIsFlat
        ? values.pod.setter_commission_rate || 0
        : new Big(values.pod.setter_commission_rate || 0).div(100).toNumber(),
      manager_commission_type: values.pod.manager_commission_type,
      special_commission_type: values.pod.special_commission_type,
      closer_commission_type: values.pod.closer_commission_type,
      setter_commission_type: values.pod.setter_commission_type,
      team_type: values.pod.team_type,
      updated_at: values.pod.updated_at,
      usersWithoutTeamRole: values.pod.usersWithoutTeamRole,
      vsc_markup: values.pod.vsc_markup,
      vsc_multiplier: values.pod.vsc_multiplier,
      us_states_object:
        values.pod.team_type === TeamType.Titling ? values.pod.us_states_object : null,
    };

    try {
      await podUpsertAndPodUsersUpdate({
        variables: { newPod, oldPod },
      });
    } catch {
      toast.error(`Failed to ${selectedPod?.id ? 'update' : 'create'} team`);
      return;
    }

    const toastMessage = selectedPod?.id
      ? `${
          newPod.team_type === TeamType.Region
            ? TeamTypeDisplay[newPod.team_type]
            : `${TeamTypeDisplay[newPod.team_type as TeamType]} Team`
        } Updated Successfully`
      : `New ${
          newPod.team_type === TeamType.Region
            ? TeamTypeDisplay[newPod.team_type]
            : `${TeamTypeDisplay[newPod.team_type as TeamType]} Team`
        } Created Successfully`;

    toast.success(toastMessage);

    handleCloseModal();
    formikHelpers.resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={podValidationSchema}
      onSubmit={onSave}
      enableReinitialize
    >
      {({ isValid, resetForm, values }) => {
        return (
          <Modal
            title={selectedPod?.id ? 'Edit Team' : 'Add New Team'}
            size={{ base: 'dynamicFull', md: 'xl' }}
            variant="noMargin"
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              handleCloseModal();
              resetForm();
            }}
            leftButtons={
              <Button
                variant="warning"
                onClick={() => {
                  handleCloseModal();
                  resetForm();
                }}
              >
                CANCEL
              </Button>
            }
            rightButtons={
              <Button
                variant="primary"
                type="submit"
                isDisabled={!isValid}
                isLoading={loading}
                onClick={() => onSave(values, { resetForm })}
              >
                {selectedPod?.id ? 'SAVE CHANGES' : 'ADD TEAM'}
              </Button>
            }
          >
            <PodModalForm users={users} regionPods={regionPods} />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default PodModal;
