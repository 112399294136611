import { gql } from '@apollo/client';

import { InboxOrderByEnum } from './generated/graphql';

import { LE_TIME_ZONE } from '../constants/LeaseEndInfo';
import { Notification } from '../constants/notifications';
import {
  formatDateNoYearWithTime,
  formatDateWithTime,
  formatPhoneNumberForDisplay,
  formatTime,
  truncateString,
} from '../libs/utils';
import { getMinutesAgo } from '../utils/dates';

import { getFullNameWithInitial } from './customerGql';
import { Deal, DealStateEnum } from './dealGql';

export interface MediaListData {
  url: string;
  contentType?: string;
}

export interface MediaListObj {
  data: MediaListData[];
}

export interface Message {
  id: string;
  fromCustomer: boolean;
  body: string;
  senderName: string;
  dateCreated?: string;
  dateCreatedTz?: string;
  from: string;
  deals?: Deal[];
  notifications?: Notification[];
  groupKey?: string;
  isFirstInGroup?: boolean;
  mediaListObj?: MediaListObj;
}

export const messagesQuery = gql`
  query messages($dealPhoneNumber: String) {
    messages(dealPhoneNumber: $dealPhoneNumber) {
      messages {
        id
        fromCustomer
        body
        senderName
        dateCreated
        mediaListObj {
          data {
            url
            contentType
          }
        }
      }
      hasErrors
    }
  }
`;

export const messagesDbQueryPaginated = gql`
  query messagesDbPaginated($dealPhoneNumber: String!, $dealId: String!, $page: Int!) {
    messagesDbPaginated(dealPhoneNumber: $dealPhoneNumber, dealId: $dealId, page: $page) {
      hasMoreMessages
      messages {
        id
        fromCustomer
        body
        senderName
        dateCreated
        mediaListObj {
          data {
            url
            contentType
          }
        }
      }
    }
  }
`;

export const messageSend = gql`
  mutation messageSend($message: String, $dealPhoneNumber: String, $id: ID) {
    messageSend(message: $message, dealPhoneNumber: $dealPhoneNumber, id: $id) {
      fromCustomer
      body
      mediaListObj {
        data {
          url
          contentType
        }
      }
      senderName
      dateCreated
    }
  }
`;

export const fileSend = gql`
  mutation fileSend($file: String, $dealPhoneNumber: String, $id: ID!) {
    fileSend(file: $file, dealPhoneNumber: $dealPhoneNumber, id: $id) {
      fromCustomer
      body
      mediaListObj {
        data {
          url
          contentType
        }
      }
    }
  }
`;

export const onNewMessage = gql`
  subscription onNewMessage($dealPhoneNumbers: [String]) {
    onNewMessage(dealPhoneNumbers: $dealPhoneNumbers) {
      fromCustomer
      body
      dateCreated
      mediaListObj {
        data {
          url
          contentType
        }
      }
      from
      deals {
        id
        state
        customer {
          first_name
          last_name
          phone_number
        }
        cobuyer {
          first_name
          last_name
          phone_number
        }
        pod_id
        titling_pod_id
        setter_id
        closer_id
        closer2_id
        funding_clerk_id
        title_clerk_id
        title_clerk2_id
      }
    }
  }
`;

export const onReceivedMessage = gql`
  subscription onReceivedMessage(
    $dealPhoneNumbers: [String]
    $isNotification: Boolean
    $isWidgetOpen: Boolean
    $activePhoneNumber: String
  ) {
    onReceivedMessage(
      dealPhoneNumbers: $dealPhoneNumbers
      isNotification: $isNotification
      isWidgetOpen: $isWidgetOpen
      activePhoneNumber: $activePhoneNumber
    ) {
      message {
        id
        fromCustomer
        body
        dateCreated
        mediaListObj {
          data {
            url
            contentType
          }
        }
        from
      }
      deals {
        id
        state
        notification_subscribers {
          id
          deal_id
          subscriber_id
        }
        car {
          id
          make
          model
          year
        }
        customer {
          first_name
          last_name
          phone_number
        }
        cobuyer {
          first_name
          last_name
          phone_number
          relation_to_buyer
        }
        contact {
          first_name
          last_name
          phone_number
          relation_to_buyer
        }
        second_contact {
          first_name
          last_name
          phone_number
          relation_to_buyer
        }
      }
      messageDetail {
        shortNames
        dealIds
        dealStates
      }
    }
  }
`;

export const templateTextQuery = gql`
  query templateTexts {
    templateTexts {
      id
      description
      text
      key
    }
  }
`;

export const inboxQuery = gql`
  query inbox(
    $filterBy: inboxFilterByEnum
    $podIds: [ID]
    $orderBy: inboxOrderByEnum
    $unreadOnly: Boolean
    $page: Int
    $itemsPerPage: Int
  ) {
    inbox(
      filterBy: $filterBy
      podIds: $podIds
      orderBy: $orderBy
      unreadOnly: $unreadOnly
      page: $page
      itemsPerPage: $itemsPerPage
    ) {
      messages {
        id
        from
        fromCustomer
        body
        dateCreated
        dateCreatedTz
        mediaListObj {
          data {
            url
            contentType
          }
        }
        notifications {
          id
          seen
          deal {
            id
            state
            car {
              id
              year
              make
              model
            }
            customer {
              id
              first_name
              last_name
              phone_number
            }
            cobuyer {
              id
              first_name
              last_name
              phone_number
            }
            contact {
              id
              first_name
              last_name
              phone_number
            }
            second_contact {
              id
              first_name
              last_name
              phone_number
            }
            setter {
              id
              name
              pods {
                name
                color
              }
            }
            closer {
              id
              name
              pods {
                name
                color
              }
            }
            closer2 {
              id
              name
              pods {
                name
                color
              }
            }
            funding_clerk {
              id
              name
              pods {
                name
                color
              }
            }
            title_clerk {
              id
              name
              titlingPods {
                name
                color
              }
            }
            title_clerk2 {
              id
              name
              titlingPods {
                name
                color
              }
            }
          }
        }
      }
      totalRecords
    }
  }
`;

export const getTimeString = (message: Message) => {
  try {
    const dateCreatedString = message.dateCreated
      ? formatDateWithTime(message.dateCreated, message.dateCreatedTz ?? LE_TIME_ZONE)
      : null;
    const dateCreated = dateCreatedString ? new Date(dateCreatedString) : null;

    const minutesAgo = getMinutesAgo(dateCreated);

    if (minutesAgo !== null && minutesAgo < 60) {
      return minutesAgo <= 1 ? 'Just now' : `${minutesAgo} minutes ago`;
    }

    const isToday = dateCreated ? new Date().toDateString() === dateCreated.toDateString() : false;

    return (
      dateCreated
        ? isToday
          ? formatTime(dateCreated.toISOString())
          : formatDateNoYearWithTime(dateCreated.toISOString())
        : ''
    ).toLowerCase();
  } catch (e) {
    return '';
  }
};

export const getFromString = (message: Message, deal: Deal) => {
  let fullName = '';
  let phoneNumber = '';

  const messageFrom =
    message.from && message.from.startsWith('+1')
      ? formatPhoneNumberForDisplay(message.from)
      : message.from ?? '';
  if (deal.customer?.phone_number && messageFrom === deal.customer.phone_number) {
    fullName = getFullNameWithInitial(deal.customer);
    phoneNumber = deal.customer.phone_number;
  } else if (deal.cobuyer?.phone_number && messageFrom === deal.cobuyer.phone_number) {
    fullName = getFullNameWithInitial(deal.cobuyer);
    phoneNumber = deal.cobuyer.phone_number;
  } else if (deal.contact?.phone_number && messageFrom === deal.contact.phone_number) {
    fullName = getFullNameWithInitial(deal.contact);
    phoneNumber = deal.contact.phone_number;
  } else if (
    deal.second_contact?.phone_number &&
    messageFrom === deal.second_contact.phone_number
  ) {
    fullName = getFullNameWithInitial(deal.second_contact);
    phoneNumber = deal.second_contact.phone_number;
  } else {
    fullName = '';
    phoneNumber = messageFrom;
  }

  return truncateString(`${fullName ? `${fullName} - ` : ''}${phoneNumber}`, 30);
};

export const getGroupKey = (orderBy: InboxOrderByEnum, message: Message) => {
  const isOrderByDeal = orderBy === InboxOrderByEnum.Deal;
  const isOrderByDate =
    orderBy === InboxOrderByEnum.DateNewest || orderBy === InboxOrderByEnum.DateOldest;

  if (isOrderByDeal) {
    const dealId = (message.deals?.length ? message.deals[0] : new Deal()).id as unknown as string;
    return `Deal ${dealId}`;
  }

  const dateCreatedString = message.dateCreated
    ? formatDateWithTime(message.dateCreated, message.dateCreatedTz ?? LE_TIME_ZONE)
    : null;
  const dateCreated = dateCreatedString ? new Date(dateCreatedString) : null;
  const isToday = dateCreated ? new Date().toDateString() === dateCreated.toDateString() : false;
  const isYesterday = dateCreated
    ? new Date(new Date().getTime() - 86400000).toDateString() === dateCreated.toDateString()
    : false;

  if (isOrderByDate) {
    return isToday
      ? 'Today'
      : isYesterday
      ? 'Yesterday'
      : dateCreated
      ? dateCreated.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
        })
      : '';
  }

  return '';
};

// keep in sync with the backend
export const SetterNotificationStates = [
  DealStateEnum.Booted,
  DealStateEnum.Estimate,
  DealStateEnum.Floor,
  DealStateEnum.SoftClose,
];
// keep in sync with the backend
export const CloserNotificationStates = [
  DealStateEnum.Booted,
  DealStateEnum.Estimate,
  DealStateEnum.Floor,
  DealStateEnum.SoftClose,
  DealStateEnum.Structuring,
  DealStateEnum.StructuringInProgress,
  DealStateEnum.Closing,
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
];
// keep in sync with the backend
export const FundingNotificationStates = [
  DealStateEnum.Booted,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
  DealStateEnum.Funded,
  DealStateEnum.SendPayoff,
];
// keep in sync with the backend
export const TitlingNotificationStates = [
  DealStateEnum.Booted,
  DealStateEnum.Signed,
  DealStateEnum.Funded,
  DealStateEnum.SendPayoff,
  DealStateEnum.SentToProcessor,
  DealStateEnum.WaitingForTitle,
  DealStateEnum.TitleSent,
  DealStateEnum.TitleReceived,
];

export const CustomerServiceNotificationStates = [DealStateEnum.Finalized];
