import { useCallback, useContext } from 'react';

import { Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { BiEditAlt } from 'react-icons/bi';
import { MdAccountBalance } from 'react-icons/md';

import CreditDecisionsModal from './CreditDecisionsModal';

import { ModalContext } from '../../libs/contextLib';

type CreditDecisionsButtonProps = {
  isEditingWithPermission: boolean;
  isIconButton?: boolean;
};

const CreditDecisionsButton = ({
  isEditingWithPermission,
  isIconButton,
}: CreditDecisionsButtonProps) => {
  const modalOptions = useDisclosure();
  const { setModal } = useContext(ModalContext);

  const handleClick = useCallback(() => {
    modalOptions.onOpen();
    setModal({ CreditDecisions: true });
  }, []);

  return (
    <>
      {isIconButton ? (
        <IconButton
          icon={<BiEditAlt />}
          variant="iconHover"
          size="xs"
          aria-label="Credit Decisions"
          onClick={handleClick}
        />
      ) : (
        <Button
          variant="secondary"
          size="smWithIconLeft"
          leftIcon={<MdAccountBalance fontSize={16} />}
          onClick={handleClick}
        >
          Credit Decisions
        </Button>
      )}
      {modalOptions.isOpen && (
        <CreditDecisionsModal isEditingWithPermission={isEditingWithPermission} {...modalOptions} />
      )}
    </>
  );
};

export default CreditDecisionsButton;
