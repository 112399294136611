import { Flex, Link } from '@chakra-ui/react';

import { Deal } from '../../../gql/generated/graphql';

import ShippingLabelButton from '../../MediaCenter/components/ShippingLabels';
import { TableColumns } from '../../shared/Table/types';

import SendTempTags from './SendTempTags';

import { formatDateIfTrue } from '../../../libs/utils';

enum TempTagColumnHeaders {
  CustomerName = 'Customer Name',
  UsState = 'US State',
  RegExp = 'Reg Exp',
  TagsExp = 'Tags Exp',
  TagsSent = 'Tags Sent',
  TrackingNum = 'Tracking #',
  FinancialSpecialist = 'Financial Specialist',
  Assigned = 'Assigned',
  Actions = 'Actions',
}

export const TEMP_TAG_COLUMNS: TableColumns<Deal> = [
  {
    header: TempTagColumnHeaders.CustomerName,
    getComponent: ({ item: deal }) => `${deal.customer?.first_name} ${deal.customer?.last_name}`,
    sortingKey: 'customer.first_name',
  },
  {
    header: TempTagColumnHeaders.UsState,
    getComponent: ({ item: deal }) => deal.customer?.address?.state,
    sortingKey: 'customer.address.state',
  },
  {
    header: TempTagColumnHeaders.RegExp,
    getComponent: ({ item: deal }) => formatDateIfTrue(deal.car?.registration_expiration) || '-',
    sortingKey: 'car.registration_expiration',
  },
  {
    header: TempTagColumnHeaders.TagsExp,
    getComponent: ({ item: deal }) =>
      formatDateIfTrue(
        deal.deal_dates?.custom_dates?.temporary_registration_tags_expiration_date,
      ) || '-',
    sortingKey: 'deal_dates.custom_dates.temporary_registration_tags_expiration_date',
  },
  {
    header: TempTagColumnHeaders.TagsSent,
    getComponent: ({ item: deal }) =>
      formatDateIfTrue(deal.deal_dates?.custom_dates?.temporary_registration_tags_sent_date) || '-',
    sortingKey: 'deal_dates.custom_dates.temporary_registration_tags_sent_date',
  },
  {
    header: TempTagColumnHeaders.TrackingNum,
    getComponent: ({ item: deal }) =>
      deal.shipping_labels?.[0]?.tracking_number && deal.shipping_labels?.[0]?.tracker_url ? (
        <Link
          href={deal.shipping_labels[0].tracker_url}
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          rel="noreferrer"
          fontWeight="bold"
          color="mediumElectricBlue"
        >
          {deal.shipping_labels[0].tracking_number}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    header: TempTagColumnHeaders.FinancialSpecialist,
    getComponent: ({ item: deal }) => deal.closer?.name,
    sortingKey: 'closer.name',
  },
  {
    header: TempTagColumnHeaders.Assigned,
    getComponent: ({ item: deal }) => deal.temporary_registration_tags_user?.name || '-',
    sortingKey: 'temporary_registration_tags_user.name',
  },
  {
    header: TempTagColumnHeaders.Actions,
    styleProps: { width: '100px' },
    getComponent: ({ item: deal, componentData }) => (
      <Flex>
        <ShippingLabelButton
          dealId={deal.id}
          componentType="icon"
          refetchTempTagsQueue={componentData?.refetch}
        />
        <SendTempTags deal={deal} />
      </Flex>
    ),
  },
];

export const TEMP_TAGS_QUEUE_DEFAULT_SORT_COLUMN = 'car.registration_expiration';
