import { useMemo, useRef } from 'react';

import { Box, Button, Flex, FormLabel } from '@chakra-ui/react';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';

import {
  UpdateUserSettingsMutationVariables,
  User,
  useUpdateUserSettingsMutation,
} from '../../gql/generated/graphql';

import Modal from '../../components/shared/Modal';
import NumberInput from '../../components/shared/NumberInput';
import Switch from '../../components/shared/Switch';

import WorkingHoursTable from './WorkingHoursTable/WorkingHoursTable';
import {
  UserSettingsFormikFields,
  getWorkingHoursFields,
  getWorkingHoursString,
  userSettingsValidationSchema,
} from './utils';

import { MIN_MAX_AUTO_ASSIGN_DEALS } from '../../constants/user';
import { logger } from '../../libs/Logger';

interface UserSettingsModalProps {
  user: User | null;
  isOpen: boolean;
  onClose: () => void;
  refetchUsers: () => Promise<unknown>;
}
const UserSettingsModal = ({ user, isOpen, onClose, refetchUsers }: UserSettingsModalProps) => {
  const formRef = useRef<FormikProps<UserSettingsFormikFields>>(null);

  const [updateUserSettings, { loading }] = useUpdateUserSettingsMutation();

  const initialValues = useMemo(
    (): UserSettingsFormikFields => ({
      workingHours: getWorkingHoursFields(user?.hours ?? undefined),
      on_vacation: user?.on_vacation ?? false,
      auto_assign_deals: user?.auto_assign_deals ?? false,
      overnight_deals: user?.overnight_deals ?? false,
      max_auto_assign_deals: user?.max_auto_assign_deals ?? MIN_MAX_AUTO_ASSIGN_DEALS,
    }),
    [user],
  );

  const handleClose = () => {
    onClose();
    formRef.current?.resetForm();
  };

  const onSubmit = async (values: UserSettingsFormikFields) => {
    const variables: UpdateUserSettingsMutationVariables = {
      id: user?.id ?? '',
      hours: getWorkingHoursString(values.workingHours),
      on_vacation: values.on_vacation,
      auto_assign_deals: values.auto_assign_deals,
      overnight_deals: values.overnight_deals,
      max_auto_assign_deals: values.max_auto_assign_deals,
    };

    await updateUserSettings({
      variables,
      onCompleted: async () => {
        toast.success('User updated successfully');
        handleClose();
        await refetchUsers();
      },
      onError: (error) => {
        logger.error(
          'src/pages/Pods/Components/UserSettingsModal.tsx',
          'updateUserHoursAndOnVacation',
          variables,
          error,
        );
        toast.error(`Error updating User: ${error.message}`);
      },
    });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={userSettingsValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, isValid, resetForm }) => {
        return (
          <Modal
            title={`${user?.name ?? 'User'} Settings`}
            size={{ base: 'dynamicFull', md: 'xl' }}
            variant="noMargin"
            isOpen={isOpen}
            onClose={() => {
              handleClose();
              resetForm();
            }}
            canDismiss={!loading}
            leftButtons={
              <Button
                variant="warning"
                onClick={() => {
                  handleClose();
                  resetForm();
                }}
                isDisabled={loading}
              >
                CANCEL
              </Button>
            }
            rightButtons={
              <Button
                onClick={() => onSubmit(values)}
                isLoading={loading}
                isDisabled={!isValid}
                loadingText="SAVE CHANGES"
              >
                SAVE CHANGES
              </Button>
            }
          >
            <Flex direction="column" gap={2} p={2}>
              <Flex justifyContent="space-between" gap={2}>
                <FormLabel fontSize="sm">Auto Assign Deals</FormLabel>
                <Box>
                  <Switch
                    isNoYes
                    noLabel="Off"
                    yesLabel="On"
                    size="sm"
                    noYesFontSize="sm"
                    name="auto_assign_deals"
                  />
                </Box>
              </Flex>
              <Flex justifyContent="space-between">
                <FormLabel fontSize="sm">Overnight Deals</FormLabel>
                <Box>
                  <Switch
                    isNoYes
                    noLabel="Off"
                    yesLabel="On"
                    size="sm"
                    noYesFontSize="sm"
                    name="overnight_deals"
                  />
                </Box>
              </Flex>

              <Flex justifyContent="space-between">
                <FormLabel fontSize="sm">On Vacation</FormLabel>
                <Box>
                  <Switch
                    isNoYes
                    noLabel="Off"
                    yesLabel="On"
                    size="sm"
                    noYesFontSize="sm"
                    name="on_vacation"
                  />
                </Box>
              </Flex>
              {values.auto_assign_deals ? (
                <Flex justifyContent="space-between">
                  <FormLabel fontSize="sm">Individual Max</FormLabel>
                  <Box>
                    <NumberInput name="max_auto_assign_deals" width="24" isWhole />
                  </Box>
                </Flex>
              ) : null}
              {values.auto_assign_deals ? <WorkingHoursTable /> : null}
            </Flex>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default UserSettingsModal;
