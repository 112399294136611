import { useContext } from 'react';

import { HStack, Skeleton, Text, TextProps } from '@chakra-ui/react';

import { BankEnum } from '../../constants/bank';
import { LienholderNameEnum } from '../../constants/lienholders';
import { PermissionEnum } from '../../constants/permissions';
import { usePaymentOptions } from '../../hooks/usePaymentOptions';
import { AbilityContext } from '../../libs/contextLib';
import { formatMoney } from '../../libs/utils';
import { getCorrectProfitToDisplay } from '../../utils/financialInfos';

type CreditDecisionCommissionProps = {
  lienholder: LienholderNameEnum | undefined;
  isDenied?: boolean;
  bankName?: BankEnum;
  skeletonSize?: 'sm' | 'lg';
  fontSize?: TextProps['fontSize'];
  lenderFees?: number | null;
};

const CreditDecisionCommission = ({
  lienholder,
  bankName,
  isDenied,
  skeletonSize = 'lg',
  fontSize = 'sm',
  lenderFees,
}: CreditDecisionCommissionProps) => {
  const ability = useContext(AbilityContext);
  const canSeeCommission = ability.has(PermissionEnum.SeeCommission);
  const canSeeProfit = ability.has(PermissionEnum.SeeProfit);
  const shouldShowProfit = canSeeCommission && canSeeProfit;

  const [{ noProducts }, paymentOptionsLoading, isPaymentOptionsDebouncing] = usePaymentOptions({
    bankName,
    creditDecisionLenderFees: lenderFees,
  });

  const isPaymentOptionsLoading = paymentOptionsLoading || isPaymentOptionsDebouncing;
  const profitToDisplay = getCorrectProfitToDisplay(noProducts.profit, lienholder);

  return isPaymentOptionsLoading ? (
    <Skeleton h={6} w={shouldShowProfit ? (skeletonSize === 'lg' ? '30%' : '15%') : '15%'} />
  ) : (
    <HStack spacing={1}>
      {canSeeCommission && (
        <Text
          fontSize={fontSize}
          color="white"
          rounded="md"
          bg="green"
          py={0.5}
          px={2}
          {...(isDenied && { color: 'gray.200' })}
        >
          {formatMoney(noProducts.closerCommission)}
        </Text>
      )}
      {shouldShowProfit && (
        <Text
          fontSize={fontSize}
          color="white"
          rounded="md"
          bg="green"
          py={0.5}
          px={2}
          {...(isDenied && { color: 'gray.200' })}
        >
          {formatMoney(profitToDisplay)}
        </Text>
      )}
    </HStack>
  );
};

export default CreditDecisionCommission;
