import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
} from 'react';

import { Box, Flex, StyleProps, TabPanel, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal, isBeforeClosing } from '../../gql/dealGql';
import {
  Bank,
  useCreditDecisionApplicationNumberQuery,
  useGetCreditDecisionByR1FsidQuery,
} from '../../gql/generated/graphql';

import PrequalificationSection from '../CreditApplication/components/Prequalification/PrequalificationSection';
import CreditDecisionsButton from '../CreditDecisions/CreditDecisionsButton';
import EditDealInfoButton from '../EditDealInfoButton';
import { TotalPayoff } from '../TotalPayoff/TotalPayoff';
import NumberInput from '../shared/NumberInput';
import Switch from '../shared/Switch';
import { BankAndPaymentsSection } from './components/BankAndPaymentsSection';
import InputRow from './components/InputRow';
import PaymentOptions from './components/PaymentOptions/PaymentOptions';
import { TitleAndRegistrationSection } from './components/TitleAndRegistrationSection';
import TopSection from './components/TopSection';
import TopSectionData from './components/TopSectionData';
import { VscAndGapSection } from './components/VscAndGapSection';
import SaveEditButton from './components/buttons/SaveEditButton';

import {
  DebouncedSave,
  dividerProps,
  sectionWidth,
  sectionsContainerProps,
} from './DealInfoBuyoutForm';
import { CARD_PAYMENT_AMOUNT_MAX, CARD_PAYMENT_AMOUNT_MIN } from './constants';

import { LDFlags } from '../../constants/experiments';
import { LienholderNameEnum } from '../../constants/lienholders';
import { PermissionEnum } from '../../constants/permissions';
import useFlag from '../../hooks/useFlag';
import { DealContext } from '../../libs/DealContext';
import { AbilityContext } from '../../libs/contextLib';
import { formatMoney } from '../../libs/utils';
import { isInputReadOnly } from '../../utils/permissions';

const sectionMarginTop: StyleProps['mt'] = 12;
const smallSectionMarginTop: StyleProps['mt'] = 5;
const smallerSectionMarginTop: StyleProps['mt'] = 3;

interface DealStructurePanelProps {
  debouncedSave: DebouncedSave;
  banks: Bank[];
  isEditable: boolean;
  isEditingWithPermission: boolean;
  setIsEditingWithPermission: Dispatch<SetStateAction<boolean>>;
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
}

export const DealStructurePanel = ({
  debouncedSave,
  banks,
  isEditable,
  isEditingWithPermission,
  setIsEditingWithPermission,
  customHandleChange,
}: DealStructurePanelProps) => {
  const abilities = useContext(AbilityContext);
  const { deal, isRecalculatingPayoff, setIsRecalculatingPayoff, setUserChangedSomething } =
    useContext(DealContext);
  const { values, setFieldValue } = useFormikContext<Deal>();

  const { data: creditDecisionApplicationNumberData } = useCreditDecisionApplicationNumberQuery({
    skip: !deal.financial_info.selected_credit_decision_id,
    variables: {
      id: Number(deal.financial_info.selected_credit_decision_id) ?? -1,
    },
  });

  const { data: getCreditDecisionByR1FSIDData } = useGetCreditDecisionByR1FsidQuery({
    skip:
      !!deal.financial_info.selected_credit_decision_id ||
      !deal?.financial_info?.new_lienholder?.r1_fsid ||
      !deal?.id,
    variables: {
      deal_id: deal.id ?? -1,
      r1_fsid: deal.financial_info.new_lienholder?.r1_fsid || '',
    },
  });

  const applicationNumber =
    creditDecisionApplicationNumberData?.creditDecisionV2?.r1_application_number ||
    getCreditDecisionByR1FSIDData?.getCreditDecisionByR1FSID?.r1_application_number;

  const inputIsReadOnlyByDealState = isInputReadOnly({
    abilities,
    dealState: values.state,
    determineReadOnlyByDealState: true,
    isEditing: isEditingWithPermission,
  });
  const inputIsReadOnlyByPermissions = isInputReadOnly({
    abilities,
    dealState: values.state,
    determineReadOnlyByDealState: false,
    isEditing: isEditingWithPermission,
  });

  const breakOutCreditAndDebitCards = useFlag(LDFlags.BREAK_OUT_CREDIT_AND_DEBIT_CARDS);
  const comDashboardDownPaymentEnabled = useFlag(LDFlags.COM_DASHBOARD_DOWN_PAYMENT);
  const showCardPaymentLimitToggle =
    comDashboardDownPaymentEnabled &&
    abilities.has(PermissionEnum.ChangeCardPaymentAmountLimit) &&
    (deal.financial_info?.money_down ?? 0) > CARD_PAYMENT_AMOUNT_MIN &&
    !isBeforeClosing(deal.state);

  const handleCardLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserChangedSomething(true);
    if (e.target.checked) {
      setFieldValue('financial_info.credit_card_payment_amount_limit', CARD_PAYMENT_AMOUNT_MAX);
    } else {
      setFieldValue('financial_info.credit_card_payment_amount_limit', CARD_PAYMENT_AMOUNT_MIN);
    }
  };

  useEffect(() => {
    if (
      deal.financial_info?.money_down &&
      deal.financial_info.money_down <= CARD_PAYMENT_AMOUNT_MIN
    ) {
      setFieldValue('financial_info.credit_card_payment_amount_limit', CARD_PAYMENT_AMOUNT_MIN);
    }
  }, [deal.financial_info?.money_down]);

  return (
    <TabPanel p={0}>
      <TopSection justifyContent="space-between">
        <TopSectionData label="Bank Application #">{applicationNumber}</TopSectionData>
        <Box alignSelf="center">
          <CreditDecisionsButton isEditingWithPermission={isEditingWithPermission} />
          <PrequalificationSection inStructuringOrBeyond />
        </Box>
      </TopSection>

      <PaymentOptions
        debounceSave={debouncedSave}
        isEditingWithPermission={isEditingWithPermission}
        lienholder={deal.car?.payoff?.lienholder_name as LienholderNameEnum}
        customHandleChange={customHandleChange}
      />

      <Flex {...sectionsContainerProps}>
        <Flex direction="column" w={sectionWidth}>
          <InputRow label="Vehicle Payoff">
            <Text variant="dealInfoBuyout">{formatMoney(values.car?.payoff?.vehicle_payoff)}</Text>
          </InputRow>

          <InputRow label="Money Down">
            <NumberInput
              name="financial_info.money_down"
              isMoney
              showThousandSeparator
              additionalHandleChange={customHandleChange}
              isDisabled={inputIsReadOnlyByDealState || !isEditable}
            />
          </InputRow>

          {showCardPaymentLimitToggle && (
            <InputRow label={`${breakOutCreditAndDebitCards ? 'Credit Card' : 'Card'} Limit`}>
              <Switch
                id="credit_card_payment_amount_limit"
                name="financial_info.credit_card_payment_amount_limit"
                isNoYes
                noYesFontSize="sm"
                noLabel="$1k"
                yesLabel="$3k"
                customHandleChange={handleCardLimitChange}
                switchProps={{
                  isChecked:
                    values.financial_info.credit_card_payment_amount_limit ===
                    CARD_PAYMENT_AMOUNT_MAX,
                }}
                py={2}
              />
            </InputRow>
          )}

          <InputRow label="Bank Fees">
            <NumberInput
              name="financial_info.bank_fees"
              isMoney
              showThousandSeparator
              additionalHandleChange={customHandleChange}
            />
          </InputRow>

          <TitleAndRegistrationSection
            mt={sectionMarginTop}
            isEditable={isEditable}
            debouncedSave={debouncedSave}
            customHandleChange={customHandleChange}
          />
        </Flex>

        <Flex direction="column" {...dividerProps} />

        <Flex direction="column" w={sectionWidth}>
          <BankAndPaymentsSection
            isEditable={isEditable}
            inputIsReadOnlyByPermissions={inputIsReadOnlyByPermissions}
            banks={banks}
          />

          <EditDealInfoButton
            isEditingWithPermission={isEditingWithPermission}
            setIsEditingWithPermission={setIsEditingWithPermission}
            mt={smallSectionMarginTop}
          />
          <SaveEditButton
            isEditingWithPermission={isEditingWithPermission}
            setIsEditingWithPermission={setIsEditingWithPermission}
            mt={smallSectionMarginTop}
          />

          <VscAndGapSection
            mt={smallerSectionMarginTop}
            isEditable={isEditable}
            inputIsReadOnlyByPermissions={inputIsReadOnlyByPermissions}
            customHandleChange={customHandleChange}
          />
        </Flex>
      </Flex>

      <Flex {...sectionsContainerProps} align="center" justify="right" mr={10}>
        <Text>Amount Financed</Text>
        <Text p={2} bgColor="azureishWhite" fontSize="26px" fontWeight="normal">
          {formatMoney(values.financial_info?.amount_financed)}
        </Text>
      </Flex>

      {isEditable ? (
        <TotalPayoff
          hidden
          isRecalculating={isRecalculatingPayoff}
          setIsRecalculating={setIsRecalculatingPayoff}
        />
      ) : null}
    </TabPanel>
  );
};
