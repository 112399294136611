import { useContext, useEffect, useState } from 'react';

import { Box, Button, Icon, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { AiFillCalculator } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { isElectricVehicle } from '../../../gql/carGql';
import { Deal, isUneditable } from '../../../gql/dealGql';
import { Rate, useVscRatesQuery } from '../../../gql/generated/graphql';

import Modal from '../../shared/Modal';

import VscPicker from './VscPicker';
import './index.scss';

import { PermissionEnum } from '../../../constants/permissions';
import { Can } from '../../../libs/Can';
import { DealContext } from '../../../libs/DealContext';
import { isNumber } from '../../../libs/utils';
import Loader from '../../Loader/Loader';

const VscRatesModal = () => {
  const { values, setFieldValue } = useFormikContext<Deal>();
  const { setUserChangedSomething } = useContext(DealContext);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [vscRates, setVscRates] = useState<Rate[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const resetProducts = () => {
    setFieldValue('financial_info.vsc_price', null);
    setFieldValue('financial_info.vsc_cost', null);
    setFieldValue('financial_info.gap_price', null);
    setFieldValue('financial_info.gap_cost', null);
    setFieldValue('financial_info.pen_vsc_session_id', null);
    setFieldValue('financial_info.pen_vsc_rate_id', null);
    setFieldValue('financial_info.pen_vsc_form_id', null);
    setFieldValue('financial_info.pen_gap_session_id', null);
    setFieldValue('financial_info.pen_gap_rate_id', null);
    setFieldValue('financial_info.pen_gap_form_id', null);
  };

  const { loading, error: queryError } = useVscRatesQuery({
    skip: !isOpen,
    fetchPolicy: 'no-cache',
    variables: {
      deal_id: values.id ?? 0,
      data: {
        year: parseInt(values.car.year, 10),
        vin: values.car.vin,
        mileage: values.car.mileage,
        msrp: values.car.retail_book_value,
        purchasePrice: values.car.payoff.vehicle_payoff,
        apr: values.financial_info.sell_rate,
        financeTerms: values.financial_info.term,
        financeTermsMileage: 0,
        financedAmount: values.car.payoff.vehicle_payoff,
        state: values.customer.address?.state,
      },
    },
    onCompleted: (data) => {
      if (data?.vscRates?.length) {
        setUserChangedSomething(true);
        setVscRates(data.vscRates as Rate[]);
      }
    },
    onError: (error) => {
      resetProducts();
      toast.error(error?.message || 'Failed to retrieve rates');
    },
  });

  useEffect(() => {
    setIsDisabled(
      !values.car.year ||
        !values.car.vin ||
        !values.car.mileage ||
        !isNumber(values.car.retail_book_value) ||
        !isNumber(values.car.payoff.vehicle_payoff) ||
        !isNumber(values.financial_info.sell_rate) ||
        !isNumber(values.financial_info.term) ||
        !values.customer.address?.state,
    );
  }, [values.customer.address, values.car, values.financial_info]);

  const renderTooltip = (
    <span>
      Missing:
      {!values.car.year ? <div>Car Year</div> : ''}
      {!values.car.vin ? <div>VIN</div> : ''}
      {!values.car.mileage ? <div>Mileage</div> : ''}
      {!isNumber(values.car.retail_book_value) ? <div>KBB Retail</div> : ''}
      {!isNumber(values.car.payoff.vehicle_payoff) ? <div>Payoff</div> : ''}
      {!isNumber(values.financial_info.sell_rate) ? <div>Sell Rate</div> : ''}
      {!isNumber(values.financial_info.term) ? <div>Term</div> : ''}
      {!values.customer.address?.state ? <div>State</div> : ''}
    </span>
  );

  return (
    <Can I={PermissionEnum.CalculateVSC}>
      <Tooltip
        hasArrow
        placement="right"
        label={isDisabled ? renderTooltip : ''}
        bgColor="red"
        openDelay={250}
        closeDelay={400}
      >
        <Box>
          <Button
            variant="secondary"
            size="sm"
            leftIcon={<Icon as={AiFillCalculator} fontSize="md" />}
            isDisabled={isDisabled}
            hidden={isUneditable(values.state)}
            onClick={onOpen}
          >
            CALC VSC RATES
          </Button>
        </Box>
      </Tooltip>
      <Modal
        title="VSC Rates"
        isOpen={isOpen}
        onClose={onClose}
        variant="fullContent"
        closeOnOverlayClick={false}
        size="5xl"
        rightButtons={
          !loading && !queryError && vscRates?.length ? (
            <Button onClick={onClose} float="right">
              SAVE
            </Button>
          ) : null
        }
      >
        <Box py={2} bgColor="queenBlue" fontSize={14} textAlign="center" textColor="white">
          <Text fontSize={18}>
            {isElectricVehicle(values.car.fuel_type) ? 'Ally Major Guard EV' : 'Ally Major Guard'}
          </Text>
          $200 Deductible
        </Box>
        {loading ? (
          <Box h="75px" bgColor="backgroundGray" borderBottomRadius="10px">
            <Loader isLoading={loading} />
          </Box>
        ) : (
          <VscPicker options={vscRates} />
        )}
      </Modal>
    </Can>
  );
};

export default VscRatesModal;
