import { useMemo } from 'react';

import { Deal, DealStateEnum, isUneditable } from '../../../gql/dealGql';
import { FollowUpStatusEnum, PaperworkType } from '../../../gql/generated/graphql';

import { showPaperworkColorStates } from '../Task.utils';

import colors from '../../../chakra/foundations/colors';
import { oneDayInMilliseconds, tenMinInMilliseconds } from '../../../utils/dates';
import {
  isCloserUnclaimed,
  isSetterUnclaimed,
  isStructuringManagerClaimed,
} from '../../../utils/permissions';

type UseDealColorProps = {
  deal: Deal;
  canClaimAsFinancialSpecialistInSoftClose: boolean;
  autoAssigningFlag: boolean;
};

const useDealColor = ({
  deal,
  canClaimAsFinancialSpecialistInSoftClose,
  autoAssigningFlag,
}: UseDealColorProps) => {
  const color = useMemo(() => {
    if (!deal || !deal.lane_arrival_utc) {
      return '';
    }

    if (deal.state === DealStateEnum.StructuringInProgress) {
      return colors.taskGrey;
    }

    let isDealUnclaimed = false;
    if (deal.state === DealStateEnum.Estimate || deal.state === DealStateEnum.SoftClose) {
      isDealUnclaimed = canClaimAsFinancialSpecialistInSoftClose
        ? isCloserUnclaimed(deal)
        : isSetterUnclaimed(deal);
    } else if (deal.state === DealStateEnum.Structuring) {
      isDealUnclaimed = isStructuringManagerClaimed(deal);
    } else if (
      deal.state === DealStateEnum.Closing ||
      deal.state === DealStateEnum.Closed ||
      deal.state === DealStateEnum.SentForSignatures
    ) {
      isDealUnclaimed = isCloserUnclaimed(deal);
    }

    const arrivalTime = new Date(deal.lane_arrival_utc as string).getTime();
    const nowInMs = new Date().getTime();
    const timeSinceArrivalInMs = nowInMs - arrivalTime;
    const isNewlyArrived = timeSinceArrivalInMs < tenMinInMilliseconds;
    if (
      autoAssigningFlag &&
      (deal.closer_id || deal.closer?.id) &&
      !deal.called_by_financial_specialist
    ) {
      return colors.taskGreen;
    }

    let isDealStale = false;
    if (isDealUnclaimed) {
      isDealStale = !isNewlyArrived;
    } else if (
      deal.follow_up &&
      deal.follow_up?.status !== FollowUpStatusEnum.Completed &&
      deal.follow_up?.status !== FollowUpStatusEnum.Deleted
    ) {
      const followUpDateInMs = new Date(deal.follow_up.date_utc as string).getTime();
      isDealStale = nowInMs - followUpDateInMs > oneDayInMilliseconds;
    } else {
      const isEditableDeal = !isUneditable(deal.state as DealStateEnum);
      isDealStale =
        isEditableDeal &&
        timeSinceArrivalInMs >= oneDayInMilliseconds &&
        !deal.hasRecentCommunication;
    }

    if (isDealStale) {
      return colors.taskRed;
    }

    if (
      showPaperworkColorStates.includes(deal.state as DealStateEnum) &&
      deal.paperwork_type === PaperworkType.Paper
    ) {
      return colors.taskYellow;
    }

    return 'white';
  }, [
    autoAssigningFlag,
    canClaimAsFinancialSpecialistInSoftClose,
    deal.lane_arrival_utc,
    deal.state,
    deal.setter?.id,
    deal.structuring_manager_id,
    deal.closer?.id,
    deal.closer2?.id,
    deal.closer_id,
    deal.called_by_financial_specialist,
    deal.follow_up?.status,
    deal.follow_up?.date_utc,
    deal.paperwork_type,
    deal.hasRecentCommunication,
  ]);

  return color;
};

export default useDealColor;
