import { CreditDecisionStatusEnum } from '../gql/generated/graphql';

export const CreditDecisionStatusLabelEnum: Record<CreditDecisionStatusEnum, string> = {
  A: 'Approved',
  D: 'Denied',
  O: 'Conditioned',
  C: 'Counteroffer', // This is an internal status and does not match up with any R1 status. It's used when applicationStatus is 'A' and applicationStatusDetail is 'Counteroffer'
  P: 'Pending Structure', // This is an internal status and does not match up with any R1 status. It's used when applicationStatus is 'A' and applicationStatusDetail is 'Pending Structure'
  R: 'Preliminary',
  I: 'Withdrawn',
  B: 'Booked',
  N: 'New',
  S: 'Submitted', // This is an internal status and does not match up with any R1 status
  OT: 'Funding', // `OT` (Other) indicates the application has progressed to the funding stage, following prior acceptance. It ties to `fundingStatus`, which provides a more meaningful contracting status.
};
