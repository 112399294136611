import { format, parse } from 'date-fns';
import * as Yup from 'yup';

import { Maybe, TeamType } from '../../gql/generated/graphql';

import { LE_TIME_ZONE } from '../../constants/LeaseEndInfo';
import { MAX_MAX_AUTO_ASSIGN_DEALS, MIN_MAX_AUTO_ASSIGN_DEALS } from '../../constants/user';

export class WorkingHours {
  working_monday?: boolean;
  working_tuesday?: boolean;
  working_wednesday?: boolean;
  working_thursday?: boolean;
  working_friday?: boolean;
  working_saturday?: boolean;
  working_sunday?: boolean;

  start_monday?: string;
  start_tuesday?: string;
  start_wednesday?: string;
  start_thursday?: string;
  start_friday?: string;
  start_saturday?: string;
  start_sunday?: string;

  finish_monday?: string;
  finish_tuesday?: string;
  finish_wednesday?: string;
  finish_thursday?: string;
  finish_friday?: string;
  finish_saturday?: string;
  finish_sunday?: string;
  constructor() {
    this.working_monday = false;
    this.working_tuesday = false;
    this.working_wednesday = false;
    this.working_thursday = false;
    this.working_friday = false;
    this.working_saturday = false;
    this.working_sunday = false;

    this.start_monday = '';
    this.start_tuesday = '';
    this.start_wednesday = '';
    this.start_thursday = '';
    this.start_friday = '';
    this.start_saturday = '';
    this.start_sunday = '';

    this.finish_monday = '';
    this.finish_tuesday = '';
    this.finish_wednesday = '';
    this.finish_thursday = '';
    this.finish_friday = '';
    this.finish_saturday = '';
    this.finish_sunday = '';
  }
}

export const daysOfTheWeek = [
  { id: 0, label: 'Sunday', day: 'sunday' },
  { id: 1, label: 'Monday', day: 'monday' },
  { id: 2, label: 'Tuesday', day: 'tuesday' },
  { id: 3, label: 'Wednesday', day: 'wednesday' },
  { id: 4, label: 'Thursday', day: 'thursday' },
  { id: 5, label: 'Friday', day: 'friday' },
  { id: 6, label: 'Saturday', day: 'saturday' },
];

export const daysOfTheWeekValidationSchema = daysOfTheWeek.reduce(
  (schema, { day }) => ({
    ...schema,
    [`working_${day}`]: Yup.boolean().optional().nullable(),
    [`start_${day}`]: Yup.string().optional().nullable().when(`working_${day}`, {
      is: true,
      then: Yup.string().required(),
    }),
    [`finish_${day}`]: Yup.string().optional().nullable().when(`working_${day}`, {
      is: true,
      then: Yup.string().required(),
    }),
  }),
  {},
);

export const showManagerFieldsArray = [TeamType.Region, TeamType.D2d];
export const showVSCFieldsArray = [TeamType.D2d, TeamType.Outbound, TeamType.Inbound];
export const showCloserAndSpecialFieldsArray = [TeamType.D2d];
export const showSetterLeadsFieldArray = [TeamType.D2d, TeamType.Inbound, TeamType.Outbound];
export const showSetterFieldsArray = [TeamType.D2d, TeamType.Inbound, TeamType.Outbound];
export const showPodHoursFieldsArray = [TeamType.Inbound, TeamType.Outbound];
export const showTitlingFieldsArray = [TeamType.Titling];

export const editUserValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  auth0Roles: Yup.array().min(1, 'Auth0 Roles are required'),

  phoneNumber: Yup.string().optional().nullable(),
  pods: Yup.array().optional().nullable(),
  recruiterId: Yup.string().optional().nullable(),
  huntGroups: Yup.array().optional().nullable(),
});

// Password is only shown on create form.
export const createUserValidationSchema = editUserValidationSchema.shape({
  password: Yup.string().required('Password is required'),
});

export const podValidationSchema = Yup.object().shape({
  pod: Yup.object().shape({
    team_type: Yup.mixed<TeamType>()
      .oneOf(Object.values(TeamType))
      .required('Team Type is required'),
    name: Yup.string().max(50, 'Name must be less than 50 characters').required('Name is required'),
    color: Yup.string().required('Color is required'),
    vsc_multiplier: Yup.number().when('team_type', (team_type, schema) => {
      return showVSCFieldsArray.includes(team_type)
        ? schema.required('VSC Multiplier is required')
        : schema.optional();
    }),
    vsc_markup: Yup.number().when('team_type', (team_type, schema) => {
      return showVSCFieldsArray.includes(team_type)
        ? schema.required('VSC Markup is required')
        : schema.optional();
    }),
    managers: Yup.array().optional().nullable(),
    leads: Yup.array().optional().nullable(),
    usersWithoutTeamRole: Yup.array().optional().nullable(),
    managerCommissionIsFlat: Yup.boolean().when('team_type', (team_type, schema) => {
      return showManagerFieldsArray.includes(team_type) ? schema.required() : schema.optional();
    }),
    manager_commission_rate: Yup.number().when('team_type', (team_type, schema) => {
      return showManagerFieldsArray.includes(team_type)
        ? schema.required('Manager Commission Rate is required')
        : schema.optional();
    }),
    specialCommissionIsFlat: Yup.boolean().when('team_type', (team_type, schema) => {
      return showCloserAndSpecialFieldsArray.includes(team_type)
        ? schema.required()
        : schema.optional();
    }),
    special_commission_rate: Yup.number().when('team_type', (team_type, schema) => {
      return showCloserAndSpecialFieldsArray.includes(team_type)
        ? schema.required('Special Commission Rate is required')
        : schema.optional();
    }),
    closerCommissionIsFlat: Yup.boolean().when('team_type', (team_type, schema) => {
      return showCloserAndSpecialFieldsArray.includes(team_type)
        ? schema.required()
        : schema.optional();
    }),
    closer_commission_rate: Yup.number().when('team_type', (team_type, schema) => {
      return showCloserAndSpecialFieldsArray.includes(team_type)
        ? schema.required('Closer Commission Rate is required')
        : schema.optional();
    }),
    setterCommissionIsFlat: Yup.boolean().when('team_type', (team_type, schema) => {
      return showSetterFieldsArray.includes(team_type) ? schema.required() : schema.optional();
    }),
    setter_commission_rate: Yup.number().when('team_type', (team_type, schema) => {
      return showSetterFieldsArray.includes(team_type)
        ? schema.required('Setter Commission Rate is required')
        : schema.optional();
    }),
  }),
  workingHours: Yup.object().shape({
    ...daysOfTheWeekValidationSchema,
  }),
});

export const userSettingsValidationSchema = Yup.object().shape({
  workingHours: Yup.object().shape({
    ...daysOfTheWeekValidationSchema,
  }),
  on_vacation: Yup.boolean().required(),
  auto_assign_deals: Yup.boolean().required(),
  max_auto_assign_deals: Yup.number()
    .required('Individual max is required')
    .min(MIN_MAX_AUTO_ASSIGN_DEALS, `Must be ${MIN_MAX_AUTO_ASSIGN_DEALS} or more`)
    .max(MAX_MAX_AUTO_ASSIGN_DEALS, `Must be ${MAX_MAX_AUTO_ASSIGN_DEALS} or less`),
});

export type UserSettingsFormikFields = {
  workingHours: WorkingHours;
  on_vacation: boolean;
  auto_assign_deals: boolean;
  overnight_deals: boolean;
  max_auto_assign_deals: number;
};

export const getWorkingHoursString = (workingHours: WorkingHours) => {
  const timezone = LE_TIME_ZONE;
  const hrs = daysOfTheWeek.reduce((hours, { id, day }) => {
    const working = workingHours[`working_${day}` as keyof WorkingHours];
    const start = workingHours[`start_${day}` as keyof WorkingHours];
    const finish = workingHours[`finish_${day}` as keyof WorkingHours];

    if (working && start && finish) {
      // eslint-disable-next-line no-param-reassign
      hours += ` ${id} ${start}-${finish}`;
    }

    return hours;
  }, '');

  return hrs ? `${timezone}${hrs}` : '';
};

// input type='time' doesn't know what to do without the leading 0
const convertTimeString = (time: string) => {
  if (time.split(':')[0].length < 2) {
    return `0${time}`;
  }
  return time;
};

const convertTo12HourFormat = (time: string): string => {
  const parsedTime = parse(time, 'HH:mm', new Date());
  return format(parsedTime, 'hh:mm a');
};

export const getWorkingHoursFields = (hours?: string) => {
  let workingHoursFields = new WorkingHours();
  if (!hours) {
    return workingHoursFields;
  }

  const split = hours.split(' ');
  for (let offset = 1; offset + 1 < split.length; offset += 2) {
    const days = split[offset].split('-');
    const startDay = parseInt(days[0], 10);
    let endDay = startDay;
    if (days.length > 1) {
      endDay = parseInt(days[1], 10);
    }
    for (let day = startDay; day <= endDay; day += 1) {
      const dayName = daysOfTheWeek.find(({ id }) => id === day)?.day;

      const time = split[offset + 1].split('-');
      const start = convertTimeString(time[0]);
      const finish = convertTimeString(time[1]);

      workingHoursFields = {
        ...workingHoursFields,
        [`working_${dayName}`]: true,
        [`start_${dayName}`]: start,
        [`finish_${dayName}`]: finish,
      };
    }
  }

  return workingHoursFields;
};

export const getWorkingHoursTableValue = (
  hours: Maybe<string>,
): {
  Today: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  Sunday: string;
} => {
  const workingHoursTableValue = {
    Today: ' - ',
    Monday: ' - ',
    Tuesday: ' - ',
    Wednesday: ' - ',
    Thursday: ' - ',
    Friday: ' - ',
    Saturday: ' - ',
    Sunday: ' - ',
  };
  if (!hours) {
    return workingHoursTableValue;
  }
  const today = new Date().getDay();

  const split = hours.split(' ');

  for (let offset = 1; offset + 1 < split.length; offset += 2) {
    const days = split[offset].split('-');
    const startDay = parseInt(days[0], 10);
    let endDay = startDay;
    if (days.length > 1) {
      endDay = parseInt(days[1], 10);
    }
    for (let day = startDay; day <= endDay; day += 1) {
      const dayName = daysOfTheWeek.find(({ id }) => id === day)?.label;
      if (!dayName) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const time = split[offset + 1].split('-');
      const start = convertTo12HourFormat(time[0]);
      const finish = convertTo12HourFormat(time[1]);

      workingHoursTableValue[
        dayName as keyof typeof workingHoursTableValue
      ] = `${start} - ${finish}`;
      if (day === today) {
        workingHoursTableValue.Today = `${start} - ${finish}`;
      }
    }
  }

  return workingHoursTableValue;
};
