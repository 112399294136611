import { useContext } from 'react';

import { Checkbox, Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { addDays, format, parseISO } from 'date-fns';
import { AiOutlineFileText } from 'react-icons/ai';

import { Maybe } from '../../gql/generated/graphql';

import CreditDecisionCommission from './CreditDecisionCommission';
import CreditDecisionInfoRow from './CreditDecisionInfoRow';
import CreditDecisionStatusText from './CreditDecisionStatusText';
import useCreditDecisions, { CreditDecisionWithSubmittedAt } from './useCreditDecisions';

import { LienholderNameEnum } from '../../constants/lienholders';
import { DealContext } from '../../libs/DealContext';
import { formatMoney } from '../../libs/utils';
import { isCreditDecisionDenied, isCreditDecisionSelectable } from '../../utils/creditDecisions';

type CreditDecisionProps = {
  bankName: ReturnType<ReturnType<typeof useCreditDecisions>['getBankNamesByR1Fsid']>;
  creditDecision: CreditDecisionWithSubmittedAt;
  isSelected: boolean;
  isEditable: boolean;
  setSelectedCreditDecisionId: (id?: Maybe<number>) => void;
  handleViewDetailsClick: (id?: Maybe<string>) => void;
};

const CreditDecision = ({
  bankName,
  creditDecision,
  isSelected,
  isEditable,
  setSelectedCreditDecisionId,
  handleViewDetailsClick,
}: CreditDecisionProps) => {
  const { deal } = useContext(DealContext);

  const isSelectable = isCreditDecisionSelectable(creditDecision);
  const isDenied = isCreditDecisionDenied(creditDecision.application_status);

  return (
    <VStack
      rounded="md"
      shadow="md"
      borderWidth={1}
      borderStyle="solid"
      borderColor="queenBlue"
      bg={isSelected ? 'gray.200' : isDenied ? 'backgroundGray' : 'white'}
      justifyContent="space-between"
    >
      <VStack w="full" alignItems="flex-start" p={2} spacing={2}>
        <VStack w="full" spacing={0}>
          <HStack w="full" justifyContent="space-between">
            <Text fontSize="lg" fontWeight="normal" color={isDenied ? 'gray.500' : 'queenBlue'}>
              {bankName.displayName}
            </Text>
            <CreditDecisionCommission
              bankName={bankName.name}
              isDenied={isDenied}
              lienholder={deal.car.payoff.lienholder_name as LienholderNameEnum}
              lenderFees={creditDecision?.lender_fees}
            />
          </HStack>
          <HStack w="full">
            <Text fontSize="xs" color="gray.600" {...(isDenied && { color: 'gray.500' })}>
              Bank App #: {creditDecision?.r1_application_number}
            </Text>
            <AiOutlineFileText
              size={20}
              cursor="pointer"
              color="red.500"
              style={{ marginRight: 8 }}
              onClick={() => handleViewDetailsClick(creditDecision?.id as Maybe<string>)}
            />
          </HStack>
        </VStack>
        <CreditDecisionStatusText creditDecision={creditDecision} />
        <Flex gap={4} w="full">
          <VStack alignItems="flex-start">
            <CreditDecisionInfoRow
              isDisabled={isDenied}
              label="Buy Rate"
              value={
                creditDecision?.annual_percentage_rate
                  ? `${creditDecision.annual_percentage_rate}%`
                  : 'N/A'
              }
            />
            <CreditDecisionInfoRow
              isDisabled={isDenied}
              label="Amount"
              value={
                creditDecision?.balance_amount
                  ? `${formatMoney(creditDecision.balance_amount, { noDecimal: true })}`
                  : 'N/A'
              }
            />
            <CreditDecisionInfoRow
              isDisabled={isDenied}
              label="Submitted"
              value={format(parseISO(creditDecision.submittedAt), 'MM/dd/yyyy')}
            />
          </VStack>
          <VStack alignItems="flex-start">
            {!creditDecision.lender_fees && <Spacer h="auto" />}
            <CreditDecisionInfoRow
              isDisabled={isDenied}
              label="Term"
              value={creditDecision?.term ? `${creditDecision.term} mos` : 'N/A'}
            />
            {creditDecision.lender_fees && (
              <CreditDecisionInfoRow
                isDisabled={isDenied}
                label="Lender Fees"
                value={`$${creditDecision.lender_fees}`}
              />
            )}
            <CreditDecisionInfoRow
              isDisabled={isDenied}
              label="Expires"
              value={format(addDays(parseISO(creditDecision.submittedAt), 30), 'MM/dd/yyyy')}
            />
          </VStack>
        </Flex>
      </VStack>
      <Checkbox
        bg="queenBlue"
        roundedBottom="sm"
        color="white"
        w="full"
        p={2}
        isDisabled={!isEditable || !isSelectable || isDenied}
        isChecked={isSelected}
        onChange={(e) => setSelectedCreditDecisionId(e.target.checked ? creditDecision?.id : null)}
      >
        {!isSelectable || isDenied ? 'UNAVAILABLE' : 'SELECT'}
      </Checkbox>
    </VStack>
  );
};

export default CreditDecision;
