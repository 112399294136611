import React, { useEffect, useRef, useState } from 'react';

import { Icon, Td, Tooltip } from '@chakra-ui/react';
import { millisecondsToHours, millisecondsToMinutes } from 'date-fns';
import { MdTimer } from 'react-icons/md';

import { PayoffRequest } from '../../../../gql/generated/graphql';

import { calculateBusinessMilliseconds, isWithinBusinessHours } from '../../../../utils/dates';

interface ColorAndText {
  color: string;
  text: string;
}

const getDifferenceColorAndText = (payoffRequest: PayoffRequest): ColorAndText => {
  if (!payoffRequest?.created_at) {
    return { color: 'gray', text: 'N/A' };
  }

  const { created_at, completed_date } = payoffRequest;
  const milliseconds = calculateBusinessMilliseconds(
    new Date(created_at),
    completed_date ? new Date(completed_date) : new Date(),
  );

  const minutes = millisecondsToMinutes(milliseconds || 0);

  if (minutes < 10) {
    return { color: 'green', text: `${minutes} min` };
  }

  if (minutes < 20) {
    return { color: 'primary', text: `${minutes} min` };
  }

  if (minutes < 30) {
    return { color: 'americanOrange', text: `${minutes} min` };
  }

  if (minutes < 60) {
    return { color: 'errorsRed', text: `${minutes} min` };
  }

  const hours = millisecondsToHours(milliseconds || 0);

  if (hours < 48) {
    return { color: 'errorsRed', text: `${hours} hrs` };
  }

  const days = hours / 24;
  return { color: 'errorsRed', text: `${days.toFixed()} days` };
};

interface Props {
  payoffRequest?: PayoffRequest;
}

export const PayoffAge: React.FC<Props> = ({ payoffRequest }) => {
  // Store the initial time in a ref to use for calculating the difference in time
  const intervalIdRef = useRef<number | undefined>();

  const [colorAndText, setColorAndText] = useState<ColorAndText>(
    payoffRequest ? getDifferenceColorAndText(payoffRequest) : { color: 'gray', text: 'N/A' },
  );

  useEffect(() => {
    if (payoffRequest?.created_at) {
      intervalIdRef.current = window.setInterval(() => {
        const newColorAndText = getDifferenceColorAndText(payoffRequest);
        setColorAndText(newColorAndText);
      }, 60000);
    }
    return () => clearInterval(intervalIdRef.current);
  }, [payoffRequest?.created_at, payoffRequest?.completed_date]);

  return (
    <Td p="10px" textAlign="center" color="white" bg={colorAndText.color}>
      {payoffRequest?.completed_date || !isWithinBusinessHours(new Date()) ? (
        <Tooltip label={`Paused at ${colorAndText.text}`} hasArrow>
          <span>
            <Icon as={MdTimer} boxSize={6} />
          </span>
        </Tooltip>
      ) : (
        colorAndText.text
      )}
    </Td>
  );
};

export default PayoffAge;
