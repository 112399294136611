import { useState } from 'react';

import { Text } from '@chakra-ui/react';
import { BiCalendarEvent } from 'react-icons/bi';

import {
  Deal,
  FollowUpStatusEnum,
  FollowUpTypeEnum,
  useOnFollowUpUpdateSubscription,
} from '../../gql/generated/graphql';

import NotificationToast from './components/NotificationToast';

import { useUserAbilityAndVisibleStates } from '../../hooks/useUserAbilityAndVisibleStates';
import { formatDateWithTimeVerbose } from '../../libs/utils';
import { isNullOrUndefined } from '../../utils';

const DueFollowUpHandler = () => {
  const { loggedUserSources } = useUserAbilityAndVisibleStates();

  const [deals, setDeals] = useState<Deal[]>([]);

  useOnFollowUpUpdateSubscription({
    variables: {
      sources: loggedUserSources,
      scheduledByMeFilter: true,
      followUpType: FollowUpTypeEnum.Appointment,
      followUpStatus: FollowUpStatusEnum.Due,
    },
    onData: async ({ data }) => {
      setDeals((prevDeals) => {
        if (!data.data?.onFollowUpUpdate) {
          return prevDeals;
        }

        const newDeals = data.data.onFollowUpUpdate
          .filter((deal) => !isNullOrUndefined(deal))
          // Can't make it in one filter because of TS error.
          .filter((deal) => !prevDeals.some((prevDeal) => prevDeal.id === deal.id));

        return [...prevDeals, ...newDeals];
      });
    },
  });

  return (
    <>
      {deals.map((deal) => (
        <NotificationToast
          key={`${deal.id}`}
          dealId={deal.id ?? null}
          slideFadeIn={!!deal.customer?.first_name}
          title={`${deal.customer?.first_name} ${deal.customer?.last_name} - Deal ${deal.id}`}
          titleIcon={BiCalendarEvent}
          titleIconColor="americanOrange"
          subTitle="Appointment Scheduled"
          details={
            <Text>{formatDateWithTimeVerbose(deal.follow_up?.date_utc, deal.follow_up?.tz)}</Text>
          }
          onClose={() =>
            setDeals((prevDeals) => prevDeals.filter((prevDeal) => prevDeal.id !== deal.id))
          }
        />
      ))}
    </>
  );
};

export default DueFollowUpHandler;
